<template>
  <div class="weekly-calorie-chart">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'WeeklyCalorieChart',
  props: ['entries'],
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.createChart();
  },
  watch: {
    entries: {
      handler() {
        this.updateChart();
      },
      deep: true
    }
  },
  methods: {
    createChart() {
      const ctx = this.$refs.chart.getContext('2d');
      const weeklyData = this.calculateWeeklyCalories();

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: weeklyData.map(week => `Woche ${week.week}`),
          datasets: [{
            label: 'Kalorien',
            data: weeklyData.map(week => week.calories),
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
            fill: false,
          }],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Kalorien',
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Wöchentliche Kalorienaufnahme',
            },
          },
        },
      });
    },
    updateChart() {
      if (this.chart) {
        const weeklyData = this.calculateWeeklyCalories();
        this.chart.data.labels = weeklyData.map(week => `Woche ${week.week}`);
        this.chart.data.datasets[0].data = weeklyData.map(week => week.calories);
        this.chart.update();
      }
    },
    calculateWeeklyCalories() {
      const weeklyCalories = {};
      this.entries.forEach(entry => {
        const date = new Date(entry.createdAt);
        const weekNumber = this.getWeekNumber(date);
        if (!weeklyCalories[weekNumber]) {
          weeklyCalories[weekNumber] = 0;
        }
        weeklyCalories[weekNumber] += entry.calories * entry.servings;
      });

      return Object.entries(weeklyCalories)
        .map(([week, calories]) => ({ week: parseInt(week), calories }))
        .sort((a, b) => a.week - b.week);
    },
    getWeekNumber(date) {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    },
  },
};
</script>