<template>
  <div>
    <div class="row">
      <div
        class="col-lg-12"
        style="border: 2px solid red; padding: 20px; margin-bottom: 20px"
        v-if="summaries.length < 7"
      >
        Hinweis: Es liegen nur wenige Daten vor, daher kann eine Tendenz noch nicht passend berechnet werden. Wähle
        einen längeren Zeitraum oder lade mehr Einheiten hoch.
      </div>
      <div class="col-6 col-lg-3 top-spacer-15" v-for="diff of difference" :key="diff.hr">
        <h4>{{ diff.hr }}bpm</h4>
        <h4>
          <span v-bind:style="diff.gradient >= 0 ? 'color:green' : diff.gradient == 0 ? 'color:black' : 'color:red'">
            <span v-if="diff.gradient >= 0">+</span>{{ diff.gradient }}km/h</span
          >
          <!--
          <svg viewBox="0 0 100 40" xmlns="http://www.w3.org/2000/svg">
            <line
              x1="0"
              y1="50%"
              x2="100%"
              y2="50%"
              v-bind:stroke="
                diff.gradient > 0
                  ? 'green'
                  : diff.gradient == 0
                  ? 'black'
                  : 'red'
              "
              v-bind:transform="
                'translate(0, 0) rotate(' + diff.slope * 4 + ')'
              "
            />
          </svg>-->
        </h4>
      </div>
    </div>
    <div style="height: 1000px" class="top-spacer-25">
      <canvas ref="graph" id="graph"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import regression from "regression";

export default {
  props: ["allSummaries"],

  data: function () {
    return {
      summaries: [],
      chart: undefined,
      gradients: [],
      difference: [],

      chartdata: {
        labels: [],
        datasets: [
          {
            data: [],
            pointBackgroundColor: [],
            pointBorderColor: [],
            pointRadius: [],
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var datasetLabel = context.parsed.y + " km/h";
                return datasetLabel;
              },
            },
          },
        },

        scales: {
          x: {
            title: {
              display: true,
              text: "Einheit",
            },
            ticks: {
              stepSize: 1,
            },
          },

          y180: {
            offset: true,
            type: "linear",
            position: "left",
            stack: "demo",
            stackWeight: 2,
            title: {
              display: true,
              text: 180 + "bpm",
            },
            ticks: {
              display: false,
            },
          },
          y170: {
            offset: true,
            type: "linear",
            position: "left",
            stack: "demo",
            stackWeight: 2,
            title: {
              display: true,
              text: 170 + "bpm",
            },
            ticks: {
              display: false,
            },
          },

          y160: {
            offset: true,
            type: "linear",
            position: "left",
            stack: "demo",
            stackWeight: 2,
            title: {
              display: true,
              text: 160 + "bpm",
            },
            ticks: {
              display: false,
            },
          },

          y150: {
            offset: true,
            type: "linear",
            position: "left",
            stack: "demo",
            stackWeight: 2,
            title: {
              display: true,
              text: 150 + "bpm",
            },
            ticks: {
              display: false,
            },
          },
          y140: {
            offset: true,
            type: "linear",
            position: "left",
            stack: "demo",
            stackWeight: 2,
            title: {
              display: true,
              text: 140 + "bpm",
            },
            ticks: {
              display: false,
            },
          },

          y130: {
            offset: true,
            type: "linear",
            position: "left",
            stack: "demo",
            stackWeight: 2,
            title: {
              display: true,
              text: 130 + "bpm",
            },
            ticks: {
              display: false,
            },
          },
          y120: {
            offset: true,
            type: "linear",
            position: "left",
            stack: "demo",
            stackWeight: 2,
            title: {
              display: true,
              text: 120 + "bpm",
            },
            ticks: {
              display: false,
            },
          },
          y110: {
            offset: true,
            type: "linear",
            position: "left",
            stack: "demo",
            stackWeight: 2,
            title: {
              display: true,
              text: 110 + "bpm",
            },

            ticks: {
              display: false,
            },
          },
        },

        maintainAspectRatio: false,
        responsive: true,
        onClick: this.clickedWorkout,
      },
    };
  },

  async mounted() {
    let ctx = this.$refs.graph.getContext("2d");

    if (this.allSummaries) {
      this.summaries = this.allSummaries.filter(function (summary) {
        return (
          summary.workout &&
          summary.workout.sport == "Laufen" &&
          summary.avg_speed > 0 &&
          summary.avg_heart_rate > 0 &&
          summary.run_speed_at_bpm != undefined
        );
      });

      console.log(this.summaries.length);

      this.computeChartData();
      const config = {
        type: "scatter",
        data: this.chartdata,
        options: this.options,
      };

      new Chart(ctx, config);

      this.$emit("chartRendered");
    }
  },

  methods: {
    computeChartData: function () {
      const _self = this;

      let i = 1;

      const hr_vals = ["110", "120", "130", "140", "150", "160", "170", "180"];
      /*
      this.summaries = this.summaries.filter(function (summary) {
        return summary.workout.sport == "Laufen";
      });*/
      this.summaries = this.summaries.reverse(); //TODO:richtig so?

      for (const summary of this.summaries) {
        for (const speed_at_bpm of summary.run_speed_at_bpm) {
          if (speed_at_bpm && speed_at_bpm.bpm) {
            for (const [j, hr] of hr_vals.entries()) {
              if (!this.chartdata.datasets[j]) {
                this.chartdata.datasets[j] = {};
                this.chartdata.datasets[j].data = [];
                this.chartdata.datasets[j].pointRadius = [];
                this.chartdata.datasets[j].pointBackgroundColor = [];
                this.chartdata.datasets[j].pointBorderColor = [];
              }

              if (speed_at_bpm.bpm == hr) {
                this.chartdata.datasets[j].data.push({
                  x: i,
                  y: speed_at_bpm.avg,
                });
                _self.chartdata.datasets[j].pointRadius.push(0);
                _self.chartdata.datasets[j].yAxisID = "y" + hr;

                _self.chartdata.datasets[j].pointBackgroundColor.push("#333");
                _self.chartdata.datasets[j].tension = 0;
                _self.chartdata.datasets[j].showLine = 1;

                _self.chartdata.datasets[j].borderWidth = 1;

                _self.chartdata.datasets[j].trendlineLinear = {
                  style: "rgba(0,30,0, .8)",
                  lineStyle: "dotted",
                  width: 2,
                };
              }
            }
          }
        }
        i++;
      }
      for (const [j, hr] of hr_vals.entries()) {
        let gradientData = [];
        if (this.chartdata.datasets[j]) {
          for (const d of this.chartdata.datasets[j].data) {
            gradientData.push([d.x, d.y]);
          }

          const result = regression.linear(gradientData);
          const gradient = result.equation[0];
          const diff = Math.round(gradient * gradientData.length * 10) / 10;

          this.gradients.push(gradient);
          this.difference.push({
            hr: hr,
            gradient: diff,
            slope: result.equation[0],
          });
          if (diff > 0) {
            _self.chartdata.datasets[j].borderColor = "green";
          } else if (diff == 0) {
            _self.chartdata.datasets[j].borderColor = "black";
          } else {
            _self.chartdata.datasets[j].borderColor = "red";
          }
        } else {
          this.difference.push({
            hr: hr,
            gradient: 0,
            slope: 0,
          });
        }
      }
    },
  },
};
</script>
