<template>
  <div class="heart-rate-analysis-chart">
    <div class="chart-container">
      <canvas ref="chartRef"></canvas>
    </div>
    <div v-if="regression" class="stats">
      <div
        class="stat-item"
        title="Coefficient of determination - indicates how well the data fits the regression line"
      >
        <span class="label">R²:</span>
        <span class="value">{{ (regression.rSquared * 100).toFixed(2) }}%</span>
      </div>
      <div class="stat-item" title="Rate of change in power/speed per heart beat">
        <span class="label">Slope:</span>
        <span class="value">{{ regression.slope.toFixed(2) }}</span>
      </div>
      <div v-if="threshold" class="stat-item" :title="thresholdTooltip">
        <span class="label">Est. {{ thresholdLabel }}:</span>
        <span class="value">{{ threshold.toFixed(2) }} {{ thresholdUnit }}</span>
      </div>
      <div
        v-if="aerobicPower"
        class="stat-item"
        title="Estimated sustainable aerobic power/speed at 75% of max heart rate"
      >
        <span class="label">Est. Aerobic {{ powerLabel }}:</span>
        <span class="value">{{ aerobicPower.toFixed(2) }} {{ thresholdUnit }}</span>
      </div>
      <div
        v-if="anaerobicPower"
        class="stat-item"
        title="Estimated maximum aerobic power/speed at 95% of max heart rate"
      >
        <span class="label">Est. Anaerobic {{ powerLabel }}:</span>
        <span class="value">{{ anaerobicPower.toFixed(2) }} {{ thresholdUnit }}</span>
      </div>
      <div
        v-if="vlamax && sport === 'Radfahren'"
        class="stat-item"
        title="Estimated maximum glycolytic rate - indicates anaerobic capacity"
      >
        <span class="label">Est. VLamax:</span>
        <span class="value">{{ vlamax.toFixed(2) }} mmol/L/s</span>
      </div>
    </div>

    <TrainingZones v-if="threshold" :sport="sport" :threshold="threshold" />
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import { onMounted, ref, watch, nextTick, computed } from "vue";
import { computeWeightedRegression } from "@/services/heartRateProcessor";
import TrainingZones from "./TrainingZones.vue";
import { generateColors } from "@/utils/chartColors";

Chart.register(...registerables);

export default {
  name: "HeartRateAnalysisChart",
  components: {
    TrainingZones,
  },

  props: {
    analyses: {
      type: Array,
      required: true,
    },
    sport: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const chartRef = ref(null);
    const chart = ref(null);
    const regression = ref(null);
    const threshold = ref(null);
    const aerobicPower = ref(null);
    const anaerobicPower = ref(null);
    const vlamax = ref(null);

    const valueLabel = computed(() => (props.sport === "Laufen" ? "Speed (km/h)" : "Power (watts)"));

    const thresholdLabel = computed(() => (props.sport === "Laufen" ? "Threshold Pace" : "FTP"));
    const thresholdUnit = computed(() => (props.sport === "Laufen" ? "km/h" : "W"));

    const powerLabel = computed(() => (props.sport === "Laufen" ? "Speed" : "Power"));

    const calculateMetrics = (regression) => {
      if (!regression || !regression.regressionLine) return;

      const lastAnalysis = props.analyses[props.analyses.length - 1];
      const maxHR = lastAnalysis.heartRateMax;
      const { slope, intercept } = regression;

      const thresholdPercent = 0.9;
      const thresholdHR = maxHR * thresholdPercent;
      threshold.value = slope * thresholdHR + intercept;

      const aerobicHR = maxHR * 0.75;
      aerobicPower.value = slope * aerobicHR + intercept;

      const anaerobicHR = maxHR * 0.95;
      anaerobicPower.value = slope * anaerobicHR + intercept;

      if (props.sport === "Radfahren") {
        const powerDiff = anaerobicPower.value - aerobicPower.value;
        vlamax.value = (powerDiff / threshold.value) * 0.4;
      }
    };

    const updateChart = async () => {
      if (!props.analyses.length) return;

      regression.value = computeWeightedRegression(props.analyses);
      if (!regression.value) return;

      calculateMetrics(regression.value);

      await nextTick();

      const canvas = chartRef.value;
      if (!canvas || !canvas.getContext("2d")) return;

      const ctx = canvas.getContext("2d");
      const { points, regressionLine } = regression.value;

      let scatterDatasets = [
        {
          type: "scatter",
          label: "Measured Data",
          data: points.map((point) => ({
            x: point.bpm,
            y: point.value,
            r: Math.sqrt(point.count) / 2,
          })),
          backgroundColor: generateColors(1)[0],
        },
      ];

      if (points.length > 0 && points[0].workoutId !== undefined) {
        const workoutGroups = points.reduce((groups, point) => {
          if (!groups[point.workoutId]) {
            groups[point.workoutId] = [];
          }
          groups[point.workoutId].push(point);
          return groups;
        }, {});

        const colors = generateColors(Object.keys(workoutGroups).length);

        scatterDatasets = Object.entries(workoutGroups).map(([, workoutPoints], index) => ({
          type: "scatter",
          label: `Workout ${index + 1}`,
          data: workoutPoints.map((point) => ({
            x: point.bpm,
            y: point.value,
            r: Math.sqrt(point.count) / 2,
          })),
          backgroundColor: colors[index],
        }));
      }

      const chartData = {
        datasets: [
          ...scatterDatasets,
          {
            type: "line",
            label: "Regression Line",
            data: [
              { x: regressionLine.start.x, y: regressionLine.start.y },
              { x: regressionLine.end.x, y: regressionLine.end.y },
            ],
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 2,
            fill: false,
            pointRadius: 0,
          },
        ],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "linear",
            position: "bottom",
            title: {
              display: true,
              text: "Heart Rate (BPM)",
            },
          },
          y: {
            title: {
              display: true,
              text: valueLabel.value,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                const dataset = context.dataset;
                if (dataset.type === "scatter") {
                  const point = points[context.dataIndex];
                  return `BPM: ${point.bpm}, ${valueLabel.value}: ${point.value.toFixed(1)}, Samples: ${point.count}`;
                }
                return `${dataset.label}: ${context.parsed.y.toFixed(1)}`;
              },
            },
          },
        },
      };

      if (chart.value) {
        chart.value.destroy();
      }

      chart.value = new Chart(ctx, {
        type: "scatter",
        data: chartData,
        options,
      });
    };

    watch(() => props.analyses, updateChart, { deep: true, immediate: true });
    watch(() => props.sport, updateChart, { immediate: true });

    onMounted(async () => {
      await nextTick();
      updateChart();
    });

    const thresholdTooltip = computed(() => {
      return "Estimated Functional Threshold Power (FTP) at 90% of max heart rate";
    });

    return {
      chartRef,
      regression,
      threshold,
      thresholdLabel,
      thresholdUnit,
      aerobicPower,
      anaerobicPower,
      vlamax,
      powerLabel,
      thresholdTooltip,
    };
  },
};
</script>

<style scoped>
.heart-rate-analysis-chart {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.chart-container {
  height: 400px;
  position: relative;
  margin: 20px 0;
}

.stats {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.stat-item {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: help;
}

.label {
  font-weight: bold;
}
</style>
