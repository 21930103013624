import { BleClient } from "@capacitor-community/bluetooth-le";

const FITNESS_MACHINE = "00001826-0000-1000-8000-00805f9b34fb";
const FITNESS_MACHINE_CONTROL_POINT = "00002ad9-0000-1000-8000-00805f9b34fb";
const FITNESS_MACHINE_DATA_POINT = "00002ad2-0000-1000-8000-00805f9b34fb";

const SUPPORTED_RESISTANCE_LEVEL_RANGE = "00002ad6-0000-1000-8000-00805f9b34fb";
let minResistance = 0;
let maxResistance = 100;

// let power = undefined;
// let cadence = undefined;

async function doesDeviceSupportFtms(device) {
  if (device) {
    const support = (await BleClient.getServices(device.deviceId)).some(
      function (service) {
        return service.uuid == FITNESS_MACHINE;
      }
    );

    console.log("SUPPORT FTMS?", support);

    if (support) {
      console.log("Trainer unterstuetzt FTMS");
      // Verfuegbare Resistance Range initialisieren
      await readSupportedResistanceRange(device);
    } else {
      console.log("Trainer does not support FTMS!", device);
    }

    return Promise.resolve(support);
  } else {
    return false;
  }
}

async function requestControl(device) {
  const requestControl = getRequestControlView();

  return await BleClient.write(
    device.deviceId,
    FITNESS_MACHINE,
    FITNESS_MACHINE_CONTROL_POINT,
    requestControl
  );
}

function setTargetPower(device, power) {
  const requestTargetPower = getPowerTargetView(power);
  try {
    BleClient.write(
      device.deviceId,
      FITNESS_MACHINE,
      FITNESS_MACHINE_CONTROL_POINT,
      requestTargetPower
    );
  } catch(err) {
    console.log("Cant set Target power " +err)
  }
}

// Resistance in % (0-100)
function setResistanceTarget(device, resistance) {
  try {
    const trainerResistance = Math.round(
      minResistance + (resistance / 100) * (maxResistance - minResistance)
    );
    console.log("Umrechnung Resistance", resistance, trainerResistance);
    const requestResistanceTarget = getResistanceTargetView(trainerResistance);

    BleClient.write(
      device.deviceId,
      FITNESS_MACHINE,
      FITNESS_MACHINE_CONTROL_POINT,
      requestResistanceTarget
    );
  } catch (err) {
    console.log("Konnte Resistance nicht setzen!", err);
  }
}

async function readSupportedResistanceRange(device) {
  try {
    const dataview = await BleClient.read(
      device.deviceId,
      FITNESS_MACHINE,
      SUPPORTED_RESISTANCE_LEVEL_RANGE
    );

    let min = dataview.getUint16(0, dataview, true);
    let max = dataview.getUint16(2, dataview, true);
    let inc = dataview.getUint16(4, dataview, true);

    console.log("Trainer Resistance Range", { min, max, inc });
    minResistance = min;
    maxResistance = max;
  } catch (err) {
    // Z.B. schwinn ic8 hat keine Resistance Range
    console.log("Konnte Resistance Range nicht lesen!", err);
    minResistance = 0;
    maxResistance = 100;
  }
}

function subscribeControlPointResponse(device) {
  BleClient.startNotifications(
    device.deviceId,
    FITNESS_MACHINE,
    FITNESS_MACHINE_CONTROL_POINT,
    function (dataview) {
      const res = controlPointResponseDecoder(dataview);
      console.log(res);
    }
  );
}

function subscribeToDataPointResponse(device) {
  BleClient.startNotifications(
    device.deviceId,
    FITNESS_MACHINE,
    FITNESS_MACHINE_DATA_POINT,
    function (dataview) {
      const res = dataPointResponseDecoder(dataview);
      console.log(res);
      // power = res.power;
      //  cadence = res.cadence;
    }
  );
}

function stopNotifications(device) {
  try {
    BleClient.stopNotifications(
      device.deviceId,
      FITNESS_MACHINE,
      FITNESS_MACHINE_CONTROL_POINT
    );

    BleClient.stopNotifications(
      device.deviceId,
      FITNESS_MACHINE,
      FITNESS_MACHINE_DATA_POINT
    );
  } catch (err) {
    console.log("Konnte FTMS Notifications nicht stoppen!", err);
  }
}

const controlPointResults = {
  "0x01": { message: "Erfolgreich verbunden" },
  "0x02": { message: "Nicht unterstützt" },
  "0x03": { message: "Ungültige Parameter" },
  "0x04": { message: "Fehlgeschlagen" },
  "0x05": { message: "Nicht erlaubt" },
};

const controlPointOperations = {
  "0x00": {
    param: false,
    message: "Request control",
  },
  "0x01": {
    param: false,
    message: "Reset",
  },
  "0x04": {
    param: { resistance: "Uint8" },
    message: "Set Target Resistance",
  },
  "0x05": {
    param: { power: "Int16" },
    message: "Set Target Power",
  },
};

function numToHex(num) {
  let hex = parseInt(num).toString(16).toUpperCase();

  if (hex.length === 1) {
    hex = "0" + hex;
  }
  return "0x" + hex;
}

function dataPointResponseDecoder(dataview) {
  let result = {};

  let power = dataview.getUint16(6);
  let cadence = dataview.getUint16(4) / 2;

  result.power = power;
  result.cadence = cadence;

  console.log("FTMS Reading", result);

  return result;
}

function controlPointResponseDecoder(dataview) {
  let result = {
    responseCode: dataview.getUint8(0, true),
    requestCode: dataview.getUint8(1, true),
    resultCode: dataview.getUint8(2, true),
  };

  console.log("RESULT", result);

  result.response = numToHex(result.responseCode) || "Keine Antwort";
  result.operationMessage =
    controlPointOperations[numToHex(result.requestCode)].message ||
    "Keine Nachricht";
  result.resultMessage =
    controlPointResults[numToHex(result.resultCode)].message ||
    "Keine Ergebnis-Nachricht";

  return result;
}

function getResetView() {
  const OpCode = 0x01;
  let dataView = new DataView(new ArrayBuffer(1));
  dataView.setUint8(0, OpCode, true);

  return dataView;
}

function getRequestControlView() {
  const OpCode = 0x00;
  let dataView = new DataView(new ArrayBuffer(1));
  dataView.setUint8(0, OpCode, true);

  return dataView;
}

function getPowerTargetView(power) {
  const opCode = 0x05;
  let dataView = new DataView(new ArrayBuffer(3));
  dataView.setUint8(0, opCode, true);
  dataView.setInt16(1, power, true);

  return dataView;
}

function getResistanceTargetView(resistance) {
  const OpCode = 0x04;
  let dataView = new DataView(new ArrayBuffer(3));
  dataView.setUint8(0, OpCode, true);
  dataView.setInt16(1, resistance, true);

  return dataView;
}

export {
  requestControl,
  setTargetPower,
  doesDeviceSupportFtms,
  subscribeControlPointResponse,
  subscribeToDataPointResponse,
  setResistanceTarget,
  stopNotifications,
  controlPointResponseDecoder,
  getResetView,
  getRequestControlView,
  getPowerTargetView,
  getResistanceTargetView,
};
