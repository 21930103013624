
<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>


<script>
import { Chart } from "chart.js";
//import moment from 'moment-timezone'

export default {
  props: ["pctHrMax", "records", "sport"],
  // TODO: SCHWELLE LAUFEN + SCHWIMMEN auf 2. Y ACHSE. Höher ist besser
  data() {
    return {
      chartData: {
        labels: this.labels,
      },

      options: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },

        plugins: {
          tooltip: {
            enabled: true, // sonst folgende settings:

            mode: "index",
            callbacks: {
              label: (context) => {
                // data for manipulation
                const val = context.formattedValue;

                const index = context.datasetIndex;
                let prefix = "";
                let middle = "";

                if (index == 0) {
                  prefix = "Kohlenhydrate: ";
                } else if (index == 1) {
                  prefix = "Fette: ";
                } else if (index == 2) {
                  prefix = "Kohlenhydrate (Summe): ";
                } else if (index == 3) {
                  prefix = "Fette (Summe): ";
                }

                if (index == 0 || index == 1) {
                  middle = prefix + val + "%";
                } else {
                  middle = prefix + val + "kcal";
                }

                if (index == 0 || index == 1) {
                  return middle;
                } else {
                  if (index == 2) {
                    return (
                      middle +
                      " (" +
                      Math.round(
                        parseInt(val.replace(".", "").split(",")[0]) / 4
                      ) +
                      "g)"
                    );
                  } else {
                    return (
                      middle +
                      " (" +
                      Math.round(
                        parseInt(val.replace(".", "").split(",")[0]) / 9
                      ) +
                      "g)"
                    );
                  }
                }
              },
            },
          },
        },

        aspectRatio: 1,
        layout: {
          padding: 0,
          margin: 0,
        },
        animation: {
          duration: 0,
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0,
          },
        },

        scales: {
          yA: {
            display: false,

            stacked: true,
            grid: {
              drawBorder: true,
              display: false,
            },
            ticks: {
              display: true,
              beginAtZero: true,
            },
          },
          yB: {
            display: true,
            beginAtZero: true,

            stacked: false,
            grid: {
              drawBorder: true,
              display: true,
            },
            ticks: {
              display: true,
              beginAtZero: true,
              callback: function (label) {
                return label + "%";
              },
            },
          },

          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: true,
              maxTicksLimit: 6,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    let ctx = this.$refs.graph.getContext("2d");

    let i = 0;
    let fat = [];
    let cho = [];
    let fatSum = [];
    let choSum = [];
    let labels = [];
    let calories = 0;
    let withCalories = false;
    for (const pct of this.pctHrMax) {

      calories = 0;

      const fatCho = this.fat_and_carbs_utilization_at_PctHRmax(pct);


      // Energy (kcal) = Power (watts) * Time (hours) * 3.6
      if (
        this.sport != "Laufen" &&
        (this.records[i].power || this.records[i].enhanced_power)
      ) {
        const power =
          (this.records && this.records[i] && this.records[i].power) ||
          (this.records[i] && this.records[i].enhanced_power) ||
          1;
        calories =
          power *
            ((this.records &&
              this.records[i] &&
              this.records[i].timer_time -
                ((this.records[i - 1] && this.records[i - 1].timer_time) ||
                  0)) /
              3600) *
            3.6 || 1;
        fatSum.push(
          Math.round((fatCho.fat / 100) * calories * 10) / 10 +
            (fatSum[i - 1] || 0)
        );
        choSum.push(
          Math.round((fatCho.cho / 100) * calories * 10) / 10 +
            (choSum[i - 1] || 0)
        );


        withCalories = true;
      } else {
        choSum.push(choSum[i - 1]);
        fatSum.push(fatSum[i - 1]);
      }
      fat.push(Math.round(fatCho.fat));
      cho.push(Math.round(fatCho.cho));
      labels.push(
        this.formattedTime((this.records[i] && this.records[i].timer_time) || 0)
      );
      i++;
    }
    this.chartData.labels = labels;
    this.chartData.datasets = [
      {
        data: cho,
        fill: false,
        borderColor: "rgba(0,0,0, 1)",
        backgroundColor: "rgba(0,0,0, 1)",
        borderWidth: 1,
        yAxisID: "yB",
        label: "Kohlenhydrate",
      },
      {
        data: fat,
        fill: false,
        borderColor: "rgba(220,40,40, 1)",
        backgroundColor: "rgba(220,40,40, 1)",
        borderWidth: 1,
        yAxisID: "yB",
        label: "Fette",
      },
    ];

    if (withCalories) {
      this.chartData.datasets.push({
        data: choSum,
        fill: true,
        borderColor: "rgba(40,40,200, 0.5)",
        backgroundColor: "rgba(40,40,200, 0.5)",
        borderWidth: 0,
        yAxisID: "yA",
        label: "Kohlenhydrate (Summe)",
      });
      this.chartData.datasets.push({
        data: fatSum,
        fill: true,
        borderColor: "rgba(40,200,40, 0.5)",
        backgroundColor: "rgba(40,200,40, 0.5)",
        borderWidth: 0,
        yAxisID: "yA",
        label: "Fette (Summe)",
      });
    }

    const config = {
      type: "line",
      data: this.chartData,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yA = chart.scales.yA;
              let yB = chart.scales.yB;

              let ctx = chart.ctx;
              ctx.save();

              if (yA) {
                ctx.beginPath();
                ctx.moveTo(x, yA.top);
                ctx.lineTo(x, yA.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }

              if (yB) {
                ctx.beginPath();
                ctx.moveTo(x, yB.top);
                ctx.lineTo(x, yB.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }
            }
          },
        },
      ],
    };

    new Chart(ctx, config);
  },

  methods: {
    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },
    fat_and_carbs_utilization_at_PctHRmax: function (pctHRmax) {
      const vo2maxPct = this.pctVo2maxFromPctHRmax(pctHRmax || 50);

      return this.fat_and_carbs_utilization_at_PctVo2max(vo2maxPct);
    },

    pctVo2maxFromPctHRmax: function (pctHrMax) {
      if (pctHrMax > 60) {
        return 1.5472 * pctHrMax - 57.53;
      } else {
        return 35.3 * (pctHrMax / 60);
      }
    },

    fat_and_carbs_utilization_at_PctVo2max: function (vo2maxPct) {
      const pctFtp = Math.min(100, vo2maxPct / 0.805);
      const cho = Math.min(
        100,
        Math.max(0, 0.0407 * Math.pow(Math.E, 0.03193 * pctFtp) * 100 + 0)
      );
      return {
        cho: cho,
        fat: 100 - cho,
      };
    },
  },
};
</script>