<template>
  <div style="height: 400px !important">
    <canvas ref="graph" id="myChart" width="100%"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  name: "WoAnalysis",
  props: ["session", "sport", "thresholds", "x1marker", "x2marker"],

  data: function () {
    return { chartdata: [], elapsed_time: [] };
  },

  methods: {
    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm");
    },
  },

  mounted: function () {
    let ctx = this.$refs.graph.getContext("2d");

    let labels = [];
    let data_speed = [];
    let data_stil = [];
    let data_zuege = [];
    let data_hf = [];
    let data_distance = [];

    const _self = this;
    const swimThreshold = this.thresholds[this.thresholds.length - 1].threshold_swim;

    const skipped = (ctx, value) => (ctx.p0.skip || ctx.p1.skip || ctx.p1.parsed.y == 0 ? value : undefined);

    const meterProSecond = 100 / swimThreshold;

    //TODO anpassen
    const lit = 0.9;
    const hit = 1.2;

    const down = (ctx) => {
      if (ctx.p1.parsed.y <= meterProSecond * lit * 3.6) {
        return "green";
      } else if (ctx.p1.parsed.y >= meterProSecond * hit * 3.6) {
        return "red";
      } else {
        return "orange";
      }
    };

    let elapsed_time = 0;

    this.session.laps.forEach(function (lap) {
      elapsed_time += lap.total_timer_time;

      // WENN 200 geändert wird auch im Workout Analysis CHart ändern

      labels.push(_self.formattedTime(elapsed_time) + "h");
      if (lap.enhanced_avg_speed !== undefined) {
        lap.avg_speed = lap.enhanced_avg_speed;
      }
      if (lap.avg_speed != undefined) {
        data_speed.push(Math.round(lap.avg_speed * 100) / 100);
      }
      let stil = lap.swim_stroke;

      if (stil == "freestyle") {
        stil = "Freistil";
      } else if (stil == "mixed") {
        stil = "Mix";
      } else if (stil == "breaststroke") {
        stil = "Brust";
      } else if (stil == "backstroke") {
        stil = "Rücken";
      } else if (stil == "drill") {
        stil = "Drill";
      } else {
        stil = "Pause";
      }

      data_stil.push(stil);

      if (lap.avg_cadence != undefined) {
        data_zuege.push(Math.round(lap.avg_cadence) || 0);
      }

      if (lap.avg_heart_rate != undefined) {
        data_hf.push(Math.round(lap.avg_heart_rate) || 0);
      }

      if (lap.total_distance != undefined) {
        data_distance.push(lap.total_distance * 1000);
      }
    });

    const data = {
      labels: labels,
      datasets: [],
    };

    data.datasets.push({
      yAxisID: "y1",
      stepped: "after",
      label: "km/h",
      data: data_speed,
      borderColor: "rgb(75, 192, 192)",
      backgroundColor: "rgb(75, 192, 192)",
      segment: {
        borderColor: (ctx) => skipped(ctx, "rgb(0,0,0,0.45)") || down(ctx, "rgb(192,75,75)"),
        borderDash: (ctx) => skipped(ctx, [1, 1]),
      },
      borderWidth: 2,
      pointHoverRadius: 0,
    });
    data.datasets.push({
      stepped: "after",
      yAxisID: "y2",
      label: "Stil",
      data: data_stil,
      borderColor: "rgb(70, 200, 70)",
      backgroundColor: "rgb(70, 200, 70)",
      pointHoverRadius: 0,
      borderWidth: 2,
    });

    data.datasets.push({
      yAxisID: "y3",
      stepped: "after",
      label: "Züge",
      data: data_zuege,
      borderColor: "rgb(70, 70, 200)",
      backgroundColor: "rgb(70, 70, 200)",
      pointHoverRadius: 0,
      borderWidth: 2,
    });

    data.datasets.push({
      yAxisID: "y4",
      stepped: "after",
      label: "HF",
      data: data_hf,
      borderColor: "rgb(200, 70, 70)",
      backgroundColor: "rgb(200, 70, 70)",
      pointHoverRadius: 0,
      borderWidth: 2,
    });

    data.datasets.push({
      yAxisID: "y5",
      stepped: "after",
      label: "Distanz",
      data: data_distance,
      borderColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(0, 0, 0)",
      pointHoverRadius: 0,
      borderWidth: 2,
    });

    const config = {
      type: "line",
      data: data,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.y1;
              let yAxis2 = chart.scales.y2;
              let yAxis3 = chart.scales.y3;
              let yAxis4 = chart.scales.y4;
              let yAxis5 = chart.scales.y5;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }

              if (yAxis2) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis2.top);
                ctx.lineTo(x, yAxis2.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }

              if (yAxis3) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis3.top);
                ctx.lineTo(x, yAxis3.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }

              if (yAxis4) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis4.top);
                ctx.lineTo(x, yAxis4.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }

              if (yAxis5) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis5.top);
                ctx.lineTo(x, yAxis5.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }
            }
          },
        },
      ],
      options: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        maintainAspectRatio: false,

        responsive: true,

        plugins: {
          tooltip: {
            backgroundColor: "rgba(0,0,0,1)",
          },
          legend: {
            display: false,
          },
        },

        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              maxTicksLimit: 25,
            },
          },

          y2: {
            display: true,
            offset: true,
            type: "category",

            labels: ["Freistil", "Brust", "Rücken", "Mix", "Pause", "Drill"],
            ticks: {
              autoSkip: false,
            },
            grid: {
              offset: false,
              color: "#999",
              drawBorder: false,
              display: true,
              borderDash: [2, 5],
            },
            title: {
              display: true,
              drawBorder: false,

              text: "Stil",
            },
            position: "right",
            stack: "s1",
            stackWeight: 2,
          },

          y1: {
            display: true,
            offset: true,
            grid: {
              offset: false,
              color: "#999",
              drawBorder: false,
              display: false,
              borderDash: [2, 5],
            },
            title: {
              display: true,
              text: "km/h",
            },
            ticks: {
              autoSkip: false,
            },
            type: "linear",
            position: "right",
            stack: "s1",
            stackWeight: 3,
            tension: 0,
          },

          y4: {
            display: true,
            offset: true,

            grid: {
              offset: false,

              color: "#999",
              drawBorder: false,
              display: false,
              borderDash: [2, 5],
            },
            title: {
              display: true,
              text: "Herzfrequenz",
            },
            ticks: {
              autoSkip: false,
            },

            type: "linear",
            position: "right",
            stack: "s1",
            stackWeight: 3,
          },
          y3: {
            display: true,
            offset: true,

            grid: {
              offset: false,

              color: "#999",
              drawBorder: false,
              display: false,
              borderDash: [2, 5],
            },
            ticks: {
              autoSkip: false,
            },
            title: {
              display: true,
              text: "Züge",
            },

            type: "linear",
            position: "right",
            stack: "s1",
            stackWeight: 2,
          },
          y5: {
            display: true,
            offset: true,

            grid: {
              offset: false,

              color: "#999",
              drawBorder: false,
              display: false,
              borderDash: [2, 5],
            },
            ticks: {
              autoSkip: false,
            },
            title: {
              display: true,
              text: "Distanz",
            },

            type: "linear",
            position: "right",
            stack: "s1",
            stackWeight: 3,
          },
        },
      },
    };

    this.chartdata = JSON.parse(JSON.stringify(data)); // copy!

    let removeValFrom = [];

    if (data_speed.length == 0 || data_speed.reduce((pv, cv) => pv + cv, 0) == 0) {
      delete config.options.scales.y1;
      removeValFrom.push(0);
    }

    if (data_stil.length == 0 || data_stil.reduce((pv, cv) => pv + cv, 0) == 0) {
      delete config.options.scales.y4;
      removeValFrom.push(1);
    }

    if (data_zuege.length == 0 || data_zuege.reduce((pv, cv) => pv + cv, 0) == 0) {
      delete config.options.scales.y3;
      //config.options.plugins.annotation.annotations = [];
      removeValFrom.push(2);
    }

    if (data_hf.length == 0 || data_hf.reduce((pv, cv) => pv + cv, 0) == 0) {
      delete config.options.scales.y4;
      //config.options.plugins.annotation.annotations = [];
      removeValFrom.push(3);
    }

    if (data_distance.length == 0 || data_distance.reduce((pv, cv) => pv + cv, 0) == 0) {
      delete config.options.scales.y5;
      //config.options.plugins.annotation.annotations = [];
      removeValFrom.push(4);
    }

    config.data.datasets = config.data.datasets.filter(function (value, index) {
      return removeValFrom.indexOf(index) == -1;
    });

    new Chart(ctx, config);
  },
};
</script>
