var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-10 white_wrapper",staticStyle:{"text-align":"left"}},[_c('h3',{staticClass:"centered top-spacer-25"},[_vm._v("Frag den Coach")]),_vm._m(1),_c('div',{staticClass:"col-md-12 top-spacer-25"},[_vm._m(2),(_vm.videoUrls && _vm.videoUrls.length)?_c('div',[_c('hr'),_vm._m(3),_c('b-accordion',{attrs:{"id":"accordion-1","role":"tablist"},model:{value:(_vm.activeItem),callback:function ($$v) {_vm.activeItem=$$v},expression:"activeItem"}},_vm._l((_vm.videoUrls),function(video,index){return _c('b-card',{key:video.uri,staticClass:"top-spacer-5",attrs:{"no-body":""}},[_c('b-card-header',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('accordion-' + index),expression:"'accordion-' + index"}],staticClass:"p-1",staticStyle:{"cursor":"pointer"},attrs:{"header-tag":"header","role":"tab"}},[_c('a',{staticClass:"accordion-link",attrs:{"href":"#"}},[_vm._v(" "+_vm._s(video.title))])]),_c('b-collapse',{attrs:{"id":'accordion-' + index,"role":"tabpanel"}},[_c('b-card-body',[_c('iframe',{attrs:{"width":"100%","height":"350px","src":video.uri,"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})])],1)],1)}),1)],1):_vm._e()]),_c('h2',{staticClass:"centered top-spacer-75"},[_vm._v("Zugseiltraining")]),_vm._m(4),_c('h3',{staticClass:"centered top-spacer-75"},[_vm._v("Wettkampfwissen")]),_vm._m(5),_vm._m(6),_c('h2',{staticClass:"centered top-spacer-75"},[_vm._v("Trainingslager")]),_vm._m(7),_c('h2',{staticClass:"centered top-spacer-75"},[_vm._v("Ask The Coach #3")]),_vm._m(8),_c('h2',{staticClass:"centered top-spacer-75"},[_vm._v("Ask The Coach #2")]),_vm._m(9),_c('h2',{staticClass:"centered top-spacer-75"},[_vm._v("Ask The Coach #1")]),_vm._m(10)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submenu col-xl-2 d-none d-xl-block"},[_c('div',{staticClass:"width-wrap sticky-top"},[_c('div',{staticClass:"row top-spacer-25"},[_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/dashboard"}},[_c('div',{staticClass:"menu-entry"},[_vm._v("Dashboard")])])]),_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/kalender"}},[_c('div',{staticClass:"menu-entry"},[_vm._v("Kalender")])])]),_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/liste"}},[_c('div',{staticClass:"menu-entry"},[_vm._v("Trainingspläne")])])]),_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/FragDenCoach"}},[_c('div',{staticClass:"menu-entry active_submenu"},[_vm._v("Wissen")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('h4',[_vm._v(" In diesem Format sammeln und beantworten wir regelmäßig eure Fragen zum Training in Videoform. Hast du eine Frage? Schick' eine Mail an fragen@pushinglimits.club. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("Schwimmtechnik-Übungen "),_c('span',{staticStyle:{"color":"blue"}},[_vm._v("(Neu)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-spacer-15"},[_c('strong',[_vm._v("Öffne die Übung mit einem Klick")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 top-spacer-25"},[_c('h2',[_vm._v("Schneller schwimmen, ohne zu schwimmen")]),_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/a0ny0KOGFEQ","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 top-spacer-25"},[_c('h2',[_vm._v("3 Tipps um schneller im Wettkampf zu schwimmen")]),_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/lx7D7nbRnHs","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})]),_c('div',{staticClass:"col-md-6 top-spacer-25"},[_c('h2',[_vm._v("3 Freiwasser Tipps für den Wettkampf")]),_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/b_-yV8bg3H4","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})]),_c('div',{staticClass:"col-md-6 top-spacer-25"},[_c('h2',[_vm._v("Raceweek + Carboloading")]),_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/P3T12rqHAxA","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})]),_c('div',{staticClass:"col-md-6 top-spacer-25"},[_c('h2',[_vm._v("Pacing im Triathlonwettkampf")]),_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/KMgYcp50QhE","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 top-spacer-25"},[_c('h2',[_vm._v("Raceday + Race Nutrition")]),_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/cCl2TxJHUdg","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})]),_c('div',{staticClass:"col-md-6 top-spacer-25"},[_c('h2',[_vm._v("Material für den Triathlonwettkampf")]),_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/987yTMDvzJE","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 top-spacer-25"},[_c('h2',[_vm._v("Wie plane ich ein Trainingslager?")]),_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/YY1yx9IW36o","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 top-spacer-25"},[_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/OdkZtU3SZi8","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})]),_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"top-spacer-25"},[_vm._v(" 00:00 - Intro"),_c('br'),_c('br'),_vm._v(" 00:30 - Wann FTP-Test"),_c('br'),_c('br'),_vm._v(" 02:18 - Aufteilung Longrun"),_c('br'),_c('br'),_vm._v(" 04:47 - Ärztliche Untersuchung"),_c('br'),_c('br'),_vm._v(" 06:45 - Schrittfrequenz"),_c('br'),_c('br'),_vm._v(" 09:20 - Wie oft FTP-Test?"),_c('br'),_c('br'),_vm._v(" 10:30 - Wochen tauschen?"),_c('br'),_c('br'),_vm._v(" 11:45 - Anpassungen der Wochen?"),_c('br'),_c('br'),_vm._v(" 14:15 - Level-Sprung in den Plänen?"),_c('br'),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 top-spacer-25"},[_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/xa-WSumFRvI","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})]),_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"top-spacer-25"},[_vm._v(" 00:00 - Intro"),_c('br'),_c('br'),_vm._v(" 01:08 - Saisonhöhepunkt 70.3 im Juni | Welcher Plan?"),_c('br'),_c('br'),_vm._v(" 03:30 - Integration Lauf ABC in das Training"),_c('br'),_c('br'),_vm._v(" 05:50 - An welchen Tagen macht Krafttraining im Triathlon Sinn?"),_c('br'),_c('br'),_vm._v(" 07:40 - Wie lange soll ich mobility Übungen machen?"),_c('br'),_c('br'),_vm._v(" 09:15 - Wie gestalte ich Erholungswoche? ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 top-spacer-25"},[_c('iframe',{attrs:{"width":"100%","height":"350px","src":"https://www.youtube-nocookie.com/embed/CGg7AntK-WQ","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}})]),_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"top-spacer-25"},[_vm._v(" 1. Dehnen vor dem Laufen?"),_c('br'),_c('br'),_vm._v(" 2. Mobility vs. Aktivierung?"),_c('br'),_c('br'),_vm._v(" 3. Wenig Zeit: lieber Athletik Einheit oder Rad/Lauf/Swim Session?"),_c('br'),_c('br'),_vm._v(" 4. Ist die Reihenfolge der Einheiten wichtig?"),_c('br'),_c('br'),_vm._v(" 5. Was ist, wenn ich ein Intervall abbrechen muss?"),_c('br'),_c('br'),_vm._v(" 6. Mir geht während der Einheit die Energie aus - soll ich eine Pause machen?"),_c('br'),_c('br'),_vm._v(" 7. Soll ich mir mehrere Laufschuhe zulegen? ")])])])
}]

export { render, staticRenderFns }