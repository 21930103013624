<template>
  <div>
    <div class="row">
      <h2 class="top-spacer-25">
        <a href="/#/createtrainingsplan"> &larr; Zurück zur Trainingsplan Übersicht</a>
      </h2>

      <div class="col-lg-12 white_wrapper">
        <div style="padding: 15px; border: 3px solid red">
          <strong>
            Achtung: Noch im frühen Rohbau und teilweise etwas kompliziert. Einheiten können nicht verschoben werden
            werden und beim Löschen wird der ganze Tag gelöscht. Wenn was fehlt oder sonstige Vorschläge immer melden.
            Das Anlegen/Bearbeiten/Löschen wird demnächst komfortabler, hat aber zurzeit keine hohe Priorität, außer
            etwas wichtiges fehlt.<br />
            <br />Die Metadaten (Kategorie, Volume etc. werden noch angepasst).
          </strong>
        </div>
        <div class="w top-spacer-50">
          <div class="row">
            <div class="col-lg-12">
              <h4>Bezeichnung</h4>
              <input v-model="title" style="width: 100%" />
            </div>
            <div class="col-lg-12 top-spacer-50">
              <h4>Beschreibung</h4>
              <textarea style="width: 100%; height: 150px" v-model="description"></textarea>
            </div>

            <div class="col-lg-3 top-spacer-50">
              <h5>Kategorie</h5>
              <select v-model="category" style="width: 100%">
                <option>Base</option>
                <option>Build</option>
                <option>Peak</option>
                <option>Block</option>
                <option>Trainingslager</option>
              </select>
            </div>

            <div class="col-lg-3 top-spacer-50">
              <h5>Sport</h5>
              <select v-model="sport" style="width: 100%">
                <option>Triathlon</option>
                <option>Radfahren</option>
                <option>Laufen</option>
                <option>Schwimmen</option>
                <option value="Kraft">Athletik</option>
              </select>
            </div>

            <div class="col-lg-3 top-spacer-50">
              <h5>Volume</h5>
              <select v-model="volume" style="width: 100%">
                <option>Anfänger</option>
                <option>Fortgeschritten</option>
                <option>Profi</option>
              </select>
            </div>

            <div class="col-lg-3 top-spacer-50">
              <h5>Aktiv?</h5>
              <select v-model="active" style="width: 100%">
                <option>true</option>
                <option>false</option>
              </select>
            </div>

            <div class="col-lg-4 top-spacer-50">
              <h5>Vorheriger Plan</h5>
              <select v-model="previousPlanId" style="width: 100%">
                <option value="">Kein vorheriger Plan</option>
                <option v-for="plan in trainingplans" :value="plan._id" :key="plan._id">{{ plan.title }}</option>
              </select>
            </div>
            <div class="col-lg-4 top-spacer-50">
              <h5>Vorheriger Plan 2</h5>
              <select v-model="previousPlanId2" style="width: 100%">
                <option value="">Kein vorheriger Plan</option>
                <option v-for="plan in trainingplans" :value="plan._id" :key="'second-' + plan._id">
                  {{ plan.title }}
                </option>
              </select>
            </div>
            <div class="col-lg-4 top-spacer-50">
              <h5>Vorheriger Plan 3</h5>
              <select v-model="previousPlanId3" style="width: 100%">
                <option value="">Kein vorheriger Plan</option>
                <option v-for="plan in trainingplans" :value="plan._id" :key="'third-' + plan._id">
                  {{ plan.title }}
                </option>
              </select>
            </div>
            <div class="col-lg-4 top-spacer-50">
              <h5>Nächster Plan</h5>
              <select v-model="nextPlanId" style="width: 100%">
                <option value="">Kein nächster Plan</option>
                <option v-for="plan in trainingplans" :value="plan._id" :key="'next-' + plan._id">
                  {{ plan.title }}
                </option>
              </select>
            </div>
            <div class="col-lg-4 top-spacer-50">
              <h5>Nächster Plan 2</h5>
              <select v-model="nextPlanId2" style="width: 100%">
                <option value="">Kein nächster Plan</option>
                <option v-for="plan in trainingplans" :value="plan._id" :key="'next-second-' + plan._id">
                  {{ plan.title }}
                </option>
              </select>
            </div>
            <div class="col-lg-4 top-spacer-50">
              <h5>Nächster Plan 3</h5>
              <select v-model="nextPlanId3" style="width: 100%">
                <option value="">Kein nächster Plan</option>
                <option v-for="plan in trainingplans" :value="plan._id" :key="'next-third-' + plan._id">
                  {{ plan.title }}
                </option>
              </select>
            </div>

            <div class="col-lg-12 top-spacer-50" :class="{ 'input-error': !weeks }">
              <h5>Anzahl Wochen</h5>
              <input v-model="weeks" style="width: 100%" />
            </div>
            <div class="col-lg-12 top-spacer-50">
              <button class="button-b" @click="save()">SPEICHERN</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div v-if="day != undefined && newWorkoutDatePicker" class="overlay">
          <div class="overlay-box-library" @keydown.esc="hideNewWorkoutPickerOverlay()">
            <div class="row">
              <div
                class="col-lg-12 styled-table styled-table-no-bottom"
                style="margin: 0; border-bottom: 0; text-align: center"
              >
                <div class="colored-heading">
                  Workout aus Bibliothek wählen
                  <span style="float: right" class="close-overlay" @click="hideNewWorkoutPickerOverlay()"
                    ><i class="fa fa-times-circle-o" aria-hidden="true"></i
                  ></span>
                </div>
                <trainingplan-workout-blueprints
                  v-if="thresholds"
                  :key="thresholdKey"
                  v-bind:trainingsplan_id="id"
                  v-bind:day="day"
                  @closed="hideNewWorkoutPickerOverlay"
                  @wasSaved="hideNewWorkoutPickerOverlay"
                ></trainingplan-workout-blueprints>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedCalendarWorkout != undefined" class="overlay" @click="hideOverlay()"></div>

    <div class="row">
      <div
        class="white_wrapper_tighter col-lg-12 top-spacer-25"
        v-if="workouts && workouts.length > 0"
        style="position: fixed; bottom: 0; left: 0; width: 33.3%; z-index: 10000"
      >
        <calendar-pss-chart
          style="height: 130px"
          v-bind:done="statisticWorkouts"
          :key="thresholdKey + 'chartF'"
          v-bind:from="$moment().startOf('day')"
          v-bind:until="$moment().add(trainingplanDays, 'd').startOf('day')"
        />
      </div>

      <div
        class="white_wrapper_tighter col-lg-12 top-spacer-25"
        v-if="workouts && workouts.length > 0"
        style="position: fixed; bottom: 0; left: 33.3%; width: 33.3%; z-index: 10000"
      >
        <calendar-intensity-chart
          style="height: 130px"
          v-bind:done="statisticWorkouts"
          :key="thresholdKey + 'chartG'"
          v-bind:from="$moment().startOf('day')"
          v-bind:until="$moment().add(trainingplanDays, 'd').startOf('day')"
        />
      </div>

      <div
        style="position: fixed; bottom: 0; left: 66.6%; width: 33.3%; z-index: 10000"
        class="white_wrapper_tighter col-lg-12 top-spacer-25"
        v-if="workouts && workouts.length > 0"
      >
        <calendar-duration-chart
          style="height: 130px"
          v-bind:done="statisticWorkouts"
          :key="thresholdKey + 'charth'"
          v-bind:from="$moment().startOf('day')"
          v-bind:until="$moment().add(trainingplanDays, 'd').startOf('day')"
        />
      </div>
    </div>
    <div class="row white_wrapper top-spacer-50">
      <div class="col-lg-12">
        <a v-bind:href="'https://pushinglimits.club/#/training/vorschau/' + id" target="_blank"
          ><button style="width: 300px">Vorschau Nutzersicht</button></a
        >
      </div>
    </div>
    <div class="row week top-spacer-50" v-for="week in loadedWeeks" :key="week">
      <div class="col-lg-12">
        <div class="row">
          <div
            class="col-xs-7ths col-md-7ths"
            v-for="(n, index) in 7"
            :key="index"
            v-bind:id="'week-' + week + '-' + index"
          >
            <div
              v-if="selectedCalendarWorkout != undefined && day == week * 7 + index + 1"
              class="overlay-box"
              @keydown.esc="hideOverlay()"
            >
              <div class="row">
                <div class="col-lg-12" style="margin: 0; border-bottom: 0">
                  <div class="clearfix" style="padding: 10px">
                    <h3>Einheit bearbeiten</h3>
                  </div>

                  <div style="padding: 30px; padding-top: 10px">
                    <new-workout-trainingplan
                      v-bind:workout="selectedCalendarWorkout"
                      v-bind:trainingsplan_id="id"
                      v-if="thresholds"
                      :key="thresholdKey"
                      v-bind:thresholds="thresholds"
                      v-bind:day="week * 7 + index + 1"
                      v-bind:dayDate="newWorkoutDate"
                      @closed="hideOverlay"
                      @wasSaved="hideOverlay"
                      @wasMoved="hideOverlay"
                      @wasDeleted="hideOverlay"
                    ></new-workout-trainingplan>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="day == week * 7 + index + 1 && newWorkoutDate"
              class="overlay"
              @click="hideNewWorkoutOverlay()"
            ></div>
            <div
              v-if="day == week * 7 + index + 1 && newWorkoutDate"
              class="overlay-box"
              @keydown.esc="hideNewWorkoutOverlay()"
            >
              <div class="row">
                <div class="col-lg-12" style="margin: 0; border-bottom: 0">
                  <div class="clearfix" style="padding: 10px">
                    <h3>Neue Einheit anlegen</h3>
                  </div>
                  <div style="padding: 30px; padding-top: 10px">
                    <new-workout-trainingplan
                      v-bind:trainingsplan_id="id"
                      v-if="thresholds"
                      :key="thresholdKey"
                      v-bind:thresholds="thresholds"
                      v-bind:day="week * 7 + index + 1"
                      v-bind:dayDate="newWorkoutDate"
                      @closed="hideNewWorkoutOverlay"
                      @wasSaved="hideNewWorkoutOverlay"
                    ></new-workout-trainingplan>
                  </div>
                </div>
              </div>
            </div>

            <div class="spinner-border text-primary" role="status" v-if="!loaded"></div>

            <div class="heading">{{ weekdays[index] }}</div>
            {{ week * 7 + index + 1 }}<br /><br />
            <div class="row">
              <div class="col-1 d-block d-md-none"></div>
              <div class="col-md-12 col-10">
                <div class="entry">
                  <div class="row">
                    <div class="col-6">
                      <a @click="showNewWorkoutOverlay(week * 7 + index + 1)" class="pointer">
                        <div>
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </div>
                      </a>
                    </div>
                    <div class="col-6">
                      <a @click="showNewWorkoutPickerOverlay(week * 7 + index + 1)" class="pointer">
                        <div>
                          <i class="fa fa-table" aria-hidden="true"></i>
                        </div>
                      </a>
                    </div>
                    <!--
                    <div class="col-4">
                      <a
                        @click="deleteDay(week * 7 + index + 1)"
                        class="pointer"
                      >
                        <div>
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </div>
                      </a>
                    </div>-->
                  </div>
                </div>
                <workout-entry
                  class="top-spacer-15"
                  @wasClicked="showOverlay($event, week * 7 + index + 1)"
                  @workoutMoved="workoutMoved"
                  v-bind:workoutsOfDay="getWorkoutsOfDate(week, index + 1)"
                  :key="thresholdKey"
                  v-bind:thresholds="thresholds"
                  v-if="thresholds"
                  v-bind:wday="getDayOfWeek(week, index + 1)"
                >
                </workout-entry>
                <div class="col-1 d-block d-md-none"></div>
              </div>

              <div class="col-12 d-block d-md-none top-spacer-25"></div>
            </div>
          </div>
          <div class="col-lg-12 top-spacer-25" style="padding-top: 15px; border-top: 2px solid #eee">
            <div class="row">
              <div class="d-none d-lg-block col-lg-1 col-md-2 col-4">
                <strong></strong><br />

                Soll
              </div>
              <div class="col-lg-1 col-md-2 col-4">
                <strong>Zeit</strong><br />

                {{
                  formattedTimeSlash(
                    getWeeklyShouldValues(week).swim +
                      getWeeklyShouldValues(week).bike +
                      getWeeklyShouldValues(week).run +
                      getWeeklyShouldValues(week).strength
                  )
                }}
              </div>

              <div class="col-lg-1 col-md-2 col-4">
                <strong>Schwimmen</strong><br />
                {{ formattedTimeSlash(getWeeklyShouldValues(week).swim) }}
              </div>
              <div class="col-lg-1 col-md-2 col-4">
                <strong>Rad</strong><br />
                {{ formattedTimeSlash(getWeeklyShouldValues(week).bike) }}
              </div>
              <div class="col-12 d-block d-md-none"><br /></div>
              <div class="col-lg-1 col-md-2 col-4">
                <strong>Laufen</strong><br />
                {{ formattedTimeSlash(getWeeklyShouldValues(week).run) }}
              </div>
              <div class="col-lg-1 col-md-2 col-4">
                <strong>Athletik</strong><br />
                {{ formattedTimeSlash(getWeeklyShouldValues(week).strength) }}
              </div>

              <div class="col-12 d-block d-md-none"><br /></div>

              <div
                class="col-lg-2 col-md-2 col-12"
                v-if="
                  getWeeklyIntensityValues(week).time_hit +
                    getWeeklyIntensityValues(week).time_mit +
                    getWeeklyIntensityValues(week).time_lit >
                  0
                "
              >
                <strong>Intensitätsverteilung</strong>
                <br />
                <div class="progress" style="margin-top: 6px">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    v-bind:style="
                      'width:' +
                      timePercentage(
                        getWeeklyIntensityValues(week).time_lit,
                        getWeeklyIntensityValues(week).time_hit +
                          getWeeklyIntensityValues(week).time_mit +
                          getWeeklyIntensityValues(week).time_lit
                      ) +
                      '%'
                    "
                    v-bind:aria-valuenow="
                      timePercentage(
                        getWeeklyIntensityValues(week).time_lit,
                        getWeeklyIntensityValues(week).time_hit +
                          getWeeklyIntensityValues(week).time_mit +
                          getWeeklyIntensityValues(week).time_lit
                      )
                    "
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div
                    class="progress-bar bg-info"
                    role="progressbar"
                    v-bind:style="
                      'background-color: rgb(232, 126, 4)!important;' +
                      'width:' +
                      timePercentage(
                        getWeeklyIntensityValues(week).time_mit,
                        getWeeklyIntensityValues(week).time_hit +
                          getWeeklyIntensityValues(week).time_mit +
                          getWeeklyIntensityValues(week).time_lit
                      ) +
                      '%'
                    "
                    v-bind:aria-valuenow="
                      timePercentage(
                        getWeeklyIntensityValues(week).time_mit,
                        getWeeklyIntensityValues(week).time_hit +
                          getWeeklyIntensityValues(week).time_mit +
                          getWeeklyIntensityValues(week).time_lit
                      )
                    "
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    v-bind:style="
                      'width:' +
                      timePercentage(
                        getWeeklyIntensityValues(week).time_hit,
                        getWeeklyIntensityValues(week).time_hit +
                          getWeeklyIntensityValues(week).time_mit +
                          getWeeklyIntensityValues(week).time_lit
                      ) +
                      '%'
                    "
                    v-bind:aria-valuenow="
                      timePercentage(
                        getWeeklyIntensityValues(week).time_hit,
                        getWeeklyIntensityValues(week).time_hit +
                          getWeeklyIntensityValues(week).time_mit +
                          getWeeklyIntensityValues(week).time_lit
                      )
                    "
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3>Diesen Plan kopieren</h3>
    <div class="col-lg-12 top-spacer-50">
      <button class="button-b" @click="copy()">KOPIE ANLEGEN</button>
    </div>

    <div class="top-spacer-50"></div>

    <hr />
    <h3>PLAN LÖSCHEN</h3>
    <div class="col-lg-12 top-spacer-50">
      <button class="button-b" @click="deletePlan()">PLAN LÖSCHEN</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import WorkoutEntry from "@/components/calendar/WorkoutEntry";
import TrainingplanWorkoutBlueprints from "@/components/workout/TrainingplanWorkoutBlueprints";
import calendar_utils from "@/js/calendar_utils";
import NewWorkoutTrainingplan from "@/components/workout/NewWorkoutTrainingplan";
import CalendarPssChart from "@/components/chart/CalendarDurationChart.vue";
import CalendarDurationChart from "@/components/chart/CalendarPssChart.vue";
import CalendarIntensityChart from "@/components/chart/CalendarIntensityChart.vue";
export default {
  name: "Calendar",
  components: {
    WorkoutEntry,
    TrainingplanWorkoutBlueprints,
    NewWorkoutTrainingplan,
    CalendarPssChart,
    CalendarIntensityChart,
    CalendarDurationChart,
  },
  props: {
    id: String,
  },
  data: function () {
    return {
      loaded: false,
      newWorkoutDate: false, // fuer WorkoutBuilder
      newWorkoutDatePicker: false, // Fuer Vorlagen-Picker
      selectedCalendarWorkout: undefined,
      workouts: [],
      workoutBlueprints: [],

      previousPlanId: null,
      nextPlanId: null,
      weeks: 0,
      trainingsplanId: "",
      title: "",
      description: "",
      category: "Base",
      sport: "Triathlon",
      volume: "Beginner",
      active: false,

      weekdays: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
      weekLoadingBlocked: false,
      loadedWeeks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      lastScroll: 0,
      thresholds: undefined,
      swimThreshold: 0,
      runThreshold: 0,
      ftp: 0,
      thresholdKey: 0,
      weeklyShould: [],
      day: undefined,
      statisticWorkouts: [],
      trainingplans: [],

      trainingplanDays: 0,
    };
  },
  created() {
    this.updateThresholds();
  },
  destroyed() {
    //window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    save: async function () {
      const req = {
        id: this.trainingsplanId,
        title: this.title,
        description: this.description,
        category: this.category,
        sport: this.sport,
        volume: this.volume,
        active: this.active,
        nextPlanId: this.nextPlanId,
        nextPlanId2: this.nextPlanId2,
        nextPlanId3: this.nextPlanId3,
        previousPlanId: this.previousPlanId,
        previousPlanId2: this.previousPlanId2,
        previousPlanId3: this.previousPlanId3,
        weeks: this.weeks,
      };
      await (
        await axios.post(this.$host + "/trainingsplan/create", req)
      ).data;

      this.updateData();
    },

    copy: async function () {
      const req = {
        id: this.trainingsplanId,
      };

      axios.post(this.$host + "/trainingsplan/copy", req).then(
        function (res) {
          console.log(res);

          window.location.replace("/#/createtrainingsplan");
        },
        function (err) {
          console.log("FEHLER BEIM KOPIEREN", err);
        }
      );
    },
    colorIsShould(is, should) {
      return calendar_utils.colorForDuration(should, is);
    },
    workoutMoved(d, id, date) {
      console.log("moved", d, id, date);

      const wo = this.workoutBlueprints.filter((workout) => {
        return workout._id == id;
      });

      console.log(wo);
    },

    hideNewWorkoutPickerOverlay() {
      this.updateData();
      this.newWorkoutDatePicker = false;
      this.day = undefined;
    },
    showNewWorkoutPickerOverlay(day) {
      this.day = day;
      this.newWorkoutDatePicker = true;
    },

    hideNewWorkoutOverlay() {
      this.updateData();
      this.day = undefined;
      this.newWorkoutDate = false;
    },

    showNewWorkoutOverlay(day) {
      this.day = day;
      this.newWorkoutDate = true;
    },

    showOverlay(id, day) {
      // TODO : If activityFile --> Direkt in Details
      // if(this.workouts.contains(workout).activityFile)

      if (day) {
        this.day = day;
      }

      const blueprint = this.workoutBlueprints.find((workout) => {
        return id == workout._id;
      });

      this.selectedCalendarWorkout = blueprint;
    },
    hideOverlay() {
      this.updateData();
      this.selectedCalendarWorkout = undefined;
    },

    formatWorkoutTimes(seconds) {
      return this.$moment().startOf("day").seconds(seconds).format("H:mm:ss");
    },
    loadCalendarEntriesBottom() {
      if (!this.weekLoadingBlocked) {
        this.weekLoadingBlocked = true;

        if (this.loadedWeeks.length > 15) {
          this.loadedWeeks.shift();
        }

        this.loadedWeeks.push(this.loadedWeeks[this.loadedWeeks.length - 1] + 1);

        this.updateData();
      }
    },
    loadCalendarEntriesTop() {
      if (!this.weekLoadingBlocked) {
        this.weekLoadingBlocked = true;
        if (this.loadedWeeks.length > 15) {
          this.loadedWeeks.pop();
        }

        this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);
        this.updateData();
      }
    },

    getDayOfWeek: function (week, day) {
      return this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(day - 1, "d");
    },

    totalWorkoutLength(workoutFile) {
      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length;
    },

    sortedWorkoutsOfDay: function () {},

    getWorkoutsOfDate: function (week, day) {
      let workouts = this.workouts.filter((workout) => {
        return workout.day == week * 7 + day;
      });

      const blueprintIds = workouts.map((x) => x.workout_blueprint);

      let blueprints = this.workoutBlueprints.filter((workout) => {
        return blueprintIds.indexOf(workout._id) != -1;
      });

      blueprints = blueprints.map(function (blueprint) {
        const wo = workouts.find((workout) => {
          return workout.workout_blueprint == blueprint._id;
        });
        blueprint.orderOfDay = (wo && wo.orderOfDay) || 0;
        return blueprint;
      });

      return { workouts: blueprints };
    },

    updateData: async function () {
      const _self = this;

      this.workouts = await (await axios.get(this.$host + "/trainingsplan/" + this.id + "/list_entries")).data;
      this.loaded = true;

      axios
        .get(this.$host + "/workout/blueprints/v2", {
          params: { trainingplanId: this.id },
        })

        .then(function (response) {
          _self.workoutBlueprints = response.data.global.concat(response.data.trainingplan);

          // remove duplicates
          _self.workoutBlueprints = _self.workoutBlueprints.filter(
            (workout, index, self) => index === self.findIndex((t) => t._id === workout._id)
          );

          _self.statisticWorkouts = _self.workouts.map((w) => {
            let blueprint = _self.workoutBlueprints.find((workout) => {
              return w.workout_blueprint == workout._id;
            });

            let wo = JSON.parse(JSON.stringify(blueprint));
            wo.date = _self.$moment().add(w.day - 1, "days");

            _self.trainingplanDays = Math.max(_self.trainingplanDays, w.day);

            return wo;
          });
          _self.thresholdKey++;
        })
        .catch(function (error) {
          console.log(error);
        });

      setTimeout(function () {
        _self.weekLoadingBlocked = false;
      }, 400);
    },

    getWeeklyIntensityValues(week) {
      const startDate = this.$moment().startOf("isoWeek").add(week, "w");
      const endDate = this.$moment().startOf("isoWeek").add(week, "w").add(7, "d");

      const workouts = this.workouts.filter(
        (w) => this.$moment(w.date).isAfter(startDate) && this.$moment(w.date).isBefore(endDate) && w.durationIs > 0
      );
      let weeklyIntensity = { time_hit: 0, time_mit: 0, time_lit: 0 };
      workouts.forEach(function (w) {
        weeklyIntensity.time_hit += w.time_hit * (w.durationIs || w.durationShould);
        weeklyIntensity.time_mit += w.time_mit * (w.durationIs || w.durationShould);
        weeklyIntensity.time_lit += w.time_lit * (w.durationIs || w.durationShould);
      });
      return weeklyIntensity;
    },

    getWeeklyIsValues(week) {
      const startDate = this.$moment().startOf("isoWeek").add(week, "w");
      const endDate = this.$moment().startOf("isoWeek").add(week, "w").add(7, "d");

      const workouts = this.workouts.filter(
        (w) => this.$moment(w.date).isAfter(startDate) && this.$moment(w.date).isBefore(endDate)
      );
      let weeklyDuration = { swim: 0, run: 0, bike: 0, strength: 0 };
      workouts.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDuration.swim += w.durationIs || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDuration.bike += w.durationIs || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDuration.run += w.durationIs || 0;
        }
        if (w.sport == "Kraft") {
          weeklyDuration.strength += w.durationIs || 0;
        }
      });

      return weeklyDuration;
    },

    getWeeklyShouldValues(week) {
      const workouts = this.workouts.filter((workout) => {
        return workout.day > week * 7 && workout.day <= week * 7 + 7;
      });

      const blueprintIds = workouts.map((x) => x.workout_blueprint);

      const blueprints = this.workoutBlueprints.filter((workout) => {
        return blueprintIds.indexOf(workout._id) != -1;
      });

      //

      let weeklyDuration = { swim: 0, run: 0, bike: 0, strength: 0 };
      blueprints.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDuration.swim += w.durationShould || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDuration.bike += w.durationShould || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDuration.run += w.durationShould || 0;
        }
        if (w.sport == "Kraft") {
          weeklyDuration.strength += w.durationShould || 0;
        }
      });
      return weeklyDuration;
    },

    timePercentage(seconds, totalTime) {
      if (totalTime > 0) {
        return (100 / totalTime) * seconds;
      } else return 0;
    },
    formattedTimeSlash(seconds) {
      let days = 0;
      let ret = "";
      let tmpSeconds = seconds;
      while (tmpSeconds > 24 * 60 * 60) {
        days += 1;
        tmpSeconds -= 24 * 60 * 60;
      }
      if (days > 0) {
        ret += days + "/";
      }

      return ret + this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },

    getWeeklyDistanceShouldValues(week) {
      const _self = this;
      const workouts = this.workouts.filter((workout) => {
        return workout.day > week * 7 && workout.day <= week * 7 + 7;
      });

      const blueprintIds = workouts.map((x) => x.workout_blueprint);

      let weeklyDistance = { swim: 0, run: 0, bike: 0, strength: 0 };
      workouts.forEach(function (wo) {
        let w = _self.workoutBlueprints.filter((workout) => {
          return blueprintIds.indexOf(workout._id) == wo._id;
        });

        if (w.sport == "Schwimmen") {
          weeklyDistance.swim += w.distanceShould || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDistance.bike += w.distanceShould || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDistance.run += w.distanceShould || 0;
        }
      });

      weeklyDistance.run = Math.round(weeklyDistance.run * 10) / 10;
      weeklyDistance.bike = Math.round(weeklyDistance.bike * 10) / 10;
      weeklyDistance.swim = Math.round(weeklyDistance.swim * 10) / 10;

      return weeklyDistance;
    },

    deleteDay: async function (day) {
      console.log("DELETE", day);
      const _self = this;
      const workouts = this.workouts.filter((workout) => {
        return workout.day == day;
      });

      workouts.forEach(function (workout) {
        axios.delete(_self.$host + "/trainingsplan/delete_entry/" + workout._id).then(function () {
          _self.updateData();
          _self.thresholdKey++;
        });
      });

      this.updateData();
      _self.thresholdKey++;
    },
    async updateThresholds() {
      // TODO: Get only last Threshold
      this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;
    },
    async deletePlan() {
      try {
        await axios.delete(this.$host + "/trainingsplan/delete/" + this.trainingsplanId);
        this.$router.push("/training/liste");
      } catch (error) {
        console.error("Error deleting plan:", error);
      }
    },
  },

  mounted: async function () {
    this.loaded = false;

    const selectedPlan = await (await axios.get(this.$host + "/trainingsplan/" + this.id)).data;

    this.title = selectedPlan.title;
    this.trainingsplanId = selectedPlan._id;
    this.description = selectedPlan.description;
    this.category = selectedPlan.category;
    this.sport = selectedPlan.sport;
    this.volume = selectedPlan.volume;
    this.active = selectedPlan.active;
    this.weeks = selectedPlan.weeks;

    this.nextPlanId = selectedPlan.nextPlanId;
    this.nextPlanId2 = selectedPlan.nextPlanId2;
    this.nextPlanId3 = selectedPlan.nextPlanId3;
    this.previousPlanId = selectedPlan.previousPlanId;
    this.previousPlanId2 = selectedPlan.previousPlanId2;
    this.previousPlanId3 = selectedPlan.previousPlanId3;

    this.workouts = await (await axios.get(this.$host + "/trainingsplan/" + this.id + "/list_entries")).data;
    this.loaded = true;

    this.trainingplans = await (await axios.get(this.$host + "/trainingsplan/list_active")).data;

    // filter trainingplans by sport and volume and active
    this.trainingplans = this.trainingplans.filter((plan) => {
      return plan.sport == this.sport && plan.volume == this.volume && plan.active;
    });

    const _self = this;
    axios
      .get(this.$host + "/workout/blueprints/v2", {
        params: { trainingplanId: this.id },
      })
      .then(function (response) {
        _self.workoutBlueprints = response.data.global.concat(response.data.trainingplan);

        _self.workouts.sort(function (a, b) {
          return a.day - b.day;
        });

        // filter duplicates
        _self.workoutBlueprints = _self.workoutBlueprints.filter(
          (workout, index, self) => index === self.findIndex((t) => t._id === workout._id)
        );

        _self.statisticWorkouts = _self.workouts.map((w) => {
          let blueprint = _self.workoutBlueprints.find((workout) => {
            return w.workout_blueprint == workout._id;
          });

          let wo = JSON.parse(JSON.stringify(blueprint));
          wo.date = _self.$moment().add(w.day - 1, "days");

          _self.trainingplanDays = Math.max(_self.trainingplanDays, w.day);

          return wo;
        });
        _self.thresholdKey++;

        _self.loaded = true;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.loading {
  background: green;
}

.week {
  background: #fff;
  margin-bottom: 30px;
  padding: 35px 0;
  padding-bottom: 15px;
}

.heading {
  font-weight: bold;
  font-size: 16px;
}

i {
  font-size: 16px;
  padding: 7px;
  color: #aaa;
}

.entry {
  background: #f7f7f7;
  padding: 5px;
  border-radius: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
  z-index: 66666;
}

.close-overlay i {
  cursor: pointer;
  font-size: 35px;
  float: right;
}

.overlay-box {
  position: fixed;
  background: #fff;
  padding: 0;
  overflow-y: auto !important;
  margin: auto;
  width: 700px;
  left: 50%;
  margin-left: -350px;
  overflow-x: hidden;
  top: 3%;
  bottom: 3%;
  z-index: 99999999;
}

.input-error {
  border: 1px solid red;
  color: red;
}

.overlay-box-library {
  position: fixed;
  left: 5%;
  right: 5%;
  top: 3%;
  bottom: 3%;
  background: #fff;
  padding: 0;
  overflow-x: hidden;
  z-index: 99999999;
  padding: 25px;
}

.pointer {
  cursor: pointer;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .overlay-box {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin-left: 0;
  }
  .overlay-box-library {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    padding: 0;
    overflow-x: hidden;
    z-index: 99999999;
  }
}
</style>
