<template>
  <div>
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-6 white_wrapper">
        <h3>Wiedereinstieg - 30 Tage kostenlos!</h3>

        <h4 v-if="!error && !success">Bitte warten....</h4>

        <h4 v-if="success">
          Deinem Account wurden 30 Tage kostenloser Zugang gutgeschrieben. Viel
          Spaß! Wir freuen uns, dass du wieder dabei bist! <br /><br />
          <strong
            >Später würden wir uns natürlich auch über ein Abo freuen - das
            kostet im Jahr weniger als eine Leistungsdiagnostik und ermöglicht
            uns unsere Arbeit auf allen Kanälen fortzusetzen. :-)</strong
          >
        </h4>

        <h4 v-if="error">
          Es ist ein Fehler aufgetreten - wurde der Token bereit eingelöst?
          Prüfe einmal, ob dein Account schon wieder freigeschaltet wurde.
          Ansonsten überprüfe den Link oder wende dich an
          jan@pushinglimits.club.
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StopMail",

  props: ["id"],
  data: function () {
    return {
      success: false,
      error: false,
    };
  },

  mounted: function () {
    const id = this.id;
    const _self = this;

    console.log(id);

    axios
      .post(_self.$host + "/user/restart", { token: id })
      .then(function () {
        _self.success = true;
      })
      .catch(function (error) {
        console.log("Error: " + error);
        _self.error = true;
      });
  },
};
</script>

