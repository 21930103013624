<template>
  <div>
    <div class="row">
      <div class="submenu col-xl-2 d-none d-xl-block">
        <div class="width-wrap sticky-top">
          <div class="row top-spacer-25">
            <div class="col-lg-12">
              <a href="/#/esszimmer"> <div class="menu-entry active_submenu">Rezepte</div></a>
            </div>
            <div class="col-lg-12">
              <a href="/#/esszimmer/foodlog"> <div class="menu-entry">Foodlog</div></a>
            </div>

            <div class="col-lg-12">
              <a href="/#/esszimmer/plan">
                <div class="menu-entry">Vorschläge</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-10">
        <div class="row" style="box-shadow: rgba(13, 13, 18, 0.039) 0px 8px 54px 0px">
          <div class="col-lg-12">
            <div
              class="row top-spacer-25"
              style="
                background: #f6c050;
                padding-top: 20px;
                padding-bottom: 12px;
                letter-spacing: 0.3rem;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
              "
            >
              <div class="col-lg-12">
                <img
                  src="https://assets.pushinglimits.club/wtf_trans.png"
                  class="pull-left d-none d-md-block"
                  style="box-shadow: none !important; max-width: 170px; width: 80%; margin-top: -7px"
                  alt="where is the food?"
                />
                TRAININGSSPEZIFISCHE REZEPTE FÜR AUSDAUERSPORTLER
              </div>
            </div>

            <div class="row white_wrapper" style="padding: 30px 0; background: #fafafa; border-radius: 0px">
              <!--<div class="col-6">
            <div
              @click="show_filter = !show_filter"
              style="text-align: center; padding: 10px"
              class="col-lg-12 pointer"
            >
              <div class="pull-right">
                Nach Zutaten filtern <span v-if="show_filter">▲</span>
                <span v-if="show_filter == false">▼</span>
              </div>
            </div>
          </div>-->
              <div class="col-lg-3 col-md-8 centered">
                <h2
                  style="
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                  "
                >
                  Suche
                </h2>

                <div class="search-wrapper" style="width: 100%">
                  <input type="text" style="height: 40px; width: 100%" v-model="search" placeholder="Haferflocken" />
                </div>
              </div>
              <div class="d-md-none col-12">&nbsp;</div>

              <div class="col-lg-3 col-6 col-md-4 centered">
                <h2
                  style="
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                  "
                >
                  Buch
                </h2>
                <select
                  class="form-select"
                  aria-label="Buch"
                  style="width: 100%; overflow: hidden; text-overflow: ellipsis"
                  v-bind:value="editionFilter"
                  v-on:input="editionFilter = $event.target.value"
                >
                  <option value="all">Alle</option>
                  <option value="Winter">Winter</option>
                  <option value="Sommer">Sommer</option>
                  <option value="Meal Prep">Meal Prep</option>
                  <option value="Athletes Delight">Athletes Delight</option>
                </select>
              </div>
              <div class="d-none d-lg-none d-md-block col-md-12">&nbsp;</div>
              <div class="col-lg-3 col-md-4 col-6 centered">
                <h2
                  style="
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                  "
                >
                  Typ
                </h2>
                <select
                  v-bind:value="intensitaetFilter"
                  v-on:input="intensitaetFilter = $event.target.value"
                  class="form-select"
                  style="width: 100%; overflow: hidden; text-overflow: ellipsis"
                >
                  <option value="all">Alle</option>
                  <option value="LIT">LIT</option>
                  <option value="HIT">HIT</option>

                  <option value="Snack">Snack</option>
                </select>

                <div class="d-block d-lg-none">&nbsp;</div>
              </div>
              <div class="col-lg-3 col-md-4 centered">
                <h2
                  style="
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                  "
                >
                  Zeitpunkt
                </h2>
                <select
                  v-bind:value="zeitpunktFilter"
                  v-on:input="zeitpunktFilter = $event.target.value"
                  class="form-select"
                  aria-label="Buch"
                  style="width: 100%; overflow: hidden; text-overflow: ellipsis"
                >
                  <option value="all">Vor und nach dem Training</option>
                  <option value="Pre">Vor dem Training</option>
                  <option value="Post">Nach dem Training</option>
                </select>

                <div class="d-block d-lg-none">&nbsp;</div>
              </div>
            </div>
            <div
              class="row white_wrapper"
              style="padding: 30px 0; border-top-left-radius: 0px; border-top-right-radius: 0px"
            >
              <div class="top-spacer-25"></div>

              <div class="row" v-if="show_filter">
                <div
                  v-for="zutat in zutaten"
                  :key="zutat"
                  class="col-lg-3 col-md-6"
                  style="
                    text-align: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                  "
                >
                  <label for="checkid" style="height: 16px; font-size: 16px; line-height: 16px">
                    <input
                      id="checkid"
                      type="checkbox"
                      :checked="selected_zutaten.includes(zutat)"
                      @change="changeZutat(zutat)"
                    />
                    {{ zutat }}
                  </label>
                </div>
              </div>
              <!--
          <div class="col-12 centered" v-if="loading">
            <div
              class="spinner-border text-primary top-spacer-50"
              role="status"
            ></div>
          </div>-->
              <div
                class="col-xl-3 col-lg-4 col-md-6 top-spacer-25"
                v-for="recipe in filteredList"
                :key="recipe._id"
                v-bind:id="recipe._id"
              >
                <a v-bind:href="'/#/esszimmer/rezept/' + recipe._id + '/' + _self.intensitaetFilter">
                  <h2
                    style="
                      font-size: 22px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 100%;
                    "
                    class="centered"
                  >
                    {{ recipe.ueberschrift }}
                  </h2>
                  <div class="recipe-parent" v-if="recipe.imageIds[0]">
                    <div class="imgSpin">
                      <div
                        style="position: absolute; top: 80px; left: 47%"
                        class="spinner-border text-primary top-spacer-25"
                        role="status"
                      ></div>
                    </div>
                    <!-- -small hinters bild-->
                    <img
                      class="lazy recipe-img"
                      :data-src="`https://assets.pushinglimits.club/${recipe.imageIds[0]}-small`"
                    />
                  </div>
                </a>
                <div class="d-block d-md-none top-spacer-25"><br /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  metaInfo: {
    title: "Esszimmer",
  },
  name: "Esszimmer",
  components: {},
  data: function () {
    return {
      favorites: "",
      search: "",
      show_filter: false,
      show_recipes: [],
      recipes: [],
      zutaten: [],
      backendUrl: "",
      selected_zutaten: [],
      loading: true,
      zeitpunktFilter: "all",
      editionFilter: "all",
      intensitaetFilter: "all",
    };
  },
  methods: {
    scrollMeTo(refName) {
      var element = document.getElementById(refName);
      // var top = element.offsetTop;
      if (element) {
        element.scrollIntoView({ block: "start" });
        var scrolledY = window.scrollY;

        if (scrolledY) {
          window.scroll(0, scrolledY - 90);
        }
      }
    },
    lazyLoad: function () {
      let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

      setTimeout(() => {
        lazyImages.forEach(function (lazyImage) {
          if (
            lazyImage.getBoundingClientRect().top <= window.innerHeight + 100 &&
            lazyImage.getBoundingClientRect().bottom >= 0 &&
            getComputedStyle(lazyImage).display !== "none"
          ) {
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove("lazy");
            lazyImages = lazyImages.filter(function (image) {
              return image !== lazyImage;
            });
          }
        });
      });
    },
    changeZutat: function (zutat) {
      if (!this.selected_zutaten.includes(zutat)) {
        this.selected_zutaten.push(zutat);
      } else {
        this.selected_zutaten = this.selected_zutaten.filter((item) => item !== zutat);
      }
      const _self = this;
      this.show_recipes = this.recipes.filter(function (rec) {
        const res = _self.selected_zutaten.every(
          (v) =>
            rec.zutaten.some((z) => {
              return z.name.replace("§", "").replace("$", "") == v;
            }) ||
            rec.dip.some((z) => {
              return z.name.replace("§", "").replace("$", "") == v;
            }) ||
            rec.sosse.some((z) => {
              return z.name.replace("§", "").replace("$", "") == v;
            }) ||
            rec.dressing.some((z) => {
              return z.name.replace("§", "").replace("$", "") == v;
            }) ||
            rec.topping.some((z) => {
              return z.name.replace("§", "").replace("$", "") == v;
            })
        );

        return res;
      });

      this.zutaten = this.getPossibleZutaten();
    },
    getPossibleZutaten: function () {
      let result = [];
      // TODO: VAR ZUTATEN
      this.show_recipes.forEach(function (res) {
        res.zutaten.forEach(function (zutat) {
          result.push(zutat.name.replace("$", "").replace("§", ""));
        });
        res.dip.forEach(function (zutat) {
          result.push(zutat.name.replace("$", "").replace("§", ""));
        });
        res.topping.forEach(function (zutat) {
          result.push(zutat.name.replace("$", "").replace("§", ""));
        });
        res.dressing.forEach(function (zutat) {
          result.push(zutat.name.replace("$", "").replace("§", ""));
        });
        res.sosse.forEach(function (zutat) {
          result.push(zutat.name.replace("$", "").replace("§", ""));
        });
      });

      // Unique
      result = [...new Set(result.sort())];
      return result;
    },
  },
  created: function () {},

  computed: {
    filteredList() {
      const _self = this;
      let show_recipes_2 = [];

      if (this.favorites && this.favorites.length) {
        const favoriteIds = this.favorites.map((recipe) => recipe._id);
        show_recipes_2 = this.show_recipes.filter((recipe) => {
          return !favoriteIds.includes(recipe._id);
        });
      } else {
        show_recipes_2 = this.show_recipes;
      }

      let show_recipes_3 = [];

      if (this.favorites && this.favorites.length && show_recipes_2) {
        show_recipes_3 = this.favorites.concat(show_recipes_2);
      } else {
        show_recipes_3 = show_recipes_2;
      }

      return show_recipes_3.filter((recipe) => {
        const inUeberschrift = recipe.ueberschrift.toLowerCase().includes(this.search.toLowerCase());

        const zutaten = recipe.zutaten
          .concat(recipe.dip)
          .concat(recipe.dressing)
          .concat(recipe.topping)
          .concat(recipe.sosse)

          .concat(recipe.var_liste_1_zutaten)
          .concat(recipe.var_liste_2_zutaten)
          .concat(recipe.var_liste_3_zutaten)
          .concat(recipe.var_liste_4_zutaten)
          .concat(recipe.var_liste_5_zutaten);

        const inZutaten = zutaten.some(
          (zutat) => zutat && zutat.name.toLowerCase().includes(this.search.toLowerCase())
        );

        let inEdition = true;
        if (_self.editionFilter != "all") {
          inEdition = recipe.edition == _self.editionFilter;
        }

        let inZeitpunkt = true;
        if (_self.zeitpunktFilter != "all") {
          inZeitpunkt = recipe.zeitpunkt == _self.zeitpunktFilter;
        }

        let inIntensity = true;
        if (_self.intensitaetFilter != "all") {
          inIntensity =
            recipe.intensitaet == _self.intensitaetFilter ||
            (recipe.edition == "Meal Prep" && _self.intensitaetFilter == "HIT"); // MEAL Prep ist fuer beide intensitaeten
        }

        return (inUeberschrift || inZutaten) && inEdition && inZeitpunkt && inIntensity;
      });
    },
  },

  watch: {
    search: {
      handler() {
        const _self = this;
        setTimeout(function () {
          _self.lazyLoad();
          window.addEventListener("scroll", _self.lazyLoad);
        }, 150);
      },
    },
    editionFilter() {
      const _self = this;
      setTimeout(function () {
        _self.lazyLoad();
        window.addEventListener("scroll", _self.lazyLoad);
      }, 150);
    },
    zeitpunktFilter() {
      const _self = this;

      setTimeout(function () {
        _self.lazyLoad();
        window.addEventListener("scroll", _self.lazyLoad);
      }, 150);
    },
    intensitaetFilter() {
      const _self = this;

      setTimeout(function () {
        _self.lazyLoad();
        window.addEventListener("scroll", _self.lazyLoad);
      }, 150);
    },
  },

  mounted: function () {
    this.loading = true;

    window.addEventListener("scroll", this.lazyLoad);
    window.scrollTo(0, 0);

    const _self = this;

    axios
      .get(this.$host + "/rezept/favorites")
      .then(function (response) {
        _self.favorites = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(this.$host + "/rezept")
      .then(function (response) {
        _self.recipes = response.data;
        _self.show_recipes = _self.recipes;
        _self.getPossibleZutaten();
        _self.loading = false;
        _self.lazyLoad();
        _self.$nextTick(function () {
          setTimeout(_self.lazyLoad, 500);
          setTimeout(_self.lazyLoad, 1050);

          if (_self.$route.query && _self.$route.query.id) {
            _self.scrollMeTo(_self.$route.query.id);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(this.$host + "/rezept/zutaten")
      .then(function (response) {
        _self.zutaten = response.data.zutaten;
        _self.getPossibleZutaten();
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.recipe-img {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.recipe-img img {
  z-index: 10;
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.esszimmer-heading {
  font-size: 80px;
  text-align: center;
}

[type="checkbox"] {
  vertical-align: middle;
}

.recipe-parent {
  width: 100%;
  height: 200px;
  overflow: hidden;
  /* Magic */
  display: flex;
  align-items: center; /* vertical */
  justify-content: center; /* horizontal */
}

a {
  color: #000;
}

.recipe-img .imgSpin {
  text-align: center;

  z-index: 5;
}
</style>
