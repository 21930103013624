<template>
    <div class="calorie-chart">
      <canvas ref="chart"></canvas>
    </div>
  </template>
  
  <script>
  import Chart from 'chart.js/auto';
  
  export default {
    name: 'CalorieChart',
    props: ['entries'],
    data() {
      return {
        chart: null
      };
    },
    mounted() {
      this.createChart();
    },
    watch: {
      entries: {
        handler() {
          this.updateChartData();
        },
        deep: true
      }
    },
    methods: {
      createChart() {
        const ctx = this.$refs.chart.getContext('2d');
        const chartData = this.getChartData();
        this.chart = new Chart(ctx, {
          type: 'line',
          data: chartData,
          options: {
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Kalorien',
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Datum',
                },
              },
            },
          },
        });
      },
      updateChartData() {
        if (this.chart) {
          const newData = this.getChartData();
          this.chart.data = newData;
          this.chart.update();
        }
      },
      getChartData() {
        const dailyCalories = {};
        if (this.entries && this.entries.length > 0) {
          this.entries.forEach(entry => {
            if (entry.createdAt && entry.calories && entry.servings) {
              const dateString = new Date(entry.createdAt).toISOString().split('T')[0];
              dailyCalories[dateString] = (dailyCalories[dateString] || 0) + (entry.calories * entry.servings);
            }
          });
        }
        const sortedDates = Object.keys(dailyCalories).sort();
        return {
          labels: sortedDates,
          datasets: [{
            label: 'Kalorien',
            data: sortedDates.map(date => dailyCalories[date]),
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
          }],
        };
      },
    },
  };
  </script>