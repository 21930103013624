<template>
  <div>
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-6 white_wrapper">
        <h3>Mail Einstellung</h3>

        <h4 v-if="!error && !unsubscribed">Bitte warten....</h4>

        <h4 v-if="unsubscribed">
          Du bekommst nun nur noch die Mails, die für die Funktion der Anwendung
          erforderlich sind (zum Beispiel Passwort zurücksetzen)
        </h4>

        <h4 v-if="error">
          Es ist ein Fehler aufgetreten. Bitte überprüfe den Link oder wende
          dich an jan@pushinglimits.club.
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StopMail",

  props: ["id"],
  data: function () {
    return {
      unsubscribed: false,
      error: false,
    };
  },

  mounted: function () {
    const id = this.id;
    const _self = this;

    console.log(id);

    axios
      .post(_self.$host + "/user/stop_mail", { token: id })
      .then(function () {
        _self.unsubscribed = true;
      })
      .catch(function (error) {
        console.log("Error: " + error);
        _self.error = true;
      });
  },
};
</script>

<style>
</style>