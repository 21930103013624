<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-xl-2 col-lg-1"></div>

        <div class="col-12 col-lg-10 col-xl-8">
          <div class="white_wrapper_tighter col-lg-12light-shadow">
            <h2 class="top-spacer-15">Workout wählen</h2>
            <span style="display: inline-block">
              Möchtest du ein anderes Workout fahren? Lege in deinem Kalender mit dem Workoutbuilder eine neue
              Radeinheit an. Wenn du die Einheit öfter fahren möchtest, speichere sie als Vorlage. Zukünftig taucht die
              Einheit hier zur Auswahl auf.<br />
              Öffne die Einheit in deinem Kalender und wähle "Im Radraum fahren", um das Workout auch ohne Vorlage
              fahren zu können.
            </span>
          </div>

          <div
            class="white_wrapper top-spacer-25"
            v-if="$parent.history.length && $parent.workout_id"
            style="border: 2px solid darkblue"
          >
            <h4>Es läuft bereits ein Workout</h4>
            Wenn du ein anderes Workout starten möchtest, musst du zunächst das aktuell laufende beenden.<br /><br />

            <a :href="'/#/radraum/new/' + $parent.workout_id"><button>Wieder öffnen</button></a>
            <hr />

            <a :href="'/#/radraum/new/' + $parent.workout_id + '?open_menu=true'"><button>Workout Menü</button></a>
          </div>

          <div
            class="white_wrapper top-spacer-25"
            style="width: 100%"
            v-if="
              !($parent.history.length && $parent.workout_id) &&
              (nextWorkouts == undefined || (nextWorkouts && nextWorkouts.length > 0))
            "
          >
            <h4>Nächste Einheiten deines Plans</h4>
            <div class="spinner-border text-primary top-spacer-25" role="status" v-if="nextWorkouts == undefined"></div>
            <div class="row" v-if="nextWorkouts && nextWorkouts.length > 0 && thresholds">
              <div v-for="value in nextWorkouts" :key="value._id" class="col-md-3 top-spacer-10">
                <div class="row">
                  <div class="col-4 d-md-none">
                    <strong style="display: block" class="top-spacer-15">{{
                      $moment(value.date).format("DD.MM.YYYY")
                    }}</strong>
                  </div>
                  <div class="col-8 col-md-12">
                    <strong style="display: block" class="top-spacer-15 d-none d-md-block">{{
                      $moment(value.date).format("DD.MM.YYYY")
                    }}</strong>
                    <a :href="'/#/radraum/new/' + value._id" style="color: inherit">
                      <workout-entry
                        class="top-spacer-5"
                        v-bind:workoutsOfDay="{ workouts: [value] }"
                        v-bind:thresholds="thresholds"
                        :key="value._id + 'entry'"
                      >
                      </workout-entry>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="white_wrapper_tighter top-spacer-25"
            style="width: 100%"
            v-if="!($parent.history.length && $parent.workout_id)"
          >
            <div class="col-lg-12">
              <h4>Andere Einheit wählen</h4>
            </div>
            <div class="row">
              <div class="col-lg-2"></div>
              <div class="col-lg-4 top-spacer-25">
                <div class="slidecontainer">
                  Dauer mindestens {{ formattedTime(durationMin) }}
                  <input
                    type="range"
                    v-bind:min="durationMinStart"
                    v-bind:max="durationMaxStart"
                    class="slider"
                    id="durationMin"
                    v-model.number="durationMin"
                    v-on:change="durationChange()"
                  />
                </div>
              </div>
              <div class="col-lg-4 top-spacer-25">
                <div class="slidecontainer">
                  Dauer höchstens {{ formattedTime(durationMax) }}
                  <input
                    type="range"
                    v-bind:min="durationMinStart"
                    v-bind:max="durationMaxStart"
                    class="slider"
                    id="durationMax"
                    v-model.number="durationMax"
                    v-on:change="durationChange()"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-12 top-spacer-50">
              <div class="col-lg-12" v-if="!loaded" style="margin-bottom: 25px">
                <div class="spinner-border text-primary" role="status"></div>
              </div>
              <div class="row" v-if="!($parent.history.length && $parent.workout_id)">
                <div
                  class="col-lg-3 col-md-4 col-sm-6 col-12"
                  v-for="workout in workoutsListTimeFiltered"
                  :key="workout.id"
                >
                  <a :href="'/#/radraum/new/' + workout._id" style="color: inherit">
                    <div class="workout-box" style="margin-left: auto; margin-right: auto">
                      <div class="sport">
                        <span
                          class="sport-inner"
                          v-bind:style="workout.durationIs ? 'font-weight:bold;margin-right:-20px' : ''"
                          ><strong>{{ workout.name }}</strong>
                        </span>
                      </div>
                      <div
                        class="row work"
                        style="background: #ddd; padding: 7px 5px"
                        v-if="
                          workout.workoutFile &&
                          workout.workoutFile.length > 0 &&
                          (workout.sport == 'Radfahren' || workout.sport == 'Laufen')
                        "
                      >
                        <div class="col-lg-12">
                          <span v-for="value in workout.workoutFile" :key="value.id">
                            <svg
                              v-if="value.type == 'SteadyState'"
                              class="workoutBuilderBlock"
                              v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
                              v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                            >
                              <rect
                                v-bind:id="value.id"
                                v-bind:width="100 + '%'"
                                v-bind:height="value.Power / 4"
                                v-bind:style="'fill:' + colorForPower(value.Power)"
                              />
                            </svg>

                            <svg
                              v-if="value.type == 'Ramp'"
                              class="workoutBuilderBlock"
                              v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
                              v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                            >
                              <rect
                                v-for="index in 20"
                                :key="index"
                                v-bind:id="value.id + '-' + index"
                                v-bind:width="100 / 20 + '%'"
                                v-bind:x="(100 / 20) * index - 5 + '%'"
                                v-bind:height="getRampRectHeight(20, value.PowerLow / 4, value.PowerHigh / 4, index)"
                                v-bind:style="
                                  'fill:' + colorForPower(getRampRectHeight(20, value.PowerLow, value.PowerHigh, index))
                                "
                              />
                            </svg>

                            <svg
                              v-if="value.type == 'FreeRide'"
                              class="workoutBuilderBlock"
                              v-bind:width="value.Duration / 15"
                              v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                            >
                              <rect
                                v-bind:id="value.id"
                                v-bind:width="value.Duration / 15"
                                v-bind:height="80"
                                style="fill: rgba(94, 110, 255, 0.5)"
                              />
                            </svg>

                            <span v-if="value.type == 'IntervalsT'">
                              <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                                <svg
                                  class="workoutBuilderBlock"
                                  v-bind:width="computeWidth(value.OnDuration, workout.workoutFile)"
                                  v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                                >
                                  <rect
                                    v-bind:id="value.id"
                                    v-bind:width="'100%'"
                                    v-bind:height="value.OnPower / 4"
                                    v-bind:style="'fill:' + colorForPower(value.OnPower)"
                                  />
                                </svg>
                                <svg
                                  class="workoutBuilderBlock"
                                  v-bind:width="computeWidth(value.OffDuration, workout.workoutFile)"
                                  v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                                >
                                  <rect
                                    v-bind:id="value.id"
                                    v-bind:width="'100%'"
                                    v-bind:height="value.OffPower / 4"
                                    v-bind:style="'fill:' + colorForPower(value.OffPower)"
                                  />
                                </svg>
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>

                      <div v-bind:style="workout.durationIs ? 'font-weight:bold' : ''">
                        <div style="margin-top: 5px">
                          <span v-if="workout.sport == 'Kraft'"
                            ><img src="@/assets/images/strength.png" style="width: 23px"
                          /></span>
                          <span v-if="workout.sport == 'Laufen'"
                            ><img src="@/assets/images/run.png" style="width: 23px"
                          /></span>
                          <span v-if="workout.sport == 'Schwimmen'"
                            ><img src="@/assets/images/swim.png" style="width: 23px"
                          /></span>
                          <span v-if="workout.sport == 'Radfahren'"
                            ><img src="@/assets/images/bike.png" style="width: 23px"
                          /></span>
                        </div>

                        <span v-if="!workout.durationIs && workout.workoutFile && workout.workoutFile.length > 0"
                          >{{ formattedTime(totalWorkoutLength(workout.workoutFile)) }}
                        </span>
                        <span v-if="!workout.durationIs && workout.workoutFile && workout.workoutFile.length == 0">{{
                          formattedTime(workout.durationShould)
                        }}</span>

                        <span v-if="workout.durationIs">{{ formattedTime(workout.durationIs) }}</span>

                        <span v-if="workout.paceAvg && workout.sport == 'Laufen'">
                          @
                          {{
                            $moment()
                              .startOf("day")
                              .seconds(Math.round(workout.paceAvg * 60))
                              .format("mm:ss")
                          }}min/km
                        </span>
                        <span v-if="workout.paceAvg && workout.sport == 'Radfahren'">
                          @
                          {{ Math.round((60 / workout.paceAvg) * 10) / 10 }}km/h
                        </span>
                        <span v-if="workout.heartrateAvg">{{ workout.heartrateAvg }}bpm</span>
                        <span v-if="workout.distance"><br />{{ Math.round(workout.distance * 10) / 10 }}km</span>
                        <span v-if="workout.powerInWatts">
                          |
                          {{ Math.round(workout.powerInWatts * 10) / 10 }}w</span
                        >

                        <span
                          v-if="
                            workout.sport == 'Radfahren' &&
                            !workout.durationIs &&
                            workout.workoutFile &&
                            workout.workoutFile.length > 0
                          "
                        >
                          Ø {{ computeAvgWatt(workout.workoutFile) }}w</span
                        >

                        <br />
                      </div></div
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import calendar_utils from "@/js/calendar_utils";
import WorkoutEntry from "@/components/calendar/WorkoutEntryNoHighlight";

export default {
  metaInfo: {
    title: "Radraum Workout wählen",
  },
  name: "WorkoutBlueprints",
  components: { WorkoutEntry },
  data() {
    return {
      thresholds: {},
      durationMin: 0,
      durationMax: 0,
      durationMinStart: 0,
      durationMaxStart: 0,
      workoutBlueprintsUser: [],
      workoutBlueprintsGlobal: [],
      allWorkoutsList: [],
      workoutsList: [],
      workoutsListTimeFiltered: [],
      active_btn: "Alle",
      list: new Array(250),
      loaded: false,
      nextWorkouts: undefined,
    };
  },
  methods: {
    getRampRectHeight(numberOfBlocks, startIntensity, endIntensity, index) {
      let res = startIntensity - ((startIntensity - endIntensity) / numberOfBlocks) * index;

      return res;
    },
    showAlle() {
      this.active_btn = "Alle";

      this.workoutsList = this.allWorkoutsList;
      this.filterByDuration();
    },
    showLaufen() {
      this.active_btn = "Laufen";
      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Laufen");
      this.filterByDuration();
    },
    showRadfahren() {
      this.active_btn = "Radfahren";
      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Radfahren");
      this.filterByDuration();
    },
    showSchwimmen() {
      this.active_btn = "Schwimmen";
      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Schwimmen");
      this.filterByDuration();
    },
    colorForPower(power) {
      if (power > 105) {
        return "darkred";
      } else if (power > 100) {
        return "brown";
      } else if (power > 95) {
        return "firebrick";
      } else if (power > 88) {
        return "darkgreen";
      } else if (power > 83) {
        return "green";
      } else {
        return "rgba(94, 110, 255, 0.97)";
      }
    },
    showKraft() {
      this.active_btn = "Kraft";

      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Kraft");
      this.filterByDuration();
    },
    durationChange() {
      this.filterByDuration();
    },

    //TODO FTP
    computeAvgWatt(workoutFile) {
      return calendar_utils.computeAvgPowerFromWorkoutFile(workoutFile, this.thresholds.ftp);
    },

    computeAvgRunSpeed(workoutFile) {
      return this.$moment()
        .startOf("day")
        .seconds(calendar_utils.computeSpeedFromWorkoutFile(workoutFile, this.thresholds.threshold_run))
        .format("mm:ss");
    },

    filterByDuration() {
      const _self = this;

      if (_self.durationMin > _self.durationMax) {
        _self.durationMax = _self.durationMin;
        console.log("yes");
      }
      console.log(typeof _self.durationMin + ">" + typeof _self.durationMax);
      this.workoutsListTimeFiltered = this.workoutsList.filter((workout) => {
        const workoutLength = _self.totalWorkoutLength(workout.workoutFile);
        return workoutLength >= _self.durationMin && workoutLength <= _self.durationMax;
      });
    },

    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H[h ]mm[min]");
    },

    computeWidth(val, workoutFile) {
      const width = Math.floor((val / this.totalWorkoutLength(workoutFile)) * 100) - 1;

      return width > 1 ? width + "%" : "1px";
    },

    setMaxDuration() {
      const _self = this;

      this.durationMax = Math.max.apply(
        Math,
        this.workoutsList.map(function (workout) {
          return _self.totalWorkoutLength(workout.workoutFile);
        })
      );

      this.durationMaxStart = this.durationMax;
    },

    setMinDuration() {
      const _self = this;
      this.durationMin = Math.min.apply(
        Math,
        this.workoutsList.map(function (workout) {
          return _self.totalWorkoutLength(workout.workoutFile);
        })
      );

      this.durationMinStart = this.durationMin;
    },

    workoutBuilderHeight(workoutFile) {
      let maxPower = Math.max.apply(
        Math,
        workoutFile.map(function (o) {
          let onPower = typeof o.OnPower == "number" ? o.OnPower : 0;
          let offPower = typeof o.OffPower == "number" ? o.OffPower : 0;
          let power = typeof o.Power == "number" ? o.Power : 0;
          let powerLow = typeof o.PowerLow == "number" ? o.PowerLow : 0;
          let powerHigh = typeof o.PowerHigh == "number" ? o.PowerHigh : 0;
          return Math.max(onPower, offPower, power, powerLow, powerHigh);
        })
      );
      return Math.min(maxPower / 4, 35);
    },
    totalWorkoutLength(workoutFile) {
      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length;
    },

    selectWorkout(workout) {
      this.$emit("workout-selected", workout);
      window.scrollTo(0, 0);
    },
  },
  activated: async function () {
    const _self = this;
    this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;

    axios
      .get(this.$host + "/workout/blueprints2/v2")
      .then(function (response) {
        _self.workoutBlueprintsGlobal = response.data.global;
        _self.workoutBlueprintsUser = response.data.user;

        _self.allWorkoutsList = _self.workoutBlueprintsGlobal.concat(_self.workoutBlueprintsUser);

        _self.allWorkoutsList = _self.allWorkoutsList.filter(function (workout) {
          return workout.sport == "Radfahren";
        });

        _self.workoutsList = _self.allWorkoutsList;
        _self.workoutsListTimeFiltered = _self.workoutsList;
        _self.setMaxDuration();
        _self.setMinDuration();
        _self.loaded = true;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  mounted: async function () {
    const _self = this;

    this.$parent.loadCyclingState();

    this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;

    axios.get(this.$host + "/workout/nextbikeworkouts").then(function (res) {
      _self.nextWorkouts = res.data;
    });

    axios
      .get(this.$host + "/workout/blueprints2/v2")
      .then(function (response) {
        _self.workoutBlueprintsGlobal = response.data.global;
        _self.workoutBlueprintsUser = response.data.user;

        _self.allWorkoutsList = _self.workoutBlueprintsGlobal.concat(_self.workoutBlueprintsUser);

        _self.allWorkoutsList = _self.allWorkoutsList.filter(function (workout) {
          return workout.sport == "Radfahren";
        });

        _self.workoutsList = _self.allWorkoutsList;
        _self.workoutsListTimeFiltered = _self.workoutsList.sort(function (a, b) {
          var durationA = _self.totalWorkoutLength(a.workoutFile); // Groß-/Kleinschreibung ignorieren
          var durationB = _self.totalWorkoutLength(b.workoutFile); // Groß-/Kleinschreibung ignorieren
          if (durationA < durationB) {
            return -1;
          }
          if (durationA > durationB) {
            return 1;
          }

          // Namen müssen gleich sein
          return 0;
        });
        _self.setMaxDuration();
        _self.setMinDuration();
        _self.loaded = true;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  props: {},
};
</script>

<style scoped>
.filter-button {
  width: 100%;
}

.title {
  font-weight: bold;
  background: #ccc;
  color: #000;
}

.workoutBuilderBlock {
  display: inline-block;
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #ddd;
  outline: none; /* Remove outline */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border: 0;
  border-radius: 30px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgba(94, 110, 255, 0.97); /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgba(94, 110, 255, 0.97); /* Green background */
  border: 0;
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}
</style>
