<template>
  <div class="row">
    <div class="submenu col-xl-2 col-lg-3 d-none d-lg-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/training/dashboard">
              <div class="menu-entry">Dashboard</div></a
            >
          </div>
          <div class="col-lg-12">
            <a href="/#/training/kalender">
              <div class="menu-entry">Kalender</div></a
            >
          </div>

          <div class="col-lg-12">
            <a href="/#/training/liste">
              <div class="menu-entry">Trainingspläne</div>
            </a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/wissen">
              <div class="menu-entry active_submenu">Wissen</div>
            </a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/vorlagen">
              <div class="menu-entry">Workout-Vorlagen</div></a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-9 col-xl-10" style="margin-top: 0">
      <div class="row" v-if="!loaded">
        <div class="col-lg-12 top-spacer-100">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>

      <div class="row top-spacer-25">
        <div class="col-lg-4" v-for="m in data.slice(0, 24)" :key="m._id">
          <a v-bind:href="'/#/training/wissen/' + m._id" class="media_link">
            <div class="top-spacer-25" style="text-align: center">
              <vue-load-image>
                <img
                  slot="image"
                  style="width: 100%"
                  alt="Bild des Videos"
                  :src="`https://assets.pushinglimits.club/${m.imageIds[0]}`"
                />
                <div
                  slot="preloader"
                  class="spinner-border text-primary"
                  role="status"
                ></div>

                <div slot="error">Bild konnte nicht geladen werden</div>
              </vue-load-image>
              <h4 class="top-spacer-25">
                <strong>{{ m.title }}</strong>
              </h4>
            </div>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <button class="pull-left" v-if="page > 0" @click="loadPreviousPage()">
            Seite zurück
          </button>
        </div>
        <div class="col-lg-6">
          <button
            v-if="data.length > 24"
            class="pull-right"
            @click="loadNextPage()"
          >
            Nächste Seite
          </button>
        </div>
      </div>

      <div v-if="user && user.isAdmin">
        <hr />
        Admin:
        <a href="/#/training/wissen/create">Neu anlegen</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueLoadImage from "vue-load-image";

export default {
  name: "Media",
  components: {
    "vue-load-image": VueLoadImage,
  },
  props: {
    initPage: Number,
  },
  data: function () {
    return {
      data: [],
      page: 0,
      user: undefined,
      loaded: false,
    };
  },
  async mounted() {
    this.loaded = false;
    if (this.initPage) {
      this.page = this.initPage;
    }
    this.loadPage();

    this.user = (await axios.get(this.$host + "/user/me")).data;
  },
  methods: {
    async loadPage() {
      this.loaded = false;
      this.data = (
        await axios.get(this.$host + "/media/page/" + this.page)
      ).data;
      this.loaded = true;
    },
    loadNextPage() {
      if (this.data.length > 3) {
        this.page++;
        this.loadPage();
      }
    },
    loadPreviousPage() {
      if (this.page > 0) {
        this.page--;
        this.loadPage();
      }
    },
  },
};
</script>

<style scoped>
main {
  margin: auto;
  max-width: 800px;
}

.media {
  font-size: 18px;
}

.media_link {
  color: #000;
}
.media_link:hover {
  color: blue;
  text-decoration: none;
}
</style>
