import { render, staticRenderFns } from "./RadraumExerciseNew.vue?vue&type=template&id=3a44bdf0&scoped=true"
import script from "./RadraumExerciseNew.vue?vue&type=script&lang=js"
export * from "./RadraumExerciseNew.vue?vue&type=script&lang=js"
import style0 from "./RadraumExerciseNew.vue?vue&type=style&index=0&id=3a44bdf0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a44bdf0",
  null
  
)

export default component.exports