<template>
  <div class="row">
    <div class="col-lg-10">
      <canvas ref="graph" style="height: 300px" id="graph" width="100%"></canvas>
    </div>
    <div class="col-lg-2">
      <div class="row">
        <div class="col-lg-12 col-4">
          <h4 class="top-spacer-25">Kohlenhydrate</h4>
          <h4 style="font-size: 1.35rem">{{ kh_gesamt }}g</h4>
        </div>
        <div class="col-lg-12 col-4">
          <h4 class="top-spacer-25">Fette</h4>
          <h4 style="font-size: 1.35rem">{{ fett_gesamt }}g</h4>
        </div>
        <div class="col-lg-12 col-4">
          <h4 class="top-spacer-25">Energie</h4>
          <h4 style="font-size: 1.35rem">{{ energie_gesamt }}kcal</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  props: ["workouts"],
  watch: {
    workouts(newWorkouts) {
      this.localWorkouts = JSON.parse(JSON.stringify(newWorkouts));
    },
  },
  data() {
    return {
      localWorkouts: null,
      kh_gesamt: 0,
      fett_gesamt: 0,
      energie_gesamt: 0,

      chartData: {
        labels: this.labels,
      },

      options: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },

        plugins: {
          tooltip: {
            enabled: true,

            mode: "index",
            callbacks: {
              label: (context) => {
                // data for manipulation
                const val = context.formattedValue;

                const index = context.datasetIndex;
                let prefix = "";

                if (index == 0) {
                  prefix = "Kohlenhydrate (Trainingseinheit): ";
                } else if (index == 1) {
                  prefix = "Fette (Trainingseinheit): ";
                } else if (index == 2) {
                  prefix = "Kohlenhydrate (Summe): ";
                } else {
                  prefix = "Fette (Summe): ";
                }

                if (index == 0 || index == 2) {
                  prefix += Math.round(parseInt(val.replace(".", "")) / 4) + "g / ";
                }
                if (index == 1 || index == 3) {
                  prefix += Math.round(parseInt(val.replace(".", "")) / 9) + "g / ";
                }

                return prefix + val + "kcal";
              },
            },
          },
        },

        aspectRatio: 1,
        layout: {
          padding: 0,
          margin: 0,
        },
        animation: {
          duration: 0,
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0,
          },
        },

        scales: {
          yA: {
            display: true,
            position: "left",
            stacked: true,
            title: {
              display: true,
              text: "Gesamt (kcal)",
            },
            grid: {
              drawBorder: true,
              display: false,
            },
            ticks: {
              display: true,
              beginAtZero: true,
              callback: function (label) {
                return new Intl.NumberFormat("de-DE", {}).format(label);
              },
            },
          },
          yB: {
            position: "right",

            title: {
              display: true,
              text: "Trainingseinheit (kcal)",
            },
            stacked: true,
            display: true,
            beginAtZero: true,

            grid: {
              drawBorder: true,
              display: true,
            },
            ticks: {
              display: true,
              beginAtZero: true,
              callback: function (label) {
                return new Intl.NumberFormat("de-DE", {}).format(label);
              },
            },
          },

          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: true,
              maxTicksLimit: 6,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.localWorkouts = JSON.parse(JSON.stringify(this.workouts));

    let ctx = this.$refs.graph.getContext("2d");

    let i = 0;
    let fat = [];
    let cho = [];
    let fatSum = [];
    let choSum = [];
    let labels = [];
    let calories = 0;
    this.localWorkouts = this.localWorkouts.reverse();

    for (const workout of this.workouts) {
      calories = workout.total_calories;

      const fatCals = Math.round(calories * ((workout.fatPercentage || 50) / 100));
      const choCals = Math.round(calories * ((workout.choPercentage || 50) / 100));

      fatSum.push(fatCals + (fatSum[i - 1] || 0));
      choSum.push(choCals + (choSum[i - 1] || 0));

      fat.push(fatCals);
      cho.push(choCals);
      labels.push(this.$moment(workout.date).format("DD.MM.YYYY"));
      this.kh_gesamt = new Intl.NumberFormat("de-DE", {}).format(Math.round(choSum[choSum.length - 1] / 4));
      this.fett_gesamt = new Intl.NumberFormat("de-DE", {}).format(Math.round(fatSum[fatSum.length - 1] / 9));

      this.energie_gesamt = new Intl.NumberFormat("de-DE", {}).format(
        Math.round(Math.round(choSum[choSum.length - 1] / 4) * 4 + Math.round(fatSum[fatSum.length - 1] / 9) * 9)
      );

      i++;
    }

    this.chartData.labels = labels;
    this.chartData.datasets = [
      {
        data: cho,
        type: "bar",
        fill: false,
        borderColor: "rgba(0,0,0, 1)",
        backgroundColor: "rgba(0,0,0, 1)",
        borderWidth: 1,
        yAxisID: "yB",
        label: "Kohlenhydrate (Trainingseinheit)",
        stack: "Base",
        maxBarThickness: 10,
        borderRadius: 3,
      },
      {
        data: fat,
        type: "bar",
        fill: false,
        borderColor: "rgba(220,40,40, 1)",
        backgroundColor: "rgba(220,40,40, 1)",
        borderWidth: 1,
        yAxisID: "yB",
        stack: "Base",
        label: "Fette (Trainingseinheit)",
        maxBarThickness: 10,
        borderRadius: 3,
      },
    ];

    this.chartData.datasets.push({
      data: choSum,
      fill: true,
      borderColor: "rgba(40,40,200, 1)",
      backgroundColor: "rgba(40,40,200, 0.6)",
      borderWidth: 0,
      yAxisID: "yA",
      label: "Kohlenhydrate (Summe)",
    });
    this.chartData.datasets.push({
      data: fatSum,
      fill: true,
      borderColor: "rgba(40,200,40, 1)",
      backgroundColor: "rgba(40,200,40, 0.6)",
      borderWidth: 0,
      yAxisID: "yA",
      label: "Fette (Summe)",
    });

    const config = {
      type: "line",
      data: this.chartData,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yA = chart.scales.yA;
              let yB = chart.scales.yB;

              let ctx = chart.ctx;
              ctx.save();

              if (yA) {
                ctx.beginPath();
                ctx.moveTo(x, yA.top);
                ctx.lineTo(x, yA.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }

              if (yB) {
                ctx.beginPath();
                ctx.moveTo(x, yB.top);
                ctx.lineTo(x, yB.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }
            }
          },
        },
      ],
    };

    new Chart(ctx, config);
  },
};
</script>
