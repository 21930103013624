<template>
  <div style="overflow-x: hidden; margin: -15px; margin-top: 0">
    <div
      class="desktop-border-radius"
      :style="{
        height: '100%',
        width: '100%',
        background:
          'url(https://assets.pushinglimits.club/tridalle.webp) no-repeat ' +
          ($client.getClient() == 'pushinglimits' ? 'top' : 'right') +
          '/cover',
      }"
    >
      <div>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-7">
            <div class="white_wrapper light-shadow" style="margin: 25px">
              <img
                class="mainlogo"
                alt="PL logo"
                style="box-shadow: none; width: 180px"
                v-bind:src="
                  $client.getClient() == 'pushinglimits'
                    ? require('@/assets/images/PL-Club-Logo.png')
                    : require('@/assets/images/' + $client.getClient() + '.jpg')
                "
              />
              <!-- <span
                v-if="$client.getClient() != 'pushinglimits'"
                style="font-size: 30px; padding: 20px; margin: auto"
              >
                X</span
              >
              <img
                class="mainlogo"
                alt="PL logo"
                style="box-shadow: none; max-width: 130px"
                v-if="$client.getClient() !== 'pushinglimits'"
                v-bind:src="require('@/assets/images/PL-Club-Logo.png')"
              />-->
              <h2 class="top-spacer-25">{{ $t("Account anlegen") }}</h2>
              <!-- Scyence im pushinglimits.club -->

              <form @submit.prevent="register" class="top-spacer-25">
                <div v-if="attempted">
                  <div v-if="success">
                    <h5 style="color: green">
                      {{
                        $t(
                          "Dein Account wurde registriert! Wir haben dir eine Mail mit einem Link gesendet. Bitte öffne diesen, um deine Anmeldung zu bestätigen."
                        )
                      }}
                    </h5>
                    <br />
                    <h4>
                      {{ $t("Bitte prüfe deinen Spam-Ordner, falls die Mail nicht ankommt.") }}
                    </h4>
                    <br />
                  </div>
                  <div v-if="!success">
                    <h5 style="color: red">
                      {{
                        $t(
                          "Dein Account konnte nicht registriert werden! Bitte prüfe nochmal deine eMail und wähle ein Passwort mit mindestens 8 Zeichen."
                        )
                      }}<br /><br />
                      <span style="color: green">
                        {{ $t('Klicke auf "Zur Anmeldung" Wenn du schon einen Account registriert hast') }}</span
                      ><br /><br />

                      <a href="/#/login">{{ $t("Zur Anmeldung") }}</a
                      ><br /><br />

                      <span v-if="msg"><br />{{ msg }}</span>
                    </h5>
                  </div>
                </div>
                <div>
                  <input
                    ref="email"
                    id="email"
                    type="email"
                    placeholder="deine@email.de"
                    v-model="email"
                    name="email"
                    required
                  />
                </div>

                <div class="top-spacer-10">
                  <input
                    ref="password"
                    id="password"
                    type="password"
                    placeholder="Passwort"
                    v-model="password"
                    name="pw"
                    required
                  />
                </div>

                <div class="top-spacer-10">
                  <button type="submit" style="max-width: 100%; width: 300px">
                    {{ $t("Kostenlos Registrieren") }}
                  </button>
                </div>
                <div style="font-size: 12px; line-height: 1.3; color: #666" class="top-spacer-15">
                  <span style="max-width: 300px; display: table; margin: 0 auto; text-align: justify">
                    Mit Klick auf "Kostenlos Registrieren" stimmst du unseren
                    <a
                      style="color: #222"
                      href="https://www.iubenda.com/nutzungsbedingungen/44469436"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Nutzungsbedingungen</a
                    >
                    zu. In unserer
                    <a
                      style="color: #000"
                      href="https://www.iubenda.com/privacy-policy/44469436"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Datenschutzerklärung</a
                    >
                    erfährst du, wie deine Daten erfasst, verwendet und geteilt werden.</span
                  >
                </div>

                <p class="top-spacer-15" style="font-weight: bold">
                  Schon registriert?
                  <a href="/#/login">{{ $t("Zur Anmeldung") }}</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8" style="font-size: 16px; padding: 0 25px">
        <h2 class="section-title">
          {{ $t(" Deine All-in-One-Lösung für Triathleten, Radsportler, Läufer und Schwimmer.") }}
        </h2>

        <div class="feature-description">
          <strong>
            {{
              $t(
                "Optimiere dein Training mit dem Pushing Limits Club: Plane, analysiere und verbessere deine Leistung. Nutze professionelle Trainingspläne, trainiere im virtuellen Radraum, entdecke sportlergerechte Rezepte und profitiere von unseren Trainingsvideos."
              )
            }}
          </strong>
        </div>

        <h2 class="section-title accent">
          {{ $t("Bring dein Training auf ein neues Level") }}
        </h2>

        <div class="feature-row">
          <div class="feature-item">
            <img src="https://assets.pushinglimits.club/kalendar.webp" alt="Optimal geplantes Training" />
            <h3>{{ $t("Optimal geplantes Training") }}</h3>
            <p>
              {{
                $t(
                  "Plane dein Training im Kalender und nutze Top-Trainingspläne. Alle Pläne sind inklusive, für maximale Flexibilität ohne Zusatzkosten."
                )
              }}
            </p>
          </div>

          <div class="feature-item">
            <img src="https://assets.pushinglimits.club/auswertung.webp" alt="Detaillierte Auswertungen" />
            <h3>{{ $t("Detaillierte Auswertungen") }}</h3>
            <p>
              {{
                $t(
                  "Visualisiere und analysiere dein Training mit verschiedenen Charts. Wähle beliebige Zeiträume und siehe deine Fortschritte auf einen Blick."
                )
              }}
            </p>
          </div>

          <div class="feature-item">
            <img src="https://assets.pushinglimits.club/radraum.webp" alt="Trainiere im Radraum" />
            <h3>{{ $t("Trainiere im Radraum") }}</h3>
            <p>
              {{
                $t(
                  "Verbinde deinen Smarttrainer und fahre direkt los. Wähle aus vielen Workouts oder erstelle deine eigenen Einheiten."
                )
              }}
            </p>
          </div>

          <div class="feature-item">
            <img src="https://assets.pushinglimits.club/esszimmer.webp" alt="Bessere Ernährung" />
            <h3>{{ $t("Bessere Ernährung") }}</h3>
            <p>
              {{
                $t(
                  "Entdecke trainingsspezifische Rezepte im Esszimmer. Nährwerte werden automatisch an dein Gewicht angepasst."
                )
              }}
            </p>
          </div>

          <div class="feature-item">
            <img src="https://assets.pushinglimits.club/kraft.1196111d.webp" alt="Stärke deinen ganzen Körper" />
            <h3>{{ $t("Stärke deinen ganzen Körper") }}</h3>
            <p>
              {{
                $t(
                  "Ergänze dein Training mit Athletik-Übungen. Von Körpergewichtübungen über Stretching bis zu Yoga-Workouts."
                )
              }}
            </p>
          </div>
        </div>

        <h2 class="section-title accent">
          {{ $t("2 Wochen kostenlos testen!") }}
        </h2>
        <p class="trial-description">
          {{
            $t(
              "Teste den Pushing Limits Club risikofrei: Keine Kosten, kein Abo. Nach der Testphase entscheidest du über eine Mitgliedschaft. Monatsabo: 19,99€, Jahresabo: ca. 15€/Monat. Alle Features inklusive!"
            )
          }}
        </p>

        <div class="cta-container">
          <button @click="$scrollToTop()" class="cta-button">
            {{ $t("Jetzt kostenlos registrieren und testen") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";

export default {
  metaInfo: {
    title: "Pushing Limits Club - Account anlegen",
  },
  data() {
    return {
      email: "",
      password: "",
      success: false,
      attempted: false,
      platform: Capacitor.getPlatform(),
      msg: "",
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async register() {
      const _self = this;
      _self.attempted = false;
      _self.success = false;

      try {
        const data = {
          email: this.email || this.$refs.email.value,
          password: this.password || this.$refs.password.value,
          client: this.$client.getClient(),
        };

        await this.$store.dispatch("register", data);
        _self.attempted = true;
        _self.success = true;
      } catch (error) {
        _self.attempted = true;
        _self.success = false;
        _self.msg = error.response?.data?.message || "Registration failed";
        console.error("Registration error:", error);
      }
    },
  },
  errorCaptured(err) {
    console.error("Error in Register component:", err);
    this.attempted = true;
    this.success = false;
    return false;
  },
};
</script>

<style scoped>
a {
  color: blue;
}
input {
  width: 300px !important;
}

.section-title {
  margin-top: 50px;
  margin-bottom: 25px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.accent {
  color: #007bff;
  border-bottom: 2px solid #007bff;
  display: inline-block;
  padding-bottom: 5px;
}

.feature-description {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  text-align: center;
}

.feature-row {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
}

.feature-item {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  flex: 0 0 250px;
}

.feature-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.feature-item h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 14px;
}

.trial-description {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
}

.cta-container {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 75px;
}

.cta-button {
  font-size: 18px;
  padding: 15px 30px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.cta-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .feature-row {
    flex-wrap: wrap;
    justify-content: center;
  }

  .feature-item {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}
</style>
