<template>
  <div class="impressum">
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-6 white_wrapper">
        <h3 class="top-spacer-50">Mitglied im Pushing Limits Club werden</h3>
        <p class="top-spacer-25">
          Du kannst ein monatliches Abo über den Zahlungsdienstleister Paddle
          abschließen. Gegenbenfalls erscheint auf deiner Abrechnung Paddle und
          nicht der Pushing Limits Club.
        </p>
        <hr />

        <div style="padding-right: 20px">
          <button
            @click="checkout()"
            style="padding: 5px 20px"
            class="secondary"
          >
            Abo abschließen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Start",
  props: {
    msg: String,
  },
  mounted: function () {
    window.Paddle.Environment.set("sandbox");
    window.Paddle.Setup({ vendor: 163627 });
  },
  methods: {
    checkout: function () {
      window.Paddle.Checkout.open({
        product: 42516,
        email: "jan@test.de",
        passthrough: '{"user_id": 98765, "affiliation": "Acme Corp"}',
      });
    },
  },
};
</script>

<style>
.impressum {
  font-size: 16px;
}
</style>