<template>
  <div>
    <div v-for="workout in sortedWorkoutsOfDay()" :key="workout.id" class="workout-box">
      <div class="sport">
        <span class="sport-inner" v-bind:style="workout.durationIs ? 'font-weight:bold;margin-right:-20px' : ''"
          >{{ workout.name }}
        </span>
        <small style="float: right" class="numberCircle" v-if="workout.pss && workout.sport != 'Aktivität'">{{
          workout.pss
        }}</small>
      </div>

      <div
        class="row work"
        style="background: #ddd; padding: 7px 5px; height: 35px; display: flex; justify-content: center"
        v-if="workout.workoutFile && workout.workoutFile.length != 0 && workout.sport == 'Schwimmen'"
      >
        <span class="workoutBuilderBlock" v-for="value in workout.workoutFile" :key="value.id" v-bind:height="35">
          <svg
            v-if="value.type == 'SteadyState'"
            class="workoutBuilderBlock"
            v-bind:width="(value.Distance || 150) / 40"
            v-bind:height="35"
          >
            <rect
              v-bind:id="value.id"
              v-bind:width="(value.Distance || 150) / 40"
              v-bind:height="28"
              v-bind:style="'fill:' + colorForPower(value.Power)"
            />
          </svg>

          <svg
            v-if="value.type == 'Rest'"
            class="workoutBuilderBlock"
            v-bind:width="(value.Distance || 150) / 20"
            v-bind:height="35"
          >
            <rect
              v-bind:id="value.id"
              v-bind:width="(value.Distance || 150) / 20"
              v-bind:height="23"
              style="fill: rgba(94, 110, 255, 0.5)"
            />
          </svg>

          <span v-if="value.type == 'IntervalsRest'">
            <span>
              <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                <svg class="workoutBuilderBlock" v-bind:width="value.Distance / 40" v-bind:height="35">
                  <rect
                    v-bind:id="value.id"
                    v-bind:width="value.Distance / 40"
                    v-bind:height="35"
                    style="fill: darkred"
                  />
                </svg>
                <svg class="workoutBuilderBlock" v-bind:width="(value.Rest || 0) / 20" v-bind:height="35">
                  <rect
                    v-bind:id="value.id"
                    v-bind:width="(value.Rest || 0) / 20"
                    v-bind:height="23"
                    v-bind:style="'fill: rgba(94, 110, 255, 0.67)'"
                  />
                </svg>
              </span>
            </span>
          </span>
        </span>
      </div>

      <div
        class="row work"
        style="background: #ddd; padding: 7px 5px; height: 35px"
        v-if="workout.sport != 'Notiz' && workout.sport != 'Schwimmen'"
      >
        <div class="col-lg-12">
          <span v-if="!workout.workoutFile || workout.workoutFile.length == 0">
            <svg class="workoutBuilderBlock" v-bind:width="150" v-bind:height="18">
              <rect v-bind:width="100 + '%'" v-bind:height="50" v-bind:style="'fill: rgba(94, 110, 255, 0.97);'" />
            </svg>
          </span>
          <span v-for="value in workout.workoutFile" :key="value.id">
            <svg
              v-if="value.type == 'SteadyState'"
              class="workoutBuilderBlock"
              v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
              v-bind:height="workoutBuilderHeight(workout.workoutFile)"
            >
              <rect
                v-bind:id="value.id"
                v-bind:width="100 + '%'"
                v-bind:height="value.Power / 4"
                v-bind:style="'fill:' + colorForPower(value.Power)"
              />
            </svg>

            <svg
              v-if="value.type == 'Ramp'"
              class="workoutBuilderBlock"
              v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
              v-bind:height="workoutBuilderHeight(workout.workoutFile)"
            >
              <rect
                v-for="index in 20"
                :key="index"
                v-bind:id="value.id + '-' + index"
                v-bind:width="100 / 20 + '%'"
                v-bind:x="(100 / 20) * index - 5 + '%'"
                v-bind:height="getRampRectHeight(20, value.PowerLow / 4, value.PowerHigh / 4, index)"
                v-bind:style="'fill:' + colorForPower(getRampRectHeight(20, value.PowerLow, value.PowerHigh, index))"
              />
            </svg>

            <svg
              v-if="value.type == 'FreeRide'"
              class="workoutBuilderBlock"
              v-bind:width="value.Duration / 15"
              v-bind:height="40"
            >
              <rect
                v-bind:id="value.id"
                v-bind:width="value.Duration / 15"
                v-bind:height="40"
                style="fill: rgba(94, 110, 255, 0.5)"
              />
            </svg>

            <span v-if="value.type == 'IntervalsT'">
              <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                <svg
                  class="workoutBuilderBlock"
                  v-bind:width="computeWidth(value.OnDuration, workout.workoutFile)"
                  v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                >
                  <rect
                    v-bind:id="value.id"
                    v-bind:width="'100%'"
                    v-bind:height="value.OnPower / 4"
                    v-bind:style="'fill:' + colorForPower(value.OnPower)"
                  />
                </svg>
                <svg
                  class="workoutBuilderBlock"
                  v-bind:width="computeWidth(value.OffDuration, workout.workoutFile)"
                  v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                >
                  <rect
                    v-bind:id="value.id"
                    v-bind:width="'100%'"
                    v-bind:height="value.OffPower / 4"
                    v-bind:style="'fill:' + colorForPower(value.OffPower)"
                  />
                </svg>
              </span>
            </span>
          </span>
        </div>
      </div>

      <div v-bind:style="workout.durationIs ? 'font-weight:bold' : ''" v-if="workout.sport != 'Notiz'">
        <div style="margin-top: 5px">
          <span v-if="workout.sport == 'Kraft'"><img src="@/assets/images/strength.png" style="width: 22px" /></span>
          <span v-if="workout.sport == 'Laufen'"><img src="@/assets/images/run.png" style="width: 22px" /></span>
          <span v-if="workout.sport == 'Schwimmen'"><img src="@/assets/images/swim.png" style="width: 22px" /></span>
          <span v-if="workout.sport == 'Radfahren'"><img src="@/assets/images/bike.png" style="width: 22px" /></span>
          <span v-if="workout.sport == 'Aktivität'"
            ><img src="@/assets/images/activity.png" style="width: 22px"
          /></span>

          <span v-if="workout.time_zones_need_adjustment" style="font-size: 15px; color: red">
            <i class="fa fa-exclamation" aria-hidden="true"></i>
          </span>
        </div>
        <!--
          <span v-bind:style="{ color: colorForDuration(totalWorkoutLength(workout.workoutFile), workout.durationIs, workout.date ) }" v-if="!isInPast(workout.date)">{{formattedTime(totalWorkoutLength(workout.workoutFile))}}</span> 
          <span v-bind:style="{ color: colorForDuration(totalWorkoutLength(workout.workoutFile), workout.durationIs, workout.date ) }" v-if="isInPast(workout.date)"><strong>{{formattedTime(workout.durationIs || 0)}}</strong></span> |
            -->
        <span
          v-if="!workout.durationIs && !workout.durationShould && workout.workoutFile && workout.workoutFile.length > 0"
          >{{ formattedTime(totalWorkoutLength(workout.workoutFile)) }}
        </span>
        <span v-if="!workout.durationIs && workout.durationShould">
          {{ formattedTime(totalWorkoutLength(workout.workoutFile) || workout.durationShould) }}</span
        ><br />

        <span v-if="workout.sport == 'Laufen' && workout.workoutFile && !workout.distance">
          {{ computeDistance(workout.workoutFile) }}km
        </span>

        <span v-if="workout.youtubeEmbedLink" style="padding-right: 5px"> &#9658; Video </span>

        <span
          v-if="
            !(workout.sport == 'Laufen' && workout.workoutFile) &&
            workout.sport == 'Schwimmen' &&
            !workout.distance &&
            workout.distanceShould &&
            !workout.durationIs
          "
          >{{ Math.round(workout.distanceShould * 10) / 10 }}km</span
        >

        <span v-if="workout.durationIs">{{ formattedTime(workout.durationIs) }}</span>
        <span v-if="workout.paceAvg && workout.sport == 'Radfahren'">
          | {{ Math.round((60 / workout.paceAvg) * 10) / 10 }}km/h
        </span>
        <span v-if="workout.heartrateAvg">{{ workout.heartrateAvg }}bpm</span>
        <span v-if="workout.distance"><br />{{ Math.round(workout.distance * 10) / 10 }}km</span>
        <span v-if="workout.sport == 'Schwimmen' && workout.durationIs && workout.distance">
          | {{ kmhToSwimPace(workout.swimDurationActive || workout.durationIs, workout.distance) }}<small>/100m</small>
        </span>
        <span v-if="workout.powerInWatts"> | {{ Math.round(workout.powerInWatts * 10) / 10 }}w</span>
        <span v-if="workout.paceIs && workout.sport == 'Laufen'">
          | {{ computeAvgRunSpeedFromPace(workout.paceIs) }}m/km
        </span>

        <span v-if="workout.sport == 'Radfahren' || workout.sport == 'Laufen'">
          <!-- todo swim run kraft-->

          <span
            v-if="
              workout.sport == 'Radfahren' &&
              !workout.durationIs &&
              workout.workoutFile &&
              workout.workoutFile.length > 0
            "
          >
            Ø {{ computeAvgWatt(workout.workoutFile) }}w</span
          >
          <span
            v-if="
              workout.sport == 'Laufen' && !workout.durationIs && workout.workoutFile && workout.workoutFile.length > 0
            "
          >
            | {{ computeAvgRunSpeed(workout.workoutFile) }}m/km</span
          >
          <!-- <span v-if="workout.sport == 'Schwimmen'"> Ø {{computeAvgSwimSpeed(workout.workoutFile)}}m/km</span>--> </span
        ><br />
        <div v-if="workout.durationIs && !workout.distance">&nbsp;</div>

        <div class="row">
          <div
            class="col-lg-12"
            style="margin-top: 10px; padding: 0; margin-bottom: -7px"
            v-if="workout.durationIs > 0 && workout && workout.time_hit + workout.time_mit + workout.time_lit > 0"
          >
            <div class="row progress" style="height: 10px; margin: 0; padding: 0">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                v-bind:style="
                  'width:' +
                  timePercentage(workout.time_lit, workout.time_hit + workout.time_mit + workout.time_lit) +
                  '%'
                "
                v-bind:aria-valuenow="
                  timePercentage(workout.time_lit, workout.time_hit + workout.time_mit + workout.time_lit)
                "
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div
                class="progress-bar bg-info"
                role="progressbar"
                v-bind:style="
                  'background-color: rgb(232, 126, 4)!important;' +
                  'width:' +
                  timePercentage(workout.time_mit, workout.time_hit + workout.time_mit + workout.time_lit) +
                  '%'
                "
                v-bind:aria-valuenow="
                  timePercentage(workout.time_mit, workout.time_hit + workout.time_mit + workout.time_lit)
                "
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div
                class="progress-bar bg-danger"
                role="progressbar"
                v-bind:style="
                  'width:' +
                  timePercentage(workout.time_hit, workout.time_hit + workout.time_mit + workout.time_lit) +
                  '%'
                "
                v-bind:aria-valuenow="
                  timePercentage(workout.time_hit, workout.time_hit + workout.time_mit + workout.time_lit)
                "
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="top-spacer-10" style="line-height: 1.2; font-size: 14px; text-align: center">
        <span style="white-space: pre-wrap">{{ workout.description }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import calendar_utils from "@/js/calendar_utils";

export default {
  name: "workout-entry-not-draggable",
  components: {},
  data: function () {
    return {
      count: 0,
      list: new Array(250),
      ftp: this.thresholds.ftp,
      swimThreshold: this.thresholds.threshold_swim,
      runThreshold: this.thresholds.threshold_run,
    };
  },

  methods: {
    kmhToSwimPace(time, distance) {
      const sec100m = Math.round((time / 60 / (distance * 1000)) * 100 * 60);
      const min = Math.floor(sec100m / 60);
      const sec = sec100m % 60;
      return min + "m" + sec;
    },
    computeDistance(workoutFile) {
      return Math.round((this.totalWorkoutLength(workoutFile) / this.computeAvgRunSpeedSeconds(workoutFile)) * 10) / 10;
    },
    sortedWorkoutsOfDay: function () {
      return this.workoutsOfDay.workouts.slice().sort(function (a, b) {
        if (!a.orderOfDay) {
          a.orderOfDay = 0;
        }
        if (!b.orderOfDay) {
          b.orderOfDay = 0;
        }
        return a.orderOfDay - b.orderOfDay;
      });
    },
    isInPast: function (date) {
      return this.$moment(date).isBefore();
    },

    colorForDuration(durationShould, durationIs, date) {
      return calendar_utils.colorForDuration(durationShould, durationIs, date);
    },

    computeAvgWatt(workoutFile) {
      return calendar_utils.computeAvgPowerFromWorkoutFile(workoutFile, this.ftp);
    },

    computeAvgRunSpeedFromPace(pace) {
      return this.$moment().startOf("day").seconds(pace).format("mm:ss");
    },

    computeAvgRunSpeed(workoutFile) {
      return this.$moment()
        .startOf("day")
        .seconds(calendar_utils.computeSpeedFromWorkoutFile(workoutFile, this.runThreshold))
        .format("mm:ss");
    },

    computeAvgRunSpeedSeconds(workoutFile) {
      return calendar_utils.computeSpeedFromWorkoutFile(workoutFile, this.runThreshold);
    },

    computeAvgSwimSpeed(workoutFile) {
      return this.$moment()
        .startOf("day")
        .seconds(calendar_utils.computeSpeedFromWorkoutFile(workoutFile, this.swimThreshold))
        .format("mm:ss");
    },
    //TODO: DRY
    colorForPower(power) {
      if (power > 105) {
        return "darkred";
      } else if (power > 100) {
        return "brown";
      } else if (power > 95) {
        return "firebrick";
      } else if (power > 88) {
        return "darkgreen";
      } else if (power > 83) {
        return "green";
      } else {
        return "rgba(94, 110, 255, 0.97)";
      }
    },
    getRampRectHeight(numberOfBlocks, startIntensity, endIntensity, index) {
      let res = startIntensity - ((startIntensity - endIntensity) / numberOfBlocks) * index;

      return res;
    },
    totalWorkoutLength(workoutFile) {
      const _self = this;
      let length = workoutFile.reduce(function (prev, cur) {
        let distance = prev;
        let interval_distance = 0;

        if (!(cur.Distance > 0 || cur.OnDistance > 0 || cur.OffDistance > 0)) {
          distance +=
            (cur.Duration > 0 ? cur.Duration : 0) +
            (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0);
        }

        if (cur.Distance > 0) {
          distance += Math.round((cur.Distance / 1000) * (_self.runThreshold / (cur.Power / 100)));
        }

        if (cur.OnDistance > 0) {
          interval_distance = Math.round((cur.OnDistance / 1000) * (_self.runThreshold / (cur.OnPower / 100)));

          distance += interval_distance * cur.Repeat;
        }
        if (cur.OffDistance > 0) {
          interval_distance = Math.round((cur.OffDistance / 1000) * (_self.runThreshold / (cur.OffPower / 100)));

          distance += interval_distance * cur.Repeat;
        }

        return distance;
      }, 0);

      return length || 0;
    },
    workoutBuilderHeight(workoutFile) {
      let maxPower = Math.max.apply(
        Math,
        workoutFile.map(function (o) {
          let onPower = typeof o.OnPower == "number" ? o.OnPower : 0;
          let offPower = typeof o.OffPower == "number" ? o.OffPower : 0;
          let power = typeof o.Power == "number" ? o.Power : 0;
          let powerLow = typeof o.PowerLow == "number" ? o.PowerLow : 0;
          let powerHigh = typeof o.PowerHigh == "number" ? o.PowerHigh : 0;
          return Math.max(onPower, offPower, power, powerLow, powerHigh);
        })
      );
      return Math.min(maxPower / 4, 35);
    },
    timePercentage(seconds, totalTime) {
      if (totalTime > 0) {
        return (100 / totalTime) * seconds;
      } else return 0;
    },
    computeWidth(val, workoutFile) {
      const width = Math.ceil((val / this.totalWorkoutLength(workoutFile)) * 100) - 1;

      return width > 1 ? width + "%" : "1px";
    },

    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },
    selectCalendarWorkout(id) {
      this.$emit("wasClicked", id);
    },
    updateWorkoutDay(list, evt) {
      if (evt.added || evt.moved) {
        const newDate = this.$moment(list.date).startOf("day").toDate();
        const workoutId = (evt.added && evt.added.element._id) || evt.moved.element._id;

        this.$emit("workoutMoved", workoutId, newDate);

        let req = { date: newDate };
        if (evt.moved) {
          req.oldIndex = evt.moved.oldIndex;
          req.newIndex = evt.moved.newIndex;
        }

        const _self = this;
        axios
          .post(this.$host + "/workout/" + workoutId, req)
          .then(function () {
            _self.$emit("dataChanged");
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
  props: ["workoutsOfDay", "thresholds"],
};
</script>

<style scoped>
.workout-box {
  max-width: 250px;
  cursor: auto;
}
@media (max-width: 768px) {
  .workout-box {
    min-height: 50px;
  }
}
svg {
  transform: scaleY(-1);
}

.workout-parent {
  background: #e8e8e8;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-flow: center;
  white-space: nowrap;
  align-items: flex-end;
  justify-content: center;
  padding: 6px;
}

.workoutBuilderBlock {
  display: inline-block;
  /*margin-right:1px;*/
}

.work {
  max-height: 42px !important;
  overflow: hidden;
}

.sport {
  color: #000;
  padding-top: 5px;
}

.sport-inner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 19ch !important;
  display: inline-block;
}

.numberCircle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-top: 3px;

  border: 1px solid #000;
  text-align: center;

  font: 10px Arial, sans-serif;
}
</style>
