export function computeWeightedRegression(analyses) {
    if (!analyses || analyses.length === 0) return null;
  
    // Combine data from all analyses
    const combinedData = analyses.reduce((acc, analysis) => {
        if (!analysis.heartRateData) {
            console.warn(`Analysis ID ${analysis.id} is missing heartRateData.`);
            return acc; // Skip this analysis
        }

        analysis.heartRateData.forEach(data => {
            const key = data.bpm;
            if (!acc[key]) {
                acc[key] = {
                    sum: 0,
                    count: 0,
                    value: 0,
                    bpm: key
                };
            }
            // For running we use avgSpeed, for cycling avgPower
            const value = data.avgSpeed || data.avgPower;
            acc[key].sum += value * data.numberOfPoints;
            acc[key].count += data.numberOfPoints;
        });
        return acc;
    }, {});
  
    // Proceed only if combinedData has entries
    if (Object.keys(combinedData).length === 0) return null;
  
    // Calculate averages and prepare data for regression
    const regressionData = Object.values(combinedData)
        .map(point => {
            point.value = point.sum / point.count;
            return point;
        })
        .filter(point => point.count > 0);
  
    // Sort by BPM for visualization
    regressionData.sort((a, b) => a.bpm - b.bpm);
  
    // Function to compute regression with given points
    const computeRegression = (points) => {
        const weightedPoints = points.map(point => ({
            x: point.bpm,
            y: point.value,
            weight: point.count * Math.pow(point.bpm / 100, 2)
        }));

        //print only weights of points, no x or y
        console.log("weightedPoints", weightedPoints.map(p => p.weight));

        const totalWeight = weightedPoints.reduce((sum, p) => sum + p.weight, 0);
        const weightedMeanX = weightedPoints.reduce((sum, p) => sum + p.x * p.weight, 0) / totalWeight;
        const weightedMeanY = weightedPoints.reduce((sum, p) => sum + p.y * p.weight, 0) / totalWeight;

        let numerator = 0;
        let denominator = 0;
        weightedPoints.forEach(point => {
            const dx = point.x - weightedMeanX;
            numerator += point.weight * dx * (point.y - weightedMeanY);
            denominator += point.weight * dx * dx;
        });

        const slope = numerator / denominator;
        const intercept = weightedMeanY - slope * weightedMeanX;

        return { slope, intercept, weightedMeanY };
    };

    // First regression
    const firstRegression = computeRegression(regressionData);

    // Calculate residuals and remove outliers
    const residuals = regressionData.map(point => {
        const predicted = firstRegression.slope * point.bpm + firstRegression.intercept;
        return {
            ...point,
            residual: Math.abs(point.value - predicted)
        };
    });

    // Sort by residual and keep 95% of the points
    const sortedByResidual = [...residuals].sort((a, b) => b.residual - a.residual);
    const numPointsToRemove = Math.ceil(sortedByResidual.length * 0.07);
    const pointsToKeep = new Set(
        sortedByResidual
            .slice(numPointsToRemove)
            .map(point => point.bpm)
    );

    // Filter out outliers
    const filteredData = regressionData.filter(point => pointsToKeep.has(point.bpm));

    // Final regression with filtered data
    const { slope, intercept } = computeRegression(filteredData);

    // Calculate R-squared for final regression
    const points = filteredData.map(point => ({
        x: point.bpm,
        y: point.value,
        weight: point.count * Math.pow(point.bpm / 100, 2)
    }));

    const predictedValues = points.map(p => slope * p.x + intercept);
    const weightedSST = points.reduce((sum, p) => sum + p.weight * Math.pow(p.y - firstRegression.weightedMeanY, 2), 0);
    const weightedSSR = points.reduce((sum, p, i) => sum + p.weight * Math.pow(p.y - predictedValues[i], 2), 0);
    const rSquared = 1 - (weightedSSR / weightedSST);
  
    return {
        slope,
        intercept,
        rSquared,
        points: filteredData, // Now returning filtered data points
        regressionLine: {
            start: {
                x: Math.min(...points.map(p => p.x)),
                y: slope * Math.min(...points.map(p => p.x)) + intercept
            },
            end: {
                x: Math.max(...points.map(p => p.x)),
                y: slope * Math.max(...points.map(p => p.x)) + intercept
            }
        }
    };
}