<template>
  <div>
    <div class="row">
      <!--
      <div class="submenu col-lg-3 col-xl-2 d-none d-lg-block">
        <div class="width-wrap sticky-top">
          <div class="row top-spacer-25">
            <div class="col-lg-12">
              <a href="/#/kraftraum">
                <div class="menu-entry active_submenu">Erklärungen</div></a
              >
            </div>
            <div class="col-lg-12">
              <a href="/#/kraftraum">
                <div class="menu-entry">Ganzer Körper</div>
              </a>
            </div>
            <div class="col-lg-12">
              <a href="/#/kraftraum"> <div class="menu-entry">Core</div></a>
            </div>
          </div>
          <div class="col-lg-12">
            <a href="/#/kraftraum"> <div class="menu-entry">Beine</div></a>
          </div>
          <div class="col-lg-12">
            <a href="/#/kraftraum"> <div class="menu-entry">Arme</div></a>
          </div>
          <div class="col-lg-12">
            <a href="/#/kraftraum"> <div class="menu-entry">Dehnen</div></a>
          </div>
          <div class="col-lg-12">
            <a href="/#/kraftraum"> <div class="menu-entry">Yoga</div></a>
          </div>
        </div>
      </div>-->

      <div class="col-xl-12 col-lg-12">
        <div>
          <div
            :style="{
              height: '150px',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
            }"
          ></div>
          <!-- background: 'url(' + require('@/assets/images/kraft.webp') + ') 40% 20%',-->
        </div>
        <div class="white_wrapper light-shadow top-spacer-15">
          <div class="row">
            <div class="col-lg-3" style="text-align: center">
              <h3 class="top-spacer-25">Core I</h3>
              <div class="top-spacer-25">
                10 Minuten Action, die deine Körpermitte fit machen sollen. Alle
                Übungen können mit dem eigenen Körpergewicht ausgeführt
                werden.<br />
              </div>
              <div class="row">
                <div class="col-lg-12 top-spacer-25" style="text-align: center">
                  <div>
                    <i class="fa fa-clock-o" aria-hidden="true"></i>

                    20 Minuten
                  </div>
                </div>
                <div class="col-lg-12 top-spacer-25">Gibbet noch nicht!</div>
              </div>
            </div>
            <div class="col-lg-3 top-spacer-25">
              <img
                src="https://assets.pushinglimits.club/kraft.1196111d.jpg"
                style="width: 100%"
              />
            </div>

            <div class="col-lg-3" style="text-align: center">
              <h3 class="top-spacer-25">HardCore</h3>
              <div class="top-spacer-25">
                10 Minuten Action, die deine Körpermitte fit machen sollen. Alle
                Übungen können mit dem eigenen Körpergewicht ausgeführt
                werden.<br />
              </div>
              <div class="row">
                <div class="col-lg-12 top-spacer-25" style="text-align: center">
                  <div>
                    <i class="fa fa-clock-o" aria-hidden="true"></i>

                    20 Minuten
                  </div>
                </div>
                <div class="col-lg-12 top-spacer-25">Gibbet noch nicht!</div>
              </div>
            </div>
            <div class="col-lg-3 top-spacer-25">
              <img
                src="https://assets.pushinglimits.club/kraft.1196111d.jpg"
                style="width: 100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Kraftraum",
};
</script>

<style scoped>
.number {
  padding: 5px 5px;
  background: #fff;
  color: #000;
  font-size: 25px;
}
.black-background {
  padding: 5px 5px;
  background: #000;
  color: #fff;
  font-size: 25px;
}

.start:hover {
  text-decoration: none;
}
</style>
