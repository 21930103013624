<template>
  <div class="impressum">
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-6 white_wrapper">
        <h3 class="top-spacer-50">
          {{ t("Einschränkung der Nutzung auf EWR und Schweiz") }}
        </h3>
        {{
          t(
            "Die Anwendung richtet sich ausschließlich an Nutzer des europäischen Wirtschaftsraums und der Schweiz. Die Nutzung in anderen Ländern ist nicht erlaubt."
          )
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Start",
  props: {
    msg: String,
  },
};
</script>

<style>
.impressum {
  font-size: 16px;
}
</style>
