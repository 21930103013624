import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import Axios from "axios";
import moment from "moment-timezone";
import FlashMessage from "@smartweb/vue-flash-message";
import "core-js/stable";
import "regenerator-runtime/runtime";

import { LMap, LTileLayer, LCircle, LPolyline } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import annotationPlugin from "chartjs-plugin-annotation";
import chartTrendline from "chartjs-plugin-trendline";
import ChartStreaming from "chartjs-plugin-streaming";
// import * as Regressions from "chartjs-plugin-regression";
import "chartjs-adapter-date-fns";
import { SplashScreen } from "@capacitor/splash-screen";
import { Capacitor } from "@capacitor/core";
import TextareaAutosize from "vue-textarea-autosize";
import VueMeta from "vue-meta";

import { defineCustomElements } from "@ionic/pwa-elements/loader";
import i18n from "./i18n";

import {
  Chart,
  PieController,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  LineController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  Filler,
  Legend,
  Title,
  Tooltip,
  ArcElement,
} from "chart.js";

// Chart.register(Regressions);

Chart.register(
  LineElement,
  BarElement,
  PointElement,
  BarController,
  PieController,
  LineController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  ArcElement,
  Filler,
  Legend,
  Title,
  Tooltip
);

Chart.register(ChartStreaming);

Chart.defaults.set("plugins.streaming", {
  duration: 30000,
});

Chart.register(annotationPlugin);

import zoomPlugin from "chartjs-plugin-zoom";

Chart.register(zoomPlugin);

import VueCarousel from "vue-carousel";

Vue.use(VueCarousel);
Vue.use(TextareaAutosize);

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

Vue.component("VueSlider", VueSlider);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Chart.register(chartTrendline);

Vue.component("LMap", LMap);
Vue.component("LCircle", LCircle);
Vue.component("LTileLayer", LTileLayer);
Vue.component("LPolyline", LPolyline);

Vue.use(FlashMessage);

// TODO: suppress nur in Produktion
moment.suppressDeprecationWarnings = true;
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

if (Capacitor.getPlatform() == "web") {
  Axios.defaults.withCredentials = true;
}

Vue.prototype.$http = Axios;

const host = window.location.host;
const parts = host.split(".");

window.onerror = function (msg, url, line, col, error) {
  console.error("Unhandled error:", msg, "URL:", url, "Line:", line, "Column:", col, "Error object:", error);
  return true; // prevents default browser behavior
};

Vue.prototype.$client = {
  _vm: new Vue({
    data: {
      client: localStorage.getItem("client") || "pushinglimits",
    },
  }),
  setClient(cli) {
    this._vm.$data.client = cli;
  },
  getClient() {
    return this._vm.$data.client;
  },
};

if (parts[0] == "scyence") {
  Vue.prototype.$client.setClient("scyence");
}

Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);

if (window.location.origin) {
  Vue.prototype.$host = window.location.origin + "/api";
  if (
    Vue.prototype.$host == "http://localhost:8080/api" ||
    Vue.prototype.$host == "http://scyence.localhost:8080/api"
  ) {
    console.log("Set correct localhost port");
    Vue.prototype.$host = "http://localhost:4000";
  }
}

const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}

if (Capacitor.getPlatform() == "ios" || Capacitor.getPlatform() == "android") {
  document.addEventListener("deviceready", onDeviceReady, false);

  console.log("Set mobile API Endpoint");
  Vue.prototype.$host = "https://pushinglimits.club/api";
} else {
  onDeviceReady();
}

function onDeviceReady() {
  const token = localStorage.getItem("token");
  if (token) {
    if (window && window.cordova) {
      console.log("Cordova available!");
      window.cordova.plugin.http.setHeader("Authorization", token); // TODO HOstname als ersten Parameter setzen?
    }
  }

  Vue.prototype.$http.interceptors.response.use((response) => {
    let X_JWT_REFRESH = response.headers["x-jwt-refresh"];
    if (X_JWT_REFRESH) {
      localStorage.setItem("token", X_JWT_REFRESH);
      Vue.prototype.$http.defaults.headers.common["Authorization"] = X_JWT_REFRESH;
      if (window && window.cordova) {
        window.cordova.plugin.http.setHeader("Authorization", X_JWT_REFRESH); // TODO HOstname als ersten Parameter setzen?
      } //https://github.com/silkimen/cordova-plugin-advanced-http
    }
    return response;
  });
}

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("err: " + error, error);
    console.log("data:", error.response.data);
    if (error.response.status === 401) {
      localStorage.setItem("token", "");

      router.push("/login");
    }
    return Promise.reject(error);
  }
);

// apply interceptor on response

Vue.prototype.$http.interceptors.response.use(
  (response) => response,
  function (error) {
    console.log("Err: " + error, error);
    // if has response show the error
    if (error.response) {
      if (error.response.status == 401) {
        Vue.prototype.flashMessage.show({
          status: "info",
          title: "Hinweis",
          time: 3500,
          message: "Bitte melde dich neu an",
          icon: false,
        });
      } else if (
        error.response.status != 404 &&
        ((error.response.data.errors && error.response.data.errors[0].msg) || error.response.data.message)
      ) {
        // Zeige Fehler nur, wenn auch Nachricht verfuegbar ist
        Vue.prototype.flashMessage.show({
          icon: false,
          status: "error",
          title: "Achtung",
          time: 5500,
          message: (error.response.data.errors && error.response.data.errors[0].msg) || error.response.data.message,
        });
      }
      return Promise.reject(error);
    }
  }
);

Vue.prototype.$window = window;

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY");
  }
});

async () => await SplashScreen.hide();

// Custom error logging function
function logError(error, context = '') {
  console.error(`Error in ${context}:`, error);
  console.error('Error stack:', error.stack);
  // You can add additional logging logic here, such as sending errors to your server
}

// Global error handler
Vue.config.errorHandler = (err, vm, info) => {
  logError(err, `Vue error in ${vm.$options.name || 'unnamed component'}`);
  console.error('Error info:', info);
};

// Unhandled promise rejection handler
window.addEventListener('unhandledrejection', event => {
  logError(event.reason, 'Unhandled promise rejection');
});

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

// Add this import
import 'bootstrap-icons/font/bootstrap-icons.css'
