<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>
<script>
import { Chart } from "chart.js";

export default {
  props: ["from", "until", "done"],
  watch: {
    from(newFrom) {
      this.localFrom = newFrom;
    },
  },
  data: function () {
    return {
      workouts: [],
      loadOnlydone: this.done,
      localFrom: null,
      chartdata: {
        labels: [],
        datasets: [
          {
            label: "Schwimmen",
            backgroundColor: "#255f85",
            data: [],
            borderRadius: 3,
          },
          {
            label: "Rad",
            backgroundColor: "#E9724C",
            data: [],
            borderRadius: 3,
          },
          {
            label: "Laufen",
            backgroundColor: "#FFC857",
            data: [],
            borderRadius: 3,
          },
          {
            label: "Athletik!",
            backgroundColor: "#481D24",
            data: [],
            borderRadius: 3,
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          annotation: {
            drawTime: "afterDraw",
            annotations: {
              line1: {
                type: "line",

                borderColor: "black",
                borderWidth: 2,
                xScaleID: "xAxes",
                yScaleID: "yAxes",
                content: "FTP",
                borderDash: [2, 2],
                xMin: -1,
                xMax: -1,
              },
            },
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "Belastung",
            position: "left",
          },
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                var datasetLabel = context.dataset.label + ": " + Math.round(context.parsed.y * 10) / 10 + " Load";
                return datasetLabel;
              },
            },
          },
        },
        scales: {
          yAxes: {
            stacked: true,
            ticks: {
              maxTicksLimit: 10,
              beginAtZero: true,
              callback: function (value) {
                return Math.round(value);
              },
            },
          },
          xAxes: {
            gridLines: {
              display: false,
            },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 20,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  async mounted() {
    this.localFrom = this.from;
    let ctx = this.$refs.graph.getContext("2d");

    this.workouts = this.done;
    this.computeChartData();
    const config = {
      type: "bar",
      data: this.chartdata,
      options: this.options,
    };
    new Chart(ctx, config);
    this.$emit("chartRendered");
  },

  methods: {
    formatDate: function (value) {
      return this.$moment(value).format("DD.MM");
    },
    getDayOfWeek: function (week, day) {
      return this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(day - 1, "d");
    },

    computeChartData: function () {
      let counter = 0;
      while (this.localFrom.isSameOrBefore(this.until, "day")) {
        counter++;
        let tomorrow = this.$moment(this.localFrom);
        tomorrow.add(1, "d");

        if (tomorrow.isSame(this.$moment(), "day")) {
          this.options.plugins.annotation.annotations.line1.xMin = counter;
          this.options.plugins.annotation.annotations.line1.xMax = counter;
        }

        let workoutsOfDate = this.workouts.filter((workout) => {
          return (
            this.$moment(workout.date).isSameOrAfter(this.localFrom) &&
            this.$moment(workout.date).isBefore(this.$moment(tomorrow))
          );
        });

        let distanceSwim = 0;
        let distanceBike = 0;
        let distanceRun = 0;
        let distanceKraft = 0;

        //TODO: Variablen umnennen...
        workoutsOfDate.forEach((workout) => {
          let load = 0;
          if (tomorrow.isSameOrBefore(this.$moment().add(1, "d"), "day")) {
            load = workout.pss;
          } else {
            load = workout.loadEstimate;
          }

          let distanceOfWorkout = load;
          if (workout.sport == "Laufen") {
            distanceRun += distanceOfWorkout;
          }
          if (workout.sport == "Radfahren") {
            distanceBike += distanceOfWorkout;
          }
          if (workout.sport == "Schwimmen") {
            distanceSwim += distanceOfWorkout;
          }
          if (workout.sport == "Kraft") {
            distanceKraft += distanceOfWorkout;
          }
        });

        this.chartdata.labels.push(this.formatDate(this.localFrom));
        this.chartdata.datasets[0].data.push(Math.round(distanceSwim));
        this.chartdata.datasets[1].data.push(Math.round(distanceBike));
        this.chartdata.datasets[2].data.push(Math.round(distanceRun));
        this.chartdata.datasets[3].data.push(Math.round(distanceKraft));

        this.localFrom = this.localFrom.add(1, "d");
      }

      return this.chartdata;
    },
  },
};
</script>
