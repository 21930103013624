<template>
  <div class="rating-distribution-chart">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'RatingDistributionChart',
  props: ['entries'],
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.createChart();
  },
  watch: {
    entries: {
      handler() {
        this.updateChart();
      },
      deep: true
    }
  },
  methods: {
    createChart() {
      const ctx = this.$refs.chart.getContext('2d');
      const ratingData = this.calculateRatingDistribution();

      this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Gut', 'Okay', 'Schlecht'],
          datasets: [{
            data: [ratingData.Good, ratingData.Okay, ratingData.Bad],
            backgroundColor: ['#28a745', '#ffc107', '#dc3545'],
          }],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
            },
            title: {
              display: true,
              text: 'Bewertungsverteilung',
            },
          },
        },
      });
    },
    updateChart() {
      if (this.chart) {
        const ratingData = this.calculateRatingDistribution();
        this.chart.data.datasets[0].data = [ratingData.Good, ratingData.Okay, ratingData.Bad];
        this.chart.update();
      }
    },
    calculateRatingDistribution() {
      return this.entries.reduce((acc, entry) => {
        acc[entry.rating]++;
        return acc;
      }, { Good: 0, Okay: 0, Bad: 0 });
    },
  },
};
</script>