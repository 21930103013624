<template>
  <div class="metabolic-profile">
    <div class="sport-selector">
      <v-btn-toggle v-model="selectedSport" mandatory>
        <v-btn value="running">Running</v-btn>
        <v-btn value="cycling">Cycling</v-btn>
      </v-btn-toggle>
    </div>

    <div class="metrics-cards">
      <v-card class="metric-card">
        <v-card-title>VO2max Estimate</v-card-title>
        <v-card-text>
          <div class="metric-value">{{ formatVO2max(profile.averageVo2Max) }}</div>
          <div class="metric-unit">ml/kg/min</div>
        </v-card-text>
      </v-card>

      <v-card class="metric-card">
        <v-card-title>VLaMax Estimate</v-card-title>
        <v-card-text>
          <div class="metric-value">{{ formatVLaMax(profile.averageVLaMax) }}</div>
          <div class="metric-unit">mmol/L/s</div>
        </v-card-text>
      </v-card>
    </div>

    <div class="chart-container" v-if="chartData">
      <v-card>
        <v-card-title class="d-flex align-center">
          Heart Rate Reserve vs {{ selectedSport === "running" ? "Pace" : "Power" }}
          <v-spacer></v-spacer>
          <v-btn-toggle v-model="chartView" mandatory dense>
            <v-btn value="scatter">Scatter</v-btn>
            <v-btn value="regression">Regression</v-btn>
          </v-btn-toggle>
        </v-card-title>
        <v-card-text>
          <canvas ref="chart"></canvas>
        </v-card-text>
      </v-card>
    </div>

    <div class="metabolic-insights" v-if="profile.averageVo2Max">
      <v-card>
        <v-card-title>Metabolic Insights</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <h3>Aerobic Capacity</h3>
              <p>
                Your estimated VO2max of {{ formatVO2max(profile.averageVo2Max) }} ml/kg/min indicates
                {{ getVO2maxLevel(profile.averageVo2Max) }}.
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <h3>Anaerobic Capacity</h3>
              <p>
                Your VLaMax of {{ formatVLaMax(profile.averageVLaMax) }} mmol/L/s suggests
                {{ getVLaMaxLevel(profile.averageVLaMax) }}.
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <div class="recent-efforts">
      <v-data-table
        :headers="effortHeaders"
        :items="recentEfforts"
        :items-per-page="5"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.date`]="{ item }">
          {{ formatDate(item.date) }}
        </template>
        <template #[`item.sport`]="{ item }">
          {{ item.sport }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn small color="primary" @click="viewEffort(item)"> View </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js/auto";
import axios from "axios";

export default {
  name: "MetabolicProfile",

  data: () => ({
    selectedSport: "running",
    profile: {
      averageVo2Max: null,
      averageVLaMax: null,
    },
    recentEfforts: [],
    chart: null,
    chartData: null,
    effortHeaders: [
      { text: "Date", value: "date" },
      { text: "VO2max", value: "estimatedVo2Max" },
      { text: "VLaMax", value: "estimatedVLaMax" },
      { text: "Valid Data Points", value: "validDataPoints" },
    ],
    chartView: "scatter",
    loading: false,
    error: null,
  }),

  computed: {
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                const point = context.raw;
                return `${this.selectedSport === "cycling" ? "Power" : "Pace"}: ${point.y.toFixed(
                  1
                )} | HR Reserve: ${point.x.toFixed(1)}%`;
              },
            },
          },
          legend: {
            position: "top",
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Heart Rate Reserve (%)",
            },
            min: 0,
            max: 100,
          },
          y: {
            title: {
              display: true,
              text: this.selectedSport === "cycling" ? "Power (watts)" : "Pace (m/s)",
            },
            min: 0,
          },
        },
      };
    },
  },

  watch: {
    selectedSport: {
      handler: "loadData",
      immediate: true,
    },
    chartView: "updateChart",
  },

  methods: {
    async loadData() {
      try {
        const [profileResponse, effortsResponse] = await Promise.all([
          axios.get(`/api/efforts/profile?sport=${this.selectedSport}`),
          axios.get(`/api/efforts?sport=${this.selectedSport}`),
        ]);

        this.profile = profileResponse.data;
        this.recentEfforts = effortsResponse.data;
        this.updateChart();
      } catch (error) {
        console.error("Error loading metabolic data:", error);
      }
    },

    updateChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      const ctx = this.$refs.chart.getContext("2d");
      const datasets = [];

      if (this.chartView === "scatter") {
        // Add scatter plot data
        datasets.push(
          ...this.recentEfforts.map((effort) => ({
            label: this.formatDate(effort.date),
            data: effort.heartRateBuckets
              .filter((bucket) => bucket.powerValues.length > 0 || bucket.paceValues.length > 0)
              .map((bucket) => ({
                x: (bucket.hrReserveMin + bucket.hrReserveMax) / 2,
                y:
                  this.selectedSport === "cycling"
                    ? this.calculateMean(bucket.powerValues)
                    : this.calculateMean(bucket.paceValues),
              })),
            pointRadius: 3,
            borderWidth: 1,
          }))
        );
      } else {
        // Add regression line
        const regressionData = this.calculateRegressionLine();
        datasets.push({
          label: "Metabolic Profile",
          data: regressionData,
          pointRadius: 0,
          borderWidth: 2,
          borderColor: "#2196F3",
          fill: false,
        });
      }

      this.chart = new Chart(ctx, {
        type: this.chartView === "scatter" ? "scatter" : "line",
        data: { datasets },
        options: this.chartOptions,
      });
    },

    calculateMean(values) {
      if (!values || values.length === 0) return null;
      return values.reduce((sum, val) => sum + val, 0) / values.length;
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },

    formatVO2max(value) {
      return value ? value.toFixed(1) : "N/A";
    },

    formatVLaMax(value) {
      return value ? value.toFixed(3) : "N/A";
    },

    calculateRegressionLine() {
      // Combine all valid data points
      const allPoints = this.recentEfforts.flatMap((effort) =>
        effort.heartRateBuckets
          .filter((bucket) => bucket.powerValues.length > 0 || bucket.paceValues.length > 0)
          .map((bucket) => ({
            x: (bucket.hrReserveMin + bucket.hrReserveMax) / 2,
            y:
              this.selectedSport === "cycling"
                ? this.calculateMean(bucket.powerValues)
                : this.calculateMean(bucket.paceValues),
          }))
      );

      // Calculate regression line
      const n = allPoints.length;
      const sumX = allPoints.reduce((sum, p) => sum + p.x, 0);
      const sumY = allPoints.reduce((sum, p) => sum + p.y, 0);
      const sumXY = allPoints.reduce((sum, p) => sum + p.x * p.y, 0);
      const sumXX = allPoints.reduce((sum, p) => sum + p.x * p.x, 0);

      const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
      const intercept = (sumY - slope * sumX) / n;

      // Generate points for the line
      return Array.from({ length: 101 }, (_, i) => ({
        x: i,
        y: slope * i + intercept,
      }));
    },

    getVO2maxLevel(vo2max) {
      if (!vo2max) return "insufficient data";
      if (vo2max > 60) return "excellent aerobic fitness";
      if (vo2max > 50) return "good aerobic fitness";
      if (vo2max > 40) return "moderate aerobic fitness";
      return "developing aerobic fitness";
    },

    getVLaMaxLevel(vlamax) {
      if (!vlamax) return "insufficient data";
      if (vlamax > 0.7) return "high anaerobic capacity";
      if (vlamax > 0.5) return "moderate anaerobic capacity";
      return "endurance-oriented metabolism";
    },
  },
};
</script>

<style scoped>
.metabolic-profile {
  padding: 20px;
}

.sport-selector {
  margin-bottom: 20px;
}

.metrics-cards {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.metric-card {
  flex: 1;
}

.metric-value {
  font-size: 24px;
  font-weight: bold;
}

.metric-unit {
  font-size: 14px;
  color: #666;
}

.chart-container {
  margin-bottom: 20px;
  height: 400px;
}

.recent-efforts {
  margin-top: 20px;
}

.metabolic-insights {
  margin: 20px 0;
}

.metabolic-insights h3 {
  margin-bottom: 10px;
  color: #2196f3;
}

.metabolic-insights p {
  line-height: 1.5;
  color: #666;
}
</style>
