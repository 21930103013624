<template>
  <div class="row">
    <div class="submenu col-xl-2 col-lg-3 d-none d-md-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/training/dashboard">
              <div class="menu-entry">Dashboard</div></a
            >
          </div>
          <div class="col-lg-12">
            <a href="/#/training/kalender">
              <div class="menu-entry">Kalender</div></a
            >
          </div>

          <div class="col-lg-12">
            <a href="/#/training/liste">
              <div class="menu-entry">Trainingspläne</div>
            </a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/wissen">
              <div class="menu-entry active_submenu">Wissen</div>
            </a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/upload">
              <div class="menu-entry">Upload</div></a
            >
          </div>

          <div class="col-lg-12">
            <a href="/#/training/vorlagen">
              <div class="menu-entry">Workout-Vorlagen</div></a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9 col-xl-10 top-spacer-50">
      <div class="col-lg-12">
        <div class="row" v-if="!loaded">
          <div class="col-lg-12">
            <div class="spinner-border text-primary" role="status"></div>
          </div>
        </div>

        <div class="row" v-if="!m">
          <div class="col-lg-12">
            <h4>Keine Inhalte gefunden</h4>
          </div>
        </div>

        <div class="row" v-if="m">
          <div class="col-lg-4 white_wrapper" style="text-align: justify">
            <div class="row">
              <div class="col-lg-12">
                <a href="/#/training/wissen"
                  ><button style="margin: 0; padding: 7px 15px">
                    ❮ Zurück zur Übersicht
                  </button></a
                >
              </div>
            </div>
            <h4 class="top-spacer-25">
              <strong>{{ m.title }}</strong>
            </h4>
            <br />
            <div v-html="m.text_body"></div>
            <br />
            <a
              v-if="user && user.isAdmin"
              style="color: blue"
              v-bind:href="'/#/training/wissen/' + m._id + '/update'"
              >Admin: Bearbeiten</a
            >
          </div>
          <div class="col-lg-8 white_wrapper">
            <iframe
              width="100%"
              height="430px"
              v-bind:src="m.youtube_link"
              frameborder="0"
              allowfullscreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
            ></iframe>
          </div>
        </div>
        <div class="row top-spacer-50">
          <div class="col-lg-12" v-if="similar.length > 0">
            <h4>Ähnliche Videos</h4>
            <div class="row">
              <div
                class="col-lg-3"
                v-for="m in similar.slice(0, 4)"
                :key="m._id"
              >
                <a v-bind:href="'/#/media/' + m._id" class="media_link">
                  <div class="top-spacer-25" style="text-align: center">
                    <img
                      style="width: 100%"
                      alt="Bild des Videos"
                      :src="`https://assets.pushinglimits.club/${m.imageIds[0]}`"
                    />
                    <h4 class="top-spacer-25">
                      <strong>{{ m.title }}</strong>
                    </h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row top-spacer-50" v-if="!similarLoaded">
          <div class="col-lg-12">
            <div class="spinner-border text-primary" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ShowMedia",
  props: {
    id: String,
  },
  data: function () {
    return {
      m: undefined,
      similar: [],

      user: undefined,
      loaded: false,
      similarLoaded: false,
    };
  },
  async mounted() {
    console.log(this.id);
    this.loaded = false;
    if (this.initPage) {
      this.page = this.initPage;
    }
    this.loadMedia();

    this.user = (await axios.get(this.$host + "/user/me")).data;
  },
  methods: {
    async loadMedia() {
      this.loaded = false;
      this.similarLoaded = false;

      this.m = (await axios.get(this.$host + "/media/" + this.id)).data;
      this.loaded = true;

      let sim = (
        await axios.get(this.$host + "/media/category/" + this.m.main_category)
      ).data;
      this.similarLoaded = true;
      // Nicht geöffnetes als ähnlichen Eintrag anzeigen
      this.similar = sim.filter((x) => x._id != this.m._id);
    },
  },
};
</script>

<style scoped>
main {
  margin: auto;
  max-width: 800px;
}

.media {
  font-size: 18px;
}

.padded {
  padding: 50px;
}
</style>
