<template>
  <div>
    <div class="row">
      <div class="submenu col-xl-2 d-none d-xl-block">
        <div class="width-wrap sticky-top">
          <div class="row top-spacer-25">
            <div class="col-lg-12">
              <a href="/#/esszimmer"> <div class="menu-entry">Rezepte</div></a>
            </div>
            <div class="col-lg-12">
              <a href="/#/esszimmer/foodlog"><div class="menu-entry">Foodlog</div></a>
            </div>

            <div class="col-lg-12">
              <a href="/#/esszimmer/plan">
                <div class="menu-entry active_submenu">Vorschläge</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-10">
        <div
          class="row top-spacer-25"
          style="
            background: #f6c050;
            padding-top: 20px;
            padding-bottom: 12px;
            letter-spacing: 0.3rem;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          "
        >
          <div class="col-lg-12">
            <img
              src="https://assets.pushinglimits.club/wtf_trans.png"
              class="pull-left d-none d-md-block"
              style="box-shadow: none !important; max-width: 170px; width: 80%; margin-top: -7px"
              alt="where is the food?"
            />
            TRAININGSSPEZIFISCHE REZEPTE FÜR AUSDAUERSPORTLER
          </div>
        </div>

        <div
          class="row white_wrapper"
          style="padding: 30px 0; padding-top: 0; border-top-left-radius: 0px; border-top-right-radius: 0px"
        >
          <div class="col-lg-9">
            <div class="spinner-border text-primary top-spacer-100" v-if="!loaded" role="status"></div>

            <div class="centered top-spacer-25 d-lg-none">
              <h2 style="font-size: 18px">Lieber die Rezepte selber aussuchen?</h2>
              <a href="/#/esszimmer">
                <button style="padding: 5px 15px" class="button-b top-spacer-15">
                  <span style="display: inline-block; transform: rotate(180deg)">➜</span>
                  Zu allen Rezepten
                </button>
              </a>
            </div>

            <div class="row top-spacer-50" v-if="loaded && workouts.length > 0">
              <div class="col-lg-4"></div>
              <div class="col-6 col-lg-4">
                <h5 style="border-bottom: 1px solid #222; padding-bottom: 15px">Vor dem Training</h5>
              </div>
              <div class="col-6 col-lg-4">
                <h5 style="border-bottom: 1px solid #222; padding-bottom: 15px">Nach dem Training</h5>
              </div>
            </div>

            <div v-if="workouts.length == 0 && loaded" class="top-spacer-100">
              <h5>Noch keine Workouts mit Mahlzeiten geplant</h5>
              <h4>
                Lege ein neues Workout im Kalender an oder wähle einen Trainingsplan aus.<br />
                Wenn du schon einen Trainingplan verwendest, musst du diesen ggf. entfernen und neu aktivieren.
              </h4>
            </div>

            <div v-for="workout of workouts" :key="workout._id" class="top-spacer-25">
              <div class="row" v-if="workout.recipePreWorkout">
                <div class="col-12 col-lg-4 colored-row">
                  <h5>{{ $moment(workout.date).format("DD.MM.YYYY") }}</h5>

                  <h5>
                    {{ workout.name }}
                  </h5>

                  <div
                    class="top-spacer-15"
                    style="padding: 0 60px; height: 50px"
                    v-if="workout.workoutFile && workout.workoutFile.length != 0"
                  >
                    <div class="col-lg-12" style="padding-left: 0px; padding-right: 0px">
                      <span v-for="value in workout.workoutFile" :key="value.id">
                        <svg
                          v-if="value.type == 'SteadyState'"
                          class="workoutBuilderBlock"
                          v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
                          v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                        >
                          <rect
                            v-bind:id="value.id"
                            v-bind:width="100 + '%'"
                            v-bind:height="value.Power / 4"
                            v-bind:style="'fill:' + colorForPower(value.Power)"
                          />
                        </svg>

                        <svg
                          v-if="value.type == 'Ramp'"
                          class="workoutBuilderBlock"
                          v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
                          v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                        >
                          <rect
                            v-for="index in 20"
                            :key="index"
                            v-bind:id="value.id + '-' + index"
                            v-bind:width="100 / 20 + '%'"
                            v-bind:x="(100 / 20) * index - 5 + '%'"
                            v-bind:height="getRampRectHeight(20, value.PowerLow / 4, value.PowerHigh / 4, index)"
                            v-bind:style="
                              'fill:' + colorForPower(getRampRectHeight(20, value.PowerLow, value.PowerHigh, index))
                            "
                          />
                        </svg>

                        <svg
                          v-if="value.type == 'FreeRide'"
                          class="workoutBuilderBlock"
                          v-bind:width="value.Duration / 15"
                          v-bind:height="40"
                        >
                          <rect
                            v-bind:id="value.id"
                            v-bind:width="value.Duration / 15"
                            v-bind:height="400"
                            style="fill: rgba(94, 110, 255, 0.5)"
                          />
                        </svg>

                        <span v-if="value.type == 'IntervalsT'">
                          <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                            <svg
                              class="workoutBuilderBlock"
                              v-bind:width="computeWidth(value.OnDuration, workout.workoutFile)"
                              v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                            >
                              <rect
                                v-bind:id="value.id"
                                v-bind:width="'100%'"
                                v-bind:height="value.OnPower / 4"
                                v-bind:style="'fill:' + colorForPower(value.OnPower)"
                              />
                            </svg>
                            <svg
                              class="workoutBuilderBlock"
                              v-bind:width="computeWidth(value.OffDuration, workout.workoutFile)"
                              v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                            >
                              <rect
                                v-bind:id="value.id"
                                v-bind:width="'100%'"
                                v-bind:height="value.OffPower / 4"
                                v-bind:style="'fill:' + colorForPower(value.OffPower)"
                              />
                            </svg>
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <!--
                  <div
                    class="top-spacer-25"
                    v-if="
                      !workout.workoutFile || workout.workoutFile.length == 0
                    "
                  >
                    <div></div>
                  </div>
                  <div class="row top-spacer-10" style="padding: 0 50px">
                    <div class="col-4">
                      <h4 style="color: green">LIT</h4>
                      <h5>
                        {{ workout.estimate_time_lit }}
                      </h5>
                    </div>
                    <div class="col-4">
                      <h4 style="color: orange">MIT</h4>
                      <h5>
                        {{ workout.estimate_time_mit }}
                      </h5>
                    </div>
                    <div class="col-4">
                      <h4 style="color: red">HIT</h4>
                      <h5>
                        {{ workout.estimate_time_hit }}
                      </h5>
                    </div>
                  </div>
                  <br /><br />-->
                </div>

                <div class="col-6 col-lg-4">
                  <a :href="'/#/esszimmer/rezept/' + workout.recipePreWorkout._id">
                    <div class="row">
                      <div class="col-lg-12">
                        <h5 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%">
                          {{ workout.recipePreWorkout.ueberschrift }}
                        </h5>
                      </div>
                      <div class="col-lg-12">
                        <img
                          :src="'https://assets.pushinglimits.club/' + workout.recipePreWorkout.imageIds[0] + '-small'"
                          width="100%"
                        />
                      </div>
                    </div>
                  </a>
                  <button class="top-spacer-10" @click="setNewRecipe('Pre', workout._id)">Anderes</button>
                </div>
                <div class="col-6 col-lg-4">
                  <a
                    :href="
                      '/#/esszimmer/rezept/' +
                        workout.recipePostWorkout._id +
                        '/' +
                        workout.recipePostWorkoutIntensity || 'LIT'
                    "
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <h5 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%">
                          {{ workout.recipePostWorkout.ueberschrift }}
                        </h5>
                      </div>
                      <div class="col-lg-12">
                        <img
                          :src="'https://assets.pushinglimits.club/' + workout.recipePostWorkout.imageIds[0] + '-small'"
                          width="100%"
                        />
                      </div></div
                  ></a>
                  <button class="top-spacer-10" @click="setNewRecipe('Post', workout._id)">Anderes</button>
                </div>
              </div>
              <div class="top-spacer-75"></div>
            </div>
          </div>
          <div class="col-lg-3" style="background: #fafafa; padding: 50px">
            <div class="centered">
              <h2 style="font-size: 18px">Lieber die Rezepte selber aussuchen?</h2>
              <a href="/#/esszimmer">
                <button style="padding: 5px 15px" class="button-b top-spacer-15">Zu allen Rezepten</button>
              </a>
            </div>
            <h2 class="top-spacer-75" style="text-align: center; font-size: 18px; max-width: 100%">
              Plan mit Rezepten, die zu deinen Workouts passen
            </h2>
            <div class="top-spacer-25">
              Wenn du dir ein Workout in deinen Kalender legst oder einen Trainingplan aktivierst, werden automatisch
              zur Intensität des Workouts passende Rezepte ausgesucht!<br /><br />

              Die Mahlzeiten vor den Workouts sollten mit ausreichend zeitlichem Vorlauf eingenommen werden.

              <br /><br />
              Hinweis: die Ernährung ist immer sehr individuell. Die Vorschläge hier sollen als Inspiration für gute
              Ernährung dienen, die an dein Training angepasst ist. Um die für dich optimale Ernährung und Mengen zu
              finden, solltest du aber mit Ernährungsberatern oder Ärzten Rücksprache halten.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Foodplan",

  data: function () {
    return {
      list: new Array(250),
      loaded: false,
      workouts: [],
    };
  },

  methods: {
    getRampRectHeight(numberOfBlocks, startIntensity, endIntensity, index) {
      let res = startIntensity - ((startIntensity - endIntensity) / numberOfBlocks) * index;

      return res;
    },

    colorForPower(power) {
      if (power > 105) {
        return "darkred";
      } else if (power > 100) {
        return "brown";
      } else if (power > 95) {
        return "firebrick";
      } else if (power > 88) {
        return "darkgreen";
      } else if (power > 83) {
        return "green";
      } else {
        return "rgba(94, 110, 255, 0.97)";
      }
    },

    totalWorkoutLength(workoutFile) {
      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length;
    },

    computeWidth(val, workoutFile) {
      const width = Math.ceil((val / this.totalWorkoutLength(workoutFile)) * 100) - 1;

      return width > 1 ? width + "%" : "0.75px";
    },

    workoutBuilderHeight(workoutFile) {
      let maxPower = Math.max.apply(
        Math,
        workoutFile.map(function (o) {
          let onPower = typeof o.OnPower == "number" ? o.OnPower : 0;
          let offPower = typeof o.OffPower == "number" ? o.OffPower : 0;
          let power = typeof o.Power == "number" ? o.Power : 0;
          let powerLow = typeof o.PowerLow == "number" ? o.PowerLow : 0;
          let powerHigh = typeof o.PowerHigh == "number" ? o.PowerHigh : 0;

          return Math.max(onPower, offPower, power, powerLow, powerHigh);
        })
      );
      return Math.min(maxPower / 4, 35);
    },

    setNewRecipe(preOrPost, id) {
      const _self = this;

      axios
        .post(this.$host + "/workout/set_new_recipe/" + id, {
          preOrPost: preOrPost,
        })
        .then(function (response) {
          console.log("OKAY", response);
          // TODO: update only the workout
          axios
            .get(_self.$host + "/workout/workouts_food_plan")
            .then(function (response) {
              _self.workouts = response.data;
              _self.loaded = true;
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

  mounted: function () {
    this.loading = true;

    const _self = this;

    axios
      .get(this.$host + "/workout/workouts_food_plan")
      .then(function (response) {
        _self.workouts = response.data;
        _self.loaded = true;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<style scoped>
img {
  max-width: 280px;
}
</style>
