<template>
  <div class="nutrient-trend-chart">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'NutrientTrendChart',
  props: ['entries'],
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.createChart();
    });
  },
  watch: {
    entries: {
      handler() {
        this.$nextTick(() => {
          this.updateChart();
        });
      },
      deep: true
    }
  },
  methods: {
    createChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      const ctx = this.$refs.chart.getContext('2d');
      const nutrientData = this.calculateNutrientTrend();

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: nutrientData.dates,
          datasets: [
            {
              label: 'Kohlenhydrate',
              data: nutrientData.carbs,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              fill: true,
            },
            {
              label: 'Fett',
              data: nutrientData.fat,
              borderColor: 'rgb(54, 162, 235)',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              fill: true,
            },
            {
              label: 'Protein',
              data: nutrientData.protein,
              borderColor: 'rgb(255, 206, 86)',
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              fill: true,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Datum',
              },
            },
            y: {
              stacked: true,
              beginAtZero: true,
              title: {
                display: true,
                text: 'Gramm',
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Nährstofftrends',
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
          },
        },
      });
    },
    updateChart() {
      if (!this.chart) {
        this.createChart();
        return;
      }

      const nutrientData = this.calculateNutrientTrend();
      this.chart.data.labels = nutrientData.dates;
      this.chart.data.datasets[0].data = nutrientData.carbs;
      this.chart.data.datasets[1].data = nutrientData.fat;
      this.chart.data.datasets[2].data = nutrientData.protein;
      this.chart.update();
    },
    calculateNutrientTrend() {
      const nutrientData = { dates: [], carbs: [], fat: [], protein: [] };
      
      if (this.entries && this.entries.length > 0) {
        const sortedEntries = [...this.entries].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        
        sortedEntries.forEach(entry => {
          const date = new Date(entry.createdAt).toLocaleDateString();
          if (!nutrientData.dates.includes(date)) {
            nutrientData.dates.push(date);
            nutrientData.carbs.push(entry.carbs * entry.servings);
            nutrientData.fat.push(entry.fat * entry.servings);
            nutrientData.protein.push(entry.protein * entry.servings);
          } else {
            const index = nutrientData.dates.indexOf(date);
            nutrientData.carbs[index] += entry.carbs * entry.servings;
            nutrientData.fat[index] += entry.fat * entry.servings;
            nutrientData.protein[index] += entry.protein * entry.servings;
          }
        });
      }

      return nutrientData;
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style scoped>
.nutrient-trend-chart {
  width: 100%;
  height: 400px;
}
</style>