<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <h2 class="top-spacer-50">Neues Rezept anlegen</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>ueberschrift</h4>
          <input v-model="ueberschrift" />
          <hr />
          <h4>untertitel</h4>
          <input v-model="untertitel" />

          <h4>Nährwerte Bezug</h4>
          <input v-model="naehrwerte_bezug" />

          <h4>ks_tk</h4>
          <input v-model="ks_tk" />

          <h4>Behaelter</h4>
          <input v-model="behaelter" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="white_wrapper">
          <h5>Art</h5>
          <select v-model="intensitaet">
            <option>HIT</option>
            <option>LIT</option>
            <option>Special</option>

            <option>Snack</option>
          </select>
          <hr />
          <h5>Zeitpunkt</h5>

          <select v-model="zeitpunkt">
            <option>Pre</option>
            <option>Post</option>
          </select>
          <hr />
          <h5>Edition</h5>

          <select v-model="edition">
            <option>Winter</option>
            <option>Sommer</option>
            <option>Special</option>
            <option>Herbst</option>
            <option>Frühling</option>
            <option>Meal Prep</option>
            <option>Athletes Delight</option>
          </select>

          <hr />
          <h4>Portionen</h4>
          <select v-model="portionen">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
            <option>13</option>
            <option>14</option>
            <option>15</option>
            <option>16</option>
            <option>17</option>
            <option>18</option>
            <option>19</option>
            <option>20</option>
          </select>
        </div>
      </div>
      <hr />
      <div class="col-lg-12">
        <div class="row">
          <div class="col">
            <div class="white_wrapper">
              <h4>zeit</h4>
              <input v-model.number="zeit" />
            </div>
          </div>
          <div class="col">
            <div class="white_wrapper">
              <h4>aktive zeit</h4>
              <input v-model.number="aktive_zeit" />
            </div>
          </div>
          <div class="col">
            <div class="white_wrapper">
              <h4>proteine</h4>
              <input v-model.number="proteine" />
            </div>
          </div>
          <div class="col">
            <div class="white_wrapper">
              <h4>kohlenhydrate</h4>
              <input v-model.number="kohlenhydrate" />
            </div>
          </div>
          <div class="col">
            <div class="white_wrapper">
              <h4>fette</h4>
              <input v-model.number="fette" />
            </div>
          </div>
          <div class="col">
            <div class="white_wrapper">
              <h4>ballaststoffe</h4>
              <input v-model.number="ballaststoffe" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>zubereitung</h4>
          <textarea v-model="zubereitung" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>ernaehrungswissen</h4>
          <textarea v-model="ernaehrungswissen" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>Zutaten</h4>
          <div class="row">
            <div class="col-lg-4"><h4>Zutat</h4></div>
            <div class="col-lg-4"><h4>Menge</h4></div>
            <div class="col-lg-4"><h4>Einheit</h4></div>
          </div>
          <div
            v-for="(zutat, index) in zutaten"
            :key="index"
            class="row top-spacer-25"
          >
            <div class="col-lg-4"><input v-model="zutat.name" /></div>
            <div class="col-lg-4"><input v-model.number="zutat.menge" /></div>
            <div class="col-lg-4"><input v-model="zutat.einheit" /></div>
          </div>
          <button @click="addZutat" class="top-spacer-25">Neue Zutat</button>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>dip</h4>
          <div class="row">
            <div class="col-lg-4"><h4>Zutat</h4></div>
            <div class="col-lg-4"><h4>Menge</h4></div>
            <div class="col-lg-4"><h4>Einheit</h4></div>
          </div>
          <div
            v-for="(zutat, index) in dipZutaten"
            :key="index"
            class="row top-spacer-25"
          >
            <div class="col-lg-4"><input v-model="zutat.name" /></div>
            <div class="col-lg-4"><input v-model.number="zutat.menge" /></div>
            <div class="col-lg-4"><input v-model="zutat.einheit" /></div>
          </div>
          <button @click="addDipZutat" class="top-spacer-25">Neue Zutat</button>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>topping</h4>
          <div class="row">
            <div class="col-lg-4"><h4>Zutat</h4></div>
            <div class="col-lg-4"><h4>Menge</h4></div>
            <div class="col-lg-4"><h4>Einheit</h4></div>
          </div>
          <div
            v-for="(zutat, index) in toppingZutaten"
            :key="index"
            class="row top-spacer-25"
          >
            <div class="col-lg-4"><input v-model="zutat.name" /></div>
            <div class="col-lg-4"><input v-model.number="zutat.menge" /></div>
            <div class="col-lg-4"><input v-model="zutat.einheit" /></div>
          </div>
          <button @click="addToppingZutat" class="top-spacer-25">
            Neue Zutat
          </button>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>dressing</h4>
          <div class="row">
            <div class="col-lg-4"><h4>Zutat</h4></div>
            <div class="col-lg-4"><h4>Menge</h4></div>
            <div class="col-lg-4"><h4>Einheit</h4></div>
          </div>
          <div
            v-for="(zutat, index) in dressingZutaten"
            :key="index"
            class="row top-spacer-25"
          >
            <div class="col-lg-4"><input v-model="zutat.name" /></div>
            <div class="col-lg-4"><input v-model.number="zutat.menge" /></div>
            <div class="col-lg-4"><input v-model="zutat.einheit" /></div>
          </div>
          <button @click="addDressingZutat" class="top-spacer-25">
            Neue Zutat
          </button>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>sosse</h4>
          <div class="row">
            <div class="col-lg-4"><h4>Zutat</h4></div>
            <div class="col-lg-4"><h4>Menge</h4></div>
            <div class="col-lg-4"><h4>Einheit</h4></div>
          </div>
          <div
            v-for="(zutat, index) in sosseZutaten"
            :key="index"
            class="row top-spacer-25"
          >
            <div class="col-lg-4"><input v-model="zutat.name" /></div>
            <div class="col-lg-4"><input v-model.number="zutat.menge" /></div>
            <div class="col-lg-4"><input v-model="zutat.einheit" /></div>
          </div>
          <button @click="addSosseZutat" class="top-spacer-25">
            Neue Zutat
          </button>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>Variable Liste 1</h4>
          <input type="text" v-model="var_liste_1_name" />
          <div class="row">
            <div class="col-lg-4"><h4>Zutat</h4></div>
            <div class="col-lg-4"><h4>Menge</h4></div>
            <div class="col-lg-4"><h4>Einheit</h4></div>
          </div>
          <div
            v-for="(zutat, index) in var_liste_1_zutaten"
            :key="index"
            class="row top-spacer-25"
          >
            <div class="col-lg-4"><input v-model="zutat.name" /></div>
            <div class="col-lg-4"><input v-model.number="zutat.menge" /></div>
            <div class="col-lg-4"><input v-model="zutat.einheit" /></div>
          </div>
          <button @click="addVarListe1Zutat" class="top-spacer-25">
            Neue Zutat
          </button>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>Variable Liste 2</h4>
          <input type="text" v-model="var_liste_2_name" />
          <div class="row">
            <div class="col-lg-4"><h4>Zutat</h4></div>
            <div class="col-lg-4"><h4>Menge</h4></div>
            <div class="col-lg-4"><h4>Einheit</h4></div>
          </div>
          <div
            v-for="(zutat, index) in var_liste_2_zutaten"
            :key="index"
            class="row top-spacer-25"
          >
            <div class="col-lg-4"><input v-model="zutat.name" /></div>
            <div class="col-lg-4"><input v-model.number="zutat.menge" /></div>
            <div class="col-lg-4"><input v-model="zutat.einheit" /></div>
          </div>
          <button @click="addVarListe2Zutat" class="top-spacer-25">
            Neue Zutat
          </button>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>Variable Liste 3</h4>
          <input type="text" v-model="var_liste_3_name" />
          <div class="row">
            <div class="col-lg-4"><h4>Zutat</h4></div>
            <div class="col-lg-4"><h4>Menge</h4></div>
            <div class="col-lg-4"><h4>Einheit</h4></div>
          </div>
          <div
            v-for="(zutat, index) in var_liste_3_zutaten"
            :key="index"
            class="row top-spacer-25"
          >
            <div class="col-lg-4"><input v-model="zutat.name" /></div>
            <div class="col-lg-4"><input v-model.number="zutat.menge" /></div>
            <div class="col-lg-4"><input v-model="zutat.einheit" /></div>
          </div>
          <button @click="addVarListe3Zutat" class="top-spacer-25">
            Neue Zutat
          </button>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>Variable Liste 4</h4>
          <input type="text" v-model="var_liste_4_name" />
          <div class="row">
            <div class="col-lg-4"><h4>Zutat</h4></div>
            <div class="col-lg-4"><h4>Menge</h4></div>
            <div class="col-lg-4"><h4>Einheit</h4></div>
          </div>
          <div
            v-for="(zutat, index) in var_liste_4_zutaten"
            :key="index"
            class="row top-spacer-25"
          >
            <div class="col-lg-4"><input v-model="zutat.name" /></div>
            <div class="col-lg-4"><input v-model.number="zutat.menge" /></div>
            <div class="col-lg-4"><input v-model="zutat.einheit" /></div>
          </div>
          <button @click="addVarListe4Zutat" class="top-spacer-25">
            Neue Zutat
          </button>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>Variable Liste 5</h4>
          <input type="text" v-model="var_liste_5_name" />
          <div class="row">
            <div class="col-lg-4"><h4>Zutat</h4></div>
            <div class="col-lg-4"><h4>Menge</h4></div>
            <div class="col-lg-4"><h4>Einheit</h4></div>
          </div>
          <div
            v-for="(zutat, index) in var_liste_5_zutaten"
            :key="index"
            class="row top-spacer-25"
          >
            <div class="col-lg-4"><input v-model="zutat.name" /></div>
            <div class="col-lg-4"><input v-model.number="zutat.menge" /></div>
            <div class="col-lg-4"><input v-model="zutat.einheit" /></div>
          </div>
          <button @click="addVarListe5Zutat" class="top-spacer-25">
            Neue Zutat
          </button>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="white_wrapper">
          <h4>Bild hinzufügen</h4>

          <input
            multiple
            accept="image/*"
            type="file"
            @change="processFile($event)"
          />
        </div>
        <div v-if="imageIds && imageIds.length > 0">
          <hr />
          <h5>Bild löschen</h5>

          <div v-for="id in imageIds" :key="id">
            <img
              class="recipe-img"
              alt="Bild des Rezepts"
              :src="`https://assets.pushinglimits.club/${id}`"
            />
            <div style="padding: 5px">
              <button @click="deleteImage(id)">Löschen</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="white_wrapper">
          <div class="col-lg-12">
            <button @click="save" class="top-spacer-25">SPEICHERN</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateRezept",
  props: ["id"],
  data: function () {
    return {
      portionen: 1,
      files: undefined,
      zutaten: [],
      sosseZutaten: [],
      dipZutaten: [],
      toppingZutaten: [],
      dressingZutaten: [],
      ueberschrift: "",
      untertitel: "",
      zubereitung: "",
      ernaehrungswissen: "",
      proteine: 0,
      kohlenhydrate: 0,
      fette: 0,
      ballaststoffe: 0,
      zeit: 0,
      aktive_zeit: 0,
      rezeptBild: undefined,
      zeitpunkt: "Pre",
      intensitaet: "HIT",
      ks_tk: "",
      behaelter: "",

      imageIds: [],
      edition: "Winter",
      var_liste_1_name: "",
      var_liste_1_zutaten: [],
      var_liste_2_name: "",
      var_liste_2_zutaten: [],
      var_liste_3_name: "",
      var_liste_3_zutaten: [],
      var_liste_4_name: "",
      var_liste_4_zutaten: [],
      var_liste_5_name: "",
      var_liste_5_zutaten: [],
      naehrwerte_bezug: "",
    };
  },

  methods: {
    processFile(event) {
      console.log("Process files!;");
      this.files = event.target.files;
    },
    addZutat: function () {
      this.zutaten.push({ name: "", menge: 0, einheit: "g" });
    },
    addDipZutat: function () {
      this.dipZutaten.push({ name: "", menge: 0, einheit: "g" });
    },
    addSosseZutat: function () {
      this.sosseZutaten.push({ name: "", menge: 0, einheit: "g" });
    },
    addToppingZutat: function () {
      this.toppingZutaten.push({ name: "", menge: 0, einheit: "g" });
    },
    addDressingZutat: function () {
      this.dressingZutaten.push({ name: "", menge: 0, einheit: "g" });
    },
    addVarListe1Zutat: function () {
      this.var_liste_1_zutaten.push({ name: "", menge: 0, einheit: "g" });
    },
    addVarListe2Zutat: function () {
      this.var_liste_2_zutaten.push({ name: "", menge: 0, einheit: "g" });
    },
    addVarListe3Zutat: function () {
      this.var_liste_3_zutaten.push({ name: "", menge: 0, einheit: "g" });
    },
    addVarListe4Zutat: function () {
      this.var_liste_4_zutaten.push({ name: "", menge: 0, einheit: "g" });
    },
    addVarListe5Zutat: function () {
      this.var_liste_5_zutaten.push({ name: "", menge: 0, einheit: "g" });
    },

    deleteImage: function (imageId) {
      const _self = this;
      axios
        .delete(this.$host + "/rezept/" + this.id + "/image/" + imageId)
        .then(function (response) {
          console.log("rezept res", response);
          _self.imageIds = response.data.imageIds;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    save: function () {
      const formData = new FormData();
      if (this.id) {
        formData.append("id", this.id);
      }

      formData.append("portionen", this.portionen);

      if (this.files) {
        var ins = this.files.length;
        for (var x = 0; x < ins; x++) {
          console.log("Append Image");
          formData.append("files[]", this.files[x]);
        }
      } else {
        formData.append("files[]", []);
      }

      this.zutaten = this.zutaten.filter(function (zutat) {
        return (
          zutat.name &&
          zutat.name.length > 0 &&
          zutat.menge > 0 &&
          zutat.einheit.length > 0
        );
      });
      this.dipZutaten = this.dipZutaten.filter(function (zutat) {
        return (
          zutat.name &&
          zutat.name.length > 0 &&
          zutat.menge > 0 &&
          zutat.einheit.length > 0
        );
      });
      this.toppingZutaten = this.toppingZutaten.filter(function (zutat) {
        return (
          zutat.name &&
          zutat.name.length > 0 &&
          zutat.menge > 0 &&
          zutat.einheit.length > 0
        );
      });
      this.dressingZutaten = this.dressingZutaten.filter(function (zutat) {
        return (
          zutat.name &&
          zutat.name.length > 0 &&
          zutat.menge > 0 &&
          zutat.einheit.length > 0
        );
      });
      this.sosseZutaten = this.sosseZutaten.filter(function (zutat) {
        return (
          zutat.name &&
          zutat.name.length > 0 &&
          zutat.menge > 0 &&
          zutat.einheit.length > 0
        );
      });

      this.var_liste_1_zutaten = this.var_liste_1_zutaten.filter(function (
        zutat
      ) {
        return (
          zutat.name &&
          zutat.name.length > 0 &&
          zutat.menge > 0 &&
          zutat.einheit.length > 0
        );
      });

      this.var_liste_2_zutaten = this.var_liste_2_zutaten.filter(function (
        zutat
      ) {
        return (
          zutat.name &&
          zutat.name.length > 0 &&
          zutat.menge > 0 &&
          zutat.einheit.length > 0
        );
      });

      this.var_liste_3_zutaten = this.var_liste_3_zutaten.filter(function (
        zutat
      ) {
        return (
          zutat.name &&
          zutat.name.length > 0 &&
          zutat.menge > 0 &&
          zutat.einheit.length > 0
        );
      });

      this.var_liste_4_zutaten = this.var_liste_4_zutaten.filter(function (
        zutat
      ) {
        return (
          zutat.name &&
          zutat.name.length > 0 &&
          zutat.menge > 0 &&
          zutat.einheit.length > 0
        );
      });

      this.var_liste_5_zutaten = this.var_liste_5_zutaten.filter(function (
        zutat
      ) {
        return (
          zutat.name &&
          zutat.name.length > 0 &&
          zutat.menge > 0 &&
          zutat.einheit.length > 0
        );
      });

      formData.append("rezeptBild", this.rezeptBild);
      formData.append("zutaten", JSON.stringify(this.zutaten));
      formData.append("ueberschrift", JSON.stringify(this.ueberschrift));
      formData.append("untertitel", JSON.stringify(this.untertitel));
      formData.append("zubereitung", JSON.stringify(this.zubereitung));
      formData.append("dip", JSON.stringify(this.dipZutaten));
      formData.append("topping", JSON.stringify(this.toppingZutaten));
      formData.append("dressing", JSON.stringify(this.dressingZutaten));
      formData.append("sosse", JSON.stringify(this.sosseZutaten));
      formData.append("edition", JSON.stringify(this.edition)),
        formData.append(
          "naehrwerte_bezug",
          JSON.stringify(this.naehrwerte_bezug)
        ),
        formData.append(
          "var_liste_1_name",
          JSON.stringify(this.var_liste_1_name)
        ),
        formData.append(
          "var_liste_1_zutaten",
          JSON.stringify(this.var_liste_1_zutaten)
        ),
        formData.append(
          "var_liste_2_name",
          JSON.stringify(this.var_liste_2_name)
        ),
        formData.append(
          "var_liste_2_zutaten",
          JSON.stringify(this.var_liste_2_zutaten)
        ),
        formData.append(
          "var_liste_3_name",
          JSON.stringify(this.var_liste_3_name)
        ),
        formData.append(
          "var_liste_3_zutaten",
          JSON.stringify(this.var_liste_3_zutaten)
        ),
        formData.append(
          "var_liste_4_name",
          JSON.stringify(this.var_liste_4_name)
        ),
        formData.append(
          "var_liste_4_zutaten",
          JSON.stringify(this.var_liste_4_zutaten)
        ),
        formData.append(
          "var_liste_5_name",
          JSON.stringify(this.var_liste_5_name)
        ),
        formData.append(
          "var_liste_5_zutaten",
          JSON.stringify(this.var_liste_5_zutaten)
        ),
        formData.append(
          "ernaehrungswissen",
          JSON.stringify(this.ernaehrungswissen)
        );
      formData.append("proteine", JSON.stringify(this.proteine));
      formData.append("kohlenhydrate", JSON.stringify(this.kohlenhydrate));
      formData.append("fette", JSON.stringify(this.fette));
      formData.append("ballaststoffe", JSON.stringify(this.ballaststoffe));
      formData.append("zeit", JSON.stringify(this.zeit));
      formData.append("aktive_zeit", JSON.stringify(this.aktive_zeit));
      formData.append("ks_tk", JSON.stringify(this.ks_tk));
      formData.append("behaelter", JSON.stringify(this.behaelter));

      formData.append("intensitaet", JSON.stringify(this.intensitaet));
      formData.append("zeitpunkt", JSON.stringify(this.zeitpunkt));

      const _self = this;
      axios
        .post(this.$host + "/rezept", formData)
        .then(function (response) {
          console.log("rezept res", response);
          _self.$router.push({
            name: "ShowRezept",
            params: { id: response.data._id },
          });
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log(formData);
    },
  },
  mounted: function () {
    if (this.id) {
      const _self = this;
      this.backendUrl = this.$host;

      console.log(this.$host);
      axios
        .get(this.$host + "/rezept/" + this.id)
        .then(function (response) {
          _self.zutaten = response.data.zutaten;
          _self.sosseZutaten = response.data.sosse;
          _self.dipZutaten = response.data.dip;
          _self.toppingZutaten = response.data.topping;
          _self.intensitaet = response.data.intensitaet;
          _self.dressingZutaten = response.data.dressing;
          _self.ueberschrift = response.data.ueberschrift;
          _self.untertitel = response.data.untertitel;
          _self.zubereitung = response.data.zubereitung;
          _self.ernaehrungswissen = response.data.ernaehrungswissen;
          _self.proteine = response.data.proteine;
          _self.kohlenhydrate = response.data.kohlenhydrate;
          _self.fette = response.data.fette;
          _self.ballaststoffe = response.data.ballaststoffe;
          _self.zeit = response.data.zeit;
          _self.aktive_zeit = response.data.aktive_zeit || 0;
          _self.rezeptBild = response.data.rezeptBild;
          _self.intensitatet = response.data.intensitatet;
          _self.zeitpunkt = response.data.zeitpunkt;
          _self.imageIds = response.data.imageIds;
          _self.edition = response.data.edition;
          _self.var_liste_1_name = response.data.var_liste_1_name || "";
          _self.var_liste_1_zutaten = response.data.var_liste_1_zutaten || [];
          _self.var_liste_2_name = response.data.var_liste_2_name || "";
          _self.var_liste_2_zutaten = response.data.var_liste_2_zutaten || [];
          _self.var_liste_3_name = response.data.var_liste_3_name || "";
          _self.var_liste_3_zutaten = response.data.var_liste_3_zutaten || [];
          _self.var_liste_4_name = response.data.var_liste_4_name || "";
          _self.var_liste_4_zutaten = response.data.var_liste_4_zutaten || [];
          _self.var_liste_5_name = response.data.var_liste_5_name || "";
          _self.var_liste_5_zutaten = response.data.var_liste_5_zutaten || [];
          _self.naehrwerte_bezug = response.data.naehrwerte_bezug || "";
          _self.ks_tk = response.data.ks_tk || "";
          _self.behaelter = response.data.behaelter || "";
          _self.portionen = response.data.portionen || 0;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>


<style scoped>
.kraftraum-heading {
  font-size: 80px;
  text-align: center;
}

.library {
  background: #efefef;
  padding: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.24);
}

.category {
  text-align: center;

  width: auto;
  height: auto;
  transform: rotate(270deg);
  font-size: 100px;
}

.box {
  margin: 25px;
  font-weight: bold;
  font-size: 18px;
  background: #efefef;
  padding: 0;
  cursor: pointer;
}

.box img {
  max-height: 150px;
  width: 100%;
}

input {
  width: 100%;
}

textarea {
  height: 350px;
}
</style>