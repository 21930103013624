import { render, staticRenderFns } from "./IronmanFrankfurt.vue?vue&type=template&id=25adc230&scoped=true"
import script from "./IronmanFrankfurt.vue?vue&type=script&lang=js"
export * from "./IronmanFrankfurt.vue?vue&type=script&lang=js"
import style0 from "./IronmanFrankfurt.vue?vue&type=style&index=0&id=25adc230&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25adc230",
  null
  
)

export default component.exports