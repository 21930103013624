<template>
  <div class="food-tracker">
    <div class="container">
      <div class="input-section">
        <h2>Neuer Eintrag</h2>
        <div class="input-container">
          <div class="date-input">
            <label for="date">Datum</label>
            <input type="date" id="date" v-model="modalDate" />
          </div>
          <div class="food-input">
            <label for="food">Was hast du gegessen?</label>
            <input type="text" id="food" v-model="txt" placeholder="Spaghetti Bolognese" />
          </div>
          <div class="food-input">
            <label for="picture-button">oder</label>
            <button id="picture-button" @click="takePicture" class="upload-btn">
              <i class="fa fa-camera"></i> Bild hochladen
            </button>
          </div>
        </div>
        <button @click="submitEntry" class="submit-btn">Eintrag hinzufügen</button>
      </div>

      <div v-if="photo" class="photo-preview">
        <img :src="photo.dataUrl" alt="Food photo" />
        <button @click="cancelPhoto" class="cancel-btn">Abbrechen</button>
      </div>

      <div v-if="quickModeMessage" class="quick-mode-message">
        {{ quickModeMessage }}
      </div>

      <div v-if="filteredMeals && filteredMeals.length" class="quick-select">
        <h3>Schnellauswahl</h3>
        <ul>
          <li v-for="entry in filteredMeals" :key="entry._id" @click="selectMeal(entry._id)">
            {{ entry.name }}
          </li>
        </ul>
      </div>

      <div class="food-entries">
        <div v-for="entry in entries" :key="entry._id" class="food-entry-card">
          <div class="entry-header">
            <span class="entry-date">{{ $moment(entry.createdAt).format("DD.MM.YYYY") }}</span>
            <div class="entry-rating" :style="{ background: getRatingColor(entry.rating) }"></div>
          </div>
          <div class="entry-body">
            <img
              v-if="entry.image_link"
              @click="showEditModal(entry._id)"
              class="entry-image"
              :src="'https://foodpictures.pushinglimits.club/' + entry.image_link"
              alt="Food"
            />
            <div class="entry-details">
              <h4>{{ entry.name }}</h4>
              <div class="entry-ingredients">
                <span v-for="(ingredient, index) in entry.ingredients" :key="ingredient.name">
                  {{ ingredient.name }} ({{ ingredient.amount }}g){{ (((entry.ingredients.length-1) > index) ?
                   ' / ' : '') }}
                </span>
              </div>
              <div class="entry-nutrients">
                <div class="nutrient">
                  <span class="nutrient-label">Kalorien:</span>
                  <span class="nutrient-value">{{ Math.round(entry.calories * entry.servings) }} kcal</span>
                </div>
                <div class="nutrient">
                  <span class="nutrient-label">Kohlenhydrate:</span>
                  <span class="nutrient-value">{{ Math.round(entry.carbs * entry.servings) }} g</span>
                </div>
                <div class="nutrient">
                  <span class="nutrient-label">Fett:</span>
                  <span class="nutrient-value">{{ Math.round(entry.fat * entry.servings) }} g</span>
                </div>
                <div class="nutrient">
                  <span class="nutrient-label">Protein:</span>
                  <span class="nutrient-value">{{ Math.round(entry.protein * entry.servings) }} g</span>
                </div>
              </div>
            </div>
          </div>
          <div class="entry-footer">
            <div class="servings-control">
              <span>Portionen: </span>
              <button @click="adjustServings(entry._id, -0.25)" class="servings-btn">-</button>
              <span class="servings-display" v-if="entry.servings">{{ entry.servings.toFixed(2) }}</span>
              <button @click="adjustServings(entry._id, 0.25)" class="servings-btn">+</button>
            </div>
            <div>
              <button @click="showEditModal(entry._id)" class="edit-btn">
                Bearbeiten
              </button>
              <button @click="confirmDelete(entry._id)" class="delete-btn">
                Löschen
              </button>
            </div>
          </div>
        </div>

        <!-- Add this modal component for each entry -->
        <b-modal v-for="entry in entries" :key="'modal-' + entry._id" :id="'edit-modal-' + entry._id" :title="'Eintrag bearbeiten: ' + entry.name" hide-footer size="lg">
          <form @submit.prevent="updateEntry(entry._id)">
            <div class="form-group">
              <label for="modalDate">Datum</label>
              <input type="date" class="form-control" id="modalDate" v-model="modalDate">
            </div>
            <div class="form-group">
              <label for="modalRating">Bewertung</label>
              <select class="form-control" id="modalRating" v-model="modalRating">
                <option value="Good">Gut</option>
                <option value="Okay">Okay</option>
                <option value="Bad">Schlecht</option>
              </select>
            </div>
            <div class="form-group">
              <label for="modalServings">Portionen</label>
              <input type="number" class="form-control" id="modalServings" v-model="modalServings" step="0.25">
            </div>
            <div class="form-group">
              <label>Zutaten</label>
              <div v-for="(ingredient, index) in modalIngredients" :key="index" class="d-flex mb-2">
                <input type="text" class="form-control mr-2" v-model="ingredient.name" placeholder="Zutat">
                <input type="number" class="form-control mr-2" v-model="ingredient.amount" placeholder="Menge (g)">
                <button type="button" class="btn btn-danger" @click="removeIngredient(index)">Entfernen</button>
              </div>
              <button type="button" class="btn btn-secondary mt-2" @click="addIngredient">Zutat hinzufügen</button>
            </div>
            <div class="mt-3">
              <button type="submit" class="btn btn-primary">Speichern</button>
              <button type="button" class="btn btn-secondary ml-2" @click="$bvModal.hide('edit-modal-' + entry._id)">Abbrechen</button>
            </div>
          </form>
        </b-modal>

        <!-- Add this modal for delete confirmation -->
        <b-modal id="delete-confirmation-modal" title="Eintrag löschen" @ok="deleteEntry">
          <p class="my-4">Möchten Sie diesen Eintrag wirklich löschen?</p>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Camera, CameraResultType } from "@capacitor/camera";

export default {
  data() {
    return {
      entries: null,
      photo: null,
      deleteModal: null,
      title: null,
      modalDate: new Date().toISOString().slice(0, 10),
      modalRating: null,
      modalServings: null,
      modalIngredients: [],
      loaded: false,
      txt: null,
      showTxt: false,
      quickModeMessage: "",
      loadedGeneration: true,
      entryToDelete: null,
    };
  },
  mounted: async function () {
    await this.refresh();
    window.addEventListener("scroll", this.lazyLoad);
    this.lazyLoad();
  },

  computed: {
    goodMeals: function () {
      return (this.entries && this.entries.filter((entry) => entry.rating === "Good").length) || 0;
    },
    okayMeals: function () {
      return (this.entries && this.entries.filter((entry) => entry.rating === "Okay").length) || 0;
    },
    badMeals: function () {
      return (this.entries && this.entries.filter((entry) => entry.rating === "Bad").length) || 0;
    },

    filteredMeals: function () {
      if (!this.txt) {
        return [];
      }
      const searchWords = this.txt.toLowerCase().split(" ");
      const seenNames = new Set();

      return this.entries.filter((entry) => {
        if (entry.name && searchWords.every((word) => entry.name.toLowerCase().includes(word))) {
          if (!seenNames.has(entry.name)) {
            seenNames.add(entry.name);
            return true;
          }
        }
        return false;
      });
    },
  },

  methods: {
    adjustServings: async function (id, amount) {
      const entry = this.entries.find((entry) => entry._id === id);
      const newServings = entry.servings + amount;

      await axios.post(this.$host + "/food/update/" + id, {
        servings: newServings,
      });

      await this.refresh(false);
    },
    getRatingColor(rating) {
      switch (rating) {
        case "Bad":
          return "#dc3545";
        case "Okay":
          return "#ff9800";
        case "Good":
          return "#28a745";
        default:
          return "grey";
      }
    },

    async selectMeal(id) {
      await axios.post(this.$host + "/food/select", {
        id: id,
        createdAt: this.modalDate || new Date().toISOString(),
      });

      this.txt = "";
      this.quickModeMessage = "Eintrag hinzugefügt!";

      await this.refresh();
    },

    newTxtEntry() {
      this.photo = null;
      this.showTxt = true;
      this.quickModeMessage = "";
    },

    async takePicture() {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        promptLabelPicture: "Foto machen",
        promptLabelPhoto: "Aus Galerie wählen",
        promptLabelCancel: "Abbrechen",
        promptLabelHeader: "Foto analysieren",
      });

      this.photo = image;
      this.showTxt = false;
    },

    async refresh(showLoaded = true) {
      const _self = this;
      if (showLoaded === true) {
        this.loaded = false;
      }
      const response365d = await axios.get(this.$host + "/food/365d");
      const entries365d = response365d.data;

      this.entries = entries365d;
      this.loaded = true;
      this.loadedGeneration = true;
      // nexttick
      this.$nextTick(() => {
        _self.lazyLoad();
      });
    },

    async deletePicture(id) {
      const index = this.entries.findIndex((entry) => entry._id === id);

      this.photo = undefined;
      await axios.delete(this.$host + "/food/" + id);

      await this.refresh();

      // Scroll to the next or previous entry in the list, or to the "Neuen Eintrag anlegen" section if there are no entries left
      if (this.entries.length > 0) {
        if (index < this.entries.length - 1) {
          const nextEntry = this.entries[index + 1];
          const nextEntryId = nextEntry._id;
          console.log("Scroll to next entry " + nextEntryId);

          const nextEntryElement = document.getElementById(nextEntryId);

          await this.refresh();

          if (nextEntryElement) {
            nextEntryElement.scrollIntoView({
              block: "start",
              inline: "nearest",
            });
          }
        } else if (index > 0) {
          console.log("Scroll to previous entry");
          const prevEntry = this.entries[index - 1];
          const prevEntryId = prevEntry._id;
          const prevEntryElement = document.getElementById(prevEntryId);

          await this.refresh();

          if (prevEntryElement) {
            prevEntryElement.scrollIntoView({
              block: "start",
              inline: "nearest",
            });
          }
        } else {
          const newEntryElement = document.getElementById("new_entry");
          if (newEntryElement) {
            newEntryElement.scrollIntoView({
              block: "start",
              inline: "nearest",
            });
          }
        }
      } else {
        console.log("Scroll to new_entry");
        await this.refresh();

        const newEntryElement = document.getElementById("new_entry");
        if (newEntryElement) {
          newEntryElement.scrollIntoView({
            block: "start",
            inline: "nearest",
          });
        }
      }

      // now account for fixed header
      var scrolledY = window.scrollY;

      if (scrolledY) {
        window.scroll(0, scrolledY - 80);
      }

      await this.refresh();
    },

    isShowEditModal(id) {
      return this.deleteModal === id;
    },

    showEditModal(id) {
      if (this.deleteModal !== id) {
        this.deleteModal = id;

        const entry = this.entries.find((entry) => entry._id === id);

        this.modalDate = this.$moment(entry.createdAt).format("YYYY-MM-DD");
        this.modalRating = entry.rating;
        this.modalServings = entry.servings;
        this.modalIngredients = JSON.parse(JSON.stringify(entry.ingredients || [])); // Deep copy

        this.$bvModal.show('edit-modal-' + id);
      } else {
        this.deleteModal = null;
      }
    },

    async updateEntry(id) {
      const modelDateWithTime = new Date(this.modalDate).setHours(new Date().getHours(), new Date().getMinutes());

      await axios.post(this.$host + "/food/update/" + id, {
        createdAt: modelDateWithTime,
        rating: this.modalRating,
        servings: this.modalServings,
        ingredients: this.modalIngredients,
      });

      await this.refresh();
      this.$bvModal.hide('edit-modal-' + id);
      this.deleteModal = null;
    },

    async submitEntry() {
      if (this.txt) {
        await this.uploadText();
      } else if (this.photo) {
        await this.uploadPicture();
      }
    },

    async uploadText() {
      const modelDateWithTime = new Date(this.modalDate).setHours(new Date().getHours(), new Date().getMinutes());

      this.loadedGeneration = false;
      this.loaded = false;
      const _self = this;
      axios
        .post(this.$host + "/food/upload/text_ai", {
          text: this.txt,
          rating: "Okay",
          createdAt: modelDateWithTime,
        })
        .then(async (result) => {
          console.log(">>>> " + result);
          // add result.diaryEntry to this.entries at the beginning
          _self.entries.unshift(result.data.diaryEntry);

          _self.showTxt = false;

          _self.loaded = true;

          _self.loadedGeneration = true;
        });
    },

    async uploadPicture() {
      this.loadedGeneration = false;

      // Convert DataUrl to Blob
      const response = await fetch(this.photo.dataUrl);
      const blob = await response.blob();
      const _self = this;
      this.loaded = false;

      // Create FormData
      const formData = new FormData();
      formData.append("file", blob, "photo.jpg");
      formData.append("rating", "Okay");

      // Send a POST request to your API
      try {
        await axios
          .post(this.$host + "/food/upload_ai", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async () => {
            _self.photo = undefined;
            _self.loaded = true;
            _self.loadedGeneration = true;

            await _self.refresh();
          });
      } catch (err) {
        console.error(err);
      }
    },

    cancelPhoto() {
      this.photo = null;
    },

    lazyLoad: function () {
      let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
      let active = false;
      const _self = this;
      if (active === false) {
        active = true;
        setTimeout(() => {
          lazyImages.forEach(function (lazyImage) {
            if (
              lazyImage.getBoundingClientRect().top <= window.innerHeight + 100 &&
              lazyImage.getBoundingClientRect().bottom >= 0 &&
              getComputedStyle(lazyImage).display !== "none"
            ) {
              lazyImage.src = lazyImage.dataset.src;
              lazyImage.classList.remove("lazy");
              lazyImages = lazyImages.filter(function (image) {
                return image !== lazyImage;
              });

              if (lazyImages.length === 0) {
                window.removeEventListener("scroll", _self.lazyLoad);
              }
            }
          });
          active = false;
        }, 30);
      }
    },

    addIngredient() {
      this.modalIngredients.push({ name: '', amount: 0 });
    },

    removeIngredient(index) {
      this.modalIngredients.splice(index, 1);
    },

    confirmDelete(id) {
      this.entryToDelete = id;
      this.$bvModal.show('delete-confirmation-modal');
    },

    async deleteEntry() {
      if (this.entryToDelete) {
        try {
          await axios.delete(this.$host + "/food/" + this.entryToDelete);
          await this.refresh();
          this.$bvModal.hide('delete-confirmation-modal');
          this.entryToDelete = null;
        } catch (error) {
          console.error("Error deleting entry:", error);
          // You might want to show an error message to the user here
        }
      }
    },
  },
};
</script>

<style scoped>
.food-tracker {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 2rem;
}

.header h1 {
  font-size: 2.5rem;
  color: #333;
}

.header p {
  font-size: 1.2rem;
  color: #666;
}

.input-section {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 2rem;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.date-input, .food-input {
  flex: 1;
  min-width: 200px;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input[type="date"], input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.upload-btn, .submit-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
  width: 100%;
  text-align: center;
}

.upload-btn {
  background-color: #6c757d;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.upload-btn:hover {
  background-color: #5a6268;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  width: 100%;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.photo-preview {
  text-align: center;
  margin-bottom: 1rem;
}

.photo-preview img {
  max-width: 100%;
  border-radius: 10px;
}

.cancel-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #c82333;
}

.quick-mode-message {
  text-align: center;
  color: #28a745;
  margin-bottom: 1rem;
}

.quick-select {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.quick-select h3 {
  margin-bottom: 1rem;
}

.quick-select ul {
  list-style-type: none;
  padding: 0;
}

.quick-select li {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quick-select li:hover {
  background-color: #e9ecef;
}

.food-entries {
  display: grid;
  gap: 1rem;
}

.food-entry-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.entry-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
}

.entry-date {
  font-weight: bold;
}

.entry-rating {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.entry-body {
  display: flex;
  padding: 1rem;
}

.entry-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 1rem;
}

.entry-details {
  flex: 1;
}

.entry-ingredients {
  margin-bottom: 0.5rem;
  font-style: italic;
}

.entry-nutrients {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 0.5rem;
}

.nutrient {
  display: flex;
  flex-direction: column;
}

.nutrient-label {
  font-size: 0.8rem;
  color: #6c757d;
}

.nutrient-value {
  font-weight: bold;
}

.entry-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
}

.servings-control {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.servings-display {
  width: 40px;
  text-align: center;
  display: inline-block;
}

.servings-btn {
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 0;
  min-width: 24px;
}

.servings-btn:hover {
  background-color: #5a6268;
}

.edit-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-btn:hover {
  background-color: #0056b3;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 0.5rem;
}

.delete-btn:hover {
  background-color: #c82333;
}
</style>

