<template>
  <div style="padding: 15px">
    <div class="row">
      <div class="col-lg-1 d-none d-lg-block"></div>
      <div class="col-lg-2">
        <button
          class="filter-button"
          v-on:click="showAlle()"
          v-bind:style="
            active_btn == 'Alle' ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)' : ''
          "
        >
          Alle
        </button>
      </div>
      <div class="col-lg-2">
        <button
          class="filter-button"
          v-on:click="showLaufen()"
          v-bind:style="
            active_btn == 'Laufen' ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)' : ''
          "
        >
          Laufen
        </button>
      </div>
      <div class="col-lg-2">
        <button
          class="filter-button"
          v-on:click="showRadfahren()"
          v-bind:style="
            active_btn == 'Radfahren' ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)' : ''
          "
        >
          Radfahren
        </button>
      </div>
      <div class="col-lg-2">
        <button
          class="filter-button"
          v-on:click="showSchwimmen()"
          v-bind:style="
            active_btn == 'Schwimmen' ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)' : ''
          "
        >
          Schwimmen
        </button>
      </div>
      <div class="col-lg-2">
        <button
          class="filter-button"
          v-on:click="showKraft()"
          v-bind:style="
            active_btn == 'Kraft' ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)' : ''
          "
        >
          Athletik
        </button>
      </div>
    </div>
    <div class="row top-spacer-25">
      <div class="col-lg-4"></div>
      <div class="col-lg-2">
        <div class="slidecontainer">
          Dauer mindestens {{ formattedTime(durationMin) }}
          <input
            type="range"
            v-bind:min="durationMinStart"
            v-bind:max="durationMaxStart"
            class="slider"
            id="durationMin"
            v-model.number="durationMin"
            v-on:change="durationChange()"
          />
        </div>
      </div>
      <div class="col-lg-2">
        <div class="slidecontainer">
          Dauer höchstens {{ formattedTime(durationMax) }}
          <input
            type="range"
            v-bind:min="durationMinStart"
            v-bind:max="durationMaxStart"
            class="slider"
            id="durationMax"
            v-model.number="durationMax"
            v-on:change="durationChange()"
          />
        </div>
      </div>
    </div>
    <div class="row top-spacer-50">
      <div class="col-lg-12">
        <div class="row">
          <div
            class="col-lg-2"
            v-for="workout in workoutsListTimeFiltered"
            :key="workout.id"
            @click="addWorkoutToCalendar(workout)"
          >
            <div class="workout-box">
              <div class="sport">
                <span class="sport-inner" v-bind:style="workout.durationIs ? 'font-weight:bold;margin-right:-20px' : ''"
                  >{{ workout.name }}
                </span>
              </div>
              <div class="row work" style="background: #cacaca; padding: 7px 5px">
                <div class="col-lg-12">
                  <span v-if="!workout.workoutFile || workout.workoutFile.length == 0">
                    <svg class="workoutBuilderBlock" v-bind:width="150" v-bind:height="18">
                      <rect v-bind:width="100 + '%'" v-bind:height="50" style="fill: rgba(94, 110, 255, 0.97)" />
                    </svg>
                  </span>
                  <span v-for="value in workout.workoutFile" :key="value.id">
                    <svg
                      v-if="value.type == 'SteadyState'"
                      class="workoutBuilderBlock"
                      v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
                      v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                    >
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="100 + '%'"
                        v-bind:height="value.Power / 4"
                        v-bind:style="'fill:' + colorForPower(value.Power)"
                      />
                    </svg>

                    <svg
                      v-if="value.type == 'Ramp'"
                      class="workoutBuilderBlock"
                      v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
                      v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                    >
                      <rect
                        v-for="index in 20"
                        :key="index"
                        v-bind:id="value.id + '-' + index"
                        v-bind:width="100 / 20 + '%'"
                        v-bind:x="(100 / 20) * index - 5 + '%'"
                        v-bind:height="getRampRectHeight(20, value.PowerLow / 4, value.PowerHigh / 4, index)"
                        v-bind:style="'fill:' + colorForPower((value.PowerLow + value.PowerHigh) / 2)"
                      />
                    </svg>

                    <svg
                      v-if="value.type == 'FreeRide'"
                      class="workoutBuilderBlock"
                      v-bind:width="value.Duration / 15"
                      v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                    >
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="value.Duration / 15"
                        v-bind:height="80"
                        style="fill: rgba(94, 110, 255, 0.5)"
                      />
                    </svg>

                    <span v-if="value.type == 'IntervalsT'">
                      <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                        <svg
                          class="workoutBuilderBlock"
                          v-bind:width="computeWidth(value.OnDuration, workout.workoutFile)"
                          v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                        >
                          <rect
                            v-bind:id="value.id"
                            v-bind:width="'100%'"
                            v-bind:height="value.OnPower / 4"
                            v-bind:style="'fill:' + colorForPower(value.OnPower)"
                          />
                        </svg>
                        <svg
                          class="workoutBuilderBlock"
                          v-bind:width="computeWidth(value.OffDuration, workout.workoutFile)"
                          v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                        >
                          <rect
                            v-bind:id="value.id"
                            v-bind:width="'100%'"
                            v-bind:height="value.OffPower / 4"
                            v-bind:style="'fill:' + colorForPower(value.OffPower)"
                          />
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
              </div>

              <div v-bind:style="workout.durationIs ? 'font-weight:bold' : ''">
                <div style="margin-top: 5px">
                  <span v-if="workout.sport == 'Kraft'"
                    ><img src="@/assets/images/strength.png" style="width: 25px"
                  /></span>
                  <span v-if="workout.sport == 'Laufen'"
                    ><img src="@/assets/images/run.png" style="width: 25px"
                  /></span>
                  <span v-if="workout.sport == 'Schwimmen'"
                    ><img src="@/assets/images/swim.png" style="width: 25px"
                  /></span>
                  <span v-if="workout.sport == 'Radfahren'"
                    ><img src="@/assets/images/bike.png" style="width: 25px"
                  /></span>
                </div>
                <!--
          <span v-bind:style="{ color: colorForDuration(totalWorkoutLength(workout.workoutFile), workout.durationIs, workout.date ) }" v-if="!isInPast(workout.date)">{{formattedTime(totalWorkoutLength(workout.workoutFile))}}</span> 
          <span v-bind:style="{ color: colorForDuration(totalWorkoutLength(workout.workoutFile), workout.durationIs, workout.date ) }" v-if="isInPast(workout.date)"><strong>{{formattedTime(workout.durationIs || 0)}}</strong></span> |
            -->
                <span v-if="!workout.durationIs && workout.workoutFile && workout.workoutFile.length > 0"
                  >{{ formattedTime(totalWorkoutLength(workout)) }}
                </span>
                <span v-if="!workout.durationIs && workout.workoutFile && workout.workoutFile.length == 0">{{
                  formattedTime(totalWorkoutLength(workout) || workout.durationShould)
                }}</span>

                <span v-if="workout.durationIs">{{ formattedTime(workout.durationIs) }}</span>

                <span v-if="workout.paceAvg && workout.sport == 'Laufen'">
                  @
                  {{
                    $moment()
                      .startOf("day")
                      .seconds(Math.round(workout.paceAvg * 60))
                      .format("mm:ss")
                  }}min/km
                </span>
                <span v-if="workout.paceAvg && workout.sport == 'Radfahren'">
                  @ {{ Math.round((60 / workout.paceAvg) * 10) / 10 }}km/h
                </span>
                <span v-if="workout.heartrateAvg">{{ workout.heartrateAvg }}bpm</span>
                <span v-if="workout.distance"><br />{{ Math.round(workout.distance * 10) / 10 }}km</span>
                <span v-if="workout.powerInWatts"> | {{ Math.round(workout.powerInWatts * 10) / 10 }}w</span>

                <span v-if="workout.sport == 'Radfahren' || workout.sport == 'Laufen'">
                  <span
                    v-if="
                      workout.sport == 'Radfahren' &&
                      !workout.durationIs &&
                      workout.workoutFile &&
                      workout.workoutFile.length > 0
                    "
                  >
                    Ø {{ computeAvgWatt(workout.workoutFile) }}w</span
                  >
                  <span
                    v-if="
                      workout.sport == 'Laufen' &&
                      !workout.durationIs &&
                      workout.workoutFile &&
                      workout.workoutFile.length > 0
                    "
                    >Ø {{ computeAvgRunSpeed(workout.workoutFile) }}m/km</span
                  >
                  <!-- <span v-if="workout.sport == 'Schwimmen'"> Ø {{computeAvgSwimSpeed(workout.workoutFile)}}m/km</span>--> </span
                ><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import calendar_utils from "@/js/calendar_utils";

export default {
  name: "WorkoutBlueprints",
  components: {},
  data() {
    return {
      thresholds: {},

      durationMin: 0,
      durationMax: 0,
      durationMinStart: 0,
      durationMaxStart: 0,
      workoutBlueprintsUser: [],
      workoutBlueprintsGlobal: [],
      allWorkoutsList: [],
      workoutsList: [],
      workoutsListTimeFiltered: [],
      active_btn: "Alle",
      list: new Array(250),
      runThreshold: 0,
    };
  },
  methods: {
    getRampRectHeight(numberOfBlocks, startIntensity, endIntensity, index) {
      let res = startIntensity - ((startIntensity - endIntensity) / numberOfBlocks) * index;

      return res;
    },
    showAlle() {
      this.active_btn = "Alle";

      this.workoutsList = this.allWorkoutsList;
      this.filterByDuration();
    },
    showLaufen() {
      this.active_btn = "Laufen";
      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Laufen");
      this.filterByDuration();
    },
    showRadfahren() {
      this.active_btn = "Radfahren";
      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Radfahren");
      this.filterByDuration();
    },
    showSchwimmen() {
      this.active_btn = "Schwimmen";
      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Schwimmen");
      this.filterByDuration();
    },
    colorForPower(power) {
      if (power > 105) {
        return "darkred";
      } else if (power > 100) {
        return "brown";
      } else if (power > 95) {
        return "firebrick";
      } else if (power > 88) {
        return "darkgreen";
      } else if (power > 83) {
        return "green";
      } else {
        return "rgba(94, 110, 255, 0.97)";
      }
    },
    showKraft() {
      this.active_btn = "Kraft";

      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Kraft");
      this.filterByDuration();
    },
    durationChange() {
      this.filterByDuration();
    },

    //TODO FTP
    computeAvgWatt(workoutFile) {
      return calendar_utils.computeAvgPowerFromWorkoutFile(workoutFile, this.thresholds.ftp);
    },

    filterByDuration() {
      const _self = this;

      if (_self.durationMin > _self.durationMax) {
        _self.durationMax = _self.durationMin;
      }

      this.workoutsListTimeFiltered = this.workoutsList.filter((workout) => {
        const workoutLength = _self.totalWorkoutLength(workout);
        return workoutLength >= _self.durationMin && workoutLength <= _self.durationMax;
      });
    },

    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H[h ]mm[min]");
    },

    computeWidth(val, workoutFile) {
      const width = Math.floor((val / this.totalWorkoutLength(workoutFile, workoutFile)) * 100) - 1;

      return width > 1 ? width + "%" : "1px";
    },

    setMaxDuration() {
      const _self = this;

      this.durationMax = Math.max.apply(
        Math,
        this.workoutsList.map(function (workout) {
          return _self.totalWorkoutLength(workout);
        })
      );

      this.durationMaxStart = this.durationMax;
    },

    computeAvgRunSpeed(workoutFile) {
      return this.$moment()
        .startOf("day")
        .seconds(calendar_utils.computeSpeedFromWorkoutFile(workoutFile, this.thresholds.threshold_run))
        .format("mm:ss");
    },
    setMinDuration() {
      const _self = this;
      this.durationMin = Math.min.apply(
        Math,
        this.workoutsList.map(function (workout) {
          return _self.totalWorkoutLength(workout);
        })
      );

      this.durationMinStart = this.durationMin;
    },

    workoutBuilderHeight(workoutFile) {
      let maxPower = Math.max.apply(
        Math,
        workoutFile.map(function (o) {
          let onPower = typeof o.OnPower == "number" ? o.OnPower : 0;
          let offPower = typeof o.OffPower == "number" ? o.OffPower : 0;
          let power = typeof o.Power == "number" ? o.Power : 0;
          let powerLow = typeof o.PowerLow == "number" ? o.PowerLow : 0;
          let powerHigh = typeof o.PowerHigh == "number" ? o.PowerHigh : 0;
          return Math.max(onPower, offPower, power, powerLow, powerHigh);
        })
      );
      return Math.min(maxPower / 4, 35);
    },
    totalWorkoutLength(workout, workoutFile) {
      // Workaround
      if (workout && workout.sport == "Schwimmen") {
        return workout.durationShould;
      }

      if (!workoutFile) {
        workoutFile = workout.workoutFile;
      }

      const _self = this;
      let length = workoutFile.reduce(function (prev, cur) {
        let distance = prev;
        let interval_distance = 0;

        if (!(cur.Distance > 0 || cur.OnDistance > 0 || cur.OffDistance > 0)) {
          distance +=
            (cur.Duration > 0 ? cur.Duration : 0) +
            (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0);
        }

        if (cur.Distance > 0) {
          distance += Math.round(
            (cur.Distance / 1000) * (_self.runThreshold + _self.runThreshold * (1 - cur.Power / 100))
          );
        }

        if (cur.OnDistance > 0) {
          interval_distance = Math.round(
            (cur.OnDistance / 1000) * (_self.runThreshold + _self.runThreshold * (1 - cur.OnPower / 100))
          );

          distance += interval_distance * cur.Repeat;
        }
        if (cur.OffDistance > 0) {
          interval_distance = Math.round(
            (cur.OffDistance / 1000) * (_self.runThreshold + _self.runThreshold * (1 - cur.OffPower / 100))
          );

          distance += interval_distance * cur.Repeat;
        }

        return distance;
      }, 0);

      return length;
    },

    addWorkoutToCalendar(workout) {
      let req = {
        trainingsplan_id: this.trainingsplan_id,
        workout_blueprint_id: workout._id,
        day: this.day,
      };

      const _self = this;

      axios
        .post(this.$host + "/trainingsplan/add_entry", req)
        .then(function () {
          // Beim Ändern Event Emitten
          _self.$emit("wasSaved");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created: async function () {
    const _self = this;

    this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;

    this.runThreshold = this.thresholds.threshold_run;

    console.log("thresholds", this.thresholds);

    axios
      .get(this.$host + "/workout/blueprints2/v2")
      .then(function (response) {
        _self.workoutBlueprintsGlobal = response.data.global;
        _self.workoutBlueprintsUser = response.data.user;

        _self.allWorkoutsList = _self.workoutBlueprintsGlobal.concat(_self.workoutBlueprintsUser);
        _self.workoutsList = _self.allWorkoutsList;

        _self.workoutsListTimeFiltered = _self.workoutsList.sort(function (a, b) {
          var durationA = _self.totalWorkoutLength(a) || a.durationShould;
          var durationB = _self.totalWorkoutLength(b) || b.durationShould;

          if (durationA < durationB) {
            return -1;
          }
          if (durationA > durationB) {
            return 1;
          }

          // Namen müssen gleich sein
          return 0;
        });
        _self.setMaxDuration();
        _self.setMinDuration();
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  props: {
    day: Number,
    trainingsplan_id: String,
  },
};
</script>

<style scoped>
.filter-button {
  width: 100%;
}

.title {
  font-weight: bold;
  background: #ccc;
  color: #000;
}

svg {
  transform: scaleY(-1);
}

.workoutBuilderBlock {
  display: inline-block;
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #bbb; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border: 0;
  border-radius: 30px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgba(94, 110, 255, 0.97); /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgba(94, 110, 255, 0.97); /* Green background */
  border: 0;
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}
</style>
