
<template>
  <div>
    <div style="height: 280px">
      <canvas ref="graph" id="graph" width="100%"></canvas>
    </div>
    <div class="row top-spacer-15">
      <div class="col-4">
        <h4>VO2max</h4>
        <h4>{{ vo2max() }}ml/kg/min</h4>
      </div>
      <div class="col-4">
        <h4>FTP</h4>
        <h4>{{ this.ftp }}w</h4>
      </div>
      <div class="col-4">
        <h4>FatMax</h4>
        <h4>{{ fatMax() }}w</h4>
      </div>
    </div>
    <!--
    <div class="col-lg-12 top-spacer-25">
      <h4>Watt bis Entleerung der KH-Speicher</h4>
    </div>
    <div
      class="row"
      style="
        text-align: center;
        display: block;
        width: 100%;
        overflow-x: scroll;
      "
    >
      <div class="col-lg-12">
        <table>
          <tr>
            <th>Zufuhr</th>
            <th>3h</th>
            <th>4h</th>
            <th>5h</th>
            <th>6h</th>
            <th>7h</th>
            <th>8h</th>
            <th>9h</th>
            <th>10h</th>
            <th>11h</th>
            <th>12h</th>
            <th>13h</th>
          </tr>
          <tr>
            <td>Ohne</td>
            <td>{{ entleerungKhSpeicher(3, false) }}</td>
            <td>{{ entleerungKhSpeicher(4, false) }}</td>
            <td>{{ entleerungKhSpeicher(5, false) }}</td>
            <td>{{ entleerungKhSpeicher(6, false) }}</td>
            <td>{{ entleerungKhSpeicher(7, false) }}</td>
            <td>{{ entleerungKhSpeicher(8, false) }}</td>
            <td>{{ entleerungKhSpeicher(9, false) }}</td>
            <td>{{ entleerungKhSpeicher(10, false) }}</td>
            <td>{{ entleerungKhSpeicher(11, false) }}</td>
            <td>{{ entleerungKhSpeicher(12, false) }}</td>
            <td>{{ entleerungKhSpeicher(13, false) }}</td>
          </tr>
          <tr>
            <td>Optimal</td>
            <td>{{ entleerungKhSpeicher(3, true) }}</td>
            <td>{{ entleerungKhSpeicher(4, true) }}</td>
            <td>{{ entleerungKhSpeicher(5, true) }}</td>
            <td>{{ entleerungKhSpeicher(6, true) }}</td>
            <td>{{ entleerungKhSpeicher(7, true) }}</td>
            <td>{{ entleerungKhSpeicher(8, true) }}</td>
            <td>{{ entleerungKhSpeicher(9, true) }}</td>
            <td>{{ entleerungKhSpeicher(10, true) }}</td>
            <td>{{ entleerungKhSpeicher(11, true) }}</td>
            <td>{{ entleerungKhSpeicher(12, true) }}</td>
            <td>{{ entleerungKhSpeicher(13, true) }}</td>
          </tr>
        </table>
      </div>
    </div>
    <small
      >Von uns entwickelte Schätzung, wie lange die Kohlenhydratspeicher unter
      der Annahme, dass die Kohlenhydratspeicher voll gefüllt sind, genutzt
      werden können. Dies bedeuet nicht, dass diese Leistung über den Zeitraum
      auch geleistet werden kann.</small
    >-->
  </div>
</template>


<script>
import { Chart } from "chart.js";
//import moment from 'moment-timezone'

export default {
  props: ["ftp", "weight"],
  // TODO: SCHWELLE LAUFEN + SCHWIMMEN auf 2. Y ACHSE. Höher ist besser
  data() {
    return {
      chartData: {
        labels: this.labels,
      },

      options: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },

        plugins: {
          annotation: {
            drawTime: "beforeDatasetsDraw",
            annotations: [
              {
                type: "box",

                xMin: 0,
                xMax: 32,

                backgroundColor: "rgba(55, 150, 55, 0.7)",
                borderColor: "transparent",
                border: 0,
                borderWidth: 0,
              },
              {
                type: "box",

                xMin: 32,
                xMax: 40,

                backgroundColor: "rgba(232, 126, 4,0.7) ",
                borderColor: "transparent",
                border: 0,
                borderWidth: 0,
              },
              {
                type: "box",

                xMin: 40,

                backgroundColor: "rgba(232, 55, 4,0.7) ",
                borderColor: "transparent",
                border: 0,
                borderWidth: 0,
              },
            ],
          },
          tooltip: {
            enabled: true, // sonst folgende settings:

            mode: "index",
            callbacks: {
              label: (context) => {
                // data for manipulation
                const val = context.formattedValue;

                const index = context.datasetIndex;
                let prefix = "";
                let middle = "";

                if (index == 0) {
                  prefix = "Kohlenhydrate: ";
                } else if (index == 1) {
                  prefix = "Fette: ";
                } else if (index == 2) {
                  prefix = "Gesamt: ";
                }

                if (index == 0 || index == 1) {
                  middle = prefix + val + "kcal";
                } else {
                  middle = prefix + val + "kcal";
                }

                if (index == 0 || index == 1) {
                  return middle;
                } else {
                  if (index == 2) {
                    return middle;
                  } else {
                    return middle;
                  }
                }
              },
            },
          },
        },

        aspectRatio: 1,
        layout: {
          padding: 0,
          margin: 0,
        },
        animation: {
          duration: 0,
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0,
          },
        },

        scales: {
          yB: {
            display: true,
            stacked: false,
            grid: {
              drawBorder: true,
              display: true,
            },
            ticks: {
              display: true,
              beginAtZero: true,
            },
            title: {
              display: false,
              text: "kcal",
            },
          },

          x: {
            grid: {
              display: true,
            },
            ticks: {
              display: true,
              maxTicksLimit: 6,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    let ctx = this.$refs.graph.getContext("2d");

    let fat = [];
    let cho = [];

    let cal = [];

    let labels = [];
    for (let i = 0; i <= 100; i++) {
      const fatCho = this.fat_and_carbs_utilization_at_PctVo2max(i);
      const watt = Math.round((this.ftp / 0.805) * (i / 100));
      const calories = watt * 1 * 3.6 || 1;
      fat.push(Math.round((fatCho.fat / 100) * calories));
      cho.push(Math.round((fatCho.cho / 100) * calories));
      cal.push(calories);

      labels.push(watt + "w");
      i++;
    }
    this.chartData.labels = labels;
    this.chartData.datasets = [
      {
        data: cho,
        fill: false,
        borderColor: "rgba(0,0,0, 1)",
        backgroundColor: "rgba(0,0,0, 1)",
        borderWidth: 2,
        yAxisID: "yB",
        label: "Kohlenhydrate",
      },
      {
        data: fat,
        fill: false,
        borderColor: "rgba(220,40,40, 1)",
        backgroundColor: "rgba(220,40,40, 1)",
        borderWidth: 2,
        yAxisID: "yB",
        label: "Fette",
      },

      {
        data: cal,
        fill: true,
        borderColor: "rgba(0,0,0, 0.1)",
        backgroundColor: "rgba(0,0,0, 0.2)",
        borderWidth: 2,
        yAxisID: "yB",
        label: "Kalorien",
      },
    ];

    const config = {
      type: "line",
      data: this.chartData,
      options: this.options,

      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yA = chart.scales.yA;
              let yB = chart.scales.yB;

              let ctx = chart.ctx;
              ctx.save();

              if (yA) {
                ctx.beginPath();
                ctx.moveTo(x, yA.top);
                ctx.lineTo(x, yA.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }

              if (yB) {
                ctx.beginPath();
                ctx.moveTo(x, yB.top);
                ctx.lineTo(x, yB.bottom);
                ctx.lineWidth = 2;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }
            }
          },
        },
      ],
    };

    new Chart(ctx, config);
  },

  methods: {
    // 0.805 * ftp = 6min Power (eigentlich 0.815?)
    //https://www.michael-konczer.com/en/training/calculators/calculate-vo2max
    // VO2max=6minPower⋅10.8weight+7

    vo2max() {
      return Math.round(((this.ftp / 0.805) * 10.8) / this.weight + 7);
    },

    khSpeicher() {
      // approx, besser trainiert korreliert mit höherer speicherkapazität
      return (this.vo2max() / 3) * this.weight;
    },

    entleerungKhSpeicher(stunden, zufuhr = false) {
      let kh = this.khSpeicher();

      if (zufuhr) {
        kh += this.khAufnahme() * 4 * stunden;
      }

      let khProStunde = kh / stunden;
      let calories = 0;
      let i = 0;
      let watt = 0;
      for (i = 0; i <= 100; i++) {
        const fatCho = this.fat_and_carbs_utilization_at_PctVo2max(i);
        watt = Math.round((this.ftp / 0.805) * (i / 100));
        calories = watt * 1 * 3.6 * (fatCho.cho / 100);
        if (calories > khProStunde) {
          break;
        }
      }

      if (i == 100) {
        return watt + "+";
      }

      return watt + "";
    },

    khAufnahme() {
      // approx
      return this.weight / 2 + 35;
    },

    fatMax() {
      return Math.round(this.ftp * 0.675);
    },

    crossOver() {
      return Math.round(this.ftp * 0.540625);
    },

    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },

    fat_and_carbs_utilization_at_PctVo2max: function (vo2maxPct) {
      const pctFtp = Math.min(100, vo2maxPct / 0.805);
      const cho = Math.min(
        100,
        Math.max(0, 0.0407 * Math.pow(Math.E, 0.03193 * pctFtp) * 100 + 0)
      );
      return {
        cho: cho,
        fat: 100 - cho,
      };
    },
  },
};
</script>