<template>
  <div class="white_wrapper">
    <div class="col-lg-12">
      <button @click="takePicture">+ Foto machen</button>
    </div>
    <div class="row">
      <div class="col-lg-12 centered">
        <img v-if="photo" :src="photo.dataUrl" />
      </div>

      <div class="col-lg-12 centered top-spacer-25" v-if="photo">
        <h4>Wie passt das Essen zu deinen persönlichen Zielen?</h4>
        <div class="row">
          <div class="col-12 centered top-spacer-15">
            <button @click="uploadPicture('Good')" class="closer">Gut</button>
          </div>
          <div class="col-12 centered top-spacer-15">
            <button @click="uploadPicture('Okay')" class="thirdary">
              Okay
            </button>
          </div>
          <div class="col-12 centered top-spacer-15">
            <button @click="uploadPicture('Bad')" class="delete">
              Schlecht
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Camera, CameraResultType } from "@capacitor/camera";
import axios from "axios";

export default {
  data() {
    return {
      photo: null,
    };
  },
  methods: {
    async takePicture() {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.DataUrl,
      });

      this.photo = image;
    },
    async uploadPicture(rating) {
      // Convert DataUrl to Blob
      const response = await fetch(this.photo.dataUrl);
      const blob = await response.blob();

      console.log("Okay upload...");

      // Create FormData
      const formData = new FormData();
      formData.append("file", blob, "photo.jpg");
      formData.append("rating", rating);

      // Send a POST request to your API
      try {
        const res = await axios.post(this.$host + "/food/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(res.data);

        this.$router.push({ name: "FoodDiary" });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
img {
  margin: 20px;
  width: 300px;
  height: 300px;
  object-fit: cover;
}
</style>
