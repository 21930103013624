<template>
  <div class="row" v-show="workouts.length > 1">
    <div class="col-lg-12">
      <div class="top-spacer-25 white_wrapper_tighter">
        <h4>{{ $t(`Schwellenschätzung Rad`) }}</h4>
        <div style="height: 200px">
          <canvas ref="stressGraph" id="stressGraph" width="100%"></canvas>
        </div>

        <div class="top-spacer-5">
          <small>{{
            $t(
              `Die blaue Linie zeigt eine Schätzung für jede Einheit, die schwarze Linie ist eine Glättung und damit genauer. Die Genauigkeit hängt von den verfügbaren Daten ab. Je abwechslungsreicher dein Training, desto genauer wird die Schätzung.`
            )
          }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";

export default {
  props: ["done"],

  data: function () {
    return {
      workouts: [],
      ctx: undefined,

      chartdata: {
        labels: [],
        datasets: [
          {
            borderColor: "rgb(100, 149, 237)",
            backgroundColor: "rgb(100, 149, 237)",

            data: [],
            yAxisID: "y",
            borderWidth: 3,
            order: 10,
            tension: 0.4,
            //fill: true,
          },
          {
            borderColor: "rgb(10, 14, 23)",
            backgroundColor: "rgb(10, 14, 23)",

            data: [],
            yAxisID: "y",
            borderWidth: 3,
            order: 10,
            tension: 0.4,
            //fill: true,
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.formattedValue + "w";
              },
            },
          },
        },

        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },

        scales: {
          y: {
            grid: {
              display: true,
            },
            stacked: false,
            id: "stack",
            position: "right",
            display: true,

            ticks: {
              beginAtZero: true,
              maxTicksLimit: 6,
              callback: function (label) {
                return label + "w";
              },
            },
          },
          xAxes: {
            grid: {
              display: true,
            },
            id: "x-axis-1",
            gridLines: {
              display: false,
            },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  async mounted() {
    let ctx = this.$refs.stressGraph.getContext("2d");
    this.ctx = ctx;

    this.workouts = this.done;

    this.computeChartData();

    const config = {
      type: "line",
      data: this.chartdata,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.y;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.yAxes;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
      ],
    };

    new Chart(this.ctx, config);

    this.$emit("chartRendered");
  },

  methods: {
    formatDate: function (value) {
      return value.format("DD.MM");
    },

    computeChartData: function () {
      this.workouts = this.workouts.filter((wo) => {
        return wo.sport == "Radfahren" && (wo.best_prediction_bike || wo.historic_ftp);
      });

      this.workouts = this.workouts.sort(function (a, b) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      for (const [i, workout] of this.workouts.entries()) {
        let wo = this.workouts;
        let arr = wo.slice(0, i + 1).slice(-4);

        let sum = arr.reduce((a, b) => a + (b.historic_ftp || b.best_prediction_bike), 0);

        const avg = Math.round(sum / arr.length);

        this.chartdata.labels.push(this.formatDate(moment(workout.date)));

        this.chartdata.datasets[0].data.push(workout.historic_ftp || workout.best_prediction_bike);
        if (avg) {
          this.chartdata.datasets[1].data.push(avg);
        } else {
          this.chartdata.datasets[1].data.push(undefined);
        }
      }

      return this.chartdata;
    },
  },
};
</script>
