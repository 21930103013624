<template>
  <div class="row">
    <div class="col-lg-12 top-spacer-25">
      <div class="white_wrapper_tighter">
        <canvas
          ref="hrvGraph"
          id="hrvGraph"
          style="height: 200px"
          width="100%"
        ></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "chart.js";

export default {
  name: "HealthChartHRV",
  props: {
    health: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      counter: 0,
    };
  },
  computed: {
    chartData() {
      const labels = [];

      const hrvData = [];

      for (const date in this.health) {
        labels.push(this.$moment(date).format("DD.MM.YYYY"));

        const data = this.health[date];

        if (data.hrvData) {
          hrvData.push(data.hrvData.lastNightAvg);
        } else {
          hrvData.push(undefined);
        }
      }

      return {
        labels: labels,
        datasets: [
          {
            label: "Herzfrequenzvariabilität",
            data: hrvData,
            borderColor: "#8e5ea2",
            backgroundColor: "#8e5ea2",
            fill: false,
            yAxisID: "y",
          },
        ],
      };
    },
  },
  mounted() {
    const ctx = this.$refs.hrvGraph.getContext("2d");

    new Chart(ctx, {
      type: "line",
      data: this.chartData,
      options: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "HRV",
          },
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                var datasetLabel =
                  context.dataset.label + ": " + context.parsed.y;
                return datasetLabel;
              },
            },
          },
        },
        scales: {
          y: {
            type: "linear",
            position: "right",
            ticks: {
              beginAtZero: true,
            },
          },

          xAxes: {
            ticks: {
              maxTicksLimit: 8, // show no more than 8 labels
            },
          },
        },
        spanGaps: true, // enable spanning gaps in the data
        elements: {
          point: {
            radius: 0,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    });

    this.$emit("chartRendered");
  },
};
</script>
