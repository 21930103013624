<template>
  <b-modal
    id="ai-coach-preferences-modal"
    title="KI-Trainer Konfiguration"
    size="lg"
    @hidden="resetForm"
    hide-footer
    centered
    dialog-class="ai-coach-modal"
  >
    <b-progress :value="currentStep" :max="3" class="mb-3"></b-progress>

    <!-- Schritt 1: Trainingstage -->
    <div v-if="currentStep === 1">
      <h4>{{ $t("Schritt 1: Trainingstage") }}</h4>
      <b-list-group>
        <b-list-group-item v-for="day in weekDays" :key="day" class="py-2">
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ $t(day) }}</span>
            <b-form-checkbox v-model="trainingDays[day]" switch>
              {{ trainingDays[day] ? $t("Training") : $t("Ruhetag") }}
            </b-form-checkbox>
          </div>
          <b-collapse :visible="trainingDays[day]">
            <div class="mt-2">
              <b-form-select
                v-model="trainingSports[day].first"
                :options="sportOptions"
                size="sm"
                class="mb-2"
              ></b-form-select>
              <b-form-select v-model="trainingSports[day].second" :options="sportOptions" size="sm"></b-form-select>
            </div>
          </b-collapse>
        </b-list-group-item>
      </b-list-group>
    </div>

    <!-- Schritt 2: Verfügbare Trainingszeiten -->
    <div v-if="currentStep === 2">
      <h4>{{ $t("Schritt 2: Verfügbare Trainingszeiten") }}</h4>
      <b-list-group>
        <b-list-group-item v-for="day in filteredTrainingDays" :key="day" class="py-2">
          <div class="d-flex justify-content-between align-items-center">
            <strong>{{ $t(day) }}</strong>
            <span
              ><strong>{{ $t("Gesamtzeit") }}: {{ formatTime(getDailyTotalTime(day)) }}</strong></span
            >
          </div>
          <div class="mt-2">
            <div v-if="trainingSports[day].first" class="d-flex align-items-center mb-2">
              <div class="sport-badge">
                <b-badge :variant="getSportVariant(trainingSports[day].first)" class="mr-2">
                  {{ trainingSports[day].first }}
                </b-badge>
              </div>
              <div class="time-input-group">
                <b-form-input
                  v-model="availableDurations[day].first.hours"
                  type="number"
                  :placeholder="$t('Stunden')"
                  size="sm"
                  min="0"
                  max="6"
                ></b-form-input>
                <span class="time-separator">:</span>
                <b-form-input
                  v-model="availableDurations[day].first.minutes"
                  type="number"
                  :placeholder="$t('Minuten')"
                  size="sm"
                  min="0"
                  max="59"
                ></b-form-input>
              </div>
            </div>
            <div v-if="trainingSports[day].second" class="d-flex align-items-center">
              <div class="sport-badge">
                <b-badge :variant="getSportVariant(trainingSports[day].second)" class="mr-2">
                  {{ trainingSports[day].second }}
                </b-badge>
              </div>
              <div class="time-input-group">
                <b-form-input
                  v-model="availableDurations[day].second.hours"
                  type="number"
                  :placeholder="$t('Stunden')"
                  size="sm"
                  min="0"
                  max="6"
                ></b-form-input>
                <span class="time-separator">:</span>
                <b-form-input
                  v-model="availableDurations[day].second.minutes"
                  type="number"
                  :placeholder="$t('Minuten')"
                  size="sm"
                  min="0"
                  max="59"
                ></b-form-input>
              </div>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
      <div v-if="!anyTrainingDays" class="text-center mt-3">
        <b-alert show variant="info">{{ $t("Keine Trainingstage ausgewählt") }}</b-alert>
      </div>
      <div v-else class="d-flex justify-content-end mt-3">
        <strong>{{ $t("Wöchentliche Gesamttrainingszeit") }}: {{ formatTime(getWeeklyTotalTime()) }}</strong>
      </div>
    </div>

    <!-- Schritt 3: Aktuelles Trainingsvolumen -->
    <div v-if="currentStep === 3">
      <h4>{{ $t("Schritt 3: Aktuelles Trainingsvolumen pro Woche") }}</h4>
      <b-list-group>
        <b-list-group-item v-for="sport in sports" :key="sport" class="py-2">
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ $t(sport) }}</span>
            <div class="time-input-group">
              <b-form-input
                v-model="currentVolume[sport].hours"
                type="number"
                :placeholder="$t('Std')"
                size="sm"
                min="0"
              ></b-form-input>
              <span class="time-separator">:</span>
              <b-form-input
                v-model="currentVolume[sport].minutes"
                type="number"
                :placeholder="$t('Min')"
                size="sm"
                min="0"
                max="59"
              ></b-form-input>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
      <div class="mt-3">
        <strong>{{ $t("Gesamtes aktuelles Trainingsvolumen") }}: {{ formatTime(getTotalCurrentVolume()) }}</strong>
      </div>

      <h4 class="mt-4">{{ $t("Zusammenfassung aller Eingaben") }}</h4>
      <pre class="json-summary">{{ JSON.stringify(getPreferencesSummary(), null, 2) }}</pre>
    </div>

    <!-- Navigationsbuttons -->
    <div class="d-flex justify-content-between mt-3">
      <b-button v-if="currentStep > 1" @click="previousStep" variant="secondary" size="sm">{{ $t("Zurück") }}</b-button>
      <b-button v-if="currentStep < 3" @click="nextStep" variant="primary" size="sm">{{ $t("Weiter") }}</b-button>
      <b-button v-if="currentStep === 3" @click="submitPreferences" variant="success" size="sm">{{
        $t("Absenden")
      }}</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AICoachPreferences",
  data() {
    return {
      currentStep: 1,
      weekDays: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
      trainingDays: {},
      trainingSports: {},
      availableDurations: {},
      currentVolume: {},
      sports: ["Schwimmen", "Radfahren", "Laufen", "Athletik"],
      sportOptions: [
        { value: null, text: "Keine Einheit" },
        { value: "Schwimmen", text: "Schwimmen" },
        { value: "Radfahren", text: "Radfahren" },
        { value: "Laufen", text: "Laufen" },
        { value: "Athletik", text: "Athletik" },
      ],
    };
  },
  computed: {
    filteredTrainingDays() {
      return this.weekDays.filter((day) => this.trainingDays[day]);
    },
    anyTrainingDays() {
      return this.filteredTrainingDays.length > 0;
    },
  },
  methods: {
    nextStep() {
      if (this.currentStep < 3) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    submitPreferences() {
      const preferences = {
        trainingDays: this.trainingDays,
        trainingSports: this.trainingSports,
        availableDurations: this.availableDurations,
        currentVolume: this.currentVolume,
        weeklyTotalTime: this.getWeeklyTotalTime(),
        totalCurrentVolume: this.getTotalCurrentVolume(),
      };

      // Convert time to minutes before sending
      for (const day in preferences.availableDurations) {
        for (const session in preferences.availableDurations[day]) {
          preferences.availableDurations[day][session] = this.calculateTotalMinutes(
            preferences.availableDurations[day][session]
          );
        }
      }

      for (const sport in preferences.currentVolume) {
        preferences.currentVolume[sport] = this.calculateTotalMinutes(preferences.currentVolume[sport]);
      }

      console.log("Präferenzen werden gesendet:", preferences);
      // TODO: Implementieren Sie hier den API-Aufruf
      this.$bvModal.hide("ai-coach-preferences-modal");
    },
    resetForm() {
      this.currentStep = 1;
      this.weekDays.forEach((day) => {
        this.$set(this.trainingDays, day, false);
        this.$set(this.trainingSports, day, { first: null, second: null });
        this.$set(this.availableDurations, day, {
          first: { hours: "", minutes: "" },
          second: { hours: "", minutes: "" },
        });
      });

      this.sports.forEach((sport) => {
        this.$set(this.currentVolume, sport, { hours: "", minutes: "" });
      });
    },
    getDailyTotalTime(day) {
      const firstSession = this.availableDurations[day].first;
      const secondSession = this.availableDurations[day].second;
      return this.calculateTotalMinutes(firstSession) + this.calculateTotalMinutes(secondSession);
    },
    getWeeklyTotalTime() {
      return this.filteredTrainingDays.reduce((total, day) => total + this.getDailyTotalTime(day), 0);
    },
    getTotalCurrentVolume() {
      return Object.values(this.currentVolume).reduce((total, time) => total + this.calculateTotalMinutes(time), 0);
    },
    calculateTotalMinutes(time) {
      return (parseInt(time.hours) || 0) * 60 + (parseInt(time.minutes) || 0);
    },
    formatTime(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours}:${minutes.toString().padStart(2, "0")}`;
    },
    getSportVariant(sport) {
      switch (sport) {
        case "Schwimmen":
          return "info";
        case "Radfahren":
          return "success";
        case "Laufen":
          return "danger";
        case "Athletik":
          return "warning";
        default:
          return "secondary";
      }
    },
    getPreferencesSummary() {
      const summary = {
        trainingDays: this.trainingDays,
        trainingSports: this.trainingSports,
        availableDurations: {},
        currentVolume: {},
        weeklyTotalTime: this.formatTime(this.getWeeklyTotalTime()),
        totalCurrentVolume: this.formatTime(this.getTotalCurrentVolume()),
      };

      // Format availableDurations
      for (const day in this.availableDurations) {
        summary.availableDurations[day] = {
          first: this.formatTime(this.calculateTotalMinutes(this.availableDurations[day].first)),
          second: this.formatTime(this.calculateTotalMinutes(this.availableDurations[day].second)),
        };
      }

      // Format currentVolume
      for (const sport in this.currentVolume) {
        const sportVolume = this.currentVolume[sport];
        const totalMinutes = this.calculateTotalMinutes(sportVolume);
        summary.currentVolume[sport] = this.formatTime(totalMinutes);
      }

      return summary;
    },
  },
  created() {
    this.weekDays.forEach((day) => {
      this.$set(this.trainingDays, day, false);
      this.$set(this.trainingSports, day, { first: null, second: null });
      this.$set(this.availableDurations, day, {
        first: { hours: "", minutes: "" },
        second: { hours: "", minutes: "" },
      });
    });

    this.sports.forEach((sport) => {
      this.$set(this.currentVolume, sport, { hours: "", minutes: "" });
    });
  },
  mounted() {
    this.$bvModal.show("ai-coach-preferences-modal");
  },
};
</script>

<style scoped>
::v-deep .ai-coach-modal {
  display: flex;
  align-items: center;
  min-height: calc(100% - 3.5rem);
}

::v-deep .ai-coach-modal .modal-content {
  max-height: 90vh;
  overflow-y: auto;
}

::v-deep .modal-backdrop {
  z-index: 1550;
}

::v-deep .modal {
  z-index: 1560;
}

.btn-group {
  flex-wrap: wrap;
}

.btn-group .btn {
  margin: 2px;
}

.sport-badge {
  display: inline-block;
  width: 100px;
  text-align: right;
  margin-right: 10px;
}

.sport-badge .badge {
  font-size: 0.9em;
  padding: 0.4em 0.6em;
}

.time-input-group {
  display: flex;
  align-items: center;
}

.time-input-group .form-control {
  width: 120px;
  text-align: center;
}

.time-separator {
  margin: 0 5px;
  font-weight: bold;
}

/* Größere Eingabefelder */
::v-deep .form-control {
  font-size: 16px;
  height: auto;
  padding: 8px 12px;
}

.json-summary {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 15px;
  font-size: 14px;
  white-space: pre-wrap;
  word-break: break-all;
  max-height: 300px;
  overflow-y: auto;
}
</style>
