<template>
  <div>
    <button @click="authorize">Sign In with OAuth2 Server</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",

  methods: {
    async authorize() {
      // Read the client_id from the URL query parameters
      const clientId = this.$route.query.client_id;

      if (!clientId) {
        console.error("Error: Client ID not provided");
        return;
      }

      try {
        // Step 1: Create the authorization URL
        const redirectUri = encodeURIComponent(
          "http://localhost:4000/callback"
        );
        const responseType = "code";
        const state = "your_state"; // Generate a unique value for each request
        const authorizationUrl = `/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&state=${state}`;

        // Step 2: Redirect the user to the authorization URL
        axios
          .get(this.$host + authorizationUrl)
          .then(function (res) {
            console.log(res);
            window.location = `${res.data.redirectUri}?code=${res.data.authorizationCodeUid}`;
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (error) {
        console.error("Error: Failed to fetch client data", error);
      }
    },
  },
};
</script>
