//import {timeout} from './lib/utils';

let ble_sint16 = ["getInt16", 2, true];
let ble_uint8 = ["getUint8", 1];
let ble_uint16 = ["getUint16", 2, true];
let ble_uint32 = ["getUint32", 4, true];
// TODO: paired 12bit uint handling
let ble_uint24 = ["getUint8", 3];

// https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.characteristic.cycling_power_measurement.xml
let cycling_power_measurement = [
  [0, [[ble_sint16, "instantaneous_power"]]],
  [1, [[ble_uint8, "pedal_power_balance"]]],
  [
    2,
    [
      /* Pedal Power Balance Reference */
    ],
  ],
  [4, [[ble_uint16, "accumulated_torque"]]],
  [
    8,
    [
      /* Accumulated Torque Source */
    ],
  ],
  [
    16,
    [
      [ble_uint32, "cumulative_wheel_revolutions"],
      [ble_uint16, "last_wheel_event_time"],
    ],
  ],
  [
    32,
    [
      [ble_uint16, "cumulative_crank_revolutions"],
      [ble_uint16, "last_crank_event_time"],
    ],
  ],
  [
    64,
    [
      [ble_sint16, "maximum_force_magnitude"],
      [ble_sint16, "minimum_force_magnitude"],
    ],
  ],
  [
    128,
    [
      [ble_sint16, "maximum_torque_magnitude"],
      [ble_sint16, "minimum_torque_magnitude"],
    ],
  ],
  [256, [[ble_uint24, "maximum_minimum_angle"]]],
  [512, [[ble_uint16, "top_dead_spot_angle"]]],
  [1024, [[ble_uint16, "bottom_dead_spot_angle"]]],
  [2048, [[ble_uint16, "accumulated_energy"]]],
  [
    4096,
    [
      /* Offset Compensation Indicator */
    ],
  ],
];

let ftms_measurement = [
  [4, [[ble_uint16, "cadence"]]],
  [6, [[ble_uint16, "power"]]],
];

/*
const UUIDS = {
  service: 'fitness_machine',
  characteristic: {
    fitnessMachineFeature: 'fitness_machine_feature', // 0x2ACC
    fitnessMachineControlPoint: 'fitness_machine_control_point',//0x2AD9
  }
 
};

const fitnessMachineFeaturesFields = {
  averageSpeedSupported: 0,
  cadenceSupported: 1,
  totalDistanceSupported: 2,
  inclinationSupported: 3,
  elevationGainSupported: 4,
  paceSupported: 5,
  stepCountSupported: 6,
  resistanceLevelSupported: 7,
  strideCountSupported: 8,
  expendedEnergySupported: 9,
  heartRateMeasurementSupported: 10,
  metabolicEquivalentSupported: 11,
  elapsedTimeSupported: 12,
  remainingTimeSupported: 13,
  powerMeasurementSupported: 14,
  forceonBeltandPowerOutputSupported: 15,
  userDataRetentionSupported: 16,
};
const targetSettingsFields = {
  speedTargetSettingSupported: 0,
  inclinationTargetSettingSupported: 1,
  resistanceTargetSettingSupported: 2,
  powerTargetSettingSupported: 3,
  heartRateTargetSettingSupported: 4,
  targetedExpendedEnergyConfigurationSupported: 5,
  targetedStepNumberConfigurationSupported: 6,
  targetedStrideNumberConfigurationSupported: 7,
  targetedDistanceConfigurationSupported: 8,
  targetedTrainingTimeConfigurationSupported: 9,
  targetedTimeinTwoHeartRateZonesConfigurationSupported: 10,
  targetedTimeinThreeHeartRateZonesConfigurationSupported: 11,
  targetedTimeinFiveHeartRateZonesConfigurationSupported: 12,
  indoorBikeSimulationParametersSupported: 13,
  wheelCircumferenceConfigurationSupported: 14,
  spinDownControlSupported: 15,
  targetedCadenceConfigurationSupported: 16,
};
*/

// https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.characteristic.csc_measurement.xml
let csc_measurement = [
  [
    1,
    [
      [ble_uint32, "cumulative_wheel_revolutions"],
      [ble_uint16, "last_wheel_event_time"],
    ],
  ],
  [
    2,
    [
      [ble_uint16, "cumulative_crank_revolutions"],
      [ble_uint16, "last_crank_event_time"],
    ],
  ],
];

class BleCharacteristicParser {
  getData(dataview) {
    let offset = 0;
    let mask;
    if (this.mask_size === 16) {
      mask = dataview.getUint16(0, true);
      offset += 2;
    } else {
      mask = dataview.getUint8(0);
      offset += 1;
    }

    let fieldArrangement = [];

    // Contains required fields
    if (this.fields[0][0] === 0) {
      for (let fdesc of this.fields[0][1]) {
        fieldArrangement.push(fdesc);
      }
    }

    for (let [flag, fieldDescriptions] of this.fields) {
      if (mask & flag) {
        for (let fdesc of fieldDescriptions) {
          fieldArrangement.push(fdesc);
        }
      }
    }

    let data = {};
    for (let field of fieldArrangement) {
      var [[accessor, fieldSize, endianness], fieldName] = field;
      let value;
      if (endianness) {
        value = dataview[accessor](offset, endianness);
      } else {
        value = dataview[accessor](offset);
      }

      data[fieldName] = value;
      offset += fieldSize;
    }

    return data;
  }
}

export class CyclingSpeedCadenceMeasurementParser extends BleCharacteristicParser {
  constructor() {
    super();
    this.fields = csc_measurement;
    this.mask_size = 8;
  }
}

export class CyclingPowerMeasurementParser extends BleCharacteristicParser {
  constructor() {
    super();
    this.fields = cycling_power_measurement;
    this.mask_size = 16;
  }
}

export class FTMSMeasurementParser extends BleCharacteristicParser {
  constructor() {
    super();
    this.fields = ftms_measurement;
    this.mask_size = 16;
  }
}

export class Meter {
  constructor() {
    this.listeners = {};
    this.timeoutID = undefined;
    this.milliTimeout = 8000;
  }

  clearValueOnTimeout(value) {
    if (this.timeoutID !== undefined) {
      clearTimeout(this.timeoutID);
    }
    this.timeoutID = setTimeout(() => {
      this.timeoutID = undefined;
      if (value.constructor === Array) {
        for (let v of value) {
          this.dispatch(v, 0);
        }
      } else {
        this.dispatch(value, 0);
      }
    }, this.milliTimeout);
  }

  clearListeners() {
    this.listeners = {};
  }

  addListener(type, callback) {
    if (!(type in this.listeners)) {
      this.listeners[type] = [];
    }

    this.listeners[type].push(callback);
  }

  dispatch(type, value, id) {
    if (!(type in this.listeners)) {
      this.listeners[type] = [];
    }

    for (let l of this.listeners[type]) {
      l(value, id);
    }
  }
}

export class BleMeter extends Meter {
  constructor(device, server, service, characteristic) {
    super();

    this.device = device;
    this.server = server;
    this.service = service;
    this.characteristic = characteristic;

    this.name = this.device.name;
    this.id = this.device.id;

    this.listening = false;

    this.device.addEventListener("gattserverdisconnected", (e) => {
      this.gattserverdisconnected(e).catch((error) => {
        console.log("Error: ", error);
      });
    });
  }

  async gattserverdisconnected() {
    console.log("Reconnecting");
    this.server = await this.device.gatt.connect();
    this.service = await this.server.getPrimaryService(this.serviceId);
    this.characteristic = await this.service.getCharacteristic(
      this.characteristicId
    );
    if (this.listening) {
      this.listening = false;
      this.listen();
    }
  }
}

export class BlePowerCadenceMeter extends BleMeter {
  constructor(device, server, service, characteristic) {
    super(device, server, service, characteristic);

    this.serviceId = 0x1818;
    this.characteristicId = 0x2a63;
    this.parser = new CyclingPowerMeasurementParser();

    this.lastCrankRevolutions = 0;
    this.lastCrankTime = 0;
    this.lastWheelRevolutions = 0;
    this.lastWheelTime = 0;
  }

  listen() {
    if (!this.listening) {
      this.characteristic.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          let data = this.parser.getData(event.target.value);

          let power = data["instantaneous_power"];
          let crankRevolutions = data["cumulative_crank_revolutions"];
          let crankTime = data["last_crank_event_time"];
          let wheelRevolutions = data["cumulative_wheel_revolutions"];
          let wheelTime = data["last_wheel_event_time"];

          /* Crank Calc */
          if (this.lastCrankTime > crankTime) {
            this.lastCrankTime = this.lastCrankTime - 65536;
          }
          if (this.lastCrankRevolutions > crankRevolutions) {
            this.lastCrankRevolutions = this.lastCrankRevolutions - 65536;
          }

          let revs = crankRevolutions - this.lastCrankRevolutions;
          let duration = (crankTime - this.lastCrankTime) / 1024;
          let rpm = 0;
          if (duration > 0) {
            rpm = (revs / duration) * 60;
          }

          this.lastCrankRevolutions = crankRevolutions;
          this.lastCrankTime = crankTime;
          /* End Crank Calc */

          /* Wheel Calc */
          if (wheelRevolutions !== undefined && wheelTime !== undefined) {
            if (this.lastWheelTime > wheelTime) {
              this.lastWheelTime = this.lastWheelTime - 65536;
            }
            if (this.lastWheelRevolutions > wheelRevolutions) {
              this.lastWheelRevolutions = this.lastWheelRevolutions - 65536;
            }

            let wheelRevs = wheelRevolutions - this.lastWheelRevolutions;
            let wheelDuration = (wheelTime - this.lastWheelTime) / 1024;
            let wheelRpm = 0;
            if (wheelDuration > 0) {
              wheelRpm = (wheelRevs / wheelDuration) * 60;
            }

            this.lastWheelRevolutions = wheelRevolutions;
            this.lastWheelTime = wheelTime;

            this.dispatch("wheelrpm", wheelRpm, this.id);
          }
          /* End Wheel Calc */

          this.dispatch("power", power, this.id);
          this.dispatch("cadence", rpm, this.id);
          this.clearValueOnTimeout(["power", "cadence", "wheelrpm"]);
        }
      );
      this.characteristic.startNotifications();
      this.listening = true;
    }
  }
}

export class BlePowerMeter extends BleMeter {
  constructor(device, server, service, characteristic) {
    super(device, server, service, characteristic);

    this.serviceId = 0x1818;
    this.characteristicId = 0x2a63;
    this.parser = new CyclingPowerMeasurementParser();
  }

  listen() {
    if (!this.listening) {
      this.characteristic.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          let data = this.parser.getData(event.target.value);
          let power = data["instantaneous_power"];
          this.dispatch("power", power, this.id);
          this.clearValueOnTimeout("power");
        }
      );
      this.characteristic.startNotifications();
      this.listening = true;
    }
  }
}

export class BleCadenceMeter extends BleMeter {
  constructor(device, server, service, characteristic) {
    super(device, server, service, characteristic);

    this.serviceId = 0x1816;
    this.characteristicId = 0x2a5b;
    this.parser = new CyclingSpeedCadenceMeasurementParser();

    this.lastCrankRevolutions = 0;
    this.lastCrankTime = 0;
    this.lastWheelRevolutions = 0;
    this.lastWheelTime = 0;
  }

  listen() {
    if (!this.listening) {
      this.characteristic.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          let data = this.parser.getData(event.target.value);
          let crankRevolutions = data["cumulative_crank_revolutions"];
          let crankTime = data["last_crank_event_time"];
          let wheelRevolutions = data["cumulative_wheel_revolutions"];
          let wheelTime = data["last_wheel_event_time"];

          if (crankRevolutions !== undefined && crankTime !== undefined) {
            if (this.lastCrankTime > crankTime) {
              this.lastCrankTime = this.lastCrankTime - 65536;
            }
            if (this.lastCrankRevolutions > crankRevolutions) {
              this.lastCrankRevolutions = this.lastCrankRevolutions - 65536;
            }

            let revs = crankRevolutions - this.lastCrankRevolutions;
            let duration = (crankTime - this.lastCrankTime) / 1024;
            let rpm = 0;
            if (duration > 0) {
              rpm = (revs / duration) * 60;
            }

            this.lastCrankRevolutions = crankRevolutions;
            this.lastCrankTime = crankTime;

            this.dispatch("cadence", rpm, this.id);
          }

          if (wheelRevolutions !== undefined && wheelTime !== undefined) {
            if (this.lastWheelTime > wheelTime) {
              this.lastWheelTime = this.lastWheelTime - 65536;
            }
            if (this.lastWheelRevolutions > wheelRevolutions) {
              this.lastWheelRevolutions = this.lastWheelRevolutions - 65536;
            }

            let wheelRevs = wheelRevolutions - this.lastWheelRevolutions;
            let wheelDuration = (wheelTime - this.lastWheelTime) / 1024;
            let wheelRpm = 0;
            if (wheelDuration > 0) {
              wheelRpm = (wheelRevs / wheelDuration) * 60;
            }

            this.lastWheelRevolutions = wheelRevolutions;
            this.lastWheelTime = wheelTime;

            this.dispatch("wheelrpm", wheelRpm, this.id);
          }

          this.clearValueOnTimeout(["cadence", "wheelrpm"]);
        }
      );
      this.characteristic.startNotifications();
      this.listening = true;
    }
  }
}

export class BleHRMeter extends BleMeter {
  constructor(device, server, service, characteristic) {
    super(device, server, service, characteristic);

    this.serviceId = 0x180d;
    this.characteristicId = 0x2a37;
  }

  listen() {
    if (!this.listening) {
      this.characteristic.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          let hr = event.target.value.getUint8(1);

          this.dispatch("hr", hr, this.id);
          this.clearValueOnTimeout("hr");
        }
      );
      this.characteristic.startNotifications();
      this.listening = true;
    }
  }
}

export class VirtualPowerMeter extends Meter {
  constructor() {
    super();
    this.listening = false;
    this.watts = 0;

    this.id = "virtual";
    this.name = "Virtual Power Meter";
  }

  listen() {
    if (!this.listening) {
      setInterval(() => {
        this.dispatch("power", 10, this.id);
      }, 750);

      this.listening = true;
    }
  }
}
