<template>
    <div class="top-ingredients-chart">
   <canvas ref="chart"></canvas>
   </div>
   </template>
<script>


import Chart from 'chart.js/auto';
export default {
    name: 'TopIngredientsChart',
    props: ['entries'],
    data() {
        return {
            chart: null
        };
    },
    mounted() {
        this.createChart();
    },
    watch: {
        entries: {
            handler() {
                this.updateChart();
            },
            deep: true
        }
    },
    methods: {
        createChart() {
            const ctx = this.$refs.chart.getContext('2d');
            const topIngredients = this.getTopIngredients();
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: topIngredients.map(ingredient => ingredient.name),
                    datasets: [{
                        label: 'Häufigkeit',
                        data: topIngredients.map(ingredient => ingredient.count),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                    }],
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Anzahl',
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Top 10 Zutaten',
                        },
                    },
                },
            });
        },
        updateChart() {
            if (this.chart) {
                const topIngredients = this.getTopIngredients();
                this.chart.data.labels = topIngredients.map(ingredient => ingredient.name);
                this.chart.data.datasets[0].data = topIngredients.map(ingredient => ingredient.count);
                this.chart.update();
            }
        },
        getTopIngredients() {
            const ingredientCounts = {};
            this.entries.forEach(entry => {
                if (entry.ingredients && Array.isArray(entry.ingredients)) {
                    entry.ingredients.forEach(ingredient => {
                        ingredientCounts[ingredient.name] = (ingredientCounts[ingredient.name] || 0) + 1;
                    });
                }
            });
            return Object.entries(ingredientCounts)
                .sort((a, b) => b[1] - a[1])
                .slice(0, 10)
                .map(([name, count]) => ({ name, count }));
        },
    },
};
</script><template>
    <div class="top-ingredients-chart">
        <canvas ref="chart"></canvas>
    </div>
</template>
<script>
import Chart from 'chart.js/auto';
export default {
name: 'TopIngredientsChart',
props: ['entries'],
data() {
return {
chart: null
 };
 },
mounted() {
this.createChart();
 },
watch: {
entries: {
handler() {
this.updateChart();
 },
deep: true
 }
 },
methods: {
createChart() {
const ctx = this.$refs.chart.getContext('2d');
const topIngredients = this.getTopIngredients();
this.chart = new Chart(ctx, {
type: 'bar',
data: {
labels: topIngredients.map(ingredient => ingredient.name),
datasets: [{
label: 'Häufigkeit',
data: topIngredients.map(ingredient => ingredient.count),
backgroundColor: 'rgba(75, 192, 192, 0.6)',
borderColor: 'rgba(75, 192, 192, 1)',
borderWidth: 1,
 }],
 },
options: {
responsive: true,
scales: {
y: {
beginAtZero: true,
title: {
display: true,
text: 'Anzahl',
 },
 },
 },
plugins: {
legend: {
display: false,
 },
title: {
display: true,
text: 'Top 10 Zutaten',
 },
 },
 },
 });
 },
updateChart() {
if (this.chart) {
const topIngredients = this.getTopIngredients();
this.chart.data.labels = topIngredients.map(ingredient => ingredient.name);
this.chart.data.datasets[0].data = topIngredients.map(ingredient => ingredient.count);
this.chart.update();
 }
 },
getTopIngredients() {
const ingredientCounts = {};
this.entries.forEach(entry => {
if (entry.ingredients && Array.isArray(entry.ingredients)) {
entry.ingredients.forEach(ingredient => {
ingredientCounts[ingredient.name] = (ingredientCounts[ingredient.name] || 0) + 1;
 });
 }
 });
return Object.entries(ingredientCounts)
 .sort((a, b) => b[1] - a[1])
 .slice(0, 10)
 .map(([name, count]) => ({ name, count }));
 },
 },
};
</script>