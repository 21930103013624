<template>
  <div>
    <div v-if="!loaded">
      <h4 class="top-spacer-50">E-Mail bestätigen!</h4>
      <div
        class="spinner-border text-primary"
        role="status"
        v-if="!loaded"
      ></div>
    </div>

    <div v-if="loaded" class="white_wrapper top-spacer-50">
      <div v-if="confirmed">
        <br />
        <h2>E-Mail bestätigt!</h2>
        <h5>
          Deine eMail-Adresse wurde bestätigt. Du kannst dich jetzt einloggen.
          Herzlich Willkommen im Pushing Limits Club!
        </h5>
        <a href="/#/login"><button>Zum Login</button></a>
      </div>
      <div v-if="!confirmed">
        <h2>E-Mail konnte nicht bestätigt werden!</h2>
        <h3>Versuch gegebenfalls die Seite nochmal zu laden</h3>
        <h5>
          Leider konnten wir keinen passenden Account finden. Bitte prüfe deinen
          Link noch einmal oder schick uns eine Mail an
          feedback@pushinglimits.club.
        </h5>

        <a href="/#/register"><button>Neuen Account anlegen</button></a>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  props: ["token"],

  data() {
    return {
      loaded: false,
      confirmed: false,
      confirmationToken: this.token,
    };
  },

  mounted: function () {
    const _self = this;
    axios
      .get(this.$host + "/user/confirm_mail/" + this.confirmationToken)
      .then(function () {
        _self.confirmed = true;
      })
      .catch(function () {
        _self.confirmed = false;
      })
      .finally(function () {
        _self.loaded = true;
      });
  },
};
</script>