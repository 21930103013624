<template>
  <div>
    <canvas id="caloriesChart" ref="calorieChart"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  props: {
    entries: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const ctx = this.$refs.calorieChart.getContext("2d");

      const ratingsColors = {
        Bad: { background: "#dc3545", border: "#dc3545" },
        Okay: { background: "#ff9800", border: "#ff9800" },
        Good: { background: "#28a745", border: "#28a745" },
      };

      // Process entries to sum calories for each day and categorize by rating
      let processedData = {};
      let totalCalories = 0;

      // copy for mutation
      const entriesReversed = this.entries.slice(0).reverse();
      entriesReversed.forEach((entry) => {
        const formattedDate = this.$moment(entry.createdAt).format("DD.MM.YYYY");
        if (!processedData[formattedDate]) {
          processedData[formattedDate] = { Good: 0, Okay: 0, Bad: 0, total: 0 };
        }
        processedData[formattedDate][entry.rating] += entry.calories * entry.servings || 0;
        processedData[formattedDate].total += entry.calories * entry.servings || 0;
        totalCalories += entry.calories * entry.servings || 0;
      });

      const averageCalories = Math.round(totalCalories / Object.keys(processedData).length);

      let labels = [];
      let datasets = {
        Good: { label: "Gut", backgroundColor: [], borderColor: [], data: [] },
        Okay: { label: "Ok", backgroundColor: [], borderColor: [], data: [] },
        Bad: { label: "Schlecht", backgroundColor: [], borderColor: [], data: [] },
      };

      for (let date in processedData) {
        labels.push(date);
        for (let rating in ratingsColors) {
          datasets[rating].data.push(processedData[date][rating]);
          datasets[rating].backgroundColor.push(ratingsColors[rating].background);
          datasets[rating].borderColor.push(ratingsColors[rating].border);
        }
      }

      console.log(datasets);

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: Object.values(datasets),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },

            y: {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          },
          plugins: {
            annotation: {
              annotations: {
                averageLine: {
                  type: "line",
                  mode: "horizontal",
                  scaleID: "y",
                  value: averageCalories,
                  borderColor: "black",
                  borderWidth: 2,
                  label: {
                    enabled: true,
                    content: "Ø " + averageCalories + "kcal",
                    backgroundColor: "black",
                  },
                },
              },
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  label += context.raw + "kcal";
                  return label;
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>
