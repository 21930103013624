<template>
  <div class="weather-forecast" :class="{ 'single-day': singleDay }">
    <div v-for="(day, date) in forecast" :key="date" class="forecast-day">
      <h5 v-if="!singleDay" class="date-header">{{ formatDate(date) }}</h5>
      <div class="forecast-items">
        <div v-for="(item, index) in day" :key="index" class="forecast-item">
          <div class="forecast-item-content">
            <div class="icon-time">
              <img :src="`https://assets.pushinglimits.club/weather/${item.icon}@2x.png`" :alt="item.text" />
              <div class="time-temp">
                <span class="time">{{ formatTime(item.time) }}</span>
                <span class="temperature">{{ item.text.split(" ")[0] }}</span>
                <small v-if="item.wind" class="wind">༄ {{ Math.round(item.wind) }}km/h</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WeatherForecast",
  props: {
    forecast: {
      type: Object,
      required: true,
    },
    singleDay: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatDate(dateString) {
      return this.$moment(dateString).format("dddd, D. MMMM");
    },
    formatTime(timeString) {
      return this.$moment(timeString, "HH:mm:ss").format("HH:mm");
    },
  },
};
</script>

<style scoped>
.weather-forecast {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  overflow-x: auto;
  padding-bottom: 0.25rem;
  width: 100%;
  white-space: nowrap;
}

.date-header {
  margin: 0;
  padding: 0.25rem 0;
  font-size: 0.9em;
}

.forecast-day {
  display: inline-flex;
  padding: 0.5rem;
  padding-top: 0;
  border-right: 2px solid #ddd;
}

.forecast-items {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

.forecast-item {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem;
}

.forecast-item-content {
  display: inline-flex;
  align-items: center;
  min-width: 95px;
}

.icon-time {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.forecast-item img {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.time-temp {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  min-width: 55px;
}

.time {
  font-size: 0.8em;
  color: #666;
}

.temperature {
  font-size: 0.9em;
  font-weight: 500;
}

.wind {
  font-size: 0.75em;
  color: #666;
}

/* Single day styles */
.single-day .forecast-day {
  width: auto;
  padding: 0.25rem;
  border-right: none;
}

.single-day .forecast-items {
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .weather-forecast {
    flex-direction: row; /* Keep row direction on mobile */
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .forecast-items {
    flex-wrap: nowrap;
    padding: 0.25rem;
  }

  .forecast-item {
    flex-shrink: 0;
  }
}
</style>
