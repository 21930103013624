<template>
  <div class="row">
    <div class="col-lg-12">
      <a class="back-button" v-bind:href="'/#/esszimmer?id=' + id">➜</a>

      <div class="row" v-if="load">
        <div class="col-lg-12 top-spacer-100">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>

      <div class="col-12 top-spacer-10" v-if="warnPremiumExpiration" style="text-align: center; color: #fff">
        <div
          class="col-12"
          style="
            background-size: 200% auto;
            padding: 30px;
            background: #000;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
          "
        >
          <br />
          <strong>Hinweis:</strong> Dein Zugang ist abgelaufen. Um dauerhaft alle Funktionen nutzen zu können, kannst du
          über die App ein Abo abschließen.

          <h2>Die Rezepte stehen nur unseren zahlenden Club Mitgliedern zur Verfügung.</h2>
        </div>
      </div>
      <div class="top-spacer-25 d-none d-lg-block">&nbsp;</div>

      <div class="row rezept" v-if="!load && !warnPremiumExpiration">
        <div class="col-lg-12 top-spacer-25">
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-2 desktop-left top-spacer-15" style="padding-left: 0">
              <a v-bind:href="'/#/esszimmer?id=' + id" class="top-spacer-10" style="color: #222; font-size: 18px"
                ><button style="margin: 0">❮ Übersicht</button></a
              >
              <div class="top-spacer-25 d-block d-lg-none"></div>
            </div>
            <div class="col-lg-6">
              <h1 class="centered heading" style="padding: 0; margin: 0; font-size: 35px">
                {{ recipe.ueberschrift }}
              </h1>
              <h4 style="text-transform: uppercase; padding-top: 5px; letter-spacing: 0.15rem">
                {{ recipe.untertitel }}
              </h4>
            </div>
          </div>
          <br />
        </div>
        <div class="col-lg-1"></div>

        <div class="col-lg-6">
          <div class="row light-shadow white_wrapper" style="padding: 0; background: #fff; margin-top: 0">
            <div class="col-lg-12">
              <h2 class="sub-heading">Nährwerte & Infos</h2>
              <span v-if="recipe.naehrwerte_bezug" style="padding-bottom: 20px !important"
                >Beziehen sich auf {{ recipe.naehrwerte_bezug }}</span
              >
            </div>
            <div class="col-lg-12 top-spacer-25"></div>

            <div class="col-lg-3 col-md-3 col-4">
              <img
                class="info"
                alt="Kohlenhydrate"
                data-toggle="tooltip"
                title="Kohlenhydrate"
                src="https://assets.pushinglimits.club/icons_kh.svg"
              />
              <h2 class="naehrwerte">{{ roundTo1(recipe.kohlenhydrate * weightFactor() * hitLitFactor()) }}g</h2>
            </div>
            <div class="col-lg-3 col-md-3 col-4">
              <img
                class="info"
                alt="Protein"
                data-toggle="tooltip"
                title="Protein"
                src="https://assets.pushinglimits.club/icons_p.svg"
              />
              <h2 class="naehrwerte">{{ roundTo1(recipe.proteine * weightFactor() * hitLitFactor()) }}g</h2>
            </div>
            <div class="col-lg-3 col-md-3 col-4">
              <img
                class="info"
                alt="Fette"
                data-toggle="tooltip"
                title="Fette"
                src="https://assets.pushinglimits.club/icons_f.svg"
              />
              <h2 class="naehrwerte">{{ roundTo1(recipe.fette * weightFactor() * hitLitFactor()) }}g</h2>
            </div>
            <div class="d-block d-lg-none top-spacer-15"></div>

            <div class="col-lg-3 col-md-3 col-4">
              <img
                class="info"
                alt="Ballaststoffe"
                data-toggle="tooltip"
                title="Ballaststoffe"
                src="https://assets.pushinglimits.club/icons_b.svg"
              />
              <h2 class="naehrwerte">{{ roundTo1(recipe.ballaststoffe * weightFactor() * hitLitFactor()) }}g</h2>
            </div>

            <div class="d-none d-lg-block col-lg-12 top-spacer-15"></div>

            <div class="col-lg-3 col-md-3 col-4">
              <img
                class="info"
                alt="Zeit"
                data-toggle="tooltip"
                title="Zeit"
                src="https://assets.pushinglimits.club/icons_aktive-zeit.svg"
              />
              <h2 class="naehrwerte">{{ recipe.zeit || recipe.aktive_zeit }}min</h2>
            </div>

            <div class="col-lg-3 col-md-3 col-4">
              <img
                class="info"
                alt="Kalorien"
                data-toggle="tooltip"
                title="Kalorien"
                src="https://assets.pushinglimits.club/icons_kalorien.svg"
              />
              <h2 class="naehrwerte">{{ roundTo1(computeCalories() * weightFactor()) }}kcal</h2>
            </div>

            <div class="col-lg-3 col-md-3 col-4" v-if="recipe.behaelter">
              <img
                class="info"
                alt="Behälter"
                data-toggle="tooltip"
                title="Behälter"
                viewBox="0 0 100 200"
                preserveAspectRatio="xMidYMid meet"
                src="https://assets.pushinglimits.club/icons_behaelter.svg"
              />
              <h2 class="naehrwerte">{{ recipe.behaelter }}</h2>
            </div>
            <div class="col-lg-3 col-md-3 col-4" v-if="recipe.ks_tk">
              <img
                class="info"
                alt="KS_TK"
                data-toggle="tooltip"
                title="Haltbarkeit (Kühlschrank / Tiefkühlung)"
                style="width: 100%; height: auto"
                src="https://assets.pushinglimits.club/icons_ks-ts.svg"
              />
              <h2 class="naehrwerte">{{ recipe.ks_tk }}</h2>
            </div>

            <div class="col-lg-12" style="text-align: center" v-if="areAllIngredientsFixed()">
              <div class="row">
                <div class="col-md-12 top-spacer-25">
                  Dieses Rezept hat feste Zutaten und kann nicht gerechnet werden
                </div>
              </div>
            </div>
            <div class="col-lg-12 top-spacer-25" v-if="!areAllIngredientsFixed()">
              Gerechnet mit
              <input
                style="width: 80px"
                type="number"
                v-on:keyup="weightChange()"
                min="1"
                max="1000"
                v-model.number="weight"
              />
              kg <span style="padding-right: 20px"></span> |<span style="padding-left: 20px"></span>Intensität
              <select v-model="hitOderLit">
                <option>LIT</option>
                <option>HIT</option>
              </select>

              <span style="padding-right: 20px"></span> |<span style="padding-left: 20px"></span>
              Portionen:
              <select v-model="portionen">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
              </select>
            </div>

            <div class="top-spacer-25">&nbsp;</div>
          </div>

          <div v-for="id in recipe.imageIds" :key="id" class="d-block d-lg-none top-spacer-15 row">
            <div class="recipe-parent">
              <div class="imgSpin">
                <div
                  style="position: absolute; top: 80px; left: 47%"
                  class="spinner-border text-primary top-spacer-25"
                  role="status"
                ></div>
              </div>
              <img class="recipe-img" alt="Rezept Bild" :src="`https://assets.pushinglimits.club/${id}`" />
            </div>
          </div>

          <div class="row white_wrapper top-spacer-15" style="padding-top: 5px; background: #fff">
            <div class="col-lg-6">
              <h2 class="sub-heading">Zubereitung</h2>
              <p class="zubereitung" v-html="recipe.zubereitung"></p>
            </div>
            <div class="col-lg-6" style="text-align: left">
              <h2 class="sub-heading" v-if="recipe.zutaten && recipe.zutaten.length > 0">Zutaten</h2>
              <div class="row zutaten" v-for="zutat in recipe.zutaten" :key="zutat.name">
                <div class="col-lg-8 col-8" style="text-align: left">
                  <strong>{{ zutat.name.replace("$", "").replace("§", "") }}</strong>
                </div>
                <div class="col-lg-4 col-4" style="padding-right: 0">
                  <span v-if="!zutat.name.startsWith('$') && !zutat.name.startsWith('§')"
                    >{{ round5(zutat.menge * weightFactor() * getPortionen()) }}
                  </span>
                  <span v-if="zutat.name.startsWith('$')">{{ zutat.menge }}</span>
                  <span v-if="zutat.name.startsWith('§')">nach Gusto</span>
                  <span v-if="!zutat.name.startsWith('§')"> {{ zutat.einheit }}</span>
                </div>
              </div>

              <h2 class="sub-heading" v-if="recipe.dip && recipe.dip.length > 0">Dip</h2>
              <div class="row zutaten" v-for="zutat in recipe.dip" :key="zutat.name">
                <div class="col-lg-8 col-8" style="text-align: left">
                  <strong>{{ zutat.name.replace("$", "").replace("§", "") }}</strong>
                </div>
                <div class="col-lg-4 col-4" style="padding-right: 0">
                  <span v-if="!zutat.name.startsWith('$') && !zutat.name.startsWith('§')"
                    >{{ round5(zutat.menge * weightFactor() * getPortionen()) }}
                  </span>
                  <span v-if="zutat.name.startsWith('$')">{{ zutat.menge }}</span>
                  <span v-if="zutat.name.startsWith('§')">nach Gusto</span>

                  <span v-if="!zutat.name.startsWith('§')"> {{ zutat.einheit }}</span>
                </div>
              </div>

              <h2 class="sub-heading" v-if="recipe.topping && recipe.topping.length > 0">Topping</h2>
              <div class="row zutaten" v-for="zutat in recipe.topping" :key="zutat.name">
                <div class="col-lg-8 col-8" style="text-align: left">
                  <strong>{{ zutat.name.replace("$", "").replace("§", "") }}</strong>
                </div>
                <div class="col-lg-4 col-4" style="padding-right: 0">
                  <span v-if="!zutat.name.startsWith('$') && !zutat.name.startsWith('§')"
                    >{{ round5(zutat.menge * weightFactor() * getPortionen()) }}
                  </span>
                  <span v-if="zutat.name.startsWith('$')">{{ zutat.menge }}</span>
                  <span v-if="zutat.name.startsWith('§')">nach Gusto</span>

                  <span v-if="!zutat.name.startsWith('§')"> {{ zutat.einheit }}</span>
                </div>
              </div>

              <h2 class="sub-heading" v-if="recipe.sosse && recipe.sosse.length > 0">Soße</h2>
              <div class="row zutaten" v-for="zutat in recipe.sosse" :key="zutat.name">
                <div class="col-lg-8 col-8" style="text-align: left">
                  <strong>{{ zutat.name.replace("$", "").replace("§", "") }}</strong>
                </div>
                <div class="col-lg-4 col-4" style="padding-right: 0">
                  <span v-if="!zutat.name.startsWith('$') && !zutat.name.startsWith('§')"
                    >{{ round5(zutat.menge * weightFactor() * getPortionen()) }}
                  </span>
                  <span v-if="zutat.name.startsWith('$')">{{ zutat.menge }}</span>
                  <span v-if="zutat.name.startsWith('§')">nach Gusto</span>

                  <span v-if="!zutat.name.startsWith('§')"> {{ zutat.einheit }}</span>
                </div>
              </div>

              <h2 class="sub-heading" v-if="recipe.dressing && recipe.dressing.length > 0">Dressing</h2>
              <div class="row zutaten" v-for="zutat in recipe.dressing" :key="zutat.name">
                <div class="col-lg-8 col-8" style="text-align: left">
                  <strong>{{ zutat.name.replace("$", "").replace("§", "") }}</strong>
                </div>
                <div class="col-lg-4 col-4" style="padding-right: 0">
                  <span v-if="!zutat.name.startsWith('$') && !zutat.name.startsWith('§')"
                    >{{ round5(zutat.menge * weightFactor() * getPortionen()) }}
                  </span>
                  <span v-if="zutat.name.startsWith('$')">{{ zutat.menge }}</span>
                  <span v-if="zutat.name.startsWith('§')">nach Gusto</span>

                  <span v-if="!zutat.name.startsWith('§')"> {{ zutat.einheit }}</span>
                </div>
              </div>
              <div v-if="recipe.var_liste_1_name">
                <h2 class="sub-heading">
                  {{ recipe.var_liste_1_name }}
                </h2>
                <div class="row zutaten" v-for="zutat in recipe.var_liste_1_zutaten" :key="zutat.name">
                  <div class="col-lg-8 col-8" style="text-align: left">
                    <strong>{{ zutat.name.replace("$", "").replace("§", "") }}</strong>
                  </div>
                  <div class="col-lg-4 col-4" style="padding-right: 0">
                    <span v-if="!zutat.name.startsWith('$') && !zutat.name.startsWith('§')"
                      >{{ round5(zutat.menge * weightFactor() * getPortionen()) }}
                    </span>
                    <span v-if="zutat.name.startsWith('$')">{{ zutat.menge }}</span>
                    <span v-if="zutat.name.startsWith('§')">nach Gusto</span>

                    <span v-if="!zutat.name.startsWith('§')"> {{ zutat.einheit }}</span>
                  </div>
                </div>
              </div>

              <div v-if="recipe.var_liste_2_name">
                <h2 class="sub-heading">
                  {{ recipe.var_liste_2_name }}
                </h2>
                <div class="row zutaten" v-for="zutat in recipe.var_liste_2_zutaten" :key="zutat.name">
                  <div class="col-lg-8 col-8" style="text-align: left">
                    <strong>{{ zutat.name.replace("$", "").replace("§", "") }}</strong>
                  </div>
                  <div class="col-lg-4 col-4" style="padding-right: 0">
                    <span v-if="!zutat.name.startsWith('$') && !zutat.name.startsWith('§')"
                      >{{ round5(zutat.menge * weightFactor() * getPortionen()) }}
                    </span>
                    <span v-if="zutat.name.startsWith('$')">{{ zutat.menge }}</span>
                    <span v-if="zutat.name.startsWith('§')">nach Gusto</span>

                    <span v-if="!zutat.name.startsWith('§')"> {{ zutat.einheit }}</span>
                  </div>
                </div>
              </div>
              <div v-if="recipe.var_liste_3_name">
                <h2 class="sub-heading">
                  {{ recipe.var_liste_3_name }}
                </h2>
                <div class="row zutaten" v-for="zutat in recipe.var_liste_3_zutaten" :key="zutat.name">
                  <div class="col-lg-8 col-8" style="text-align: left">
                    <strong>{{ zutat.name.replace("$", "").replace("§", "") }}</strong>
                  </div>
                  <div class="col-lg-4 col-4" style="padding-right: 0">
                    <span v-if="!zutat.name.startsWith('$') && !zutat.name.startsWith('§')"
                      >{{ round5(zutat.menge * weightFactor() * getPortionen()) }}
                    </span>
                    <span v-if="zutat.name.startsWith('$')">{{ zutat.menge }}</span>
                    <span v-if="zutat.name.startsWith('§')">nach Gusto</span>

                    <span v-if="!zutat.name.startsWith('§')"> {{ zutat.einheit }}</span>
                  </div>
                </div>
              </div>
              <div v-if="recipe.var_liste_4_name">
                <h2 class="sub-heading">
                  {{ recipe.var_liste_4_name }}
                </h2>
                <div class="row zutaten" v-for="zutat in recipe.var_liste_4_zutaten" :key="zutat.name">
                  <div class="col-lg-8 col-8">
                    <strong>{{ zutat.name.replace("$", "").replace("§", "") }}</strong>
                  </div>
                  <div class="col-lg-4 col-4" style="padding-right: 0">
                    <span v-if="!zutat.name.startsWith('$') && !zutat.name.startsWith('§')"
                      >{{ round5(zutat.menge * weightFactor() * getPortionen()) }}
                    </span>
                    <span v-if="zutat.name.startsWith('$')">{{ zutat.menge }}</span>
                    <span v-if="zutat.name.startsWith('§')">nach Gusto</span>

                    <span v-if="!zutat.name.startsWith('§')"> {{ zutat.einheit }}</span>
                  </div>
                </div>
              </div>
              <div v-if="recipe.var_liste_5_name">
                <h2 class="sub-heading">
                  {{ recipe.var_liste_5_name }}
                </h2>
                <div class="row zutaten" v-for="zutat in recipe.var_liste_5_zutaten" :key="zutat.name">
                  <div class="col-lg-8 col-8" style="text-align: left">
                    <strong>{{ zutat.name.replace("$", "").replace("§", "") }}</strong>
                  </div>
                  <div class="col-lg-4 col-4" style="padding-right: 0">
                    <span v-if="!zutat.name.startsWith('$') && !zutat.name.startsWith('§')"
                      >{{ round5(zutat.menge * weightFactor() * getPortionen()) }}
                    </span>
                    <span v-if="zutat.name.startsWith('$')">{{ zutat.menge }}</span>
                    <span v-if="zutat.name.startsWith('§')">nach Gusto</span>

                    <span v-if="!zutat.name.startsWith('§')"> {{ zutat.einheit }}</span>
                  </div>
                </div>
              </div>
              <div class="top-spacer-25">
                <small
                  >Hinweis: Es kommt bei den Zutaten nicht auf die letzten 0.1g an. Der Rechner errechnet die Zutaten
                  für das Rezept anhand deines Gewichts, daher sind die Angaben sehr genau. Du musst dich aber nicht 1:1
                  daran halten.</small
                >
              </div>
            </div>
            <div class="row" v-if="recipe.ernaehrungswissen">
              <div class="col-lg-12 top-spacer-25">
                <div class="ernaherungswissen" v-html="recipe.ernaehrungswissen"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div v-for="id in recipe.imageIds" :key="id" class="d-none d-lg-block">
            <div class="recipe-parent">
              <div class="imgSpin">
                <div
                  style="position: absolute; top: 80px; left: 47%"
                  class="spinner-border text-primary top-spacer-25"
                  role="status"
                ></div>
              </div>
              <img class="recipe-img" alt="Rezept Bild" :src="`https://assets.pushinglimits.club/${id}`" />
            </div>
          </div>

          <h2
            v-if="recipe.intensitaet == 'Snack'"
            class="top-spacer-10"
            style="background: #e07989; border-radius: 10px; padding: 15px; letter-spacing: 0.2rem; font-size: 20px"
          >
            <strong>Als {{ recipe.intensitaet }} genießen</strong>
          </h2>

          <h2
            class="top-spacer-10"
            style="background: #8fc9da; border-radius: 10px; padding: 15px; letter-spacing: 0.2rem; font-size: 20px"
          >
            <span v-if="recipe.zeitpunkt == 'Snack'">Am besten als Snack</span>
            <span v-if="recipe.zeitpunkt == 'Pre'">Am besten <strong>vor dem Training</strong></span>
            <span v-if="recipe.zeitpunkt == 'Post'">Am besten <strong>nach dem Training</strong></span>
          </h2>

          <!--
        <h2
          class="top-spacer-10"
          style="
            background: #7989e0;
            border-radius: 10px;
            padding: 15px;
            letter-spacing: 0.2rem;
            font-size: 20px;
          "
        >
          <span v-if="recipe.intensitaet == 'Snack'">Als Snack</span>

        
            <span
            v-if="recipe.intensitaet == 'LIT' || recipe.intensitaet == 'HIT'"
            >Für
          </span>
          <strong>{{ recipe.intensitaet }}</strong>
          <span
            v-if="recipe.intensitaet == 'LIT' || recipe.intensitaet == 'HIT'"
          >
            Training</span
          >
        </h2>-->
          <h2
            class="top-spacer-10"
            style="background: #f6c050; border-radius: 10px; padding: 15px; letter-spacing: 0.2rem; font-size: 20px"
          >
            Aus dem Buch: <strong>{{ recipe.edition }}</strong>
          </h2>
          <div v-if="recipe.edition == 'Athletes Delight'">
            <a
              href="https://www.wheresthefood.de/products/snack-kochbuch-athletes-delight-fuer-sportler"
              target="_blank"
            >
              <img
                class="recipe-img"
                alt="Buch Bild"
                :src="`https://assets.pushinglimits.club/books/athletesdelight.webp`"
              />
            </a>
          </div>
          <div v-if="recipe.edition == 'Winter'">
            <a href="https://www.wheresthefood.de/products/wheres-the-food-kochbuch-winter-edition" target="_blank">
              <img class="recipe-img" alt="Buch Bild" :src="`https://assets.pushinglimits.club/books/winter.webp`" />
            </a>
          </div>
          <div v-if="recipe.edition == 'Sommer'">
            <a href="https://www.wheresthefood.de/products/wheres-the-food-kochbuch-summer-edition" target="_blank">
              <img class="recipe-img" alt="Buch Bild" :src="`https://assets.pushinglimits.club/books/summer.webp`" />
            </a>
          </div>
          <div v-if="recipe.edition == 'Meal Prep'">
            <a href="https://www.wheresthefood.de/products/wheres-the-food-kochbuch-meal-prep" target="_blank">
              <img class="recipe-img" alt="Buch Bild" :src="`https://assets.pushinglimits.club/books/mealprep.webp`"
            /></a>
          </div>
          <div
            style="color: #aaa; text-align: center"
            class="top-spacer-15"
            v-if="platform != 'web' && platform != 'electron'"
          >
            <div v-if="recipe.edition == 'Winter'">
              <a href="https://www.wheresthefood.de/products/wheres-the-food-kochbuch-winter-edition" target="_blank">
                Zum WTF Winter-Edition Buch &#128279;
              </a>
            </div>
            <div v-if="recipe.edition == 'Sommer'">
              <a href="https://www.wheresthefood.de/products/wheres-the-food-kochbuch-summer-edition" target="_blank">
                Zum WTF Summer-Edition Buch &#128279;
              </a>
            </div>
            <div v-if="recipe.edition == 'Meal Prep'">
              <a href="https://www.wheresthefood.de/products/wheres-the-food-kochbuch-meal-prep" target="_blank">
                Zum WTF Mealprep Buch &#128279;</a
              >
            </div>
            <div v-if="recipe.edition == 'Athletes Delight'">
              <a
                href="https://www.wheresthefood.de/products/snack-kochbuch-athletes-delight-fuer-sportler"
                target="_blank"
              >
                Zum Athletes Delight Buch &#128279;</a
              >
            </div>
          </div>

          <div class="white_wrapper light-shadow top-spacer-15" style="padding-top: 25px; padding-bottom: 25px">
            <div v-if="!isFavorite">
              <button @click="toggleFavoriteRecipe()" style="width: 100%">
                <i class="fa fa-star-o" aria-hidden="true"></i> Als Favorit speichern
              </button>
            </div>

            <div v-if="isFavorite">
              <button @click="toggleFavoriteRecipe()" class="closer" style="width: 100%">
                <i class="fa fa-star" aria-hidden="true"></i> Als Favorit entfernen
              </button>
            </div>
          </div>

          <div class="white_wrapper light-shadow top-spacer-15" style="padding-top: 25px">
            <div class="col-lg-12">
              <h3>Weitere Rezepte</h3>
            </div>
            <div
              class="row colored-row top-spacer-25"
              style="padding: 25px 0; border-radius: 10px"
              v-for="r in recipes.slice(0, 4)"
              :key="r._id"
            >
              <div class="col-4">
                <a :href="'/#/esszimmer/rezept/' + r._id" style="color: inherit">
                  <img
                    alt="Kein Bild"
                    class="recipe-img"
                    style="object-fit: cover"
                    :src="`https://assets.pushinglimits.club/${r.imageIds[0]}-small`"
                  />
                </a>
              </div>
              <div class="col-8">
                <a :href="'/#/esszimmer/rezept/' + r._id" style="color: inherit">
                  <h4>{{ r.ueberschrift }}</h4>
                  <p>{{ r.untertitel }}</p>
                  <!--
              <img
                style="width: 20px !important; block: inline; margin-top: 3px"
                class="info"
                alt="Zeit"
                :src="require(`@/assets/images/wtf/zeit.svg`)"
              />
              <span style="block: inline; font-size: 20px">
                {{ r.zeit || r.aktive_zeit }}min
              </span>-->
                </a>

                <div class="row">
                  <div class="col-3">
                    <img class="info" alt="Kohlenhydrate" :src="require(`@/assets/images/wtf/kh.svg`)" />
                    <h2 class="naehrwerte">{{ roundTo1(r.kohlenhydrate * weightFactor() * hitLitFactor()) }}g</h2>
                  </div>
                  <div class="col-3">
                    <img class="info" alt="Protein" :src="require(`@/assets/images/wtf/protein.svg`)" />
                    <h2 class="naehrwerte">{{ roundTo1(r.proteine * weightFactor() * hitLitFactor()) }}g</h2>
                  </div>
                  <div class="col-3">
                    <img class="info" alt="Fette" :src="require(`@/assets/images/wtf/fett.svg`)" />
                    <h2 class="naehrwerte">{{ roundTo1(r.fette * weightFactor() * hitLitFactor()) }}g</h2>
                  </div>
                  <div class="col-3">
                    <img class="info" alt="Ballaststoffe" :src="require(`@/assets/images/wtf/ballast.svg`)" />
                    <h2 class="naehrwerte">{{ roundTo1(r.ballaststoffe * weightFactor() * hitLitFactor()) }}g</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isAdmin" class="top-spacer-100">
        <h4>Admin: Rezept löschen</h4>
        <span
          v-if="!loeschen"
          @click="loeschen = true"
          style="cursor: pointer; background: darkred; color: #fff; padding: 5px 15px"
        >
          LÖSCHEN
        </span>
        <div v-if="loeschen">
          Achtung: Das Rezept wird unwiderruflich gelöscht!! Wirklich löschen?
          <br /><br />
        </div>
        <span
          v-if="loeschen"
          style="cursor: pointer; background: darkred; color: #fff; padding: 5px 15px"
          @click="deleteRecipe()"
        >
          LÖSCHEN BESTÄTIGEN
        </span>
      </div>

      <!--<div class="row" v-if="recipe.ernaehrungswissen.length > 0">
          <div class="col-lg-12 wissen">
            <div class="white_wrapper" style="padding-bottom: 35px">
              {{ recipe.ernaehrungswissen }}
            </div>
          </div>
        </div>-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Capacitor } from "@capacitor/core";

export default {
  metaInfo() {
    return { title: (this.recipe && this.recipe.ueberschrift) || "Rezept" };
  },
  name: "ShowRezept",
  data: function () {
    return {
      loeschen: false,
      platform: Capacitor.getPlatform(),
      recipe: {},
      weight: 75,
      recipes: [],
      hitOderLit: "LIT",
      load: true,
      portionen: 1,
      warnPremiumExpiration: false,
      isAdmin: false,
      isFavorite: false,
    };
  },
  props: ["id", "typ"],

  methods: {
    getPortionen() {
      return this.portionen || 1;
    },

    toggleFavoriteRecipe() {
      axios.post(this.$host + "/rezept/toggleFavorite/" + this.id).then((response) => {
        this.isFavorite = response.data.favorite;
      });
    },

    isFavoriteRecipe() {
      return this.isFavorite;
    },

    computeCalories() {
      return (
        this.roundTo1(this.recipe.kohlenhydrate * this.hitLitFactor() * 4) +
        this.roundTo1(this.recipe.proteine * this.hitLitFactor() * 4) +
        this.roundTo1(this.recipe.fette * this.hitLitFactor() * 9) +
        this.roundTo1(this.recipe.ballaststoffe * this.hitLitFactor() * 2)
      );
    },
    hitLitFactor() {
      if (this.hitOderLit == "HIT") {
        return 1.38;
      } else {
        return 1;
      }
    },
    weightFactor() {
      if (!this.areAllIngredientsFixed()) {
        return this.weight / 75;
      } else {
        return 1;
      }
    },
    roundTo1(x) {
      if (x == undefined) {
        x = 0;
      }

      return Math.round(x);
    },
    round5(x) {
      if (!x) {
        x = 1;
      }

      x = x * this.hitLitFactor();

      if (x > 20) {
        return Math.max(0.5, Math.round(x / 5) * 5);
      } else if (x > 5) {
        return Math.max(0.5, Math.round(x));
      } else if (x > 2) {
        return Math.max(0.5, Math.round(x * 2) / 2);
      } else if (x > 1.5) {
        return Math.max(0.25, Math.round(x * 4) / 4);
      } else {
        return Math.max(0.1, Math.round(x * 10) / 10);
      }
    },
    weightChange() {
      if (this.weight < 0) {
        this.weight = 0;
      }
      if (this.weight > 1000) {
        this.weight = 1000;
      }
      this.weight = parseInt(this.weight, 10);
    },

    deleteRecipe() {
      axios
        .get(this.$host + "/rezept/" + this.id + "/delete")
        .then(function () {
          console.log("OKAY");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    areAllIngredientsFixed() {
      return (
        this.recipe.zutaten.every((zutat) => zutat.name.startsWith("$")) &&
        this.recipe.dip.every((zutat) => zutat.name.startsWith("$")) &&
        this.recipe.topping.every((zutat) => zutat.name.startsWith("$")) &&
        this.recipe.dressing.every((zutat) => zutat.name.startsWith("$")) &&
        this.recipe.sosse.every((zutat) => zutat.name.startsWith("$")) &&
        this.recipe.var_liste_1_zutaten.every((zutat) => zutat.name.startsWith("$")) &&
        this.recipe.var_liste_2_zutaten.every((zutat) => zutat.name.startsWith("$")) &&
        this.recipe.var_liste_3_zutaten.every((zutat) => zutat.name.startsWith("$")) &&
        this.recipe.var_liste_4_zutaten.every((zutat) => zutat.name.startsWith("$")) &&
        this.recipe.var_liste_5_zutaten.every((zutat) => zutat.name.startsWith("$"))
      );
    },
  },

  watch: {
    async $route(to, from) {
      const _self = this;
      if (to !== from) {
        _self.load = true;

        axios
          .get(this.$host + "/rezept/" + this.id)
          .then(function (response) {
            _self.recipe = response.data;
            _self.load = false;
          })
          .catch(function (error) {
            console.log(error);
          });

        axios
          .get(this.$host + "/rezept")
          .then(function (response) {
            _self.recipes = response.data.sort(() => Math.random() - 0.5);
            if (_self.recipe.intensitaet == "HIT") {
              _self.hitOderLit = "HIT";
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
  mounted: async function () {
    const _self = this;

    const thresholds = await (await axios.get(this.$host + "/user/thresholds")).data;

    let user = (await axios.get(this.$host + "/user/me")).data;
    this.isAdmin = user.isAdmin;

    if (
      !this.$moment(user.premiumAccountUntil).isAfter(this.$moment()) &&
      !this.$moment(user.freeAccountUntil).isAfter(this.$moment())
    ) {
      this.warnPremiumExpiration = true;
    }

    this.weight = thresholds[thresholds.length - 1].weight;

    axios
      .get(this.$host + "/rezept/" + this.id)
      .then(function (response) {
        _self.recipe = response.data;
        _self.portionen = _self.recipe.portionen || 1;

        if (_self.recipe.intensitaet == "HIT") {
          _self.hitOderLit = "HIT";
        }

        if (_self.typ && (_self.typ == "LIT" || _self.typ == "HIT")) {
          _self.hitOderLit = _self.typ;
        }
        _self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(this.$host + "/rezept/favorite/" + this.id)
      .then(function (response) {
        _self.isFavorite = response.data.isFavorite;
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(this.$host + "/rezept")
      .then(function (response) {
        _self.recipes = response.data.sort(() => Math.random() - 0.5);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.recipe-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.zutaten {
  font-size: 16px;
}

.zubereitung {
  text-align: justify;
  font-size: 16px;
}

.heading {
  padding: 20px;
  font-size: 80px;
}

.zubereitung a {
  font-weight: bold;
}

.sub-heading {
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  margin-top: 70px;
}

.naehrwerte {
  font-size: 16px;
}

.wissen {
  font-size: 25px;
  letter-spacing: 0.015rem;
}

.info {
  width: 60%;
  margin-bottom: 10px;
  max-width: 60px;
}

.ernaherungswissen {
  text-align: justify;
  border: 3px solid green;
  padding: 20px;
  font-size: 16px;
  line-height: 1.15;
}

.recipe-parent {
  overflow: hidden;
  /* Magic */
  display: flex;
  align-items: center; /* vertical */
  justify-content: center; /* horizontal */
}

.imgSpin {
  text-align: center;

  z-index: -1;
}

.recipe-img {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.recipe-img img {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
