

<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";
//import regression from "regression";

export default {
  props: ["fromDay", "until", "done", "wo", "reducex"],

  data: function () {
    return {
      workouts: [],
      loadedWeeks: this.weeks,
      loadOnlydone: this.done,
      reduceToSimilar: this.reducex || false,
      workoutsFiltered: [],
      from: this.fromDay,
      chart: undefined,

      chartdata: {
        labels: [],
        datasets: [
          {
            data: [],
            pointBackgroundColor: [],
            pointBorderColor: [],
            pointRadius: [],

            trendlineLinear: {
              style: "rgba(0,0,0, .8)",
              lineStyle: "dotted",
              width: 2,
            },
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            callbacks: {
              label: function (context) {
                var datasetLabel =
                  context.parsed.y + "w bei " + context.parsed.x + "bpm";
                return datasetLabel;
              },
            },
          },
        },

        scales: {
          yAxes: {
            title: {
              display: true,
              text: "watt",
            },
          },
          xAxes: {
            title: {
              display: true,
              text: "bpm",
            },
          },
        },

        maintainAspectRatio: false,
        responsive: true,
        onClick: this.clickedWorkout,
      },
    };
  },
  watch: {
    reducex: function (newVal) {
      this.reduceToSimilar = newVal;

      this.computeChartData();
    },
  },

  async mounted() {
    // TODO IDEE:
    // Haken setzen: nur gleichmäßige Einheiten berücksichtigen und dann
    // zb vergleich avg power und normalized power mit gewissem schwellwert
    // oder zeit in zonen verwenden
    let ctx = this.$refs.graph.getContext("2d");

    this.workouts = this.done;

    this.computeChartData();
    const config = {
      type: "scatter",
      data: this.chartdata,
      options: this.options,
    };

    new Chart(ctx, config);

    this.$emit("chartRendered");
  },

  methods: {
    clickedWorkout: function (click) {
      const points = click.chart.getElementsAtEventForMode(
        click,
        "nearest",
        { intersect: true },
        true
      );

      if (points.length) {
        const firstPoint = points[0];
        this.$router.push(
          "/auswertung/workout/details/" +
            this.workoutsFiltered[firstPoint.index].workout._id
        );
      }
    },
    getColorForPercentage: function (pct) {
      var a = (94 - pct) / 100,
        b = 250 * a;

      return "hsl(" + b + ", 100%, 40%)";
    },
    computeChartData: function () {
      const _self = this;

      _self.chartdata.datasets[0].pointRadius = [];
      _self.chartdata.datasets[0].data = [];
      _self.chartdata.datasets[0].pointBackgroundColor = [];
      _self.chartdata.datasets[0].pointBorderColor = [];

      this.workoutsFiltered = this.workouts.filter((workout) => {
        if (workout && workout.workout) {
          return (
            workout.workout.sport == "Radfahren" &&
            workout.avg_heart_rate &&
            workout.avg_power
          );
        }
      });
      if (
        this.reduceToSimilar &&
        this.wo &&
        this.wo.avg_heart_rate &&
        this.wo.avg_power
      ) {
        this.workoutsFiltered = this.workoutsFiltered.filter((workout) => {
          return (
            workout.workout.time_lit >= this.wo.time_lit - 12 &&
            workout.workout.time_lit <= this.wo.time_lit + 12 &&
            workout.workout.time_mit >= this.wo.time_mit - 7 &&
            workout.workout.time_mit <= this.wo.time_mit + 7 &&
            workout.workout.time_hit >= this.wo.time_hit - 3 &&
            workout.workout.time_hit <= this.wo.time_hit + 3
          );
          // TODO
          // nach zeit ?
        });
      }

      const minLength = Math.min.apply(
        Math,
        this.workoutsFiltered.map(function (w) {
          return w.workout.durationIs;
        })
      );

      const maxWoLength = Math.max.apply(
        Math,
        this.workoutsFiltered.map(function (w) {
          return w.workout.durationIs;
        })
      );

      let newWorkoutsFiltered = [];
      const totalDays = this.until.diff(this.from, "days");
      let totalCounter = 0;
      let predict_vals = [];
      while (this.from.isSameOrBefore(this.until, "day")) {
        totalCounter++;
        let tomorrow = this.$moment(this.from);
        tomorrow.add(1, "d");

        let workoutsOfDate = this.workoutsFiltered.filter((workout) => {
          return (
            this.$moment(workout.date).isSameOrAfter(this.from) &&
            this.$moment(workout.date).isBefore(this.$moment(tomorrow))
          );
        });

        workoutsOfDate.forEach((workout) => {
          predict_vals.push({
            x: Math.round(workout.avg_heart_rate),
            y: Math.round((workout.weighted_power + workout.avg_power) / 2),
          });

          _self.chartdata.datasets[0].data.push({
            x: Math.round(workout.avg_heart_rate),
            y: Math.round(workout.weighted_power || workout.avg_power),
          });
          _self.chartdata.datasets[0].pointBackgroundColor.push(
            _self.getColorForPercentage((totalCounter / totalDays) * 100)
          );
          _self.chartdata.datasets[0].pointBorderColor.push(
            _self.getColorForPercentage((totalCounter / totalDays) * 100)
          );
          const radius =
            2 +
            ((workout.workout.durationIs - minLength) /
              (maxWoLength - minLength)) *
              4;
          _self.chartdata.datasets[0].pointRadius.push(radius);
          newWorkoutsFiltered.push(workout);
        });
        this.from = this.from.add(1, "d");
      }

      this.workoutsFiltered = newWorkoutsFiltered;

      this.from = this.fromDay;

      if (this.wo && this.wo.avg_heart_rate && this.wo.avg_power) {
        this.chartdata.datasets[0].data.push({
          x: Math.round(this.wo.avg_heart_rate),
          y: Math.round(this.wo.weighted_power || this.wo.avg_power),
        });

        predict_vals.push({
          x: Math.round(this.wo.avg_heart_rate),
          y: Math.round((this.wo.weighted_power + this.wo.avg_power) / 2),
        });

        _self.chartdata.datasets[0].pointRadius.push(7);

        _self.chartdata.datasets[0].pointBackgroundColor.push("#000");
        _self.chartdata.datasets[0].pointBorderColor.push("#000");
      }
      /*
      const vals = predict_vals.map(function (f) {
        return [f.x, f.y];
      });

      const result = regression.linear(vals);

      const result10 = regression.linear(vals.slice(-10));
      const result15 = regression.linear(vals.slice(-15));
      const result20 = regression.linear(vals.slice(-20));
      const result30 = regression.linear(vals.slice(-30));
      const result40 = regression.linear(vals.slice(-40));
      const result50 = regression.linear(vals.slice(-50));

      // TODO: predict at max hr*0.9x-7 => ergibt schwelle!
      const hr = 160;
     
      console.log("All", result.predict(hr));
      console.log("10", result10.predict(hr));
      console.log("15", result15.predict(hr));
      console.log("20", result20.predict(hr));
      console.log("30", result30.predict(hr));
      console.log("40", result40.predict(hr));
      console.log("50", result50.predict(hr));*/
    },
  },
};
</script>

