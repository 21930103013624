<template>
  <div class="glossar">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8 white_wrapper">
        <h3 class="top-spacer-50">Glossar Schwimmen</h3>

        <div style="width: 100%; overflow: auto">
          <table class="table overflow-auto top-spacer-75">
            <thead>
              <tr>
                <th>Trainingsbereiche</th>
                <th>Belastungszeit</th>
                <th>Beispiel</th>
                <th>Intensität</th>
              </tr>
            </thead>
            <tr>
              <td>Regeneration (Recom)</td>
              <td>5 – 60 min</td>
              <td>200m locker</td>
              <td>3 – 4 RPE*</td>
            </tr>
            <tr>
              <td>Grundlagenausdauer 1 (GA1)</td>
              <td>15 – 60 min</td>
              <td>5* 400m Grundlage “20sec</td>
              <td>5 – 6 RPE*</td>
            </tr>
            <tr>
              <td>Grundlagenausdauer 1+ (Ga1+)</td>
              <td>15 – 60 min</td>
              <td>5* 200m Renngeschwindigkeit “20sec</td>
              <td>6 – 7 RPE*</td>
            </tr>
            <tr>
              <td>Grundlagenausdauer 2 (GA2)</td>
              <td>15 – 45 min</td>
              <td>12* 100m schnell “15sec</td>
              <td>7 – 8 RPE*</td>
            </tr>
            <tr>
              <td>Schnelligkeitsausdauer 1 (SA1)</td>
              <td>5 – 15 min</td>
              <td>10* 50m max. “10sec</td>
              <td>8 – 9 RPE*</td>
            </tr>
            <tr>
              <td>Schnelligkeitsausdauer 2 (SA2)</td>
              <td>15 – 30 min</td>
              <td>6* 50m max. '3min</td>
              <td>10 RPE*</td>
            </tr>
            <tr>
              <td>Grundschnelligkeit (GS)</td>
              <td>5 – 15 min</td>
              <td>6* 15m max. “30sec</td>
              <td>10 RPE*</td>
            </tr>
          </table>
        </div>

        <br />
        <h4>Abkürzungen</h4>
        <br />
        <strong>‘</strong> Pause/Belastung in Minuten <br />
        <strong>‘‘ </strong>Pause/Belastung in Sekunden <br />
        <strong>BA/DB:</strong> Brust Arme / Delphin Beine <br />
        <strong>Progressiv (prog):</strong> Schneller werden (Steigerung)
        <br />
        <strong>KuFl:</strong> Kurzflossen <br />
        <strong>RB:</strong> Rücken Beine <br />
        <strong>RT:</strong> Rotationstechnik <br />
        <strong>3er</strong> Atmung <br />
        <strong>5er</strong> Atmung <br />
        <strong>7er</strong> Atmung <br />
        <strong>AbH:</strong> Abschlag hinten <br />
        <strong>Abh:</strong> Abschlag hinten <br />
        <strong>AbRü:</strong> Abschlag Rücken <br />
        <strong>AbV:</strong> Abschlag Vorne <br />
        <strong>Ar:</strong> Arme <br />
        <strong>Ati:</strong> Abschlag Tippen <br />
        <strong>Aus:</strong> Ausschwimmen <br />
        <strong>BA:</strong> Band <br />
        <strong>BA/KB:</strong> Brust Arme / Kraul Beine <br />
        <strong>Bei:</strong> Beine <br />
        <strong>Br:</strong> Brust <br />
        <strong>CSS:</strong> Critical Swim Speed <br />
        <strong>D:</strong> Delfin <br />
        <strong>DA/BB:</strong>Delfin Arme Brust Beine <br />
        <strong>DEEA:</strong> Delfin Einarmig <br />
        <strong>Degressiv (degr):</strong> schnell anfangen, kontrolliert
        langsamer werden <br />
        <strong>Ein:</strong> Einschwimmen <br />
        <strong>FAHB:</strong> Freistil Arme mit Paddles <br />
        <strong>FQ:</strong> Front-Quadrant-Technik <br />
        <strong>FSchn:</strong> Freistil Schnorchel<br />
        <strong>i.W.:</strong> im Wechsel <br />
        <strong>KA/BB:</strong> Kraul Arme Brust Beine <br />
        <strong>Kr: </strong>Kraul <br />
        <strong>KREA:</strong> Kraul Einarmig <br />
        <strong>La:</strong> Lagen <br />
        <strong>Lo:</strong> Locker <br />
        <strong>Max:</strong> maximaler Sprint <br />
        <strong>Pad:</strong> Paddles <br />
        <strong>PB:</strong> Pullbuoy <br />
        <strong>RA:</strong> Rücken Arme <br />
        <strong>RPE</strong>: Intensität (1-10) <br />
        <strong>Rü:</strong> Rücken <br />
        <strong>Stg:</strong> Steigerung <br />

        <strong>TA:</strong> Tauchen <br />
        <strong>TE:</strong> Technik <br />

        <strong>TÜ:</strong> Technische Übung <br />
        <strong>Wriggen:</strong> z.B. Scheibenwischer <br />
        <strong>ZF:</strong> Zugfrequenz <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Glossar",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.glossar {
  font-size: 16px;
  text-align: left;
}
</style>
