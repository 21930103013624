<template>
  <div class="effort-list">
    <div class="filters">
      <input type="date" v-model="dateFilter" class="filter-date" :max="today" />
    </div>

    <div v-if="loading" class="loading">Loading efforts...</div>

    <div v-else-if="error" class="error">
      {{ error }}
    </div>

    <div v-else class="tables-container">
      <!-- Cycling Table -->
      <div class="table-section">
        <h2>Cycling Efforts</h2>
        <div class="table-container">
          <table v-if="cyclingEfforts.length">
            <thead>
              <tr>
                <th>Date</th>
                <th>Data Points</th>
                <th>VO2max</th>
                <th>VLamax</th>
                <th>FTP</th>
                <th>Profile FTP</th>
                <th>Quality</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="effort in cyclingEfforts" :key="effort._id">
                <td>{{ formatDate(effort.date) }}</td>
                <td>{{ effort.processedLength || 0 }}</td>
                <td>{{ formatNumber(effort.estimatedVo2Max) }}</td>
                <td>{{ formatNumber(effort.estimatedVLaMax) }}</td>
                <td>{{ formatNumber(effort.thresholdPower) }}W</td>
                <td>{{ formatNumber(effort.profileThresholdPower) }}W</td>
                <td>
                  <div class="quality-indicator" :style="qualityStyle(effort.regressionQuality)">
                    {{ formatPercent(effort.regressionQuality) }}%
                  </div>
                </td>
                <td>
                  <router-link :to="{ name: 'effort-viewer', params: { id: effort._id } }" class="view-button">
                    View
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="no-data">No cycling efforts found</div>
        </div>
      </div>

      <!-- Running Table -->
      <div class="table-section">
        <h2>Running Efforts</h2>
        <div class="table-container">
          <table v-if="runningEfforts.length">
            <thead>
              <tr>
                <th>Date</th>
                <th>Data Points</th>
                <th>VO2max</th>
                <th>VLamax</th>
                <th>Threshold Pace</th>
                <th>Profile Pace</th>
                <th>Quality</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="effort in runningEfforts" :key="effort._id">
                <td>{{ formatDate(effort.date) }}</td>
                <td>{{ effort.processedLength || 0 }}</td>
                <td>{{ formatNumber(effort.estimatedVo2Max) }}</td>
                <td>{{ formatNumber(effort.estimatedVLaMax) }}</td>
                <td>{{ formatPace(effort.thresholdPace) }}</td>
                <td>{{ formatPace(effort.profileThresholdPace) }}</td>
                <td>
                  <div class="quality-indicator" :style="qualityStyle(effort.regressionQuality)">
                    {{ formatPercent(effort.regressionQuality) }}%
                  </div>
                </td>
                <td>
                  <router-link :to="{ name: 'effort-viewer', params: { id: effort._id } }" class="view-button">
                    View
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="no-data">No running efforts found</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EffortList",
  data() {
    return {
      efforts: [],
      loading: true,
      error: null,
      dateFilter: "",
      today: new Date().toISOString().split("T")[0],
    };
  },
  async created() {
    await this.loadEfforts();
  },
  computed: {
    filteredEfforts() {
      return this.efforts
        .filter((effort) => {
          const matchesDate = !this.dateFilter || new Date(effort.date).toISOString().split("T")[0] === this.dateFilter;
          return matchesDate;
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    cyclingEfforts() {
      return this.filteredEfforts.filter((effort) => effort.sport === "Radfahren");
    },
    runningEfforts() {
      return this.filteredEfforts.filter((effort) => effort.sport === "Laufen");
    },
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    formatDuration(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours}:${minutes.toString().padStart(2, "0")}`;
    },
    formatNumber(num) {
      return num ? Number(num).toFixed(1) : "-";
    },
    formatPercent(num) {
      return num ? (num * 100).toFixed(1) : "-";
    },
    formatPace(speedKmh) {
      if (!speedKmh) return "-";
      const paceMinKm = 60 / speedKmh;
      const minutes = Math.floor(paceMinKm);
      const seconds = Math.round((paceMinKm - minutes) * 60);
      return `${minutes}:${seconds.toString().padStart(2, "0")}/km`;
    },
    qualityStyle(quality) {
      if (!quality) return { backgroundColor: "#f5f5f5" };
      const hue = Math.min(quality * 120, 120); // 0 = red, 120 = green
      return {
        backgroundColor: `hsl(${hue}, 70%, 90%)`,
        color: `hsl(${hue}, 70%, 30%)`,
      };
    },
    async loadEfforts() {
      this.loading = true;
      this.error = null;
      try {
        const response = await axios.get("http://localhost:4000/effort/efforts");
        this.efforts = response.data;
      } catch (err) {
        this.error = "Failed to load efforts: " + (err.response?.data?.error || err.message);
        console.error("Error loading efforts:", err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.effort-list {
  padding: 20px;
}

.filters {
  margin-bottom: 20px;
  display: flex;
  gap: 15px;
}

.filter-date {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.tables-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.table-section {
  flex: 1;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 0;
}

.table-section h2 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 1.5em;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #666;
}

.quality-indicator {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
}

.view-button {
  display: inline-block;
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 12px;
}

.view-button:hover {
  background-color: #0056b3;
}

.no-data {
  padding: 40px;
  text-align: center;
  color: #666;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }

  th,
  td {
    padding: 8px;
    font-size: 12px;
  }
}

.loading,
.error {
  padding: 40px;
  text-align: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error {
  color: #dc3545;
}

/* Add responsive behavior for mobile screens */
@media (max-width: 1200px) {
  .tables-container {
    flex-direction: column;
  }
}
</style>
