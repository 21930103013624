<template>
  <div class="macronutrient-chart">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'MacronutrientChart',
  props: ['entries'],
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.createChart();
    });
  },
  watch: {
    entries: {
      handler() {
        this.$nextTick(() => {
          this.updateChart();
        });
      },
      deep: true
    }
  },
  methods: {
    createChart() {

      const ctx = this.$refs.chart.getContext('2d');
      const macroData = this.calculateMacroData();

      this.chart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ['Kohlenhydrate', 'Fett', 'Protein'],
          datasets: [{
            data: [macroData.carbs, macroData.fat, macroData.protein],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          }],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
            },
            title: {
              display: true,
              text: 'Makronährstoffe Verteilung',
            },
          },
        },
      });
    },
    updateChart() {
      if (!this.chart) {
        this.createChart();
        return;
      }
      const macroData = this.calculateMacroData();
      this.chart.data.datasets[0].data = [macroData.carbs, macroData.fat, macroData.protein];
      this.chart.update();
    },
    calculateMacroData() {
      const macroData = this.entries.reduce((acc, entry) => {
        acc.carbs += (entry.carbs || 0) * (entry.servings || 1);
        acc.fat += (entry.fat || 0) * (entry.servings || 1);
        acc.protein += (entry.protein || 0) * (entry.servings || 1);
        return acc;
      }, { carbs: 0, fat: 0, protein: 0 });

      const total = macroData.carbs + macroData.fat + macroData.protein;
      if (total === 0) {
        return { carbs: 1, fat: 1, protein: 1 }; // Prevent division by zero
      }

      return {
        carbs: (macroData.carbs / total) * 100,
        fat: (macroData.fat / total) * 100,
        protein: (macroData.protein / total) * 100
      };
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style scoped>
.macronutrient-chart {
  width: 100%;
  height: 400px;
}
</style>