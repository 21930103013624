import { Preferences } from '@capacitor/preferences';

/**
 * Helper utility for handling storage operations using Capacitor Preferences
 */
const storageHelper = {
  /**
   * Get item from storage
   * @param {string} key Storage key
   * @returns {Promise<any>} Stored value
   */
  async getItem(key) {
    try {
      const { value } = await Preferences.get({ key });
      if (!value) return null;
      
      // Try parsing as JSON, if it fails return the raw value
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    } catch (err) {
      console.error('Error getting item from storage:', err);
      return null;
    }
  },

  /**
   * Set item in storage
   * @param {string} key Storage key 
   * @param {any} value Value to store
   * @returns {Promise<void>}
   */
  async setItem(key, value) {
    try {
      // Only stringify if the value is an object or array
      const stringValue = typeof value === 'object' && value !== null 
        ? JSON.stringify(value) 
        : String(value);
        
      await Preferences.set({ key, value: stringValue });
    } catch (err) {
      console.error('Error setting item in storage:', err);
    }
  },

  /**
   * Remove item from storage
   * @param {string} key Storage key
   * @returns {Promise<void>}
   */
  async removeItem(key) {
    try {
      await Preferences.remove({ key });
    } catch (err) {
      console.error('Error removing item from storage:', err);
    }
  },

  /**
   * Clear all items from storage
   * @returns {Promise<void>}
   */
  async clear() {
    try {
      await Preferences.clear();
    } catch (err) {
      console.error('Error clearing storage:', err);
    }
  }
};

export default storageHelper; 