<template>
  <div class="row">
    <div class="submenu col-xl-2 d-none d-xl-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/esszimmer"> <div class="menu-entry">Rezepte</div></a>
          </div>
          <div class="col-lg-12">
            <a href="/#/esszimmer/foodlog"> <div class="menu-entry active_submenu">Foodlog</div></a>
          </div>

          <div class="col-lg-12">
            <a href="/#/esszimmer/plan">
              <div class="menu-entry">Vorschläge</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-10 top-spacer-10">
      <div
        class="row top-spacer-15"
        style="
          background: #f6c050;
          padding-top: 20px;
          padding-bottom: 12px;
          letter-spacing: 0.3rem;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        "
      >
        <div class="col-lg-12">
          <img
            src="https://assets.pushinglimits.club/wtf_trans.png"
            class="pull-left d-none d-md-block"
            style="box-shadow: none !important; max-width: 170px; width: 80%; margin-top: -7px"
            alt="where is the food?"
          /><strong>FOODLOG</strong> /// MACH' DIR BEWUSST, WAS DU ISST
        </div>
      </div>

      <div class="row white_wrapper" id="new_entry">
        <div class="col-lg-6" style="margin-bottom: 15px" v-if="!photo">
          <h4>Neuen Eintrag anlegen</h4>
          <button style="margin: 1px" @click="takePicture">+ Bild</button>
          <button style="margin: 1px" @click="newTxtEntry">+ Text</button><br />
          <div class="top-spacer-10">
            <small>Das Foodlog ist eine ganz neue Funktion. Sende uns gerne dein Feedback per Mail!</small>
          </div>
        </div>

        <div class="col-lg-6" style="margin-bottom: 15px" v-if="!photo">
          <h4>Jahresüberblick</h4>

          <div class="row">
            <div class="col-4">
              <div style="border: 2px solid green; border-radius: 5px">
                <div style="background: green; color: #fff">Gut</div>
                <h4 class="top-spacer-10">
                  {{ goodMeals }} ({{ Math.round((goodMeals / (goodMeals + okayMeals + badMeals || 1)) * 100) }}%)
                </h4>
              </div>
            </div>
            <div class="col-4">
              <div style="border: 2px solid #d47404; border-radius: 5px">
                <div style="background: #d47404; color: #fff">Okay</div>
                <h4 class="top-spacer-10">
                  {{ okayMeals }} ({{ Math.round((okayMeals / (goodMeals + okayMeals + badMeals || 1)) * 100) }}%)
                </h4>
              </div>
            </div>
            <div class="col-4">
              <div style="border: 2px solid darkred; border-radius: 5px">
                <div style="background: darkred; color: #fff">Schlecht</div>
                <h4 class="top-spacer-10">
                  {{ badMeals }} ({{ Math.round((badMeals / (goodMeals + okayMeals + badMeals || 1)) * 100) }}%)
                </h4>
              </div>
            </div>
          </div>

          <!--
          <div class="row">
            <div
              class="col-1"
              style="padding: 0; line-height: 1; margin: 0"
              v-for="entry in entries"
              :key="entry.id"
            >
              <div
                v-if="entry.rating == 'Bad'"
                class="dot"
                style="background: darkred"
              ></div>
              <div
                v-if="entry.rating == 'Okay'"
                class="dot"
                style="background: orange"
              ></div>
              <div
                v-if="entry.rating == 'Good'"
                class="dot"
                style="background: green"
              ></div>
            </div>
          </div>-->
        </div>
        <div class="col-lg-12 centered top-spacer-15">
          <div v-if="!loaded" style="margin: 20px" class="spinner-border text-primary" role="status"></div>
        </div>
        <div class="col-lg-12" v-if="photo" style="border-radius: 15px; background: #f3f3f3; padding: 20px">
          <div class="row">
            <div class="col-lg-6 centered">
              <img class="upload_img" :src="photo.dataUrl" />
            </div>

            <div class="col-lg-6 centered top-spacer-25">
              <h4>Wie passt das Essen zu deinen persönlichen Zielen?</h4>
              <div class="row">
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadPicture('Good')" class="closer" style="background: green">Gut</button>
                </div>
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadPicture('Okay')" class="thirdary">Okay</button>
                </div>
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadPicture('Bad')" class="delete">Schlecht</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12" v-if="showTxt" style="border-radius: 15px; background: #f3f3f3; padding: 20px">
          <div class="row">
            <div class="col-lg-6 centered">
              <h4 class="top-spacer-25">1. Was hast du gegessen?</h4>
              <input type="text" style="width: 100%" v-model="txt" />
            </div>

            <div class="col-lg-6 centered top-spacer-25">
              <h4>2. Wie passt das Essen zu deinen persönlichen Zielen?</h4>
              <div class="row">
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadText('Good')" class="closer" style="background: green">Gut</button>
                </div>
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadText('Okay')" class="thirdary">Okay</button>
                </div>
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadText('Bad')" class="delete">Schlecht</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3 col-12 top-spacer-50" v-for="entry in entries" :key="entry._id">
              <div style="margin-bottom: 10px" :id="entry._id">
                <strong>{{ $moment(entry.createdAt).format("DD.MM.YYYY") }} </strong>
              </div>
              <div class="rated">
                <div v-if="entry.rating == 'Bad'" class="dot" style="background: darkred; border-radius: 5px"></div>
                <div v-if="entry.rating == 'Okay'" class="dot" style="background: #d47404; border-radius: 5px"></div>
                <div v-if="entry.rating == 'Good'" class="dot" style="background: green; border-radius: 5px"></div>

                <div v-if="entry.text" class="pic_img pointer" @click="showDeleteModal(entry._id)">
                  <h4 style="padding: 15px">{{ entry.text }}</h4>
                </div>
                <img
                  v-if="entry.image_link"
                  @click="showDeleteModal(entry._id)"
                  class="lazy pic_img"
                  style="cursor: pointer"
                  :data-src="'https://foodpictures.pushinglimits.club/' + entry.image_link"
                />
              </div>
              <div v-if="isShowDeleteModal(entry._id)" class="top-spacer-25">
                <input type="date" style="width: 100%" v-model="modalDate" /><br /><br />
                <select v-model="modalRating" style="width: 100%">
                  <option value="Bad" v-if="entry.rating == 'Bad'" selected>Schlecht</option>
                  <option value="Bad" v-else>Schlecht</option>
                  <option value="Okay" v-if="entry.rating == 'Okay'" selected>Okay</option>
                  <option value="Okay" v-else>Okay</option>
                  <option value="Good" v-if="entry.rating == 'Good'" selected>Gut</option>
                  <option value="Good" v-else>Gut</option>
                </select>
                <br /><br />
                <button @click="updatePicture(entry._id)">Speichern</button>
                <br /><br />
                <span @click="deletePicture(entry._id)" style="cursor: pointer">
                  <h4><i class="fa fa-trash"></i> Eintrag löschen</h4>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Camera, CameraResultType } from "@capacitor/camera";

export default {
  data() {
    return {
      entries: null,
      photo: null,
      deleteModal: null,
      title: null,
      modalDate: null,
      modalRating: null,
      loaded: false,
      txt: null,
      showTxt: false,
    };
  },
  mounted: async function () {
    await this.refresh();
    window.addEventListener("scroll", this.lazyLoad);
    this.lazyLoad();
  },

  computed: {
    goodMeals: function () {
      return (this.entries && this.entries.filter((entry) => entry.rating === "Good").length) || 0;
    },
    okayMeals: function () {
      return (this.entries && this.entries.filter((entry) => entry.rating === "Okay").length) || 0;
    },
    badMeals: function () {
      return (this.entries && this.entries.filter((entry) => entry.rating === "Bad").length) || 0;
    },
  },

  methods: {
    newTxtEntry() {
      this.photo = null;
      this.showTxt = true;
    },

    async takePicture() {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.DataUrl,
      });

      this.photo = image;
      this.showTxt = false;
    },

    async refresh() {
      this.loaded = false;
      const response365d = await axios.get(this.$host + "/food/365d");
      const entries365d = response365d.data;

      this.entries = entries365d;
      this.loaded = true;
      // nexttick
      this.$nextTick(() => {
        this.lazyLoad();
      });
    },

    async deletePicture(id) {
      const index = this.entries.findIndex((entry) => entry._id === id);

      this.photo = undefined;
      await axios.delete(this.$host + "/food/" + id);

      // Scroll to the next or previous entry in the list, or to the "Neuen Eintrag anlegen" section if there are no entries left
      if (this.entries.length > 0) {
        if (index < this.entries.length - 1) {
          const nextEntry = this.entries[index + 1];
          const nextEntryId = nextEntry._id;
          console.log("Scroll to next entry " + nextEntryId);

          const nextEntryElement = document.getElementById(nextEntryId);

          await this.refresh();

          if (nextEntryElement) {
            nextEntryElement.scrollIntoView({
              block: "start",
              inline: "nearest",
            });
          }
        } else if (index > 0) {
          console.log("Scroll to previous entry");
          const prevEntry = this.entries[index - 1];
          const prevEntryId = prevEntry._id;
          const prevEntryElement = document.getElementById(prevEntryId);

          await this.refresh();

          if (prevEntryElement) {
            prevEntryElement.scrollIntoView({
              block: "start",
              inline: "nearest",
            });
          }
        } else {
          const newEntryElement = document.getElementById("new_entry");
          if (newEntryElement) {
            newEntryElement.scrollIntoView({
              block: "start",
              inline: "nearest",
            });
          }
        }
      } else {
        console.log("Scroll to new_entry");
        await this.refresh();

        const newEntryElement = document.getElementById("new_entry");
        if (newEntryElement) {
          newEntryElement.scrollIntoView({
            block: "start",
            inline: "nearest",
          });
        }
      }

      // now account for fixed header
      var scrolledY = window.scrollY;

      if (scrolledY) {
        window.scroll(0, scrolledY - 80);
      }

      await this.refresh();
    },

    isShowDeleteModal(id) {
      return this.deleteModal === id;
    },

    showDeleteModal(id) {
      this.deleteModal = id;

      this.modalDate = this.$moment(this.entries.find((entry) => entry._id === id).createdAt).format("YYYY-MM-DD");

      this.modalRating = this.entries.find((entry) => entry._id === id).rating;

      console.log(this.modalDate);
    },

    async updatePicture(id) {
      await axios.post(this.$host + "/food/update/" + id, {
        createdAt: this.modalDate,
        rating: this.modalRating,
      });

      this.refresh();
      this.deleteModal = null;

      // TODO: Refresh Auswertung
    },

    async uploadText(rating) {
      this.loaded = false;

      axios
        .post(this.$host + "/food/upload/text", {
          text: this.txt,
          rating: rating,
        })
        .then(async () => {
          const response = await axios.get(this.$host + "/food/365d");

          this.entries = response.data;
          this.showTxt = false;

          this.loaded = true;
        });
    },

    async uploadPicture(rating) {
      // Convert DataUrl to Blob
      const response = await fetch(this.photo.dataUrl);
      const blob = await response.blob();

      this.loaded = false;

      // Create FormData
      const formData = new FormData();
      formData.append("file", blob, "photo.jpg");
      formData.append("rating", rating);

      // Send a POST request to your API
      try {
        await axios
          .post(this.$host + "/food/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async () => {
            this.photo = undefined;
            this.loaded = true;
            this.refresh();
          });
      } catch (err) {
        console.error(err);
      }
    },
    lazyLoad: function () {
      let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
      let active = false;
      const _self = this;
      if (active === false) {
        active = true;
        setTimeout(() => {
          lazyImages.forEach(function (lazyImage) {
            if (
              lazyImage.getBoundingClientRect().top <= window.innerHeight + 100 &&
              lazyImage.getBoundingClientRect().bottom >= 0 &&
              getComputedStyle(lazyImage).display !== "none"
            ) {
              lazyImage.src = lazyImage.dataset.src;
              lazyImage.classList.remove("lazy");
              lazyImages = lazyImages.filter(function (image) {
                return image !== lazyImage;
              });

              if (lazyImages.length === 0) {
                window.removeEventListener("scroll", _self.lazyLoad);
              }
            }
          });
          active = false;
        }, 30);
      }
    },
  },
};
</script>

<style scoped>
.pic_img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 250px;
  z-index: 50;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.upload_img {
  margin: 20px;
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.dot {
  height: 7px;
  width: 100%;
  display: inline-block;
}
</style>
