<template>
  <div class="effort-viewer">
    <div v-if="loading" class="loading">Loading effort...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <template v-else-if="effort">
      <div class="metrics-container">
        <div class="metric">
          <span class="label">VO2max:</span>
          <span class="value">{{ formatNumber(effort.estimatedVo2Max) }} ml/kg/min</span>
        </div>
        <div class="metric">
          <span class="label">VLamax:</span>
          <span class="value">{{ formatNumber(effort.estimatedVLaMax) }} mmol/l/s</span>
        </div>
        <div class="metric">
          <span class="label">Regression Quality:</span>
          <span class="value">{{ formatPercent(effort.regressionQuality) }}%</span>
        </div>
      </div>

      <div class="chart-container" ref="chartContainer">
        <canvas ref="chart"></canvas>
      </div>

      <div class="threshold-container">
        <div v-if="effort.sport === 'Radfahren'" class="threshold">
          <div class="threshold-item">
            <span class="label">Estimated FTP:</span>
            <span class="value">{{ formatNumber(effort.thresholdPower) }}W</span>
          </div>
          <div class="threshold-item">
            <span class="label">Profile FTP:</span>
            <span class="value">{{ formatNumber(effort.profileThresholdPower) }}W</span>
          </div>
        </div>
        <div v-else class="threshold">
          <div class="threshold-item">
            <span class="label">Estimated Threshold Pace:</span>
            <span class="value">{{ formatPace(effort.thresholdPace) }}/km</span>
          </div>
          <div class="threshold-item">
            <span class="label">Profile Threshold Pace:</span>
            <span class="value">{{ formatPace(effort.profileThresholdPace) }}/km</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "axios";

export default {
  name: "EffortViewer",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      effort: null,
      loading: true,
      error: null,
      chart: null,
    };
  },
  async created() {
    await this.loadEffort();
  },
  methods: {
    async loadEffort() {
      this.loading = true;
      this.error = null;
      try {
        console.log("Loading effort with ID:", this.id);
        const response = await axios.get(`http://localhost:4000/effort/efforts/${this.id}`);
        this.effort = response.data;
      } catch (err) {
        this.error = "Failed to load effort: " + (err.response?.data?.error || err.message);
        console.error("Error loading effort:", err);
      } finally {
        this.loading = false;
      }
    },
    formatNumber(num) {
      return num ? Number(num).toFixed(1) : "-";
    },
    formatPercent(num) {
      return num ? (num * 100).toFixed(1) : "-";
    },
    formatPace(speedMs) {
      if (!speedMs) return "-";
      const paceMinKm = 16.6667 / speedMs; // Convert m/s to min/km
      const minutes = Math.floor(paceMinKm);
      const seconds = Math.round((paceMinKm - minutes) * 60);
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    },
    createChart() {
      if (!this.effort) return;

      const ctx = this.$refs.chart.getContext("2d");

      // Filter points to only include those above 50% HR
      const validPoints = this.effort.processedData
        .filter((point) => point.isValid && point.hrReserve >= 50)
        .map((point) => ({
          x: point.hrReserve,
          y: this.effort.sport === "Radfahren" ? point.power : point.pace,
        }));

      // Filter regression line points to start at 50%
      const regressionLine = this.effort.regressionPoints
        .filter((point) => point.hrReserve >= 50)
        .map((point) => ({
          x: point.hrReserve,
          y: point.predictedValue,
        }));

      this.chart = new Chart(ctx, {
        type: "scatter",
        data: {
          datasets: [
            {
              label: "Data Points",
              data: validPoints,
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              pointRadius: 3,
            },
            {
              label: "Regression Line",
              data: regressionLine,
              type: "line",
              borderColor: "rgba(255, 99, 132, 1)",
              pointRadius: 0,
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: "Heart Rate (%)",
              },
              min: 50, // Set minimum x-axis value to 50%
              max: 100, // Set maximum x-axis value to 100%
            },
            y: {
              title: {
                display: true,
                text: this.effort.sport === "Radfahren" ? "Power (W)" : "Pace (km/h)",
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.dataset.label || "";
                  const value = context.parsed.y;
                  if (this.effort.sport === "Laufen") {
                    return `${label}: ${this.formatPace(value)}/km`;
                  }
                  return `${label}: ${Math.round(value)}W`;
                },
              },
            },
          },
        },
      });
    },
    updateChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      this.createChart();
    },
  },
  watch: {
    effort: {
      handler() {
        this.$nextTick(() => {
          this.updateChart();
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.effort-viewer {
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
}

.metrics-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
}

.metric {
  background: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-container {
  height: 400px;
  background: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.threshold-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.threshold {
  background: white;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.threshold-item {
  margin: 5px 0;
}

.label {
  font-weight: bold;
  color: #666;
  margin-right: 8px;
}

.value {
  color: #2c3e50;
}
</style>
