import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: {},
  },
  mutations: {
    update_user(state, user) {
      state.user = user;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token, user) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
  },
  actions: {
    login({ commit }, user) {
      console.log(Vue.$host);
      console.log("Login now...")
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: this._vm.$host + "/user/login",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.token;
            const user = resp.data.user;
            localStorage.setItem("token", token);
            this._vm.$client.setClient(user.client || "pushinglimits");
            localStorage.setItem("client", user.client || "pushinglimits");

            axios.defaults.headers.common["Authorization"] = token;
            if (window && window.cordova) {
              window.cordova.plugin.http.setHeader("Authorization", token); // TODO HOstname als ersten Parameter setzen?
              //window.store.refresh();
            }
            commit("auth_success", token, user);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    refresh_user({ commit }) {
      return new Promise((resolve, reject) => {
        axios({ url: this._vm.$host + "/user/me", method: "GET" })
          .then((resp) => {
            console.log("Refresh User", resp.data);
            commit("update_user", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cookie_login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: this._vm.$host + "/user/login_cookie",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const user = resp.data.user;
            localStorage.setItem("token", "cookie");

            this._vm.$client.setClient(user.client || "pushinglimits");
            localStorage.setItem("client", user.client || "pushinglimits");

            commit("auth_success", "cookie", user);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    session_login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: this._vm.$host + "/user/login_session",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const user = resp.data.user;
            localStorage.setItem("token", "cookie");

            this._vm.$client.setClient(user.client || "pushinglimits");
            localStorage.setItem("client", user.client || "pushinglimits");

            commit("auth_success", "cookie", user);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    register_plattfuss({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: this._vm.$host + "/user/signup/plattfuss",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.token;
            const user = resp.data.user;

            localStorage.setItem("token", token);
            commit("auth_success", token, user);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    register_david({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: this._vm.$host + "/user/signup/david",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.token;
            const user = resp.data.user;

            localStorage.setItem("token", token);
            commit("auth_success", token, user);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    register_ironman_frankfurt({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: this._vm.$host + "/user/signup/ironman_frankfurt",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.token;
            const user = resp.data.user;

            localStorage.setItem("token", token);
            commit("auth_success", token, user);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: this._vm.$host + "/user/signup",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.token;
            const user = resp.data.user;

            localStorage.setItem("token", token);
            commit("auth_success", token, user);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    // Cleans localStorage and sends logout to Server
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");

        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];

        if (window && window.cordova) {
          window.cordova.plugin.http.setHeader("Authorization", ""); // todo: undefined?
        }

        axios({ url: this._vm.$host + "/user/logout", method: "POST" })
          .then((resp) => {
            commit("logout");

            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            reject(err);
          });
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    user: (state) => state.user,
  },
});
