<template>
  <div class="top-dishes-chart">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'TopDishesChart',
  props: ['entries'],
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.createChart();
  },
  watch: {
    entries: {
      handler() {
        this.updateChart();
      },
      deep: true
    }
  },
  methods: {
    createChart() {
      const ctx = this.$refs.chart.getContext('2d');
      const topDishes = this.getTopDishes();

      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: topDishes.map(dish => dish.name),
          datasets: [{
            label: 'Häufigkeit',
            data: topDishes.map(dish => dish.count),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          }],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Anzahl',
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Top 10 Gerichte',
            },
          },
        },
      });
    },
    updateChart() {
      if (this.chart) {
        const topDishes = this.getTopDishes();
        this.chart.data.labels = topDishes.map(dish => dish.name);
        this.chart.data.datasets[0].data = topDishes.map(dish => dish.count);
        this.chart.update();
      }
    },
    getTopDishes() {
      const dishCounts = {};
      this.entries.forEach(entry => {
        dishCounts[entry.name] = (dishCounts[entry.name] || 0) + 1;
      });

      return Object.entries(dishCounts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(([name, count]) => ({ name, count }));
    },
  },
};
</script>