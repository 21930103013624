 <template>
  <div>
    <div id="kraftraum-exercise" class="row">
      <div class="col-lg-12">
        <div class="centered">
          <div class="row" v-show="!workoutStarted">
            <div class="col-lg-7">
              <!--
              <img src="@/assets/images/kraft.jpg" class="hero-img" />
              -->
            </div>
            <div class="col-lg-5">
              <h2 class="cat-heading-smaller">
                Wie lange möchtest du trainieren?
              </h2>
              <h3 class="cat-heading-very-small">
                <small>(1-60 Minuten)</small>
              </h3>
              <button v-on:click="generateWorkout">WORKOUT STARTEN</button>
            </div>
          </div>

          <div class="row" v-show="workoutStarted && getTimeLeft() > 0">
            <div class="col-lg-12">
              <div id="playArea">
                <!-- TODO OVERLAY SO IM WATTRAUM!!!-->
                <div class="yt-overlay row">
                  <div class="col-lg-8">
                    <div class="yt-text">
                      <router-link
                        @click.native="$scrollToTop()"
                        to="/kraftraum"
                        style="color: #fff"
                      >
                        <span class="close-kraftraum">
                          <i
                            class="fa fa-chevron-circle-left"
                            aria-hidden="true"
                          ></i
                        ></span>

                        <span class="staydreaming workout-name">zurück</span>
                        <span class="d-block d-md-none">
                          {{ getTimeLeft() | timeInMinutes }}
                        </span>
                      </router-link>

                      <span class="workout-next"
                        >Next: {{ nextWorkoutTitle }}</span
                      >
                    </div>
                  </div>

                  <div class="col-lg-4 content">
                    <h1 class="d-none d-md-block time-heading">
                      {{ getTimeLeft() | timeInMinutes }}
                    </h1>

                    <!--
                                    <h2 class="current-workout">{{currentWorkoutTitle}}</h2>
                                    <h2 class="centered">und als nächstes</h2>
                                    <h2 class="current-workout">{{nextWorkoutTitle}}</h2>-->
                  </div>
                </div>
                <div>
                  <youtube
                    id="player_iframe"
                    :video-id="videoId"
                    ref="youtube"
                    :player-vars="options"
                    @playing="playing"
                    @ready="ready"
                    @ended="ended"
                    @paused="paused"
                  ></youtube>
                </div>
              </div>
            </div>
          </div>
          <div v-show="workoutStarted && getTimeLeft() == 0">
            <div class="row">
              <div class="col-lg-12 centered">
                <h1 class="cat-heading-medium centered">
                  Geschafft! <br /><small>Und jetzt ab auf die Couch.</small>
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-6">
                <!--<img
                  src="@/assets/images/couch.jpg"
                  class="top-spacer-75 hero-img"
                />-->
              </div>
              <div class="col-lg-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Exercise",

  props: {
    strengthWorkouts: Array,
  },
  data: function () {
    return {
      allStrengthWorkouts: this.strengthWorkouts,
      videoId: "",
      workoutStarted: false,
      generatedDuration: 0,
      selectedWorkouts: [],
      restDurationSec: 15,
      timer: 0,
      options: {
        autoplay: 1,
        controls: 0,
        modestbranding: 1,
        rel: 0,
        showinfo: 0,
      },
      nextWorkoutTitle: "",
      currentVideoId: 0,
      currentWorkoutTitle: "Workout",
      currentWorkoutDescription: "",
      currentWorkoutDescriptionEasy: "Einfache Version",
      currentWorkoutDescriptionHard: "Schwere Version",
      workoutPaused: true,
      workouts: [
        {
          id: 1,
          type: "core",
          duration: 50,
          title: "Crunch",
          video_id: "G-MZqWHBRmc",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },
        {
          id: 2,
          type: "core",
          duration: 50,
          title: "Wechselbeinschlag",
          video_id: "cEDX8eLnVLo",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },
        {
          id: 3,
          type: "core",
          duration: 50,
          title: "Beinheben",
          video_id: "NHDJGyzbVAk",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },
        {
          id: 4,
          type: "legs",
          duration: 50,
          title: "Kniebeuge",
          video_id: "JX_wElvzeu4",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },
        {
          id: 5,
          type: "legs",
          duration: 50,
          title: "Ausfallschritte",
          video_id: "UHet7fZd5Os",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },
        {
          id: 6,
          type: "legs",
          duration: 50,
          title: "Sprung",
          video_id: "W93W1x6_0mM",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },

        {
          id: 7,
          type: "arms",
          duration: 50,
          title: "Liegestütze",
          video_id: "Y48269zF31A",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },
        {
          id: 8,
          type: "arms",
          duration: 50,
          title: "Liegestütze mit Armen eng",
          video_id: "s5JCKOI2tVE",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },
        {
          id: 9,
          type: "arms",
          duration: 50,
          title: "Liegestütze mit Armen weit",
          video_id: "s5JCKOI2tVE",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },

        {
          id: 10,
          type: "back",
          duration: 50,
          title: "Klimmzug",
          video_id: "JX_wElvzeu4",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },
        {
          id: 11,
          type: "back",
          duration: 50,
          title: "Hochdrücken",
          video_id: "cEDX8eLnVLo",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },
        {
          id: 12,
          type: "back",
          duration: 50,
          title: "Runterdrücken",
          video_id: "cEDX8eLnVLo",
          description: "Der Klassiker: Liegestütze!",
          description_version_easy: "Lege die Knie auf dem Boden ab",
          description_version_hard: "Mit ganzer Kraft abdrücken",
        },
      ],
    };
  },

  mounted: function () {
    this.generateWorkout();
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      this.workoutPaused = false;
    },
    paused() {
      this.workoutPaused = true;
    },
    ended() {
      this.currentVideoId++;
      if (this.currentVideoId < this.selectedWorkouts.length) {
        this.initWorkout();
      }
    },
    ready() {
      console.log("ready");

      this.initWorkout();
      this.startTimer();
    },
    generateWorkout: function () {
      this.workoutStarted = true;
      this.generatedWorkoutsNum = 0;
      const _self = this;
      this.allStrengthWorkouts.forEach((workoutId) => {
        let wo = this.workouts.find((workout) => workout.id == workoutId);
        _self.selectedWorkouts = _self.selectedWorkouts.concat([wo]);
        _self.generatedWorkoutsNum += 1;
      });
    },
    shuffle: function (a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    },
    totalWorkOutDuration: function () {
      // TODO

      return this.selectedWorkouts.reduce(
        (acc, workout) => acc + workout.duration,
        0
      );
    },
    startTimer: function () {
      window.setInterval(() => {
        if (!this.workoutPaused) {
          this.timer += 1;
        }
      }, 1000);
    },
    getTimeLeft: function () {
      this.timeLeft = this.totalWorkOutDuration() - this.timer;
      if (this.timeLeft > 0) {
        return this.timeLeft;
      } else {
        this.player.stopVideo();
        return 0;
      }
    },

    initWorkout: function () {
      var workout = this.selectedWorkouts[this.currentVideoId];
      this.currentWorkoutTitle = workout.title;

      if (this.selectedWorkouts[this.currentVideoId + 1]) {
        this.nextWorkoutTitle =
          this.selectedWorkouts[this.currentVideoId + 1].title;
      } else {
        this.nextWorkoutTitle = "Couch!";
      }

      this.currentWorkoutDescription = workout.description;
      this.currentWorkoutDescriptionEasy = workout.description_version_easy;
      this.currentWorkoutDescriptionHard = workout.description_version_hard;
      this.videoId = workout.video_id;
      if (this.player) {
        this.player.playVideo();
      }
    },
  },
};
</script>


 <style>
.content {
  padding-top: 50px;
  color: #fff;
}

#kraftraum-exercise {
  margin: auto;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.25);
  z-index: 600;
}

.workout-name {
  float: left;
  margin-left: 20px;
}
.workout-next {
  float: right;
  padding: 15px;
  font-size: 50px;
  letter-spacing: 0.001rem;
  font-weight: bold;
}

.yt-overlay,
#playArea,
#playArea > .yt-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  font-size: 300px;
}

.close-kraftraum {
  float: left;
  color: #fff;
  margin-left: 20px;
}

.current-workout {
  font-family: "StayDreaming", "Barlow", sans-serif;
  font-size: 55px;
  font-weight: 400;
  line-height: 55px;
  vertical-align: middle;
  background: rgba(255, 255, 255, 0.3);
  padding: 20px 10px;
  border-radius: 10px;
  color: #000;
}

.cat-heading {
  font-family: "StayDreaming", "Barlow", sans-serif;
  font-size: 150px;
  font-weight: 400;
  line-height: 70px;
  vertical-align: middle;
}

.time-heading {
  font-family: "StayDreaming", "Barlow", sans-serif;
  font-size: 150px;
  font-weight: 400;
  line-height: 60px;
  vertical-align: middle;
  text-align: right;
}
x .cat-heading-medium {
  font-family: "StayDreaming", "Barlow", sans-serif;
  font-size: 60px;
  font-weight: 400;
  line-height: 60px;
  vertical-align: middle;
}

.cat-heading-smaller {
  font-family: "StayDreaming", "Barlow", sans-serif;
  font-size: 75px;
  font-weight: 400;
  line-height: 75px;
  vertical-align: middle;
}
.cat-heading-very-small {
  font-family: "StayDreaming", "Barlow", sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  vertical-align: middle;
}

.white-bar {
  width: 100%;
  height: 25px;
  background: #fff;
}

.white-bar-thinner {
  width: 100%;
  height: 5px;
  background: #fff;
}

.spacer-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.top-spacer-25 {
  margin-top: 25px;
}

.top-spacer-46 {
  margin-top: 46px;
}

.top-spacer-50 {
  margin-top: 50px;
}

.bottom-spacer-50 {
  margin-bottom: 50px;
}

.top-spacer-100 {
  margin-top: 100px;
}

.top-spacer-125 {
  margin-top: 125px;
}

.top-spacer-150 {
  margin-top: 150px;
}

.centered {
  text-align: center;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.hero-img {
  width: 100%;
}

#player_iframe {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: calc(100vw / 1.77777778);
}

@media screen and (max-width: 450px) {
  iframe {
    width: 100%;
    height: calc(100vw / 1.77777778);
  }
}

@media screen and (max-width: 768px) {
  iframe {
    width: 100%;
    height: calc(100vw / 1.77777778);
  }
}
</style>