<template>
  <div class="statistics-page">
    <h1>Ernährungsstatistiken</h1>
    
    <div class="date-picker">
      <label for="start-date">Startdatum:</label>
      <input type="date" id="start-date" v-model="startDate" @change="filterData">
      <label for="end-date">Enddatum:</label>
      <input type="date" id="end-date" v-model="endDate" @change="filterData">
    </div>
    
    <div v-if="dataLoaded && filteredEntries.length > 0" class="chart-container">
      <div v-for="(chart, index) in charts" :key="index" class="chart">
        <h2>{{ chart.title }}</h2>
        <component :is="chart.component" :entries="filteredEntries"/>
      </div>
    </div>
    <div v-else-if="dataLoaded && filteredEntries.length === 0" class="no-data">
      Keine Daten für den ausgewählten Zeitraum verfügbar.
    </div>
    <div v-else class="loading">
      Daten werden geladen...
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CalorieChart from './charts/CalorieChart.vue';
import MacronutrientChart from './charts/MacronutrientChart.vue';
import RatingDistributionChart from './charts/RatingDistributionChart.vue';
import WeeklyCalorieChart from './charts/WeeklyCalorieChart.vue';
import NutrientTrendChart from './charts/NutrientTrendChart.vue';
import TopDishesChart from './charts/TopDishesChart.vue';
import TopIngredientsChart from './charts/TopIngredientsChart.vue';

export default {
  name: 'StatisticsPage',
  components: {
    CalorieChart,
    MacronutrientChart,
    RatingDistributionChart,
    WeeklyCalorieChart,
    NutrientTrendChart,
    TopDishesChart,
    TopIngredientsChart,
  },
  data() {
    return {
      entries: [],
      filteredEntries: [],
      startDate: this.getDefaultStartDate(),
      endDate: new Date().toISOString().split('T')[0],
      dataLoaded: false,
      chartKey: 0,
      charts: [
        { title: 'Kalorienaufnahme', component: 'calorie-chart' },
        { title: 'Makronährstoffe Verteilung', component: 'macronutrient-chart' },
        { title: 'Top 10 Gerichte', component: 'top-dishes-chart' },
        { title: 'Top 10 Zutaten', component: 'top-ingredients-chart' },
        { title: 'Bewertungsverteilung', component: 'rating-distribution-chart' },
        { title: 'Wöchentliche Kalorienaufnahme', component: 'weekly-calorie-chart' },
        { title: 'Nährstofftrends', component: 'nutrient-trend-chart' },
      ],
    };
  },
  async created() {
    await this.fetchData();
    this.dataLoaded = true;
  },
  methods: {
    getDefaultStartDate() {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date.toISOString().split('T')[0];
    },
    async fetchData() {
      try {
        const response = await axios.get(this.$host + '/food/365d');
        this.entries = response.data;
        this.filterData();
      } catch (error) {
        console.error('Error fetching food entries:', error);
      }
    },
    filterData() {
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      end.setHours(23, 59, 59, 999); // Set to end of day
      this.filteredEntries = this.entries.filter(entry => {
        const entryDate = new Date(entry.createdAt);
        return entryDate >= start && entryDate <= end;
      });

      if (this.filteredEntries.length === 0) {
        console.log('No data available for the selected date range');
      }
    },
  },
};
</script>

<style scoped>
.statistics-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.date-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.date-picker label {
  margin-right: 0.5rem;
}

.date-picker input {
  margin-right: 1rem;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.chart-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 2rem;
}

.chart {
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.chart:hover {
  transform: translateY(-5px);
}

h2 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #444;
  text-align: center;
}

.loading {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
}

.no-data {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
}
</style>