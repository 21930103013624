<template>
  <div class="box">
    <img
      class="logo"
      style="box-shadow: none"
      v-bind:src="require('@/assets/images/PL-Club-Logo-w.png')"
    />
    <div class="text-heading">Workout</div>
  </div>
</template>

<script>
export default {
  name: "Start",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.box {
  background: rgb(60, 131, 185);
  background: linear-gradient(
    130deg,
    rgba(60, 131, 185, 1) 0%,
    rgba(78, 157, 164, 1) 53%
  );
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999999;
  color: #fff;
}

.logo {
  position: fixed;
  width: 150px;
  top: 5%;
  left: 50%;
  margin-left: -75px;
}

.text-heading {
  position: fixed;
  bottom: 100px;
  left: 20px;
  font-size: 20px;
}
</style>