<template>
  <div v-cloak @drop.prevent="addFile" @dragover.prevent @dragenter="dragEnter">
    <div class="row">
      <div class="submenu col-xl-2 col-lg-3 d-none d-lg-block">
        <div class="width-wrap sticky-top">
          <div class="row top-spacer-25">
            <div class="col-lg-12">
              <a href="/#/training/dashboard">
                <div class="menu-entry">Dashboard</div></a
              >
            </div>
            <div class="col-lg-12">
              <a href="/#/training/kalender">
                <div class="menu-entry">Kalender</div></a
              >
            </div>

            <div class="col-lg-12">
              <a href="/#/training/liste">
                <div class="menu-entry">Trainingspläne</div>
              </a>
            </div>

            <div class="col-lg-12">
              <a href="/#/training/wissen">
                <div class="menu-entry">Wissen</div>
              </a>
            </div>

            <div class="col-lg-12">
              <a href="/#/training/upload">
                <div class="menu-entry active_submenu">Upload</div></a
              >
            </div>

            <div class="col-lg-12">
              <a href="/#/training/vorlagen">
                <div class="menu-entry">Workout-Vorlagen</div></a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-xl-10">
        <div class="white_wrapper light-shadow">
          <div id="dropzone" v-if="showDropzone">
            <h3 style="text-align: center">.FIT File Upload</h3>
            <div
              v-if="filesUploaded + fileUploadErrors == totalFilesToUpload"
              style="padding: 15px; margin: 15px; border: 5px solid green"
            >
              <h2>
                Upload abgeschlossen!<br />
                Die Verarbeitung dauert ggf. noch einige Minuten.<br />
              </h2>
            </div>
            <div
              v-if="!(filesUploaded + fileUploadErrors == totalFilesToUpload)"
              style="padding: 15px; margin: 15px; border: 5px solid yellow"
            >
              <h2>Upload läuft, bitte warten...</h2>
            </div>
            <div v-if="totalFilesToUpload > 0">
              <h4 style="text-align: center">
                {{ filesUploaded }}/{{ totalFilesToUpload }} erfolgreich
              </h4>
              <div
                v-if="filesUploaded + fileUploadErrors < totalFilesToUpload"
                class="top-spacer-25 spinner-border text-primary"
                role="status"
              ></div>
              <br />
              <br />
              <span
                style="text-align: center"
                class="centered top-spacer-25"
                v-if="fileUploadErrors > 0"
              >
                {{ fileUploadErrors }} Datei<span v-if="fileUploadErrors > 1"
                  >en</span
                >
                konnten nicht ausgewertet werden. Bitte nur vollständige .FIT
                Files hochladen.<br /><br />
                Hinweis: Zwift legt oft kleine unnötige FIT-Files ab, die hier
                als nicht-erfolgreich angezeigt werden. Das ist aber kein
                Problem! Die Workouts müssen nicht nochmal hochgeladen werden!
              </span>
              <br />

              <div
                v-if="fileUploadErrors + filesUploaded == totalFilesToUpload"
                style="cursor: pointer"
                class="top-spacer-25 button"
                @click="dragExit"
              >
                Schließen
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div
                v-if="uploadFinished && uploadError == false"
                style="color: green"
              >
                <h5>
                  <span v-if="files.length == 1">Workout wurde</span
                  ><span v-if="files.length > 1">Workouts wurden</span>
                  erfolgreich hochgeladen!
                </h5>
              </div>

              <h3 class="top-spacer-25">.FIT Datei manuell hochladen</h3>
              <div class="top-spacer-25">
                Du kannst mehrere Dateien gleichzeitig auswählen
              </div>
              <input
                class="top-spacer-25"
                type="file"
                accept=".fit,.FIT"
                multiple
                @change="processFile($event)"
              />
              <div class="col-lg-12">
                <button @click="saveFile()" class="top-spacer-25">
                  Hochladen
                </button>
              </div>

              <hr />

              <h3 class="top-spacer-50">Automatischer Upload</h3>
              Verbinde dich mit deinem Garmin, Wahoo oder Polar Account, um
              deine Workouts automatisch zu synchronisieren. Öffne dafür dein
              Profil und wähle deine Verbindungen aus.

              <a href="/#/profil"
                ><button class="top-spacer-25">Zum Profil</button></a
              >
            </div>
            <div class="col-lg-6">
              <h3 class="top-spacer-25">Drag & Drop</h3>
              <p>
                Du kannst mehrere .FIT-Dateien am Computer per Drag & Drop
                direkt auf deinen Kalender ziehen
              </p>
              <img
                style="width: 100%"
                alt="Drag und Drop Beispiel"
                src="
                  https://assets.pushinglimits.club/dragdrop.gif
                "
              />
            </div>
          </div>

          <div class="top-spacer-50"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Upload",
  components: {},
  props: {
    msg: String,
  },
  data: function () {
    return {
      // FIT UPLOAD

      showDropzone: false,
      totalFilesToUpload: 0,
      filesUploaded: 0,
      fileUploadErrors: 0,
      uploadFinishedLoader: false,
      uploadFinished: false,

      files: undefined,
      key: 0,
    };
  },
  methods: {
    dragEnter() {
      this.showDropzone = true;
    },
    dragExit() {
      this.showDropzone = false;
      this.filesUploaded = 0;
      this.fileUploadErrors = 0;
      this.totalFilesToUpload = 0;
    },
    /////// FILE UPLOAD
    processFile(event) {
      this.files = event.target.files;
    },
    saveFile: function () {
      this.dragEnter();
      this.uploadFinishedLoader = false;
      this.uploadFinished = false;
      this.uploadError = false;

      if (this.files.length > 0) {
        let droppedFiles = this.files;
        this.showDropzone = true;

        if (
          this.totalFilesToUpload -
            this.filesUploaded -
            this.fileUploadErrors ==
          0
        ) {
          if (!droppedFiles) return;

          this.filesUploaded = 0;
          this.fileUploadErrors = 0;

          this.totalFilesToUpload = droppedFiles.length;

          [...droppedFiles].forEach((f) => {
            this.uploadFinishedLoader = false;
            const formData = new FormData();
            this.uploadFinished = false;
            this.uploadError = false;

            formData.append("files[]", f);

            const _self = this;
            axios
              .post(this.$host + "/fit/upload", formData)
              .then(function () {
                _self.uploadFinished = true;
                _self.filesUploaded += 1;
              })
              .catch(function (error) {
                console.log(error);
                _self.uploadError = true;
                _self.fileUploadErrors += 1;
              })
              .finally(function () {
                _self.uploadFinishedLoader = true;
                _self.updateData();
                if (_self.totalFilesToUpload - _self.filesUploaded == 0) {
                  _self.dragExit();
                }
              });
          });
        } else {
          // Upload läuft noch...
        }
      }
    },
  },

  destroyed() {
    //window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
#dropzone {
  padding: 30px;
  text-align: center;
  position: fixed;
  margin: auto;
  left: 20%;
  border-radius: 5px;
  right: 20%;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  border: 3px solid #000;
  z-index: 999999999999;
}
</style>