<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  props: ["fromDay", "until", "done", "wo", "reducex"],

  data: function () {
    return {
      workouts: [],
      chart: undefined,
      loadedWeeks: this.weeks,
      loadOnlydone: this.done,
      reduceToSimilar: this.reducex || false,
      from: this.fromDay,
      workoutsFiltered: [],

      chartdata: {
        labels: [],
        datasets: [
          {
            regressions: {
              extendPredictions: true,
              type: "linear",
              line: { color: "black", width: 2 },
              sections: [{ startIndex: 0, endIndex: 99999 }],
            },
            data: [],
            pointBackgroundColor: [],
            pointBorderColor: [],
            pointRadius: [],
            trendlineLinear: {
              style: "rgba(0,0,0, .8)",
              lineStyle: "dotted",
              width: 2,
            },
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var datasetLabel =
                  Math.round(context.parsed.y * 100) / 100 +
                  " Geschwindigkeit/Herzfrequenz*100";

                context.xLabel + "bpm";
                return datasetLabel;
              },
            },
          },
        },

        scales: {
          yAxes: {
            title: {
              display: true,
              text: "Geschwindigkeit zu HF * 100",
            },
          },
          xAxes: {
            title: {
              display: true,
              text: "Einheit",
            },
          },
        },

        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        responsive: true,

        onClick: this.clickedWorkout,
      },
    };
  },

  async mounted() {
    let ctx = this.$refs.graph.getContext("2d");

    this.workouts = this.done;

    this.computeChartData();
    const config = {
      type: "scatter",
      data: this.chartdata,
      options: this.options,
    };
    this.$emit("chartRendered");

    new Chart(ctx, config);
  },

  watch: {
    reducex: function (newVal) {
      this.reduceToSimilar = newVal;

      this.computeChartData();
    },
  },

  methods: {
    clickedWorkout: function (click) {
      const points = click.chart.getElementsAtEventForMode(
        click,
        "nearest",
        { intersect: true },
        true
      );

      if (points.length) {
        const firstPoint = points[0];
        this.$router.push(
          "/auswertung/workout/details/" +
            this.workoutsFiltered[firstPoint.index].workout._id
        );
      }
    },
    getColorForPercentage: function (pct) {
      var a = (94 - pct) / 100,
        b = 250 * a;

      return "hsl(" + b + ", 100%, 40%)";
    },
    computeChartData: function () {
      const _self = this;

      _self.chartdata.datasets[0].pointRadius = [];
      _self.chartdata.datasets[0].data = [];
      _self.chartdata.datasets[0].pointBackgroundColor = [];
      _self.chartdata.datasets[0].pointBorderColor = [];

      this.workoutsFiltered = this.workouts.filter((workout) => {
        if (workout && workout.workout) {
          return (
            workout.workout.sport == "Laufen" &&
            workout.avg_heart_rate &&
            workout.avg_speed &&
            !workout.exclude_in_analysis
          );
        }
      });

      if (
        this.reduceToSimilar &&
        this.wo &&
        this.wo.avg_heart_rate &&
        this.wo.avg_speed
      ) {
        this.workoutsFiltered = this.workoutsFiltered.filter((workout) => {
          return (
            workout.workout.time_lit >= this.wo.time_lit - 12 &&
            workout.workout.time_lit <= this.wo.time_lit + 12 &&
            workout.workout.time_mit >= this.wo.time_mit - 7 &&
            workout.workout.time_mit <= this.wo.time_mit + 7 &&
            workout.workout.time_hit >= this.wo.time_hit - 3 &&
            workout.workout.time_hit <= this.wo.time_hit + 3
          );
          // TODO
          // nach zeit ?
        });
      }

      const minLength = Math.min.apply(
        Math,
        this.workoutsFiltered.map(function (w) {
          return w.workout.durationIs;
        })
      );

      const maxWoLength = Math.max.apply(
        Math,
        this.workoutsFiltered.map(function (w) {
          return w.workout.durationIs;
        })
      );

      let counter = 1;
      let newWorkoutsFiltered = [];
      const totalDays = this.until.diff(this.from, "days");
      let totalCounter = 0;

      while (this.from.isSameOrBefore(this.until, "day")) {
        let tomorrow = this.$moment(this.from);
        tomorrow.add(1, "d");

        let workoutsOfDate = this.workoutsFiltered.filter((workout) => {
          return (
            this.$moment(workout.date).isSameOrAfter(this.from) &&
            this.$moment(workout.date).isBefore(this.$moment(tomorrow))
          );
        });

        workoutsOfDate.forEach((workout) => {
          this.chartdata.datasets[0].data.push({
            x: counter,
            y: (workout.avg_speed / workout.avg_heart_rate) * 100, // ( - 50 bereinigt)
          });
          const radius =
            2 +
            ((workout.workout.durationIs - minLength) /
              (maxWoLength - minLength)) *
              4;

          _self.chartdata.datasets[0].pointRadius.push(radius);
          _self.chartdata.datasets[0].pointBackgroundColor.push(
            _self.getColorForPercentage((totalCounter / totalDays) * 100)
          );
          _self.chartdata.datasets[0].pointBorderColor.push(
            _self.getColorForPercentage((totalCounter / totalDays) * 100)
          );

          newWorkoutsFiltered.push(workout);
          counter++;
        });
        this.from = this.from.add(1, "d");
        totalCounter++;
      }
      if (this.chartdata.datasets[0] && this.chartdata.datasets[0].data[0]) {
        _self.chartdata.datasets[0].regressions.sections[0].startIndex =
          this.chartdata.datasets[0].data[0].x;
      }
      this.workoutsFiltered = newWorkoutsFiltered;

      this.from = this.fromDay;

      //this.addPlugin(Regressions.ChartRegressions);

      //this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>
