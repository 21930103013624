<template>
  <div v-show="workouts.length >= 2">
    <div class="white_wrapper_tighter top-spacer-25">
      <h4>{{ $t(`Schwellenschätzung Laufen`) }}</h4>

      <div style="height: 200px">
        <canvas ref="stressGraph" id="stressGraph" width="100%"></canvas>
      </div>
      <div class="top-spacer-5">
        <small>{{
          $t(
            `Die Linie stellt eine Schätzung für jede Einheit dar. Die Genauigkeit hängt von den verfügbaren Daten ab. Je abwechslungsreicher dein Training, desto genauer wird die Schätzung.`
          )
        }}</small>
      </div>
    </div>
    <div class="top-spacer-25 white_wrapper_tighter">
      <div>
        <h4>{{ $t(`Distanz-Leistungsschätzungen`) }}</h4>
      </div>

      <div class="row">
        <div class="col-4">
          <strong>{{ $t(`Distanz`) }}</strong>
        </div>

        <div class="col-4">
          <strong>{{ $t(`Schätzung`) }}</strong>
        </div>

        <div class="col-4">
          <strong>{{ $t(`Profil`) }}</strong>
        </div>
      </div>
      <div class="row">
        <div class="col-4">5km</div>

        <div class="col-4">
          {{
            $moment()
              .startOf("day")
              .seconds(3600 * Math.pow(5 / schwelleKm, 1.06))
              .format("H:mm:ss")
          }}
        </div>
        <div class="col-4">
          {{
            $moment()
              .startOf("day")
              .seconds(3600 * Math.pow(5 / schwelleKmProfil, 1.06))
              .format("H:mm:ss")
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">10km</div>
        <div class="col-4">
          {{
            $moment()
              .startOf("day")
              .seconds(3600 * Math.pow(10 / schwelleKm, 1.06))
              .format("H:mm:ss")
          }}
        </div>
        <div class="col-4">
          {{
            $moment()
              .startOf("day")
              .seconds(3600 * Math.pow(10 / schwelleKmProfil, 1.06))
              .format("H:mm:ss")
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{ $t(`Halbmarathon`) }}</div>
        <div class="col-4">
          {{ formatDuration(3600 * Math.pow(21.0975 / schwelleKm, 1.06)) }}
        </div>
        <div class="col-4">
          {{ formatDuration(3600 * Math.pow(21.0975 / schwelleKmProfil, 1.06)) }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{ $t(`Marathon`) }}</div>
        <div class="col-4">
          {{ formatDuration(3600 * Math.pow(42.195 / schwelleKm, 1.06)) }}
        </div>
        <div class="col-4">
          {{ formatDuration(3600 * Math.pow(42.195 / schwelleKmProfil, 1.06)) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";
import axios from "axios";

export default {
  props: ["done"],

  data: function () {
    return {
      workouts: [],
      runThreshold: 0,
      schwelleKm: 0,
      schwelleKmProfil: 0,

      ctx: undefined,

      chartdata: {
        labels: [],
        datasets: [
          {
            borderColor: "#000",
            backgroundColor: "#000",

            data: [],
            yAxisID: "y",
            borderWidth: 3,
            order: 10,
            tension: 0.4,
            //fill: true,
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return moment.utc(parseInt(context.formattedValue) * 1000).format("mm:ss") + "m/km";
              },
            },
          },
        },

        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            grid: {
              display: true,
            },
            reverse: true,

            stacked: false,
            id: "stack",
            position: "right",
            display: true,

            ticks: {
              beginAtZero: true,
              maxTicksLimit: 6,
              callback: function (label) {
                return moment.utc(label * 1000).format("mm:ss") + "m/km";
              },
            },
          },
          xAxes: {
            grid: {
              display: true,
            },
            id: "x-axis-1",
            gridLines: {
              display: false,
            },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  async mounted() {
    let ctx = this.$refs.stressGraph.getContext("2d");
    this.ctx = ctx;

    this.workouts = this.done;

    this.computeChartData();

    const config = {
      type: "line",
      data: this.chartdata,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.y;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.yAxes;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
      ],
    };

    new Chart(this.ctx, config);

    this.$emit("chartRendered");
  },

  methods: {
    formatDate: function (value) {
      return value.format("DD.MM");
    },

    formatDuration: function (seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    },

    computeChartData: async function () {
      this.workouts = this.workouts.filter((wo) => {
        return wo.sport == "Laufen" && (wo.best_prediction_run_enhanced > 0 || wo.best_prediction_run > 0);
      });
      this.workouts = this.workouts.sort(function (a, b) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      for (const workout of this.workouts) {
        this.chartdata.labels.push(this.formatDate(moment(workout.date)));

        if (!workout.best_prediction_run_enhanced) {
          workout.best_prediction_run_enhanced = workout.best_prediction_run;
        }

        if (workout.best_prediction_run_enhanced) {
          this.chartdata.datasets[0].data.push(3600 / workout.best_prediction_run_enhanced);
        }
      }

      // neue Schätzung
      if (
        this.chartdata.datasets[0] &&
        this.chartdata.datasets[0].data &&
        this.chartdata.datasets[0].data[this.chartdata.datasets[0].data.length - 1]
      ) {
        this.schwelleKm = 3600 / this.chartdata.datasets[0].data[this.chartdata.datasets[0].data.length - 1];
      }

      const thresholds = await (await axios.get(this.$host + "/user/thresholds")).data;

      this.runThreshold = thresholds[thresholds.length - 1].threshold_run;

      this.schwelleKmProfil = 3600 / this.runThreshold;

      return this.chartdata;
    },
  },
};
</script>
