<template>
  <div class="impressum">
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-6 white_wrapper">
        <h3 class="top-spacer-50">{{ $t("Abo kündigen") }}</h3>

        Du kannst dein Abo jederzeit auf deinem Smartphone über den normalen Weg
        im App Store bzw. Play Store kündigen. Wenn du die App benutzt,
        erscheint hier ein Button um zur Verwaltung deiner Abos zu kommen. Dort
        kannst du das Abo kündigen.<br /><br />
        <div
          v-if="
            $parent.appstoreState &&
            $parent.appstoreState.product1 &&
            $parent.appstoreState.product2 &&
            Object.keys($parent.appstoreState.product1).length > 0 &&
            Object.keys($parent.appstoreState.product2).length > 0
          "
        >
          <button @click="$parent.manageSubscription()">
            {{ $t("Zur Abo-Verwaltung") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Start",
  props: {
    msg: String,
  },
};
</script>

<style>
.impressum {
  font-size: 16px;
}
</style>
