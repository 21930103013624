<template>
  <div>
    <a class="back-button" href="/#/training/liste">➜</a>
    <div class="row">
      <div class="submenu col-xl-2 d-none d-xl-block">
        <div class="width-wrap sticky-top">
          <div class="row">
            <div class="col-lg-12">
              <a href="/#/training/dashboard"> <div class="menu-entry">Dashboard</div></a>
            </div>
            <div class="col-lg-12">
              <a href="/#/training/kalender"> <div class="menu-entry">Kalender</div></a>
            </div>

            <div class="col-lg-12">
              <a href="/#/training/liste">
                <div class="menu-entry active_submenu">Trainingspläne</div>
              </a>
            </div>

            <div class="col-lg-12">
              <a href="/#/training/FragDenCoach">
                <div class="menu-entry">{{ $t("Wissen") }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-10 col-12" id="uebersicht">
        <div class="col-12 top-spacer-10" v-if="warnPremiumExpiration" style="text-align: center; color: #fff">
          <div
            class="col-12"
            style="
              background-size: 200% auto;
              padding: 20px;
              background: #000;
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
            "
          >
            <br />

            <strong>Hinweis:</strong> Dein Zugang ist abgelaufen. Um dauerhaft alle Funktionen nutzen zu können, kannst
            du über die App ein Abo abschließen.

            <h2>Die Trainingspläne stehen nur unseren zahlenden Club Mitgliedern zur Verfügung.</h2>
          </div>
        </div>

        <div v-if="!warnPremiumExpiration">
          <!--
          <div>
            <!- :style="{
                height: '150px',
                background:
                  'url(' + require('@/assets/images/plan.webp') + ') 20% 50%',
              }"->
            <div
              v-if="selectedPlan.sport == 'Radfahren'"
              :style="{
                height: '280px',
                background:
                  'url(https://assets.pushinglimits.club/bike-wide.webp) 15% 30%',
              }"
            ></div>
            <div
              v-if="selectedPlan.sport == 'Schwimmen'"
              :style="{
                height: '280px',
                background:
                  'url(https://assets.pushinglimits.club/swimdalle.webp) 90% 10%',
              }"
            ></div>

            <div
              v-if="selectedPlan.sport == 'Triathlon'"
              :style="{
                height: '280px',
                background:
                  'url(https://assets.pushinglimits.club/tridalle.webp) 90% 40%',
              }"
            ></div>

            <div
              v-if="selectedPlan.sport == 'Laufen'"
              :style="{
                height: '280px',
                background:
                  'url(https://assets.pushinglimits.club/tridalle.webp) 50% 60%',
              }"
            ></div>
          </div>-->

          <div class="row">
            <div class="col-lg-12">
              <div
                class="white_wrapper"
                style="border-top-left-radius: 0; border-top-right-radius: 0; padding-top: 5px"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <h3>
                      {{ selectedPlan.title }}
                    </h3>
                  </div>
                </div>
                <div class="row top-spacer-25">
                  <div class="col-lg-3">
                    <a href="/#/training/liste" class="pull-left"
                      ><button style="margin-left: 0">❮ Zu den Plänen</button></a
                    >
                  </div>

                  <div class="top-spacer-10 d-block d-lg-none">&nbsp;</div>

                  <div class="col-lg-3" style="margin-bottom: 10px">
                    <select style="width: 100%" v-model="applyPlanMode" @change="updateRelatedPlanDates">
                      <option selected value="begin">Beginn am</option>
                      <option value="end">Ende am</option>
                    </select>
                  </div>
                  <div class="col-lg-3" style="margin-bottom: 10px">
                    <input style="width: 100%" type="date" v-model="applyPlanDate" @input="updateRelatedPlanDates" />
                  </div>
                  <div class="col-lg-3" style="margin-bottom: 10px">
                    <button class="closer" @click="insertPlan(selectedPlan._id)" style="width: 100%">
                      Plan einfügen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="white_wrapper_tighter top-spacer-25" v-if="getRelatedPlans(selectedPlan).length > 1">
            <h5>Zusammenhängende Pläne:</h5>
            <div class="plan-chain">
              <div
                v-for="(relatedPlan, planIndex) in getRelatedPlans(selectedPlan)"
                :key="relatedPlan._id"
                class="chain-plan"
                :class="[
                  getPlanClass(planIndex, getRelatedPlans(selectedPlan).length),
                  { 'selected-plan': relatedPlan._id === selectedPlan._id },
                ]"
              >
                <h6>{{ getPlanPosition(planIndex, getRelatedPlans(selectedPlan).length) }}</h6>
                <p>{{ relatedPlan.title }}</p>
                <p>
                  {{ calculatedPlanDates[relatedPlan._id] || calculatePlanDates(relatedPlan._id) }}
                </p>
                <div v-if="isPlanDateInPast(relatedPlan._id)" class="past-plan-note">
                  Dieser Plan liegt in der Vergangenheit
                </div>
                <button
                  v-else
                  @click="insertPlan(relatedPlan._id)"
                  :disabled="isInserting === relatedPlan._id || isPlanActive(relatedPlan._id)"
                  :class="{ 'active-plan': isPlanActive(relatedPlan._id) }"
                  class="plan-action-button"
                >
                  <span v-if="isInserting !== relatedPlan._id && !isPlanActive(relatedPlan._id)">Einfügen</span>
                  <span v-else-if="isPlanActive(relatedPlan._id)">Aktiv</span>
                  <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>

                <div v-if="getOverlappingPlans(relatedPlan._id).length > 0" class="overlapping-plans">
                  <p><strong>Überlappende Pläne:</strong></p>
                  <ul>
                    <li v-for="overlapPlan in getOverlappingPlans(relatedPlan._id)" :key="overlapPlan._id">
                      {{ overlapPlan.trainingsplan.title }} ({{ $moment(overlapPlan.start).format("DD.MM.YYYY") }} -
                      {{ $moment(overlapPlan.end).format("DD.MM.YYYY") }})
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="white_wrapper_tighter top-spacer-25">
            <div
              class="spinner-border text-primary top-spacer-50"
              style="margin-bottom: 50px"
              role="status"
              v-if="!selectedPlan"
            ></div>
            <div class="row" style="white-space: pre-line; text-align: left">
              <div :class="selectedPlan.description && selectedPlan.description.length > 1 ? 'col-lg-6' : 'd-none'">
                <h2 style="margin-top: 0">Über diesen Plan</h2>
                {{ selectedPlan.description }}
              </div>
              <!-- SCYENCE-->
              <div
                :class="selectedPlan.description && selectedPlan.description.length > 1 ? 'col-lg-6' : 'col-lg-12 '"
                v-if="selectedPlan && selectedPlan.title && selectedPlan.title.toLowerCase().includes('scyence')"
              >
                <div>
                  <div class="row">
                    <div class="col-lg-12 d-block d-md-none top-spacer-25">&nbsp;</div>
                    <div class="col-12 col-md-4 centered top-spacer-15">
                      <img style="box-shadow: none; width: 100%; max-width: 185px" src="@/assets/images/lukas.jpg" />
                      <div class="col-lg-12 d-block d-md-none top-spacer-25">&nbsp;</div>
                    </div>
                    <div class="col-12 col-md-8 top-spacer-15">
                      <h2 style="margin-top: 0">Lukas Löer von Scyence.cc</h2>
                      Lukas ist der schlaue Kopf der Truppe und für jeden Spaß und die verrücktesten Aktionen zu haben,
                      wie beispielsweise damals, als er in einer 17-stündigen Tour von Stuttgart bis auf den Stelvio
                      Pass mit 390 km Distanz und über 7000 Höhenmetern mit zusätzlichen Zwischensprints für die Kamera
                      fuhr! Nach solchen Aktionen gönnt sich Lukas aber natürlich auch genug Zeit, um seinem Körper die
                      benötigte Ruhe zu genehmigen. Mit einem Recovery Drink in der Hand und dem Laptop auf dem Schoß
                      findet man ihn auf der Couch, wo er sich genüsslich die neusten sportwissenschaftlichen Studien im
                      Bereich Radsport durchliest, analysiert und sich um seinen Geist kümmert. Easy verpackt könnt Ihr
                      Euch im Anschluss die aktuellen Erkenntnisse in Videos anschauen, in unterschiedlichen
                      Blogbeiträgen durchlesen oder ganz bequem einen der SCYENCE Podcasts anhören.
                    </div>
                  </div>
                </div>
              </div>
              <!-- LAUSO USINGER -->
              <div
                :class="selectedPlan.description && selectedPlan.description.length > 1 ? 'col-lg-6' : 'col-lg-12 '"
                v-if="selectedPlan && selectedPlan.title && selectedPlan.title.toLowerCase().includes('usinger')"
              >
                <div>
                  <div class="row">
                    <div class="col-lg-12 d-block d-md-none top-spacer-25">&nbsp;</div>
                    <div class="col-12 col-md-4 centered top-spacer-15">
                      <img style="box-shadow: none; width: 100%; max-width: 185px" src="@/assets/images/lauso.jpg" />
                      <div class="col-lg-12 d-block d-md-none top-spacer-25">&nbsp;</div>
                    </div>
                    <div class="col-12 col-md-8 top-spacer-15">
                      <h2 style="margin-top: 0">Über Laura-Sophie</h2>
                      Laura war selbst 20 Jahre Leistungssportlerin im Turnen und Triathlon und weiß was es heißt, hart
                      für Ziele zu arbeiten – aber auch, dass dieser Prozess ohne strukturierte Planung nicht möglich
                      ist. Als Sportwissenschaftlerin und Sportmanagerin arbeitet sie seit 8 Jahren im Ausdauercoaching
                      und in der Leistungsdiagnostik. Laura hat u.a. Jan Stratmann, Anna-Lena Best-Pohl oder Franz
                      Löschke zu Spitzenleistungen gecoacht, betreut aber neben den Profis auch zahlreiche
                      Amateurtriathlet:innen und Läufer:innen. Während ihrer Masterthesis hat sie zu zyklusbasiertem
                      Training im Ausdauersport geforscht und verbindet ihre gewonnen Erkenntnisse mit den Erfahrungen,
                      die sie in der Praxis mit ihren Athletinnen sammelt.
                      <br />
                      <br />
                      Homepage:
                      <a target="_blank" href="Lu-ausdauercoaching.de">Lu-ausdauercoaching.de</a>
                    </div>
                  </div>
                </div>
              </div>
              <!--  goerke -->
              <div
                :class="selectedPlan.description && selectedPlan.description.length > 1 ? 'col-lg-6' : 'col-lg-12 '"
                v-if="selectedPlan && selectedPlan.title && selectedPlan.title.toLowerCase().includes('goerke')"
              >
                <div>
                  <div class="row">
                    <div class="col-lg-12 d-block d-md-none top-spacer-25">&nbsp;</div>
                    <div class="col-12 col-md-4 centered top-spacer-15">
                      <img style="box-shadow: none; width: 100%; max-width: 185px" src="@/assets/images/ngoerke.webp" />
                      <div class="col-lg-12 d-block d-md-none top-spacer-25">&nbsp;</div>
                    </div>
                    <div class="col-12 col-md-8 top-spacer-15">
                      <h2 style="margin-top: 0">Nils Goerke von NG TRNG</h2>
                      Nils wird den meisten von Euch als Coach von Nick im Rahmen des Pro:jects ein Begriff sein oder
                      aber als Coach der schnellen Jungs und Mädels der Fischmarktcrew und diversen anderen Top
                      Athleten. <br /><br />Die Älteren werden wissen, dass Nils selber jahrelang als Profiathlet recht
                      erfolgreich war. 8 Starts auf Hawaii (der 22.Pl 2003 war die beste Platzierung), Vize
                      Europameister Triathlon Langdistanz, Top 3 IM/IM 70.3 Rennen, sowie Siege im Allgäu und beim Bonn
                      Triathlon sind einige Erfolge seiner Palmares. Erste Erfahrungen im Ausdauersport sammelte Nils
                      bereits als 15-jähriger Lizenzradfahrer, bevor er sich dann dem Triathlon widmete.
                      <br /><br />Während seiner Karriere als Profi Triathlet studierte er Sportwissenschaften an der
                      CAU in Kiel und machte sich dann 2013 als Coach selbstständig. „Training im Ausdauersport
                      funktioniert dann am besten, wann man kontinuierlich an seiner Leistung arbeitet. Über einen
                      langen Zeitraum viel zu trainieren, gesund zu bleiben, die Motivation aufrecht zu halten, Spaß zu
                      haben und eine große Basis zu schaffen - das sind wichtige Schlüssel zum Erfolg.“
                    </div>
                  </div>
                </div>
              </div>
              <!--  SCHWIMMCOACH -->
              <div
                :class="selectedPlan.description && selectedPlan.description.length > 1 ? 'col-lg-6' : 'col-lg-12 '"
                v-if="selectedPlan && selectedPlan.title && selectedPlan.title.toLowerCase().includes('schwimmcoach')"
              >
                <div>
                  <div class="row">
                    <div class="col-lg-12 d-block d-md-none top-spacer-25">&nbsp;</div>
                    <div class="col-12 col-md-4 centered top-spacer-15">
                      <img style="box-shadow: none; width: 100%; max-width: 185px" src="@/assets/images/johann.webp" />
                      <div class="col-lg-12 d-block d-md-none top-spacer-25">&nbsp;</div>
                    </div>
                    <div class="col-12 col-md-8 top-spacer-15">
                      <h2 style="margin-top: 0">Johann Ackermann (deinschwimmcoach.de)</h2>
                      Das Schwimmen widerspricht unserer aufrechten Fortbewegung an Land und verursacht nicht selten
                      Stress. Erfolgreiches Schwimmtraining beginnt deshalb bei "Deinschwimmcoach" mit dem Wohlfühlen im
                      Wasser und dem überwinden von Reflexen. Das Konzept der Wassergewöhung und Wasserbewältigung wurde
                      bereits an vielen hundert Schwimmern erfolgreich angewendet. Wichtig ist uns dabei die spezifisch
                      individuelle Herangehensweise zur Verbesserung deiner Schwimmtechnik. Durch seine langjährige
                      Erfahrung als einer der besten Schwimmer im Triathlon, sowie seiner 15 jährigen Expertise kann
                      Johann auch dir weiterhelfen.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="white_wrapper_tighter top-spacer-25" v-if="workouts && workouts.length > 0">
            <calendar-duration-chart
              style="height: 150px"
              v-bind:done="statisticWorkouts"
              :key="thresholdKey + 'chartF'"
              v-bind:from="$moment().startOf('day')"
              v-bind:until="$moment().add(trainingplanDays, 'd').endOf('day')"
            />
          </div>
          <div class="white_wrapper_tighter top-spacer-25" v-if="workouts && workouts.length > 0">
            <calendar-intensity-chart
              style="height: 150px"
              v-bind:done="statisticWorkouts"
              :key="thresholdKey + 'chartF'"
              v-bind:from="$moment().startOf('day')"
              v-bind:until="$moment().add(trainingplanDays, 'd').endOf('day')"
            />
          </div>

          <div class="white_wrapper_tighter top-spacer-25" v-if="workouts && workouts.length > 0">
            <calendar-pss-chart
              style="height: 150px"
              v-bind:done="statisticWorkouts"
              :key="thresholdKey + 'chartF'"
              v-bind:from="$moment().startOf('day')"
              v-bind:until="$moment().add(trainingplanDays, 'd').endOf('day')"
            />
          </div>

          <div class="white_wrapper_tighter top-spacer-25" v-if="workouts && workouts.length > 0">
            <calendar-stress-chart-form-ramp
              style="height: 200px"
              v-bind:done="workoutsAndStatisticWorkouts"
              v-bind:planWorkouts="statisticWorkouts"
              :key="thresholdKey + 'chartG'"
              v-bind:fromDay="$moment().add(-42, 'd').startOf('day')"
              v-bind:until="$moment().add(trainingplanDays, 'd').endOf('day')"
            />
            <div class="top-spacer-25">Entwicklung deiner Belastung, wenn der Plan heute hinzugefügt wird</div>
          </div>

          <div class="top-spacer-25 d-block d-md-none"></div>

          <div v-for="week in loadedWeeks" :key="week">
            <div
              class="top-spacer-25 week"
              v-if="
                getWeeklyShouldValues(week).swim +
                  getWeeklyShouldValues(week).bike +
                  getWeeklyShouldValues(week).run +
                  getWeeklyShouldValues(week).strength >
                0
              "
            >
              <div class="col-lg-12" :key="week + '1'">
                <div class="row">
                  <div
                    class="col-xs-7ths col-md-7ths cal-col"
                    v-for="(n, index) in 7"
                    :key="index"
                    v-bind:id="'week-' + week + '-' + index"
                  >
                    <div class="spinner-border text-primary" role="status" v-if="!loaded"></div>

                    <div class="d-none d-md-block heading">Tag {{ week * 7 + index + 1 }}</div>
                    <br />
                    <div class="row">
                      <div class="col-3 d-block d-md-none">
                        <div class="heading" style="text-align: right">Tag {{ week * 7 + index + 1 }}</div>
                      </div>
                      <div class="col-md-12 col-9">
                        <div v-if="getWorkoutsOfDate(week, index + 1).workouts.length == 0">
                          <div>Kein Training /<br />Ruhetag</div>
                        </div>
                        <workout-entry
                          v-bind:workoutsOfDay="getWorkoutsOfDate(week, index + 1)"
                          :key="thresholdKey"
                          v-bind:thresholds="thresholds"
                          v-if="thresholds"
                          v-bind:wday="getDayOfWeek(week, index + 1)"
                        >
                        </workout-entry>
                      </div>
                      <div class="col-12 d-block d-md-none top-spacer-25"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 top-spacer-25" style="padding-top: 15px; border-top: 2px solid #eee">
                <div class="row">
                  <div class="d-none d-lg-block col-lg-1 col-md-2 col-4">
                    <strong></strong><br />

                    Soll
                  </div>
                  <div class="col-lg-1 col-md-2 col-4">
                    <strong>Zeit</strong><br />

                    {{
                      formattedTimeSlash(
                        getWeeklyShouldValues(week).swim +
                          getWeeklyShouldValues(week).bike +
                          getWeeklyShouldValues(week).run +
                          getWeeklyShouldValues(week).strength
                      )
                    }}
                  </div>

                  <div class="col-lg-1 col-md-2 col-4">
                    <strong>Schwimmen</strong><br />
                    {{ formattedTimeSlash(getWeeklyShouldValues(week).swim) }}
                  </div>
                  <div class="col-lg-1 col-md-2 col-4">
                    <strong>Rad</strong><br />
                    {{ formattedTimeSlash(getWeeklyShouldValues(week).bike) }}
                  </div>
                  <div class="col-12 d-block d-md-none"><br /></div>
                  <div class="col-lg-1 col-md-2 col-4">
                    <strong>Laufen</strong><br />
                    {{ formattedTimeSlash(getWeeklyShouldValues(week).run) }}
                  </div>
                  <div class="col-lg-1 col-md-2 col-4">
                    <strong>Athletik</strong><br />
                    {{ formattedTimeSlash(getWeeklyShouldValues(week).strength) }}
                  </div>

                  <div class="col-12 d-block d-md-none"><br /></div>

                  <div
                    class="col-lg-2 col-md-2 col-12"
                    v-if="
                      getWeeklyIntensityValues(week).time_hit +
                        getWeeklyIntensityValues(week).time_mit +
                        getWeeklyIntensityValues(week).time_lit >
                      0
                    "
                  >
                    <strong>Intensitätsverteilung</strong>
                    <br />
                    <div class="progress" style="margin-top: 6px">
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        v-bind:style="
                          'width:' +
                          timePercentage(
                            getWeeklyIntensityValues(week).time_lit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          ) +
                          '%'
                        "
                        v-bind:aria-valuenow="
                          timePercentage(
                            getWeeklyIntensityValues(week).time_lit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          )
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-info"
                        role="progressbar"
                        v-bind:style="
                          'background-color: rgb(232, 126, 4)!important;' +
                          'width:' +
                          timePercentage(
                            getWeeklyIntensityValues(week).time_mit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          ) +
                          '%'
                        "
                        v-bind:aria-valuenow="
                          timePercentage(
                            getWeeklyIntensityValues(week).time_mit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          )
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-danger"
                        role="progressbar"
                        v-bind:style="
                          'width:' +
                          timePercentage(
                            getWeeklyIntensityValues(week).time_hit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          ) +
                          '%'
                        "
                        v-bind:aria-valuenow="
                          timePercentage(
                            getWeeklyIntensityValues(week).time_hit,
                            getWeeklyIntensityValues(week).time_hit +
                              getWeeklyIntensityValues(week).time_mit +
                              getWeeklyIntensityValues(week).time_lit
                          )
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="white_wrapper">
            <div class="row top-spacer-25">
              <div class="col-lg-3">
                <a href="/#/training/liste" class="pull-left"
                  ><button style="margin-left: 0">❮ Zu den Plänen</button></a
                >
              </div>
              <div class="top-spacer-10 d-block d-lg-none">&nbsp;</div>

              <div class="col-lg-3" style="margin-bottom: 10px">
                <select style="width: 100%" v-model="applyPlanMode" @change="updateRelatedPlanDates">
                  <option selected value="begin">Beginn am</option>
                  <option value="end">Ende am</option>
                </select>
              </div>
              <div class="col-lg-3" style="margin-bottom: 10px">
                <input
                  style="width: 100%"
                  type="date"
                  value="TT.MM.JJJJ"
                  placeholder="TT.MM.JJJJ"
                  v-model="applyPlanDate"
                  @input="updateRelatedPlanDates"
                />
              </div>
              <div class="col-lg-3" style="margin-bottom: 10px">
                <button href="button-b" @click="insertPlan(selectedPlan._id)" class="closer" style="width: 100%">
                  Plan einfügen
                </button>
              </div>
            </div>
          </div>
          <div class="top-spacer-50"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import WorkoutEntry from "@/components/calendar/WorkoutEntryNotDraggable";
import calendar_utils from "@/js/calendar_utils";
import CalendarPssChart from "@/components/chart/CalendarDurationChart.vue";
import CalendarDurationChart from "@/components/chart/CalendarPssChart.vue";
import CalendarIntensityChart from "@/components/chart/CalendarIntensityChart.vue";
import CalendarStressChartFormRamp from "@/components/chart/CalendarStressChartFormRamp.vue";

export default {
  metaInfo() {
    return {
      title: (this.selectedPlan && this.selectedPlan.title) || "Trainingsplan",
    };
  },
  name: "CalendarVorschau",
  components: {
    WorkoutEntry,
    CalendarPssChart,
    CalendarDurationChart,
    CalendarStressChartFormRamp,
    CalendarIntensityChart,
  },
  props: ["id"],
  data: function () {
    return {
      isPremium: false,
      warnPremiumExpiration: false,
      selectedPlan: {},
      trainingplanDays: 0,
      workoutBlueprints: [],
      loaded: false,
      newWorkoutDate: undefined, // fuer WorkoutBuilder
      newWorkoutDatePicker: undefined, // Fuer Vorlagen-Picker
      selectedCalendarWorkout: undefined,
      workouts: [],
      weekdays: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
      applyPlanMode: "begin",
      applyPlanDate: this.$moment().format("YYYY-MM-DD"),
      weekLoadingBlocked: false,
      loadedWeeks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
      lastScroll: 0,
      thresholds: undefined,
      swimThreshold: 0,
      runThreshold: 0,
      ftp: 0,
      thresholdKey: 0,
      weeklyShould: [],
      // FIT UPLOAD
      fitFile: undefined,
      uploadFinished: false,
      uploadError: false,
      uploadFinishedLoader: true,
      showDropzone: false,
      totalFilesToUpload: 0,
      filesUploaded: 0,
      files: undefined,
      fileUploadErrors: 0,
      scrollLock: false,
      statisticWorkouts: [],
      workoutsAndStatisticWorkouts: [],
      calculatedPlanDates: {},
      isInserting: false,
      activeTrainingplans: [],
      trainingplans: [], // This should be populated with all available training plans
    };
  },
  created() {
    this.updateThresholds();
  },
  destroyed() {
    //window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    timePercentage(seconds, totalTime) {
      if (totalTime > 0) {
        return (100 / totalTime) * seconds;
      } else return 0;
    },
    formattedTimeSlash(seconds) {
      let days = 0;
      let ret = "";
      let tmpSeconds = seconds;
      while (tmpSeconds > 24 * 60 * 60) {
        days += 1;
        tmpSeconds -= 24 * 60 * 60;
      }
      if (days > 0) {
        ret += days + "/";
      }

      return ret + this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },
    colorIsShould(is, should) {
      return calendar_utils.colorForDuration(should, is);
    },
    workoutMoved(id, date) {
      this.workouts.filter((w) => w._id == id)[0].date = date;
    },
    hideNewWorkoutOverlay() {
      this.updateData();
      this.newWorkoutDate = undefined;
      this.scrollLock = false;
    },
    showNewWorkoutOverlay(date) {
      this.newWorkoutDate = date;
      this.scrollLock = true;
    },
    hideNewWorkoutPickerOverlay() {
      this.updateData();
      this.newWorkoutDatePicker = undefined;
      this.scrollLock = false;
    },
    showNewWorkoutPickerOverlay(date) {
      this.newWorkoutDatePicker = date;
      this.scrollLock = true;
    },
    showOverlay(id) {
      this.scrollLock = true;

      // TODO : If activityFile --> Direkt in Details
      // if(this.workouts.contains(workout).activityFile)
      this.selectedCalendarWorkout = this.workouts.find((element) => element._id == id);
    },
    hideOverlay() {
      this.scrollLock = false;

      this.updateData();
      this.selectedCalendarWorkout = undefined;
    },
    /*handleScroll() {               
        let html = document.documentElement;

        if(window.pageYOffset < 80) {
          this.loadCalendarEntriesTop();
        }

        else if (!html.offsetHeight- (parseInt((html.clientHeight) + parseInt(window.pageYOffset))) < 80) {
          this.loadCalendarEntriesBottom();
        }
    },*/

    formatWorkoutTimes(seconds) {
      return this.$moment().startOf("day").seconds(seconds).format("H:mm:ss");
    },
    loadCalendarEntriesBottom() {
      if (!this.weekLoadingBlocked) {
        this.weekLoadingBlocked = true;

        if (this.loadedWeeks.length > 15) {
          this.loadedWeeks.shift();
        }

        this.loadedWeeks.push(this.loadedWeeks[this.loadedWeeks.length - 1] + 1);

        this.updateData();
      }
    },
    loadCalendarEntriesTop() {
      this.loaded = false;
      if (!this.weekLoadingBlocked) {
        this.weekLoadingBlocked = true;
        if (this.loadedWeeks.length > 15) {
          this.loadedWeeks.pop();
        }

        this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);
        this.updateData();
      }
    },

    getDayOfWeek: function (week, day) {
      return this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(day - 1, "d");
    },

    getWeeklyIntensityValues(week) {
      const startDate = this.$moment().startOf("isoWeek").add(week, "w");
      const endDate = this.$moment().startOf("isoWeek").add(week, "w").add(7, "d");

      const workouts = this.workouts.filter(
        (w) => this.$moment(w.date).isAfter(startDate) && this.$moment(w.date).isBefore(endDate) && w.durationIs > 0
      );
      let weeklyIntensity = { time_hit: 0, time_mit: 0, time_lit: 0 };
      workouts.forEach(function (w) {
        weeklyIntensity.time_hit += w.time_hit * (w.durationIs || w.durationShould);
        weeklyIntensity.time_mit += w.time_mit * (w.durationIs || w.durationShould);
        weeklyIntensity.time_lit += w.time_lit * (w.durationIs || w.durationShould);
      });
      return weeklyIntensity;
    },

    getWeeklyIsValues(week) {
      const startDate = this.$moment().startOf("isoWeek").add(week, "w");
      const endDate = this.$moment().startOf("isoWeek").add(week, "w").add(7, "d");

      const workouts = this.workouts.filter(
        (w) => this.$moment(w.date).isAfter(startDate) && this.$moment(w.date).isBefore(endDate)
      );
      let weeklyDuration = { swim: 0, run: 0, bike: 0, strength: 0 };
      workouts.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDuration.swim += w.durationIs || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDuration.bike += w.durationIs || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDuration.run += w.durationIs || 0;
        }
        if (w.sport == "Kraft") {
          weeklyDuration.strength += w.durationIs || 0;
        }
      });

      return weeklyDuration;
    },

    getWeeklyShouldValues(week) {
      const workouts = this.workouts.filter((workout) => {
        return workout.day > week * 7 && workout.day <= week * 7 + 7;
      });

      const blueprintIds = workouts.map((x) => x.workout_blueprint);

      const blueprints = this.workoutBlueprints.filter((workout) => {
        return blueprintIds.indexOf(workout._id) != -1;
      });

      let weeklyDuration = { swim: 0, run: 0, bike: 0, strength: 0 };
      blueprints.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDuration.swim += w.durationShould || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDuration.bike += w.durationShould || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDuration.run += w.durationShould || 0;
        }
        if (w.sport == "Kraft") {
          weeklyDuration.strength += w.durationShould || 0;
        }
      });
      return weeklyDuration;
    },

    getWeeklyDistanceShouldValues(week) {
      const _self = this;
      const workouts = this.workouts.filter((workout) => {
        return workout.day > week * 7 && workout.day <= week * 7 + 7;
      });

      const blueprintIds = workouts.map((x) => x.workout_blueprint);

      let weeklyDistance = { swim: 0, run: 0, bike: 0, strength: 0 };
      workouts.forEach(function (wo) {
        let w = _self.workoutBlueprints.filter((workout) => {
          return blueprintIds.indexOf(workout._id) == wo._id;
        });

        if (w.sport == "Schwimmen") {
          weeklyDistance.swim += w.distanceShould || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDistance.bike += w.distanceShould || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDistance.run += w.distanceShould || 0;
        }
      });

      weeklyDistance.run = Math.round(weeklyDistance.run * 10) / 10;
      weeklyDistance.bike = Math.round(weeklyDistance.bike * 10) / 10;
      weeklyDistance.swim = Math.round(weeklyDistance.swim * 10) / 10;

      return weeklyDistance;
    },

    totalWorkoutLength(workoutFile) {
      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length;
    },

    getWorkoutsOfDate: function (week, day) {
      const workouts = this.workouts.filter((workout) => {
        return workout.day == week * 7 + day;
      });

      const blueprintIds = workouts.map((x) => x.workout_blueprint);

      let blueprints = this.workoutBlueprints.filter((workout) => {
        return blueprintIds.indexOf(workout._id) != -1;
      });

      console.log("blueprintIds", blueprintIds, blueprintIds.length);
      console.log("blueprints", blueprints, blueprints.length);

      return { workouts: blueprints };
    },

    updateData: async function () {
      this.workouts = await (await axios.get(this.$host + "/trainingsplan/" + this.id + "/list_entries")).data;

      this.loaded = true;
      const _self = this;
      setTimeout(function () {
        _self.weekLoadingBlocked = false;
      }, 400);
    },

    async updateThresholds() {
      // TODO: Get only last Threshold
      this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;
      this.thresholdKey++;
    },

    async insertPlan(planId) {
      this.isInserting = planId;
      const scrollPosition = window.pageYOffset;

      try {
        const plan = this.trainingplans.find((p) => p._id === planId);
        if (!plan) return;

        const planDates = this.calculatedPlanDates[planId];
        if (!planDates) return;

        const [startDate] = planDates.split(" - ");

        await axios.post(this.$host + "/trainingsplan/activate", {
          id: planId,
          applyPlanMode: this.applyPlanMode,
          date: this.$moment(startDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
        });

        // Update the active training plans
        await this.loadActiveTrainingplans();

        // Instead of redirecting, show a success message
        this.$toast.success("Plan erfolgreich eingefügt!", {
          position: "top-center",
          duration: 3000,
        });

        // Recalculate dates for all related plans
        this.updateRelatedPlanDates();
      } catch (error) {
        console.error("Error adding plan:", error);
        this.$toast.error("Fehler beim Einfügen des Plans. Bitte versuchen Sie es erneut.", {
          position: "top-center",
          duration: 3000,
        });
      } finally {
        this.isInserting = false;
        this.$nextTick(() => {
          window.scrollTo(0, scrollPosition);
        });
      }
    },

    async loadActiveTrainingplans() {
      try {
        const response = await axios.get(this.$host + "/trainingsplan/list/user");
        this.activeTrainingplans = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    getRelatedPlans(plan) {
      const relatedPlans = [];
      let currentPlan = plan;
      const processedPlanIds = new Set();

      while (currentPlan.previousPlanId && !processedPlanIds.has(currentPlan.previousPlanId)) {
        const prevPlan = this.trainingplans.find((p) => p._id === currentPlan.previousPlanId);
        if (!prevPlan) break;
        processedPlanIds.add(currentPlan._id);

        currentPlan = prevPlan;
        relatedPlans.push(currentPlan);
      }

      relatedPlans.reverse();
      relatedPlans.push(plan);
      currentPlan = plan;

      while (currentPlan.nextPlanId && !processedPlanIds.has(currentPlan.nextPlanId)) {
        const nextPlan = this.trainingplans.find((p) => p._id === currentPlan.nextPlanId);
        if (!nextPlan) break;
        processedPlanIds.add(currentPlan._id);

        currentPlan = nextPlan;
        relatedPlans.push(currentPlan);
      }

      return relatedPlans;
    },

    getPlanPosition(index, total) {
      if (index === 0) return "Erster Plan:";
      if (index === total - 1) return "Letzter Plan:";
      return `Plan ${index + 1}:`;
    },

    getPlanClass(index, total) {
      if (index === 0) return "first-plan";
      if (index === total - 1) return "last-plan";
      if (index < Math.floor(total / 2)) return "early-middle-plan";
      return "late-middle-plan";
    },

    calculatePlanDates(planId) {
      const plan = this.trainingplans.find((p) => p._id === planId);
      if (!plan) return "";

      const relatedPlans = this.getRelatedPlans(plan);
      const selectedPlanIndex = relatedPlans.findIndex((p) => p._id === planId);

      let anchorDate = this.$moment(this.applyPlanDate);
      let dates = {};

      if (this.applyPlanMode === "begin") {
        // Calculate dates for begin mode
        let startDate = anchorDate.clone();
        let endDate = startDate.clone().add(plan.weeks * 7 - 1, "days");
        dates[planId] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;

        // Calculate for plans after the selected plan
        let currentDate = endDate.clone().add(1, "days");
        for (let i = selectedPlanIndex + 1; i < relatedPlans.length; i++) {
          startDate = currentDate.clone();
          endDate = startDate.clone().add(relatedPlans[i].weeks * 7 - 1, "days");
          dates[relatedPlans[i]._id] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;
          currentDate = endDate.clone().add(1, "days");
        }

        // Calculate for plans before the selected plan
        currentDate = anchorDate.clone().subtract(1, "days");
        for (let i = selectedPlanIndex - 1; i >= 0; i--) {
          endDate = currentDate.clone();
          startDate = endDate.clone().subtract(relatedPlans[i].weeks * 7 - 1, "days");
          dates[relatedPlans[i]._id] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;
          currentDate = startDate.clone().subtract(1, "days");
        }
      } else {
        // Calculate dates for end mode
        let endDate = anchorDate.clone();
        let startDate = endDate.clone().subtract(plan.weeks * 7 - 1, "days");
        dates[planId] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;

        // Calculate for plans before the selected plan
        let currentDate = startDate.clone().subtract(1, "days");
        for (let i = selectedPlanIndex - 1; i >= 0; i--) {
          endDate = currentDate.clone();
          startDate = endDate.clone().subtract(relatedPlans[i].weeks * 7 - 1, "days");
          dates[relatedPlans[i]._id] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;
          currentDate = startDate.clone().subtract(1, "days");
        }

        // Calculate for plans after the selected plan
        currentDate = anchorDate.clone().add(1, "days");
        for (let i = selectedPlanIndex + 1; i < relatedPlans.length; i++) {
          startDate = currentDate.clone();
          endDate = startDate.clone().add(relatedPlans[i].weeks * 7 - 1, "days");
          dates[relatedPlans[i]._id] = `${startDate.format("DD.MM.YYYY")} - ${endDate.format("DD.MM.YYYY")}`;
          currentDate = endDate.clone().add(1, "days");
        }
      }

      this.calculatedPlanDates = { ...this.calculatedPlanDates, ...dates };
      return dates[planId] || "";
    },

    isPlanActive(planId) {
      const planDates = this.calculatedPlanDates[planId];
      if (!planDates) return false;

      const [startDate, endDate] = planDates.split(" - ");
      const planStart = this.$moment(startDate, "DD.MM.YYYY");
      const planEnd = this.$moment(endDate, "DD.MM.YYYY");

      return this.activeTrainingplans.some(
        (activePlan) =>
          activePlan.trainingsplan._id === planId &&
          this.$moment(activePlan.start).isSame(planStart, "day") &&
          this.$moment(activePlan.end).isSame(planEnd, "day")
      );
    },

    isPlanDateInPast(planId) {
      const planDates = this.calculatedPlanDates[planId];
      if (!planDates) return false;

      const endDate = planDates.split(" - ")[1];
      return this.$moment(endDate, "DD.MM.YYYY").isBefore(this.$moment(), "day");
    },

    getOverlappingPlans(planId) {
      const planDates = this.calculatedPlanDates[planId];
      if (!planDates) return [];

      const [startDate, endDate] = planDates.split(" - ");
      const planStart = this.$moment(startDate, "DD.MM.YYYY");
      const planEnd = this.$moment(endDate, "DD.MM.YYYY");

      return this.activeTrainingplans.filter((activePlan) => {
        const activeStart = this.$moment(activePlan.start);
        const activeEnd = this.$moment(activePlan.end);
        return (
          activePlan.trainingsplan._id !== planId &&
          ((activeStart.isSameOrBefore(planEnd) && activeEnd.isSameOrAfter(planStart)) ||
            (planStart.isSameOrBefore(activeEnd) && planEnd.isSameOrAfter(activeStart)))
        );
      });
    },

    updateRelatedPlanDates() {
      this.calculatedPlanDates = {};
      this.calculatePlanDates(this.selectedPlan._id);
    },
  },

  mounted: async function () {
    this.loaded = false;
    const _self = this;
    let user = (await axios.get(this.$host + "/user/me")).data;

    if (
      !this.$moment(user.premiumAccountUntil).isAfter(this.$moment()) &&
      !this.$moment(user.freeAccountUntil).isAfter(this.$moment())
    ) {
      this.warnPremiumExpiration = true;
    }

    this.selectedPlan = await (await axios.get(this.$host + "/trainingsplan/" + this.id)).data;

    this.workouts = await (await axios.get(this.$host + "/trainingsplan/" + this.id + "/list_entries")).data;

    axios
      .get(this.$host + "/workout/blueprints/v2", {
        params: { trainingplanId: this.id },
      })
      .then(async function (response) {
        _self.workoutBlueprints = response.data.global.concat(response.data.trainingplan);

        // remove duplicates
        _self.workoutBlueprints = _self.workoutBlueprints.filter(
          (workout, index, self) => index === self.findIndex((t) => t._id === workout._id)
        );

        _self.workouts.sort(function (a, b) {
          return a.day - b.day;
        });
        _self.statisticWorkouts = _self.workouts.map((w) => {
          let blueprint = _self.workoutBlueprints.find((workout) => {
            return w.workout_blueprint == workout._id;
          });

          if (!blueprint) {
            console.log("Missing BP", w.workout_blueprint);
          } else {
            let wo = JSON.parse(JSON.stringify(blueprint));
            wo.date = _self.$moment().add(w.day - 1, "days");

            _self.trainingplanDays = Math.max(_self.trainingplanDays, w.day);

            return wo;
          }
        });

        const woDone = await (
          await axios.get(
            _self.$host +
              "/workout/from/" +
              _self.$moment().add(-42, "d").startOf("d") +
              "/until/" +
              _self.$moment().add(_self.trainingplanDays, "d").endOf("d")
          )
        ).data;

        _self.thresholdKey++;

        _self.workoutsAndStatisticWorkouts = _self.statisticWorkouts.concat(woDone);
        _self.loaded = true;
      })
      .catch(function (error) {
        console.log(error);
      });

    // Load all training plans
    try {
      const plansResponse = await axios.get(this.$host + "/trainingsplan/list_active");
      this.trainingplans = plansResponse.data;
    } catch (error) {
      console.log(error);
    }

    // Load active training plans
    await this.loadActiveTrainingplans();

    // Calculate initial dates for related plans
    this.updateRelatedPlanDates();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.loading {
  background: green;
}

.heading {
  font-weight: bold;
  font-size: 16px;
}

i {
  font-size: 16px;
  padding: 7px;
  color: #aaa;
}

.entry {
  background: #f7f7fa;
  padding: 5px;
  border-radius: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
  z-index: 66666;
  overflow: hidden;
}

@media (min-width: 768px) {
  .cal-col:not(:last-child) {
    border-right: 2px dotted #ddd;
  }
}

.close-overlay i {
  cursor: pointer;
  font-size: 35px;
  float: right;
}

.overlay-box {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  background: #fff;
  padding: 0;
  overflow-y: auto !important;
  margin: auto;
  max-width: 750px;
  left: 50%;
  margin-left: -375px;
  overflow-x: hidden;
  top: 3%;
  bottom: 3%;

  z-index: 99999999;
  border-radius: 5px;
}

#dropzone {
  padding: 50px;
  text-align: center;
  position: fixed;
  margin: auto;
  left: 20%;
  border-radius: 5px;
  right: 20%;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border: 3px solid #000;
  z-index: 999999999999;
}

.overlay-box-library {
  position: fixed;
  left: 2%;
  right: 2%;
  top: 2%;
  bottom: 2%;
  margin: auto;

  padding: 0;
  overflow-x: hidden;
  z-index: 99999999;
  max-width: 1400px;
  border-radius: 5px;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 800px) {
  .overlay-box {
    position: fixed;

    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    margin-left: 0;
  }
  .overlay-box-library {
    position: fixed;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    background: #fff;
    padding: 0;
    overflow-x: hidden;
    z-index: 99999999;
  }
}

.plan-chain {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem 0;
}

.chain-plan {
  flex: 0 0 auto;
  width: 220px;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.first-plan {
  border-left: 4px solid #28a745;
}

.early-middle-plan {
  border-left: 4px solid #ffc107;
}

.late-middle-plan {
  border-left: 4px solid #17a2b8;
}

.last-plan {
  border-left: 4px solid #dc3545;
}

.selected-plan {
  background-color: #e6f7ff;
  border: 2px solid #1890ff;
  box-shadow: 0 0 10px rgba(24, 144, 255, 0.3);
}

.plan-action-button {
  width: 100%;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-plan {
  background-color: #28a745;
  color: white;
  border-color: #28a745;
}

.active-plan:hover,
.active-plan:focus {
  background-color: #218838;
  border-color: #1e7e34;
}

.active-plan:disabled {
  background-color: #28a745;
  border-color: #28a745;
  opacity: 0.65;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.past-plan-note {
  color: #dc3545;
  font-style: italic;
  margin-top: 0.5rem;
}

.overlapping-plans {
  margin-top: 1rem;
  font-size: 0.9em;
  color: #6c757d;
}

.overlapping-plans ul {
  padding-left: 1.2rem;
  margin-bottom: 0;
}

.overlapping-plans li {
  margin-bottom: 0.3rem;
}
</style>
