<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";

export default {
  props: ["sessions"],

  data: function () {
    return {
      workouts: [],

      chartdata: {
        labels: [
          "<50",
          "<60",
          "<70",
          "<80",
          "<90",
          "<100",
          "<110",
          "<120",
          "<130",
          "<140",
          "<150",
          "<160",
          "<170",
          "<180",
          "<190",
          "190+",
        ],
        datasets: [
          {
            borderRadius: 3,

            borderColor: "hsl(356, 100%, 41%)",
            backgroundColor: "hsl(356, 100%, 41%)",
            fill: true,
            tension: 0.4,
            data: [],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                var datasetLabel = moment().startOf("day").seconds(context.parsed.y).format("H:mm[h]");
                return datasetLabel;
              },
            },
          },
        },

        elements: {
          point: {
            radius: 0,
          },
        },
        animation: {
          duration: 0,
        },

        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },

        scales: {
          y: {
            ticks: {
              maxTicksLimit: 5,
              callback: function (value) {
                return moment().startOf("day").seconds(value).format("H:mm[h]");
              },
            },
          },
          xAxes: {
            gridLines: {
              display: false,
            },
            ticks: {
              display: true,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  mounted() {
    let ctx = this.$refs.graph.getContext("2d");

    this.computeChartData();

    const config = {
      type: "bar",
      data: this.chartdata,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.y;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
      ],
    };

    new Chart(ctx, config);
  },

  methods: {
    computeChartData: function () {
      // `this` points to the vm instance
      const _self = this;
      let dataDist = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.sessions.forEach(function (session) {
        let last_timer_time = 0;
        session.laps.forEach(function (lap) {
          lap.records.forEach(function (record) {
            if (record.heart_rate < 50) {
              dataDist[0] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 60) {
              dataDist[1] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 70) {
              dataDist[2] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 80) {
              dataDist[3] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 90) {
              dataDist[4] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 100) {
              dataDist[5] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 110) {
              dataDist[6] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 120) {
              dataDist[7] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 130) {
              dataDist[8] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 140) {
              dataDist[9] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 150) {
              dataDist[10] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 160) {
              dataDist[11] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 170) {
              dataDist[12] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 180) {
              dataDist[13] += record.timer_time - last_timer_time;
            } else if (record.heart_rate < 190) {
              dataDist[14] += record.timer_time - last_timer_time;
            } else {
              dataDist[15] += record.timer_time - last_timer_time;
            }

            last_timer_time = record.timer_time;
            _self.chartdata.datasets[0].data = dataDist;
          });
        });
      });

      /*
       
*/
      return this.chartdata;
    },
  },
};
</script>
