<template>
  <div>
    <div class="white_wrapper">
      <h4>{{ message }}</h4>
      <br />
      <div v-if="token">
        <h3>Neues Passwort festlegen!</h3>

        <input
          type="password"
          v-model="password"
          style="width: 300px"
        /><br /><br />
        <button class="b-button" @click="savePassword()">Speichern</button>
      </div>
      <div v-if="!token">
        <h3>Passwort zurücksetzen</h3>

        <h4>eMail-Adresse des Accounts eingeben</h4>
        <br />
        <form action="javascript:void(0);">
          <input type="email" v-model="email" style="width: 300px" />
          <br /><br />
          <button class="b-button" @click="resetPassword()">
            Zurücksetzen
          </button>
        </form>

        <img
          class="top-spacer-50"
          src="https://assets.pushinglimits.club/d-tri-1.webp"
          style="max-width: 300px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Start",
  props: {
    token: String,
  },
  data: function () {
    return {
      message: "",
      email: "",
      password: "",
    };
  },
  methods: {
    async resetPassword() {
      const _self = this;
      this.message = "bitte warten...";
      if (this.email && this.email.length > 0) {
        const req = { email: this.email };
        axios
          .post(this.$host + "/user/resetpassword", req)
          .then(function () {
            console.log("SUCCESs!");
            _self.message =
              "Wenn zu der eMail-Adresse ein Account existiert, wurde eine eMail zum Zurücksetzen geschickt. Bitte prüfe dein Postfach (auch deinen Spam-Ordner).";
          })
          .catch(function (error) {
            console.log(error);
            _self.message =
              "Wenn zu der eMail-Adresse ein Account existiert, wurde eine eMail zum Zurücksetzen geschickt. Bitte prüfe dein Postfach (auch deinen Spam-Ordner).";
          });
      } else {
        this.message = "Bitte eine eMail-Adresse eingeben.";
      }
    },

    async savePassword() {
      const _self = this;
      this.message = "bitte warten...";
      if (this.password && this.password.length > 0) {
        const req = { password: this.password };
        axios
          .post(this.$host + "/user/resetpassword/" + this.token, req)
          .then(function () {
            console.log("SUCCESs!");
            _self.message =
              "Passwort wurde gesetzt. Das Formular kann nicht nochmal verwendet werden!";
          })
          .catch(function (error) {
            console.log(error);
            _self.message =
              "Passwort konnte nicht gesetzt werden. Bitte wähle ein anderes (mindestens 8 Zeichen). Bitte beachten: Das Passwort kann nur einmal über diesen Link geändert werden. Fordere ggf. einen neuen Link an.";
          });
      }
    },
  },
};
</script>
