<template>
  <div class="workout-analysis">
    <div class="container-fluid">
      <div class="row">
        <!-- Radfahren (Cycling) Column -->
        <div class="col-md-6">
          <div class="metrics-summary" v-if="heartRateAnalysesCycling.length > 0">
            <div class="metric-card">
              <h4>Max Heart Rate</h4>
              <div class="metric-value">{{ maxHeartRateCycling }} bpm</div>
              <div class="metric-value">{{ maxHeartRateCycling * 0.9 }} bpm</div>
            </div>
            <div class="metric-card">
              <h4>Current Threshold</h4>
              <span class="metric-value">{{ currentThresholdCycling }} Watt</span>
            </div>
            <div class="metric-card">
              <h4>Number of Analyses</h4>
              <span class="metric-value">{{ heartRateAnalysesCycling.length }}</span>
            </div>
          </div>
          <HeartRateAnalysisChart :analyses="heartRateAnalysesCycling" :sport="'Radfahren'" />
          <!--  <div class="chart-section">
            <h3>Heart Rate Efficiency Analysis</h3>
            <HeartRateEfficiencyChart
              :analyses="heartRateAnalysesCycling"
              :sport="'Radfahren'"
              :maxHr="maxHeartRate || 190"
            />
          </div>-->
        </div>

        <!-- Laufen (Running) Column -->
        <div class="col-md-6">
          <div class="metrics-summary" v-if="heartRateAnalysesRun.length > 0">
            <div class="metric-card">
              <h4>Max Heart Rate</h4>
              <div class="metric-value">{{ maxHeartRateRun }} bpm</div>
              <div class="metric-value">{{ maxHeartRateRun * 0.9 }} bpm</div>
            </div>
            <div class="metric-card">
              <h4>Current Threshold</h4>
              <span class="metric-value">{{ currentThresholdRun }} kmh</span>
            </div>
            <div class="metric-card">
              <h4>Number of Analyses</h4>
              <span class="metric-value">{{ heartRateAnalysesRun.length }}</span>
            </div>
          </div>
          <HeartRateAnalysisChart :analyses="heartRateAnalysesRun" :sport="'Laufen'" />
          <!-- <div class="chart-section">
            <h3>Heart Rate Efficiency Analysis</h3>
            <HeartRateEfficiencyChart :analyses="heartRateAnalysesRun" :sport="'Laufen'" :maxHr="maxHeartRate || 190" />
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeartRateAnalysisChart from "@/components/chart/HeartRateAnalysisChart.vue";
// import HeartRateEfficiencyChart from "../chart/HeartRateEfficiencyChart.vue";
import axios from "axios";

export default {
  name: "WorkoutAnalysis",

  components: {
    HeartRateAnalysisChart,
    // HeartRateEfficiencyChart,
  },

  data() {
    return {
      heartRateAnalysesRun: [],
      heartRateAnalysesCycling: [],
      heartRateAnalyses: [],
      isLoaded: false,
    };
  },

  computed: {
    maxHeartRateCycling() {
      if (!this.isLoaded || this.heartRateAnalysesCycling.length === 0) return 190;
      return this.heartRateAnalysesCycling[0].heartRateMax || 190;
    },

    maxHeartRateRun() {
      if (!this.isLoaded || this.heartRateAnalysesRun.length === 0) return 190;
      return this.heartRateAnalysesRun[0].heartRateMax || 190;
    },

    currentThresholdRun() {
      if (!this.isLoaded || this.heartRateAnalysesRun.length === 0) return "-";
      const lastAnalysis = this.heartRateAnalysesRun[0];
      return Math.round((3600 / lastAnalysis.runThreshold) * 100) / 100 || "-";
    },

    currentThresholdCycling() {
      if (!this.isLoaded || this.heartRateAnalysesCycling.length === 0) return "-";
      const lastAnalysis = this.heartRateAnalysesCycling[0];
      return lastAnalysis.ftp || "-";
    },
  },

  methods: {
    async loadAnalyses() {
      try {
        const response = await axios.get(this.$host + "/workout/heart-rate-analyses", {
          params: {
            limit: 15,
            sport: "Laufen",
          },
        });
        this.heartRateAnalysesRun = response.data;

        const responseRad = await axios.get(this.$host + "/workout/heart-rate-analyses", {
          params: {
            limit: 15,
            sport: "Radfahren",
          },
        });
        this.heartRateAnalysesCycling = responseRad.data;

        this.heartRateAnalyses = [...this.heartRateAnalysesRun, ...this.heartRateAnalysesCycling];
        this.isLoaded = true;
        console.log("Fetched Heart Rate Analyses:", this.heartRateAnalyses);
      } catch (error) {
        console.error("Failed to load heart rate analyses:", error);
        this.isLoaded = false;
      }
    },
  },

  mounted() {
    this.loadAnalyses();
  },
};
</script>

<style scoped>
.workout-analysis {
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

.chart-section {
  margin-top: 2rem;
}

h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.metrics-summary {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
}

.metric-card {
  background: #f8f9fa;
  padding: 1rem 2rem;
  border-radius: 8px;
  text-align: center;
  min-width: 150px;
}

.metric-card h4 {
  margin: 0 0 0.5rem 0;
  color: #6c757d;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
}
</style>
