<template>
  <div class="row top-spacer-15">
    <div class="col-3" style="text-align: right">
      {{ from }}
    </div>
    <div class="col-6" style="margin-top: 3px">
      <div
        class="pull-left"
        :style="'height:15px;width:10%;background:' + getColorForPercentage(0)"
      ></div>
      <div
        class="pull-left"
        :style="'height:15px;width:10%;background:' + getColorForPercentage(10)"
      ></div>
      <div
        class="pull-left"
        :style="'height:15px;width:10%;background:' + getColorForPercentage(20)"
      ></div>
      <div
        class="pull-left"
        :style="'height:15px;width:10%;background:' + getColorForPercentage(30)"
      ></div>
      <div
        class="pull-left"
        :style="'height:15px;width:10%;background:' + getColorForPercentage(40)"
      ></div>
      <div
        class="pull-left"
        :style="'height:15px;width:10%;background:' + getColorForPercentage(50)"
      ></div>
      <div
        class="pull-left"
        :style="'height:15px;width:10%;background:' + getColorForPercentage(60)"
      ></div>
      <div
        class="pull-left"
        :style="'height:15px;width:10%;background:' + getColorForPercentage(70)"
      ></div>
      <div
        class="pull-left"
        :style="'height:15px;width:10%;background:' + getColorForPercentage(80)"
      ></div>
      <div
        class="pull-left"
        :style="'height:15px;width:10%;background:' + getColorForPercentage(90)"
      ></div>
    </div>
    <div class="col-3" style="text-align: left">
      {{ until }}
    </div>
  </div>
</template>

<script>
module.exports = {
  data: function () {
    return {};
  },
  props: ["from", "until"],

  methods: {
    getColorForPercentage: function (pct) {
      var a = (94 - pct) / 100,
        b = 250 * a;

      return "hsl(" + b + ", 100%, 40%)";
    },
  },
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
