export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}



export function fileRead(file) {
  return new Promise(function(resolve,reject) {
    let reader = new FileReader();
    reader.onload = e => {
      resolve(e.target.result);
    };
    reader.onerror = e => {
      reject(e.target.error);
    };
    reader.readAsText(file, "UTF-8");
  });
}

export function readCharacteristicValue(characteristic) {
  return new Promise(function(resolve) {
    let executed = false;
    let listener = event => {
      characteristic.removeEventListener('characteristicvaluechanged', listener);
      characteristic.stopNotifications();
      if(!executed) {
        executed = true;
        resolve(event.target.value);
      }
    }

    characteristic.addEventListener('characteristicvaluechanged', listener);
    characteristic.startNotifications();
  });
}

export function dateFormat(dt) {
  let month = dt.getUTCMonth() + 1;
  month = (month < 10) ? "0" + month : month;

  let day = dt.getUTCDate();
  day = (day < 10) ? "0" + day : day;

  let hours = dt.getUTCHours();
  hours = (hours < 10) ? "0" + hours : hours;

  let minutes = dt.getUTCMinutes();
  minutes = (minutes < 10) ? "0" + minutes : minutes;

  let seconds = dt.getUTCSeconds();
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return dt.getUTCFullYear() + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":" + seconds + "Z";
}

export function perc2color(perc) {   

  if(perc <= 0.6) { return "#888"; }
  if(perc < 0.8) { return "#3CAEA3"; }
  if(perc < 1.01) { return "#F6D55C"; }
  if(perc >= 1.01) { return "#ED553B"; }
  if(perc >= 1.15) { return "#CB3305"; }

}

export function timeToString(sec_num) {
  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);
  //if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  let time;
  if(hours !== 0) {
    time = hours+':'+minutes+':'+seconds;
  } else {
    time = minutes+':'+seconds;
  }

  return time;
}

export function computePowerFromWorkoutFile(workoutFile, ftp) {
  if (!workoutFile || !workoutFile.length || !ftp) {
    return 0;
  }

  let totalDuration = 0;
  let weightedPower = 0;

  workoutFile.forEach(segment => {
    if (segment.type === 'SteadyState') {
      weightedPower += (segment.Power * ftp / 100) * segment.Duration;
      totalDuration += segment.Duration;
    } 
    else if (segment.type === 'Ramp') {
      // For ramps, use average of start and end power
      const avgPower = (segment.PowerLow + segment.PowerHigh) / 2;
      weightedPower += (avgPower * ftp / 100) * segment.Duration;
      totalDuration += segment.Duration;
    }
    else if (segment.type === 'IntervalsT') {
      // For intervals, calculate both on and off portions
      const onDurationTotal = segment.OnDuration * segment.Repeat;
      const offDurationTotal = segment.OffDuration * segment.Repeat;
      
      weightedPower += (segment.OnPower * ftp / 100) * onDurationTotal;
      weightedPower += (segment.OffPower * ftp / 100) * offDurationTotal;
      
      totalDuration += onDurationTotal + offDurationTotal;
    }
  });

  return totalDuration > 0 ? Math.round(weightedPower / totalDuration) : 0;
}

