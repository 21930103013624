export function generateColors(count) {
  const colors = [
    'rgba(54, 162, 235, 0.5)',   // blue
    'rgba(75, 192, 192, 0.5)',   // teal
    'rgba(153, 102, 255, 0.5)',  // purple
    'rgba(255, 159, 64, 0.5)',   // orange
    'rgba(255, 99, 132, 0.5)',   // pink
    'rgba(255, 206, 86, 0.5)',   // yellow
    'rgba(50, 168, 82, 0.5)',    // green
    'rgba(232, 62, 140, 0.5)',   // hot pink
  ];

  // If we need more colors than we have defined, generate them
  while (colors.length < count) {
    colors.push(`rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.5)`);
  }

  return colors.slice(0, count);
} 