<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>
<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";

export default {
  props: ["from", "until", "done"],

  data: function () {
    return {
      localFrom: null,
      workouts: [],
      loadOnlydone: this.done,

      chartdata: {
        labels: [],
        datasets: [
          {
            label: "LIT",
            backgroundColor: "#28a745",
            data: [],
            borderRadius: 3,
          },
          {
            label: "MIT",
            backgroundColor: "rgb(232, 126, 4)",
            data: [],
            borderRadius: 3,
          },
          {
            label: "HIT",
            backgroundColor: "#dc3545",
            data: [],
            borderRadius: 3,
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          title: {
            display: true,
            text: "Intensität",
            position: "left",
          },
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                var datasetLabel =
                  context.dataset.label + ": " + moment().startOf("day").seconds(context.parsed.y).format("HH:mm");
                return datasetLabel + "h";
              },
            },
          },
        },
        scales: {
          yAxes: {
            stacked: true,
            ticks: {
              maxTicksLimit: 10,
              beginAtZero: true,
              callback: function (value) {
                return moment().startOf("day").seconds(value).format("HH:mm") + "h";
              },
            },
          },
          xAxes: {
            gridLines: {
              display: false,
            },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  async mounted() {
    this.localFrom = this.from;

    let ctx = this.$refs.graph.getContext("2d");

    this.workouts = this.done;

    this.computeChartData();
    const config = {
      type: "bar",
      data: this.chartdata,
      options: this.options,
    };
    new Chart(ctx, config);
    this.$emit("chartRendered");
  },

  methods: {
    formatDate: function (value) {
      return this.$moment(value).format("DD.MM");
    },
    getDayOfWeek: function (week, day) {
      return this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(day - 1, "d");
    },

    computeChartData: function () {
      while (this.localFrom.isSameOrBefore(this.until, "day")) {
        let tomorrow = this.$moment(this.localFrom);
        tomorrow.add(1, "d");

        let workoutsOfDate = this.workouts.filter((workout) => {
          return (
            this.$moment(workout.date).isSameOrAfter(this.localFrom) &&
            this.$moment(workout.date).isBefore(this.$moment(tomorrow))
          );
        });

        let durationHit = 0;
        let durationMit = 0;
        let durationLit = 0;

        workoutsOfDate.forEach((workout) => {
          let durationOfWorkout = workout.durationIs;

          durationHit += (workout.time_hit / 100) * durationOfWorkout;
          durationMit += (workout.time_mit / 100) * durationOfWorkout;
          durationLit += (workout.time_lit / 100) * durationOfWorkout;
        });

        this.chartdata.labels.push(this.formatDate(this.localFrom));
        this.chartdata.datasets[2].data.push(Math.floor(durationHit));
        this.chartdata.datasets[1].data.push(Math.floor(durationMit));
        this.chartdata.datasets[0].data.push(Math.floor(durationLit));

        this.localFrom = this.localFrom.add(1, "d");
      }

      return this.chartdata;
    },
  },
};
</script>
