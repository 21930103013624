<template>
  <div>
    <div class="row top-spacer-100">
      <div class="col-lg-9">
        <div class="white_wrapper" style="text-align: left">
          <div v-if="trainingplans.length == 0">Keine Trainingspläne angelegt</div>
          <!-- Add Filters Section -->
          <div class="row">
            <div class="filter-section col-4" style="margin-bottom: 20px">
              <h5>Filter nach Sport</h5>
              <select v-model="filters.sport" @change="applyFilters">
                <option value="">All</option>
                <option>Triathlon</option>
                <option>Radfahren</option>
                <option>Laufen</option>
                <option>Schwimmen</option>
                <option value="Kraft">Athletik</option>
              </select>
            </div>
            <div class="filter-section col-4" style="margin-bottom: 20px">
              <h5>Filter nach Typ</h5>
              <select v-model="filters.volume" @change="applyFilters">
                <option value="">All</option>
                <option>Anfänger</option>
                <option>Fortgeschritten</option>
                <option>Profi</option>
              </select>
            </div>
            <!-- New Name Filter Section -->
            <div class="filter-section col-4" style="margin-bottom: 20px">
              <h5>Filter nach Name</h5>
              <input type="text" v-model="filters.name" @input="applyFilters" placeholder="Name eingeben" />
            </div>
          </div>

          <!-- Modify v-for to use filteredTrainingplans -->
          <div
            v-for="trainingplan in filteredTrainingplans"
            :key="trainingplan._id"
            class="row colored-row"
            style="padding: 25px"
          >
            <div class="col-lg-12">
              <h4>
                <span style="margin-right: 5px">
                  <span v-if="trainingplan.sport == 'Kraft'"
                    ><img src="@/assets/images/strength.png" style="width: 22px"
                  /></span>
                  <span v-if="trainingplan.sport == 'Laufen'"
                    ><img src="@/assets/images/run.png" style="width: 22px"
                  /></span>
                  <span v-if="trainingplan.sport == 'Schwimmen'"
                    ><img src="@/assets/images/swim.png" style="width: 22px"
                  /></span>
                  <span v-if="trainingplan.sport == 'Radfahren'"
                    ><img src="@/assets/images/bike.png" style="width: 22px"
                  /></span>
                  <span v-if="trainingplan.sport == 'Triathlon'"
                    ><img src="@/assets/images/tri.png" style="width: 22px"
                  /></span>
                </span>
                {{ trainingplan.title }}
              </h4>
              <span :style="{ color: trainingplan.weeks && trainingplan.weeks !== 0 ? 'inherit' : 'red' }">
                ({{ trainingplan.weeks || 0 }} Wochen)
              </span>
            </div>

            <div class="col-lg-3">
              {{ trainingplan.sport }}
            </div>
            <div class="col-lg-3">{{ trainingplan.category }}</div>
            <div class="col-lg-2">
              {{ trainingplan.volume }}
            </div>
            <div class="col-lg-2">
              <div v-if="trainingplan.active" style="color: green">✓ aktiviert</div>
              <div v-if="!trainingplan.active" style="color: red">× deaktiviert</div>
            </div>
            <div class="col-lg-2">
              <a v-bind:href="'/#/createtrainingsplan/' + trainingplan._id" class="a-button">Editieren</a>
            </div>
            <div v-if="hasPreviousOrNextPlans(trainingplan)" class="col-lg-12">
              <br /><br /><strong><u>Vorherige / Nachfolgende Pläne</u></strong
              ><br />
              <div class="row">
                <div v-if="trainingplan.previousPlanId" class="col-lg-6">
                  <strong>Vorheriger Plan</strong>
                  <a :href="'/#/createtrainingsplan/' + trainingplan.previousPlanId">
                    {{ getPlanTitle(trainingplan.previousPlanId) }}
                  </a>
                </div>
                <div v-if="trainingplan.previousPlanId2" class="col-lg-6">
                  <strong>Vorheriger Plan 2</strong>
                  <a :href="'/#/createtrainingsplan/' + trainingplan.previousPlanId2">
                    {{ getPlanTitle(trainingplan.previousPlanId2) }}
                  </a>
                </div>
                <div v-if="trainingplan.previousPlanId3" class="col-lg-6">
                  <strong>Vorheriger Plan 3</strong>
                  <a :href="'/#/createtrainingsplan/' + trainingplan.previousPlanId3">
                    {{ getPlanTitle(trainingplan.previousPlanId3) }}
                  </a>
                </div>
                <div v-if="trainingplan.nextPlanId" class="col-lg-6">
                  <strong>Nächster Plan</strong>
                  <a :href="'/#/createtrainingsplan/' + trainingplan.nextPlanId">
                    {{ getPlanTitle(trainingplan.nextPlanId) }}
                  </a>
                </div>
                <div v-if="trainingplan.nextPlanId2" class="col-lg-6">
                  <strong>Nächster Plan 2</strong>
                  <a :href="'/#/createtrainingsplan/' + trainingplan.nextPlanId2">
                    {{ getPlanTitle(trainingplan.nextPlanId2) }}
                  </a>
                </div>
                <div v-if="trainingplan.nextPlanId3" class="col-lg-6">
                  <strong>Nächster Plan 3</strong>
                  <a :href="'/#/createtrainingsplan/' + trainingplan.nextPlanId3">
                    {{ getPlanTitle(trainingplan.nextPlanId3) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="white_wrapper">
          <h4>Neuen Trainingsplan anlegen</h4>
          <hr />
          <h4>Bezeichnung</h4>
          <input v-model="title" />
          <hr />
          <h4>Beschreibung</h4>
          <textarea v-model="description"></textarea>
          <hr />
          <h5>Kategorie</h5>
          <select v-model="category">
            <option>Base</option>
            <option>Build</option>
            <option>Peak</option>
            <option>Block</option>
            <option>Trainingslager</option>
          </select>
          <hr />
          <h5>Sport</h5>
          <select v-model="sport">
            <option>Triathlon</option>
            <option>Radfahren</option>
            <option>Laufen</option>
            <option>Schwimmen</option>
            <!--<option>Hyrox</option>-->

            <option value="Kraft">Athletik</option>
          </select>
          <hr />
          <h5>Volume</h5>
          <select v-model="volume">
            <option>Anfänger</option>
            <option>Fortgeschritten</option>
            <option>Profi</option>
          </select>
          <hr />
          <button class="button-b" @click="save()">SPEICHERN</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateTrainingsplan",

  data: function () {
    return {
      trainingplans: [],
      title: "",
      description: "",
      category: "Base",
      sport: "Triathlon",
      volume: "Anfänger", // Ändert von "Beginner" zu "Anfänger"
      // Add filters object
      filters: {
        sport: "",
        volume: "",
        name: "", // Added name filter
      },
    };
  },

  computed: {
    filteredTrainingplans() {
      return this.trainingplans
        .filter((plan) => {
          const matchesSport = this.filters.sport ? plan.sport === this.filters.sport : true;
          const matchesVolume = this.filters.volume ? plan.volume === this.filters.volume : true;
          const matchesName = this.filters.name
            ? plan.title.toLowerCase().includes(this.filters.name.toLowerCase())
            : true;
          return matchesSport && matchesVolume && matchesName;
        })
        .sort((a, b) => {
          // First, sort by volume
          if (a.volume !== b.volume) {
            console.log("BY VOLUME", a.volume, b.volume);
            return a.volume.localeCompare(b.volume);
          }
          // If volumes are the same, sort by name
          return a.title.localeCompare(b.title);
        });
    },
  },

  methods: {
    save: async function () {
      const req = {
        title: this.title,
        description: this.description,
        category: this.category,
        sport: this.sport,
        volume: this.volume,
      };
      const result = await (await axios.post(this.$host + "/trainingsplan/create", req)).data;
      console.log(result);

      this.updateData();
    },
    updateData: async function () {
      const response = await axios.get(this.$host + "/trainingsplan/list");
      this.trainingplans = response.data;
    },
    applyFilters() {},
    hasPreviousOrNextPlans(trainingplan) {
      return (
        trainingplan.previousPlanId ||
        trainingplan.previousPlanId2 ||
        trainingplan.previousPlanId3 ||
        trainingplan.nextPlanId ||
        trainingplan.nextPlanId2 ||
        trainingplan.nextPlanId3
      );
    },
    getPlanTitle(planId) {
      const plan = this.trainingplans.find((p) => p._id === planId);
      return plan ? plan.title : "Unbekannter Plan";
    },
  },
  mounted: async function () {
    this.updateData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.loading {
  background: green;
}

.week {
  background: #efefef;
  margin-bottom: 30px;
  padding: 35px 0;
  padding-bottom: 15px;
}

.heading {
  font-weight: bold;
  font-size: 16px;
}

i {
  font-size: 16px;
  padding: 7px;
  color: #aaa;
}

.entry {
  background: #ddd;
  padding: 5px;
  border-radius: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
  z-index: 66666;
}

.close-overlay i {
  cursor: pointer;
  font-size: 35px;
  float: right;
}

.overlay-box {
  position: fixed;
  background: #fff;
  padding: 0;
  overflow-y: auto !important;
  margin: auto;
  max-width: 750px;
  left: 50%;
  margin-left: -375px;
  overflow-x: hidden;
  top: 3%;
  bottom: 3%;
  z-index: 99999999;
}

.overlay-box-library {
  position: fixed;
  left: 1%;
  right: 1%;
  top: 2%;
  bottom: 2%;
  background: #fff;
  padding: 0;
  overflow-x: hidden;
  z-index: 99999999;
}

.pointer {
  cursor: pointer;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .overlay-box {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin-left: 0;
  }

  .overlay-box-library {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    padding: 0;
    overflow-x: hidden;
    z-index: 99999999;
  }
}
</style>
