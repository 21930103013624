<template>
  <div>
    <div style="height: 320px">
      <canvas ref="graph" id="graph" width="100%"></canvas>
    </div>
    <div class="row top-spacer-15">
      <div class="col-4">
        <h4>VO2max</h4>
        <h4>{{ vo2max() }}</h4>
      </div>
      <div class="col-4">
        <h4>Schwelle</h4>
        <h4>
          {{
            $moment
              .utc((3600 / (60 / (this.threshold_run / 60) || 0)) * 1000)
              .format("mm:ss")
          }}min/km
        </h4>
      </div>
      <div class="col-4">
        <h4>FatMax</h4>
        <h4>{{ fatMax() }}km/h</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";

export default {
  props: ["threshold_run", "weight"],
  // TODO: SCHWELLE LAUFEN + SCHWIMMEN auf 2. Y ACHSE. Höher ist besser
  data() {
    return {
      chartData: {
        labels: this.labels,
      },

      options: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },

        plugins: {
          annotation: {
            drawTime: "beforeDatasetsDraw",
            annotations: [
              {
                type: "box",

                xMin: 0,
                xMax: 11,

                backgroundColor: "rgba(55, 150, 55, 0.7)",
                borderColor: "transparent",
                border: 0,
                borderWidth: 0,
              },
              {
                type: "box",

                xMin: 11,
                xMax: 20,

                backgroundColor: "rgba(232, 126, 4,0.7) ",
                borderColor: "transparent",
                border: 0,
                borderWidth: 0,
              },
              {
                type: "box",

                xMin: 20,

                backgroundColor: "rgba(232, 55, 4,0.7) ",
                borderColor: "transparent",
                border: 0,
                borderWidth: 0,
              },
            ],
          },
          tooltip: {
            enabled: true, // sonst folgende settings:

            mode: "index",
            callbacks: {
              label: (context) => {
                // data for manipulation
                const val = context.formattedValue;

                const index = context.datasetIndex;
                let prefix = "";
                let middle = "";

                if (index == 0) {
                  prefix = "Kohlenhydrate: ";
                } else if (index == 1) {
                  prefix = "Fette: ";
                } else if (index == 2) {
                  prefix = "Gesamt: ";
                }

                if (index == 0 || index == 1) {
                  middle = prefix + val + "kcal";
                } else {
                  middle = prefix + val + "kcal";
                }

                if (index == 0 || index == 1) {
                  return middle;
                } else {
                  if (index == 2) {
                    return middle;
                  } else {
                    return middle;
                  }
                }
              },
            },
          },
        },

        aspectRatio: 1,
        layout: {
          padding: 0,
          margin: 0,
        },
        animation: {
          duration: 0,
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0,
          },
        },

        scales: {
          yA: {
            display: true,
            stacked: false,
            grid: {
              drawBorder: true,
              display: true,
            },
            ticks: {
              display: true,
              beginAtZero: true,
            },
            title: {
              display: false,
              text: "kcal",
            },
          },
          x: {
            grid: {
              display: true,
            },
            title: {
              display: true,
              text: "km/h",
            },
            ticks: {
              display: true,
              maxTicksLimit: 6,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    let ctx = this.$refs.graph.getContext("2d");

    let fat = [];
    let cho = [];
    let cal = [];

    let labels = [];
    for (let i = 40; i <= 100; i++) {
      const fatCho = this.fat_and_carbs_utilization_at_PctVo2max(i);

      // 0.85 gute Approximation? auch die anderen wie kalorien zu vo2?
      const meterPerSecondAtV02Percentage =
        (1000 / ((this.threshold_run / 60) * 0.818)) * (i / 100);

      const vo2 =
        0.182258 * meterPerSecondAtV02Percentage +
        0.000104 * Math.pow(meterPerSecondAtV02Percentage, 2);

      const calories = vo2 * 19;

      fat.push(Math.round((fatCho.fat / 100) * calories));
      cho.push(Math.round((fatCho.cho / 100) * calories));
      cal.push(Math.round(calories));
      const kmh =
        Math.round((meterPerSecondAtV02Percentage * 10 * 3.6) / 60) / 10;

      const formattedDuration = moment
        .utc((3600 / kmh || 0) * 1000)
        .format("mm:ss");

      labels.push(formattedDuration + "min/km");
      i++;
    }
    this.chartData.labels = labels;
    this.chartData.datasets = [
      {
        data: cho,
        fill: false,
        borderColor: "rgba(0,0,0, 1)",
        backgroundColor: "rgba(0,0,0, 1)",
        borderWidth: 2,
        yAxisID: "yA",
        label: "Kohlenhydrate",
      },
      {
        data: fat,
        fill: false,
        borderColor: "rgba(220,40,40, 1)",
        backgroundColor: "rgba(220,40,40, 1)",
        borderWidth: 2,
        yAxisID: "yA",
        label: "Fette",
      },

      {
        data: cal,
        fill: true,
        borderColor: "rgba(0,0,0, 0.1)",
        backgroundColor: "rgba(0,0,0, 0.2)",
        borderWidth: 2,
        yAxisID: "yA",
        label: "Kalorien",
      },
    ];

    const config = {
      type: "line",
      data: this.chartData,
      options: this.options,

      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yA = chart.scales.yA;

              let ctx = chart.ctx;
              ctx.save();

              if (yA) {
                ctx.beginPath();
                ctx.moveTo(x, yA.top);
                ctx.lineTo(x, yA.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
      ],
    };

    new Chart(ctx, config);
  },

  methods: {
    //https://www.omnicalculator.com/sports/vo2-max-runners

    vo2max() {
      // todo...
      const meterPerMinuteThreshold = 1000 / (this.threshold_run / 60);

      const vo2 =
        -4.6 +
        0.182258 * meterPerMinuteThreshold +
        0.000104 * Math.pow(meterPerMinuteThreshold, 2);

      return Math.round(vo2 / 0.9);
    },

    khSpeicher() {
      // approx, besser trainiert korreliert mit höherer speicherkapazität
      return (this.vo2max() / 2) * this.weight;
    },

    entleerungKhSpeicher(stunden, zufuhr = false) {
      let kh = this.khSpeicher();

      if (zufuhr) {
        kh += this.khAufnahme() * 4 * stunden;
      }

      let khProStunde = kh / stunden;
      let calories = 0;
      let i = 0;
      let watt = 0;
      for (i = 10; i <= 100; i++) {
        const fatCho = this.fat_and_carbs_utilization_at_PctVo2max(i);
        const meterPerMinuteV02Percentage =
          (1000 / (this.threshold_run / 60)) * 0.818 * (i / 100);

        const vo2 =
          -4.6 +
          0.182258 * meterPerMinuteV02Percentage +
          0.000104 * Math.pow(meterPerMinuteV02Percentage, 2);

        calories = vo2 * 18 * (fatCho.cho / 100);
        if (calories > khProStunde) {
          break;
        }
      }

      if (i == 100) {
        return watt + "+";
      }

      return watt + "";
    },

    khAufnahme() {
      // approx
      return this.weight * 1.1;
    },

    fatMax() {
      return Math.round((60 / (this.threshold_run / 60)) * 10 * 0.68) / 10;
    },

    crossOver() {
      return Math.round((this.threshold_run / 6) * 0.540625);
    },

    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },

    fat_and_carbs_utilization_at_PctVo2max: function (vo2maxPct) {
      const pctFtp = Math.min(100, vo2maxPct / 0.805);
      const cho = Math.min(
        100,
        Math.max(0, 0.0407 * Math.pow(Math.E, 0.03193 * pctFtp) * 100 + 0)
      );
      return {
        cho: cho,
        fat: 100 - cho,
      };
    },
  },
};
</script>
