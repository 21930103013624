<template>
  <div class="row">
    <div class="submenu col-xl-2 d-none d-md-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/training/dashboard">
              <div class="menu-entry">Dashboard</div></a
            >
          </div>
          <div class="col-lg-12">
            <a href="/#/training/kalender">
              <div class="menu-entry">Kalender</div></a
            >
          </div>

          <div class="col-lg-12">
            <a href="/#/training/liste">
              <div class="menu-entry">Trainingspläne</div>
            </a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/aicoach">
              <div class="menu-entry active_submenu">
                Voach
                <small style="color: darkred">Neu</small>
              </div>
            </a>
          </div>
          <!--
            <div class="col-lg-12">
            <a href="/#/training/ai">
              <div class="menu-entry">AI-Coach</div>
            </a>
          </div>-->
        </div>
      </div>
    </div>
    <div class="col-xl-10">
      <div class="row"></div>
      <div class="row top-spacer-25">
        <div class="col-lg-8" style="margin-bottom: 10px">
          <div class="white_wrapper" v-if="!feeling">
            <h4>Wie fühlst du dich heute?</h4>
            <h4>
              <small
                >Beziehe dabei deinen Schlaf, Stress, Erschöpfung, muskuläre
                Ermüdung usw. mit ein</small
              >
            </h4>
            <div class="row top-spacer-25">
              <div class="col-lg-1"></div>
              <div class="col-lg-2 top-spacer-5">
                <div class="copilot-btn" @click="feeling = 1">Erschöpft</div>
              </div>
              <div class="col-lg-2 top-spacer-5">
                <div class="copilot-btn" @click="feeling = 2">müde</div>
              </div>
              <div class="col-lg-2 top-spacer-5">
                <div class="copilot-btn" @click="feeling = 3">okay</div>
              </div>
              <div class="col-lg-2 top-spacer-5">
                <div class="copilot-btn" @click="feeling = 4">gut</div>
              </div>
              <div class="col-lg-2 top-spacer-5">
                <div class="copilot-btn" @click="feeling = 5">sehr gut</div>
              </div>
            </div>
          </div>

          <div class="white_wrapper" v-if="feeling">
            <h4>Wie lange möchtest du heute noch ungefähr trainieren?</h4>
            <div class="row top-spacer-25">
              <div class="col-lg-2"></div>

              <div class="col-lg-2 top-spacer-5">
                <div class="copilot-btn">45min</div>
              </div>
              <div class="col-lg-2 top-spacer-5">
                <div class="copilot-btn">1h</div>
              </div>
              <div class="col-lg-2 top-spacer-5">
                <div class="copilot-btn">1h30</div>
              </div>
              <div class="col-lg-2 top-spacer-5">
                <div class="copilot-btn">2h</div>
              </div>
            </div>
          </div>
          <div class="white_wrapper top-spacer-10" v-if="message || workouts">
            <h4>{{ message }}</h4>
          </div>
        </div>
        <div class="col-lg-4" style="text-align: left">
          <div class="white_wrapper">
            <h2 style="border-bottom: 3px solid #000; padding-bottom: 5px">
              Täglicher Coach
            </h2>
            Leistung steigern ohne konkretes Ziel.

            <h4 class="top-spacer-25">Sportarten</h4>

            <div style="text-align: left; padding-left: 20px">
              <div style="font-size: 16px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  style="height: 14px"
                  checked
                />
                <label>Schwimmen</label>
              </div>

              <div style="font-size: 16px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  style="height: 14px"
                  checked
                />
                <label>Radfahren</label>
              </div>
              <div style="font-size: 16px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  style="height: 14px"
                  checked
                />
                <label>Laufen</label>
              </div>
              <div style="font-size: 16px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  style="height: 14px"
                  checked
                />
                <label>Athletik</label>
              </div>
            </div>

            <h4 class="top-spacer-25">Hinweis</h4>
            Trainiere nicht, wenn du krank bist. Hole vorher ärztlichen Rat ein.
            Breche das Training ab, wenn Beschwerden auftreten.<br /><br />
            Wenn du dein ganzes Training anhand eines Trainingsplans
            ausrichtest, solltest du diesen entsprechend der Vorgaben verfolgen
            und nicht diese Vorschläge verwenden.

            <!--

            <h4 class="top-spacer-25">Umfang</h4>
            <small
              >Wie viel möchtest du ungefähr pro Woche trainineren? In den
              letzten Wochen hast du im Schnitt
              <strong>3h50min</strong> trainiert</small
            ><br />
            <select name="hours" class="top-spacer-10">
              <option value="bike">2-4h</option>
              <option value="run">4-6h</option>
              <option value="run">6-9h</option>
              <option value="run">9-12h</option>
              <option value="run">12-16h</option>
            </select>-->
            <!--
            <h4 class="top-spacer-50">Trainingszeit (maximal)</h4>
            <div class="row top-spacer-15">
              <div class="col-lg-6"><strong>Montag</strong> &nbsp;</div>
              <div class="col-lg-6">
                <select name="monday">
                  <option value="0">Ruhetag</option>

                  <option value="60">1h</option>
                  <option value="90">1,5h</option>
                  <option value="120">2h</option>
                  <option value="180">3</option>
                  <option value="240">4</option>
                </select>
              </div>
            </div>

            <div class="row top-spacer-10">
              <div class="col-lg-6"><strong>Dienstag</strong> &nbsp;</div>
              <div class="col-lg-6">
                <select name="tuesday">
                  <option value="0">Ruhetag</option>

                  <option value="60">1h</option>
                  <option value="90">1,5h</option>
                  <option value="120">2h</option>
                  <option value="180">3</option>
                  <option value="240">4</option>
                </select>
              </div>
            </div>

            <div class="row top-spacer-10">
              <div class="col-lg-6"><strong>Mittwoch</strong> &nbsp;</div>
              <div class="col-lg-6">
                <select name="wednesday">
                  <option value="0">Ruhetag</option>

                  <option value="60">1h</option>
                  <option value="90">1,5h</option>
                  <option value="120">2h</option>
                  <option value="180">3</option>
                  <option value="240">4</option>
                </select>
              </div>
            </div>

            <div class="row top-spacer-10">
              <div class="col-lg-6"><strong>Donnerstag</strong> &nbsp;</div>
              <div class="col-lg-6">
                <select name="thursday">
                  <option value="0">Ruhetag</option>

                  <option value="60">1h</option>
                  <option value="90">1,5h</option>
                  <option value="120">2h</option>
                  <option value="180">3</option>
                  <option value="240">4</option>
                </select>
              </div>
            </div>

            <div class="row top-spacer-10">
              <div class="col-lg-6"><strong>Freitag</strong> &nbsp;</div>
              <div class="col-lg-6">
                <select name="friday">
                  <option value="0">Ruhetag</option>

                  <option value="60">1h</option>
                  <option value="90">1,5h</option>
                  <option value="120">2h</option>
                  <option value="180">3</option>
                  <option value="240">4</option>
                </select>
              </div>
            </div>
            <div class="row top-spacer-10">
              <div class="col-lg-6"><strong>Samstag</strong> &nbsp;</div>
              <div class="col-lg-6">
                <select name="saturday">
                  <option value="0">Ruhetag</option>

                  <option value="60">1h</option>
                  <option value="90">1,5h</option>
                  <option value="120">2h</option>
                  <option value="180">3</option>
                  <option value="240">4</option>
                </select>
              </div>
            </div>

            <div class="row top-spacer-10">
              <div class="col-lg-6"><strong>Sonntag</strong> &nbsp;</div>
              <div class="col-lg-6">
                <select name="sunday">
                  <option value="0">Ruhetag</option>

                  <option value="60">1h</option>
                  <option value="90">1,5h</option>
                  <option value="120">2h</option>
                  <option value="180">3</option>
                  <option value="240">4</option>
                </select>
              </div>
            </div>-->

            <!--

            <h4 class="top-spacer-50">Wichtigste Rennen</h4>
            <div class="row top-spacer-15">
              <div class="col-lg-12"><strong>A-Wettkämpfe</strong> &nbsp;</div>

              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-7">
                    Datum<br />
                    <input
                      type="date"
                      value="2023-07-22"
                      name="competition_1"
                    />
                  </div>
                  <div class="col-lg-5">
                    Dauer (min)<br /><input type="number" value="90" />
                  </div>
                </div>
              </div>
              <div class="col-lg-12 top-spacer-15">
                <div class="col-lg-12">
                  <strong>B-Wettkämpfe</strong> &nbsp;
                </div>
              </div>
              <div class="col-lg-12">
                <input type="date" value="2023-07-22" name="competition_1" />
              </div>
              <div class="col-lg-12 top-spacer-15">
                <div class="col-lg-12">
                  <strong>C-Wettkämpfe</strong> &nbsp;
                </div>
              </div>
              <div class="col-lg-12">
                <input type="date" value="2023-07-22" name="competition_1" />
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AICoach",
  props: {},
  data: function () {
    return {
      feeling: 0,
      training_time: 60,
    };
  },
};
</script>
