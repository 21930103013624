<template>
  <div class="row">
    <a class="back-button" href="/#/kraftraum">➜</a>

    <div class="col-lg-12 col-xl-12 top-spacer-25 white_wrapper">
      <div class="col-lg-12">
        <div class="row" v-if="!loaded">
          <div class="col-lg-12">
            <div class="spinner-border text-primary" role="status"></div>
          </div>
        </div>

        <div class="row" v-if="!m && loaded">
          <div class="col-lg-12">
            <h4>Keine Inhalte gefunden</h4>
          </div>
        </div>

        <div class="row" v-if="m">
          <div class="col-lg-3" style="text-align: justify">
            <div class="row">
              <div class="col-lg-12">
                <a href="/#/kraftraum"
                  ><button style="margin: 0; padding: 7px 15px">
                    ❮ Zurück zur Übersicht
                  </button></a
                >
              </div>
            </div>
            <h4 class="top-spacer-25">
              <strong>{{ m.title }}</strong>
            </h4>
            <br />
            <div style="white-space: pre-line; text-align: left">
              {{ m.text_body }}
            </div>
            <br />
            <a
              v-if="user && user.isAdmin"
              style="color: blue"
              v-bind:href="'/#/kraftraum/' + m._id + '/update'"
              >Admin: Bearbeiten</a
            >
          </div>
          <div class="col-lg-9">
            <iframe
              width="100%"
              height="500px"
              v-bind:src="m.youtube_link"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
        <div class="row top-spacer-100">
          <div class="col-lg-12" v-if="similar.length > 0">
            <h4>Ähnliche Videos</h4>
            <div class="row">
              <div
                class="col-lg-3"
                v-for="m in similar.slice(0, 4)"
                :key="m._id"
              >
                <a v-bind:href="'/#/kraftraum/' + m._id" class="media_link">
                  <div class="top-spacer-25" style="text-align: center">
                    <iframe
                      width="100%"
                      height="200px"
                      v-bind:src="m.youtube_link"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                    <h4 class="top-spacer-25">
                      <strong>{{ m.title }}</strong>
                    </h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row top-spacer-50" v-if="!similarLoaded">
          <div class="col-lg-12">
            <div class="spinner-border text-primary" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ShowVideo",
  props: {
    id: String,
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.loadMedia();
      }
    },
  },
  data: function () {
    return {
      m: undefined,
      similar: [],

      user: undefined,
      loaded: false,
      similarLoaded: false,
    };
  },
  async mounted() {
    console.log(this.id);
    this.loaded = false;
    if (this.initPage) {
      this.page = this.initPage;
    }
    this.loadMedia();

    this.user = (await axios.get(this.$host + "/user/me")).data;
  },
  methods: {
    async loadMedia() {
      this.loaded = false;
      this.similarLoaded = false;

      this.m = (await axios.get(this.$host + "/video/" + this.id)).data;
      this.loaded = true;

      let sim = (
        await axios.get(this.$host + "/video/category/" + this.m.main_category)
      ).data;
      this.similarLoaded = true;
      // Nicht geöffnetes als ähnlichen Eintrag anzeigen
      this.similar = sim.filter((x) => x._id != this.m._id);
    },
  },
};
</script>

<style scoped>
main {
  margin: auto;
  max-width: 800px;
}

.media {
  font-size: 18px;
}

.padded {
  padding: 50px;
}
</style>
