<template>
  <div>
    <canvas ref="graph"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";

export default {
  props: {
    from: { type: [String, Object], required: true },
    until: { type: [String, Object], required: true },
    done: { type: Array, default: () => [] },
  },

  data() {
    return {
      chartdata: {
        labels: [],
        datasets: [
          {
            label: "Schwimmen",
            backgroundColor: "#255f85",
            data: [],
            borderRadius: 3,
          },
          {
            label: "Rad",
            backgroundColor: "#E9724C",
            data: [],
            borderRadius: 3,
          },
          {
            label: "Laufen",
            backgroundColor: "#FFC857",
            data: [],
            borderRadius: 3,
          },
          {
            label: "Athletik",
            backgroundColor: "#481D24",
            data: [],
            borderRadius: 3,
          },
        ],
      },
      options: {
        animation: { duration: 0 },
        plugins: {
          title: {
            display: true,
            text: "Trainingsdauer",
            position: "left",
          },
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                return `${context.dataset.label}: ${moment().startOf("day").seconds(context.parsed.y).format("H:mm")}h`;
              },
            },
          },
        },
        scales: {
          y: {
            stacked: true,
            ticks: {
              maxTicksLimit: 10,
              beginAtZero: true,
              callback: function (value) {
                return moment().startOf("day").seconds(value).format("HH:mm") + "h";
              },
            },
          },
          x: {
            grid: { display: false },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  mounted() {
    this.initializeChart();
  },

  methods: {
    formatDate(value) {
      return moment(value).format("DD.MM");
    },

    computeChartData() {
      let fromDate = moment(this.from);
      const untilDate = moment(this.until);

      while (fromDate.isSameOrBefore(untilDate, "day")) {
        let workoutsOfDate = this.done.filter((workout) => moment(workout.date).isSame(fromDate, "day"));

        let durationSwim = 0,
          durationBike = 0,
          durationRun = 0,
          durationKraft = 0;

        workoutsOfDate.forEach((workout) => {
          switch (workout.sport) {
            case "Laufen":
              durationRun += workout.durationIs;
              break;
            case "Radfahren":
              durationBike += workout.durationIs;
              break;
            case "Schwimmen":
              durationSwim += workout.durationIs;
              break;
            case "Kraft":
              durationKraft += workout.durationIs;
              break;
          }
        });

        this.chartdata.labels.push(this.formatDate(fromDate));
        this.chartdata.datasets[0].data.push(Math.floor(durationSwim));
        this.chartdata.datasets[1].data.push(Math.floor(durationBike));
        this.chartdata.datasets[2].data.push(Math.floor(durationRun));
        this.chartdata.datasets[3].data.push(Math.floor(durationKraft));

        fromDate.add(1, "day");
      }
    },

    initializeChart() {
      const ctx = this.$refs.graph.getContext("2d");
      this.computeChartData();
      new Chart(ctx, {
        type: "bar",
        data: this.chartdata,
        options: this.options,
      });
      this.$emit("chartRendered");
    },
  },
};
</script>
