<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <h1 class="staydreaming kraftraum-heading top-spacer-25">
          Video Kraftraum anlegen
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="white_wrapper">
          <h4>ueberschrift</h4>
          <input v-model="title" />
          <hr />
        </div>

        <div class="col-lg-12">
          <div class="white_wrapper">
            <h4>beschreibung</h4>
            <textarea v-model="text_body" />
          </div>
        </div>

        <div class="white_wrapper">
          <h4>youtube link</h4>
          <input v-model="youtube_link" />
          <hr />
        </div>

        <div class="white_wrapper">
          <h4>Kategorie</h4>
          <input v-model="category" />
          <hr />
        </div>

        <div class="white_wrapper">
          <h4>Bild hinzufügen</h4>

          <input
            multiple
            accept="image/*"
            type="file"
            @change="processFile($event)"
          />
        </div>
        <div v-if="imageIds && imageIds.length > 0">
          <hr />
          <h5>Bild löschen</h5>
          <div class="row">
            <div v-for="id in imageIds" :key="id" class="col-lg-4">
              <img
                style="width: 100%"
                alt="Bild des Rezepts"
                :src="`https://assets.pushinglimits.club/${id}`"
              />
              <div style="padding: 5px">
                <button @click="deleteImage(id)">Löschen</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="white_wrapper">
          <div class="col-lg-12">
            <button @click="save" class="top-spacer-25">SPEICHERN</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateRezept",
  props: ["id"],
  data: function () {
    return {
      files: undefined,

      title: "",
      text_body: "",
      youtube_link: "",
      category: "",
      imageIds: [],
    };
  },

  methods: {
    processFile(event) {
      console.log("Process files!;");
      this.files = event.target.files;
    },
    deleteImage: function (imageId) {
      const _self = this;
      axios
        .delete(this.$host + "/video/" + this.id + "/image/" + imageId)
        .then(function (response) {
          console.log("vodeo delte res", response);
          _self.imageIds = response.data.imageIds;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    save: function () {
      const formData = new FormData();
      if (this.id) {
        formData.append("id", this.id);
      }

      if (this.files) {
        var ins = this.files.length;
        for (var x = 0; x < ins; x++) {
          console.log("Append Image");
          formData.append("files[]", this.files[x]);
        }
      } else {
        formData.append("files[]", []);
      }

      formData.append("title", this.title);
      formData.append("text_body", this.text_body);
      formData.append("youtube_link", this.youtube_link);
      formData.append("category", this.category);

      const _self = this;
      axios
        .post(this.$host + "/video", formData)
        .then(function (res) {
          console.log(res);
          _self.$router.push({
            name: "Video",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted: function () {
    console.log(this.id);
    // URL mit Video Id aufgerufen ---> Bearbeiten eines Mediums
    if (this.id) {
      const _self = this;
      axios
        .get(this.$host + "/video/" + this.id)
        .then(function (response) {
          _self.title = response.data.title;
          _self.text_body = response.data.text_body;
          _self.youtube_link = response.data.youtube_link;
          _self.category = response.data.main_category;
          _self.imageIds = response.data.imageIds;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>


<style scoped>
.kraftraum-heading {
  font-size: 80px;
  text-align: center;
}

.library {
  background: #efefef;
  padding: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.24);
}

.category {
  text-align: center;

  width: auto;
  height: auto;
  transform: rotate(270deg);
  font-size: 100px;
}

.box {
  margin: 25px;
  font-weight: bold;
  font-size: 18px;
  background: #efefef;
  padding: 0;
  cursor: pointer;
}

.box img {
  max-height: 150px;
  width: 100%;
}

input {
  width: 100%;
}

textarea {
  height: 350px;
}
</style>