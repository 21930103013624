import { BleClient } from "@capacitor-community/bluetooth-le";

const fecService = '6e40fec1-b5a3-f393-e0a9-e50e24dcca9e';
const fec2characteristic = '6e40fec2-b5a3-f393-e0a9-e50e24dcca9e';
const fec3characteristic = '6e40fec3-b5a3-f393-e0a9-e50e24dcca9e';



async function doesDeviceSupportFEC(device) {
    if (device) {

        const support = (await BleClient.getServices(device.deviceId)).some(function (service) {
            return service.uuid == fecService;
        });

        if (support) { console.log("Trainer supports FEC"); }
        else { console.log("Trainer does not support FEC!", device); }

        return Promise.resolve(support);
    } else { return false; }
}



// INIT - ggf. wieder raus
async function subscribeToFecResponse(device) {
    await BleClient.startNotifications(
        device.deviceId,
        fecService,
        fec2characteristic,
        function (dataview) {
            console.log("FEC Subscription wurde initialisiert", dataview);
        }
    );
}

// todo bei unmounted aufrufen !
async function stopNotifications(device) {
    await BleClient.stopNotifications(
        device.deviceId,
        fecService,
        fec2characteristic

    );
}


// SET TARGET POWER
function getPowerTargetView(value, channel = 5) {
    return control(_dataPage49(value, channel));
}


async function setTargetPower(device, power) {
    const requestTargetPower = getPowerTargetView(power)

    await BleClient.write(
        device.deviceId,
        fecService,
        fec3characteristic,
        requestTargetPower
    )
}



// SET TARGET RESISTANCE
function getResistanceTargetView(value, channel = 5) {
    return control(_dataPage48(value, channel));
}

async function setResistanceTarget(device, resistance) {
    const requestTargetResistance = getResistanceTargetView(resistance)

    await BleClient.write(
        device.deviceId,
        fecService,
        fec3characteristic,
        requestTargetResistance
    )
}



function control(content, channel = 5) {
    let buffer = new ArrayBuffer(13);
    let view = new DataView(buffer);

    view.setUint8(0, 164, true);
    view.setUint8(1, 9, true);
    view.setUint8(2, 79, true);
    view.setUint8(3, channel, true);

    let x = 4;
    for (let y = 0; y < 8; y++) {
        view.setUint8(x, content.getUint8(y), true);
        x++;
    }

    view.setUint8(12, _xor(view), true);

    return view;
}


function _dataPage48(resistance) {
    const dataPage = 48;
    let buffer = new ArrayBuffer(8);
    let view = new DataView(buffer);

    view.setUint8(0, dataPage, true);
    view.setUint8(7, resistance / 0.5, true);

    return view;
}

function _dataPage49(power) {
    const dataPage = 49;
    let buffer = new ArrayBuffer(8);
    let view = new DataView(buffer);

    view.setUint8(0, dataPage, true);
    view.setUint16(6, power / 0.25, true);

    return view;
}

function _xor(view) {
    let c = 0;
    for (let i = 0; i < view.byteLength; i++) {
        c ^= view.getUint8(i);
    }
    return c;
}



export {
    setTargetPower,
    doesDeviceSupportFEC,
    subscribeToFecResponse,
    setResistanceTarget,
    getPowerTargetView,
    getResistanceTargetView,
    stopNotifications
};
