<template>
  <div class="row">
    <div class="submenu col-xl-2 d-none d-xl-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/training/dashboard"> <div class="menu-entry">Dashboard</div></a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/kalender"> <div class="menu-entry">Kalender</div></a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/liste">
              <div class="menu-entry">Trainingspläne</div>
            </a>
          </div>
          <div class="col-lg-12">
            <a href="/#/training/FragDenCoach">
              <div class="menu-entry active_submenu">Wissen</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-10 white_wrapper" style="text-align: left">
      <h3 class="centered top-spacer-25">Frag den Coach</h3>

      <div class="col-lg-12">
        <h4>
          In diesem Format sammeln und beantworten wir regelmäßig eure Fragen zum Training in Videoform. Hast du eine
          Frage? Schick' eine Mail an fragen@pushinglimits.club.
        </h4>
      </div>
      <div class="col-md-12 top-spacer-25">
        <h2>Schwimmtechnik-Übungen <span style="color: blue">(Neu)</span></h2>
        <div v-if="videoUrls && videoUrls.length">
          <hr />
          <div class="top-spacer-15">
            <strong>Öffne die Übung mit einem Klick</strong>
          </div>
          <b-accordion id="accordion-1" role="tablist" v-model="activeItem">
            <b-card no-body v-for="(video, index) in videoUrls" :key="video.uri" class="top-spacer-5">
              <b-card-header
                header-tag="header"
                class="p-1"
                style="cursor: pointer"
                role="tab"
                v-b-toggle="'accordion-' + index"
              >
                <a href="#" class="accordion-link">&nbsp;{{ video.title }}</a>
              </b-card-header>
              <b-collapse :id="'accordion-' + index" role="tabpanel">
                <b-card-body>
                  <iframe
                    width="100%"
                    height="350px"
                    :src="video.uri"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-accordion>
        </div>
      </div>

      <h2 class="centered top-spacer-75">Zugseiltraining</h2>

      <div class="col-md-12 top-spacer-25">
        <h2>Schneller schwimmen, ohne zu schwimmen</h2>
        <iframe
          width="100%"
          height="350px"
          src="https://www.youtube-nocookie.com/embed/a0ny0KOGFEQ"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>

      <h3 class="centered top-spacer-75">Wettkampfwissen</h3>

      <div class="row">
        <div class="col-md-6 top-spacer-25">
          <h2>3 Tipps um schneller im Wettkampf zu schwimmen</h2>
          <iframe
            width="100%"
            height="350px"
            src="https://www.youtube-nocookie.com/embed/lx7D7nbRnHs"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>

        <div class="col-md-6 top-spacer-25">
          <h2>3 Freiwasser Tipps für den Wettkampf</h2>
          <iframe
            width="100%"
            height="350px"
            src="https://www.youtube-nocookie.com/embed/b_-yV8bg3H4"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>

        <div class="col-md-6 top-spacer-25">
          <h2>Raceweek + Carboloading</h2>
          <iframe
            width="100%"
            height="350px"
            src="https://www.youtube-nocookie.com/embed/P3T12rqHAxA"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div class="col-md-6 top-spacer-25">
          <h2>Pacing im Triathlonwettkampf</h2>
          <iframe
            width="100%"
            height="350px"
            src="https://www.youtube-nocookie.com/embed/KMgYcp50QhE"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 top-spacer-25">
          <h2>Raceday + Race Nutrition</h2>
          <iframe
            width="100%"
            height="350px"
            src="https://www.youtube-nocookie.com/embed/cCl2TxJHUdg"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div class="col-md-6 top-spacer-25">
          <h2>Material für den Triathlonwettkampf</h2>
          <iframe
            width="100%"
            height="350px"
            src="https://www.youtube-nocookie.com/embed/987yTMDvzJE"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>

      <h2 class="centered top-spacer-75">Trainingslager</h2>

      <div class="col-md-12 top-spacer-25">
        <h2>Wie plane ich ein Trainingslager?</h2>
        <iframe
          width="100%"
          height="350px"
          src="https://www.youtube-nocookie.com/embed/YY1yx9IW36o"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>

      <h2 class="centered top-spacer-75">Ask The Coach #3</h2>

      <div class="row">
        <div class="col-md-6 top-spacer-25">
          <iframe
            width="100%"
            height="350px"
            src="https://www.youtube-nocookie.com/embed/OdkZtU3SZi8"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div class="col-md-6">
          <h4 class="top-spacer-25">
            00:00 - Intro<br /><br />
            00:30 - Wann FTP-Test<br /><br />
            02:18 - Aufteilung Longrun<br /><br />
            04:47 - Ärztliche Untersuchung<br /><br />
            06:45 - Schrittfrequenz<br /><br />
            09:20 - Wie oft FTP-Test?<br /><br />
            10:30 - Wochen tauschen?<br /><br />
            11:45 - Anpassungen der Wochen?<br /><br />
            14:15 - Level-Sprung in den Plänen?<br /><br />
          </h4>
        </div>
      </div>

      <h2 class="centered top-spacer-75">Ask The Coach #2</h2>

      <div class="row">
        <div class="col-md-6 top-spacer-25">
          <iframe
            width="100%"
            height="350px"
            src="https://www.youtube-nocookie.com/embed/xa-WSumFRvI"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div class="col-md-6">
          <h4 class="top-spacer-25">
            00:00 - Intro<br /><br />
            01:08 - Saisonhöhepunkt 70.3 im Juni | Welcher Plan?<br /><br />
            03:30 - Integration Lauf ABC in das Training<br /><br />
            05:50 - An welchen Tagen macht Krafttraining im Triathlon Sinn?<br /><br />
            07:40 - Wie lange soll ich mobility Übungen machen?<br /><br />
            09:15 - Wie gestalte ich Erholungswoche?
          </h4>
        </div>
      </div>

      <h2 class="centered top-spacer-75">Ask The Coach #1</h2>

      <div class="row">
        <div class="col-md-6 top-spacer-25">
          <iframe
            width="100%"
            height="350px"
            src="https://www.youtube-nocookie.com/embed/CGg7AntK-WQ"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div class="col-md-6">
          <h4 class="top-spacer-25">
            1. Dehnen vor dem Laufen?<br /><br />
            2. Mobility vs. Aktivierung?<br /><br />
            3. Wenig Zeit: lieber Athletik Einheit oder Rad/Lauf/Swim Session?<br /><br />
            4. Ist die Reihenfolge der Einheiten wichtig?<br /><br />
            5. Was ist, wenn ich ein Intervall abbrechen muss?<br /><br />
            6. Mir geht während der Einheit die Energie aus - soll ich eine Pause machen?<br /><br />
            7. Soll ich mir mehrere Laufschuhe zulegen?
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FAQ",
  data: () => ({
    videoUrls: null,
    activeItem: null,
  }),
  props: {
    msg: String,
  },

  async mounted() {
    const wordToVideoUrl = await axios.get(this.$host + "/media/get_videos_wordlist").then((res) => res.data);
    let uniqueVideoUrls = new Set();
    for (const key in wordToVideoUrl) {
      uniqueVideoUrls.add(wordToVideoUrl[key]);
    }

    this.videoUrls = Array.from(uniqueVideoUrls);
  },
};
</script>

<style>
.impressum {
  font-size: 16px;
}
</style>
