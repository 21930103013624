import { BleClient } from "@capacitor-community/bluetooth-le";

import * as ftms from "./ftms";
import * as fec from "./fec";

const HEART_RATE = "0000180d-0000-1000-8000-00805f9b34fb";
const HEART_RATE_MEASUREMENT = "00002a37-0000-1000-8000-00805f9b34fb";
const CYCLING_POWER = "00001818-0000-1000-8000-00805f9b34fb";
const CYCLING_POWER_MEASUREMENT = "00002a63-0000-1000-8000-00805f9b34fb";

// TODO: auslesen?
// const CYCLING_SPEED_MEASUREMENT = "00001816-0000-1000-8000-00805f9b34fb";

let isFtmsDevice = false;
let isFecOverBle = false;
let initialSubscribeDone = false;
let lastPower = 0;
let lastResistanceTarget = 0;
let setTargetOpCounter = 0;

async function initialize() {
  console.log("INIT");
  try {
    const client = await BleClient.initialize();

    if (client) {
      console.log("BleClient verfuegbar");
    }

    return client;
  } catch (err) {
    console.log("BLE / Web Bluetooth API nicht verfügbar");
    return undefined;
  }
}

async function initialSubscribe(device) {
  initialSubscribeDone = true;
  lastResistanceTarget = 0;
  lastPower = 0;
  if (await ftms.doesDeviceSupportFtms(device)) {
    console.log("SUPPORTS FTMS!");

    isFtmsDevice = true;
    ftms.subscribeControlPointResponse(device);
    //ftms.subscribeToDataPointResponse(device);

    ftms.requestControl(device);
  } else if (await fec.doesDeviceSupportFEC(device)) {
    console.log("SUPPORTS FEC");
    isFecOverBle = true;
    fec.subscribeToFecResponse(device);
  } else {
    console.log("Trainer unterstützt kein verfuegbares Protokoll");
  }
}

async function stopNotifications(device) {
  if (await ftms.doesDeviceSupportFtms(device)) {
    ftms.stopNotifications(device);
  } else if (await fec.doesDeviceSupportFEC(device)) {
    fec.stopNotifications(device);
  }
}

function doesDeviceSupportControl() {
  if (!initialSubscribeDone) {
    throw "doesDeviceSupportControl() zu früh aufgerufen. Vorher initialSubscribe(device) aufrufen!";
  }
  return isFtmsDevice || isFecOverBle;
}

function stopHeartMeterNotification(device) {
  BleClient.stopNotifications(
    device.deviceId,
    HEART_RATE,
    HEART_RATE_MEASUREMENT
  );
}

function startHeartMeterNotification(device, callback) {
  BleClient.startNotifications(
    device.deviceId,
    HEART_RATE,
    HEART_RATE_MEASUREMENT,
    callback()
  );
}

function stopPowerMeterNotification(device) {
  BleClient.stopNotifications(
    device.deviceId,
    CYCLING_POWER,
    CYCLING_POWER_MEASUREMENT
  );
}

function startPowerMeterNotification(device, callback) {
  BleClient.startNotifications(
    device.deviceId,
    CYCLING_POWER,
    CYCLING_POWER_MEASUREMENT,
    callback()
  );
}

function setTargetPower(device, power, forceSet = false) {
  setTargetOpCounter++;
  if (power != lastPower || forceSet || setTargetOpCounter % 5 == 0) {
    lastPower = power;

    if (isFtmsDevice) {
      console.log("SET FTMS POWER");

      ftms.setTargetPower(device, power);
    } else if (isFecOverBle) {
      console.log("SET FEC POWER");
      fec.setTargetPower(device, power);
    } else {
      console.log("Trainer kann Target-Power nicht setzen");
    }

    setTargetOpCounter = 0;
  }
}

function setResistanceTarget(device, resistance, forceSet = false) {
  if (lastResistanceTarget != resistance || forceSet) {
    lastResistanceTarget = resistance;
    if (isFtmsDevice) {
      ftms.setResistanceTarget(device, resistance);
    } else if (isFecOverBle) {
      fec.setResistanceTarget(device, resistance);
    } else {
      console.log("Trainer kann Target-Resistance nicht setzen");
    }
  }
}

export {
  initialize,
  stopHeartMeterNotification,
  stopPowerMeterNotification,
  setTargetPower,
  doesDeviceSupportControl,
  initialSubscribe,
  setResistanceTarget,
  startHeartMeterNotification,
  startPowerMeterNotification,
  stopNotifications,
  ftms,
};
