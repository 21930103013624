<template>
  <div>
    Garmin Test Garmin User Token: {{ user.garminUserToken }}

    <button @click="connect()">Verbinden</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Garmin",
  data: function () {
    return {
      token: undefined,
      user: { garminUserToken: "Nicht verbunden" },
    };
  },
  methods: {
    connect: function () {
      console.log("CONNECt" + this.token);
      if (this.token && this.token.length > 0) {
        window.location.href =
          "https://connect.garmin.com/oauthConfirm?" +
          this.token +
          "&oauth_callback=" +
          this.$host +
          "/user/garmin/oauth/callback?action=step3";
      }
    },
  },
  mounted: async function () {
    this.user = (await axios.get(this.$host + "/user/me")).data;
    this.token = await (
      await axios.get(this.$host + "/user/garmin/oauth")
    ).data.oauth_token;
    console.log(this.token);
  },
};
</script>