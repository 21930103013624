<template>
  <div class="fluid-container">
    <router-link 
      class="back-button" 
      to="/radraum"
      style="position: fixed; z-index: 999999999;"
    >➜</router-link>

    <div class="row">
      <div class="col-lg-12">
        <!--Fuer Electron-->
        <div v-if="showDeviceList">
          <div class="overlayx"></div>
          <div class="device_list">
            <h3 class="top-spacer-25">Verbinden</h3>
            <div class="top-spacer-25" v-if="deviceList.length == 0">
              <div class="spinner-border text-primary" role="status"></div>
              <h4 class="top-spacer-15">Suche...</h4>
            </div>
            <div v-for="device of deviceList" :key="device.deviceId" class="row">
              <div class="col-6 top-spacer-25">
                <strong style="display: block" class="top-spacer-10">{{ device.deviceName }}</strong>
              </div>
              <div class="col-6 top-spacer-25">
                <button @click="pickDevice(device.deviceId)">Verbinden</button>
              </div>
            </div>

            <button class="top-spacer-100 closer" @click="closeDevicePicker()">Schließen</button
            ><!--TOOO:Terminate-->
          </div>
        </div>

        <div
          class="overlayx"
          v-if="(showOptionsStatus || showSensorStatus || disconnected) && $parent.workoutStarted"
        ></div>

        <div class="options" v-if="showOptionsStatus">
          <div class="centered" v-if="loaded">
            <img alt="PL logo" style="box-shadow: none" src="@/assets/images/PL-Club-Logo.png" />
          </div>
          <div class="spinner-border text-primary" role="status" v-if="!loaded" style="margin-bottom: 25px"></div>

          <div>
            <button @click="quitAndSaveWorkout()" class="closer">Beenden & Speichern</button>
            <hr />
            <button @click="showSensor()">Sensoren verbinden</button>

            <hr />

            <button class="delete" @click="preQuit = !preQuit">Beenden ohne Speichern</button>
            <div v-if="preQuit" class="top-spacer-25" style="border: 2px solid darkred; padding: 15px">
              <p>Möchtest du wirklich ohne Speichern das Workout verlassen?</p>
              <button class="delete" @click="quitWorkout()">Ohne Speichern Beenden!</button>
            </div>

            <button class="top-spacer-150" @click="showOptions()">Weiter</button>
          </div>
        </div>

        <div v-if="(showSensorStatus || disconnected) && $parent.workoutStarted" class="options">
          <div class="row">
            <div class="col-lg-12">
              <div v-if="disconnected" style="color: red; font-weight: bold">
                <h4>Ein Sensor hat die Verbindung verloren</h4>
              </div>
              <h4 class="centered">Leistungsmesser</h4>
              <div>
                {{ $parent.powerMeter ? $parent.powerMeter.name : "Kein Leistungsmesser" }}
              </div>
              <div>
                <button
                  style="background-color: #007bff; border-color: #007bff"
                  v-if="!blePowerConnecting && platform != 'electron'"
                  class="top-spacer-10"
                  @click="connectBluetoothPower()"
                  role="button"
                  v-bind:style="$parent.powerMeter ? 'background-color:#009933;border-color:#009933' : ''"
                >
                  <i class="fa fa-bluetooth" aria-hidden="true"></i>
                  <span>
                    Bluetooth
                    {{ !$parent.powerMeter ? "verbinden" : "verbunden" }}</span
                  >
                </button>

                <a
                  v-if="!blePowerConnecting && platform == 'electron'"
                  class="btn btn-primary top-spacer-10"
                  @click="getBluetoothPowerDeviceList()"
                  role="button"
                  v-bind:style="$parent.powerMeter ? 'background-color:#009933;border-color:#009933' : ''"
                >
                  <i class="fa fa-bluetooth" aria-hidden="true"></i>
                  <span>
                    Bluetooth
                    {{ !$parent.powerMeter ? "verbinden" : "verbunden" }}</span
                  >
                </a>
                <span v-if="blePowerConnecting">
                  <div class="spinner-border text-primary top-spacer-10" role="status"></div>
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <h4 class="centered"><br />Herzfrequenz</h4>

              <div>
                {{ $parent.heartMeter ? $parent.heartMeter.name : "Kein HR-Messer" }}
              </div>
              <div>
                <button
                  style="background-color: #007bff; border-color: #007bff"
                  v-if="!bleHrConnecting && platform != 'electron'"
                  @click="connectBluetoothHr()"
                  role="button"
                  class="top-spacer-10"
                  v-bind:style="$parent.heartMeter ? 'background-color:#009933;border-color:#009933' : ''"
                >
                  <i class="fa fa-bluetooth" aria-hidden="true"></i>
                  <span>
                    Bluetooth
                    {{ !$parent.heartMeter ? "verbinden" : "verbunden" }}</span
                  >
                </button>

                <a
                  v-if="!blePowerConnecting && platform == 'electron'"
                  class="btn btn-primary top-spacer-10"
                  v-bind:style="$parent.heartMeter ? 'background-color:#009933;border-color:#009933' : ''"
                  @click="getBluetoothHeartrateList()"
                  role="button"
                >
                  <i class="fa fa-bluetooth" aria-hidden="true"></i>
                  <span>
                    Bluetooth
                    {{ !$parent.heartMeter ? "verbinden" : "verbunden" }}</span
                  >
                </a>
                <span v-if="bleHrConnecting">
                  <div class="spinner-border text-primary top-spacer-10" role="status"></div>
                </span>
              </div>
            </div>
            <div class="col-lg-12 top-spacer-25">
              <h4 class="centered">Trittfrequenz</h4>

              <div>
                {{ $parent.cadenceMeter ? $parent.cadenceMeter.name : "Kein Trittfrequenzsensor" }}
              </div>
              <div>
                <button
                  style="background-color: #007bff; border-color: #007bff"
                  v-if="!bleCadenceConnecting && platform != 'electron'"
                  @click="connectBluetoothCadence()"
                  href="#"
                  role="button"
                  class="top-spacer-10"
                  v-bind:style="$parent.cadenceMeter ? 'background-color:#009933;border-color:#009933' : ''"
                >
                  <i class="fa fa-bluetooth" aria-hidden="true"></i>
                  <span>
                    Bluetooth
                    {{ !$parent.cadenceMeter ? "verbinden" : "verbunden" }}</span
                  >
                </button>

                <a
                  v-if="!blePowerConnecting && platform == 'electron'"
                  class="btn btn-primary top-spacer-10"
                  @click="getBluetoothCadenceList()"
                  v-bind:style="$parent.cadenceMeter ? 'background-color:#009933;border-color:#009933' : ''"
                  role="button"
                >
                  <i class="fa fa-bluetooth" aria-hidden="true"></i>
                  <span>
                    Bluetooth
                    {{ !$parent.cadenceMeter ? "verbinden" : "verbunden" }}</span
                  >
                </a>
                <span v-if="bleCadenceConnecting">
                  <div class="spinner-border text-primary top-spacer-10" role="status"></div>
                </span>
              </div>
            </div>
            <div
              class="col-lg-12 top-spacer-25"
              v-if="$parent.cadenceMeter || $parent.heartMeter || $parent.cadenceMeter"
            >
              <a @click="disconnectAllBle()">Bluetooth zurücksetzen</a>
            </div>
            <div class="col-lg-12 top-spacer-50">
              <button class="closer" @click="showSensor()">Zurück</button>
            </div>
          </div>
        </div>
        <div class="row" v-show="!$parent.workoutStarted">
          <div class="col-12 top-spacer-10" v-if="warnPremiumExpiration" style="text-align: center; color: #fff">
            <div
              class="col-12"
              style="
                background-size: 200% auto;
                padding: 30px;
                background: #000;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
              "
            >
              <br />

              <strong>Hinweis:</strong> Dein Zugang ist abgelaufen. Um dauerhaft alle Funktionen nutzen zu können,
              kannst du über die App ein Abo abschließen.

              <h2>Der Radraum steht nur unseren zahlenden Club Mitgliedern zur Verfügung.</h2>
            </div>
          </div>

          <!-- START // EINSTIEG -->
          <div class="col-lg-12" style="text-align: center" v-if="!warnPremiumExpiration">
            <div class="row top-spacer-50">
              <div class="col-lg-1"></div>
              <div class="col-lg-7">
                <a href="/#/radraum" @click="$parent.workout_id = undefined">
                  <button class="pointer" style="width: 200px; padding: 10px">❮ Workout wechseln</button>
                </a>

                <div v-if="startTimeInSeconds" class="top-spacer-50">
                  <h4>Workout wird verarbeitet...</h4>
                  <div class="spinner-border text-primary" role="status"></div>
                </div>

                <div v-if="workoutAnalysisId" class="top-spacer-50">
                  <h4>Workout wurde verarbeitet!</h4>
                  <a v-bind:href="'/#/auswertung/workout/details/' + workoutAnalysisId">
                    <button class="pointer thirdary" style="width: 200px; padding: 10px">Zur Analyse</button>
                  </a>

                  <div v-if="platform == 'web'">
                    <div class="top-spacer-75">
                      <button class="closer pointer" style="width: 200px; padding: 10px" @click="garminUploadWeb">
                        Bei Garmin hochladen
                      </button>
                    </div>
                    <div class="top-spacer-10 centered">
                      <small style="display: inline-block; max-width: 300px">
                        Garmin erlaubt keinen automatischen Upload. Du gelangst zur Upload Seite und kannst dort aus
                        deinem Download-Ordner das Workout hochladen. Eventuell musst du Popups zulassen
                      </small>
                    </div>
                  </div>

                  <div v-if="platform != 'web'">
                    <div class="top-spacer-75">
                      <h4>Bei Garmin hochladen</h4>

                      <button class="closer pointer" style="width: 200px; padding: 10px" @click="garminDownload">
                        1. Schritt: Download
                      </button>
                      <br /><br />
                      <button class="closer pointer" style="width: 200px; padding: 10px" @click="garminUpload">
                        2. Schritt: Upload
                      </button>
                    </div>
                    <div class="top-spacer-10 centered">
                      <small style="display: inline-block; max-width: 300px">
                        Garmin erlaubt keinen automatischen Upload. Du musst die Datei zunächst speichern und und kannst
                        dann im zweiten Schritt bei Garmin aus deinem Download-Ordner das Workout hochladen.
                      </small>
                    </div>
                  </div>
                </div>

                <div class="top-spacer-50">
                  <span v-for="value in $parent.workout.workoutFile" :key="value.id">
                    <svg
                      v-if="value.type == 'SteadyState'"
                      class="workoutBuilderBlock"
                      v-bind:width="computeWidth(value.Duration, $parent.workout.workoutFile)"
                      v-bind:height="workoutBuilderHeight($parent.workout.workoutFile)"
                    >
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="100 + '%'"
                        v-bind:height="value.Power"
                        v-bind:style="'fill:' + colorForPower(value.Power)"
                      />
                    </svg>

                    <svg
                      v-if="value.type == 'Ramp'"
                      class="workoutBuilderBlock"
                      v-bind:width="computeWidth(value.Duration, $parent.workout.workoutFile)"
                      v-bind:height="workoutBuilderHeight($parent.workout.workoutFile)"
                    >
                      <rect
                        v-for="index in 40"
                        :key="index"
                        v-bind:id="value.id + '-' + index"
                        v-bind:width="100 / 40 + '%'"
                        v-bind:x="(100 / 40) * index - 2.5 + '%'"
                        v-bind:height="getRampRectHeight(40, value.PowerLow, value.PowerHigh, index)"
                        v-bind:style="
                          'fill:' + colorForPower(getRampRectHeight(40, value.PowerLow, value.PowerHigh, index))
                        "
                      />
                    </svg>

                    <svg
                      v-if="value.type == 'FreeRide'"
                      class="workoutBuilderBlock"
                      v-bind:width="computeWidth(value.Duration, $parent.workout.workoutFile)"
                      v-bind:height="92"
                    >
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="'100%'"
                        v-bind:height="92"
                        style="fill: rgba(94, 110, 255, 0.5)"
                      />
                    </svg>

                    <span v-if="value.type == 'IntervalsT'">
                      <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                        <svg
                          class="workoutBuilderBlock"
                          v-bind:width="computeWidth(value.OnDuration, $parent.workout.workoutFile)"
                          v-bind:height="workoutBuilderHeight($parent.workout.workoutFile)"
                        >
                          <rect
                            v-bind:id="value.id"
                            v-bind:width="'100%'"
                            v-bind:height="value.OnPower"
                            v-bind:style="'fill:' + colorForPower(value.OnPower)"
                          />
                        </svg>
                        <svg
                          class="workoutBuilderBlock"
                          v-bind:width="computeWidth(value.OffDuration, $parent.workout.workoutFile)"
                          v-bind:height="workoutBuilderHeight($parent.workout.workoutFile)"
                        >
                          <rect
                            v-bind:id="value.id"
                            v-bind:width="'100%'"
                            v-bind:height="value.OffPower"
                            v-bind:style="'fill:' + colorForPower(value.OffPower)"
                          />
                        </svg>
                      </span>
                    </span>
                  </span>
                  <h3 class="top-spacer-25" v-if="$parent.workout.name">
                    {{ $parent.workout.name }}
                  </h3>

                  <div
                    class="top-spacer-25 spinner-border text-primary"
                    role="status"
                    v-if="!loaded"
                    style="margin-bottom: 25px"
                  ></div>

                  <div class="content_wrapper" v-if="$parent.workout.workoutFile">
                    <div class="col-lg-12">
                      <p
                        class="top-spacer-25"
                        style="white-space: pre-line; text-align: left"
                        v-if="$parent.workout.description"
                      >
                        {{ $parent.workout.description }}
                      </p>
                    </div>
                    <div style="overflow: auto">
                      <table class="table top-spacer-50" style="text-align: left">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Typ</th>

                            <th scope="col">Segment</th>
                            <th scope="col">Trittfrequenz</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(segment, index) in $parent.workout.workoutFile" :key="segment.id + index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                              <span v-if="segment.type == 'Ramp'">Rampe </span>
                              <span v-if="segment.type == 'SteadyState'"> Steady State </span>
                              <span v-if="segment.type == 'IntervalsT'"> Intervalle </span>
                              <span v-if="segment.type == 'FreeRide'"> Beliebig </span>
                            </td>
                            <td>
                              <span v-if="segment.type == 'Ramp'">
                                {{ betterformattedTime(segment.Duration) }} von {{ segment.PowerLow }}%
                                {{ computeIntensityValues(segment.PowerLow) }}
                                bis {{ segment.PowerHigh }}%
                                {{ computeIntensityValues(segment.PowerHigh) }}
                              </span>
                              <span v-if="segment.type == 'SteadyState'">
                                {{ betterformattedTime(segment.Duration) }} @ {{ segment.Power }}%
                                {{ computeIntensityValues(segment.Power) }}
                              </span>
                              <span v-if="segment.type == 'IntervalsT'">
                                Wiederhole {{ segment.Repeat }} mal<br />
                                {{ betterformattedTime(segment.OnDuration) }} @ {{ segment.OnPower }}%
                                {{ computeIntensityValues(segment.OnPower) }}
                                und
                                {{ betterformattedTime(segment.OffDuration) }} @ {{ segment.OffPower }}%
                                {{ computeIntensityValues(segment.OffPower) }}
                              </span>
                              <span v-if="segment.type == 'FreeRide'">
                                {{ betterformattedTime(segment.Duration) }}
                              </span>
                            </td>
                            <td>
                              <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                              <span v-if="!segment.Cadence">Beliebig</span>
                              <span v-if="segment.CadenceResting != undefined">
                                /
                                <span v-if="segment.CadenceResting > 0">{{ segment.CadenceResting }}</span>
                                <span v-if="segment.CadenceResting == 0">Beliebig</span>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-lg-12" style="text-align: center" v-if="$parent.workout.workoutFile">
                      <div
                        class="top-spacer-50"
                        v-if="!$parent.cadenceMeter && !$parent.powerMeter && !$parent.heartMeter"
                      >
                        <hr />
                        <strong style="color: red"
                          >Noch keinen Sensor verbunden. Verbinde deine Sensoren für das beste Training.</strong
                        ><br />
                        <div v-if="platform != 'web'">
                          Klicke dafür auf einen der "Bluetooth verbinden"-Buttons unten
                        </div>
                        <br />
                        Damit der ERG-Modus funktioniert, muss dein Smarttrainer FTMS oder FEC-over-BLE unterstützen.
                        Die meisten neueren Smarttrainer unterstützen FTMS, ggf. musst du aber die Firmware des Trainers
                        aktualisieren (z.B. bei Wahoo).
                        <hr />
                      </div>

                      <div
                        v-if="$parent.cadenceMeter || $parent.powerMeter || $parent.heartMeter"
                        class="top-spacer-25"
                      ></div>
                      <div v-if="$parent.controlDevice">
                        <strong>ERG Mode aktivieren?</strong><br />
                        Wenn du den ERG-Modus aktivierst, steuern wir deinen Trainer automatisch auf die geforderten
                        Wattwerte<br />
                        <input type="checkbox" v-model="$parent.erg_mode" v-on:change="forcePowerUpdate" />
                      </div>
                      <div style="font-weight: bold; margin-bottom: 20px; text-align: justify" v-if="platform == 'web'">
                        Achtung: Wenn das Browserfenster in den Hintergrund kommt, kann es passieren, dass der Browser
                        die Steuerung unterbindet. Am besten ist es, das Fenster geöffnet zu halten. Wir haben dies den
                        Chrome-Entwicklern bereits gemeldet und die Behebung dieses Verhaltens wurde in den
                        Entwicklungsbacklog aufgenommen.
                      </div>
                      <div
                        style="margin-bottom: 20px; text-align: justify"
                        v-if="platform != 'web' && platform != 'electron'"
                      >
                        <strong>Achtung:</strong> Wenn du die App (insbesondere bei Android) längere Zeit verlässt oder
                        dein Gerät in den Standby geht, kann es passieren, dass dein System unsere App beendet und das
                        Workout verloren geht. Darauf haben wir leider keinen Einfluss. Am besten ist es, die App
                        geöffnet zu halten oder nur für kürzere Zeiträume zu verlassen.
                      </div>

                      <button
                        @click="startWorkout()"
                        style="padding: 8px 30px; font-size: 18px; margin-bottom: 50px"
                        v-if="!blePowerConnecting && !bleCadenceConnecting && !bleHrConnecting"
                      >
                        Workout starten
                      </button>
                      <div
                        style="padding: 8px 30px; font-size: 18px; margin-bottom: 50px"
                        v-if="blePowerConnecting || bleCadenceConnecting || bleHrConnecting"
                      >
                        Verbinde Bluetooth...
                      </div>
                    </div>
                  </div>
                </div>

                <div class="top-spacer-25" v-if="platform == 'android' && $parent.workout">
                  Die .mrc-Datei und das Zwift Workout File kannst du im Browser am Computer runterladen
                </div>

                <div class="top-spacer-25" v-if="platform != 'android'">
                  <a
                    style="font-size: 12px"
                    v-if="$parent.workout"
                    v-bind:href="$host + '/workout/download/' + $parent.workout._id"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                    &nbsp; Zwift Workout File herunterladen
                  </a>
                  &nbsp; // &nbsp;
                  <a
                    v-if="$parent.workout"
                    style="font-size: 12px"
                    v-bind:href="$host + '/workout/download/' + $parent.workout._id + '/mrc'"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                    &nbsp; .mrc herunterladen
                  </a>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="row sticky-top">
                  <div class="col-lg-12">
                    <div class="top-spacer-25 d-block d-lg-none"></div>
                    <span v-if="platform == 'web'">
                      <h2 class="centered">Hinweis!</h2>
                      <div
                        v-if="!bluetoothAvailable"
                        style="margin: 20px 0; color: red; font-weight: bold; border: 3px solid red; padding: 20px"
                      >
                        Dein Browser unterstützt keine Bluetooth-Verbindungen!<br /><br />
                        Am Computer kannst du Chrome verwenden. Auf dem iPad oder iPhone musst du dir die Apps aus dem
                        App Store laden. Auch auf Android Geräten empfehlen wir dir den App-Download.
                      </div>
                      <div>
                        Damit du den Radraum am Computer nutzen kannst, muss dein Browser Bluetooth unterstützen. Wir
                        empfehlen Google Chrome. Firefox und Safari unterstützen leider keine Bluetooth-Verbindungen.<br />
                        <div v-if="platform == 'web'">
                          <strong>Tipp: Lade dir die App herunter!</strong>
                        </div>
                      </div>
                    </span>
                    <div
                      style="font-weight: bold; margin-bottom: 20px; text-align: justify"
                      v-if="platform == 'android'"
                    >
                      Damit Bluetooth Verbindungen möglich sind, fordert Android deinen Standort an. Ohne die
                      Berechtigung können wir keine Verbindung aufbauen. Wir fragen deinen Standort über die
                      Berechtigung nicht ab und speichern diesen auch nicht!<br />
                      Gegebenfalls musst du die Berechtigung in den Systemeinstellung setzen!
                    </div>

                    <h2 class="centered top-spacer-50">Leistungsmesser</h2>
                    <div>
                      {{ $parent.powerMeter ? $parent.powerMeter.name : "Kein Leistungsmesser" }}
                    </div>
                    <div class="top-spacer-25">
                      <a
                        v-if="!blePowerConnecting && platform != 'electron'"
                        class="btn btn-primary"
                        @click="connectBluetoothPower()"
                        v-bind:style="$parent.powerMeter ? 'background-color:#009933;border-color:#009933' : ''"
                        role="button"
                      >
                        <i class="fa fa-bluetooth" aria-hidden="true"></i>
                        <span>
                          Bluetooth
                          {{ !$parent.powerMeter ? "verbinden" : "verbunden" }}</span
                        >
                      </a>

                      <a
                        v-if="!blePowerConnecting && platform == 'electron'"
                        class="btn btn-primary"
                        @click="getBluetoothPowerDeviceList()"
                        v-bind:style="$parent.powerMeter ? 'background-color:#009933;border-color:#009933' : ''"
                        role="button"
                      >
                        <i class="fa fa-bluetooth" aria-hidden="true"></i>
                        <span>
                          Bluetooth
                          {{ !$parent.powerMeter ? "verbinden" : "verbunden" }}</span
                        >
                      </a>
                      <span v-if="blePowerConnecting">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Verbinden...</span>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h2 class="centered top-spacer-25"><br />Herzfrequenz</h2>

                    <div>
                      {{ $parent.heartMeter ? $parent.heartMeter.name : "Kein HR-Messer" }}
                    </div>
                    <div class="top-spacer-25">
                      <a
                        id="btn-bluetooth-device-hr"
                        v-if="!bleHrConnecting && platform != 'electron'"
                        class="btn btn-primary"
                        v-bind:style="$parent.heartMeter ? 'background-color:#009933;border-color:#009933' : ''"
                        @click="connectBluetoothHr()"
                        role="button"
                      >
                        <i class="fa fa-bluetooth" aria-hidden="true"></i>
                        <span id="btn-bluetooth-device-txt-hr">
                          Bluetooth
                          {{ !$parent.heartMeter ? "verbinden" : "verbunden" }}</span
                        >
                      </a>

                      <a
                        v-bind:style="$parent.heartMeter ? 'background-color:#009933;border-color:#009933' : ''"
                        v-if="!bleHrConnecting && platform == 'electron'"
                        class="btn btn-primary"
                        @click="getBluetoothHeartrateList()"
                        role="button"
                      >
                        <i class="fa fa-bluetooth" aria-hidden="true"></i>
                        <span>
                          Bluetooth
                          {{ !$parent.heartMeter ? "verbinden" : "verbunden" }}</span
                        >
                      </a>
                      <span v-if="bleHrConnecting">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Verbinden...</span>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h2 class="centered top-spacer-50">Trittfrequenz</h2>

                    <div>
                      {{ $parent.cadenceMeter ? $parent.cadenceMeter.name : "Kein Trittfrequenzsensor" }}
                    </div>
                    <div class="top-spacer-25">
                      <a
                        v-bind:style="$parent.cadenceMeter ? 'background-color:#009933;border-color:#009933' : ''"
                        id="btn-bluetooth-device-cadence"
                        v-if="!bleCadenceConnecting && platform != 'electron'"
                        class="btn btn-primary"
                        @click="connectBluetoothCadence()"
                        role="button"
                      >
                        <i class="fa fa-bluetooth" aria-hidden="true"></i>
                        <span id="btn-bluetooth-device-txt-cadence">
                          Bluetooth
                          {{ !$parent.cadenceMeter ? "verbinden" : "verbunden" }}</span
                        >
                      </a>

                      <a
                        v-bind:style="$parent.cadenceMeter ? 'background-color:#009933;border-color:#009933' : ''"
                        v-if="!bleCadenceConnecting && platform == 'electron'"
                        class="btn btn-primary"
                        @click="getBluetoothCadenceList()"
                        role="button"
                      >
                        <i class="fa fa-bluetooth" aria-hidden="true"></i>
                        <span>
                          Bluetooth
                          {{ !$parent.cadenceMeter ? "verbinden" : "verbunden" }}</span
                        >
                      </a>
                      <span v-if="bleCadenceConnecting">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Verbinden...</span>
                        </div>
                      </span>
                    </div>
                    <div
                      class="col-lg-12 top-spacer-25"
                      v-if="$parent.cadenceMeter || $parent.heartMeter || $parent.cadenceMeter"
                    >
                      <a @click="disconnectAllBle()">Bluetooth zurücksetzen</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="radraum-exercise" class="row" v-if="$parent.workoutStarted">
          <div
            v-show="$parent.timer > totalWorkoutLength($parent.workout.workoutFile) && continueFreeride == false"
            class="col-lg-12"
            style="z-index: 9999999999999999999999999999"
          >
            <div class="row top-spacer-50">
              <div
                class="col-lg-12 centered"
                :style="{
                  height: '100%',
                  width: '100%',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: 'fixed',
                  borderRadius: '10px',
                  paddingBottom: '95px',

                  background:
                    'url(' + 'https://assets.pushinglimits.club/couch.webp' + ') no-repeat center/cover fixed ',
                }"
              >
                <h1
                  class="cat-heading-medium centered top-spacer-50"
                  style="color: #fff; text-shadow: 1px 1px 6px black"
                >
                  Geschafft! <br /><small>Und jetzt ab auf die Couch.</small>
                </h1>
                <br />
                <button
                  @click="quitAndSaveWorkout()"
                  class="btn btn-primary"
                  style="
                    width: 300px;
                    padding: 10px 30px;
                    font-size: 20px;
                    margin-top: 30px;
                    box-shadow: 1px 1px 2px black;
                    text-shadow: 1px 1px 2px black;
                  "
                >
                  Speichern und Beenden
                </button>
                <br />

                <button
                  @click="preQuit = !preQuit"
                  class="delete"
                  style="
                    width: 300px;
                    padding: 5px 30px;
                    font-size: 20px;
                    margin-top: 50px;
                    box-shadow: 1px 1px 2px black;
                    text-shadow: 1px 1px 2px black;
                  "
                >
                  <small>Beenden ohne Speichern</small>
                </button>
                <div v-if="preQuit" class="top-spacer-25" style="padding: 15px">
                  <h4 style="color: #fff; text-shadow: 1px 1px 2px black">
                    Möchtest du wirklich ohne Speichern das Workout verlassen?
                  </h4>
                  <button
                    style="
                      width: 300px;
                      padding: 5px 30px;
                      box-shadow: 1px 1px 6px black;
                      text-shadow: 1px 1px 6px black;
                    "
                    class="delete"
                    @click="quitWorkout()"
                  >
                    Beenden
                  </button>
                </div>
                <br />
                <button
                  @click="continueFreeride = true"
                  class="closer top-spacer-50"
                  style="
                    width: 300px;
                    padding: 5px 30px;
                    font-size: 20px;
                    margin-top: 50px;
                    box-shadow: 1px 1px 2px black;
                    text-shadow: 1px 1px 2px black;
                  "
                >
                  <small>Weiter fahren!</small>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-6">
                <!--
                <img
                  src="@/assets/images/couch.jpg"
                  class="top-spacer-75 hero-img"
                />-->
              </div>
              <div class="col-lg-3"></div>
            </div>
          </div>
          <div class="col-lg-12 centered top-spacer-10">
            <div class="row" style="margin: 0; z-index: 2147483000" v-if="$parent.workoutStarted">
              <div class="col-lg-12">
                <!-- TODO OVERLAY SO IM WATTRAUM!!!-->

                <div class="container" style="padding: 0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="metrics-container">
                        <div class="row" style="font-size: 24px">
                          <div v-if="errorMessage.length > 0" class="overlayed">
                            <span style="color: #fff"> {{ errorMessage }}</span>
                          </div>

                          <div class="col-lg-3 col-6 d-md-none">
                            <div class="metric-card">
                              <div class="metric-label">ZIEL</div>
                              <div class="metric-value">{{ $parent.watts_should }}w</div>
                            </div>
                            <!-- Add progress bar for mobile only -->
                            <div class="power-progress d-md-none">
                              <div
                                class="progress"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Genauigkeit"
                              >
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  v-bind:style="
                                    'width:' +
                                    powerBarProgress($parent.watts - $parent.watts_should) +
                                    '%;background:' +
                                    powerBarColor($parent.watts - $parent.watts_should) +
                                    '!important'
                                  "
                                ></div>
                              </div>
                            </div>
                          </div>

                          <div class="col-6 col-lg-3">
                            <div class="metric-card">
                              <div class="metric-label">BPM</div>
                              <div class="metric-value" :class="{ pulse: $parent.heart > 0 }">
                                {{ $parent.heart }}
                              </div>
                            </div>
                            <!-- Add RPE progress bar for mobile -->
                            <div class="rpe-progress d-md-none" v-if="$parent.workoutTimes[$parent.timer]">
                              <div
                                class="progress"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="RPE / Anstrengung"
                              >
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  v-bind:style="
                                    'width:' +
                                    wattsShouldToRpe($parent.workoutTimes[$parent.timer].intensity) * 10 +
                                    '%;background:' +
                                    getRpeColor($parent.workoutTimes[$parent.timer].intensity) +
                                    '!important'
                                  "
                                ></div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3 col-6 d-md-none">
                            <div class="metric-card">
                              <div class="metric-label">IST</div>
                              <div class="metric-value">{{ $parent.watts }}w</div>
                            </div>
                          </div>

                          <div class="col-6 col-lg-3">
                            <div class="metric-card">
                              <div class="metric-label">RPM</div>
                              <div class="metric-value">
                                {{ $parent.cadence
                                }}<span v-if="$parent.cadence_should" class="metric-target"
                                  >/{{ $parent.cadence_should }}</span
                                >
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3 col-6">
                            <div class="metric-card">
                              <div class="metric-label">SEGMENT</div>
                              <div class="metric-value">
                                <div v-if="!$parent.workoutTimes[$parent.timer]">00:00</div>
                                <div v-else-if="$parent.workoutTimes[$parent.timer].time_left_step < 3600">
                                  {{
                                    $moment()
                                      .startOf("day")
                                      .seconds($parent.workoutTimes[$parent.timer].time_left_step || 0)
                                      .format("mm:ss")
                                  }}
                                </div>
                                <div v-else>
                                  {{
                                    $moment()
                                      .startOf("day")
                                      .seconds($parent.workoutTimes[$parent.timer].time_left_step || 0)
                                      .format("HH:mm:ss")
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3 col-6">
                            <div
                              class="metric-card"
                              @click="$parent.timer_mode = $parent.timer_mode === 'elapsed' ? 'remaining' : 'elapsed'"
                              style="cursor: pointer"
                            >
                              <div class="metric-label">
                                WORKOUT
                                <i
                                  class="fa"
                                  :class="$parent.timer_mode === 'elapsed' ? 'fa-arrow-up' : 'fa-arrow-down'"
                                ></i>
                              </div>
                              <div class="metric-value">
                                <template
                                  v-if="
                                    $parent.timer_mode === 'remaining' &&
                                    $parent.timer < totalWorkoutLength($parent.workout.workoutFile)
                                  "
                                >
                                  {{
                                    $moment()
                                      .startOf("day")
                                      .seconds(getTimeLeft() || 0)
                                      .format("HH:mm:ss")
                                  }}
                                </template>
                                <template v-else>
                                  {{
                                    $moment()
                                      .startOf("day")
                                      .seconds($parent.timer || 0)
                                      .format("HH:mm:ss")
                                  }}
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="d-none d-lg-block top-spacer-10"></div>
                    <div class="col-lg-12 "
                     style="background: #fff;  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
 border-radius: 0; padding: 15px 15px">
 
                      <div class="row">
                        
                        <div class="col-lg-8 col-md-6">
                          <workout-line-chart-few-seconds
                            class="w-line-chart"
                            style="margin-left: -5px; height: 110px; z-index: 200"
                            v-bind:labels="$parent.labels_current"
                            v-bind:adjustFactor="adjust_power"
                            v-bind:done="$parent.done_current"
                            v-bind:chart_watts_should="$parent.chart_watts_should_current"
                          />
                        </div>
                        <div class="col-lg-4 col-md-6 d-md-block d-none">
                          <div class="col-lg-12 top-spacer-10" style="font-size: 32px">
                            <span style="padding: 0 15px">
                              <strong
                                >{{ $parent.watts }}w<span v-if="$parent.watts_should">/{{ $parent.watts_should }}</span
                                ><span v-if="$parent.watts_should">w</span></strong
                              ></span
                            >
                            <div class="col-lg-12" style="font-size: 48px" v-if="$parent.watts_should != 0">
                              <div style="margin: auto; width: 270px">
                                <div class="progress top-spacer-5">
                                  <div
                                    class="progress-bar"
                                    role="progressbar"
                                    v-bind:style="
                                      'width:' +
                                      powerBarProgress($parent.watts - $parent.watts_should) +
                                      '%;background:' +
                                      powerBarColor($parent.watts - $parent.watts_should) +
                                      '!important'
                                    "
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div
                              style="font-size: 18px; padding-top: 8px; margin: 0"
                              v-if="$parent.workoutTimes[$parent.timer]"
                            >
                              RPE:
                              {{ wattsShouldToRpe($parent.workoutTimes[$parent.timer].intensity) }}/10
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-12 top-spacer-10"
                      style="background: #fff; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);  padding: 15px 15px"
                    >
                      <workout-line-chart
                        class="w-line-chart"
                        style="margin-left: -5px; height: 200px; z-index: 200"
                        v-bind:labels="$parent.labels"
                        v-bind:done="$parent.done"
                        v-bind:chart_watts_should="$parent.chart_watts_should"
                        v-bind:hrdone="$parent.hrdone"
                        :key="chartKey"
                      />
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div class="stats-container top-spacer-10">
                          <div class="stats-grid">
                            <!-- Current Segment Stats -->
                            <div class="stat-item">
                              <div class="stat-label">Segment Power</div>
                              <div class="stat-value">
                                {{ getCurrentSegmentAvgPower }}w
                                <small v-if="getCurrentSegmentTargetPower" class="stat-target"
                                  >/{{ getCurrentSegmentTargetPower }}w</small
                                >
                              </div>
                            </div>
                            <div class="stat-item">
                              <div class="stat-label">Segment HR</div>
                              <div class="stat-value">
                                {{ getCurrentSegmentAvgHr }}
                                <small class="stat-unit">bpm</small>
                              </div>
                            </div>

                            <!-- Workout Stats -->
                            <div class="stat-item">
                              <div class="stat-label">Workout Power</div>
                              <div class="stat-value">
                                {{ getWorkoutAvgPower }}w
                                <small v-if="getWorkoutTargetPower" class="stat-target"
                                  >/{{ getWorkoutTargetPower }}w</small
                                >
                              </div>
                            </div>
                            <div class="stat-item">
                              <div class="stat-label">Workout HR</div>
                              <div class="stat-value">
                                {{ getWorkoutAvgHr }}
                                <small class="stat-unit">bpm</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" style="margin: 0;">
                      <div class="col-lg-6" style=" box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);background: #fff;">
                        <div>
                          <br />
                          <div v-if="$parent.timer > totalWorkoutLength($parent.workout.workoutFile)">
                            <h4 style="margin-bottom: 15px">Workout beendet, freie Fahrt!</h4>
                          </div>

                          <div v-for="(segment, index) in $parent.workout.workoutFile" :key="segment.id + index">
                            <span v-if="show_next_step && index - 1 == active_segment" class="segment_text centered">
                              <div>
                                <h4>Als Nächstes</h4>
                                <span v-if="segment.type == 'Ramp'">
                                  {{ betterformattedTime(segment.Duration) }}
                                  von
                                  {{ Math.round(segment.PowerLow * (adjust_power / 100)) }}%
                                  {{ computeIntensityValues(segment.PowerLow) }}
                                  bis
                                  {{ Math.round(segment.PowerHigh * (adjust_power / 100)) }}%
                                  {{ computeIntensityValues(segment.PowerHigh) }}
                                  <br />
                                  Trittfrequenz:
                                  <span v-if="segment.Cadence > 0">{{ segment.Cadence }})</span>
                                  <span v-if="!(segment.Cadence > 0)">Beliebig</span>
                                </span>
                                <span v-if="segment.type == 'SteadyState'">
                                  {{ betterformattedTime(segment.Duration) }} @
                                  {{ Math.round(segment.Power * (adjust_power / 100)) }}%
                                  {{ computeIntensityValues(segment.Power) }}
                                  <br />Trittfrequenz:
                                  <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                                  <span v-if="!(segment.Cadence > 0)">Beliebig</span>
                                </span>
                                <span v-if="segment.type == 'IntervalsT' && $parent.workoutTimes[$parent.timer]">
                                  Wiederhole {{ segment.Repeat || 0 }}
                                  mal<br />
                                  <br />
                                  {{ betterformattedTime(segment.OnDuration) }}
                                  @
                                  {{ Math.round(segment.OnPower * (adjust_power / 100)) }}%
                                  {{ computeIntensityValues(segment.OnPower) }}
                                  <br /><small
                                    >Trittfrequenz:
                                    <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                                    <span v-if="!(segment.Cadence > 0)">Beliebig</span></small
                                  ><br />
                                  und<br />
                                  {{ betterformattedTime(segment.OffDuration) }}
                                  @
                                  {{ Math.round(segment.OffPower * (adjust_power / 100)) }}%
                                  {{ computeIntensityValues(segment.OffPower) }}
                                  <br /><small
                                    >Trittfrequenz:
                                    <span v-if="segment.CadenceResting > 0">{{ segment.CadenceResting }}</span>
                                    <span v-if="!(segment.CadenceResting > 0)">Beliebig</span></small
                                  >
                                </span>
                                <span v-if="segment.type == 'FreeRide'">
                                  {{ betterformattedTime(segment.Duration) }}
                                  <br />Trittfrequenz:
                                  <span v-if="segment.Cadence > 0">{{ segment.Cadence }})</span>
                                  <span v-if="!(segment.Cadence > 0)">Beliebig</span>
                                </span>
                              </div>
                            </span>
                          </div>

                          <table
                            v-if="$parent.workout.workoutFile"
                            class="table tablex"
                            style="color: #222; overflow-x: scroll; text-align: left; font-size: 14px"
                          >
                            <tbody>
                              <tr
                                style="margin-bottom: 8px"
                                v-for="(segment, index) in $parent.workout.workoutFile"
                                :key="segment.id + index"
                              >
                                <th scope="row" v-if="index >= active_segment">
                                  {{ index + 1 }}
                                </th>

                                <td v-if="index >= active_segment">
                                  <span v-if="segment.type == 'Ramp'">
                                    {{ betterformattedTime(segment.Duration) }}
                                    von
                                    {{ Math.round(segment.PowerLow * (adjust_power / 100)) }}%
                                    {{ computeIntensityValues(segment.PowerLow) }}
                                    bis
                                    {{ Math.round(segment.PowerHigh * (adjust_power / 100)) }}%
                                    {{ computeIntensityValues(segment.PowerHigh) }}
                                    <div class="pull-right" style="margin-left: 20px; font-size: 20px">
                                      <i
                                        @click="increaseDuration(index)"
                                        class="pointer fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>
                                      /
                                      <i
                                        @click="decreaseDuration(index)"
                                        class="pointer fa fa-minus-circle"
                                        aria-hidden="true"
                                      ></i
                                      ><br />
                                    </div>
                                    <br />Trittfrequenz:
                                    <span v-if="segment.Cadence > 0">{{ segment.Cadence }})</span>
                                    <span v-if="!(segment.Cadence > 0)">Beliebig</span>
                                  </span>
                                  <span v-if="segment.type == 'SteadyState'">
                                    {{ betterformattedTime(segment.Duration) }}
                                    @
                                    {{ Math.round(segment.Power * (adjust_power / 100)) }}%
                                    {{ computeIntensityValues(segment.Power) }}
                                    <div class="pull-right" style="font-size: 20px">
                                      <i
                                        @click="increaseDuration(index)"
                                        class="pointer fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>
                                      /
                                      <i
                                        @click="decreaseDuration(index)"
                                        class="pointer fa fa-minus-circle"
                                        aria-hidden="true"
                                      ></i
                                      ><br />
                                    </div>
                                    <br />Trittfrequenz:
                                    <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                                    <span v-if="!(segment.Cadence > 0)">Beliebig</span>
                                  </span>
                                  <span v-if="segment.type == 'IntervalsT' && $parent.workoutTimes[$parent.timer]">
                                    Wiederhole
                                    {{
                                      segment.Repeat +
                                      1 -
                                      (index == active_segment
                                        ? $parent.workoutTimes[$parent.timer].current_repeat || 1
                                        : 1)
                                    }}
                                    mal
                                    <div class="pull-right" style="font-size: 20px">
                                      <i
                                        @click="increaseRepetitions(index)"
                                        class="pointer fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>
                                      /
                                      <i
                                        @click="decreaseRepetitions(index)"
                                        class="pointer fa fa-minus-circle"
                                        aria-hidden="true"
                                      ></i
                                      ><br />
                                    </div>
                                    <hr />
                                    <strong>1)</strong>
                                    {{ betterformattedTime(segment.OnDuration) }}
                                    @
                                    {{ Math.round(segment.OnPower * (adjust_power / 100)) }}%
                                    {{ computeIntensityValues(segment.OnPower) }}
                                    <div class="pull-right" style="font-size: 20px">
                                      <i
                                        @click="increaseOnDuration(index)"
                                        class="pointer fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>
                                      /
                                      <i
                                        @click="decreaseOnDuration(index)"
                                        class="pointer fa fa-minus-circle"
                                        aria-hidden="true"
                                      ></i
                                      ><br />
                                    </div>
                                    <br />
                                    Trittfrequenz:
                                    <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                                    <span v-if="!(segment.Cadence > 0)">Beliebig</span>
                                    <br /><br />
                                    <strong>2)</strong>
                                    {{ betterformattedTime(segment.OffDuration) }}
                                    @
                                    {{ Math.round(segment.OffPower * (adjust_power / 100)) }}%
                                    {{ computeIntensityValues(segment.OffPower) }}
                                    <div class="pull-right" style="font-size: 20px">
                                      <i
                                        @click="increaseOffDuration(index)"
                                        class="pointer fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>
                                      /
                                      <i
                                        @click="decreaseOffDuration(index)"
                                        class="pointer fa fa-minus-circle"
                                        aria-hidden="true"
                                      ></i
                                      ><br />
                                    </div>
                                    <br />
                                    Trittfrequenz:
                                    <span v-if="segment.CadenceResting > 0">{{ segment.CadenceResting }}</span>
                                    <span v-if="!(segment.CadenceResting > 0)">Beliebig</span>
                                  </span>
                                  <span v-if="segment.type == 'FreeRide'">
                                    {{ betterformattedTime(segment.Duration) }}
                                    <br />Trittfrequenz:
                                    <span v-if="segment.Cadence > 0">{{ segment.Cadence }})</span>
                                    <span v-if="!(segment.Cadence > 0)">Beliebig</span>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-lg-6" style="padding-right: 0">
                        <div class="d-block d-md-none top-spacer-10"></div>
                        <div class="centered" v-if="loaded">
                          <div
                            class="sidemenuItem menu-b top-spacer-5"
                            style="cursor: pointer; margin-top: 0"
                            @click="showOptions()"
                          >
                            <div style="font-size: 22px">Menü</div>
                          </div>
                        </div>

                        <div class="centered" v-if="loaded">
                          <div
                            class="sidemenuItem menu-b top-spacer-5"
                            style="cursor: pointer; margin-top: 0"
                            @click="togglePause()"
                          >
                            <div style="font-size: 22px" v-if="!workoutPaused">Pausieren</div>
                            <div style="font-size: 22px" v-if="workoutPaused">Weiter</div>
                          </div>
                        </div>

                        <!-- Add skip button here -->
                        <div class="centered" v-if="loaded && $parent.workoutTimes && $parent.workoutTimes[$parent.timer]">
                          <div
                            class="sidemenuItem menu-b top-spacer-5"
                            style="cursor: pointer; margin-top: 0"
                            @click="skipCurrentStep()"
                          >
                            <div style="font-size: 22px">Schritt überspringen</div>
                          </div>
                        </div>

                        <!--deaktiviert!!!-->
                        <div
                          class="sidemenuItem"
                          v-if="_self.$parent.ridingState && _self.$parent.ridingState.bpm > 0 && false"
                        >
                          Herzfrequenz-Regulierung ab<br />
                          <div style="font-size: 35px">
                            <span @click="max_hr_bpm--" v-if="max_hr_bpm > 80" style="cursor: pointer">
                              <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                            </span>
                            {{ max_hr_bpm }}
                            <span @click="max_hr_bpm++" v-if="max_hr_bpm < 230" style="cursor: pointer">
                              <i class="fa fa-arrow-circle-up" aria-hidden="true"></i>
                            </span>
                          </div>
                        </div>

                        <div
                          class="sidemenuItem"
                          v-if="
                            (!$parent.erg_mode && $parent.controlDevice) ||
                            ($parent.erg_mode && $parent.watts_should == 0 && $parent.controlDevice)
                          "
                        >
                          Widerstand<br />

                          <div style="font-size: 38px">
                            <span @click="$parent.resistance--" v-if="$parent.resistance > 1" style="cursor: pointer">
                              <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                            </span>
                            {{ $parent.resistance }}
                            <span @click="$parent.resistance++" v-if="$parent.resistance < 100" style="cursor: pointer">
                              <i class="fa fa-arrow-circle-up" aria-hidden="true"></i>
                            </span>
                          </div>
                        </div>

                        <div class="sidemenuItem">
                          <!-- v-if="max_hr_adjustment == 100">-->
                          Zielleistung anpassen %<br />
                          <div style="font-size: 38px">
                            <span @click="adjustPower(-1)" v-if="adjust_power > 30" style="cursor: pointer">
                              <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                            </span>
                            {{ adjust_power }}
                            <span @click="adjustPower(1)" v-if="adjust_power < 250" style="cursor: pointer">
                              <i class="fa fa-arrow-circle-up" aria-hidden="true"></i>
                            </span>
                          </div>
                        </div>

                        <div class="sidemenuItem" v-if="$parent.controlDevice">
                          ERG-Mode<br />
                          <input type="checkbox" v-model="$parent.erg_mode" />
                        </div>

                        <div class="sidemenuItem" v-if="max_hr_adjustment == 100 && $parent.erg_mode">
                          Variabilität hinzufügen<br />
                          <input type="checkbox" v-model="road_feel" />
                        </div>

                        <div class="sidemenuItem">
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              v-model="play_audio"
                              class="custom-control-input"
                              id="customSwitch1"
                            />
                            <label
                              class="custom-control-label"
                              for="customSwitch1"
                              style="font-size: 18px; color: #222; cursor: pointer"
                              >Ton</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CyclingPowerMeasurementParser, CyclingSpeedCadenceMeasurementParser, FTMSMeasurementParser } from "@/js/Meter";
import { timeToString } from "@/js/utils";
import WorkoutLineChart from "@/components/chart/WorkoutLineChart.vue";
import WorkoutLineChartFewSeconds from "@/components/chart/WorkoutLineChartFewSeconds.vue";

import axios from "axios";

import * as controller from "../../js/controller";

import { BleClient, numberToUUID } from "@capacitor-community/bluetooth-le";
import { Capacitor } from "@capacitor/core";
import { KeepAwake } from "@capacitor-community/keep-awake";
import LZString from "lz-string";
import { Browser } from "@capacitor/browser";
export default {
  metaInfo() {
    return {
      title: (this.workout && this.workout.name) || "Radraum",
    };
  },
  name: "RadraumExercise",
  components: {
    WorkoutLineChart,
    WorkoutLineChartFewSeconds,
  },
  props: {
    bikeWorkouts: Array,
    id: String,
    workout_id: String,
  },
  data: function () {
    return {
      show_next_step: false,
      chartKey: 0,
      // Wenn das Workout zuende ist, kommt ein Popup zum Beenden des Workouts
      // Wenn der Nutzer angibt, dass er weiterfahren möchte, verschwindet es
      // Der Zustand wird ueber diese Variable gesteuert
      continueFreeride: false,

      // Damit ein Workout nicht aus Versehen verlassen wird,
      // ist das beenden 2-stufig. Das erste mal klicken setzt diese Variable
      // und zeigt den "richtigen" Beenden-Button
      preQuit: false,

      // Wenn ein Sensor disconnected, wird dieser Wert auf true gesetzt
      // das führt dazu, dass ein Popup erscheint, das den Nutzer darauf hinweist
      disconnected: false,

      // Web / iOS / Android / Electron?
      platform: Capacitor.getPlatform(),

      // Wenn ein Browser verwendet wird, der kein Bluetooth unterstützt, wird diese Variable false
      bluetoothAvailable: true,

      startTimeInSeconds: 0,

      // Darf der Radraum benutzt werden oder ist Premium / Test abgelaufen?
      warnPremiumExpiration: false,

      // fuer electron
      showDeviceList: false,
      deviceList: [],

      // Wird zur Berechnung der RPM verwendet
      lastCrankTime: 0,
      lastCrankRevolutions: 0,

      // User-setzbare Flag. Spielt einen Ton, fürs nächste Intervall
      // TODO: möglich, dass Sound anderer Anwendung nicht unterbrochen wird?
      play_audio: true,

      // kann verwendet werden, um den %-Widerstand zu ändern
      // 100 ist normaler Wert
      adjust_power: 100,

      played_countdown_times: {},

      // Zeige ggf. eine Fehlermeldung an!
      errorMessage: "",

      // Kurz vor Schluss wird ein Beenden Sound gespielt.
      // Beim ersten Spielen wird dieser Wert True, damit er kein 2. mal
      // abgespielt wird.
      played_complete_sound: false,

      // Die folgenden Parameter können genutzt werden, um den Widerstand
      // des Trainers zu steuern - zB. bei Erreichen einer bestimmten Herzfrequenz
      max_hr_bpm: -1, // wird genutzt, um gefordere Leistung zu reduzieren, wenn HR > als Wert wird
      max_hr_adjustment: 100, // adjustment aufgrund des max_hr_bpm (0-100, wobei 100 die eigentlich geforderte Leistung ist)
      road_feel_adjustment: 0,
      road_feel: false,

      intervalId: undefined,
      list: new Array(250),
      thresholdKey: 0,

      thresholds: {},

      time: 0,
      wo_duration: "",

      totalDuration: 0,

      active_segment: 0,

      // Connection Time
      bleHrConnecting: false,
      bleCadenceConnecting: false,
      blePowerConnecting: false,

      workoutPaused: false,
      showOptionsStatus: false,
      showSensorStatus: false,
      loaded: false,
      wakeLock: null,
      quit: false,

      workoutAnalysisId: 0,

      isCalendarWorkout: false,
      // for chart
    };
  },

  computed: {
    getCurrentSegmentAvgPower() {
      try {
        if (!this.$parent?.workoutTimes?.[this.$parent?.timer]) {
          return 0;
        }

        const currentStep = this.$parent.workoutTimes[this.$parent.timer].step;
        if (currentStep === undefined) return 0;

        const currentStepPowers = (this.$parent.done || []).filter(
          (_, index) => this.$parent.workoutTimes[index]?.step === currentStep
        );

        if (!currentStepPowers?.length) return 0;
        return Math.round(currentStepPowers.reduce((a, b) => (a || 0) + (b || 0), 0) / currentStepPowers.length);
      } catch (error) {
        console.error('Error calculating segment avg power:', error);
        return 0;
      }
    },

    getCurrentSegmentTargetPower() {
      try {
        return Math.round(this.$parent?.workoutTimes?.[this.$parent?.timer]?.watt || 0);
      } catch (error) {
        console.error('Error calculating segment target power:', error);
        return 0;
      }
    },

    getCurrentSegmentAvgHr() {
      try {
        if (!this.$parent?.workoutTimes?.[this.$parent?.timer]) {
          return 0;
        }

        const currentStep = this.$parent.workoutTimes[this.$parent.timer].step;
        if (currentStep === undefined) return 0;

        const currentStepHr = (this.$parent.hrdone || []).filter(
          (_, index) => this.$parent.workoutTimes[index]?.step === currentStep
        );

        if (!currentStepHr?.length) return 0;
        return Math.round(currentStepHr.reduce((a, b) => (a || 0) + (b || 0), 0) / currentStepHr.length);
      } catch (error) {
        console.error('Error calculating segment avg HR:', error);
        return 0;
      }
    },

    getWorkoutAvgPower() {
      try {
        const validPowers = (this.$parent.done || []).filter(power => power != null);
        if (!validPowers?.length) return 0;
        return Math.round(validPowers.reduce((a, b) => (a || 0) + (b || 0), 0) / validPowers.length);
      } catch (error) {
        console.error('Error calculating workout avg power:', error);
        return 0;
      }
    },

    getWorkoutTargetPower() {
      try {
        if (!this.$parent?.workoutTimes?.length || this.$parent.timer == null) return 0;

        const targetPowers = this.$parent.workoutTimes
          .slice(0, this.$parent.timer + 1)
          .filter(time => time?.watt != null)
          .map(time => time.watt);

        if (!targetPowers?.length) return 0;
        return Math.round(targetPowers.reduce((a, b) => (a || 0) + (b || 0), 0) / targetPowers.length);
      } catch (error) {
        console.error('Error calculating workout target power:', error);
        return 0;
      }
    },

    getWorkoutAvgHr() {
      try {
        const validHr = (this.$parent.hrdone || []).filter(hr => hr != null);
        if (!validHr?.length) return 0;
        return Math.round(validHr.reduce((a, b) => (a || 0) + (b || 0), 0) / validHr.length);
      } catch (error) {
        console.error('Error calculating workout avg HR:', error);
        return 0;
      }
    },
  },

  beforeDestroy: function () {
    console.log("destropy");
    /*
    if (this.$parent.intervalWorker) {
      this.$parent.intervalWorker.terminate();
    }*/
  },

  setScrollLock(lockIt) {
    // Only apply scroll lock on non-Android devices
    if (this.platform === "android") {
      return;
    }

    const el = document.body;
    var root = document.getElementsByTagName("body")[0];

    if (lockIt) {
      root.classList.add("scroll-bounce-lock");
      el.classList.add("scroll-bounce-lock");
      root.classList.add("locked");
      el.classList.add("locked");
    } else {
      root.classList.remove("scroll-bounce-lock");
      el.classList.remove("scroll-bounce-lock");
      root.classList.remove("locked");
      el.classList.remove("locked");
    }
  },

  mounted: async function () {
    const _self = this;

    console.log(this.$parent.workoutStarted, this.$parent.workout_id, this.workout_id);
    if ((!this.$parent.workoutStarted || !this.$parent.workout_id) && this.workout_id) {
      this.$parent.workout_id = this.workout_id;
    } else {
      this.errorMessage = "In laufendes Workout zurückgekehrt";
      console.log("laufendes Workout", this.workout_id);
      console.log(this.$route.query);
      if (this.$route.query.open_menu === "true") {
        this.onlyShowOptions();
      }

      setTimeout(() => {
        _self.errorMessage = "";
      }, 3000);
    }

    if (this.$parent.workoutStarted) {
      console.log("Relaunch...!");

      if (this.$parent.wasReset) {
        this.$parent.bluetoothInitialized = false;

        this.disconnected = true;
        this.$parent.heartMeter = null;
        this.$parent.powerMeter = null;
        this.$parent.cadenceMeter = null;

        this.$parent.wasReset = false;
      }

      this.startWorkout();
    }

    axios.get(_self.$host + "/user/me").then(function (res) {
      _self.user = res.data;

      if (
        !_self.$moment(_self.user.premiumAccountUntil).isAfter(_self.$moment()) &&
        !_self.$moment(_self.user.freeAccountUntil).isAfter(_self.$moment())
      ) {
        _self.warnPremiumExpiration = true;
      }
    });

    // Nach Tab-Wechsel, soll das Screenlock wieder erlangt werden
    document.addEventListener("visibilitychange", async () => {
      if (this.wakeLock !== null && document.visibilityState === "visible") {
        this.wakeLock = await navigator.wakeLock.request("screen");
      }
    });

    this.quit = false;

    if (this.$parent.workout_id) {
      const _self = this;

      axios
        .get(_self.$host + "/workout/" + this.$parent.workout_id || this.workout_id)
        .then(function (res) {
          _self.isCalendarWorkout = true;
          _self.workoutSelected(res.data);
          _self.loaded = true;
        })
        .catch(function () {
          // Nicht gefunden, also in Vorlagen schauen
          axios.get(_self.$host + "/workout/blueprints2/v2").then(function (response) {
            _self.workoutBlueprintsGlobal = response.data.global;
            _self.workoutBlueprintsUser = response.data.user;
            _self.allWorkoutsList = _self.workoutBlueprintsGlobal.concat(_self.workoutBlueprintsUser);

            _self.allWorkoutsList = _self.allWorkoutsList.filter(function (workout) {
              return workout.sport == "Radfahren";
            });

            const workout = _self.allWorkoutsList.find(function (element) {
              return element._id == _self.workout_id;
            });

            if (workout) {
              _self.workoutSelected(workout);
            } else {
              console.log("KEIN WORKOUT GEFUNDEN");
            }

            _self.loaded = true;
          });
        });
    }

    this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;

    this.max_hr_bpm = this.thresholds.maxHr;
    this.loaded = true;
    this.thresholdKey++;
  },

  methods: {
    async garminUploadWeb() {
      // Erstellt die URL für den Download
      const downloadUrl = this.$host + "/workout/" + this.workoutAnalysisId + "/tcxexport";
      // Die Garmin-Upload-Seite
      const garminUploadUrl = "https://connect.garmin.com/modern/import-data";

      // Erstellt ein neues <a> Element
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "workout" + this.workoutAnalysisId + ".tcx"; // Setzt den Dateinamen

      // Fügt das Element zum DOM hinzu
      document.body.appendChild(link);

      // Klickt auf das Element, um den Download zu starten
      link.click();

      // Entfernt das Element aus dem DOM
      document.body.removeChild(link);

      // Öffnet die Garmin-Upload-Seite in einem neuen Browser-Tab
      window.open(garminUploadUrl, "_blank");
    },

    async garminUpload() {
      const garminUploadUrl = "https://connect.garmin.com/modern/import-data";
      await Browser.open({ url: garminUploadUrl });
    },

    async garminDownload() {
      const downloadUrl = this.$host + "/workout/" + this.workoutAnalysisId + "/tcxexport";
      await Browser.open({ url: downloadUrl });
    },

    arrayBufferToBase64(buffer) {
      let binary = "";
      let bytes = new Uint8Array(buffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },

    disconnectAllBle() {
      this.$parent.bluetoothInitialized = false;

      this.disconnected = true;
      this.$parent.heartMeter = null;
      this.$parent.powerMeter = null;
      this.$parent.cadenceMeter = null;

      this.$parent.wasReset = false;
    },
    wattsShouldToRpe(intensity) {
      // return RPE with scale from 1-10
      // 1 = very easy
      // 10 = very hard
      // 0 = no RPE
      if (intensity == 0) {
        return 0;
      }

      if (intensity > 120) {
        return 10;
      }
      if (intensity > 105) {
        return 9;
      }
      if (intensity > 97) {
        return 8;
      }
      if (intensity > 90) {
        return 7;
      }
      if (intensity > 85) {
        return 6;
      }
      if (intensity > 79) {
        return 5;
      }
      if (intensity > 73) {
        return 4;
      }
      if (intensity > 57) {
        return 3;
      }
      if (intensity > 40) {
        return 2;
      } else {
        return 1;
      }
    },
    adjustPower(val) {
      this.adjust_power += val;
      this.$parent.workoutTimes = this.computeWorkoutTimes();
      this.chartKey++;
    },
    forcePowerUpdate() {
      const _self = this;

      // Bei Aenderungen an ERG_MODE muss ggfs. ein Update der Target-Power erzwungen werden
      // set Target Power sendet den Befehl nämlich nur nochmal zum Trainer, wenn die Watt-Zielwerte sich
      // veraendert haben. Wenn man zwischendurch wechselt, wird damit der Widerstand u.U. nicht erhoeht
      if (_self.$parent.watts_should > 0) {
        if (_self.$parent.controlDevice && _self.$parent.erg_mode) {
          controller.setTargetPower(
            _self.$parent.controlDevice,
            _self.$parent.watts_should,
            true // erzwinge update!
          );
        }
      }
    },
    powerBarColor(diff) {
      const percentage = this.powerBarProgress(diff);
      const col = "rgb(" + (180 - (percentage * 1.8 || 0)) + "," + (0 + (percentage * 1.8 || 0)) + ",0)";
      return col;
    },
    powerBarProgress(diff) {
      const percentage = Math.max(1, Math.min(100 - Math.abs(diff), 100));
      return percentage;
    },
    totalWorkOutDuration: function () {
      this.totalDuration = this.$parent.workoutTimes.length;
      return this.totalDuration;
    },
    setScrollLock(lockIt) {
      // Only apply scroll lock on non-Android devices
      if (this.platform === "android") {
        return;
      }

      const el = document.body;
      var root = document.getElementsByTagName("body")[0];

      if (lockIt) {
        root.classList.add("scroll-bounce-lock");
        el.classList.add("scroll-bounce-lock");
        root.classList.add("locked");
        el.classList.add("locked");
      } else {
        root.classList.remove("scroll-bounce-lock");
        el.classList.remove("scroll-bounce-lock");
        root.classList.remove("locked");
        el.classList.remove("locked");
      }
    },

    stopSensorNotifications() {
      try {
        controller.stopNotifications(this.$parent.controlDevice);
      } catch (err) {
        console.log("ERR STOP NOTIFICATIONS", err);
      }

      if (this.$parent.heartMeter) {
        controller.stopHeartMeterNotification(this.heartMeter);
      }

      if (this.$parent.powerMeter) {
        controller.stopPowerMeterNotification(this.$parent.powerMeter);
      }

      if (this.$parent.intervalWorker) {
        this.$parent.intervalWorker.terminate();
      }
    },

    reset: async function () {
      this.setScrollLock(false);
      this.loaded = false;
      this.preQuit = false;

      if (typeof this.wakeLock !== "undefined" && this.wakeLock != null) {
        this.wakeLock.release().then(() => {
          this.wakeLock = null;
        });
      }

      const sleep = async () => {
        try {
          await KeepAwake.allowSleep();
        } catch (err) {
          console.log("Kein Screen Lock moeglich");
        }
      };

      sleep();

      try {
        if (this.$parent.intervalWorker) {
          this.$parent.intervalWorker.terminate();
          this.$parent.intervalWorker = undefined;
        }
      } catch (err) {
        console.log("Fehler beim terminieren des Workers" + err);
      }

      this.$parent.heart = 0;
      this.$parent.cadence = 0;
      this.time = 0;
      this.wo_duration = "";
      this.$parent.watts = 0;
      this.$parent.watts_should = 150;
      this.$parent.powerSamples = [];

      this.$parent.workout_id = undefined;

      // for chart
      this.$parent.history = [];
      this.$parent.done = [];
      this.$parent.labels = [];
      this.$parent.hrdone = [];
      this.$parent.chart_watts_should = [];
      this.$parent.chart_watts_should_current = [];
      this.$parent.done_current = [];
      this.label_current = [];
      // Connection Time
      this.bleHrConnecting = false;
      this.bleCadenceConnecting = false;
      this.blePowerConnecting = false;
      this.totalDuration = 0;
      this.active_segment = 0;
      this.$parent.ridingState = undefined;
      this.$parent.workoutStarted = false;
      this.$parent.timer = 0;
      this.workoutPaused = false;
      this.showOptionsStatus = false;
      this.quit = true;
      this.$parent.workoutTimes = undefined;

      clearInterval(this.intervalId);
      this.$scrollToTop();
    },
    showOptions() {
      this.loaded = true;
      if (!this.workoutPaused) {
        this.pause();
      } else {
        this.resume();
      }
      this.showOptionsStatus = !this.showOptionsStatus;
    },

    onlyShowOptions() {
      this.showOptionsStatus = true;
    },

    togglePause() {
      if (!this.workoutPaused) {
        this.pause();
      } else {
        this.resume();
      }
    },

    showSensor() {
      this.showSensorStatus = !this.showSensorStatus;
      this.showOptionsStatus = !this.showOptionsStatus;
      this.disconnected = false; // Eventuell möchte der Nutzer den Sensor nicht neu verbinden
    },

    quitWorkout() {
      this.setScrollLock(false);

      this.pause();
      this.loaded = false;
      this.$parent.workoutStarted = false;

      this.quit = true;
      this.$parent.removeCyclingState();
      this.$parent.endAutoSave();

      this.showOptionsStatus = false;
      if (this.intervalWorker) {
        this.intervalWorker.terminate();
      }

      this.reset();

      this.loaded = true;
      const _self = this;
      let interval = setInterval(function () {
        axios.get(_self.$host + "/workout/starttime/" + _self.startTimeInSeconds).then(function (res) {
          if (res.data._id) {
            _self.startTimeInSeconds = 0;
            _self.workoutAnalysisId = res.data._id;
            clearInterval(interval);
          }
        });
      }, 5000);
    },

    getTimeTillNextSegment(i) {
      this.$parent.workoutTimes[i].segment;
    },

    async addWorkoutToCalendar() {
      // Fuege in Kalender hinzu, falls Workout aus Vorlage stammt!
      if (!this.isCalendarWorkout) {
        const workout = this.$parent.workout;
        let tempWorkoutDuration = this.totalWorkoutLength(workout.workoutFile);

        let req = {
          name: workout.name,
          sport: workout.sport,
          description: workout.description,
          durationShould: tempWorkoutDuration,
          paceShould: workout.paceShould || 0,
          distanceShould: workout.distanceShould || 0,
          distance: workout.distance || 0,
          date: this.$moment(),
          workoutFile: workout.workoutFile,
          radraumWorkoutId: workout.radraumWorkoutId,
          durationIs: 0,
          paceIs: 0,
          youtubeEmbedLink: workout.youtubeEmbedLink,
        };

        const _self = this;

        return axios
          .post(this.$host + "/workout", req)
          .then(function () {
            // Beim Ändern Event Emitten
            _self.$emit("wasSaved");
          })
          .catch(function (error) {
            console.log("ERROR" + error, error);
          });
      }
    },

    async quitAndSaveWorkout() {
      this.pause();

      // In Trainingsplan einfügen
      await this.addWorkoutToCalendar();

      this.loaded = false;

      let json = {};
      json.protocolVersion = 16;
      json.profileVersion = 100;
      json.activity = {
        timestamp: "",
        local_timestamp: "",

        total_timer_time: this.$parent.history.length,
        type: "manual", // ?
        event: "activity",
        event_type: "stop",
        event_group: 0,
        max_hr_adjustment: 100, // adjustment aufgrund des max_hr_bpm (0-100, wobei 100 die eigentlich geforderte Leistung ist)
        sessions: [
          {
            timestamp: "",
            start_time: "",
            start_position_lat: 0,
            start_position_long: 0,
            total_elapsed_time: this.$parent.history.length,
            total_timer_time: this.$parent.history.length,
            total_distance: 0,
            total_cproycles: 0,
            nec_lat: 0,
            nec_long: 0,
            swc_lat: 0,
            swc_long: 0,
            message_index: {
              0: false,
              value: 0,
              reserved: false,
              selected: false,
            },
            total_calories: 0,
            total_fat_calories: 0,
            avg_speed: 0,
            max_speed: 0,
            avg_power: 0,
            max_power: 0,
            total_ascent: 0,
            total_descent: 0,
            first_lap_index: 0,
            num_laps: 0,
            event: "session",
            event_type: "stop",
            sport: "cycling",
            sub_sport: "virtual_activity",
            avg_heart_rate: 0,
            max_heart_rate: 0,
            avg_cadence: 0,
            max_cadence: 0,
            total_training_effect: 0,
            event_group: 0,
            trigger: "activity_end",
          },
        ],
      };

      json.activity.sessions[0].laps = [];

      let lastStep = -1;
      let currentLap = -1;
      let distance = 0;
      let segmentTime = 0;
      let segmentPower = 0;
      let segmentHr = 0;
      let segmentMaxHr = 0;

      let segmentMaxPower = 0;
      let segmentSpeed = 0;
      let segmentMaxSpeed = 0;
      let segmentCadence = 0;
      let segmentMaxCadence = 0;
      let segmentDistance = 0;

      let segmentStartTimestamp = "";

      let totalMaxPower = 0;
      let totalPower = 0;
      let totalMaxSpeed = 0;
      let totalSpeed = 0;
      let totalHr = 0;
      let totalMaxHr = 0;
      let totalCadence = 0;
      let totalMaxCadence = 0;
      let totalDistance = 0;

      // Am Ende müssen wir zum Abschluss auch in den segment!=last_segment Block
      this.$parent.workoutTimes[this.$parent.workoutTimes.length] = {
        segment: -2,
      };
      for (let i = 0; i < this.$parent.history.length + 1; i++) {
        if (
          (this.$parent.workoutTimes[i] && this.$parent.workoutTimes[i].step != lastStep) ||
          i == this.$parent.history.length
        ) {
          if (lastStep != -1) {
            // Next segment Time
            const iTemp = i < this.$parent.history.length ? i : i - 1;

            // Durchschnitte des letzten Laps.
            json.activity.sessions[0].laps[currentLap].timestamp = this.$parent.history[iTemp].time;
            json.activity.sessions[0].laps[currentLap].start_time = segmentStartTimestamp;

            json.activity.sessions[0].laps[currentLap].avg_power = Math.round(segmentPower / segmentTime);
            json.activity.sessions[0].laps[currentLap].max_power = segmentMaxPower;
            json.activity.sessions[0].laps[currentLap].total_calories =
              (segmentPower / segmentTime) * 3.6 * (segmentTime / 3600);
            json.activity.sessions[0].laps[currentLap].total_timer_time = segmentTime;
            json.activity.sessions[0].laps[currentLap].total_elapsed_time = segmentTime;
            json.activity.sessions[0].laps[currentLap].start_position_lat = 0;
            json.activity.sessions[0].laps[currentLap].start_position_long = 0;
            json.activity.sessions[0].laps[currentLap].end_position_lat = 0;
            json.activity.sessions[0].laps[currentLap].end_position_long = 0;
            json.activity.sessions[0].laps[currentLap].total_distance = 0;

            json.activity.sessions[0].laps[currentLap].total_cycles = 0;

            json.activity.sessions[0].laps[currentLap].total_fat_calories = 0;
            json.activity.sessions[0].laps[currentLap].avg_speed = segmentSpeed / segmentTime;
            json.activity.sessions[0].laps[currentLap].total_ascent = 0;
            json.activity.sessions[0].laps[currentLap].total_descent = 0;
            json.activity.sessions[0].laps[currentLap].event = "lap";
            json.activity.sessions[0].laps[currentLap].event_type = "stop";
            json.activity.sessions[0].laps[currentLap].avg_heart_rate = segmentHr / segmentTime;
            json.activity.sessions[0].laps[currentLap].max_heart_rate = segmentMaxHr;
            json.activity.sessions[0].laps[currentLap].avg_cadence = segmentCadence / segmentTime;
            json.activity.sessions[0].laps[currentLap].max_cadence = segmentMaxCadence;
            json.activity.sessions[0].laps[currentLap].intensity = "active";
            json.activity.sessions[0].laps[currentLap].lap_trigger = "manual";
            json.activity.sessions[0].laps[currentLap].event_group = 0;
          }

          totalMaxPower = Math.max(totalMaxPower, segmentMaxPower);
          totalPower += segmentPower;
          totalMaxSpeed = Math.max(totalMaxSpeed, segmentMaxSpeed);
          totalSpeed += segmentSpeed;
          totalHr += segmentHr;
          totalDistance += segmentDistance;

          totalMaxHr = Math.max(totalMaxHr, segmentMaxHr);
          totalCadence += segmentCadence;
          totalMaxCadence = Math.max(totalMaxCadence, segmentMaxCadence);

          if (i == this.$parent.history.length) {
            // Last segment ist nur zum abhaken der Lap-Stats,
            // danch aussteigen

            json.activity.timestamp = this.$parent.history[0].time;
            json.activity.local_timestamp = this.$parent.history[0].time;
            json.activity.sessions[0].timestamp = this.$parent.history[this.$parent.history.length - 1].time;
            json.activity.sessions[0].start_time = this.$parent.history[0].time;

            json.activity.sessions[0].avg_power = Math.round(totalPower / i);
            json.activity.sessions[0].max_power = totalMaxPower;
            json.activity.sessions[0].total_calories = Math.round((totalPower / i) * 3.6 * (i / 3600));
            json.activity.sessions[0].total_timer_time = i;
            json.activity.sessions[0].total_elapsed_time = i;
            json.activity.sessions[0].start_position_lat = 0;
            json.activity.sessions[0].start_position_long = 0;
            json.activity.sessions[0].end_position_lat = 0;
            json.activity.sessions[0].end_position_long = 0;

            json.activity.sessions[0].total_cycles = 0;
            json.activity.sessions[0].total_fat_calories = 0;
            json.activity.sessions[0].avg_speed = totalSpeed / i;
            json.activity.sessions[0].max_speed = totalMaxSpeed;

            json.activity.sessions[0].total_ascent = 0;
            json.activity.sessions[0].total_descent = 0;
            json.activity.sessions[0].event = "lap";
            json.activity.sessions[0].event_type = "stop";
            json.activity.sessions[0].avg_heart_rate = totalHr / i;
            json.activity.sessions[0].max_heart_rate = totalMaxHr;
            json.activity.sessions[0].total_distance = totalDistance;

            json.activity.sessions[0].avg_cadence = totalCadence / i;
            json.activity.sessions[0].max_cadence = totalMaxCadence;
            json.activity.sessions[0].intensity = "active";
            json.activity.sessions[0].lap_trigger = "manual";
            json.activity.sessions[0].event_group = 0;
            json.activity.sessions[0].num_laps = json.activity.sessions[0].laps.length;
            break;
          }

          segmentStartTimestamp = this.$parent.history[i].time;
          segmentTime = 0;
          segmentMaxPower = 0;
          segmentPower = 0;
          segmentMaxSpeed = 0;
          segmentSpeed = 0;
          segmentHr = 0;
          segmentMaxHr = 0;
          segmentCadence = 0;
          segmentMaxCadence = 0;
          segmentDistance = 0;

          json.activity.sessions[0].laps.push({});
          currentLap++;
          lastStep = (this.$parent.workoutTimes[i] && this.$parent.workoutTimes[i].step) || -1;
          json.activity.sessions[0].laps[currentLap].records = [];
        }

        // -0.507601 - 1.11089 x + 0.239665 x^2
        let r = Math.sqrt(this.$parent.history[i].power);
        let speed = 0;

        this.$parent.history[i].power < 26
          ? (speed = (0.057 - 0.172 * r + 0.759 * r) ^ (2 - 0.079 * r) ^ 3)
          : (speed = (-1.635 + 2.325 * r - 0.064 * r) ^ (2 + 0.001 * r) ^ 3);

        segmentTime = segmentTime + 1;
        segmentPower += this.$parent.history[i].power || 0;
        segmentMaxPower = Math.max(segmentMaxPower, this.$parent.history[i].power) || 0;
        segmentMaxSpeed = Math.max(segmentMaxSpeed, speed);
        segmentSpeed += speed;
        segmentHr += this.$parent.history[i].hr || 0;
        segmentMaxHr = Math.max(segmentMaxHr, this.$parent.history[i].hr || 0);
        segmentCadence += this.$parent.history[i].cad || 0;
        segmentDistance += distance + speed / 3600;
        segmentMaxCadence = Math.max(segmentMaxCadence, this.$parent.history[i].cad || 0);

        // Geschwindigkeit entsteht aus Power ueber die letzten Sekunden
        let rec_speed = 0;
        let rec_r = Math.sqrt(this.$parent.history[i].power);

        if (i >= 4) {
          rec_r = Math.sqrt(
            (this.$parent.history[i].power +
              this.$parent.history[i - 1].power +
              this.$parent.history[i - 2].power +
              this.$parent.history[i - 3].power +
              this.$parent.history[i - 4].power) /
              5
          );
        }
        this.$parent.history[i].power < 26
          ? (rec_speed = (0.057 - 0.172 * rec_r + 0.759 * rec_r) ^ (2 - 0.079 * rec_r) ^ 3)
          : (rec_speed = (-1.635 + 2.325 * rec_r - 0.064 * rec_r) ^ (2 + 0.001 * rec_r) ^ 3);

        json.activity.sessions[0].laps[currentLap].records.push({
          speed: rec_speed,
          timestamp: this.$parent.history[i].time,
          distance: distance + rec_speed / 3600,
          timer_time: i,
          elapsed_time: i,
          position_lat: 0,
          position_long: 0,
          heart_rate: this.$parent.history[i].hr || 0,
          power: this.$parent.history[i].power || 0,
          cadence: this.$parent.history[i].cad || 0,
        });
      }

      const result = json;

      const _self = this;

      const stringRepresentation = JSON.stringify(result);

      axios
        .post(this.$host + "/radraum/upload_compressed", {
          workout: LZString.compressToBase64(stringRepresentation),
          name: _self.$parent.workout.name,
        })
        .then(function (res) {
          _self.loaded = true;
          _self.startTimeInSeconds = res.data.startTimeInSeconds;
          _self.quitWorkout();
        })
        .catch(function (error) {
          // TODO: FEHLER ANZEIGEN
          console.log("error" + error, error);
        });

      this.$scrollToTop();
    },

    increaseRepetitions(index) {
      this.$parent.workout.workoutFile[index].Repeat++;
      this.$parent.workoutTimes = this.computeWorkoutTimes();
      this.chartKey++;
    },

    increaseDuration(index) {
      this.$parent.workout.workoutFile[index].Duration = Math.round(
        this.$parent.workout.workoutFile[index].Duration * 1.05
      );
      this.$parent.workoutTimes = this.computeWorkoutTimes();
      this.chartKey++;
    },

    decreaseDuration(index) {
      if (this.$parent.workout.workoutFile[index].Duration > 1) {
        this.$parent.workout.workoutFile[index].Duration = Math.round(
          this.$parent.workout.workoutFile[index].Duration / 1.05
        );
      }
      this.$parent.workoutTimes = this.computeWorkoutTimes();
      this.chartKey++;
    },

    increaseOnDuration(index) {
      this.$parent.workout.workoutFile[index].OnDuration *= 1.05;
      this.$parent.workoutTimes = this.computeWorkoutTimes();
      this.chartKey++;
    },

    decreaseOnDuration(index) {
      if (this.$parent.workout.workoutFile[index].OnDuration > 1) {
        this.$parent.workout.workoutFile[index].OnDuration = Math.round(
          this.$parent.workout.workoutFile[index].OnDuration / 1.05
        );
      }
      this.$parent.workoutTimes = this.computeWorkoutTimes();
      this.chartKey++;
    },

    increaseOffDuration(index) {
      this.$parent.workout.workoutFile[index].OffDuration *= 1.05;
      this.$parent.workoutTimes = this.computeWorkoutTimes();
      this.chartKey++;
    },

    decreaseOffDuration(index) {
      if (this.$parent.workout.workoutFile[index].OffDuration > 1) {
        this.$parent.workout.workoutFile[index].OffDuration = Math.round(
          this.$parent.workout.workoutFile[index].OffDuration / 1.05
        );
      }
      this.$parent.workoutTimes = this.computeWorkoutTimes();
      this.chartKey++;
    },

    decreaseRepetitions(index) {
      if (this.$parent.workout.workoutFile[index].Repeat > 1) {
        this.$parent.workout.workoutFile[index].Repeat--;
      }
      this.$parent.workoutTimes = this.computeWorkoutTimes();
      this.chartKey++;
    },

    computeWorkoutTimes() {
      if (this.$parent.workout && this.$parent.workout.workoutFile) {
        let workoutTimes = [];
        let step = 0;
        const file = this.$parent.workout.workoutFile;
        for (let i = 0; i < file.length; i++) {
          const segment = file[i];
          const repeat = segment.Repeat || 1;
          const cadence = segment.Cadence || 0;
          for (let r = 0; r < repeat; r++) {
            const duration = segment.Duration || segment.OnDuration;
            step++;
            for (let t = 0; t < duration; t++) {
              const st = workoutTimes.length > 0 ? workoutTimes[workoutTimes.length - 1].startTime + 1 : 0;

              let intensity = segment.Power || segment.OnPower;
              if (!intensity) {
                intensity = segment.PowerLow + (segment.PowerHigh - segment.PowerLow) / (duration / t);
              }

              workoutTimes.push({
                startTime: st,
                intensity: intensity,
                watt: (intensity / 100) * this.thresholds.ftp * (this.adjust_power / 100),
                segment: i,
                step_dur: duration,
                time_left_step: duration - t,
                total_time_left_step: duration + (segment.OffDuration || 0) - t,

                total_repeat: repeat,
                current_repeat: r + 1,
                step: step,
                cadence: cadence,
              });
            }

            const offDuration = segment.OffDuration;
            if (offDuration) {
              step++;
              const cadenceResting = segment.CadenceResting || 0;

              for (let t = 0; t < offDuration; t++) {
                const st = workoutTimes.length > 0 ? workoutTimes[workoutTimes.length - 1].startTime + 1 : 0;

                let intensity = segment.OffPower;
                if (!intensity) {
                  intensity = segment.OffPower;
                }
                workoutTimes.push({
                  startTime: st,
                  watt: (intensity / 100) * this.thresholds.ftp * (this.adjust_power / 100),
                  segment: i,
                  step_dur: offDuration,
                  time_left_step: offDuration - t,
                  total_repeat: repeat,
                  current_repeat: r + 1,
                  total_time_left_step: offDuration - t,

                  step: step,
                  cadence: cadenceResting,
                });
              }
            }
          }
          // + offDuration off power
        }

        this.$parent.chart_watts_should = workoutTimes.map((x) => x.watt || 0);
        this.$parent.labels = workoutTimes.map((x, index) => index);
        return workoutTimes;
      } else {
        console.log("Nothing to load...");
        return [];
      }
    },

    betterformattedTime(seconds) {
      if (seconds >= 3600) {
        return this.$moment.utc(seconds * 1000).format("H[:]mm[:]ss");
      } else if (seconds >= 60) {
        return this.$moment.utc(seconds * 1000).format("mm[:]ss");
      } else {
        return this.$moment.utc(seconds * 1000).format("ss[s]");
      }
    },
    computeIntensityValues(val) {
      return "(" + Math.round((this.thresholds.ftp * val * (this.adjust_power / 100)) / 100) + "w)";
    },
    workoutSelected(workout) {
      this.$parent.workout = workout;
    },
    computeWidth(val, workoutFile) {
      const width = Math.floor((val / this.totalWorkoutLength(workoutFile)) * 100);

      return width > 1 ? width + "%" : "2px";
    },
    totalWorkoutLength(workoutFile) {
      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length;
    },
    getRampRectHeight(numberOfBlocks, startIntensity, endIntensity, index) {
      let res = startIntensity - ((startIntensity - endIntensity) / numberOfBlocks) * index;

      return res;
    },
    // TODO ANPASSEN FARBEN ETC
    colorForPower(power) {
      if (power > 105) {
        return "darkred";
      } else if (power > 100) {
        return "brown";
      } else if (power > 95) {
        return "firebrick";
      } else if (power > 88) {
        return "darkgreen";
      } else if (power > 83) {
        return "green";
      } else {
        return "rgba(94, 110, 255, 0.97)";
      }
    },

    workoutBuilderHeight(workoutFile) {
      let maxPower = Math.max.apply(
        Math,
        workoutFile.map(function (o) {
          let onPower = typeof o.OnPower == "number" ? o.OnPower : 0;
          let offPower = typeof o.OffPower == "number" ? o.OffPower : 0;
          let power = typeof o.Power == "number" ? o.Power : 0;
          let powerLow = typeof o.PowerLow == "number" ? o.PowerLow : 0;
          let powerHigh = typeof o.PowerHigh == "number" ? o.PowerHigh : 0;
          return Math.max(onPower, offPower, power, powerLow, powerHigh);
        })
      );
      return maxPower;
    },
    ended() {
      /*this.currentVideoId++;
                if (this.currentVideoId < this.selectedWorkouts.length) {
                    this.initWorkout();
                }*/
    },
    playing() {
      this.workoutPaused = false;
    },

    pause() {
      this.workoutPaused = true;
    },
    resume() {
      this.workoutPaused = false;
    },
    isPaused() {
      return this.workoutPaused;
    },

    async requestWakeLock() {
      try {
        if (this.isScreenLockSupported()) {
          this.wakeLock = await navigator.wakeLock.request("screen");
          this.wakeLock.addEventListener("release", () => {
            console.log("Screen Wake Lock released:", this.wakeLock.released);
          });
        } else {
          console.log("Kein Screelock supportet");
        }
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    },

    startWorkout: async function () {
      // Damit der Bildschirm nicht abdunkelt und BLE getrennt wird
      // muss ein wake lock acquired werden
      // TODO: Nach Wechsel von Tab wiedererlangen
      await this.requestWakeLock();

      // Fuer iOS/Android
      const keepAwake = async () => {
        await KeepAwake.keepAwake();
      };

      keepAwake();

      this.setScrollLock(true);

      if (!this.$parent.workoutTimes || this.$parent.workoutTimes.length == 0) {
        this.$parent.workoutTimes = this.computeWorkoutTimes();
      }

      this.$parent.workoutStarted = true;

      const _self = this;

      (async function () {
        await _self.initDisplay();
        _self.updateUI(_self.$parent.workoutTimes);
        _self.startTimer();
      })().catch((error) => {
        console.log("Error launching Radraum: ", error);
      });
    },

    isScreenLockSupported: function () {
      console.log("wlock", navigator.wakeLock);
      return "wakeLock" in navigator && navigator.wakeLock;
    },
    startTimer: function () {
      console.log("Start Timer");
      const _self = this;

      if (this.$parent.intervalWorker) {
        this.$parent.intervalWorker.terminate();
      }

      this.$parent.intervalWorker = new Worker("./interval_worker.js");

      this.$parent.startAutoSaveCyclingState();

      this.resume();

      this.$parent.intervalWorker.onmessage = async function () {
        try {
          // Kein Powermeter, dann genau Zielleistung
          if (!_self.$parent.powerMeter) {
            _self.$parent.powerSamples.push(_self.$parent.watts_should);
          }

          if (_self.$parent.powerSamples.length) {
            _self.$parent.ridingState.watts = Math.round(
              _self.$parent.powerSamples.reduce((a, b) => a + b, 0) / _self.$parent.powerSamples.length
            );
            _self.$parent.powerSamples = _self.$parent.powerSamples.slice(-1);
          }

          if (_self.$parent.hrSamples.length) {
            _self.$parent.ridingState.bpm = Math.round(
              _self.$parent.hrSamples.reduce((a, b) => a + b, 0) / _self.$parent.hrSamples.length
            );
            _self.$parent.hrSamples = _self.$parent.hrSamples.slice(-1);
          }

          if (_self.$parent.cadenceSamples.length) {
            _self.$parent.ridingState.rpm = Math.round(
              _self.$parent.cadenceSamples.reduce((a, b) => a + b, 0) / _self.$parent.cadenceSamples.length
            );
            _self.$parent.cadenceSamples = _self.$parent.cadenceSamples.slice(-1);
          }

          _self.$parent.ridingState.lastSampleTime = new Date();

          if (
            !(
              // Workout kann pausiert sein oder Nutzer tritt nicht
              // in beiden Fällen soll das Workout nicht weiterlaufen, außer bei freier Fahrt
              (
                _self.workoutPaused ||
                // Wenn watts_should 0 ist, dann befindet der Nutzer sich im "freie Fahrt" Modus
                // wenn kein Wattmesser da ist, würde das Workout hier beendet werden
                // bei freier Fahrt soll ausserdem auch eine Pause zum Ablauf der Zeit führen
                // ansonsten hier prüfen, ob power physische quelle vorhanden und nur dann die
                // Bedingung verwenden, ansonsten - bei vorhandener Quelle - stoppen
                (_self.$parent.ridingState.watts == 0 && _self.$parent.watts_should > 0)
              )
            )
          ) {
            _self.$parent.timer += 1;

            _self.$parent.ridingState.elapsed = _self.$parent.timer;
            _self.$parent.history.push({
              time: new Date().toJSON(),
              elapsed: _self.$parent.ridingState.elapsed,
              power: _self.$parent.ridingState.watts || 0,
              hr: _self.$parent.ridingState.bpm || 0,
              cad: _self.$parent.ridingState.rpm || 0,
            });

            /*   startTime: st,
                  watt: (intensity / 100) * this.thresholds.ftp,
                  segment: i,
                  step_dur: offDuration,
                  time_left_step: offDuration - t,
                  total_repeat: repeat,
                  current_repeat: r + 1,
                  step: step,
                  cadence: cadenceResting,


              */

            //const start = Math.max(0,$parent.workoutTimes[ _self.$parent.timer].startTime -10);
            //const end = $parent.workoutTimes[ _self.$parent.timer].startTime + $parent.workoutTimes[ _self.$parent.timer].step_dur + 10;

            //   $parent.workoutTimes[ _self.$parent.timer]

            const start = Math.max(0, _self.$parent.timer - 45);
            const end = Math.min(_self.$parent.chart_watts_should.length, Math.max(60, _self.$parent.timer + 90));

            _self.$parent.chart_watts_should_current = JSON.parse(
              JSON.stringify(_self.$parent.chart_watts_should)
            ).slice(start, end);

            _self.$parent.done.push(_self.$parent.ridingState.watts);
            _self.$parent.done_current.push(_self.$parent.ridingState.watts);
            if (_self.$parent.done_current.length > 44) {
              _self.$parent.done_current.shift();
            }
            _self.$parent.hrdone.push(_self.$parent.ridingState.bpm || 0);
          } else {
            // Pause data
            _self.$parent.history.push({
              time: new Date().toJSON(),
              elapsed: _self.$parent.ridingState.elapsed,
              power: 0,
              hr: _self.$parent.ridingState.bpm || 0,
              cad: 0,
            });
          }

          if (_self.$parent.watts_should > 0) {
            if (_self.$parent.controlDevice && _self.$parent.erg_mode) {
              controller.setTargetPower(_self.$parent.controlDevice, _self.$parent.watts_should);
            }
          } else {
            // FREIE FAHRT
            // TODO: TEST
            if (_self.$parent.controlDevice) {
              // controller.setTargetPower(_self.$parent.controlDevice, 0); TODO:Wieder rein? GLaube nicht //testen
              controller.setResistanceTarget(_self.$parent.controlDevice, _self.$parent.resistance);
            }
          }

          if (_self.$parent.controlDevice && !_self.$parent.erg_mode) {
            controller.setResistanceTarget(_self.$parent.controlDevice, _self.$parent.resistance);
          }
          _self.errorMessage = "";
        } catch (err) {
          console.log(err);
        }
      };
    },
    getTimeLeft: function () {
      this.timeLeft = this.totalWorkOutDuration() - this.$parent.timer;
      if (this.timeLeft > 0) {
        return this.timeLeft;
      } else {
        return 0;
      }
    },

    initDisplay: function () {
      if (this.$parent.ridingState === undefined || this.$parent.ridingState === null) {
        this.$parent.ridingState = {
          lastSampleTime: new Date(),
          watts: 0,
          rpm: 0,
          bpm: 0,
          elapsed: 0,
        };
      }
    },

    // for electron
    closeDevicePicker: function () {
      this.showDeviceList = false;
      this.deviceList = [];
      window.api.send("channelForTerminationSignal");
    },

    // for electron
    pickDevice: function (deviceId) {
      window.api.send("selectBluetoothDevice", deviceId);
      this.deviceList = [];

      this.showDeviceList = false;
    },

    // for electron
    getBluetoothPowerDeviceList: async function () {
      // TODO: ggfs. initialize controller

      this.device = [];
      const _self = this;
      window.api.receive("channelForBluetoothDeviceList", (data) => {
        console.log(data);
        _self.deviceList = data;
      });

      const fitnessMachineService = numberToUUID(0x1826);
      const fecService = "6e40fec1-b5a3-f393-e0a9-e50e24dcca9e";

      this.showDeviceList = true;

      let device = await BleClient.requestDevice({
        // TODO: ZUHAUSE TESTEN, auch andere requestdevice aufrufe!
        // https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.service.cycling_power.xml
        services: [numberToUUID(0x1818)],
        optionalServices: [fitnessMachineService, fecService],
      });

      this.connectBluetoothPower(device);
    },

    // for electron
    getBluetoothHeartrateList: async function () {
      // TODO: ggfs. initialize controller

      this.device = [];
      const _self = this;
      window.api.receive("channelForBluetoothDeviceList", (data) => {
        _self.deviceList = data;
      });

      this.showDeviceList = true;

      let device = await BleClient.requestDevice({
        // TODO: ZUHAUSE TESTEN, auch andere requestdevice aufrufe!
        // https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.service.cycling_power.xml
        services: [numberToUUID(0x180d)],
      });

      this.connectBluetoothHr(device);
    },

    // for electron
    getBluetoothCadenceList: async function () {
      // TODO: ggfs. initialize controller
      this.device = [];
      const _self = this;
      window.api.receive("channelForBluetoothDeviceList", (data) => {
        _self.deviceList = data;
      });

      this.showDeviceList = true;

      let device = await BleClient.requestDevice({
        // TODO: ZUHAUSE TESTEN, auch andere requestdevice aufrufe!
        // https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.service.cycling_power.xml
        services: [numberToUUID(0x1816)],
      });

      this.connectBluetoothCadence(device);
    },

    connectBluetoothPower: function (device = undefined) {
      const _self = this;
      (async function () {
        if (!_self.$parent.bluetoothInitialized) {
          _self.$parent.bluetoothInitialized = true;
          await controller.initialize();
        }

        _self.$parent.powerMeter = undefined;
        _self.blePowerConnecting = true;
        // Fitness Machine Feature
        if (!device) {
          const fitnessMachineService = numberToUUID(0x1826);
          const fecService = "6e40fec1-b5a3-f393-e0a9-e50e24dcca9e";

          device = await BleClient.requestDevice({
            // TODO: ZUHAUSE TESTEN, auch andere requestdevice aufrufe!
            // https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.service.cycling_power.xml
            services: [numberToUUID(0x1818), fitnessMachineService],
            optionalServices: [fitnessMachineService, fecService],
          });
        }

        await BleClient.connect(
          device.deviceId,
          () => {
            _self.disconnected = true;
            _self.$parent.powerMeter = undefined;
          },
          { timeout: 60000 }
        );
        _self.disconnected = false;

        _self.$parent.powerMeter = device;

        let parser = new CyclingPowerMeasurementParser();

        // check whether 1818 is supported, if not, use 1828 for power instead (if supported)

        let services = await BleClient.getServices(device.deviceId);
        let service = services.find((s) => s.uuid === "00001818-0000-1000-8000-00805f9b34fb");
        if (service) {
          await BleClient.startNotifications(
            device.deviceId,
            "00001818-0000-1000-8000-00805f9b34fb",
            "00002a63-0000-1000-8000-00805f9b34fb",
            function (res) {
              try {
                let data = parser.getData(res);
                let power = data["instantaneous_power"];

                // Ausreisser raus, die tlw. falsch durch Trainer gemeldet werden
                if (power < 2200) {
                  _self.$parent.powerSamples.push(power);
                }

                if ("cumulative_crank_revolutions" in data) {
                  if (!_self.$parent.cadenceMeter) {
                    _self.$parent.cadenceMeter = device;
                  }

                  let crankRevolutions = data["cumulative_crank_revolutions"];
                  let crankTime = data["last_crank_event_time"];

                  if (crankRevolutions !== undefined && crankTime !== undefined) {
                    if (_self.lastCrankTime > crankTime) {
                      _self.lastCrankTime = _self.lastCrankTime - 65536;
                    }
                    if (_self.lastCrankRevolutions > crankRevolutions) {
                      _self.lastCrankRevolutions = _self.lastCrankRevolutions - 65536;
                    }

                    let revs = crankRevolutions - _self.lastCrankRevolutions;
                    let duration = (crankTime - _self.lastCrankTime) / 1024;
                    let rpm = 0;
                    if (duration > 0) {
                      rpm = (revs / duration) * 60;
                    }
                    _self.lastCrankRevolutions = crankRevolutions;
                    _self.lastCrankTime = crankTime;
                    if (rpm < 300) {
                      _self.$parent.cadenceSamples.push(rpm);
                    }
                  }
                }
              } catch (err) {
                console.log(err);
              }
            }
          );
        } else {
          console.log("No 1818 service found");
          let ftmsParser = new FTMSMeasurementParser();

          let services = await BleClient.getServices(device.deviceId);

          console.log(services);
          let service = services.find((s) => s.uuid === "00001826-0000-1000-8000-00805f9b34fb");

          if (service) {
            console.log("1826 service was found");

            await BleClient.startNotifications(
              device.deviceId,
              "00001826-0000-1000-8000-00805f9b34fb",
              "00002ad2-0000-1000-8000-00805f9b34fb",
              function (dataview) {
                try {
                  let data = ftmsParser.getData(dataview);
                  let power = data["power"];

                  // Ausreisser raus, die tlw. falsch durch Trainer gemeldet werden
                  if (power < 2200) {
                    _self.$parent.powerSamples.push(power);
                  }
                } catch (err) {
                  console.log(err);
                }
              }
            );
          } else {
            console.log("No 1826 service found");
          }
        }

        await controller.initialSubscribe(device);

        const supportsControl = await controller.doesDeviceSupportControl();

        console.log("DOES DEVICE SUPPORT CONTROL?", supportsControl);

        if (supportsControl) {
          _self.$parent.controlDevice = device;
          controller.setTargetPower(_self.$parent.controlDevice, 50);
          // Standardmaeßig ist ERG an
          _self.$parent.erg_mode = true;
        }

        await _self.initDisplay();
      })()
        .catch((error) => {
          console.log("Error: ", error);
        })
        .finally(() => (_self.blePowerConnecting = false));

      if (typeof navigator === "undefined" || !("bluetooth" in navigator)) {
        this.bluetoothAvailable = false;
      }
    },

    connectBluetoothHr: function (device = undefined) {
      const _self = this;
      (async function () {
        _self.bleHrConnecting = true;

        if (!_self.$parent.bluetoothInitialized) {
          _self.$parent.bluetoothInitialized = true;
          await controller.initialize();
        }

        if (!device) {
          device = await BleClient.requestDevice({
            // https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.service.heart_rate.xml
            services: [numberToUUID(0x180d)],
          });
        }
        _self.$parent.heartMeter = device;

        await BleClient.connect(
          device.deviceId,
          () => {
            _self.disconnected = true;
            _self.$parent.heartMeter = undefined;
          },
          { timeout: 60000 }
        );
        _self.disconnected = false;

        await BleClient.startNotifications(
          device.deviceId,
          "0000180d-0000-1000-8000-00805f9b34fb",
          "00002a37-0000-1000-8000-00805f9b34fb",
          function (res) {
            try {
              let data = res.getUint8(1);
              _self.$parent.hrSamples.push(data);
            } catch (err) {
              console.log(err);
            }
          }
        );

        await _self.initDisplay();
      })()
        .catch((error) => {
          console.log("Error: ", error);
        })
        .finally(() => (_self.bleHrConnecting = false));
      if (typeof navigator === "undefined" || !("bluetooth" in navigator)) {
        this.bluetoothAvailable = false;
      }
    },

    connectBluetoothCadence: function (device = undefined) {
      console.log(CyclingSpeedCadenceMeasurementParser);
      let parser = new CyclingSpeedCadenceMeasurementParser();

      const _self = this;
      (async function () {
        _self.bleCadenceConnecting = true;
        if (!_self.$parent.bluetoothInitialized) {
          _self.$parent.bluetoothInitialized = true;
          await controller.initialize();
        }

        if (!device) {
          device = await BleClient.requestDevice({
            services: [numberToUUID(0x1816)],
          });
        }
        _self.$parent.cadenceMeter = device;

        await BleClient.connect(
          device.deviceId,
          () => {
            _self.disconnected = true;
            _self.$parent.cadenceMeter = undefined;
          },
          { timeout: 60000 }
        );
        _self.disconnected = false;

        await BleClient.startNotifications(
          device.deviceId,
          "00001816-0000-1000-8000-00805f9b34fb",
          "00002a5b-0000-1000-8000-00805f9b34fb",
          function (res) {
            try {
              let data = parser.getData(res);

              if ("cumulative_crank_revolutions" in data) {
                if (!_self.$parent.cadenceMeter) {
                  _self.$parent.cadenceMeter = device;
                }

                let crankRevolutions = data["cumulative_crank_revolutions"];
                let crankTime = data["last_crank_event_time"];

                if (crankRevolutions !== undefined && crankTime !== undefined) {
                  if (_self.lastCrankTime > crankTime) {
                    _self.lastCrankTime = _self.lastCrankTime - 65536;
                  }
                  if (_self.lastCrankRevolutions > crankRevolutions) {
                    _self.lastCrankRevolutions = _self.lastCrankRevolutions - 65536;
                  }

                  let revs = crankRevolutions - _self.lastCrankRevolutions;
                  let duration = (crankTime - _self.lastCrankTime) / 1024;
                  let rpm = 0;
                  if (duration > 0) {
                    rpm = (revs / duration) * 60;
                  }
                  _self.lastCrankRevolutions = crankRevolutions;
                  _self.lastCrankTime = crankTime;
                  if (rpm < 300) {
                    _self.$parent.cadenceSamples.push(rpm);
                  }
                }
              }
            } catch (err) {
              console.log(err);
            }
          }
        );

        await _self.initDisplay();
      })()
        .catch((error) => {
          console.log("Error connecting cadence: ", error);
        })
        .finally(() => (_self.bleCadenceConnecting = false));
      if (typeof navigator === "undefined" || !("bluetooth" in navigator)) {
        this.bluetoothAvailable = false;
      }
    },

    getRpeClass(intensity) {
      const rpe = this.wattsShouldToRpe(intensity);
      if (rpe >= 9) return "rpe-very-hard";
      if (rpe >= 7) return "rpe-hard";
      if (rpe >= 5) return "rpe-moderate";
      return "rpe-light";
    },

    getRpeDescription(intensity) {
      const rpe = this.wattsShouldToRpe(intensity);
      if (rpe >= 9) return "Very Hard";
      if (rpe >= 7) return "Hard";
      if (rpe >= 5) return "Moderate";
      return "Light";
    },

    getRpeColor(intensity) {
      const rpe = this.wattsShouldToRpe(intensity);
      if (rpe >= 9) return "#dc3545"; // Very Hard - Red
      if (rpe >= 7) return "#fd7e14"; // Hard - Orange
      if (rpe >= 5) return "#28a745"; // Moderate - Green
      return "#17a2b8"; // Light - Blue
    },

    // Update UI ist nicht mehr ganz korrekt als Name, da hier auch in die Logik eingegriffen wird!
    async updateUI(workoutTimes) {
      workoutTimes = this.$parent.workoutTimes;
      this.quit = false;
      const _self = this;
      setTimeout(function () {
        try {
          if (!this.quit && _self.$parent.ridingState) {
            try {
              let sec_num = _self.$parent.timer;
              let time = _self.totalDuration - sec_num;

              let watts = _self.$parent.ridingState.watts;
              if (watts !== undefined && watts !== null) {
                watts = watts.toFixed();
              } else {
                watts = 0;
              }

              let bpm = _self.$parent.ridingState.bpm;
              if (bpm !== undefined && bpm !== null) {
                bpm = bpm.toFixed();
              } else {
                bpm = 0;
              }

              let rpm = _self.$parent.ridingState.rpm;
              if (rpm !== undefined && rpm !== null) {
                rpm = rpm.toFixed();
              } else {
                rpm = 0;
              }
              _self.$parent.watts = watts;
              _self.$parent.heart = bpm;
              _self.$parent.cadence = rpm;
              _self.time = timeToString(time);

              if (!workoutTimes[_self.$parent.timer]) {
                const lastStep = workoutTimes[workoutTimes.length - 1].step;
                const freeDuration = 60 * 10; // 10 min
                for (let t = 0; t < freeDuration; t++) {
                  const freeStep = {
                    startTime: _self.$parent.timer + t,
                    watt: 0,
                    segment: workoutTimes[workoutTimes.length - 1].segment + 1,
                    step_dur: 1,
                    time_left_step: freeDuration - t,
                    total_repeat: 0,
                    current_repeat: 0,
                    step: lastStep + 1,
                    cadence: 0,
                  };

                  workoutTimes[_self.$parent.timer + t] = freeStep;
                  _self.$parent.chart_watts_should.push(freeStep.watt || 0);
                  _self.$parent.labels.push(_self.$parent.labels[_self.$parent.labels.length - 1]++);
                }
              }

              _self.index = workoutTimes[_self.$parent.timer];

              // Wenn verfügbar - wähle Segment der nächsten Sekunde, da immer kurze Verzögerung bis Trainer reagiert.
              // dadurch kann watts_should, das an Trainer gesendet wird, bereits 1 Sekunde vorher erledigt werden
              // wenn keiner Powermeter da ist, einfach das normale abspulen, da keine Verzögerung
              const index_next =
                workoutTimes[_self.$parent.timer + 1] && _self.$parent.powerMeter
                  ? workoutTimes[_self.$parent.timer + 1]
                  : workoutTimes[_self.$parent.timer];

              if (_self.index) {
                // Max HR Grenze ist gesetzt, aktuelle HR ist groeßer als Grenze und adjustment ist noch reduzierbar
                if (_self.max_hr_bpm != -1 && _self.$parent.heart > _self.max_hr_bpm) {
                  _self.max_hr_adjustment = _self.max_hr_adjustment - 0.04;
                } else {
                  // Keine Anpassung aufgrund der HR noetig
                  if (_self.max_hr_adjustment < 100) {
                    // HR unter Grenze - kann wieder erhöht werden
                    // die Erhöhung darf schneller geschehen
                    _self.max_hr_adjustment = _self.max_hr_adjustment + 0.12;
                  }
                }

                // Alle paar Sekunden den Untergrund etwas "aendern" -> leicht schwankende Leistung
                if (_self.road_feel && _self.$parent.erg_mode) {
                  if (_self.$parent.timer % 8 == 0) {
                    _self.road_feel_adjustment = Math.floor(-5 + Math.random() * 11);
                  }
                } else {
                  _self.road_feel_adjustment = 0;
                }

                // Zielwatt als eigentliche Watt mit errechnetem HR Anpassungsfaktor
                // hier wird index_next verwendet, um 1sek verzögerung zum Trainer rauszunehmen
                if (index_next.watt) {
                  _self.$parent.watts_should =
                    Math.round(
                      index_next.watt *
                        (_self.max_hr_adjustment / 100) *
                        (_self.max_hr_adjustment == 100
                          ? 1 // _self.adjust_power / 100
                          : 1) // Nur adjust power verwenden, wenn max_hr_adjustment nicht greift
                    ) + _self.road_feel_adjustment;
                } else {
                  _self.$parent.watts_should = 0;
                }
                _self.active_segment = _self.index.segment;

                if (_self.index.cadence) {
                  _self.$parent.cadence_should = _self.index.cadence;
                } else {
                  _self.$parent.cadence_should = 0;
                }

                // TODO TEST
                // Spiral of Death verhindern, durch gestufte Watterhöhung bei geringer aktueller Wattleistung
                if (_self.$parent.watts_should - _self.$parent.watts > 30 && _self.$parent.watts < 90) {
                  _self.$parent.watts_should = Math.min(_self.$parent.watts_should, parseInt(_self.$parent.watts) + 80);
                }
              }

              if (workoutTimes[_self.$parent.timer] && workoutTimes[_self.$parent.timer].total_time_left_step <= 8) {
                // entweder nicht im Intervall, oder letze Wiederholung!
                if (
                  !workoutTimes[_self.$parent.timer].current_repeat ||
                  workoutTimes[_self.$parent.timer].current_repeat == workoutTimes[_self.$parent.timer].total_repeat
                ) {
                  _self.show_next_step = true;
                  console.log(workoutTimes[_self.$parent.timer]);
                }
              } else {
                _self.show_next_step = false;
              }

              if (
                workoutTimes[_self.$parent.timer] &&
                workoutTimes[_self.$parent.timer].time_left_step == 3 &&
                !_self.played_countdown_times[_self.$parent.timer]
              ) {
                _self.played_countdown_times[_self.$parent.timer] = true;
                if (_self.play_audio) {
                  new Audio(require("@/assets//audio/countdown.mp3")).play();
                }
              }

              if (sec_num >= _self.totalDuration - 5 && !_self.played_complete_sound) {
                _self.played_complete_sound = true;
                if (_self.play_audio) {
                  new Audio(require("@/assets//audio/applause.mp3")).play();
                }
              }
            } catch (err) {
              console.log(err);
            }
            _self.updateUI(workoutTimes);
          }
        } catch (err) {
          console.log("ERR RR UI UPDATE: " + err);
        }
      }, 500);
    },

    skipCurrentStep() {
      if (!this.$parent.workoutTimes || !this.$parent.workoutTimes[this.$parent.timer]) {
        return;
      }

      const currentTime = this.$parent.timer;
      const currentStep = this.$parent.workoutTimes[currentTime].step;
      
      // Find the next different step
      let skipToTime = currentTime;
      while (
        skipToTime < this.$parent.workoutTimes.length && 
        this.$parent.workoutTimes[skipToTime].step === currentStep
      ) {
        skipToTime++;
      }

      // Update timer to skip to next step
      this.$parent.timer = skipToTime;

      // Update chart data arrays to match new position
      while (this.$parent.done.length < skipToTime) {
        this.$parent.done.push(0);
        this.$parent.hrdone.push(0);
      }

      // Force chart update
      this.chartKey++;
    },
  },
};
</script>

<style scoped>
tr {
  border-bottom: 1px solid #eee;
}
tr:nth-child(even) {
  background-color: #f9f9f9;
}

@media screen and (max-width: 1024px) {

  .metric-card {
    padding: 8px 12px;
    min-height: 72px; /* Set a smaller fixed height for mobile */
  }

  .metric-value {
    font-size: 24px;
  }

  .metric-target {
    font-size: 18px;
  }

  .metric-label {
    font-size: 12px;
    margin-bottom: 2px;
  }
  
  .metrics-container {
    background: #f3f3f6;
    padding: 8px 0;
    padding-bottom: 0;
    margin: 0;
    border-radius: 0; /* Remove border radius on mobile */
  }

  .stats-container {
    border-radius: 0; /* Remove border radius on mobile */
    margin: 8px 0; /* Keep vertical spacing but remove horizontal margins */
  }

  .metric-card {
    border-radius: 0; /* Remove border radius on mobile */
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .pulse {
    animation: pulse 1.5s infinite;
    transform-origin: center;
    display: inline-block;
  }


    .stats-container,
  .metric-card,
  .options {
    transform: translateZ(0);
    backface-visibility: hidden;
  }

  /* Remove padding from row */
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  /* Remove padding from columns */
  [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }

  .tooltip {
    font-size: 12px;
  }

  .power-progress {
    display: block;
  }

  .metric-card {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
  }

  .rpe-progress {
    display: block;
  }

  .metric-card {
    padding: 8px 12px;
    min-height: 72px; /* Set a smaller fixed height for mobile */
  }

  .metric-value {
    font-size: 24px;
  }

  .metric-target {
    font-size: 18px;
  }

  .metric-label {
    font-size: 12px;
    margin-bottom: 2px;
  }
}

.power-progress {
  position: absolute;
  left: 0;
  right: 0;
  top: 101%; /* Increased from 100% to move it down */
  transform: translateY(-25%); /* Changed from -50% to -25% to move it down further */
  padding: 0 25px;
  z-index: 10;
  pointer-events: none;
}

.power-progress .progress {
  height: 12px;
  border-radius: 6px;
  background: #eee;
}

.power-progress .progress-bar {
  border-radius: 6px;
  transition: width 0.3s ease;
}

.rpe-progress {
  position: absolute;
  left: 0;
  right: 0;
  top: 101%;
  transform: translateY(-25%);
  padding: 0 25px;
  z-index: 10;
  pointer-events: none;
}

.rpe-progress .progress {
  height: 12px;
  border-radius: 6px;
  background: #eee;
}

.rpe-progress .progress-bar {
  border-radius: 6px;
  transition: width 0.3s ease;
}

.power-progress .progress,
.rpe-progress .progress {
  cursor: help;
}

/* Ensure tooltips appear above other elements */
.tooltip {
  z-index: 99999;
}



.metric-card {
  background: #fff;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90px; /* Default height for desktop */
}

.metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.metric-label {
  font-size: 14px;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

.metric-value {
  font-weight: 700;
  color: #333;
  line-height: 1.2;
}

.metric-target {
  font-size: 24px;
  color: #666;
  margin-left: 4px;
}

.overlayx {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10500;
}

.device_list {
  position: fixed;
  width: 400px;
  height: 540px;
  left: 50%;
  top: 50%;
  margin-top: -270px;
  margin-left: -200px;
  overflow-y: scroll;
  background: #eee;
  border: 2px solid #cfcfcf;
  padding: 10px;
  z-index: 999999999500;
  border-radius: 10px;
}

input[type="checkbox"] {
  transform: scale(1.5);
}
iframe {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100% !important;
  height: calc(100vw / 1.77777778) !important;
}

@media screen and (max-width: 450px) {
  iframe {
    width: 100%;
    height: calc(100vw / 1.77777778);
  }
}

@media screen and (max-width: 768px) {
  iframe {
    width: 100%;
    height: calc(100vw / 1.77777778);
  }
}

.menu-b:hover {
  background: #eee;
}

.options {
  width: 400px;
  height: 600px;
  padding: 50px;
  position: fixed;
  margin: auto;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -200px;
  z-index: 11600;
  border: 5px solid #eee;
  background: #eee;
  color: #222;
  border-radius: 5px;
}

.options img {
  width: 100px;
  margin-bottom: 20px;
}
.options button {
  width: 100%;
}

.overlay,
.overlay-box-library {
  position: fixed;
  left: 1%;
  right: 1%;
  top: 2%;
  bottom: 2%;
  background: #000;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1000;
}

#radraum-exercise {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1; /* Lower base z-index */
  background-color: #f3f3f6;
  color: #222;
  -webkit-overflow-scrolling: touch;
}

.exercise-time {
  text-align: center;
  font-size: 45px;

  font-weight: bold;
}

.segment_text {
  position: fixed;
  left: 50%;
  top: 50%;
  padding: 20px;
  width: 90%;
  font-size: 24px;
  background: rgba(50, 50, 50, 0.85);
  color: #fff;
  max-width: 450px;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  z-index: 1000000;
}

.video {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.watts-mobile {
  z-index: 300;
  background: #000;
}

.right {
  text-align: right;
}

.sidemenuItem {
  background: #fff;
  color: #222;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  z-index: 9999999999;
}

.yt-text {
  text-align: center !important;
  padding: 20px;
  color: #222;
  padding-bottom: 200px;
}

.workout-name {
  float: left;
  margin-left: 20px;
}

.back {
  float: left;
  margin-left: 20px;
}

.table {
  background: inherit;
  overflow-x: scroll;
}

.tablex td,
.tablex th {
  border: 0;
}

.workout-next {
  float: right;
  margin-right: 20px;
}

.close-kraftraum {
  float: left;
  color: #fff;
  margin-left: 20px;
}

.current-workout {
  font-family: "StayDreaming", "Barlow", sans-serif;
  font-size: 55px;
  font-weight: 400;
  line-height: 55px;
  vertical-align: middle;
  padding: 20px 10px;
  border-radius: 10px;
  color: #000;
}

.cat-heading-medium {
  font-family: "StayDreaming", "Barlow", sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  vertical-align: middle;
}

.cat-heading-smaller {
  font-family: "StayDreaming", "Barlow", sans-serif;
  font-size: 75px;
  font-weight: 400;
  line-height: 75px;
  vertical-align: middle;
}
.cat-heading-very-small {
  font-family: "StayDreaming", "Barlow", sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  vertical-align: middle;
}

.white-bar {
  width: 100%;
  height: 25px;
  background: #fff;
}

.white-bar-thinner {
  width: 100%;
  height: 5px;
  background: #fff;
}

.spacer-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.top-spacer-46 {
  margin-top: 46px;
}

.top-spacer-50 {
  margin-top: 50px;
}

.bottom-spacer-50 {
  margin-bottom: 50px;
}

.top-spacer-100 {
  margin-top: 100px;
}

.top-spacer-125 {
  margin-top: 125px;
}

.top-spacer-150 {
  margin-top: 150px;
}

.centered {
  text-align: center;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

select {
  text-align: center;
  width: 220px;
}

.hero-img {
  width: 100%;
}

.w-line-chart {
  height: 180px;
  margin: 0;
}

@media screen and (max-width: 450px) {
  iframe {
    width: 100% !important;
    height: calc(100vw / 1.77777778) !important;
  }
  .w-line-chart {
    height: 80px;
  }
}

@media screen and (max-width: 768px) {
  .w-line-chart {
    height: 100px;
  }
  iframe {
    width: 100% !important;
    height: calc(100vw / 1.77777778) !important;
  }
}

.base-timer {
  position: relative;
  width: 300px;
  height: 300px;
  /* Removes SVG styling that would hide the time label */
  /* The SVG path that displays the timer's progress */
}
.base-timer__circle {
  fill: none;
  stroke: none;
}
.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.progressbar {
  height: 15px;
  text-align: right;
  padding: 0 15px;
  line-height: 15px;
  width: 0;
  box-sizing: border-box;
  z-index: 9999999999;
}

@keyframes textColorChange-orange {
  0% {
    color: orange;
  }
  100% {
    color: #000;
  }
}
/* Use @-webkit-keyframes for Safari/Chrome */

#pulsate-orange {
  animation: textColorChange-orange 0.7s infinite;
}

@keyframes textColorChange-red {
  0% {
    color: darkred;
  }
  100% {
    color: #000;
  }
}
/* Use @-webkit-keyframes for Safari/Chrome */

#pulsate-red {
  animation: textColorChange-red 0.7s infinite;
}

/* ... existing styles ... */

.row[style*="margin: 0; z-index: 2147483000"] {
  position: sticky;
  top: 0;
  background: #f3f3f6;
  padding: 10px 0;
  margin: 0;
  z-index: 2147483000;
}

/* Make sure tables can scroll horizontally on mobile */
.tablex {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Adjust the main container for better mobile scrolling */
#radraum-exercise {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10000;
  background-color: #f3f3f6;
  color: #222;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  /* Add these properties for better Android scrolling */
  transform: translateZ(0);
  backface-visibility: hidden;
}

/* Make sure content below sticky header is accessible */
.col-lg-12[style*="background: #fff; padding: 15px 15px"] {
  margin-top: 10px;
  position: relative;
  z-index: 1;
}

/* Adjust for Android specific issues */
@media screen and (max-width: 768px) {
  #radraum-exercise {
    position: fixed; /* Keep fixed positioning */
    height: 100vh; /* Explicit height */
    width: 100vw; /* Explicit width */
  }

  .tablex {
    max-width: 100vw;
 
  }

  .options {
    width: 90%; /* Make options modal more responsive */
    height: auto;
    min-height: 400px;
    max-height: 90vh;
    left: 5%;
    margin-left: 0;
    padding: 25px;
    overflow-y: auto;
  }

  /* Fix stacking context for important elements */
  .row[style*="margin: 0; z-index: 2147483000"] {
    position: sticky;
    top: 0;
    background: #f3f3f6;
    padding: 10px 0;
    z-index: 100; /* Lower but still high enough */
    width: 100%;
  }

  /* Ensure content containers are visible */
  .col-lg-12[style*="background: #fff"] {
    position: relative;
    z-index: 2;
    margin: 10px 0;
    width: 100%;
  }

  /* Ensure charts are visible */
  .w-line-chart {
    position: relative;
    z-index: 3;
    width: 100%;
  }

  /* Fix table scrolling */
  .tablex {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* Add styles for very small screens */
@media screen and (max-width: 480px) {
  .options {
    width: 95%;
    left: 2.5%;
    padding: 15px;
  }

  .sidemenuItem {
    margin-bottom: 5px;
    padding: 8px;
  }
}

/* Ensure content is visible when scrolling */
.container {
  position: relative;
  z-index: 2;
}

/* Fix stacking context for overlays */
.device_list {
  z-index: 2000;
}

.segment_text {
  z-index: 1500;
}

/* ... rest of existing styles ... */

/* Add these new metric styles */
.metric-card {
  background: #fff;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90px;
}

.metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.metric-label {
  font-size: 14px;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

.metric-value {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  line-height: 1.2;
}

.metric-target {
  font-size: 24px;
  color: #666;
  margin-left: 4px;
}


/* Add progress bar styles */
.power-progress {
  position: absolute;
  left: 0;
  right: 0;
  top: 101%;
  transform: translateY(-25%);
  padding: 0 25px;
  z-index: 10;
  pointer-events: none;
}

.power-progress .progress {
  height: 12px;
  border-radius: 6px;
  background: #eee;
}

.power-progress .progress-bar {
  border-radius: 6px;
  transition: width 0.3s ease;
}

.rpe-progress {
  position: absolute;
  left: 0;
  right: 0;
  top: 101%;
  transform: translateY(-25%);
  padding: 0 25px;
  z-index: 10;
  pointer-events: none;
}

.rpe-progress .progress {
  height: 12px;
  border-radius: 6px;
  background: #eee;
}

.rpe-progress .progress-bar {
  border-radius: 6px;
  transition: width 0.3s ease;
}

/* Add animation styles */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.pulse {
  animation: pulse 1.5s infinite;
  transform-origin: center;
  display: inline-block;
  color: #dc3545;
}

/* Update mobile styles */
@media screen and (max-width: 1024px) {
  .metric-card {
    padding: 8px 12px;
    min-height: 72px;
    border-radius: 0;
  }

  .metric-value {
    font-size: 24px;
  }

  .metric-target {
    font-size: 18px;
  }

  .metric-label {
    font-size: 12px;
    margin-bottom: 2px;
  }

  .metrics-container {
    background: #f3f3f6;
    padding: 8px 0;
    padding-bottom: 0;
    margin: 0;
    border-radius: 0;
  }

  /* Remove padding from row and columns */
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }

  .col-6 {
    margin-bottom: 8px;
  }
}

/* Add these styles for the stats section */
.stats-container {
  background: #fff;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;
}

.stats-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-around;
}

.stat-item {
  padding: 10px;
  border: none;
  background: transparent;
  flex: 1;
  min-width: 150px;
  text-align: center;
}

.stat-label {
  font-size: 11px;
  color: #666;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  display: inline-block; /* Keep value and target on same line */
}

.stat-target {
  font-size: 18px;
  color: #666;
  margin-left: 4px;
  display: inline-block; /* Keep value and target on same line */
}

.stat-unit {
  font-size: 16px;
  color: #666;
  margin-left: 2px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .stats-grid {
    display: grid; /* Change to grid layout on mobile */
    grid-template-columns: repeat(2, 1fr); /* Create 2 columns */
    gap: 12px; /* Reduce gap on mobile */
    padding: 0 8px; /* Add some horizontal padding */
  }

  .stat-item {
    min-width: unset;
    padding: 8px;
    /* Remove flex properties since we're using grid */
    flex: unset;
    width: 100%;
  }

  .stat-value {
    font-size: 20px;
  }

  .stat-target {
    font-size: 16px;
  }

  /* Ensure the container has proper spacing */
  .stats-container {
    padding: 12px 8px;
    margin: 8px 0;
  }
}
</style>
