

<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  props: [
    "bp",
    "label",
    "color",
    "alltime",
    "alltime_color",
    "text",
    "fourtyTwoDays",
    "fourtyTwoDays_color",
  ],
  data() {
    return {
      d_bp: this.bp,
      d_fourtyTwoDays: this.fourtyTwoDays,
      d_alltime: this.alltime,

      loaded: false,
      labels: [],
      power: [],
      chartData: {},
      options: {
        elements: {
          point: {
            radius: 0,
          },
        },
        animation: {
          duration: 0,
        },

        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },

        scales: {
          yAxes: {
            title: {
              display: true,
              text: this.label,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  created() {
    this.loaded = false;
  },
  mounted() {
    let ctx = this.$refs.graph.getContext("2d");

    this.labels = [
      "5s",
      "10s",
      "15s",
      "30s",
      "1m",
      "1m30",
      "2m",
      "3m",
      "4m",
      "5m",
      "6m",
      "7m",
      "8m",
      "9m",
      "10m",
      "12m",
      "14m",
      "16m",
      "18m",
      "20m",
      "25m",
      "30m",
      "40m",
      "50m",
      "1h",
      "1h30m",
      "2h",
      "3h",
      "4h",
    ];

    for (let i = 0; i < this.bp.length; i++) {
      if (this.d_bp[i] <= 0) {
        this.d_bp = this.d_bp.slice(0, i);
        if (this.d_fourtyTwoDays) {
          this.d_fourtyTwoDays = this.d_fourtyTwoDays.slice(0, i);
        }
        if (this.d_alltime) {
          this.d_alltime = this.d_alltime.slice(0, i);
        }
        this.labels = this.labels.slice(0, i);
        break;
      }
    }

    this.chartData.labels = this.labels;

    this.chartData.datasets = [
      {
        label: this.text,
        data: this.d_bp,
        fill: false,
        borderColor: this.color,
        backgroundColor: this.color,
        borderWidth: 3,
        tension: 0.35,
      },
    ];
    if (this.d_fourtyTwoDays && this.d_fourtyTwoDays.length > 0) {
      this.chartData.datasets.push({
        label: "6 Wochen",
        data: this.d_fourtyTwoDays,
        fill: false,
        borderColor: this.fourtyTwoDays_color,
        backgroundColor: this.fourtyTwoDays_color,
        borderWidth: 3,
        tension: 0.35,
      });
    }
    if (this.d_alltime && this.d_alltime.length > 0) {
      this.chartData.datasets.push({
        label: "12 Monate",
        data: this.d_alltime,
        fill: false,
        borderColor: this.alltime_color,
        backgroundColor: this.alltime_color,
        borderWidth: 3,
        tension: 0.35,
      });
    }

    const config = {
      type: "line",
      data: this.chartData,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.yAxes;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
      ],
    };

    new Chart(ctx, config);
    this.loaded = true;
  },
};
</script>