<template>
  <div class="impressum">
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-6 white_wrapper">
        <h3 class="top-spacer-50" v-if="client">
          Mit {{ client.name }} verbinden
        </h3>
        Wenn du dich mit der oben genannten App verbindest, bekommt diese das
        Recht, Einheiten für dich in den Pushing Limits Club zu übertragen und
        der Pushing Limits Club erhält das Recht, diese gemäß unserer
        Nutzungsbedingungen zu verwenden. Außerdem übertragen wir geplante
        Einheiten zur App. Die App kann diese Daten dann nach eigenem Ermessen
        nutzen. Gib dazu hier deine Login-Daten aus dem Pushing Limits Club an.
        <div v-if="error">{{ error }}</div>

        <div v-if="stateResult">
          <strong>
            Folgende Daten werden würden im nächsten Schritt zur redirectUri
            gesendet werden: {{ stateResult }}

            redirect zb:
            {{
              this.client.redirectUris[0] +
              "/authorzationCode=" +
              stateResult.authorizationCode +
              "&state=" +
              stateResult.state
            }}
          </strong>
        </div>

        <div class="row" v-if="!error">
          <div class="col-lg-12">
            <input
              class="top-spacer-15"
              required
              v-model="email"
              ref="email"
              name="email"
              type="email"
              placeholder="deine@email.de"
              style="width: 300px"
            />
          </div>
          <div class="col-lg-12">
            <input
              class="top-spacer-10"
              required
              ref="password"
              name="pw"
              v-model="password"
              type="password"
              placeholder="Passwort"
              style="width: 300px"
            />
          </div>
          <div class="col-lg-12">
            <button
              style="width: 300px"
              @click="connect"
              class="top-spacer-10"
              type="submit"
            >
              Verbinden
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Start",
  props: ["id", "state"],

  data() {
    return {
      stateResult: undefined,
      password: "",
      email: "",
      client: undefined,
      error: undefined,
      result: undefined,
    };
  },

  mounted: function () {
    const _self = this;
    axios
      .get(this.$host + "/oauth/client/" + this.id)
      .then(function (res) {
        console.log("OKAY");
        _self.client = res.data;
      })
      .catch(function (error) {
        console.log(error);
        _self.error = "Keine App mit der übergebenen Id gefunden";
      });
  },
  methods: {
    connect() {
      let email = this.email || this.$refs.email.value;
      let password = this.password || this.$refs.password.value;

      axios
        .get(
          this.$host +
            "/oauth/authorize?response_type=code&client_id=" +
            this.client.clientId +
            "&redirect_uri=" +
            this.client.redirectUris[0] +
            "&email=" +
            email +
            "&password=" +
            password +
            "&state=" +
            this.state
        )
        .then(function (res) {
          console.log("OKAY");
          // redirect to result
          window.location.assign(res.data.uri);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.impressum {
  font-size: 16px;
}
</style>
