<template>
  <div class="training-zones">
    <h3>Training Zones</h3>
    <div class="zones-grid">
      <div
        v-for="zone in zones"
        :key="zone.name"
        class="zone-item"
        :class="`zone-${zone.number}`"
        :title="zone.description"
      >
        <div class="zone-header">
          <span class="zone-name">Zone {{ zone.number }}: {{ zone.name }}</span>
          <span class="zone-hr">{{ zone.hrRange }}% HR</span>
        </div>
        <div class="zone-value">{{ formatValue(zone.value) }} {{ unit }}</div>
        <div class="zone-details">
          <div class="zone-description">{{ zone.description }}</div>
          <div class="zone-time">
            <span class="time-label">Weekly Time:</span>
            <span class="time-value">{{ zone.timeRecommendation }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "TrainingZones",

  props: {
    sport: {
      type: String,
      required: true,
    },
    threshold: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const unit = computed(() => (props.sport === "Laufen" ? "km/h" : "W"));
    const isCycling = computed(() => props.sport === "Radfahren");

    const calculateZones = computed(() => {
      const threshold = props.threshold;
      const isRunning = !isCycling.value;

      return [
        {
          number: 1,
          name: "Recovery",
          hrRange: "60-72",
          value: isCycling.value ? threshold * 0.55 : threshold * 0.65,
          description:
            "Active recovery and technical work. Promotes recovery, fat metabolism, and capillary development. Perfect for warm-up and cool-down.",
          timeRecommendation: isRunning ? "40-60 min sessions" : "1-3 hours/week",
          color: "#3498db",
        },
        {
          number: 2,
          name: "Endurance",
          hrRange: "73-82",
          value: isCycling.value ? threshold * 0.75 : threshold * 0.8,
          description:
            "Basic aerobic endurance. Builds mitochondrial density and capillary network. Foundation of training volume.",
          timeRecommendation: isRunning ? "60-150 min sessions" : "3-8 hours/week",
          color: "#2ecc71",
        },
        {
          number: 3,
          name: "Tempo",
          hrRange: "83-87",
          value: isCycling.value ? threshold * 0.9 : threshold * 0.9,
          description:
            "Moderate intensity. Improves aerobic capacity and lactate clearance. 'Comfortably hard' effort.",
          timeRecommendation: isRunning ? "20-40 min intervals" : "2-3 hours/week",
          color: "#f1c40f",
        },
        {
          number: 4,
          name: "Threshold",
          hrRange: "88-92",
          value: threshold,
          description:
            "Highest sustainable intensity. Improves lactate tolerance and mental toughness. Key intensity for performance gains.",
          timeRecommendation: isRunning ? "15-30 min intervals" : "1-2 hours/week",
          color: "#e67e22",
        },
        {
          number: 5,
          name: "VO2max",
          hrRange: "93-100",
          value: isCycling.value ? threshold * 1.15 : threshold * 1.1,
          description:
            "Maximum aerobic capacity. Improves VO2max, power output, and neuromuscular coordination. High intensity intervals.",
          timeRecommendation: isRunning ? "2-5 min intervals" : "30-60 min/week",
          color: "#e74c3c",
        },
      ];
    });

    const formatValue = (value) => value.toFixed(2);

    return {
      zones: calculateZones,
      unit,
      formatValue,
    };
  },
};
</script>

<style scoped>
.training-zones {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.zones-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.zone-item {
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-left: 4px solid;
  background-color: white;
  transition: transform 0.2s;
}

.zone-item:hover {
  transform: translateY(-2px);
}

/* Zone color coding */
.zone-1 {
  border-left-color: #3498db;
}
.zone-2 {
  border-left-color: #2ecc71;
}
.zone-3 {
  border-left-color: #f1c40f;
}
.zone-4 {
  border-left-color: #e67e22;
}
.zone-5 {
  border-left-color: #e74c3c;
}

.zone-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  align-items: center;
}

.zone-name {
  font-weight: bold;
  color: #2c3e50;
}

.zone-hr {
  color: #666;
  font-size: 0.9em;
  padding: 0.2rem 0.5rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.zone-value {
  font-size: 1.2em;
  color: #42b983;
  margin-bottom: 0.5rem;
  padding: 0.3rem 0;
  border-bottom: 1px solid #eee;
}

.zone-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.zone-description {
  font-size: 0.9em;
  color: #666;
  line-height: 1.4;
}

.zone-time {
  font-size: 0.9em;
  color: #666;
  padding-top: 0.5rem;
  border-top: 1px solid #eee;
}

.time-label {
  font-weight: 600;
  margin-right: 0.5rem;
}

h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}
</style>
