<template>
  <div class="row">
    <div class="col-lg-12 top-spacer-25">
      <div class="white_wrapper_tighter">
        <canvas
          ref="sleepGraph"
          id="sleepGraph"
          style="height: 200px"
          width="100%"
        ></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "chart.js";
import moment from "moment";

export default {
  name: "HealthChartSleep",
  props: {
    health: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      counter: 0,
    };
  },
  computed: {
    chartData() {
      const labels = [];

      const sleepData = [];

      for (const date in this.health) {
        labels.push(this.$moment(date).format("DD.MM.YYYY"));

        const data = this.health[date];

        if (data.sleepData) {
          sleepData.push(data.sleepData.durationInSeconds);
        } else {
          sleepData.push(undefined);
        }
      }

      return {
        labels: labels,
        datasets: [
          {
            label: "Schlaf (h)",
            data: sleepData,
            borderColor: "#3e95cd",
            backgroundColor: "#3e95cd",
            fill: false,
            yAxisID: "y",
          },
        ],
      };
    },
  },
  mounted() {
    const ctx = this.$refs.sleepGraph.getContext("2d");

    new Chart(ctx, {
      type: "line",
      data: this.chartData,
      options: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "Schlafdauer",
          },
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                var datasetLabel =
                  context.dataset.label +
                  ": " +
                  moment()
                    .startOf("day")
                    .seconds(context.parsed.y)
                    .format("H:mm");
                return datasetLabel;
              },
            },
          },
        },
        scales: {
          y: {
            type: "linear",
            position: "right",
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                return (
                  moment().startOf("day").seconds(value).format("HH:mm") + "h"
                );
              },
            },
          },

          xAxes: {
            ticks: {
              maxTicksLimit: 8, // show no more than 8 labels
            },
          },
        },
        spanGaps: false,
        elements: {
          point: {
            radius: 0,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    });

    this.$emit("chartRendered");
  },
};
</script>
