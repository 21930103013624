<template>
  <div>
    <div class="white_wrapper_tighter metrics-container">
      <div class="metrics-grid">
        <div class="metric-card">
          <div class="metric-label">{{ $t("Form") }}</div>
          <div class="metric-value" :class="getFormClass">{{ form }}</div>
        </div>
        <div class="metric-card">
          <div class="metric-label">{{ $t("Ermüdung") }}</div>
          <div class="metric-value stress">{{ ermuedung }}</div>
        </div>
        <div class="metric-card">
          <div class="metric-label">{{ $t("Fitness") }}</div>
          <div class="metric-value fitness">{{ fitness }}</div>
        </div>
        <div class="metric-card">
          <div class="metric-label">{{ todayText }}</div>
          <div class="metric-value today">{{ today }}</div>
        </div>
      </div>
    </div>
    <div style="height: 300px" class="top-spacer-25 white_wrapper_tighter">
      <canvas ref="stressGraph" id="stressGraph" width="100%"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";

export default {
  props: ["fromDay", "until", "done", "todayText"],

  data: function () {
    return {
      // Gehe 42 Tage zurück, für 42 / 10 Tage Load
      from: moment(this.fromDay).add(-42, "d"),
      workouts: [],
      loadedWeeks: this.weeks,
      loadOnlydone: this.done,
      fitness: 0,
      today: 0,
      ermuedung: 0,
      form: 0,
      days: [1, 2, 3, 4, 5, 6, 7],

      chartdata: {
        labels: [],
        datasets: [
          {
            label: "Fitness",
            borderColor: "#4CAF50", // Green
            backgroundColor: "#4CAF50",
            data: [],
            yAxisID: "y",
            borderWidth: 3,
            order: 10,
            tension: 0.4,
          },
          {
            label: "Ermüdung",
            borderColor: "#FF5722", // Deep Orange
            backgroundColor: "#FF5722",
            data: [],
            yAxisID: "y",
            borderWidth: 3,
            fill: false,
            order: 5,
            tension: 0.4,
          },
          {
            label: "Form",
            backgroundColor: "#2196F3", // Blue
            borderColor: "#2196F3",
            data: [],
            yAxisID: "y",
            borderWidth: 3,
            fill: false,
            order: 0,
            tension: 0.4,
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: true,
          },
        },

        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },

        scales: {
          y: {
            grid: {
              display: true,
            },
            stacked: false,
            id: "stack",
            position: "right",
            display: true,

            ticks: {
              beginAtZero: true,
              maxTicksLimit: 15,
            },
          },
          xAxes: {
            grid: {
              display: true,
            },
            id: "x-axis-1",
            gridLines: {
              display: false,
            },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  computed: {
    getFormClass() {
      if (this.form >= 15) return "form-excellent";
      if (this.form >= 0) return "form-good";
      if (this.form >= -10) return "form-normal";
      if (this.form >= -20) return "form-warning";
      return "form-danger";
    },
  },

  async mounted() {
    let ctx = this.$refs.stressGraph.getContext("2d");

    this.workouts = this.done;
    this.workouts42d = this.done42d;

    this.computeChartData();

    const config = {
      type: "line",
      data: this.chartdata,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.y;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.yAxes;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
      ],
    };

    new Chart(ctx, config);
    this.$emit("chartRendered");
  },

  methods: {
    formatDate: function (value) {
      return value.format("DD.MM");
    },
    getDayOfWeek: function (week, day) {
      return this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(day - 1, "d");
    },

    totalWorkoutLength(workoutFile) {
      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length;
    },

    computeAvg(arr) {
      const sum = arr.reduce((a, b) => a + b, 0);
      return sum / arr.length || 0;
    },

    computeWeightedAvg(arr) {
      const sum = arr.reduce((a, b, index) => a + b * (1 + index / arr.length), 0);
      return sum / arr.length || 0;
    },

    computeChartData: function () {
      // `this` points to the vm instance
      let smoothed = [];
      let smoothed42 = [];

      while (this.from.isSameOrBefore(this.until, "day")) {
        let tomorrow = this.$moment(this.from);
        tomorrow.add(1, "d");

        let workoutsOfDate = this.workouts.filter((workout) => {
          return (
            this.$moment(workout.date).isSameOrAfter(this.from) &&
            this.$moment(workout.date).isBefore(this.$moment(tomorrow))
          );
        });

        let durationSwim = 0;
        let durationBike = 0;
        let durationRun = 0;
        let durationKraft = 0;

        workoutsOfDate.forEach((workout) => {
          if (workout.sport == "Laufen") {
            durationRun += workout.pss;
          }
          if (workout.sport == "Radfahren") {
            durationBike += workout.pss;
          }
          if (workout.sport == "Schwimmen") {
            durationSwim += workout.pss;
          }
          if (workout.sport == "Kraft") {
            durationKraft += workout.pss;
          }
        });

        smoothed.push(durationRun + durationBike + durationSwim + durationKraft);
        smoothed42.push(durationRun + durationBike + durationSwim + durationKraft);

        if (smoothed.length > 10) {
          smoothed.shift();
        }
        if (smoothed42.length > 42) {
          smoothed42.shift();
        }

        if (this.from.isSameOrAfter(this.fromDay)) {
          this.chartdata.labels.push(this.formatDate(this.from));

          if (this.$moment(this.from).isSameOrBefore(this.$moment())) {
            this.chartdata.datasets[0].data.push(Math.round(this.computeWeightedAvg(smoothed42)));
            this.chartdata.datasets[1].data.push(Math.round(this.computeWeightedAvg(smoothed)));
            this.chartdata.datasets[2].data.push(
              Math.round(this.computeWeightedAvg(smoothed42) - this.computeWeightedAvg(smoothed))
            );
          }
        }

        this.today = smoothed[smoothed.length - 1];

        this.from = this.from.add(1, "d");
      }

      const fit = this.computeWeightedAvg(smoothed42);
      const fatigue = this.computeWeightedAvg(smoothed);
      this.form = Math.round(fit - fatigue);
      this.fitness = Math.round(fit);
      this.ermuedung = Math.round(fatigue);

      return this.chartdata;
    },
  },
};
</script>

<style scoped>
.metrics-container {
  padding: 1.5rem;
  border-radius: 12px;
  background: white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.metrics-grid {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.metric-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  transition: transform 0.2s ease;
  min-width: 100px;
}

.metric-card:hover {
  transform: translateY(-2px);
}

.metric-label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #6c757d;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.metric-value {
  font-size: 1.8rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  min-width: 60px;
  text-align: center;
}

/* Color classes for Form values - now using blue tones */
.form-excellent {
  background: #e3f2fd;
  color: #1565c0;
  border: 1px solid #64b5f6;
}
.form-good {
  background: #e3f2fd;
  color: #1976d2;
  border: 1px solid #64b5f6;
}
.form-normal {
  background: #e3f2fd;
  color: #1e88e5;
  border: 1px solid #64b5f6;
}
.form-warning {
  background: #e3f2fd;
  color: #2196f3;
  border: 1px solid #64b5f6;
}
.form-danger {
  background: #e3f2fd;
  color: #42a5f5;
  border: 1px solid #64b5f6;
}

/* Other metric colors - today now using green tones */
.stress {
  background: #fbe9e7;
  color: #d84315;
  border: 1px solid #ffab91;
}
.fitness {
  background: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #81c784;
}
.today {
  background: #f5f5f5;
  color: #424242;
  border: 1px solid #bdbdbd;
}

@media (max-width: 768px) {
  .metrics-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
  }

  .metric-card {
    min-width: unset;
    width: 100%;
  }

  .metric-value {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .metric-card {
    flex: 1 1 100%;
  }
}
</style>
