<template>
  <div class="statistics">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="submenu">
            <router-link to="/esszimmer/tracker" class="submenu-item">Tracker</router-link>
            <router-link to="/esszimmer/statistics" class="submenu-item active">Statistiken</router-link>
          </div>
        </div>
        <div class="col-md-9">
          <h1>Ernährungsstatistiken</h1>

          <div class="date-picker">
            <label for="start-date">Von:</label>
            <input type="date" id="start-date" v-model="startDate" @change="fetchData">
            <label for="end-date">Bis:</label>
            <input type="date" id="end-date" v-model="endDate" @change="fetchData">
          </div>

          <div class="charts">
            <div class="chart">
              <h2>Kalorienaufnahme</h2>
              <calorie-chart :entries="entries" :key="chartKey" />
            </div>
            <div class="chart">
              <h2>Makronährstoffe</h2>
              <macronutrient-chart :entries="entries" :key="chartKey" />
            </div>
            <div class="chart">
              <h2>Mahlzeitenbewertung</h2>
              <meal-rating-chart :entries="entries" :key="chartKey" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CalorieChart from './CalorieChart.vue';
import MacronutrientChart from './MacronutrientChart.vue';
import MealRatingChart from './MealRatingChart.vue';

export default {
  name: 'Statistics', // Add this line to explicitly name the component
  components: {
    CalorieChart,
    MacronutrientChart,
    MealRatingChart,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      entries: [],
      chartKey: 0,
    };
  },
  mounted() {
    const today = new Date();
    this.endDate = today.toISOString().split('T')[0];
    this.startDate = new Date(today.setMonth(today.getMonth() - 1)).toISOString().split('T')[0];
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(`${this.$host}/food/statistics`, {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
          },
        });
        this.entries = response.data;
        this.chartKey++; // Force chart re-render
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    },
  },
};
</script>

<style scoped>
/* Update the submenu styles */
.submenu {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.submenu-item {
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #333;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-bottom: 0.5rem;
}

.submenu-item:hover, .submenu-item.active {
  background-color: #007bff;
  color: white;
}

.date-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.date-picker label {
  margin-right: 0.5rem;
}

.date-picker input {
  margin-right: 1rem;
}

.charts {
  display: grid;
  gap: 2rem;
}

.chart {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 1rem;
}

h1, h2 {
  text-align: center;
}
</style>