<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>
<script>
import { Chart } from "chart.js";
import moment from "moment";

export default {
  props: ["data", "type", "chartLabel"],

  data: function () {
    return {
      workouts: [],
      chartType: this.type,
      chartdata: {
        labels: [
          "<90",
          "100",
          "110",
          "120",
          "130",
          "140",
          "150",
          "160",
          "170",
          "180",
          "190",
          "200+",
        ],
        datasets: [
          {
            fill: true,
            borderRadius: 3,

            backgroundColor: "hsl(356, 100%, 41%)",
            data: [],
            tension: 0.4,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let days = 0;
                let ret = "";
                let tmpSeconds = context.parsed.y;
                while (tmpSeconds > 24 * 60 * 60) {
                  days += 1;
                  tmpSeconds -= 24 * 60 * 60;
                }
                if (days > 0) {
                  ret += days + "/";
                }

                return (
                  ret +
                  moment().startOf("day").seconds(tmpSeconds).format("H:mm[h]")
                );
              },
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },

        elements: {
          point: {
            radius: 0,
          },
        },
        animation: {
          duration: 0,
        },

        scales: {
          yAxes: {
            ticks: {
              maxTicksLimit: 5,
              callback: function (seconds) {
                let days = 0;
                let ret = "";
                let tmpSeconds = seconds;

                while (tmpSeconds > 24 * 60 * 60) {
                  days += 1;
                  tmpSeconds -= 24 * 60 * 60;
                }
                if (days > 0) {
                  ret += days + "/";
                }

                return (
                  ret +
                  moment().startOf("day").seconds(tmpSeconds).format("H:mm[h]")
                );
              },
            },
          },
          xAxes: {
            title: {
              display: true,
              text: this.chartLabel,
            },
            gridLines: {
              display: false,
            },
            ticks: {
              display: true,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  mounted() {
    let ctx = this.$refs.graph.getContext("2d");

    if (this.chartType == "power") {
      this.chartdata.labels = [
        "80",
        "100",
        "120",
        "140",
        "160",
        "180",
        "200",
        "220",
        "240",
        "260",
        "280",
        "300",
        "320",
        "340",
        "360",
        "380",
        "400",
        "420",
        "440",
        "460",
        "480",
        "500+",
      ];

      this.chartdata.datasets[0].backgroundColor = "hsl(226, 65%, 35%)";
    }

    if (this.chartType == "run") {
      this.chartdata.labels = [
        ">8:30",
        "7:30",
        "6:40",
        "6:00",
        "5:30",
        "5:00",
        "4:35",
        "4:20",
        "4:00",
        "3:45",
        "3:30",
        "3:20 ",
        "3:10",
        "<3:00",
      ];

      this.chartdata.datasets[0].backgroundColor = "hsl(126, 65%, 35%)";
    }
    this.computeChartData();
    const config = {
      type: "bar",
      data: this.chartdata,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.yAxes;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
      ],
    };
    new Chart(ctx, config);
  },

  methods: {
    computeChartData: function () {
      this.chartdata.datasets[0].data = this.data;

      return this.chartdata;
    },
  },
};
</script>