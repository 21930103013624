import moment from 'moment-timezone'

// TODO:Name....
let compute_colors = {
    colorForDuration: function (durationShould, durationIs, date) {

        if (!date) {
            // Kein Datum => Vergangenheit
            date = new Date();
            date.setDate(date.getDate() - 1);
        }

        if (durationShould == -1) { return "gray" }
        // Noch nicht durchgefuehrt
        if ((durationIs == undefined || durationIs == 0) && !this.isInPast(date) || (durationShould == 0)) { return "#333" }

        else if (durationShould * 0.80 <= durationIs && durationIs <= durationShould * 1.2) { return "#00A300" }

        else if (durationShould * 0.50 <= durationIs && durationIs <= durationShould * 1.5) { return "#EECE00" }

        else if (durationIs > 0) { return "#FFA500" }

        else { return "#D10000" }
    },


    isInPast: function (date) { return moment(date).isBefore() },
    isInPastNotToday: function (date) { return moment(date).isBefore(moment().startOf('day')) },

    computeAvgPowerFromWorkoutFile(workoutFile, ftp) {
        let accumumlatedPower = 0;
        let totalDuration = 1;
        workoutFile.forEach(o => {
            let Duration = o.Duration || 0;
            let OnDuration = o.OnDuration || 0;
            let OffDuration = o.OnDuration || 0;

            let OnPower = o.OnPower || 0;
            let OffPower = o.OffPower || 0;
            let Power = o.Power || 0;
            let PowerLow = o.PowerLow || 0;
            let PowerHigh = o.PowerHigh || 0;
            let Repeat = o.Repeat || 0;

            totalDuration += Duration + (OnDuration + OffDuration) * Repeat;
            accumumlatedPower += Math.max(Duration * Power, (OnDuration * OnPower + OffDuration * OffPower) * Repeat, Duration * ((PowerLow + PowerHigh) / 2))

        })

        return Math.round((accumumlatedPower / totalDuration) / 100 * ftp);

    },

    computeSpeedFromWorkoutFile(workoutFile, threshold) {
        // TODO

        //....500+500*(1-0,8)
        let accumumlatedPower = 0;
        let totalDuration = 1;
        workoutFile.forEach(o => {
            let Duration = o.Duration || 0;
            let OnDuration = o.OnDuration || 0;
            let OffDuration = o.OnDuration || 0;

            let OnPower = o.OnPower || 0;
            let OffPower = o.OffPower || 0;
            let Power = o.Power || 0;
            let PowerLow = o.PowerLow || 0;
            let PowerHigh = o.PowerHigh || 0;
            let Repeat = o.Repeat || 0;

            totalDuration += Duration + (OnDuration + OffDuration) * Repeat;
            accumumlatedPower += Math.max(Duration * Power, (OnDuration * OnPower + OffDuration * OffPower) * Repeat, Duration * ((PowerLow + PowerHigh) / 2))

        })

        return Math.round((threshold / ((accumumlatedPower || 1) / (totalDuration || 1) / 100)));

    }




}




export default compute_colors;