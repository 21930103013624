<template>
  <div style="overflow-x: hidden; margin: -15px; margin-top: 0">
    <div
      class="desktop-border-radius"
      :style="{
        height: '100%',
        width: '100%',
        minHeight: '650px',

        background: 'url(https://assets.pushinglimits.club/swimdalle.webp) no-repeat ' + 'top/cover ',
      }"
    >
      <div class="row">
        <div class="col-lg-5 col-xl-4 col-md-7">
          <div class="white_wrapper light-shadow" style="margin: 25px">
            <div class="centered">
              <img
                class="mainlogo"
                alt="PL logo"
                style="box-shadow: none; width: 180px"
                v-bind:src="
                  $client.getClient() == 'pushinglimits'
                    ? require('@/assets/images/PL-Club-Logo.png')
                    : require('@/assets/images/' + $client.getClient() + '.jpg')
                "
              />
            </div>

            <div v-if="loginError" class="top-spacer-25">
              <h5>
                <span style="color: red">{{ $t("loginError") }}</span>
                <hr />
                <small>{{ $t("passwordNote") }}</small>
              </h5>
            </div>
            <form class="login top-spacer-25" @submit.prevent="login">
              <h2>{{ $t("loginHeading") }}</h2>
              <input
                class="top-spacer-15"
                required
                v-model="email"
                ref="email"
                name="email"
                type="email"
                placeholder="deine@email.de"
              />
              <input
                class="top-spacer-10"
                required
                ref="password"
                name="pw"
                v-model="password"
                type="password"
                placeholder="Passwort"
              />

              <button style="width: 300px" class="top-spacer-10" type="submit">Login</button>
              <br /><br />
              <strong
                ><a href="/#/passwortreset">{{ $t("Passwort vergessen?") }}</a>
                &nbsp;/&nbsp;
                <a href="/#/register">{{ $t("Noch keinen Account?") }}</a></strong
              >
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 top-spacer-50">
        <h3>{{ $t("Noch keinen Account?") }}</h3>
        <a href="/#/register"
          ><button style="width: 350px">
            {{ $t("Neuen Account anlegen!") }}
          </button></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

// Add storageHelper
const storageHelper = {
  async getItem(key) {
    const { value } = await Preferences.get({ key });
    try {
      return value ? JSON.parse(value) : null;
    } catch {
      return value;
    }
  },
  async setItem(key, value) {
    const stringValue = typeof value === "object" ? JSON.stringify(value) : value.toString();
    await Preferences.set({ key, value: stringValue });
  },
};

export default {
  metaInfo: {
    title: "Login",
  },
  data() {
    return {
      platform: Capacitor.getPlatform(),
      email: "",
      password: "",
      loginError: false,
    };
  },
  async created() {
    if (this.$store.getters.isLoggedIn) {
      try {
        await this.$store.dispatch("refresh_user");
        this.$router.push("/training/dashboard");
      } catch (err) {
        console.error("Error refreshing user data:", err);
        await this.$store.dispatch("logout");
      }
    }
  },
  methods: {
    async login() {
      console.log("Start Login on platform: " + this.platform);
      const _self = this;
      let email = this.email || this.$refs.email.value;
      let password = this.password || this.$refs.password.value;

      try {
        if (this.platform != "web") {
          const response = await this.$store.dispatch("login", { email, password });
          if (response.data && response.data.user) {
            await storageHelper.setItem("user", response.data.user);
          }
          this.$parent.updateStore();
          this.$router.push("/training/dashboard");
        } else {
          const response = await this.$store.dispatch("session_login", { email, password });
          if (response.data && response.data.user) {
            await storageHelper.setItem("user", response.data.user);
          }
          this.$router.push("/training/dashboard");
        }
      } catch (err) {
        _self.loginError = true;
        console.error("Login error:", err);
      }
    },
  },
  errorCaptured(err) {
    console.error("Error in Login component:", err);
    this.loginError = true;
    return false;
  },
};
</script>

<style scoped>
input {
  width: 300px !important;
}

.mainlogo {
  max-width: 200px;
}

a {
  color: blue;
}
</style>
