<template>
  <div>
    <canvas ref="graph"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment";

export default {
  props: {
    from: { type: [String, Object], required: true },
    until: { type: [String, Object], required: true },
    done: { type: Array, default: () => [] },
  },

  data() {
    return {
      workouts: this.done, // Initialisierung mit den übergebenen Workouts

      chartdata: {
        labels: [],
        datasets: [
          {
            label: "Schwimmen",
            backgroundColor: "#255f85",
            data: [],
            borderRadius: 3,
          },
          {
            label: "Rad",
            backgroundColor: "#E9724C",
            data: [],
            borderRadius: 3,
          },
          {
            label: "Laufen",
            backgroundColor: "#FFC857",
            data: [],
            borderRadius: 3,
          },
          {
            label: "Athletik",
            backgroundColor: "#481D24",
            data: [],
            borderRadius: 3,
          },
          /* {
            label: "Aktivität",
            backgroundColor: "#9DBF9E",
            data: [],
            borderRadius: 3,
          },*/
        ],
      },
      options: {
        animation: { duration: 0 },
        plugins: {
          title: {
            display: true,
            text: "Belastung",
            position: "left",
          },
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                return `${context.dataset.label}: ${Math.round(context.parsed.y * 10) / 10} Load`;
              },
            },
          },
        },
        scales: {
          y: {
            stacked: true,
            ticks: {
              maxTicksLimit: 10,
              beginAtZero: true,
              callback: function (value) {
                return Math.round(value);
              },
            },
          },
          x: {
            grid: { display: false },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  mounted() {
    this.initializeChart();
  },

  methods: {
    formatDate(value) {
      return moment(value).format("DD.MM");
    },

    computeChartData() {
      let fromDate = moment(this.from);
      const untilDate = moment(this.until);

      while (fromDate.isSameOrBefore(untilDate, "day")) {
        let tomorrow = fromDate.clone().add(1, "day");

        let workoutsOfDate = this.workouts.filter(
          (workout) => moment(workout.date).isSameOrAfter(fromDate) && moment(workout.date).isBefore(tomorrow)
        );

        let load = { Schwimmen: 0, Radfahren: 0, Laufen: 0, Kraft: 0 };

        workoutsOfDate.forEach((workout) => {
          if(workout.pss) {
          load[workout.sport] += workout.pss;
        }
        });

        this.chartdata.labels.push(this.formatDate(fromDate));
        Object.keys(load).forEach((sport, index) => {
          if (sport != "Aktivität" && sport != 'Wettkampf' && sport != 'Notiz') {
            this.chartdata.datasets[index].data.push(Math.round(load[sport]));
          }
        });

        fromDate.add(1, "day");
      }
    },

    initializeChart() {
      const ctx = this.$refs.graph.getContext("2d");
      this.computeChartData();
      new Chart(ctx, {
        type: "bar",
        data: this.chartdata,
        options: this.options,
      });
      this.$emit("chartRendered");
    },
  },
};
</script>
