<template>
  <div class="mobile-centered">
    <div v-if="useWorkoutBuilder && sport == 'Schwimmen'">
      <div class="row top-spacer-25">
        <div class="col-lg-4">
          <button style="margin-bottom: 2px" class="segment-button" v-on:click="addSwimSteadyStateToWorkoutFile()">
            Steady State
          </button>
        </div>
        <div class="col-lg-4" style="margin-bottom: 2px">
          <button class="segment-button" v-on:click="addSwimRestToWorkoutFile()">Pause</button>
        </div>
        <div class="col-lg-4" style="margin-bottom: 2px">
          <button class="segment-button" v-on:click="addSwimIntervalToWorkoutFile()">Intervall</button>
        </div>
      </div>
      <div class="row top-spacer-25" v-if="workoutFile.length == 0">
        <div class="col-lg-12 centered">
          <strong
            >Klicke auf einen der Buttons, um ein Element hinzuzufügen.<br />Du kannst die hinzugefügten Elemente per
            Drag & Drop verschieben</strong
          >
        </div>
      </div>
      <div class="row top-spacer-25">
        <div class="col-lg-12" style="overflow-x: scroll !important">
          <draggable v-model="workoutFile" @start="drag = true" @end="drag = false" class="workout-parent wo">
            <span
              class="wo-wrap"
              v-for="value in workoutFile"
              :key="value.id"
              v-bind:height="75"
              v-bind:class="{ active: isSelectedSegment(value.id) }"
            >
              <svg
                v-if="value.type == 'SteadyState'"
                class="workoutBuilderBlock"
                @click="selectBlock"
                v-bind:width="(value.Distance || 100) / 15"
                v-bind:height="75"
              >
                <rect
                  v-bind:id="value.id"
                  v-bind:width="(value.Distance || 100) / 15"
                  v-bind:height="50"
                  v-bind:style="'fill:' + colorForPower(value.Power)"
                />
              </svg>

              <svg
                v-if="value.type == 'Rest'"
                class="workoutBuilderBlock"
                @click="selectBlock"
                v-bind:width="value.Duration / 5"
                v-bind:height="75"
              >
                <rect
                  v-bind:id="value.id"
                  v-bind:width="value.Duration / 5"
                  v-bind:height="50"
                  style="fill: rgba(94, 110, 255, 0.5)"
                />
              </svg>

              <span v-if="value.type == 'IntervalsRest'">
                <span @click="selectBlock">
                  <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                    <svg class="workoutBuilderBlock" v-bind:width="(value.Distance || 100) / 15" v-bind:height="75">
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="(value.Distance || 100) / 15"
                        v-bind:height="75"
                        style="fill: darkred"
                      />
                    </svg>
                    <svg class="workoutBuilderBlock" v-bind:width="(value.Rest || 0) / 5" v-bind:height="75">
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="(value.Rest || 0) / 5"
                        v-bind:height="35"
                        v-bind:style="'fill: rgba(94, 110, 255, 0.67)'"
                      />
                    </svg>
                  </span>
                </span>
              </span>
            </span>
          </draggable>
          <div class="row" v-if="selectedWorkoutItem && selectedWorkoutItem.id != undefined">
            <div class="col-lg-12 centerd">
              <br />

              <a class="block" @click="deleteSelectedBlock" style="border-radius: 5px">Gewählten Block entfernen</a
              >&nbsp; <a class="block" @click="copySelectedBlock" style="border-radius: 5px">Kopieren</a>&nbsp;
              <br />
            </div>
          </div>
          <div class="row top-spacer-25">
            <div class="col-lg-12" v-if="selectedWorkoutItem">
              <div class="row" v-if="selectedWorkoutItem.type == 'SteadyState'">
                <div class="col-lg-4 interval-card top-spacer-10">
                  <strong>Distanz</strong><br />
                  <input
                    style="width: 100%"
                    @input="setSwimDistance($event)"
                    v-model.number="selectedWorkoutItem.Distance"
                  /><br />
                  (in Metern)
                </div>
                <div class="col-lg-4 interval-card top-spacer-10">
                  <strong>Schwimmstil</strong><br />
                  <!-- BACKSTROKE, BREASTSTROKE, DRILL, BUTTERFLY, FREESTYLE, MIXED, IM-->
                  <select style="width: 100%" v-model="selectedWorkoutItem.StrokeType" @input="setSwimstroke($event)">
                    <option value="FREESTYLE">Freistil</option>
                    <option value="BACKSTROKE">Rücken</option>
                    <option value="BREASTSTROKE">Brust</option>
                    <option value="BUTTERFLY">Schmetterling</option>
                    <option value="IM">Lagen</option>
                    <option value="DRILL">Drill</option>
                    <option value="MIXED">Mixed</option>
                  </select>
                </div>

                <div class="col-lg-4 interval-card top-spacer-10">
                  <strong>Beschreibung</strong><br />
                  <textarea
                    style="width: 100%"
                    @input="setDescription($event)"
                    v-model.number="selectedWorkoutItem.Description"
                  ></textarea
                  ><br />
                </div>
                <!--
                <div class="col-lg-12 interval-card top-spacer-25">
                  <input
                    style="display: inline-block; height: inherit"
                    type="checkbox"
                    v-model="selectedWorkoutItem.endOnLapButton"
                  />
                  Mit Lap-Button beenden<br /><small
                    >muss vom Gerät unterstützt werden</small
                  >
                </div>-->
              </div>

              <div class="row" v-if="selectedWorkoutItem.type == 'Rest'">
                <div class="col-lg-4 interval-card">
                  <strong>Dauer</strong><br />
                  <input
                    min="1"
                    max="999"
                    v-model.number="selectedWorkoutItem.Duration"
                    type="number"
                    style="width: 100%; max-width: 180px"
                  />
                  <br />(In Sekunden)
                </div>
                <div class="col-lg-8 interval-card">
                  <strong>Beschreibung</strong><br />
                  <textarea
                    style="width: 100%"
                    @input="setDescription($event)"
                    v-model.number="selectedWorkoutItem.Description"
                  ></textarea
                  ><br />
                </div>
                <!--
                <div class="col-lg-12 interval-card top-spacer-25">
                  <input
                    style="display: inline-block; height: inherit"
                    type="checkbox"
                    v-model="selectedWorkoutItem.endOnLapButton"
                  />
                  Mit Lap-Button beenden<br /><small
                    >muss vom Gerät unterstützt werden</small
                  >
                </div>-->
              </div>

              <span v-if="selectedWorkoutItem.type == 'IntervalsRest'">
                <div class="row top-spacer-15">
                  <div class="col-lg-4 interval-card top-spacer-10">
                    <strong>Wiederholungen</strong><br />
                    <input style="width: 100%" v-model.number="selectedWorkoutItem.Repeat" type="number" />
                  </div>

                  <div class="col-lg-4 interval-card top-spacer-10">
                    <strong>Distanz</strong><br />
                    <input
                      style="width: 100%"
                      @input="setDistance()"
                      v-model.number="selectedWorkoutItem.Distance"
                    /><br />
                    (in Metern)
                  </div>
                  <div class="col-lg-4 interval-card top-spacer-10">
                    <strong>Pause</strong><br />
                    <input
                      min="1"
                      max="999"
                      v-model.number="selectedWorkoutItem.Rest"
                      type="number"
                      style="width: 100%"
                    />
                    <br />(In Sekunden)
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 interval-card top-spacer-25">
                    <strong>Schwimmstil</strong><br />
                    <!-- BACKSTROKE, BREASTSTROKE, DRILL, BUTTERFLY, FREESTYLE, MIXED, IM-->
                    <select style="width: 100%" v-model="selectedWorkoutItem.StrokeType" @input="setSwimstroke($event)">
                      <option value="FREESTYLE">Freistil</option>
                      <option value="BACKSTROKE">Rücken</option>
                      <option value="BREASTSTROKE">Brust</option>
                      <option value="BUTTERFLY">Schmetterling</option>
                      <option value="IM">Lagen</option>
                      <option value="DRILL">Drill</option>
                      <option value="MIXED">Mixed</option>
                    </select>
                  </div>
                  <div class="col-lg-8 interval-card top-spacer-25">
                    <strong>Beschreibung</strong><br />
                    <textarea
                      style="width: 100%"
                      @input="setDescription($event)"
                      v-model.number="selectedWorkoutItem.Description"
                    ></textarea
                    ><br />
                  </div>
                </div>
                <!--
                <div class="row">
                  <div class="col-lg-6 interval-card top-spacer-25">
                    <div>
                      <input
                        style="display: inline-block; height: inherit"
                        type="checkbox"
                        v-model="selectedWorkoutItem.endOnLapButtonOnDuration"
                      />
                      Intervall mit Lap-Button beenden<br /><small
                        >muss vom Gerät unterstützt werden</small
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 interval-card top-spacer-25">
                    <div>
                      <input
                        style="display: inline-block; height: inherit"
                        type="checkbox"
                        v-model="selectedWorkoutItem.endOnLapButtonOffDuration"
                      />
                      Pause mit Lap-Button beenden<br /><small
                        >muss vom Gerät unterstützt werden</small
                      >
                    </div>
                  </div>
                </div>-->
              </span>

              <div class="row">
                <div class="col-lg-12 top-spacer-75">
                  <button class="closer" @click="saveWorkoutBuilder()">Fertig</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="useWorkoutBuilder && sport != 'Schwimmen'">
      <div class="row top-spacer-25">
        <div class="col-lg-3">
          <button style="margin-bottom: 2px" class="segment-button" v-on:click="addSteadyStateToWorkoutFile()">
            Steady State
          </button>
        </div>
        <div class="col-lg-3" style="margin-bottom: 2px">
          <button class="segment-button" v-on:click="addRampToWorkoutFile()">Rampe</button>
        </div>
        <div class="col-lg-3" style="margin-bottom: 2px">
          <button class="segment-button" v-on:click="addIntervalToWorkoutFile()">Intervall</button>
        </div>
        <div class="col-lg-3" style="margin-bottom: 2px">
          <button class="segment-button" v-on:click="addFreeRideToWorkoutFile()">Beliebig</button>
        </div>
      </div>

      <div class="col-lg-12" v-if="workoutFile.length > 0">
        <br />Länge:
        <span
          >{{ formattedTime(totalWorkoutLength()) }} |
          <span v-if="sport == 'Radfahren'"> Ø {{ computeAvgWatt(workoutFile) }}w</span>
          <span v-if="sport == 'Laufen'">Ø {{ computeAvgRunSpeed(workoutFile) }}m/km</span>
          <span v-if="sport == 'Schwimmen'"> Ø {{ computeAvgSwimSpeed(workoutFile) }}m/km</span></span
        >
      </div>
      <div class="row top-spacer-25" v-if="workoutFile.length == 0">
        <div class="col-lg-12 centered">
          <strong
            >Klicke auf einen der Buttons, um ein Element hinzuzufügen.<br />Du kannst die hinzugefügten Elemente per
            Drag & Drop verschieben</strong
          >
        </div>
      </div>
      <div class="row top-spacer-25">
        <div class="col-lg-12" style="overflow-x: scroll !important">
          <draggable v-model="workoutFile" @start="drag = true" @end="drag = false" class="workout-parent wo">
            <span
              class="wo-wrap"
              v-for="value in workoutFile"
              :key="value.id"
              v-bind:height="2 * workoutBuilderHeight()"
              v-bind:class="{ active: isSelectedSegment(value.id) }"
            >
              <svg
                v-if="value.type == 'SteadyState'"
                class="workoutBuilderBlock"
                @click="selectBlock"
                v-bind:width="value.Duration / 10"
                v-bind:height="workoutBuilderHeight()"
              >
                <rect
                  v-bind:id="value.id"
                  v-bind:width="value.Duration / 10"
                  v-bind:height="value.Power"
                  v-bind:style="'fill:' + colorForPower(value.Power)"
                />
              </svg>

              <svg
                v-if="value.type == 'FreeRide'"
                class="workoutBuilderBlock"
                @click="selectBlock"
                v-bind:width="value.Duration / 10"
                v-bind:height="100"
              >
                <rect
                  v-bind:id="value.id"
                  v-bind:width="value.Duration / 10"
                  v-bind:height="80"
                  style="fill: rgba(94, 110, 255, 0.5)"
                />
              </svg>

              <svg
                v-if="value.type == 'Ramp'"
                class="workoutBuilderBlock"
                @click="selectBlock"
                v-bind:width="value.Duration / 10"
                v-bind:height="workoutBuilderHeight()"
              >
                <path
                  v-bind:id="value.id"
                  v-bind:d="
                    'M 1 ' + value.PowerLow + ' L ' + value.Duration / 10 + ' ' + value.PowerHigh + ' V 0 H 1 Z'
                  "
                  v-bind:style="'fill:' + colorForPower((value.PowerLow + value.PowerHigh) / 2)"
                />
              </svg>

              <span v-if="value.type == 'IntervalsT'">
                <span @click="selectBlock">
                  <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                    <svg
                      class="workoutBuilderBlock"
                      v-bind:width="value.OnDuration / 10"
                      v-bind:height="workoutBuilderHeight()"
                    >
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="value.OnDuration / 10"
                        v-bind:height="value.OnPower"
                        v-bind:style="'fill:' + colorForPower(value.OnPower)"
                      />
                    </svg>
                    <svg
                      class="workoutBuilderBlock"
                      v-bind:width="value.OffDuration / 10"
                      v-bind:height="workoutBuilderHeight()"
                    >
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="value.OffDuration / 10"
                        v-bind:height="value.OffPower"
                        v-bind:style="'fill:' + colorForPower(value.OffPower)"
                      />
                    </svg>
                  </span>
                </span>
              </span>
            </span>
          </draggable>
        </div>
      </div>
      <div class="row" v-if="selectedWorkoutItem && selectedWorkoutItem.id != undefined">
        <div class="col-lg-12 centerd">
          <br />

          <a class="block" @click="deleteSelectedBlock" style="border-radius: 5px">Gewählten Block entfernen</a>&nbsp;
          <a class="block" @click="copySelectedBlock" style="border-radius: 5px">Kopieren</a>&nbsp;
          <a
            v-if="mode == 'Duration' && sport == 'Laufen' && selectedWorkoutItem.type != 'Ramp'"
            class="block"
            @click="mode = 'Distance'"
            style="border-radius: 5px"
            >Zeitmodus</a
          >
          <a
            v-if="mode == 'Distance' && sport == 'Laufen' && selectedWorkoutItem.type != 'Ramp'"
            class="block"
            @click="
              mode = 'Duration';
              selectedWorkoutItem.Distance = 0;
              selectedWorkoutItem.OnDistance = 0;
              selectedWorkoutItem.OffDistance = 0;
            "
            style="border-radius: 5px"
            >Distanzmodus</a
          >
        </div>
      </div>
      <div class="row top-spacer-25">
        <div class="col-lg-12" v-if="selectedWorkoutItem">
          <div class="row" v-if="selectedWorkoutItem.type == 'SteadyState'">
            <div v-if="mode == 'Duration'" class="col-lg-4 interval-card">
              <strong>Dauer</strong><br />
              <input @input="setDuration($event)" v-model="builderDuration" /><br />
              (h:mm:ss)
            </div>
            <div v-if="mode == 'Distance'" class="col-lg-4 interval-card">
              <strong>Distanz</strong><br />
              <input @input="setDistance($event)" v-model.number="selectedWorkoutItem.Distance" /><br />
              (in Metern)
            </div>
            <div class="col-lg-4 interval-card">
              <strong>Intensität</strong><br />
              <input
                @input="setIntensity()"
                v-model.number="selectedWorkoutItem.Power"
                min="1"
                max="400"
                type="number"
                style="width: 100%; max-width: 180px"
              />
              <br />(In %)
            </div>
            <div class="col-lg-4 interval-card" v-if="sport == 'Radfahren'">
              <strong>Trittfrequenz</strong><br />
              <input
                v-model.number="selectedWorkoutItem.Cadence"
                min="0"
                max="400"
                type="number"
                style="width: 100%; max-width: 180px"
              />
              <br />(0=Beliebig)
            </div>
            <div class="col-lg-12 interval-card top-spacer-25">
              <input
                style="display: inline-block; height: inherit"
                type="checkbox"
                v-model="selectedWorkoutItem.endOnLapButton"
              />
              Mit Lap-Button beenden<br /><small>muss vom Gerät unterstützt werden</small>
            </div>
          </div>

          <div class="row top-spacer-15" v-if="selectedWorkoutItem.type == 'FreeRide'">
            <div v-if="mode != 'Distance'" class="col-lg-6 interval-card">
              <strong>Dauer</strong><br />
              <input @input="setDuration($event)" v-model="builderDuration" /><br />
              (h:mm:ss)
            </div>

            <div v-if="mode == 'Distance'" class="col-lg-4 interval-card">
              <strong>Distanz</strong><br />
              <input @input="setDistance($event)" v-model.number="selectedWorkoutItem.Distance" /><br />
              (in Metern)
            </div>
            <div class="col-lg-6 interval-card" v-if="sport == 'Radfahren'">
              <strong>Trittfrequenz</strong><br />
              <input
                v-model.number="selectedWorkoutItem.Cadence"
                min="0"
                max="400"
                type="number"
                style="width: 100%; max-width: 180px"
              />
              <br />(0=Beliebig)
            </div>
            <div class="col-lg-12 interval-card top-spacer-25">
              <div>
                <input
                  style="display: inline-block; height: inherit"
                  type="checkbox"
                  v-model="selectedWorkoutItem.endOnLapButton"
                />
                Mit Lap-Button beenden<br /><small>muss vom Gerät unterstützt werden</small>
              </div>
            </div>
          </div>

          <span v-if="selectedWorkoutItem.type == 'Ramp'">
            <div class="row top-spacer-15">
              <div class="col-lg-4 interval-card">
                <strong>Dauer</strong><br />
                <input @input="setDuration($event)" v-model="builderDuration" />
                <br />(h:mm:ss)
              </div>

              <div class="col-lg-4 interval-card">
                <strong>Intensität Start</strong><br />
                <input
                  min="1"
                  max="400"
                  v-model.number="selectedWorkoutItem.PowerLow"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(In %)
              </div>
              <div class="col-lg-4 interval-card">
                <strong>Intensität Ende</strong><br />
                <input
                  v-model.number="selectedWorkoutItem.PowerHigh"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(In %)
              </div>
              <!--
              <div class="col-lg-4 interval-card" v-if="sport == 'Laufen'">
                <strong>Distanz</strong><br />
                <input
                  v-if="!builderDuration"
                  v-model.number="selectedWorkoutItem.Distance"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />

                <input
                  v-if="builderDuration"
                  v-bind:value="
                    Math.round(
                      (selectedWorkoutItem.Duration /
                        (runThreshold +
                          runThreshold *
                            (1 -
                              (selectedWorkoutItem.PowerLow +
                                selectedWorkoutItem.PowerHigh) /
                                2 /
                                100))) *
                        1000
                    )
                  "
                  type="number"
                  disabled
                  style="width: 100%; max-width: 180px"
                />

                <br />(In Metern)
              </div>

              -->
              <div class="col-lg-4 interval-card top-spacer-15" v-if="sport == 'Radfahren'">
                <strong>Trittfrequenz</strong><br />
                <input
                  v-model.number="selectedWorkoutItem.Cadence"
                  min="1"
                  max="400"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(0=Beliebig)
              </div>
            </div>
          </span>

          <span v-if="selectedWorkoutItem.type == 'IntervalsT'">
            <div class="row top-spacer-15">
              <div class="col-lg-4 interval-card">
                <strong>Wiederholungen</strong><br />
                <input v-model.number="selectedWorkoutItem.Repeat" type="number" />
              </div>
              <div v-if="mode != 'Distance'" class="col-lg-4 interval-card">
                <strong>Dauer</strong><br />
                <input @input="setOnDuration($event)" v-model="builderOnDuration" />
                <br />(h:mm:ss)
              </div>

              <div v-if="mode == 'Distance'" class="col-lg-4 interval-card">
                <strong>Distanz</strong><br />
                <input @input="setOnDistance()" v-model.number="selectedWorkoutItem.OnDistance" /><br />
                (in Metern)
              </div>
              <div class="col-lg-4 interval-card">
                <strong>Intensität</strong><br />
                <input
                  @input="setOnIntensity()"
                  min="1"
                  max="400"
                  v-model.number="selectedWorkoutItem.OnPower"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(In %)
              </div>
            </div>
            <div class="row top-spacer-15">
              <div class="col-lg-4"></div>
              <div v-if="mode == 'Distance'" class="col-lg-4 interval-card">
                <strong>Distanz</strong><br />
                <input @input="setOffDistance()" v-model.number="selectedWorkoutItem.OffDistance" /><br />
                (in Metern)
              </div>
              <div v-if="mode != 'Distance'" class="col-lg-4 interval-card">
                <strong>Dauer Pause</strong><br />
                <input @input="setOffDuration($event)" v-model="builderOffDuration" />
                <br />(h:mm:ss)
              </div>
              <div class="col-lg-4 interval-card">
                <strong>Intensität Pause</strong><br />
                <input
                  @input="setOffIntensity()"
                  min="1"
                  max="400"
                  v-model.number="selectedWorkoutItem.OffPower"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(In %)
              </div>
              <div class="col-lg-4"></div>

              <div class="col-lg-4 interval-card top-spacer-15" v-if="sport == 'Radfahren'">
                <strong>Trittfrequenz</strong><br />
                <input
                  v-model.number="selectedWorkoutItem.Cadence"
                  min="1"
                  max="400"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(0=Beliebig)
              </div>
              <div class="col-lg-4 interval-card top-spacer-15" v-if="sport == 'Radfahren'">
                <strong>Trittfrequenz Pause</strong><br />
                <input
                  v-model.number="selectedWorkoutItem.CadenceResting"
                  min="1"
                  max="400"
                  type="number"
                  style="width: 100%; max-width: 180px"
                />
                <br />(0=Beliebig)
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 interval-card top-spacer-25">
                <div>
                  <input
                    style="display: inline-block; height: inherit"
                    type="checkbox"
                    v-model="selectedWorkoutItem.endOnLapButtonOnDuration"
                  />
                  Intervall mit Lap-Button beenden<br /><small>muss vom Gerät unterstützt werden</small>
                </div>
              </div>
              <div class="col-lg-6 interval-card top-spacer-25">
                <div>
                  <input
                    style="display: inline-block; height: inherit"
                    type="checkbox"
                    v-model="selectedWorkoutItem.endOnLapButtonOffDuration"
                  />
                  Pause mit Lap-Button beenden<br /><small>muss vom Gerät unterstützt werden</small>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>

      <div class="row top-spacer-50" v-if="workoutFile && workoutFile.length > 0">
        <div class="col-lg-12" style="overflow-x: scroll">
          <table class="table" style="text-align: left">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Typ</th>

                <th scope="col">Segment</th>
                <th class="d-none d-md-block" scope="col" v-if="sport == 'Radfahren'">Trittfrequenz</th>
                <th scope="col" class="centered">Löschen</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(segment, index) in workoutFile"
                :key="segment.id + index"
                @click="selectBlock({}, segment.id)"
                class="segment-block"
                v-bind:style="selectedWorkoutItem.id == segment.id ? 'background:#ddd' : ''"
              >
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  <span v-if="segment.type == 'Ramp'">Rampe </span>
                  <span v-if="segment.type == 'SteadyState'"> Steady State </span>
                  <span v-if="segment.type == 'IntervalsT'"> Intervalle </span>
                  <span v-if="segment.type == 'FreeRide'"> Beliebig </span>
                </td>
                <td>
                  <span v-if="segment.type == 'Ramp'">
                    <span v-if="segment.Distance"> {{ segment.Distance }}m</span>
                    <span v-if="!segment.Distance">
                      {{ betterformattedTime(segment.Duration) }}
                    </span>
                    von
                    {{ segment.PowerLow }}% ({{ computeIntensityValues(segment.PowerLow) }}-{{
                      computeIntensityValuesHigh(segment.PowerLow)
                    }}) bis {{ segment.PowerHigh }}% ({{ computeIntensityValues(segment.PowerHigh) }}-{{
                      computeIntensityValuesHigh(segment.PowerHigh)
                    }}) // {{ intensityToHeartRate(segment.PowerLow).low }}bpm-{{
                      intensityToHeartRate(segment.PowerHigh).high
                    }}bpm
                  </span>
                  <span v-if="segment.type == 'SteadyState'">
                    <span v-if="segment.Distance"> {{ segment.Distance }}m</span>

                    <span v-if="!segment.Distance"> {{ betterformattedTime(segment.Duration) }}</span>
                    @
                    {{ segment.Power }}% ({{ computeIntensityValues(segment.Power) }}-{{
                      computeIntensityValuesHigh(segment.Power)
                    }}
                    // {{ intensityToHeartRate(segment.Power).low }}bpm-{{
                      intensityToHeartRate(segment.Power).high
                    }}bpm)
                  </span>
                  <span v-if="segment.type == 'IntervalsT'">
                    Wiederhole {{ segment.Repeat }} mal<br />
                    <span v-if="segment.OnDistance"> {{ segment.OnDistance }}m</span>
                    <span v-if="!segment.OnDistance"> {{ betterformattedTime(segment.OnDuration) }}</span>
                    @ {{ segment.OnPower }}% ({{ computeIntensityValues(segment.OnPower) }}-{{
                      computeIntensityValuesHigh(segment.OnPower)
                    }}
                    // {{ intensityToHeartRate(segment.OnPower).low }}bpm-{{
                      intensityToHeartRate(segment.OnPower).high
                    }}bpm) und<br />

                    <span v-if="segment.OffDistance"> {{ segment.OffDistance }}m</span>
                    <span v-if="!segment.OffDistance"> {{ betterformattedTime(segment.OffDuration) }}</span>
                    @ {{ segment.OffPower }}% ({{ computeIntensityValues(segment.OffPower) }}-{{
                      computeIntensityValuesHigh(segment.OffPower)
                    }}
                    // {{ intensityToHeartRate(segment.OffPower).low }}bpm-{{
                      intensityToHeartRate(segment.OffPower).high
                    }}bpm)
                  </span>
                  <span v-if="segment.type == 'FreeRide'">
                    {{ betterformattedTime(segment.Duration) }}
                    <span v-if="segment.Distance"> - {{ segment.Distance }}m</span>
                  </span>

                  <div class="d-block d-md-none" v-if="sport == 'Radfahren' && segment.Cadence > 0">
                    Trittfrequenz:
                    <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                    <span v-if="segment.Cadence == 0">Beliebig</span>
                    <span v-if="segment.CadenceResting != undefined">
                      /
                      <span v-if="segment.CadenceResting > 0">{{ segment.CadenceResting }}</span>
                      <span v-if="segment.CadenceResting == 0">Beliebig</span>
                    </span>
                  </div>
                </td>
                <td v-if="sport == 'Radfahren'" class="d-none d-md-block">
                  <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                  <span v-if="segment.Cadence == 0">Beliebig</span>
                  <span v-if="segment.CadenceResting != undefined">
                    /
                    <span v-if="segment.CadenceResting > 0">{{ segment.CadenceResting }}</span>
                    <span v-if="segment.CadenceResting == 0">Beliebig</span>
                  </span>
                </td>
                <td class="centered">
                  <span @click="deleteBlock(segment.id)" class="pointer">&#x2716;</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <button class="closer" @click="saveWorkoutBuilder()">Fertig</button>
        </div>
      </div>
    </div>

    <div class="row" v-if="!useWorkoutBuilder">
      <div class="col-lg-12" v-if="workout && workout.durationIs == 0 && workout.aiCoachRecommendationAlternative">
        <div class="row" style="background: #f3f3f3; border-radius: 10px; margin-bottom: 25px">
          <div class="col-12">
            <h5 class="top-spacer-15">Bessere Alternative</h5>

            <div class="row top-spacer-25">
              <div class="col-lg-6">
                <h5 class="top-spacer-15">
                  {{ workout.aiCoachRecommendationAlternative.name }}
                </h5>

                <div class="col-lg-12" style="padding-left: 25px; padding-right: 25px">
                  <span v-for="value in workout.aiCoachRecommendationAlternative.workoutFile" :key="value.id">
                    <svg
                      v-if="value.type == 'SteadyState'"
                      class="workoutBuilderBlock"
                      v-bind:width="computeWidth(value.Duration, workout.aiCoachRecommendationAlternative.workoutFile)"
                      v-bind:height="workoutBuilderHeight(workout.aiCoachRecommendationAlternative.workoutFile) / 2"
                    >
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="100 + '%'"
                        v-bind:height="value.Power / 3"
                        v-bind:style="'fill:' + colorForPower(value.Power)"
                      />
                    </svg>

                    <svg
                      v-if="value.type == 'Ramp'"
                      class="workoutBuilderBlock"
                      v-bind:width="computeWidth(value.Duration, workout.aiCoachRecommendationAlternative.workoutFile)"
                      v-bind:height="workoutBuilderHeight(workout.aiCoachRecommendationAlternative.workoutFile) / 2"
                    >
                      <rect
                        v-for="index in 20"
                        :key="index"
                        v-bind:id="value.id + '-' + index"
                        v-bind:width="100 / 20 + '%'"
                        v-bind:x="(100 / 20) * index - 5 + '%'"
                        v-bind:height="getRampRectHeight(20, value.PowerLow / 4, value.PowerHigh / 4, index)"
                        v-bind:style="
                          'fill:' + colorForPower(getRampRectHeight(20, value.PowerLow, value.PowerHigh, index))
                        "
                      />
                    </svg>

                    <svg
                      v-if="value.type == 'FreeRide'"
                      class="workoutBuilderBlock"
                      v-bind:width="value.Duration / 15"
                      v-bind:height="40"
                    >
                      <rect
                        v-bind:id="value.id"
                        v-bind:width="value.Duration / 15"
                        v-bind:height="400"
                        style="fill: rgba(94, 110, 255, 0.5)"
                      />
                    </svg>

                    <span v-if="value.type == 'IntervalsT'">
                      <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                        <svg
                          class="workoutBuilderBlock"
                          v-bind:width="
                            computeWidth(value.OnDuration, workout.aiCoachRecommendationAlternative.workoutFile)
                          "
                          v-bind:height="workoutBuilderHeight(workout.aiCoachRecommendationAlternative.workoutFile) / 2"
                        >
                          <rect
                            v-bind:id="value.id"
                            v-bind:width="'100%'"
                            v-bind:height="value.OnPower / 4"
                            v-bind:style="'fill:' + colorForPower(value.OnPower)"
                          />
                        </svg>
                        <svg
                          class="workoutBuilderBlock"
                          v-bind:width="
                            computeWidth(value.OffDuration, workout.aiCoachRecommendationAlternative.workoutFile)
                          "
                          v-bind:height="workoutBuilderHeight(workout.aiCoachRecommendationAlternative.workoutFile) / 2"
                        >
                          <rect
                            v-bind:id="value.id"
                            v-bind:width="'100%'"
                            v-bind:height="value.OffPower / 4"
                            v-bind:style="'fill:' + colorForPower(value.OffPower)"
                          />
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>

                <div class="row top-spacer-25" style="padding: 0 50px; padding-bottom: 25px">
                  <div class="col-4">
                    <h4 style="color: green">LIT</h4>
                    <h5>{{ workout.aiCoachRecommendationAlternative.estimate_time_lit }}%</h5>
                  </div>
                  <div class="col-4">
                    <h4 style="color: orange">MIT</h4>
                    <h5>{{ workout.aiCoachRecommendationAlternative.estimate_time_mit }}%</h5>
                  </div>
                  <div class="col-4">
                    <h4 style="color: red">HIT</h4>
                    <h5>{{ workout.aiCoachRecommendationAlternative.estimate_time_hit }}%</h5>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="top-spacer-15">
                  Basierend auf deinem Training und deinen geplanten Einheiten hat der KI Coach ein besser für diesen
                  Tag passendes Workout gefunden.

                  <br />
                  <button
                    class="top-spacer-25 secondary"
                    @click="changeAiWorkout(workout.aiCoachRecommendationAlternative)"
                  >
                    Einheit tauschen</button
                  ><br /><br />
                  <div class="centered"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12">
            <div>
              <form @submit.prevent="createWorkout">
                <div class="row top-spacer-25">
                  <div class="col-lg-3">
                    <strong v-if="!workout" class="pull-right-non-mobile">Datum</strong>
                    <strong v-if="workout" class="pull-right-non-mobile">Verschieben!</strong>
                  </div>
                  <div class="col-lg-9" style="text-align: center; text-align-last: center">
                    <input name="date" v-model="formDate" type="date" style="width: 100%" />
                  </div>
                </div>
                <div class="row top-spacer-25" v-if="sport != 'Notiz' && sport != 'Wettkampf'">
                  <div class="col-lg-3">
                    <strong class="pull-right-non-mobile">Name des Workouts</strong>
                  </div>
                  <div class="col-lg-9">
                    <input
                      required
                      v-model="name"
                      @click="name == 'Workout' ? (name = '') : ''"
                      type="text"
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row top-spacer-25" v-if="sport == 'Wettkampf'">
                  <div class="col-lg-3">
                    <strong class="pull-right-non-mobile">Name des Wettkampfs</strong>
                  </div>
                  <div class="col-lg-9">
                    <input
                      required
                      v-model="name"
                      @click="name == 'Wettkampf in ...' ? (name = '') : ''"
                      type="text"
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row top-spacer-25">
                  <div class="col-lg-3">
                    <strong class="pull-right-non-mobile">Sport</strong>
                  </div>
                  <div class="col-lg-9" style="text-align: center; text-align-last: center">
                    <select name="sport" v-model="sport" style="width: 100%">
                      <option selected>Laufen</option>
                      <option>Radfahren</option>
                      <option>Schwimmen</option>

                      <option value="Kraft">Athletik</option>
                      <option>Aktivität</option>

                      <option v-if="!wo || wo.sport == 'Notiz'" value="" disabled="disabled">──────</option>
                      <option v-if="!wo || wo.sport == 'Notiz'">Notiz</option>
                      <option
                        v-if="!wo || wo.sport == 'Wettkampf'"
                        @click="
                          name = 'Wettkampf in ...';
                          prioritaet = 'B';
                        "
                      >
                        Wettkampf
                      </option>
                      <option>Krank</option>
                    </select>
                  </div>
                </div>

                <div class="row top-spacer-25" v-if="sport == 'Wettkampf'">
                  <div class="col-lg-3">
                    <strong class="pull-right-non-mobile">Priorität</strong>
                  </div>
                  <div class="col-lg-9" style="text-align: center; text-align-last: center">
                    <select name="prioritaet" v-model="prioritaet" style="width: 100%">
                      <option value="A">A (höchste)</option>
                      <option value="B">B (mittel)</option>
                      <option value="C">C (niedrig)</option>
                    </select>
                  </div>
                </div>

                <div class="row top-spacer-25" v-if="sport == 'Wettkampf'">
                  <div class="col-lg-3">
                    <strong class="pull-right-non-mobile">Art des Wettkampfs</strong>
                  </div>
                  <div class="col-lg-9">
                    <select name="wettkampf_sport" v-model="wettkampf_sport" style="width: 100%">
                      <option @click="wettkampf_typ = 'Olympische Distanz'" selected>Triathlon</option>
                      <option @click="wettkampf_typ = '5km'">Laufen</option>
                      <option>Radfahren</option>
                      <option>Schwimmen</option>
                      <option>Duathlon</option>
                      <option>Andere</option>
                    </select>

                    <select
                      class="top-spacer-25"
                      v-if="wettkampf_sport == 'Triathlon'"
                      name="wettkampf_typ"
                      v-model="wettkampf_typ"
                      style="width: 100%"
                    >
                      <option selected>Volksdistanz</option>
                      <option>Olympische Distanz</option>
                      <option>Mitteldistanz</option>
                      <option>Langdistanz</option>
                    </select>

                    <select
                      class="top-spacer-25"
                      v-if="wettkampf_sport == 'Laufen'"
                      name="wettkampf_typ"
                      v-model="wettkampf_typ"
                      style="width: 100%"
                    >
                      <option selected>400m</option>
                      <option selected>800m</option>
                      <option selected>1600m</option>
                      <option selected>3000m</option>
                      <option selected>5km</option>
                      <option>10km</option>
                      <option>Halbmarathon</option>
                      <option>Marathon</option>
                    </select>
                  </div>
                </div>

                <div class="row top-spacer-25">
                  <div class="col-lg-3">
                    <strong class="pull-right-non-mobile" v-if="sport != 'Notiz' && sport != 'Wettkampf'"
                      >Beschreibung</strong
                    >
                    <strong class="pull-right-non-mobile" v-if="sport == 'Notiz'">Notiz</strong>
                  </div>
                  <div class="col-lg-9">
                    <textarea-autosize
                      required
                      v-model="description"
                      type="text"
                      placeholder="Beschreibung"
                      style="width: 100%"
                      :min-height="90"
                    />
                  </div>
                </div>

                <div v-if="sport == 'Schwimmen' && user.garminUserToken" class="row top-spacer-25">
                  <div class="col-lg-1"></div>

                  <div class="col-lg-4 col-12">
                    <strong class="pull-right-non-mobile">Pool Länge</strong>
                  </div>
                  <div class="col-lg-6 col-12">
                    <select v-model="poolLength" style="width: 100%">
                      <option value="16">16m</option>
                      <option value="20">20m</option>
                      <option value="25">25m</option>
                      <option value="33">33m</option>
                      <option value="50">50m</option>
                    </select>
                  </div>
                </div>

                <div class="row" v-if="youtubeEmbedLink">
                  <div class="col-lg-12 top-spacer-25">
                    <iframe
                      width="100%"
                      height="315"
                      v-bind:src="youtubeToNoCookie(youtubeEmbedLink)"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <br /><br />
                </div>

                <div class="row" v-if="sport == 'Radfahren' || sport == 'Laufen' || sport == 'Schwimmen'">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-9 top-spacer-25">
                    <button @click="useWorkoutBuilder = true" style="width: 100%">Workout Builder verwenden</button>
                    <hr v-if="!workoutFile || workoutFile.length == 0" />
                  </div>
                </div>

                <div
                  class="row top-spacer-25"
                  v-if="workoutFile && workoutFile.length > 0 && (sport == 'Radfahren' || sport == 'Laufen')"
                >
                  <div class="col-lg-12" style="overflow-x: scroll">
                    <table class="table" style="text-align: left">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Typ</th>

                          <th scope="col">Segment</th>
                          <th v-if="sport == 'Radfahren'" scope="col" class="d-none d-md-block">Trittfrequenz</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(segment, index) in workoutFile" :key="segment.id + index">
                          <th scope="row">{{ index + 1 }}</th>
                          <td>
                            <span v-if="segment.type == 'Ramp'">Rampe </span>
                            <span v-if="segment.type == 'SteadyState'"> Steady State </span>
                            <span v-if="segment.type == 'IntervalsT'"> Intervalle </span>
                            <span v-if="segment.type == 'FreeRide'"> Beliebig </span>
                          </td>
                          <td>
                            <span v-if="segment.type == 'Ramp'">
                              <span v-if="segment.Distance"> {{ segment.Distance }}m</span>
                              <span v-if="!segment.Distance">
                                {{ betterformattedTime(segment.Duration) }}
                              </span>
                              von
                              {{ segment.PowerLow }}% ({{ computeIntensityValues(segment.PowerLow) }}-{{
                                computeIntensityValuesHigh(segment.PowerLow)
                              }}) bis {{ segment.PowerHigh }}% ({{ computeIntensityValues(segment.PowerHigh) }}-{{
                                computeIntensityValuesHigh(segment.PowerHigh)
                              }}) // {{ intensityToHeartRate(segment.PowerLow).high }}bpm-{{
                                intensityToHeartRate(segment.PowerHigh).high
                              }}bpm
                            </span>
                            <span v-if="segment.type == 'SteadyState'">
                              <span v-if="segment.Distance"> {{ segment.Distance }}m</span>

                              <span v-if="!segment.Distance"> {{ betterformattedTime(segment.Duration) }}</span>
                              @
                              {{ segment.Power }}% ({{ computeIntensityValues(segment.Power) }}-{{
                                computeIntensityValuesHigh(segment.Power)
                              }}
                              // {{ intensityToHeartRate(segment.Power).low }}bpm-{{
                                intensityToHeartRate(segment.Power).high
                              }}bpm)
                            </span>
                            <span v-if="segment.type == 'IntervalsT'">
                              Wiederhole {{ segment.Repeat }} mal<br />
                              <span v-if="segment.OnDistance"> {{ segment.OnDistance }}m</span>
                              <span v-if="!segment.OnDistance"> {{ betterformattedTime(segment.OnDuration) }}</span>
                              @ {{ segment.OnPower }}% ({{ computeIntensityValues(segment.OnPower) }}-{{
                                computeIntensityValuesHigh(segment.OnPower)
                              }}
                              // {{ intensityToHeartRate(segment.OnPower).low }}bpm-{{
                                intensityToHeartRate(segment.OnPower).high
                              }}bpm) und<br />

                              <span v-if="segment.OffDistance"> {{ segment.OffDistance }}m</span>
                              <span v-if="!segment.OffDistance"> {{ betterformattedTime(segment.OffDuration) }}</span>
                              @ {{ segment.OffPower }}% ({{ computeIntensityValues(segment.OffPower) }}-{{
                                computeIntensityValuesHigh(segment.OffPower)
                              }}
                              // {{ intensityToHeartRate(segment.OffPower).low }}bpm-{{
                                intensityToHeartRate(segment.OffPower).high
                              }}bpm)
                            </span>
                            <span v-if="segment.type == 'FreeRide'">
                              <span v-if="segment.Distance"> {{ segment.Distance }}m</span>
                              <span v-if="!segment.Distance">{{ betterformattedTime(segment.Duration) }}</span>
                            </span>
                            <div class="d-block d-md-none">
                              Trittfrequenz:
                              <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                              <span v-if="!segment.Cadence">Beliebig</span>
                              <span v-if="segment.CadenceResting != undefined">
                                /
                                <span v-if="segment.CadenceResting > 0">{{ segment.CadenceResting }}</span>
                                <span v-if="segment.CadenceResting == 0">Beliebig</span>
                              </span>
                            </div>
                          </td>
                          <td v-if="sport == 'Radfahren'" class="d-none d-md-block">
                            <span v-if="segment.Cadence > 0">{{ segment.Cadence }}</span>
                            <span v-if="segment.Cadence == 0">Beliebig</span>
                            <span v-if="segment.CadenceResting != undefined">
                              /
                              <span v-if="segment.CadenceResting > 0">{{ segment.CadenceResting }}</span>
                              <span v-if="segment.CadenceResting == 0">Beliebig</span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div v-if="videoUrls && videoUrls.length">
                  <hr />
                  <div class="top-spacer-15">
                    <strong>Videos, die helfen könnten</strong>
                  </div>
                  <b-accordion id="accordion-1" role="tablist" v-model="activeItem">
                    <b-card no-body v-for="(video, index) in videoUrls" :key="video.uri" class="top-spacer-5">
                      <b-card-header
                        header-tag="header"
                        class="p-1"
                        style="cursor: pointer"
                        role="tab"
                        v-b-toggle="'accordion-' + index"
                      >
                        <a href="#" class="accordion-link">{{ video.title }}</a>
                      </b-card-header>
                      <b-collapse :id="'accordion-' + index" role="tabpanel">
                        <b-card-body>
                          <iframe
                            width="100%"
                            height="350px"
                            :src="video.uri"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          ></iframe>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </b-accordion>
                </div>

                <hr v-if="workoutFile && workoutFile.length != 0" />
                <div v-if="sport != 'Notiz' && sport != 'Wettkampf'">
                  <div class="row top-spacer-25">
                    <div class="col-lg-1"></div>

                    <div class="col-lg-4"></div>
                    <div class="col-lg-3 d-none d-lg-block">Soll</div>
                    <div class="col-lg-3 d-none d-lg-block">Ist</div>
                    <div class="col-lg-1"></div>
                  </div>

                  <div class="row">
                    <div class="col-lg-1"></div>

                    <div class="col-lg-4">
                      <strong class="pull-right-non-mobile">Dauer <small>(hh:mm:ss)</small></strong>
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="d-block d-lg-none">Soll</span>
                      <input
                        :disabled="workoutFile && workoutFile.length > 0"
                        required
                        @click="
                          durationShould == 0 ||
                          durationShould == '0:00' ||
                          durationShould == '00:00' ||
                          durationIs == '00:00:00' ||
                          durationIs == '0:00:00'
                            ? (durationShould = undefined)
                            : ''
                        "
                        v-model="durationShould"
                        type="text"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="d-block d-lg-none">Ist</span>

                      <input
                        required
                        @click="
                          durationIs == 0 ||
                          durationIs == '0:00' ||
                          durationIs == '00:00' ||
                          durationIs == '00:00:00' ||
                          durationIs == '0:00:00'
                            ? (durationIs = undefined)
                            : ''
                        "
                        v-model="durationIs"
                        type="text"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-lg-1"></div>
                  </div>
                  <div class="row top-spacer-25" v-if="sport == 'Laufen'">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-4">
                      <strong class="pull-right-non-mobile"
                        >Geschwindigkeit
                        <small v-if="sport == 'Laufen'">(mm:ss)</small>
                      </strong>
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="d-block d-lg-none">Soll</span>

                      <input
                        :disabled="workoutFile && workoutFile.length > 0 && sport != 'Schwimmen'"
                        required
                        @click="
                          paceShould == 0 || paceShould == '0:00' || paceShould == '00:00'
                            ? (paceShould = undefined)
                            : ''
                        "
                        v-model="paceShould"
                        type="text"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="d-block d-lg-none">Ist</span>

                      <input
                        @click="paceIs == 0 || paceIs == '0:00' || paceIs == '00:00' ? (paceIs = undefined) : ''"
                        required
                        v-model="paceIs"
                        type="text"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-lg-1"></div>
                  </div>
                  <div v-if="sport != 'Kraft'" class="row top-spacer-25">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-4">
                      <strong class="pull-right-non-mobile"
                        >Distanz
                        <small>(km)</small>
                      </strong>
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="d-block d-lg-none">Soll</span>

                      <input
                        @click="distanceShould == 0 ? (distanceShould = undefined) : ''"
                        required
                        v-model="distanceShould"
                        type="text"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="d-block d-lg-none">Ist</span>

                      <input
                        required
                        @click="distance == 0 ? (distance = undefined) : ''"
                        v-model="distance"
                        type="text"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-lg-1"></div>
                  </div>
                  <div class="row top-spacer-25">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-4">
                      <strong class="pull-right-non-mobile"
                        >Herzfrequenz
                        <small>(bpm)</small>
                      </strong>
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="d-block d-lg-none">Soll</span>

                      <input
                        required
                        @click="heartRateAvgShould == 0 ? (heartRateAvgShould = undefined) : ''"
                        v-model="heartRateAvgShould"
                        type="text"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="d-block d-lg-none">Ist</span>

                      <input
                        required
                        @click="heartRateAvg == 0 ? (heartRateAvg = undefined) : ''"
                        v-model="heartRateAvg"
                        type="text"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-lg-1"></div>
                  </div>

                  <div class="row top-spacer-25">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-4">
                      <strong class="pull-right-non-mobile"
                        >Leistung
                        <small>(w)</small>
                      </strong>
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="d-block d-lg-none">Soll</span>

                      <input
                        required
                        @click="powerInWattsShould == 0 ? (powerInWattsShould = undefined) : ''"
                        v-model="powerInWattsShould"
                        type="text"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-lg-3 col-6">
                      <span class="d-block d-lg-none">Ist</span>

                      <input
                        required
                        @click="powerInWatts == 0 ? (powerInWatts = undefined) : ''"
                        v-model="powerInWatts"
                        type="text"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-lg-1"></div>
                  </div>
                </div>

                <div v-if="sport == 'Notiz' || sport == 'Wettkampf'" class="top-spacer-15">&nbsp;</div>

                <hr class="top-spacer-25" />

                <div
                  class="col-lg-12 top-spacer-25"
                  v-if="
                    durationIs &&
                    durationIs != '0' &&
                    durationIs != '0:00' &&
                    durationIs != '00:00' &&
                    wo &&
                    wo.time_hit + wo.time_mit + wo.time_lit > 0
                  "
                >
                  <div class="row top-spacer-25">
                    <div class="col-lg-3">
                      <strong class="pull-right-non-mobile" style="margin-top: 4px">Intensitätsverteilung</strong>
                    </div>
                    <div class="col-lg-9">
                      <div class="progress" style="margin-top: 6px; height: 1.3rem">
                        <div
                          class="progress-bar bg-success"
                          role="progressbar"
                          v-bind:style="
                            'width:' + timePercentage(wo.time_lit, wo.time_hit + wo.time_mit + wo.time_lit) + '%'
                          "
                          v-bind:aria-valuenow="timePercentage(wo.time_lit, wo.time_hit + wo.time_mit + wo.time_lit)"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ timePercentage(wo.time_lit, wo.time_hit + wo.time_mit + wo.time_lit) }}%
                        </div>
                        <div
                          class="progress-bar bg-info"
                          role="progressbar"
                          v-bind:style="
                            'background-color: rgb(232, 126, 4)!important;' +
                            'width:' +
                            timePercentage(wo.time_mit, wo.time_hit + wo.time_mit + wo.time_lit) +
                            '%'
                          "
                          v-bind:aria-valuenow="timePercentage(wo.time_mit, wo.time_hit + wo.time_mit + wo.time_lit)"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ timePercentage(wo.time_mit, wo.time_hit + wo.time_mit + wo.time_lit) }}%
                        </div>
                        <div
                          class="progress-bar bg-danger"
                          role="progressbar"
                          v-bind:style="
                            'width:' + timePercentage(wo.time_hit, wo.time_hit + wo.time_mit + wo.time_lit) + '%'
                          "
                          v-bind:aria-valuenow="timePercentage(wo.time_hit, wo.time_hit + wo.time_mit + wo.time_lit)"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ timePercentage(wo.time_hit, wo.time_hit + wo.time_mit + wo.time_lit) }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="wo && wo.newFtp > 0"
                  class="top-spacer-15"
                  style="padding: 10px; background: #eee; border-radius: 10px"
                >
                  Neue FTP erkannt: {{ wo.newFtp }}w
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="row top-spacer-25" v-if="sport != 'Notiz' && sport != 'Wettkampf'">
          <div class="col-lg-3">
            <strong class="pull-right-non-mobile" v-if="sport != 'Notiz' && sport != 'Wettkampf'"
              >Notiz zum Training</strong
            >
          </div>
          <div class="col-lg-9">
            <textarea-autosize
              required
              v-model="done_description"
              type="text"
              placeholder="Das Training lief..."
              style="width: 100%"
              :min-height="90"
            />
          </div>
        </div>
        <div
          v-if="wo && wo.time_zones_need_adjustment"
          class="top-spacer-25"
          style="padding: 15px; margin-bottom: 15px; border-radius: 10px; background: darkred; color: #fff"
        >
          Achtung: LIT/MIT/HIT müssen noch angepasst werden, da sie nicht berechnet werden konnten.<br />
          Wenn das Workout gespeichert wird, gelten sie als angepasst.<br /><br />
          LIT = unter der Schwelle, MIT = an der Schwelle, HIT = über der Schwelle
        </div>
        <div
          class="row top-spacer-25"
          v-if="
            durationIs &&
            durationIs != '0:00' &&
            durationIs != '00:00' &&
            durationIs != 0 &&
            sport != 'Notiz' &&
            sport != 'Wettkampf'
          "
        >
          <div class="col-12 col-lg-3"></div>
          <div class="col-4 col-lg-3">
            <span>LIT (%)</span><br />
            <input required v-model="time_lit" type="number" style="width: 100%; max-width: 120px" />
          </div>
          <div class="col-4 col-lg-3">
            <span>MIT (%)</span><br />
            <input required v-model="time_mit" type="number" style="width: 100%; max-width: 120px" />
          </div>
          <div class="col-4 col-lg-3">
            <span>HIT (%)</span><br />
            <input required v-model="time_hit" type="number" style="width: 100%; max-width: 120px" />
          </div>
          <hr class="top-spacer-25" />
        </div>
        <div
          class="row top-spacer-25"
          v-if="
            !useWorkoutBuilder &&
            sport != 'Notiz' &&
            sport != 'Wettkampf' &&
            (!durationIs || durationIs == '0:00' || durationIs == '00:00' || durationIs == 0)
          "
        >
          <div class="col-12 col-lg-3"></div>
          <div class="col-4 col-lg-3">
            <span>LIT (%)</span><br />
            <input required v-model="estimate_time_lit" type="number" style="width: 100%; max-width: 120px" />
          </div>
          <div class="col-4 col-lg-3">
            <span>MIT (%)</span><br />
            <input required v-model="estimate_time_mit" type="number" style="width: 100%; max-width: 120px" />
          </div>
          <div class="col-4 col-lg-3">
            <span>HIT (%)</span><br />
            <input required v-model="estimate_time_hit" type="number" style="width: 100%; max-width: 120px" />
          </div>
          <hr class="top-spacer-25" />
        </div>
      </div>

      <div class="col-lg-12 top-spacer-25">
        <!-- TODO: Hit lit mit form selber verändern -->
        <div>
          <a class="pull-right" v-if="!showYoutubeEmbed" @click="showYoutubeEmbed = true">+ Youtube-Video einbinden</a>
        </div>
        <div v-if="showYoutubeEmbed" class="top-spacer-25">
          <span style="font-size: 16px">
            Füge hier die URL zum Youtube-Video ein <br /><input
              style="width: 80%"
              type="text"
              v-model="youtubeEmbedLink"
          /></span>
          <div class="top-spacer-25"></div>
        </div>
      </div>
      <div class="col-lg-12 top-spacer-25">
        <div v-if="!workout && (workoutFile.length > 0 || sport == 'Schwimmen' || sport == 'Kraft')">
          <span style="font-size: 16px">
            Als Vorlage in Bibliothek aufnehmen? <br /><input type="checkbox" id="checkbox" v-model="isSaveAsTemplate"
          /></span>

          <br />
          <span style="font-size: 16px" v-if="user.isAdmin">
            (ADMIN) Als globale Vorlage in Bibliothek aufnehmen? <br /><input
              type="checkbox"
              id="checkbox"
              v-model="isSaveAsGlobalTemplate"
          /></span>
          <br /><br />
        </div>

        <div class="warning" v-if="deleteWorkoutConfirmation">
          <span v-if="sport != 'Notiz' && sport != 'Wettkampf'"
            >Achtung: Beim Löschen gehen alle eingegeben Daten und Auswertungen der Einheit verloren.<br /><br
          /></span>
          <span v-if="sport == 'Notiz'">Möchtest du die Notiz löschen?<br /><br /></span>
          <span v-if="sport == 'Wettkampf'">Möchtest du diesen Wettkampf löschen?<br /><br /></span>
          <button type="submit" class="delete" @click="deleteWorkout()">
            <span v-if="deleteWorkoutConfirmation">Wirklich löschen</span>
          </button>
          <br v-if="showSpinner" />
          <br v-if="showSpinner" />
          <div v-if="showSpinner" class="spinner-border text-primary" role="status"></div>
        </div>

        <div
          class="row top-spacer-25"
          v-if="durationIs && durationIs != '0:00' && durationIs != '0:00:00' && durationIs != '00:00:00'"
        >
          <hr />

          <div class="col-lg-3">
            <strong class="pull-right-non-mobile">RPE / Anstrengung</strong>
          </div>
          <div class="col-lg-6">
            <vue-slider max="10" interval="0.1" v-model="rpeValue"></vue-slider>
          </div>
          <div class="col-lg-3">
            <div class="text-center">
              <span v-if="rpeValue == 0" class="rpe-value">Keine Bewertung</span>
              <span v-if="rpeValue > 0 && rpeValue < 1.5" class="rpe-value">Sehr leicht</span>
              <span v-if="rpeValue >= 1.5 && rpeValue < 3.0" class="rpe-value">Leicht</span>
              <span v-if="rpeValue >= 3.0 && rpeValue < 6.0" class="rpe-value">Moderat</span>
              <span v-if="rpeValue >= 6.0 && rpeValue < 8.0" class="rpe-value">Anstrengend </span>
              <span v-if="rpeValue >= 8.0 && rpeValue < 9.0" class="rpe-value">Hart</span>
              <span v-if="rpeValue >= 9.0" class="rpe-value">Maximale Anstrengung</span>
            </div>
          </div>

          <div class="col-lg-12" v-if="rpeUpdated">RPE gespeichert: {{ rpeUpdated }}</div>

          <hr />
        </div>
        <div v-if="showSpinner && !deleteWorkoutConfirmation">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div class="top-spacer-25">
          <button style="margin: 0 5px" type="submit" class="secondary mobile-wide-button" @click="createWorkout">
            Speichern
          </button>

          <div class="d-block d-sm-none" v-if="workout && (workout.activityFile || workout.summaryId)">
            <hr />
          </div>
          <button
            style="margin: 0 5px"
            class="thirdary mobile-wide-button"
            v-if="workout && (workout.activityFile || workout.summaryId)"
            @click="showDetails"
          >
            Auswertung
          </button>
          <div class="d-block d-sm-none" v-if="!deleteWorkoutConfirmation">
            <hr />
          </div>

          <button
            style="margin: 0 5px"
            v-if="!deleteWorkoutConfirmation"
            type="submit"
            class="delete mobile-wide-button"
            @click="preDeleteWorkout()"
          >
            Löschen
          </button>

          <div class="d-block d-sm-none">
            <hr />
          </div>
          <button style="margin: 0 5px" class="closer mobile-wide-button" type="submit" @click="$parent.hideOverlay()">
            Schließen
          </button>
        </div>

        <div v-if="workout && workout.strength_blueprint" class="mt-2">
          <hr />

          <button
            @click="$router.push(`/kraftraum/workout/${workout.strength_blueprint}`)"
            class="btn btn-secondary mobile-wide-button"
            style="font-size: 12px; font-weight: bold"
          >
            {{ $t("Im Kraftraum öffnen") }}
          </button>
        </div>

        <div v-if="healthkitConnected && wo" class="top-spacer-15">
          <hr />
          <button
            style="margin: 0 5px; width: 250px; background: #fff; color: #333"
            v-if="platform == 'ios'"
            type="submit"
            @click="syncWorkoutsToApple()"
          >
            <span
              v-if="isSyncingApple"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="!isSyncingApple">Zu Apple synchronisieren</span>
          </button>
          <small v-if="platform != 'ios'">Öffne die iOS-App um die Workouts zur Apple Watch zu synchronisieren</small>
        </div>

        <div class="top-spacer-25">
          <div v-if="platform != 'android'">
            <i
              class="fa fa-download"
              v-if="wo && (sport == 'Radfahren' || sport == 'Laufen') && wo.workoutFile.length > 0"
              aria-hidden="true"
            ></i>
            &nbsp;
            <a
              style="font-size: 12px"
              v-bind:href="uri + '/workout/download/' + this.wo._id"
              v-if="wo && sport == 'Radfahren' && wo.workoutFile.length > 0"
            >
              Zwift Workout File
            </a>
            <span v-if="wo && sport == 'Radfahren' && wo.workoutFile.length > 0"> / </span>
            <a
              style="font-size: 12px"
              v-bind:href="uri + '/workout/download/running/' + this.wo._id"
              v-if="wo && sport == 'Laufen' && wo.workoutFile.length > 0"
            >
              Zwift Workout File (Hinweise beachten!)
            </a>

            <i
              class="fa fa-download"
              v-if="wo && sport == 'Radfahren' && wo.workoutFile.length > 0"
              aria-hidden="true"
            ></i>
            &nbsp;
            <a
              style="font-size: 12px"
              v-bind:href="uri + '/workout/download/' + this.wo._id + '/mrc'"
              v-if="wo && sport == 'Radfahren' && wo.workoutFile.length > 0"
            >
              .mrc Download
            </a>
            <span v-if="wo && sport == 'Radfahren' && wo.workoutFile.length > 0">
              <br />
            </span>
          </div>
          <div v-if="platform == 'android' && sport == 'Radfahren'">
            Die .mrc-Datei und das Zwift Workout File kannst du im Browser am Computer runterladen
          </div>

          <i
            v-if="wo && sport == 'Radfahren' && wo.workoutFile.length > 0"
            class="fa fa-bicycle"
            aria-hidden="true"
          ></i>
          &nbsp;

          <a
            style="font-size: 12px"
            v-bind:href="'/#/radraum/' + wo._id"
            v-if="wo && sport == 'Radfahren' && wo.workoutFile.length > 0"
          >
            Im Radraum fahren
          </a>

          <div v-if="workout && workout.backlink">
            <a target="_blank" style="text-decoration: underline !important; color: #fc4c02" :href="wo.backlink"
              >Anzeigen auf Strava</a
            >
          </div>
        </div>

        <div
          v-if="
            workout &&
            workout.recipePreWorkout &&
            workout.recipePostWorkout &&
            workout.recipePreWorkout.imageIds &&
            workout.recipePostWorkout.imageIds
          "
        >
          <hr class="top-spacer-25" />
          <div class="top-spacer-50"><h3>Passend dazu</h3></div>

          <div class="row top-spacer-25">
            <div class="col-6">
              <div class="col-lg-12 top-spacer-10">
                <h4>Vorher</h4>
              </div>
              <a :href="'/#/esszimmer/rezept/' + workout.recipePreWorkout._id">
                <div class="row">
                  <div class="col-lg-12">
                    <img
                      style="max-width: 220px"
                      :src="'https://assets.pushinglimits.club/' + workout.recipePreWorkout.imageIds[0] + '-small'"
                      width="100%"
                    />
                  </div>
                  <div class="col-lg-12 top-spacer-15">
                    <h5>{{ workout.recipePreWorkout.ueberschrift }}</h5>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-6">
              <div class="col-lg-12 top-spacer-10">
                <h4>Nachher</h4>
              </div>
              <a
                :href="
                  '/#/esszimmer/rezept/' + workout.recipePostWorkout._id + '/' + workout.recipePostWorkoutIntensity ||
                  'LIT'
                "
              >
                <div class="row">
                  <div class="col-lg-12">
                    <img
                      style="max-width: 220px"
                      :src="'https://assets.pushinglimits.club/' + workout.recipePostWorkout.imageIds[0] + '-small'"
                      width="100%"
                    />
                  </div>
                  <div class="col-lg-12 top-spacer-15">
                    <h5>{{ workout.recipePostWorkout.ueberschrift }}</h5>
                  </div>
                </div></a
              >
            </div>
          </div>
        </div>

        <div
          v-if="
            (durationShould &&
              durationIs &&
              durationShould != '0:00:00' &&
              durationShould != '00:00:00' &&
              durationShould != '0:00' &&
              durationShould != '00:00' &&
              durationShould != '0' &&
              durationShould != 0 &&
              durationIs != '0:00' &&
              durationIs != '00:00:00' &&
              durationIs != '0:00:00') ||
            sport == 'Notiz'
          "
        >
          <hr />
          <button
            class="b-button"
            @click="uncoupleWorkout()"
            v-if="
              durationShould &&
              durationIs &&
              durationShould != '0:00' &&
              durationShould != '00:00:00' &&
              durationShould != '0:00:00' &&
              durationShould != '00:00' &&
              durationShould != '0' &&
              durationShould != 0 &&
              durationIs != '0:00' &&
              durationIs != '00:00:00' &&
              durationIs != '0:00:00'
            "
          >
            Einheit und Vorlage entkoppeln
          </button>
        </div>
        <div>
          <hr />
          <button v-if="sport != 'Wettkampf'" class="b-button" @click="copyWorkout()">Kopieren</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import calendar_utils from "@/js/calendar_utils";
import draggable from "vuedraggable";
import { Capacitor } from "@capacitor/core";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import AppleHealthService from "@/services/AppleHealthService";
import { computePowerFromWorkoutFile } from "@/js/utils";

export default {
  name: "NewWorkout",
  components: { draggable, VueSlider },
  data() {
    return {
      showYoutubeEmbed: false,
      mode: "Duration",
      formDate: moment(this.dayDate).format("yyyy-MM-DD"),
      // Workout by Props
      wo: undefined,
      deleteWorkoutConfirmation: false,
      platform: Capacitor.getPlatform(),

      poolLength: 25,

      wettkampf_sport: undefined,
      wettkampf_typ: undefined,
      prioritaet: undefined,

      // Auf Server spichern
      name: "Workout",
      description: "",
      done_description: "",

      sport: "Radfahren",
      workoutFile: [],
      date: moment(this.dayDate).format("DD.MM.YYYY"),

      // Wenn kein WorkoutBuilder benutzt wird
      durationShould: 0,
      durationIs: 0,
      paceShould: 0,
      paceIs: 0,
      distance: 0,
      distanceShould: 0,
      heartRateAvg: 0,
      heartRateAvgShould: 0,

      wordToVideoUrl: [],

      powerInWattsShould: 0,
      powerInWatts: 0,

      showSpinner: false,

      youtubeEmbedLink: undefined,

      time_hit: 0,
      time_mit: 0,
      time_lit: 0,

      estimate_time_lit: 0,
      estimate_time_mit: 0,
      estimate_time_hit: 0,

      // Funktionales
      selectedWorkoutItem: {},
      isSaveAsTemplate: false,
      isSaveAsGlobalTemplate: false,
      workoutLengthIs: "0:00:00",
      performance: "Passend",

      // 1 bis 5
      perceivedStress: 3,

      ftp: this.thresholds.ftp,
      swimThreshold: this.thresholds.threshold_swim,
      runThreshold: this.thresholds.threshold_run,
      maxHr: this.thresholds.maxHr,

      details: {},

      activeItem: null,

      // TODO: Set max repeats to 250 in form
      // Die Variable wird als "Hack" verwendet, um eine For-Schleife mit vue zu realisieren
      list: new Array(250),
      uri: this.$host,

      useWorkoutBuilder: false,
      builderDuration: "",
      builderOffDuration: "",
      builderOnDuration: "",
      user: {},

      rpeUpdated: false,

      healthkitConnected: false,
      isSyncingApple: false,

      rpeValue: 0,
    };
  },
  mounted: async function () {
    const _self = this;
    this.user = (await axios.get(_self.$host + "/user/me")).data;
    if (this.workout && !this.workout.poolLength) {
      this.poolLength = this.user.poolLength || 25;
    }

    // go through workoutFile and add id if not existing
    if (this.workout && this.workout.workoutFile) {
      this.workout.workoutFile.forEach((item) => {
        if (!item.id) {
          item.id = Math.random().toString(36).substring(7);
        }
      });
    }
    this.wordToVideoUrl = await axios.get(this.$host + "/media/get_videos_wordlist").then((res) => res.data);

    const res = await axios.get(`${this.$host}/healthkit/is_connected`);
    this.healthkitConnected = res.data.connected;
  },
  computed: {
    isInPast: function () {
      return this.$moment(this.date).isBefore();
    },
    videoUrls: function () {
      return this.findVideoUrls();
    },
  },

  watch: {
    sport(newSport) {
      // Wenn zum Beispiel von Laufen zu Radfahren gewechselt wird,
      // so müssen ggf. gesetzte Distanzen im Segment entfernt werden!
      if (newSport != "Laufen" && newSport != "Schwimmen") {
        this.mode = "Duration";
      }
    },
    rpeValue() {
      this.debouncedRpeChange();
    },
  },

  methods: {
    async syncWorkoutsToApple(force = false) {
      this.isSyncingApple = true;
      await AppleHealthService.syncWorkoutsToApple(force);
      this.isSyncingApple = false;
    },
    debounce(func, wait, immediate) {
      let timeout;
      return function executedFunction(...args) {
        const context = this;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    rpeChange() {
      const _self = this;
      axios
        .post(this.$host + "/workout/rpe/" + this.workout._id, {
          rpe: _self.rpeValue,
        })
        .then((res) => {
          _self.rpeUpdated = res.data.workout.rpe;
        });
    },
    changeAiWorkout(workout) {
      let tempWorkoutDuration = workout.durationShould;
      if (workout.sport == "Laufen" || workout.sport == "Radfahren") {
        tempWorkoutDuration = this.totalWorkoutLengthWorkoutFile(workout.workoutFile);
      }

      // Distanz berechnen, falls duration und pace gegeben

      if (workout.sport == "Laufen") {
        workout.paceShould =
          calendar_utils.computeSpeedFromWorkoutFile(workout.workoutFile, this.thresholds.threshold_run) || 0;
      }

      if (workout.sport == "Laufen" && tempWorkoutDuration > 0 && workout.paceShould > 0) {
        workout.distanceShould = Math.round((tempWorkoutDuration / workout.paceShould) * 10) / 10;
      }

      let req = {
        name: workout.name,
        sport: workout.sport,
        description: workout.description,
        durationShould: tempWorkoutDuration,
        paceShould: workout.paceShould || 0,
        distanceShould: workout.distanceShould || 0,
        distance: workout.distance || 0,
        date: this.workout.date,
        workoutFile: workout.workoutFile,
        radraumWorkoutId: workout.radraumWorkoutId,
        durationIs: 0,
        paceIs: 0,
        youtubeEmbedLink: workout.youtubeEmbedLink,
        workout_blueprint: workout._id,
        aiCoachRecommendation: true,
      };

      const _self = this;

      axios
        .post(this.$host + "/workout", req)
        .then(async function () {
          // Beim Ändern Event Emitten
          _self.deleteWorkout();
          await AppleHealthService.syncWorkoutsToApple();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    totalWorkoutLengthWorkoutFile(workoutFile) {
      if (this.workout && this.workout.sport == "Schwimmen") {
        return this.workout.durationShould;
      }

      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length;
    },
    getRampRectHeight(numberOfBlocks, startIntensity, endIntensity, index) {
      let res = startIntensity - ((startIntensity - endIntensity) / numberOfBlocks) * index;

      return res;
    },
    youtubeToNoCookie(link) {
      if (link && link.indexOf("youtube.com") !== -1) {
        const search = new URL(link).searchParams;
        const videoId = search.get("v");
        if (videoId) {
          link = "https://www.youtube-nocookie.com/embed/" + videoId;
        }
      } else if (link && link.indexOf("youtu.be") !== -1) {
        link = link.replace("//youtu.be/", "//www.youtube-nocookie.com/embed/");
      }
      return link;
    },
    computeWidth(val, workoutFile) {
      const width = Math.ceil((val / this.totalWorkoutLengthWorkoutFile(workoutFile)) * 100) - 1;

      return width > 1 ? width + "%" : "0.75px";
    },
    async downloadMrc(woId) {
      // TODO: wird noch nicht genutzt. GGf. file system api
      axios
        .get(this.uri + "/workout/download/" + this.wo._id + "/mrc/content", {
          responseType: "blob",
        })
        .then((res) => {
          console.log(res);
          const file = res.data;
          console.log(file);

          let fileUrl = window.URL.createObjectURL(res.data);
          let fileLink = document.createElement("a");

          fileLink.href = fileUrl;
          fileLink.setAttribute("download", woId + ".mrc");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => console.log(err));
    },

    setDuration(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.Duration = moment.duration(event.target.value).asSeconds();
      }
    },

    setDistance(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.Duration = Math.round(
          (event.target.value / 1000) * (this.runThreshold / (this.selectedWorkoutItem.Power / 100))
        );
      }
    },

    setSwimDistance(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.Distance = event.target.value;
      }
    },

    setSwimstroke(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.SwimStroke = event.target.value;
      }
    },

    setDescription(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.Description = event.target.value;
      }
    },

    intensityToHeartRate(intensity) {
      let low = 0;
      let high = 0;

      // Ramp needs hrTargeZoneUpdate
      if (intensity < 56) {
        low = 0;
        high = this.user.threshold_hr_zone_1 * this.maxHr;
      } else if (intensity < 82) {
        low = this.user.threshold_hr_zone_1 * this.maxHr;
        high = this.user.threshold_hr_zone_2 * this.maxHr;
      } else if (intensity < 96) {
        low = this.user.threshold_hr_zone_2 * this.maxHr;
        high = this.user.threshold_hr_zone_3 * this.maxHr;
      } else if (intensity < 111) {
        low = this.user.threshold_hr_zone_3 * this.maxHr;
        high = this.user.threshold_hr_zone_4 * this.maxHr;
      } else {
        low = this.user.threshold_hr_zone_4 * this.maxHr;
        high = this.maxHr * 100;
      }

      low = Math.round(low / 100);
      high = Math.round(high / 100);
      return { low, high };
    },

    setOnDistance() {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.OnDuration = Math.round(
          (this.selectedWorkoutItem.OnDistance / 1000) * (this.runThreshold / (this.selectedWorkoutItem.OnPower / 100))
        );
      }
    },

    setOffDistance() {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.OffDuration = Math.round(
          (this.selectedWorkoutItem.OffDistance / 1000) *
            (this.runThreshold / (this.selectedWorkoutItem.OffPower / 100))
        );
      }
    },

    setIntensity() {
      if (this.selectedWorkoutItem && this.selectedWorkoutItem.Distance > 0) {
        this.selectedWorkoutItem.Duration = Math.round(
          (this.selectedWorkoutItem.Distance / 1000) * (this.runThreshold / (this.selectedWorkoutItem.Power / 100))
        );
      }
    },

    setOnIntensity() {
      if (this.selectedWorkoutItem && this.selectedWorkoutItem.OnDistance > 0) {
        this.selectedWorkoutItem.OnDuration = Math.round(
          (this.selectedWorkoutItem.OnDistance / 1000) * (this.runThreshold / (this.selectedWorkoutItem.OnPower / 100))
        );
      }
    },

    setOffIntensity() {
      if (this.selectedWorkoutItem && this.selectedWorkoutItem.OffDistance > 0) {
        this.selectedWorkoutItem.OffDuration = Math.round(
          (this.selectedWorkoutItem.OnDistance / 1000) * (this.runThreshold / (this.selectedWorkoutItem.OffPower / 100))
        );
      }
    },

    //TODO: DRY
    colorForPower(power) {
      if (power > 105) {
        return "darkred";
      } else if (power > 100) {
        return "brown";
      } else if (power > 95) {
        return "firebrick";
      } else if (power > 88) {
        return "darkgreen";
      } else if (power > 83) {
        return "green";
      } else {
        return "rgba(94, 110, 255, 0.97)";
      }
    },

    setOnDuration(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.OnDuration = moment.duration(event.target.value).asSeconds();
      }
    },

    setOffDuration(event) {
      if (this.selectedWorkoutItem) {
        this.selectedWorkoutItem.OffDuration = moment.duration(event.target.value).asSeconds();
      }
    },

    showDetails() {
      if (this.workout.activityFile || this.workout.summaryId) {
        this.$router.push("/auswertung/workout/details/" + this.workout._id);
      }
    },

    findVideoUrls() {
      // Define a mapping of keywords to video URLs
      const text = this.description;
      const objects = this.workoutFile;

      // Initialize a set to store unique video URLs
      const uniqueVideoUrls = new Set();

      // Combine text and all descriptions into a single string
      let combinedText = text + " " + objects.map((obj) => obj.Description).join(" ");

      // Function to check if all words in a key are present in the text
      const areAllWordsPresent = (key, text) => {
        return key.split(" ").every((word) => text.includes(word));
      };

      // Iterate over each key in the phraseToVideoUrl mapping

      for (let key in this.wordToVideoUrl) {
        if (areAllWordsPresent(key, combinedText)) {
          uniqueVideoUrls.add(this.wordToVideoUrl[key]);
        }
      }

      // Convert the set to an array and return it
      return Array.from(uniqueVideoUrls);
    },

    timePercentage(seconds, totalTime) {
      if (totalTime > 0) {
        return Math.round((100 / totalTime) * seconds);
      } else return 0;
    },
    saveWorkoutBuilder() {
      if (this.sport != "Schwimmen") {
        this.durationShould = this.$moment()
          .startOf("day")
          .seconds(this.totalWorkoutLength() || 0)
          .format("HH:mm:ss");
      }

      if (this.sport == "Laufen") {
        this.paceShould = this.$moment()
          .startOf("day")
          .seconds(calendar_utils.computeSpeedFromWorkoutFile(this.workoutFile, this.runThreshold) || 0)
          .format("mm:ss");
      }

      if (this.sport == "Radfahren") {
        this.paceShould = this.$moment()
          .startOf("day")
          .seconds(calendar_utils.computeSpeedFromWorkoutFile(this.workoutFile, this.ftp) || 0)
          .format("mm:ss");
        // Add this line to compute power
        this.powerInWattsShould = computePowerFromWorkoutFile(this.workoutFile, this.ftp);
      }

      const durationShouldSecs = moment(this.durationShould, "HH:mm:ss").diff(moment().startOf("day"), "seconds");
      const paceShouldSec = moment(this.paceShould, "mm:ss").diff(moment().startOf("day"), "seconds");
      if (this.sport == "Laufen" && durationShouldSecs > 0 && paceShouldSec > 0) {
        this.distanceShould = Math.round((durationShouldSecs / paceShouldSec) * 10) / 10;
      }

      this.compute_workout_load();

      this.useWorkoutBuilder = false;
    },

    computeIntensityValues(val) {
      let percentageFactorLow = 1;

      if (this.sport == "Radfahren") {
        percentageFactorLow = 0.94;
      }

      if (this.sport == "Laufen") {
        percentageFactorLow = 0.97;
        if (val < 81) {
          percentageFactorLow = 0.93;
        }
      }

      if (this.sport == "Laufen" || this.sport == "Schwimmen") {
        const threshold = this.runThreshold || this.swimThreshold;
        return this.$moment.utc(Math.round((threshold / (val / 100)) * 1000 * percentageFactorLow)).format("mm:ss");
      }
      if (this.sport == "Radfahren") {
        return Math.round(((this.ftp * val) / 100) * percentageFactorLow);
      }
    },

    computeIntensityValuesHigh(val) {
      let percentageFactorHigh = 1;

      if (this.sport == "Radfahren") {
        percentageFactorHigh = 1.06;
      }

      if (this.sport == "Laufen") {
        percentageFactorHigh = 1.03;
      }

      if (this.sport == "Laufen" || this.sport == "Schwimmen") {
        const threshold = this.runThreshold || this.swimThreshold;
        return (
          this.$moment.utc(Math.round((threshold / (val / 100)) * 1000 * percentageFactorHigh)).format("mm:ss") + "/km"
        );
      }
      if (this.sport == "Radfahren") {
        return Math.round(((this.ftp * val) / 100) * percentageFactorHigh) + "w";
      }
    },
    maxPowerInWorkout() {
      return this.workoutBuilderHeight();
    },

    setPerceivedStress(num) {
      this.perceivedStress = num;
    },

    workoutBuilderHeight() {
      let maxPower = Math.max.apply(
        Math,
        this.workoutFile.map(function (o) {
          let onPower = typeof o.OnPower == "number" ? o.OnPower : 0;
          let offPower = typeof o.OffPower == "number" ? o.OffPower : 0;
          let power = typeof o.Power == "number" ? o.Power : 0;
          let powerLow = typeof o.PowerLow == "number" ? o.PowerLow : 0;
          let powerHigh = typeof o.PowerHigh == "number" ? o.PowerHigh : 0;
          return Math.max(onPower, offPower, power, powerLow, powerHigh);
        })
      );
      return maxPower;
    },

    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("HH:mm:ss");
    },

    computeAvgRunSpeed(workoutFile) {
      return this.$moment()
        .startOf("day")
        .seconds(calendar_utils.computeSpeedFromWorkoutFile(workoutFile, this.runThreshold))
        .format("mm:ss");
    },

    computeAvgSwimSpeed(workoutFile) {
      return this.$moment()
        .startOf("day")
        .seconds(calendar_utils.computeSpeedFromWorkoutFile(workoutFile, this.swimThreshold))
        .format("mm:ss");
    },

    computeAvgWatt(workoutFile) {
      return calendar_utils.computeAvgPowerFromWorkoutFile(workoutFile, this.ftp);
    },

    addSwimSteadyStateToWorkoutFile() {
      const elem = {
        type: "SteadyState",
        id: this.generateGUid(),
        Distance: 100,
        StrokeType: "FREESTYLE",
        Description: "",
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },

    addSwimRestToWorkoutFile() {
      const elem = {
        type: "Rest",
        id: this.generateGUid(),
        Duration: 30,
        Description: "",
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },

    addSwimIntervalToWorkoutFile() {
      const elem = {
        type: "IntervalsRest",
        id: this.generateGUid(),
        Repeat: 3,
        Distance: 100,
        Rest: 20,
        StrokeType: "FREESTYLE",
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },

    addSteadyStateToWorkoutFile() {
      const elem = {
        type: "SteadyState",
        id: this.generateGUid(),
        Duration: 600,
        Power: 70,
        Cadence: 0,
        Distance: 0,
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },
    addRampToWorkoutFile() {
      const elem = {
        type: "Ramp",
        id: this.generateGUid(),
        Duration: 600,
        PowerLow: 50,
        PowerHigh: 70,
        Cadence: 0,
        Distance: 0,
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },
    addIntervalToWorkoutFile() {
      const elem = {
        type: "IntervalsT",
        id: this.generateGUid(),
        Repeat: 3,
        OffDuration: 90,
        OnDuration: 30,
        OnPower: 120,
        OffPower: 70,
        Cadence: 0,
        CadenceResting: 0,
        OffDistance: 0,
        OnDistance: 0,
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },

    addFreeRideToWorkoutFile() {
      // <FreeRide Duration="600" FlatRoad="1"/>
      const elem = {
        type: "FreeRide",
        id: this.generateGUid(),
        Duration: 300,
        FlatRoad: 1,
        Cadence: 0,
        Distance: 0,
      };
      this.workoutFile.push(elem);
      this.selectNewBlock(elem);
    },
    workoutDoneColor() {
      const durationDone = (this.wo && this.wo.durationShould) || this.totalWorkoutLength();
      if (!this.parseShouldTime()) {
        return "inherit";
      } else {
        return calendar_utils.colorForDuration(durationDone, this.parseShouldTime(), this.date);
      }
    },

    parseShouldTime() {
      try {
        // TODO FIX ME
        /* if(this.workoutLengthIs && this.workoutLengthIs.length > 0) {
          let str = this.workoutLengthIs.trim();
          str = str.split("h");
          const h = parseInt(str[0]);

          str.shift();
          if(str.length == 0) str.push(0);
          str = str.join()
          const m = parseInt(str[0]);
          const res = 60*60*h+60*m;
          console.log("PRASETIME " + res);
          return res;
        }
      } catch(err) {
        return 0;
      }*/
        return this.$moment.duration(this.workoutLengthIs).asSeconds();
      } catch (err) {
        return 0;
      }
    },

    isSelectedSegment(id) {
      if (this.selectedWorkoutItem.id == id) {
        return true;
      }
    },

    getDateForTimestamp() {
      return this.$moment(this.formDate, "yyyy-MM-DD").toDate();
    },

    getFormattedDateForTimestamp() {
      return this.$moment(this.date).format("DD.MM.YYYY");
    },

    deleteBlock(id) {
      this.workoutFile = this.workoutFile.filter(function (el) {
        return el.id != id;
      });

      if (this.workoutFile[0]) {
        const event = {};
        event.target = {};
        event.target.id = this.workoutFile[this.workoutFile.length - 1].id;
        this.selectBlock(event);
      } else {
        this.selectedWorkoutItem = undefined;
      }
    },

    deleteSelectedBlock() {
      let id = this.selectedWorkoutItem.id;
      this.workoutFile = this.workoutFile.filter(function (el) {
        return el.id != id;
      });
      if (this.workoutFile[0]) {
        const event = {};
        event.target = {};
        event.target.id = this.workoutFile[this.workoutFile.length - 1].id;
        this.selectBlock(event);
      } else {
        this.selectedWorkoutItem = undefined;
      }
    },

    copySelectedBlock() {
      let elem = { ...this.selectedWorkoutItem };
      elem.id = this.generateGUid();
      this.workoutFile.push(elem);

      this.selectNewBlock(elem);
    },

    totalWorkoutLength() {
      const _self = this;
      let length = this.workoutFile.reduce(function (prev, cur) {
        let distance = prev;
        let interval_distance = 0;

        if (!(cur.Distance > 0 || cur.OnDistance > 0 || cur.OffDistance > 0)) {
          distance +=
            (cur.Duration > 0 ? cur.Duration : 0) +
            (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0);
        }

        if (cur.Distance > 0) {
          distance += Math.round((cur.Distance / 1000) * (_self.runThreshold / (cur.Power / 100)));
        }

        if (cur.OnDistance > 0) {
          interval_distance = Math.round((cur.OnDistance / 1000) * (_self.runThreshold / (cur.OnPower / 100)));

          distance += interval_distance * cur.Repeat;
        }
        if (cur.OffDistance > 0) {
          interval_distance = Math.round((cur.OffDistance / 1000) * (_self.runThreshold / (cur.OffPower / 100)));

          distance += interval_distance * cur.Repeat;
        }

        return distance;
      }, 0);

      return length;
    },

    compute_workout_load() {
      if (this.workoutFile && this.workoutFile.length > 0) {
        let time_lit = 0;
        let time_hit = 0;
        let time_mit = 0;

        try {
          for (const entry of this.workoutFile) {
            if (entry.Power <= 80) {
              time_lit += entry.Duration;
            }
            if (entry.Power > 80 && entry.Power <= 101) {
              time_mit += entry.Duration;
            }
            if (entry.Power > 101) {
              time_hit += entry.Duration;
            }

            // PowerLow und PowerHigh werden einfach aufaddiert und gleichen sich aus
            if (entry.PowerLow <= 80) {
              time_lit += entry.Duration;
            }
            if (entry.PowerLow > 80 && entry.PowerLow <= 101) {
              time_mit += entry.Duration;
            }
            if (entry.PowerLow > 101) {
              time_hit += entry.Duration;
            }
            if (entry.PowerHigh <= 80) {
              time_lit += entry.Duration;
            }
            if (entry.PowerHigh > 80 && entry.PowerHigh <= 101) {
              time_mit += entry.Duration;
            }
            if (entry.PowerHigh > 101) {
              time_hit += entry.Duration;
            }

            if (entry.OnPower <= 80) {
              time_lit += entry.OnDuration * entry.Repeat;
            }
            if (entry.OnPower > 80 && entry.OnPower <= 101) {
              time_mit += entry.OnDuration * entry.Repeat;
            }
            if (entry.OnPower > 101) {
              time_hit += entry.OnDuration * entry.Repeat;
            }

            if (entry.OffPower <= 80) {
              time_lit += entry.OffDuration * entry.Repeat;
            }
            if (entry.OffPower > 80 && entry.OffPower <= 101) {
              time_mit += entry.OffDuration * entry.Repeat;
            }
            if (entry.OffPower > 101) {
              time_hit += entry.OffDuration * entry.Repeat;
            }
          }

          const total = time_lit + time_mit + time_hit;
          this.estimate_time_lit = Math.round((time_lit / total) * 100);
          this.estimate_time_mit = Math.round((time_mit / total) * 100);
          this.estimate_time_hit = Math.round((time_hit / total) * 100);
          console.log(
            "ESIMATED LOAD lit mit hit",
            this.estimate_time_lit,
            this.estimate_time_mit,
            this.estimate_time_hit
          );
        } catch (err) {
          console.log("Load konnte nicht geschaetzt werden: " + err);
        }
      } else {
        // kein Workoutfile Durchschnittswert
        this.estimate_time_lit = Math.round(90);
        this.estimate_time_mit = Math.round(7);
        this.estimate_time_hit = Math.round(3);
      }
    },

    selectBlock(event, index = undefined) {
      let id = index;
      if (event && event.target) {
        id = event.target.id;
      }
      this.workoutFile.forEach((element) => {
        if (element.id == id) {
          // Toggle
          if (this.selectedWorkoutItem.id != id) {
            this.selectedWorkoutItem = element;

            this.builderDuration = moment.utc(element.Duration * 1000).format("HH:mm:ss");
            this.builderOnDuration = moment.utc(element.OnDuration * 1000).format("HH:mm:ss");
            this.builderOffDuration = moment.utc(element.OffDuration * 1000).format("HH:mm:ss");
          } else {
            this.selectedWorkoutItem = {};
          }
        }
      });

      if (
        this.selectedWorkoutItem.Distance ||
        this.selectedWorkoutItem.OnDistance ||
        this.selectedWorkoutItem.OffDistance
      ) {
        this.mode = "Distance";
      } else {
        this.mode = "Duration";
      }
    },
    selectNewBlock(element) {
      this.selectedWorkoutItem = element;

      this.builderDuration = moment.utc(element.Duration * 1000).format("HH:mm:ss");
      this.builderOnDuration = moment.utc(element.OnDuration * 1000).format("HH:mm:ss");
      this.builderOffDuration = moment.utc(element.OffDuration * 1000).format("HH:mm:ss");

      this.mode = "Duration";
    },
    betterformattedTime(seconds) {
      if (seconds >= 3600) {
        return this.$moment.utc(seconds * 1000).format("H[h]mm[m]ss[s]");
      } else {
        return this.$moment.utc(seconds * 1000).format("mm[m]ss[s]");
      }
    },

    createWorkout: function () {
      this.showSpinner = true;

      let name = this.name;
      let description = this.description;
      let done_description = this.done_description;

      let sport = this.sport;
      let distance = 0;
      let youtubeEmbedLink = this.youtubeToNoCookie(this.youtubeEmbedLink);

      if (this.distance) {
        distance = parseFloat(String(this.distance).replace(/,/g, "."));
      }
      let distanceShould = 0;
      if (this.distanceShould) {
        distanceShould = parseFloat(String(this.distanceShould).replace(/,/g, "."));
      }
      const durationShouldSecs = this.toSeconds(this.durationShould);

      const durationIsSecs = this.toSeconds(this.durationIs);

      const paceShouldSec = this.toSeconds(this.paceShould);
      const paceIsSec = this.toSeconds(this.paceIs);

      // Distanz berechnen, falls duration und pace gegeben
      if (this.sport == "Laufen" && distanceShould == 0 && durationShouldSecs > 0 && paceShouldSec > 0) {
        distanceShould = Math.round((durationShouldSecs / paceShouldSec) * 10) / 10;
      }
      if (this.sport == "Laufen" && distance == 0 && durationIsSecs > 0 && paceIsSec > 0) {
        distance = Math.round((durationIsSecs / paceIsSec) * 10) / 10;
      }

      let workout = [];
      if (sport == "Radfahren" || sport == "Laufen" || sport == "Schwimmen") {
        workout = this.workoutFile;
      }

      const durShould = this.toSeconds(this.durationShould);
      const pacShould = this.toSeconds(this.paceShould);

      let req = {
        name: name,
        distance,
        distanceShould,
        sport: sport,
        time_lit: this.time_lit,
        time_hit: this.time_hit,
        time_mit: this.time_mit,

        rpe: this.rpeValue,

        estimate_time_lit: this.estimate_time_lit || 0,
        estimate_time_mit: this.estimate_time_mit || 0,
        estimate_time_hit: this.estimate_time_hit || 0,
        description: description,
        done_description: done_description,

        heartRateAvg: this.heartRateAvg,
        heartRateAvgShould: this.heartRateAvgShould,

        powerInWatts: this.powerInWatts,
        powerInWattsShould: this.powerInWattsShould,

        time_zones_need_adjustment: false,
        durationShould: durShould,

        date: this.getDateForTimestamp(),
        workoutFile: workout,
        durationIs: this.toSeconds(this.durationIs),

        performance: this.performance,
        paceShould: pacShould,
        paceIs: this.toSeconds(this.paceIs),
        perceivedStress: this.perceivedStress,
      };

      if (sport == "Wettkampf") {
        req.wettkampf_sport = this.wettkampf_sport;
        req.wettkampf_typ = this.wettkampf_typ;
        req.prioritaet = this.prioritaet;
      }

      if (sport == "Schwimmen") {
        req.poolLength = this.poolLength;
      }

      if (this.youtubeEmbedLink) {
        req.youtubeEmbedLink = this.youtubeToNoCookie(this.youtubeEmbedLink);
      }

      if (this.workout) {
        req._id = this.workout._id;
        req.strength_blueprint = this.workout.strength_blueprint;
      }
      const _self = this;

      axios
        .post(this.$host + "/workout", req)
        .then(async function () {
          _self.showSpinner = true;

          _self.$emit("wasSaved");
          await AppleHealthService.syncWorkoutsToApple();
        })
        .catch(function (error) {
          console.log(error);
        });

      if (this.isSaveAsTemplate) {
        axios
          .post(this.$host + "/workout/blueprint", {
            name: name,
            sport: sport,
            description: description,
            done_description: done_description,
            workoutFile: workout,
            durationShould: durShould,
            paceShould: pacShould,
            distanceShould,
            youtubeEmbedLink: youtubeEmbedLink,
            estimate_time_lit: this.estimate_time_lit,
            estimate_time_mit: this.estimate_time_mit,
            estimate_time_hit: this.estimate_time_hit,
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      if (this.isSaveAsGlobalTemplate) {
        axios
          .post(this.$host + "/workout/blueprint/global", {
            name: name,
            sport: sport,
            description: description,
            done_description: done_description,

            workoutFile: workout,
            durationShould: durShould,
            paceShould: pacShould,
            distanceShould,
            youtubeEmbedLink: youtubeEmbedLink,
            estimate_time_lit: this.estimate_time_lit,
            estimate_time_mit: this.estimate_time_mit,
            estimate_time_hit: this.estimate_time_hit,
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    copyWorkout: function () {
      this.showSpinner = true;

      let name = this.name;
      let description = this.description;
      let done_description = this.done_description;

      let sport = this.sport;
      let distance = 0;

      if (this.distance) {
        distance = parseFloat(String(this.distance).replace(/,/g, "."));
      }
      let distanceShould = 0;
      if (this.distanceShould) {
        distanceShould = parseFloat(String(this.distanceShould).replace(/,/g, "."));
      }
      const durationShouldSecs = this.toSeconds(this.durationShould);

      const durationIsSecs = this.toSeconds(this.durationIs);

      const paceShouldSec = this.toSeconds(this.paceShould);

      const paceIsSec = this.toSeconds(this.paceIs);

      // Distanz berechnen, falls duration und pace gegeben
      if (this.sport == "Laufen" && distanceShould == 0 && durationShouldSecs > 0 && paceShouldSec > 0) {
        distanceShould = Math.round((durationShouldSecs / paceShouldSec) * 10) / 10;
      }
      if (this.sport == "Laufen" && distance == 0 && durationIsSecs > 0 && paceIsSec > 0) {
        distance = Math.round((durationIsSecs / paceIsSec) * 10) / 10;
      }

      let workout = [];
      if (sport == "Radfahren" || sport == "Laufen" || sport == "Schwimmen") {
        workout = this.workoutFile;
      }

      // iterate through the workout and set the segments ids to       elem.id = this.generateGUid();
      for (let i = 0; i < workout.length; i++) {
        workout[i].id = this.generateGUid();
      }

      const durShould = this.toSeconds(this.durationShould);

      const pacShould = this.toSeconds(this.paceShould);

      let req = {
        name: name,
        distance,
        distanceShould,
        sport: sport,
        time_lit: this.time_lit,
        time_hit: this.time_hit,
        time_mit: this.time_mit,

        rpe: this.rpeValue,

        estimate_time_lit: this.estimate_time_lit || 0,
        estimate_time_mit: this.estimate_time_mit || 0,
        estimate_time_hit: this.estimate_time_hit || 0,
        description: description,
        done_description: done_description,

        heartRateAvg: this.heartRateAvg,
        heartRateAvgShould: this.heartRateAvgShould,

        powerInWatts: this.powerInWatts,
        powerInWattsShould: this.powerInWattsShould,

        time_zones_need_adjustment: false,
        durationShould: durShould,

        date: this.getDateForTimestamp(),
        workoutFile: workout,
        durationIs: this.toSeconds(this.durationIs),

        performance: this.performance,
        paceShould: pacShould,
        paceIs: this.toSeconds(this.paceIs),
        perceivedStress: this.perceivedStress,
      };

      if (sport == "Wettkampf") {
        req.wettkampf_sport = this.wettkampf_sport;
        req.wettkampf_typ = this.wettkampf_typ;
        req.prioritaet = this.prioritaet;
      }

      if (sport == "Schwimmen") {
        req.poolLength = this.poolLength;
      }

      if (this.youtubeEmbedLink) {
        req.youtubeEmbedLink = this.youtubeToNoCookie(this.youtubeEmbedLink);
      }

      const _self = this;

      axios
        .post(this.$host + "/workout", req)
        .then(function () {
          _self.showSpinner = true;

          _self.$emit("wasSaved");
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    uncoupleWorkout: function () {
      let name = this.name;
      let description = this.description;
      let done_description = this.done_description;

      let sport = this.sport;
      let distance = 0;

      if (this.distance) {
        distance = parseFloat(String(this.distance).replace(/,/g, "."));
      }
      let distanceShould = 0;
      if (this.distanceShould) {
        distanceShould = parseFloat(String(this.distanceShould).replace(/,/g, "."));
      }
      const durationShouldSecs = this.toSeconds(this.durationShould);

      const durationIsSecs = this.toSeconds(this.durationIs);

      const paceShouldSec = this.toSeconds(this.paceShould);

      const paceIsSec = this.toSeconds(this.paceIs);

      // Distanz berechnen, falls duration und pace gegeben
      if (this.sport == "Laufen" && distanceShould == 0 && durationShouldSecs > 0 && paceShouldSec > 0) {
        distanceShould = Math.round((durationShouldSecs / paceShouldSec) * 10) / 10;
      }
      if (this.sport == "Laufen" && distance == 0 && durationIsSecs > 0 && paceIsSec > 0) {
        distance = Math.round((durationIsSecs / paceIsSec) * 10) / 10;
      }

      let workout = [];
      if (sport == "Radfahren" || sport == "Laufen") {
        workout = this.workoutFile;
      }

      const durShould = this.toSeconds(this.durationShould);

      const pacShould = this.toSeconds(this.paceShould);

      let req = {
        name: "Workout",
        distance,
        distanceShould: 0,
        sport: sport,
        time_lit: this.time_lit,
        time_hit: this.time_hit,
        time_mit: this.time_mit,

        rpe: this.rpeValue,

        description: "",
        done_description: done_description,

        heartRateAvg: this.heartRateAvg,
        heartRateAvgShould: this.heartRateAvgShould,

        powerInWatts: this.powerInWatts,
        powerInWattsShould: this.powerInWattsShould,

        time_zones_need_adjustment: false,

        date: this.getDateForTimestamp(),
        workoutFile: [],
        durationShould: 0,

        durationIs: this.toSeconds(this.durationIs),

        paceShould: 0,

        paceIs: this.toSeconds(this.paceIs),
      };

      if (this.workout) {
        req._id = this.workout._id;
      }

      let req2 = {
        name: name,
        distance: 0,
        distanceShould,
        sport: sport,

        estimate_time_lit: this.estimate_time_lit || 0,
        estimate_time_mit: this.estimate_time_mit || 0,
        estimate_time_hit: this.estimate_time_hit || 0,
        description: description,
        done_description: done_description,

        time_zones_need_adjustment: false,
        durationShould: durShould,

        date: this.getDateForTimestamp(),
        workoutFile: workout,
        durationIs: 0,

        paceShould: pacShould,
        paceIs: 0,
      };

      if (sport == "Schwimmen") {
        req2.poolLength = this.poolLength;
      }

      if (this.youtubeEmbedLink) {
        req2.youtubeEmbedLink = this.youtubeToNoCookie(this.youtubeEmbedLink);
      }

      const _self = this;
      axios
        .post(this.$host + "/workout", req)
        .then(function () {
          _self.$emit("wasSaved");
        })
        .catch(function (error) {
          console.log(error);
        });

      // Bestehendes Ist-raus
      axios
        .post(this.$host + "/workout", req2)
        .then(function () {
          _self.$emit("wasSaved");
        })
        .catch(function (error) {
          console.log(error);
        });

      //this.$store.dispatch('login', { email, password })
      //.then(() => this.$router.push('/'))
      //.catch(err => console.log(err))
    },
    toSeconds(str) {
      if (!str) {
        return 0;
      }

      return str
        ? str
            .split(":")
            .reverse()
            .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0)
        : 0;
    },
    detailsForWorkout() {
      const _self = this;
      axios
        .get(this.$host + "/workout/" + this.workout._id + "/details")
        .then(function (res) {
          // Event Namen anpassen?
          console.log("DETAILS CALLED", res.data);
          _self.details = res.data;
        })
        .catch(function (error) {
          console.log("err in details!", error);
        });
    },
    preDeleteWorkout() {
      this.deleteWorkoutConfirmation = true;
    },
    deleteWorkout() {
      const _self = this;
      this.showSpinner = true;

      if (this.workout && this.workout._id) {
        _self.$emit("workoutDeleted", _self.workout._id);

        axios
          .delete(this.$host + "/workout/" + this.workout._id)
          .then(async function () {
            await AppleHealthService.syncWorkoutsToApple();
          })
          .catch(function (error) {
            console.log(error);
            // If there's an error, emit an event to add the workout back
            _self.$emit("workoutDeleteFailed", _self.workout);
          })
          .finally(function () {
            _self.showSpinner = false;
          });
      } else {
        _self.showSpinner = false;
      }

      // Close the overlay
      this.$parent.hideOverlayNoUpdate();
    },
    generateGUid() {
      return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
  },

  created() {
    this.debouncedRpeChange = this.debounce(this.rpeChange, 300);

    if (this.workout) {
      this.name = this.workout.name;
      this.description = this.workout.description;
      this.done_description = this.workout.done_description;

      this.sport = this.sport = this.workout.sport;
      this.distance = this.workout.distance;
      this.distanceShould = this.workout.distanceShould;

      this.estimate_time_lit = this.workout.estimate_time_lit;
      this.estimate_time_mit = this.workout.estimate_time_mit;
      this.estimate_time_hit = this.workout.estimate_time_hit;

      this.time_lit = this.workout.time_lit || this.estimate_time_lit;
      this.time_mit = this.workout.time_mit || this.estimate_time_mit;
      this.time_hit = this.workout.time_hit || this.estimate_time_hit;

      this.youtubeEmbedLink = this.workout.youtubeEmbedLink;

      this.rpeValue = this.workout.rpe || 0;

      this.heartRateAvg = this.workout.heartRateAvg || 0;
      this.heartRateAvgShould = this.workout.heartRateAvgShould || 0;

      this.powerInWatts = this.workout.powerInWatts || 0;
      this.powerInWattsShould = this.workout.powerInWattsShould || 0;

      this.prioritaet = this.workout.prioritaet;
      this.wettkampf_sport = this.workout.wettkampf_sport;
      this.wettkampf_typ = this.workout.wettkampf_typ;

      this.poolLength = this.workout.poolLength || 25;

      this.durationShould = this.$moment()
        .startOf("day")
        .seconds(this.workout.durationShould || 0)
        .format("HH:mm:ss");

      this.durationIs = this.$moment()
        .startOf("day")
        .seconds(this.workout.durationIs || 0)
        .format("HH:mm:ss");

      this.paceShould = this.$moment()
        .startOf("day")
        .seconds(this.workout.paceShould || 0)
        .format("mm:ss");
      this.paceIs = this.$moment()
        .startOf("day")
        .seconds(this.workout.paceIs || 0)
        .format("mm:ss");

      this.workoutFile = this.workout.workoutFile;
      this.date = moment(this.workout.date).format("DD.MM.YYYY");
      this.formDate = moment(this.workout.date).format("yyyy-MM-DD");

      if (this.workoutFile.length > 0) {
        this.selectedWorkoutItem = this.workoutFile[0];
        if (this.sport != "Schwimmen") {
          if (
            this.selectedWorkoutItem.Distance ||
            this.selectedWorkoutItem.OnDistance ||
            this.selectedWorkoutItem.OffDistance
          ) {
            this.mode = "Distance";
          } else {
            this.mode = "Duration";
          }

          this.builderDuration = moment.utc(this.selectedWorkoutItem.Duration * 1000).format("HH:mm:ss");
          this.builderOnDuration = moment.utc(this.selectedWorkoutItem.OnDuration * 1000).format("HH:mm:ss");
          this.builderOffDuration = moment.utc(this.selectedWorkoutItem.OffDuration * 1000).format("HH:mm:ss");
        }
        this.saveWorkoutBuilder();
      }

      this.performance = this.workout.performance;
      this.wo = this.workout;
      this.perceivedStress = this.workout.perceivedStress;
    }
  },

  props: ["dayDate", "workout", "thresholds"],
};
</script>

<style>
@media (max-width: 576px) {
  .mobile-wide-button {
    width: 250px;
  }
}

.intensitaet {
  cursor: pointer;
  padding: 10px;
}

.close-overlay i {
  cursor: pointer;
  font-size: 45px;
  float: right;
  color: #000;
}

.wo-wrap {
  margin-right: 2px;
  display: inline-block;
}

.warning {
  padding: 15px;
  border: 2px solid darkred;
  margin: 10px 0;
  color: darkred;
  border-radius: 10px;
}

.active {
  border-bottom: 6px solid #000;
  margin-bottom: -6px;
}

.soll {
  padding: 9px;
  font-size: 20px;
  font-weight: bold;
  text-align: right;
}

.lit-hinweis {
  padding: 15px;
}

svg {
  transform: scaleY(-1);
}

.workout-parent {
  flex-direction: row;
  flex-wrap: nowrap;
  flex-flow: center;
  white-space: nowrap;
  align-items: flex-end;
  justify-content: center;
  padding: 6px;
}

.wo {
  overflow-x: scroll !important;
  padding-top: 10px !important;
  padding-bottom: 25px !important;
}

.workoutBuilderBlock {
  display: inline-block;
}

.intensitaet {
  font-size: 16px;
  font-weight: bold;
}

.intensitaet img {
  width: 35px;
}

.performance_active {
  border: 2px solid #000;
  border-radius: 5px;
  padding: 8px;
}

.performance_not_active {
  padding: 8px;
}

.segment-button {
  width: 100%;
  padding: 10px 0;
}
</style>
