import { render, staticRenderFns } from "./AICoachPreferences.vue?vue&type=template&id=37f6d0ab&scoped=true"
import script from "./AICoachPreferences.vue?vue&type=script&lang=js"
export * from "./AICoachPreferences.vue?vue&type=script&lang=js"
import style0 from "./AICoachPreferences.vue?vue&type=style&index=0&id=37f6d0ab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f6d0ab",
  null
  
)

export default component.exports