export const exerciseTranslations = [
  {
    id: 1,
    original: "A steps",
    german: "A-Schritte",
    categories: ["bodyweight", "warmup"],
    muscles: ["legs", "core"]
  },
  {
    id: 2,
    original: "Bear hold",
    german: "Bärenhaltung", 
    categories: ["bodyweight", "cooldown"],
    muscles: ["core", "shoulders"]
  },
  {
    id: 3,
    original: "Bicycles",
    german: "Fahrradfahren",
    categories: ["bodyweight"],
    muscles: ["core", "obliques"]
  },
  {
    id: 4,
    original: "Burpees",
    german: "Burpees",
    categories: ["bodyweight"],
    muscles: ["full body"]
  },
  {
    id: 5,
    original: "Butt kicks",
    german: "Butt Kicks",
    categories: ["bodyweight", "warmup"],
    muscles: ["calves", "hamstrings"]
  },
  {
    id: 6,
    original: "Clamshells (left)",
    german: "Clamshells (links)",
    belongs_to_id: 7,
    categories: ["bodyweight", "cooldown"],
    muscles: ["glutes", "hip abductors"]
  },
  {
    id: 7,
    original: "Clamshells (right)",
    german: "Clamshells (rechts)",
    belongs_to_id: 6,
    categories: ["bodyweight", "cooldown"],
    muscles: ["glutes", "hip abductors"]
  },
  {
    id: 8,
    original: "Commando planks",
    german: "Commando Planks",
    categories: ["bodyweight"],
    muscles: ["core", "shoulders", "triceps"]
  },
  {
    id: 9,
    original: "Criss cross jacks",
    german: "Criss Cross Jacks",
    categories: ["bodyweight"],
    muscles: ["legs", "core", "shoulders"]
  },
  {
    id: 10,
    original: "Cross body crunches (left)",
    german: "Cross Body Crunches (links)",
    belongs_to_id: 11,
    categories: ["bodyweight", "cooldown"],
    muscles: ["core", "obliques"]
  },
  {
    id: 11,
    original: "Cross body crunches (right)",
    german: "Cross Body Crunches (rechts)",
    belongs_to_id: 10,
    categories: ["bodyweight", "cooldown"],
    muscles: ["core", "obliques"]
  },
  {
    id: 12,
    original: "Cross body mountain climbers",
    german: "Cross Body Mountain Climbers",
    categories: ["bodyweight"],
    muscles: ["core", "shoulders", "obliques"]
  },
  {
    id: 13,
    original: "Crunches",
    german: "Crunches",
    categories: ["bodyweight"],
    muscles: ["core"]
  },
  {
    id: 14,
    original: "Curst lunges (right)",
    german: "Curst Ausfallschritte (rechts)",
    belongs_to_id: 15,
    categories: ["bodyweight", "warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 15,
    original: "Curtsy lunges (left)",
    german: "Hofknicks-Ausfallschritte (links)",
    belongs_to_id: 14,
    categories: ["bodyweight", "warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 16,
    original: "Curtsy lunges",
    german: "Hofknicks-Ausfallschritte",
    categories: ["bodyweight", "warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 17,
    original: "Dead bugs",
    german: "Tote Käfer",
    categories: ["bodyweight", "cooldown"],
    muscles: ["core", "hip flexors"]
  },
  {
    id: 18,
    original: "Donkey kicks (left)",
    german: "Eseltritte (links)",
    belongs_to_id: 19,
    categories: ["bodyweight"],
    muscles: ["glutes", "lower back"]
  },
  {
    id: 19,
    original: "Donkey kicks (right)",
    german: "Eseltritte (rechts)",
    belongs_to_id: 18,
    categories: ["bodyweight"],
    muscles: ["glutes", "lower back"]
  },
  {
    id: 20,
    original: "Donkey kicks",
    german: "Eseltritte",
    categories: ["bodyweight"],
    muscles: ["glutes", "lower back"]
  },
  {
    id: 21,
    original: "Fire hydrants (left)",
    german: "Feuerwehrhydranten (links)",
    belongs_to_id: 22,
    categories: ["bodyweight"],
    muscles: ["glutes", "hip abductors"]
  },
  {
    id: 22,
    original: "Fire hydrants (right)",
    german: "Feuerwehrhydranten (rechts)",
    belongs_to_id: 21,
    categories: ["bodyweight"],
    muscles: ["glutes", "hip abductors"]
  },
  {
    id: 23,
    original: "Fire hydrants",
    german: "Feuerwehrhydranten",
    categories: ["bodyweight"],
    muscles: ["glutes", "hip abductors"]
  },
  {
    id: 24,
    original: "Forearm plank",
    german: "Unterarmstütz",
    categories: ["bodyweight", "cooldown"],
    muscles: ["core", "shoulders"]
  },
  {
    id: 25,
    original: "Froggers",
    german: "Froggers",
    categories: ["bodyweight"],
    muscles: ["full body"]
  },
  {
    id: 26,
    original: "Glute bridges",
    german: "Glute Bridges",
    categories: ["bodyweight", "warmup", "cooldown"],
    muscles: ["glutes", "hamstrings", "lower back"]
  },
  {
    id: 27,
    original: "Glute kickbacks (left)",
    german: "Glute Kickbacks (links)",
    belongs_to_id: 28,
    categories: ["bodyweight"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 28,
    original: "Glute kickbacks (right)",
    german: "Glute Kickbacks (rechts)",
    belongs_to_id: 27,
    categories: ["bodyweight"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 29,
    original: "Glute kickbacks",
    german: "Glute Kickbacks",
    categories: ["bodyweight"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 30,
    original: "Half burpees",
    german: "Half Burpees",
    categories: ["bodyweight"],
    muscles: ["full body"]
  },
  {
    id: 31,
    original: "Heel taps",
    german: "Fersen Tippen",
    categories: ["bodyweight", "warmup"],
    muscles: ["core", "obliques"]
  },
  {
    id: 32,
    original: "High knees",
    german: "Hohe Knie",
    categories: ["bodyweight", "warmup"],
    muscles: ["core", "hip flexors", "quads"]
  },
  {
    id: 33,
    original: "High plank",
    german: "Hohe Planke",
    categories: ["bodyweight", "cooldown"],
    muscles: ["core", "shoulders", "chest"]
  },
  {
    id: 34,
    original: "Hip lift and abduction (left)",
    german: "Hüftlift und Abduktion (links)",
    belongs_to_id: 35,
    categories: ["bodyweight"],
    muscles: ["glutes", "hip abductors"]
  },
  {
    id: 35,
    original: "Hip lift and abduction (right)",
    german: "Hüftlift und Abduktion (rechts)",
    belongs_to_id: 34,
    categories: ["bodyweight"],
    muscles: ["glutes", "hip abductors"]
  },
  {
    id: 36,
    original: "In and out squats",
    german: "In und Out Kniebeugen",
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 37,
    original: "Jogging in place",
    german: "Auf der Stelle joggen",
    categories: ["bodyweight", "warmup"],
    muscles: ["legs", "core"]
  },
  {
    id: 38,
    original: "Jumping jacks",
    german: "Hampelmann",
    categories: ["bodyweight", "warmup"],
    muscles: ["full body"]
  },
  {
    id: 39,
    original: "Kick throughs",
    german: "Kick Throughs",
    categories: ["bodyweight"],
    muscles: ["core", "shoulders", "hip flexors"]
  },
  {
    id: 40,
    original: "Knee push ups",
    german: "Knie Push-ups",
    categories: ["bodyweight"],
    muscles: ["chest", "shoulders", "triceps"]
  },
  {
    id: 41,
    original: "Leg lifts",
    german: "Beinheben",
    categories: ["bodyweight"],
    muscles: ["lower abs", "hip flexors"]
  },
  {
    id: 42,
    original: "Lunge jumps",
    german: "Ausfallschritt-Sprünge",
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 43,
    original: "Marching glute bridges",
    german: "Marschierende Gesäßbrücken",
    categories: ["bodyweight", "warmup"],
    muscles: ["glutes", "hamstrings", "core"]
  },
  {
    id: 44,
    original: "Mountain climbers",
    german: "Bergsteiger",
    categories: ["bodyweight"],
    muscles: ["core", "shoulders", "hip flexors"]
  },
  {
    id: 45,
    original: "Pike push ups",
    german: "Pike Push Ups",
    categories: ["bodyweight"],
    muscles: ["shoulders", "triceps", "upper chest"]
  },
  {
    id: 46,
    original: "Plank jacks",
    german: "Plank Jacks",
    categories: ["bodyweight"],
    muscles: ["core", "shoulders", "chest"]
  },
  {
    id: 47,
    original: "Plank leg lifts",
    german: "Plank Beinheben",
    categories: ["bodyweight"],
    muscles: ["core", "glutes", "shoulders"]
  },
  {
    id: 48,
    original: "Pop squats",
    german: "Pop Squats",
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 49,
    original: "Power lunges (left)",
    german: "Power-Ausfallschritte (links)",
    belongs_to_id: 50,
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 50,
    original: "Power lunges (right)",
    german: "Power-Ausfallschritte (rechts)",
    belongs_to_id: 49,
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 51,
    original: "Push ups",
    german: "Push-ups",
    categories: ["bodyweight"],
    muscles: ["chest", "shoulders", "triceps"]
  },
  {
    id: 52,
    original: "Reverse crunches",
    german: "Reverse Crunches",
    categories: ["bodyweight"],
    muscles: ["lower abs"]
  },
  {
    id: 53,
    original: "Reverse lunges (left)",
    german: "Reverse Lunges (links)",
    belongs_to_id: 54,
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 54,
    original: "Reverse lunges (right)",
    german: "Ausfallschritte rückwärts (rechts)",
    belongs_to_id: 53,
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 55,
    original: "Reverse lunges to squats",
    german: "Rückwärts-Ausfallschritte zu Kniebeugen",
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 56,
    original: "Reverse lunges",
    german: "Rückwärts-Ausfallschritte",
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 57,
    original: "Russian twists",
    german: "Russische Drehungen",
    categories: ["bodyweight"],
    muscles: ["core", "obliques"]
  },
  {
    id: 58,
    original: "Scissor kicks",
    german: "Schere-Kicks",
    categories: ["bodyweight"],
    muscles: ["lower abs", "hip flexors"]
  },
  {
    id: 59,
    original: "Seal jacks",
    german: "Seal Jacks",
    categories: ["bodyweight", "warmup"],
    muscles: ["shoulders", "core", "legs"]
  },
  {
    id: 60,
    original: "Shoulder taps",
    german: "Schultertippen",
    categories: ["bodyweight"],
    muscles: ["shoulders", "core"]
  },
  {
    id: 61,
    original: "Side lunges (left)",
    german: "Seitliche Ausfallschritte (links)",
    belongs_to_id: 62,
    categories: ["bodyweight", "warmup"],
    muscles: ["inner thighs", "glutes", "quads"]
  },
  {
    id: 62,
    original: "Side lunges (right)",
    german: "Seitliche Ausfallschritte (rechts)",
    belongs_to_id: 61,
    categories: ["bodyweight", "warmup"],
    muscles: ["inner thighs", "glutes", "quads"]
  },
  {
    id: 63,
    original: "Side plank (left)",
    german: "Seitenplanke (links)",
    belongs_to_id: 64,
    categories: ["bodyweight", "cooldown"],
    muscles: ["obliques", "shoulders"]
  },
  {
    id: 64,
    original: "Side plank (right)",
    german: "Seitenplanke (rechts)",
    belongs_to_id: 63,
    categories: ["bodyweight", "cooldown"],
    muscles: ["obliques", "shoulders"]
  },
  {
    id: 65,
    original: "Side to side lunges",
    german: "Seitliche Ausfallschritte",
    categories: ["bodyweight", "warmup"],
    muscles: ["inner thighs", "glutes", "quads"]
  },
  {
    id: 66,
    original: "Single leg deadlifts (left)",
    german: "Einbeiniges Kreuzheben (links)",
    belongs_to_id: 67,
    categories: ["bodyweight", "cooldown"],
    muscles: ["hamstrings", "glutes", "lower back"]
  },
  {
    id: 67,
    original: "Single leg deadlifts (right)",
    german: "Einbeinige Kreuzheben (rechts)",
    belongs_to_id: 66,
    categories: ["bodyweight", "cooldown"],
    muscles: ["hamstrings", "glutes", "lower back"]
  },
  {
    id: 68,
    original: "Single leg glute bridges (left)",
    german: "Einfüßige Gesäßbrücken (links)",
    belongs_to_id: 69,
    categories: ["bodyweight", "cooldown"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 69,
    original: "Single leg glute bridges (right)",
    german: "Einfüßige Gesäßbrücken (rechts)",
    belongs_to_id: 68,
    categories: ["bodyweight", "cooldown"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 70,
    original: "Sit ups",
    german: "Sit-ups",
    categories: ["bodyweight"],
    muscles: ["core", "hip flexors"]
  },
  {
    id: 71,
    original: "Skaters",
    german: "Skaters",
    categories: ["bodyweight"],
    muscles: ["legs", "glutes", "core"]
  },
  {
    id: 72,
    original: "Split squats (left)",
    german: "Split Squats (links)",
    belongs_to_id: 73,
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 73,
    original: "Split squats (right)",
    german: "Split Squats (rechts)",
    belongs_to_id: 72,
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 74,
    original: "Squat jumps",
    german: "Kniebeugen Sprünge",
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 75,
    original: "Squat pulses",
    german: "Squat Pulses",
    categories: ["bodyweight", "warmup"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 76,
    original: "Squats",
    german: "Kniebeugen",
    categories: ["bodyweight", "warmup"],
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 77,
    original: "Staggered squats (left)",
    german: "Versetzte Kniebeugen (links)",
    belongs_to_id: 78,
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 78,
    original: "Staggered squats (right)",
    german: "Versetzte Kniebeugen (rechts)",
    belongs_to_id: 77,
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 79,
    original: "Sumo squat pulses",
    german: "Sumo Kniebeugen Pulses",
    categories: ["bodyweight"],
    muscles: ["inner thighs", "glutes", "quads"]
  },
  {
    id: 80,
    original: "Surrender squats",
    german: "Surrender Squats",
    categories: ["bodyweight"],
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 81,
    original: "Toe touch crunches",
    german: "Zehenspitzen-Crunches",
    categories: ["bodyweight"],
    muscles: ["core", "hip flexors"]
  },
  {
    id: 82,
    original: "Tricep dips",
    german: "Trizeps Dips",
    categories: ["bodyweight", "cooldown"],
    muscles: ["triceps", "chest", "shoulders"]
  },
  {
    id: 83,
    original: "V ups",
    german: "V-Ups",
    categories: ["bodyweight"],
    muscles: ["core", "hip flexors"]
  },
];

export const dumbbellExercises = [
  {
    id: 84,
    original: "Arnold presses",
    german: "Arnold Drücken",
    muscles: ["shoulders", "triceps"]
  },
  {
    id: 85,
    original: "Bent over rows",
    german: "Vorgebeugtes Rudern",
    muscles: ["back", "biceps"]
  },
  {
    id: 86,
    original: "Bicep curl to presses",
    german: "Bizeps-Curls zu Schulterdrücken",
    muscles: ["biceps", "shoulders"]
  },
  {
    id: 87,
    original: "Bicep curls",
    german: "Bizeps Curls",
    categories: ["warmup"],
    muscles: ["biceps"]
  },
  {
    id: 88,
    original: "Curtsy lunges (right)",
    german: "Hofknicks-Ausfallschritte (rechts)",
    belongs_to_id: 92,
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 89,
    original: "Curtsy lunges to lateral raises (left)",
    german: "Hofknicks-Ausfallschritte zu seitlichen Heben (links)",
    belongs_to_id: 91,
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 90,
    original: "Curtsy lunges to lateral raises (right)",
    german: "Hofknicks-Ausfallschritte zu seitlichen Heben (rechts)",
    belongs_to_id: 91,
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 91,
    original: "Curtsy lunges to lateral raises",
    german: "Hofknicks-Ausfallschritte zu seitlichen Heben",
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 92,
    original: "Curtsy lunges",
    german: "Hofknix-Kniebeugen",
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 93,
    original: "Cutsy lunges (left)",
    german: "Cutsy Ausfallschritte (links)",
    belongs_to_id: 92,
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 94,
    original: "Dead bugs",
    german: "Tote Käfer",
    categories: ["warmup"],
    muscles: ["core", "hip flexors"]
  },
  {
    id: 95,
    original: "Deadlifts to bent over rows",
    german: "Kreuzheben zu vorgebeugtem Rudern",
    muscles: ["back", "hamstrings", "glutes", "biceps"]
  },
  {
    id: 96,
    original: "Dumbbell swings",
    german: "Kettlebell-Schwünge",
    muscles: ["shoulders", "core", "glutes"]
  },
  {
    id: 97,
    original: "External rotations",
    german: "Äußere Rotationen",
    categories: ["warmup", "cooldown"],
    muscles: ["rotator cuff"]
  },
  {
    id: 98,
    original: "Floor chest flyes",
    german: "Boden Brust Fliegen",
    muscles: ["chest", "shoulders"]
  },
  {
    id: 99,
    original: "Floor chest presses",
    german: "Boden Brustdrücken",
    muscles: ["chest", "shoulders", "triceps"]
  },
  {
    id: 100,
    original: "Forward lunges",
    german: "Ausfallschritte nach vorne",
    categories: ["warmup"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 101,
    original: "Front squats",
    german: "Front Squats",
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 102,
    original: "Frontal shoulder raises",
    german: "Frontale Schulterheben",
    muscles: ["front deltoids"]
  },
  {
    id: 103,
    original: "Glute bridges",
    german: "Glute Bridges",
    categories: ["warmup", "cooldown"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 104,
    original: "Goblet squat jumps",
    german: "Goblet Kniebeugen Sprünge",
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 105,
    original: "Goblet squats",
    german: "Goblet Squats",
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 106,
    original: "Halo circles",
    german: "Halo Kreise",
    categories: ["warmup"],
    muscles: ["shoulders", "core"]
  },
  {
    id: 107,
    original: "Hammer curls",
    german: "Hammer Curls",
    categories: ["warmup"],
    muscles: ["biceps", "forearms"]
  },
  {
    id: 108,
    original: "Kneeling shoulder presses",
    german: "Kniende Schulterdrücken",
    muscles: ["shoulders", "triceps"]
  },
  {
    id: 109,
    original: "Lateral shoulder raises",
    german: "Seitliches Schulterheben",
    muscles: ["lateral deltoids"]
  },
  {
    id: 110,
    original: "Pendulum lunges (left)",
    german: "Pendel-Ausfallschritte (links)",
    belongs_to_id: 126,
    muscles: ["quads", "glutes", "hamstrings"]
  },
  {
    id: 111,
    original: "Pendulum lunges (right)",
    german: "Pendel-Ausfallschritte (rechts)",
    belongs_to_id: 126,
    muscles: ["quads", "glutes", "hamstrings"]
  },
  {
    id: 112,
    original: "Plank T rotations (left)",
    german: "Plank T-Rotationen (links)",
    belongs_to_id: 114,
    muscles: ["core", "shoulders", "obliques"]
  },
  {
    id: 113,
    original: "Plank T rotations (right)",
    german: "Plank T-Rotationen (rechts)",
    belongs_to_id: 114,
    muscles: ["core", "shoulders", "obliques"]
  },
  {
    id: 114,
    original: "Plank pull throughs",
    german: "Plank Pull Throughs",
    muscles: ["core", "shoulders"]
  },
  {
    id: 115,
    original: "Rainbow bicep curls",
    german: "Rainbow Bizeps Curls",
    muscles: ["biceps"]
  },
  {
    id: 116,
    original: "Renegade rows",
    german: "Renegade Rows",
    muscles: ["back", "core", "shoulders"]
  },
  {
    id: 117,
    original: "Rest 1",
    german: "Ruhe 1",
    muscles: []
  },
  {
    id: 118,
    original: "Rest 2",
    german: "Ruhe 2",
    muscles: []
  },
  {
    id: 119,
    original: "Rest 3",
    german: "Ruhe 3",
    muscles: []
  },
  {
    id: 120,
    original: "Reverse flyes",
    german: "Reverse Flyes",
    categories: ["warmup", "cooldown"],
    muscles: ["rear deltoids", "upper back"]
  },
  {
    id: 121,
    original: "Reverse lunge to bicep curls (left)",
    german: "Rückwärts-Ausfallschritt mit Bizeps-Curls (links)",
    belongs_to_id: 125,
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 122,
    original: "Reverse lunges (left)",
    german: "Reverse Ausfallschritte (links)",
    belongs_to_id: 126,
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 123,
    original: "Reverse lunges (right)",
    german: "Ausfallschritte rückwärts (rechts)",
    belongs_to_id: 126,
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 124,
    original: "Reverse lunges to bicep curls (right)",
    german: "Rückwärts-Ausfallschritte zu Bizeps-Curls (rechts)",
    belongs_to_id: 125,
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 125,
    original: "Reverse lunges to bicep curls",
    german: "Rückwärts-Ausfallschritte zu Bizeps-Curls",
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 126,
    original: "Reverse lunges",
    german: "Rückwärts-Ausfallschritte",
    categories: ["warmup"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 127,
    original: "Romanian deadlifts",
    german: "Rumänisches Kreuzheben",
    muscles: ["hamstrings", "glutes", "lower back"]
  },
  {
    id: 128,
    original: "Russian twists",
    german: "Russische Drehungen",
    muscles: ["core", "obliques"]
  },
  {
    id: 129,
    original: "Shoulder presses",
    german: "Schulterdrücken",
    muscles: ["shoulders", "triceps"]
  },
  {
    id: 130,
    original: "Shrugs",
    german: "Schulterzucken",
    categories: ["warmup", "cooldown"],
    muscles: ["trapezius"]
  },
  {
    id: 131,
    original: "Side bends (left)",
    german: "Seitliche Beugen (links)",
    categories: ["warmup", "cooldown"],
    belongs_to_id: 132,
    muscles: ["obliques"]
  },
  {
    id: 132,
    original: "Side bends (right)",
    german: "Seitliche Beugen (rechts)",
    categories: ["warmup", "cooldown"],
    muscles: ["obliques"]
  },
  {
    id: 133,
    original: "Side lunges (left)",
    german: "Seitliche Ausfallschritte (links)",
    categories: ["warmup"],
    belongs_to_id: 135,
    muscles: ["inner thighs", "glutes", "quads"]
  },
  {
    id: 134,
    original: "Side lunges (right)",
    german: "Seitliche Ausfallschritte (rechts)",
    categories: ["warmup"],
    belongs_to_id: 135,
    muscles: ["inner thighs", "glutes", "quads"]
  },
  {
    id: 135,
    original: "Side to side lunges",
    german: "Seitliche Ausfallschritte",
    categories: ["warmup"],
    muscles: ["inner thighs", "glutes", "quads"]
  },
  {
    id: 136,
    original: "Single arm overhead squats (left)",
    german: "Einarmige Overhead-Kniebeugen (links)",
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 137,
    original: "Single arm overhead squats (right)",
    german: "Einarmige Overhead-Kniebeugen (rechts)",
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 138,
    original: "Single leg deadlifts (left)",
    german: "Einbeiniges Kreuzheben (links)",
    muscles: ["hamstrings", "glutes", "lower back"]
  },
  {
    id: 139,
    original: "Single leg deadlifts (right)",
    german: "Einbeinige Kreuzheben (rechts)",
    muscles: ["hamstrings", "glutes", "lower back"]
  },
  {
    id: 140,
    original: "Single leg glute bridges (left)",
    german: "Einfache Bein-Glute-Bridges (links)",
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 141,
    original: "Single leg glute bridges (right)",
    german: "Einfüßige Gesäßbrücken (rechts)",
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 142,
    original: "Sit up cross punches",
    german: "Sit-ups mit Kreuzschlägen",
    muscles: ["core", "shoulders"]
  },
  {
    id: 143,
    original: "Sit up to presses",
    german: "Sit-ups zu Pressen",
    muscles: ["core", "shoulders"]
  },
  {
    id: 144,
    original: "Snatches",
    german: "Snatches",
    muscles: ["full body"]
  },
  {
    id: 145,
    original: "Split squats (left)",
    german: "Split Squats (links)",
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 146,
    original: "Split squats (right)",
    german: "Split Squats (rechts)",
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 147,
    original: "Staggered stance deadlifts (left)",
    german: "Staffellaufstellung Kreuzheben (links)",
    muscles: ["hamstrings", "glutes", "lower back"]
  },
  {
    id: 148,
    original: "Staggered stance deadlifts (right)",
    german: "Staffelhaltung Kreuzheben (rechts)",
    muscles: ["hamstrings", "glutes", "lower back"]
  },
  {
    id: 149,
    original: "Sumo squat pulses",
    german: "Sumo Kniebeugen Pulses",
    muscles: ["inner thighs", "glutes", "quads"]
  },
  {
    id: 150,
    original: "Sumo squat to upright rows",
    german: "Sumo Kniebeugen mit aufrechtem Rudern",
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 151,
    original: "Sumo squats",
    german: "Sumo Kniebeugen",
    muscles: ["inner thighs", "glutes", "quads"]
  },
  {
    id: 152,
    original: "Thrusters",
    german: "Thrusters",
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 153,
    original: "Tricep extensions",
    german: "Trizeps Extensions",
    categories: ["warmup"],
    muscles: ["triceps"]
  },
  {
    id: 154,
    original: "Upright rows",
    german: "Aufrechtes Rudern",
    muscles: ["shoulders", "trapezius"]
  },
  {
    id: 155,
    original: "V sit cross jabs",
    german: "V-Sitz Cross Jabs",
    muscles: ["core", "shoulders"]
  },
  {
    id: 156,
    original: "Windmill (left)",
    german: "Windmühle (links)",
    belongs_to_id: 157,
    muscles: ["core", "obliques"]
  },
  {
    id: 157,
    original: "Windmills (right)",
    german: "Windmühlen (rechts)",
    muscles: ["core", "obliques"]
  },
  {
    id: 158,
    original: "Wood chops (left)",
    german: "Holzhacken (links)",
    belongs_to_id: 159,
    muscles: ["core", "obliques"]
  },
  {
    id: 159,
    original: "Wood chops (right)",
    german: "Holzhacken (rechts)",
    muscles: ["core", "obliques"]
  },
];

export const yogaExercises = [
  {
    id: 160,
    original: "Boat pose",
    german: "Boots-Pose",
    categories: ["cooldown"],
    muscles: ["core", "hip flexors"]
  },
  {
    id: 161,
    original: "Bow pose",
    german: "Bogenhaltung",
    categories: ["cooldown"],
    muscles: ["back", "shoulders"]
  },
  {
    id: 162,
    original: "Bridge pose",
    german: "Brückenpose",
    categories: ["warmup", "cooldown"],
    muscles: ["glutes", "hamstrings", "lower back"]
  },
  {
    id: 163,
    original: "Camel pose",
    german: "Kamel-Pose",
    categories: ["cooldown"],
    muscles: ["back", "shoulders"]
  },
  {
    id: 164,
    original: "Cat cow pose",
    german: "Katzen-Kuh-Pose",
    categories: ["warmup"],
    muscles: ["spine", "core"]
  },
  {
    id: 165,
    original: "Cat pose",
    german: "Katzenstellung",
    categories: ["warmup"],
    muscles: ["spine", "core"]
  },
  {
    id: 166,
    original: "Chair pose",
    german: "Stuhl-Pose",
    categories: ["warmup"],
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 167,
    original: "Child_s pose",
    german: "Kindstellung",
    categories: ["cooldown"],
    muscles: ["back", "hips"]
  },
  {
    id: 168,
    original: "Cobbler_s pose",
    german: "Schustersitz",
    categories: ["cooldown"],
    muscles: ["inner thighs", "hips"]
  },
  {
    id: 169,
    original: "Cobra pose",
    german: "Kobra-Pose",
    categories: ["warmup"],
    muscles: ["back", "shoulders"]
  },
  {
    id: 170,
    original: "Corpse pose",
    german: "Totenstellung",
    categories: ["cooldown"],
    muscles: []
  },
  {
    id: 171,
    original: "Cow face pose (left)",
    german: "Kuhgesicht-Pose (links)",
    belongs_to_id: 172,
    categories: ["cooldown"],
    muscles: ["shoulders", "hips"]
  },
  {
    id: 172,
    original: "Cow face pose (right)",
    german: "Kuhkopf-Pose (rechts)",
    belongs_to_id: 171,
    categories: ["cooldown"],
    muscles: ["shoulders", "hips"]
  },
  {
    id: 173,
    original: "Cow pose",
    german: "Kuh-Pose",
    categories: ["warmup"],
    muscles: ["spine", "core"]
  },
  {
    id: 174,
    original: "Crescent lunge (left)",
    german: "Mond-Anlauf (links)",
    belongs_to_id: 175,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 175,
    original: "Crescent lunge (right)",
    german: "Ausfallschritt mit rechtem Halbmond",
    belongs_to_id: 174,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 176,
    original: "Downward facing dog split (left)",
    german: "Herabschauender Hund Split (links)",
    belongs_to_id: 177,
    categories: ["warmup"],
    muscles: ["hamstrings", "calves", "shoulders"]
  },
  {
    id: 177,
    original: "Downward facing dog split (right)",
    german: "Herabschauender Hund Split (rechts)",
    belongs_to_id: 176,
    categories: ["warmup"],
    muscles: ["hamstrings", "calves", "shoulders"]
  },
  {
    id: 178,
    original: "Downward facing dog",
    german: "Herabschauender Hund",
    categories: ["warmup"],
    muscles: ["hamstrings", "calves", "shoulders"]
  },
  {
    id: 179,
    original: "Extended side angle (left)",
    german: "Erweiterte Seitenwinkel (links)",
    belongs_to_id: 180,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 180,
    original: "Extended side angle (right)",
    german: "Erweiterte Seitenwinkelhaltung (rechts)",
    belongs_to_id: 179,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 181,
    original: "Fire log pose (left)",
    german: "Feuerholz-Pose (links)",
    belongs_to_id: 182,
    categories: ["cooldown"],
    muscles: ["hips", "glutes"]
  },
  {
    id: 182,
    original: "Fire log pose (right)",
    german: "Feuerholz-Pose (rechts)",
    belongs_to_id: 181,
    categories: ["cooldown"],
    muscles: ["hips", "glutes"]
  },
  {
    id: 183,
    original: "Frog pose",
    german: "Frosch Pose",
    categories: ["cooldown"],
    muscles: ["inner thighs", "hips"]
  },
  {
    id: 184,
    original: "Garland pose",
    german: "Garland Pose",
    categories: ["warmup"],
    muscles: ["quads", "glutes", "hips"]
  },
  {
    id: 185,
    original: "Gate pose (left)",
    german: "Torhaltung (links)",
    belongs_to_id: 186,
    categories: ["warmup", "cooldown"],
    muscles: ["obliques", "hips"]
  },
  {
    id: 186,
    original: "Gate pose (right)",
    german: "Torhaltung (rechts)",
    belongs_to_id: 185,
    categories: ["warmup", "cooldown"],
    muscles: ["obliques", "hips"]
  },
  {
    id: 187,
    original: "Goddess pose",
    german: "Göttinnen-Pose",
    categories: ["warmup"],
    muscles: ["quads", "glutes", "inner thighs"]
  },
  {
    id: 188,
    original: "Half boat pose",
    german: "Halbe Boot-Pose",
    categories: ["cooldown"],
    muscles: ["core", "hip flexors"]
  },
  {
    id: 189,
    original: "Half frog pose (left)",
    german: "Halbe Froschpose (links)",
    belongs_to_id: 190,
    categories: ["cooldown"],
    muscles: ["hips", "glutes"]
  },
  {
    id: 190,
    original: "Half frog pose (right)",
    german: "Halbe Froschpose (rechts)",
    belongs_to_id: 189,
    categories: ["cooldown"],
    muscles: ["hips", "glutes"]
  },
  {
    id: 191,
    original: "Half loard of the fishes pose (right)",
    german: "Halber Herr der Fische Pose (rechts)",
    belongs_to_id: 192,
    categories: ["cooldown"],
    muscles: ["spine", "obliques"]
  },
  {
    id: 192,
    original: "Half lord of the fishes pose (left)",
    german: "Halber Herr der Fische Pose (links)",
    belongs_to_id: 191,
    categories: ["cooldown"],
    muscles: ["spine", "obliques"]
  },
  {
    id: 193,
    original: "Half moon pose (left)",
    german: "Halbmond-Pose (links)",
    belongs_to_id: 194,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 194,
    original: "Half moon pose (right)",
    german: "Halbmond-Pose (rechts)",
    belongs_to_id: 193,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 195,
    original: "Half split (left)",
    german: "Halber Spagat (links)",
    belongs_to_id: 196,
    categories: ["cooldown"],
    muscles: ["hamstrings", "glutes"]
  },
  {
    id: 196,
    original: "Half split (right)",
    german: "Halber Spagat (rechts)",
    belongs_to_id: 195,
    categories: ["cooldown"],
    muscles: ["hamstrings", "glutes"]
  },
  {
    id: 197,
    original: "Happy baby pose",
    german: "Glückliches Baby Pose",
    categories: ["cooldown"],
    muscles: ["hips", "lower back"]
  },
  {
    id: 198,
    original: "High plank",
    german: "Hohe Planke",
    categories: ["warmup"],
    muscles: ["core", "shoulders"]
  },
  {
    id: 199,
    original: "Lotus pose",
    german: "Lotuspose",
    categories: ["cooldown"],
    muscles: ["hips", "spine"]
  },
  {
    id: 200,
    original: "Low lunge (right)",
    german: "Tiefer Ausfallschritt (rechts)",
    belongs_to_id: 201,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 201,
    original: "Low lunges (left)",
    german: "Tiefe Ausfallschritte (links)",
    belongs_to_id: 200,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 202,
    original: "Mountain pose",
    german: "Bergpose",
    categories: ["warmup"],
    muscles: ["core", "legs"]
  },
  {
    id: 203,
    original: "Plow pose",
    german: "Pflug-Pose",
    categories: ["cooldown"],
    muscles: ["spine", "shoulders"]
  },
  {
    id: 204,
    original: "Puppy pose",
    german: "Welpenpose",
    categories: ["warmup", "cooldown"],
    muscles: ["spine", "shoulders"]
  },
  {
    id: 205,
    original: "Pyramid pose (left)",
    german: "Pyramiden-Pose (links)",
    belongs_to_id: 206,
    categories: ["cooldown"],
    muscles: ["hamstrings", "calves"]
  },
  {
    id: 206,
    original: "Pyramid pose (right)",
    german: "Pyramiden-Pose (rechts)",
    belongs_to_id: 205,
    categories: ["cooldown"],
    muscles: ["hamstrings", "calves"]
  },
  {
    id: 207,
    original: "Reclined butterfly",
    german: "Liegender Schmetterling",
    categories: ["cooldown"],
    muscles: ["inner thighs", "hips"]
  },
  {
    id: 208,
    original: "Reclined pigeon (left)",
    german: "Liegender Taube (links)",
    belongs_to_id: 209,
    categories: ["cooldown"],
    muscles: ["hips", "glutes"]
  },
  {
    id: 209,
    original: "Reclined pigeon (right)",
    german: "Liegender Taube (rechts)",
    belongs_to_id: 208,
    categories: ["cooldown"],
    muscles: ["hips", "glutes"]
  },
  {
    id: 210,
    original: "Reclined windshield wipers",
    german: "Liegender Scheibenwischer",
    categories: ["warmup"],
    muscles: ["spine", "obliques"]
  },
  {
    id: 211,
    original: "Reverse warrior (left)",
    german: "Umgekehrter Krieger (links)",
    belongs_to_id: 212,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 212,
    original: "Reverse warrior (right)",
    german: "Umgekehrter Krieger (rechts)",
    belongs_to_id: 211,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 213,
    original: "Revolved chair pose (left)",
    german: "Gedrehte Stuhl-Pose (links)",
    belongs_to_id: 214,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 214,
    original: "Revolves chair pose (right)",
    german: "Drehender Stuhl Pose (rechts)",
    belongs_to_id: 213,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 215,
    original: "Revolved triangle (left)",
    german: "Gedrehtes Dreieck (links)",
    belongs_to_id: 216,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 216,
    original: "Revolved triangle (right)",
    german: "Gedrehtes Dreieck (rechts)",
    belongs_to_id: 215,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 217,
    original: "Sage marichi I pose (left)",
    german: "Sage Marichi I Pose (links)",
    belongs_to_id: 218,
    categories: ["cooldown"],
    muscles: ["spine", "obliques"]
  },
  {
    id: 218,
    original: "Sage marichi I pose (right)",
    german: "Sage Marichi I Pose (rechts)",
    belongs_to_id: 217,
    categories: ["cooldown"],
    muscles: ["spine", "obliques"]
  },
  {
    id: 219,
    original: "Seated eagle pose (left)",
    german: "Sitzende Adlerpose (links)",
    belongs_to_id: 220,
    categories: ["cooldown"],
    muscles: ["shoulders", "hips"]
  },
  {
    id: 220,
    original: "Seated eagle pose (right)",
    german: "Sitzende Adlerpose (rechts)",
    belongs_to_id: 219,
    categories: ["cooldown"],
    muscles: ["shoulders", "hips"]
  },
  {
    id: 221,
    original: "Seated forward bend",
    german: "Sitzende Vorwärtsbeuge",
    categories: ["cooldown"],
    muscles: ["hamstrings", "spine"]
  },
  {
    id: 222,
    original: "Seated wide angle straddle",
    german: "Sitzender breiter Winkel-Spagat",
    categories: ["cooldown"],
    muscles: ["inner thighs", "spine"]
  },
  {
    id: 223,
    original: "Side plank (left)",
    german: "Seitenplanke (links)",
    belongs_to_id: 224,
    categories: ["warmup"],
    muscles: ["obliques", "shoulders"]
  },
  {
    id: 224,
    original: "Side plank (right)",
    german: "Seitenplanke (rechts)",
    belongs_to_id: 223,
    categories: ["warmup"],
    muscles: ["obliques", "shoulders"]
  },
  {
    id: 225,
    original: "Sphinx pose",
    german: "Sphinx-Pose",
    categories: ["warmup"],
    muscles: ["spine", "shoulders"]
  },
  {
    id: 226,
    original: "Standing forward bend",
    german: "Vorbeuge im Stehen",
    categories: ["warmup", "cooldown"],
    muscles: ["hamstrings", "spine"]
  },
  {
    id: 227,
    original: "Supported shoulder stand",
    german: "Gestützter Schulterstand",
    categories: ["cooldown"],
    muscles: ["shoulders", "spine"]
  },
  {
    id: 228,
    original: "Tiger pose (left)",
    german: "Tigerpose (links)",
    belongs_to_id: 229,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "spine"]
  },
  {
    id: 229,
    original: "Tiger pose (right)",
    german: "Tigerpose (rechts)",
    belongs_to_id: 228,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "spine"]
  },
  {
    id: 230,
    original: "Tree pose (left)",
    german: "Baumhaltung (links)",
    belongs_to_id: 231,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 231,
    original: "Tree pose (right)",
    german: "Baumhaltung (rechts)",
    belongs_to_id: 230,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 232,
    original: "Triangle pose (left)",
    german: "Dreieck-Pose (links)",
    belongs_to_id: 233,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 233,
    original: "Triangle pose (right)",
    german: "Dreieck-Pose (rechts)",
    belongs_to_id: 232,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "obliques"]
  },
  {
    id: 234,
    original: "Upward facing dog",
    german: "Aufwärts gerichteter Hund",
    categories: ["warmup"],
    muscles: ["spine", "shoulders"]
  },
  {
    id: 235,
    original: "Warrior I (left)",
    german: "Krieger I (links)",
    belongs_to_id: 236,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 236,
    original: "Warrior I (right)",
    german: "Krieger I (rechts)",
    belongs_to_id: 235,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 237,
    original: "Warrior II (left)",
    german: "Krieger II (links)",
    belongs_to_id: 238,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 238,
    original: "Warrior II (right)",
    german: "Krieger II (rechts)",
    belongs_to_id: 237,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 239,
    original: "Warrior III (left)",
    german: "Krieger III (links)",
    belongs_to_id: 240,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 240,
    original: "Warrior III (right)",
    german: "Krieger III (rechts)",
    belongs_to_id: 239,
    categories: ["warmup"],
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 241,
    original: "Wheel pose",
    german: "Radpose",
    categories: ["cooldown"],
    muscles: ["spine", "shoulders"]
  },
  {
    id: 242,
    original: "Wild thing pose (left)",
    german: "Wild Thing Pose (links)",
    belongs_to_id: 243,
    categories: ["cooldown"],
    muscles: ["spine", "shoulders"]
  },
  {
    id: 243,
    original: "Wild thing pose (right)",
    german: "Wild Thing Pose (rechts)",
    belongs_to_id: 242,
    categories: ["cooldown"],
    muscles: ["spine", "shoulders"]
  },
];

export const mobilityExercises = [
  {
    id: 244,
    original: "90 90 hip switches",
    german: "90 90 Hüftwechsel",
    categories: ["warmup", "cooldown"],
    muscles: ["hip flexors", "glutes"]
  },
  {
    id: 245,
    original: "Ankle circles (right)",
    german: "Knöchelkreisen (rechts)",
    belongs_to_id: 246,
    categories: ["warmup", "cooldown"],
    muscles: ["ankles"]
  },
  {
    id: 246,
    original: "Ankle rolls (left)",
    german: "Knöchel kreisen (links)",
    belongs_to_id: 245,
    categories: ["warmup", "cooldown"],
    muscles: ["ankles"]
  },
  {
    id: 247,
    original: "Arm circles (backward)",
    german: "Armkreisen (rückwärts)",
    categories: ["warmup"],
    belongs_to_id: 248,
    muscles: ["shoulders"]
  },
  {
    id: 248,
    original: "Arm circles (forward)",
    german: "Armkreisen (vorwärts)",
    belongs_to_id: 247,
    categories: ["warmup"],
    muscles: ["shoulders"]
  },
  {
    id: 249,
    original: "Arm swings (front to back)",
    german: "Armschwünge (vorwärts und rückwärts)",
    categories: ["warmup"],
    muscles: ["shoulders", "chest"]
  },
  {
    id: 250,
    original: "Arm swings (up and down)",
    german: "Armschwünge (auf und ab)",
    categories: ["warmup"],
    muscles: ["shoulders", "chest"]
  },
  {
    id: 251,
    original: "Butterfly stretch",
    german: "Schmetterlingsdehnung",
    categories: ["cooldown"],
    muscles: ["inner thighs", "hips"]
  },
  {
    id: 252,
    original: "Cat cow",
    german: "Katze-Kuh",
    categories: ["warmup", "cooldown"],
    muscles: ["spine", "core"]
  },
  {
    id: 253,
    original: "Child_s pose",
    german: "Kindhaltung",
    categories: ["cooldown"],
    muscles: ["back", "hips"]
  },
  {
    id: 254,
    original: "Deep squat openers",
    german: "Tiefe Kniebeugenöffner",
    categories: ["warmup"],
    muscles: ["quads", "glutes", "hips"]
  },
  {
    id: 255,
    original: "Figure four stretch (left)",
    german: "Figur-Vier-Dehnung (links)",
    belongs_to_id: 256,
    categories: ["cooldown"],
    muscles: ["glutes", "hips"]
  },
  {
    id: 256,
    original: "Figure four stretch (right)",
    german: "Figur-Vier-Dehnung (rechts)",
    belongs_to_id: 255,
    categories: ["cooldown"],
    muscles: ["glutes", "hips"]
  },
  {
    id: 257,
    original: "Forearm stretch (left)",
    german: "Unterarmdehnung (links)",
    belongs_to_id: 258,
    categories: ["cooldown"],
    muscles: ["forearms"]
  },
  {
    id: 258,
    original: "Forearm stretch (right)",
    german: "Unterarmdehnung (rechts)",
    belongs_to_id: 257,
    categories: ["cooldown"],
    muscles: ["forearms"]
  },
  {
    id: 259,
    original: "Frog squats",
    german: "Frosch Kniebeugen",
    categories: ["warmup"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 260,
    original: "Half split stretch (left)",
    german: "Halbe Spagat Dehnung (links)",
    belongs_to_id: 261,
    categories: ["cooldown"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 261,
    original: "Half split stretch (right)",
    german: "Halbe Spagat Dehnung (rechts)",
    belongs_to_id: 260,
    categories: ["cooldown"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 262,
    original: "Hamstring stretch (left)",
    german: "Dehnung der linken Oberschenkelrückseite",
    belongs_to_id: 263,
    categories: ["cooldown"],
    muscles: ["hamstrings"]
  },
  {
    id: 263,
    original: "Hamstring stretch (right)",
    german: "Dehnung der rechten Oberschenkelrückseite",
    belongs_to_id: 262,
    categories: ["cooldown"],
    muscles: ["hamstrings"]
  },
  {
    id: 264,
    original: "Hip circles (clockwise)",
    german: "Hüftkreise (im Uhrzeigersinn)",
    belongs_to_id: 265,
    categories: ["warmup"],
    muscles: ["glutes"]
  },
  {
    id: 265,
    original: "Hip circles (counter clockwise)",
    german: "Hüftkreise (gegen den Uhrzeigersinn)",
    belongs_to_id: 264,
    categories: ["warmup"],
    muscles: ["glutes"]
  },
  {
    id: 266,
    original: "Hip flexor stretch (left)",
    german: "Hüftbeuger Dehnung (links)",
    belongs_to_id: 267,
    categories: ["cooldown"],
    muscles: ["hip flexors"]
  },
  {
    id: 267,
    original: "Hip flexor stretch (right)",
    german: "Hüftbeuger Dehnung (rechts)",
    belongs_to_id: 266,
    categories: ["cooldown"],
    muscles: ["hip flexors"]
  },
  {
    id: 268,
    original: "Inch worms",
    german: "Inchworms",
    categories: ["warmup"],
    muscles: ["inner thighs"]
  },
  {
    id: 269,
    original: "Knee to chest (left)",
    german: "Knie zur Brust (links)",
    belongs_to_id: 270,
    categories: ["cooldown"],
    muscles: ["quads"]
  },
  {
    id: 270,
    original: "Knee to chest (right)",
    german: "Knie zur Brust (rechts)",
    belongs_to_id: 269,
    categories: ["cooldown"],
    muscles: ["quads"]
  },
  {
    id: 271,
    original: "Leg swings (left)",
    german: "Beinschwünge (links)",
    belongs_to_id: 272,
    categories: ["warmup"],
    muscles: ["quads"]
  },
  {
    id: 272,
    original: "Leg swings (right)",
    german: "Beinschwünge (rechts)",
    belongs_to_id: 271,
    categories: ["warmup"],
    muscles: ["quads"]
  },
  {
    id: 273,
    original: "Low lunge with revolved rotation (left)",
    german: "Tiefe Ausfallschritte mit gedrehter Rotation (links)",
    belongs_to_id: 274,
    categories: ["warmup"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 274,
    original: "Low lunge with revolved rotation (right)",
    german: "Tiefe Ausfallschritte mit gedrehter Rotation (rechts)",
    belongs_to_id: 273,
    categories: ["warmup"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 275,
    original: "Low lunge with rotation (left)",
    german: "Tiefe Ausfallschritte mit Drehung (links)",
    belongs_to_id: 276,
    categories: ["warmup"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 276,
    original: "Low lunge with rotation (right)",
    german: "Tiefe Ausfallschritte mit Drehung (rechts)",
    belongs_to_id: 275,
    categories: ["warmup"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 277,
    original: "Neck circles (clockwise)",
    german: "Nackenkreisen (im Uhrzeigersinn)",
    belongs_to_id: 278,
    categories: ["cooldown"],
    muscles: ["neck"]
  },
  {
    id: 278,
    original: "Neck circles (counter clockwise)",
    german: "Nackenkreisen (gegen den Uhrzeigersinn)",
    belongs_to_id: 277,
    categories: ["cooldown"],
    muscles: ["neck"]
  },
  {
    id: 279,
    original: "Neck stretch (left)",
    german: "Nackendehnung (links)",
    belongs_to_id: 280,
    categories: ["cooldown"],
    muscles: ["neck"]
  },
  {
    id: 280,
    original: "Neck stretch (right)",
    german: "Nackendehnung (rechts)",
    belongs_to_id: 279,
    categories: ["cooldown"],
    muscles: ["neck"]
  },
  {
    id: 281,
    original: "Piriformis stretch (left)",
    german: "Piriformis-Dehnung (links)",
    belongs_to_id: 282,
    categories: ["cooldown"],
    muscles: ["glutes"]
  },
  {
    id: 282,
    original: "Piriformis stretch (right)",
    german: "Piriformis-Dehnung (rechts)",
    belongs_to_id: 281,
    categories: ["cooldown"],
    muscles: ["glutes"]
  },
  {
    id: 283,
    original: "Quad stretch (left)",
    german: "Quadrizeps-Dehnung (links)",
    belongs_to_id: 284,
    categories: ["cooldown"],
    muscles: ["quads"]
  },
  {
    id: 284,
    original: "Quad stretch (right)",
    german: "Quadrizeps-Dehnung (rechts)",
    belongs_to_id: 283,
    categories: ["cooldown"],
    muscles: ["quads"]
  },
  {
    id: 285,
    original: "Scapular push ups",
    german: "Skapula Push-Ups",
    categories: ["warmup"],
    muscles: ["shoulders"]
  },
  {
    id: 286,
    original: "Shoulder rolls (backward)",
    german: "Schulterkreisen (rückwärts)",
    categories: ["warmup"],
    belongs_to_id: 287,
    muscles: ["shoulders"]
  },
  {
    id: 287,
    original: "Shoulder rolls (forward)",
    german: "Schulterkreisen (vorwärts)",
    categories: ["warmup"],
    belongs_to_id: 286,
    muscles: ["shoulders"]
  },
  {
    id: 288,
    original: "Side bend (left)",
    german: "Seitliche Neigung (links)",
    belongs_to_id: 289,
    categories: ["cooldown"],
    muscles: ["obliques"]
  },
  {
    id: 289,
    original: "Side bend (right)",
    german: "Seitliche Neigung (rechts)",
    belongs_to_id: 288,
    categories: ["cooldown"],
    muscles: ["obliques"]
  },
  {
    id: 290,
    original: "Side to side lunges",
    german: "Seitliche Ausfallschritte",
    categories: ["warmup"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 291,
    original: "Sideways leg swings (left)",
    german: "Seitliche Beinschwünge (links)",
    belongs_to_id: 292,
    categories: ["warmup"],
    muscles: ["quads"]
  },
  {
    id: 292,
    original: "Sideways leg swings (right)",
    german: "Seitliche Beinschwünge (rechts)",
    belongs_to_id: 291,
    categories: ["warmup"],
    muscles: ["quads"]
  },
  {
    id: 293,
    original: "Spine twist (left)",
    german: "Wirbelsäulendrehung (links)",
    belongs_to_id: 294,
    categories: ["cooldown"],
    muscles: ["back"]
  },
  {
    id: 294,
    original: "Spine twist (right)",
    german: "Wirbelsäulendrehung (rechts)",
    belongs_to_id: 293,
    categories: ["cooldown"],
    muscles: ["back"]
  },
  {
    id: 295,
    original: "Standing calf stretch (left)",
    german: "Stehende Wadenstretch (links)",
    belongs_to_id: 296,
    categories: ["cooldown"],
    muscles: ["calves"]
  },
  {
    id: 296,
    original: "Standing calf stretch (right)",
    german: "Stehende Wadenstretch (rechts)",
    belongs_to_id: 295,
    categories: ["cooldown"],
    muscles: ["calves"]
  },
  {
    id: 297,
    original: "Straight arm stretch (left)",
    german: "Streckung des gestreckten Arms (links)",
    belongs_to_id: 298,
    categories: ["cooldown"],
    muscles: ["forearms"]
  },
  {
    id: 298,
    original: "Straight arm stretch (right)",
    german: "Streckung des gestreckten Arms (rechts)",
    belongs_to_id: 297,
    categories: ["cooldown"],
    muscles: ["forearms"]
  },
  {
    id: 299,
    original: "Straight leg stretch",
    german: "Streckung des gestreckten Beines",
    categories: ["cooldown"],
    muscles: ["quads"]
  },
  {
    id: 300,
    original: "Thread the needle (left)",
    german: "Die Nadel einfädeln (links)",
    belongs_to_id: 301,
    categories: ["cooldown"],
    muscles: ["hands"]
  },
  {
    id: 301,
    original: "Thread the needle (right)",
    german: "Die Nadel einfädeln (rechts)",
    belongs_to_id: 300,
    categories: ["cooldown"],
    muscles: ["hands"]
  },
  {
    id: 302,
    original: "Tricep stretch (left)",
    german: "Trizepsdehnung (links)",
    belongs_to_id: 303,
    categories: ["cooldown"],
    muscles: ["triceps"]
  },
  {
    id: 303,
    original: "Tricep stretch (right)",
    german: "Trizepsdehnung (rechts)",
    belongs_to_id: 302,
    categories: ["cooldown"],
    muscles: ["triceps"]
  },
  {
    id: 304,
    original: "Wide legged straddle stretch",
    german: "Grätschdehnung mit breiten Beinen",
    categories: ["cooldown"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 305,
    original: "Wrist circles (clockwise)",
    german: "Handgelenkkreisen (im Uhrzeigersinn)",
    belongs_to_id: 306,
    categories: ["warmup", "cooldown"],
    muscles: ["forearms"]
  },
  {
    id: 306,
    original: "Wrist circles (counter clockwise)",
    german: "Handgelenkkreisen (gegen den Uhrzeigersinn)",
    belongs_to_id: 305,
    categories: ["warmup", "cooldown"],
    muscles: ["forearms"]
  },
];

export const bandedExercises = [
  {
    id: 307,
    original: "Alternating bent over rows",
    german: "Wechselnde vorgebeugte Rudern",
    categories: ["banded"],
    muscles: ["back", "biceps"]
  },
  {
    id: 308,
    original: "Back squats",
    german: "Kniebeugen mit der Langhantel auf dem Rücken",
    categories: ["banded"],
    muscles: ["quads", "glutes", "lower back"]
  },
  {
    id: 309,
    original: "Band pull apart",
    german: "Band Pull Apart",
    categories: ["warmup", "cooldown"],
    muscles: ["shoulders", "upper back"]
  },
  {
    id: 310,
    original: "Bent over rows",
    german: "Vorgebeugtes Rudern",
    categories: ["banded"],
    muscles: ["back", "biceps"]
  },
  {
    id: 311,
    original: "Bicep curls (left)",
    german: "Bizeps Curls (links)",
    belongs_to_id: 312,
    categories: ["banded"],
    muscles: ["biceps"]
  },
  {
    id: 312,
    original: "Bicep curls (right)",
    german: "Bizeps Curls (rechts)",
    belongs_to_id: 311,
    categories: ["banded"],
    muscles: ["biceps"]
  },
  {
    id: 313,
    original: "Bicep curls",
    german: "Bizeps-Curls",
    categories: ["banded"],
    muscles: ["biceps"]
  },
  {
    id: 314,
    original: "Curtsy lunges (left)",
    german: "Hofknicks-Ausfallschritte (links)",
    belongs_to_id: 315,
    categories: ["banded", "warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 315,
    original: "Curtsy lunges (right)",
    german: "Hofknicks-Ausfallschritte (rechts)",
    belongs_to_id: 314,
    categories: ["banded", "warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 316,
    original: "Deadlift to bent over row",
    german: "Kreuzheben zu vorgebeugtem Rudern",
    categories: ["banded"],
    muscles: ["quads", "glutes", "lower back"]
  },
  {
    id: 317,
    original: "External rotations",
    german: "Äußere Rotationen",
    categories: ["banded", "warmup", "cooldown"],
    muscles: ["rotator cuff"]
  },
  {
    id: 318,
    original: "Floor chest flyes",
    german: "Boden Brust Fliegen",
    categories: ["banded"],
    muscles: ["chest", "shoulders"]
  },
  {
    id: 319,
    original: "Floor chest presses",
    german: "Boden Brustdrücken",
    categories: ["banded"],
    muscles: ["chest", "shoulders", "triceps"]
  },
  {
    id: 320,
    original: "Floor diamond presses",
    german: "Boden Diamantdrücken",
    categories: ["banded"],
    muscles: ["chest", "shoulders", "triceps"]
  },
  {
    id: 321,
    original: "Front shoulder raises",
    german: "Vordere Schulterheben",
    categories: ["banded"],
    muscles: ["front deltoids"]
  },
  {
    id: 322,
    original: "Front squats",
    german: "Front Squats",
    categories: ["banded"],
    muscles: ["quads", "glutes", "core"]
  },
  {
    id: 323,
    original: "Glute bridge with chest presses",
    german: "Glute Bridge mit Brustdrücken",
    categories: ["banded"],
    muscles: ["glutes", "hamstrings", "lower back"]
  },
  {
    id: 324,
    original: "Glute bridges",
    german: "Glute Bridges",
    categories: ["banded", "warmup", "cooldown"],
    muscles: ["glutes", "hamstrings", "lower back"]
  },
  {
    id: 325,
    original: "Glute kickbacks (left)",
    german: "Glute Kickbacks (links)",
    belongs_to_id: 326,
    categories: ["banded"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 326,
    original: "Glute kickbacks (right)",
    german: "Glute Kickbacks (rechts)",
    belongs_to_id: 325,
    categories: ["banded"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 327,
    original: "Good mornings",
    german: "Good Mornings",
    categories: ["banded"],
    muscles: ["back", "biceps"]
  },
  {
    id: 328,
    original: "Hammer curls",
    german: "Hammer Curls",
    categories: ["banded"],
    muscles: ["biceps"]
  },
  {
    id: 329,
    original: "Lateral shoulder raises (left)",
    german: "Seitliches Schulterheben (links)",
    belongs_to_id: 330,
    categories: ["banded"],
    muscles: ["lateral deltoids"]
  },
  {
    id: 330,
    original: "Lateral shoulder raises (right)",
    german: "Seitliches Schulterheben (rechts)",
    belongs_to_id: 329,
    categories: ["banded"],
    muscles: ["lateral deltoids"]
  },
  {
    id: 331,
    original: "Lateral shoulder raises",
    german: "Seitliches Schulterheben",
    categories: ["banded"],
    muscles: ["lateral deltoids"]
  },
  {
    id: 332,
    original: "Leg extensions",
    german: "Beinstrecken",
    categories: ["banded"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 333,
    original: "Overhead sit ups",
    german: "Overhead Sit-ups",
    categories: ["banded"],
    muscles: ["shoulders", "triceps"]
  },
  {
    id: 334,
    original: "Overhead squats",
    german: "Overhead Squats",
    categories: ["banded"],
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 335,
    original: "Plank T rotations",
    german: "Plank T-Rotationen",
    categories: ["banded", "warmup"],
    muscles: ["core", "shoulders", "obliques"]
  },
  {
    id: 336,
    original: "Reclined in and outs",
    german: "Rücklings Ein- und Ausklappen",
    categories: ["banded"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 337,
    original: "Reclined leg presses",
    german: "Beinpressen im Liegen",
    categories: ["banded"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 338,
    original: "Reverse flyes (left)",
    german: "Reverse Flyes (links)",
    belongs_to_id: 339,
    categories: ["banded", "warmup", "cooldown"],
    muscles: ["back", "biceps"]
  },
  {
    id: 339,
    original: "Reverse flyes (right)",
    german: "Reverse Flyes (rechts)",
    belongs_to_id: 338,
    categories: ["banded", "warmup", "cooldown"],
    muscles: ["back", "biceps"]
  },
  {
    id: 340,
    original: "Reverse lunge to front raises (left)",
    german: "Rückwärts-Ausfallschritt mit Front-Armheben (links)",
    belongs_to_id: 341,
    categories: ["banded", "warmup"],
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 341,
    original: "Reverse lunge to front raises (right)",
    german: "Rückwärts-Ausfallschritt mit Frontalheben (rechts)",
    belongs_to_id: 340,
    categories: ["banded", "warmup"],
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 342,
    original: "Reverse lunge to lateral raises (left)",
    german: "Rückwärts-Ausfallschritt mit seitlichem Heben (links)",
    belongs_to_id: 343,
    categories: ["banded", "warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 343,
    original: "Reverse lunge to lateral raises (right)",
    german: "Rückwärts-Ausfallschritt mit seitlichem Heben (rechts)",
    belongs_to_id: 342,
    categories: ["banded", "warmup"],
    muscles: ["quads", "glutes", "hip flexors"]
  },
  {
    id: 344,
    original: "Reverse lunges (left)",
    german: "Reverse Lunges (links)",
    belongs_to_id: 345,
    categories: ["banded", "warmup"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 345,
    original: "Reverse lunges (right)",
    german: "Ausfallschritte rückwärts (rechts)",
    belongs_to_id: 344,
    categories: ["banded", "warmup"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 346,
    original: "Romanian deadlifts",
    german: "Rumänisches Kreuzheben",
    categories: ["banded"],
    muscles: ["hamstrings", "glutes", "lower back"]
  },
  {
    id: 347,
    original: "Russian twists",
    german: "Russische Drehungen",
    categories: ["banded"],
    muscles: ["core", "obliques"]
  },
  {
    id: 348,
    original: "Seated rows",
    german: "Sitzendes Rudern",
    categories: ["banded"],
    muscles: ["back", "biceps"]
  },
  {
    id: 349,
    original: "Shoulder dislocates",
    german: "Schulterdislokationen",
    categories: ["banded", "warmup", "cooldown"],
    muscles: ["shoulders", "triceps"]
  },
  {
    id: 350,
    original: "Shoulder presses (left)",
    german: "Schulterdrücken (links)",
    belongs_to_id: 351,
    categories: ["banded"],
    muscles: ["shoulders"]
  },
  {
    id: 351,
    original: "Shoulder presses (right)",
    german: "Schulterdrücken (rechts)",
    belongs_to_id: 350,
    categories: ["banded"],
    muscles: ["shoulders"]
  },
  {
    id: 352,
    original: "Shoulder presses",
    german: "Schulterdrücken",
    categories: ["banded"],
    muscles: ["shoulders", "triceps"]
  },
  {
    id: 353,
    original: "Side to side lunges",
    german: "Seitliche Ausfallschritte",
    categories: ["banded", "warmup"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 354,
    original: "Side to side steps",
    german: "Seitliche Schritte",
    categories: ["banded", "warmup"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 355,
    original: "Single leg deadlifts (left)",
    german: "Einbeiniges Kreuzheben (links)",
    belongs_to_id: 356,
    categories: ["banded"],
    muscles: ["hamstrings", "glutes", "lower back"]
  },
  {
    id: 356,
    original: "Single leg deadlifts (right)",
    german: "Einbeinige Kreuzheben (rechts)",
    belongs_to_id: 355,
    categories: ["banded"],
    muscles: ["hamstrings", "glutes", "lower back"]
  },
  {
    id: 357,
    original: "Split squats (left)",
    german: "Split Squats (links)",
    belongs_to_id: 358,
    categories: ["banded"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 358,
    original: "Split squats (right)",
    german: "Split Squats (rechts)",
    belongs_to_id: 357,
    categories: ["banded"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 359,
    original: "Staggered deadlifts (left)",
    german: "Versetzte Kreuzheben (links)",
    belongs_to_id: 360,
    categories: ["banded"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 360,
    original: "Staggered deadlifts (right)",
    german: "Versetzte Kreuzheben (rechts)",
    belongs_to_id: 359,
    categories: ["banded"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 361,
    original: "Static lunge tricep extensions (left)",
    german: "Statische Ausfallschritte mit Trizepsstreckungen (links)",
    belongs_to_id: 362,
    categories: ["banded"],
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 362,
    original: "Static lunge tricep extensions (right)",
    german: "Statische Ausfallschritte mit Trizepsstreckungen (rechts)",
    belongs_to_id: 361,
    categories: ["banded"],
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 363,
    original: "Sumo squat to upright rows",
    german: "Sumo Kniebeugen mit aufrechtem Rudern",
    categories: ["banded"],
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 364,
    original: "Sumo squats",
    german: "Sumo Kniebeugen",
    categories: ["banded"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 365,
    original: "Thrusters",
    german: "Thrusters",
    categories: ["banded"],
    muscles: ["quads", "glutes", "shoulders"]
  },
  {
    id: 366,
    original: "Tricep extensions",
    german: "Trizeps Extensions",
    categories: ["banded"],
    muscles: ["triceps"]
  },
  {
    id: 367,
    original: "Tricep kickbacks (left)",
    german: "Trizeps Kickbacks (links)",
    belongs_to_id: 368,
    categories: ["banded"],
    muscles: ["triceps"]
  },
  {
    id: 368,
    original: "Tricep kickbacks (right)",
    german: "Trizeps Kickbacks (rechts)",
    belongs_to_id: 367,
    categories: ["banded"],
    muscles: ["triceps"]
  },
  {
    id: 369,
    original: "Upright rows",
    german: "Aufrechtes Rudern",
    categories: ["banded"],
    muscles: ["shoulders", "triceps"]
  },
];

export const pilatesExercises = [
  {
    id: 370,
    original: "Arm curls",
    german: "Arm Curls Pilates",
    categories: ["pilates"],
    muscles: ["biceps"]
  },
  {
    id: 371,
    original: "Arm diagonal presses",
    german: "Diagonale Arm-Pressen Pilates",
    categories: ["pilates"],
    muscles: ["shoulders", "triceps"]
  },
  {
    id: 372,
    original: "Arm lateral extensions",
    german: "Seitliche Armstreckungen Pilates",
    categories: ["pilates"],
    muscles: ["shoulders"]
  },
  {
    id: 373,
    original: "Arm openers",
    german: "Armöffner Pilates",
    categories: ["pilates"],
    muscles: ["shoulders", "chest"]
  },
  {
    id: 374,
    original: "Arm pulses",
    german: "Arm-Pulsationen Pilates",
    categories: ["pilates"],
    muscles: ["shoulders"]
  },
  {
    id: 375,
    original: "Bent over arm extensions",
    german: "Überbeugte Armstreckungen Pilates",
    categories: ["pilates"],
    muscles: ["triceps"]
  },
  {
    id: 376,
    original: "Bent over arm raises",
    german: "Vorgebeugtes Armheben Pilates",
    categories: ["pilates"],
    muscles: ["shoulders", "back"]
  },
  {
    id: 377,
    original: "Bent over arm rows",
    german: "Vorgebeugtes Rudern mit den Armen Pilates",
    categories: ["pilates"],
    muscles: ["back", "biceps"]
  },
  {
    id: 378,
    original: "Bicycles",
    german: "Bicycles Pilates",
    categories: ["pilates"],
    muscles: ["core", "obliques"]
  },
  {
    id: 379,
    original: "Bird dog crunches (left)",
    german: "Bird Dog Crunches (links) Pilates",
    belongs_to_id: 380,
    categories: ["pilates"],
    muscles: ["core", "glutes"]
  },
  {
    id: 380,
    original: "Bird dog crunches (right)",
    german: "Bird Dog Crunches (rechts) Pilates",
    belongs_to_id: 379,
    categories: ["pilates"],
    muscles: ["core", "glutes"]
  },
  {
    id: 381,
    original: "Bird dog",
    german: "Bird Dog Pilates",
    categories: ["pilates"],
    muscles: ["core", "glutes"]
  },
  {
    id: 382,
    original: "Bridge reaches",
    german: "Brückenreichweiten Pilates",
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 383,
    original: "Bridge with overhead reach",
    german: "Brücke mit Überkopfreichweite Pilates",
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 384,
    original: "Bridges",
    german: "Bridges Pilates",
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 385,
    original: "Chair squat hold with side steps",
    german: "Stuhl-Kniebeugen-Halten mit seitlichen Schritten Pilates",
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 386,
    original: "Chair squat pulses",
    german: "Stuhl Kniebeugen Pulses Pilates",
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 387,
    original: "Chair squats to heel lifts",
    german: "Stuhlkniebeugen zu Fersenheben Pilates",
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 388,
    original: "Chair squats",
    german: "Stuhlkniebeugen Pilates",
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 389,
    original: "Clamshell to leg extension (right)",
    german: "Clamshell zur Beinstreckung (rechts) Pilates",
    belongs_to_id: 390,
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 390,
    original: "Clamshell to leg extensions (left)",
    german: "Clamshell zu Beinstreckungen (links) Pilates",
    belongs_to_id: 389,
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 391,
    original: "Clamshells (left)",
    german: "Clamshells (links) Pilates",
    belongs_to_id: 392,
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 392,
    original: "Clamshells (right)",
    german: "Clamshells (rechts) Pilates",
    belongs_to_id: 391,
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 393,
    original: "Cobra presses",
    german: "Cobra Presses Pilates",
    categories: ["pilates"],
    muscles: ["spine", "core"]
  },
  {
    id: 394,
    original: "Core twists",
    german: "Pilates Core Twists",
    categories: ["pilates"],
    muscles: ["core"]
  },
  {
    id: 395,
    original: "Cross body mountain climbers",
    german: "Cross Body Mountain Climbers Pilates",
    categories: ["pilates"],
    muscles: ["core", "glutes"]
  },
  {
    id: 396,
    original: "Dolphin planks",
    german: "Delfin-Planks Pilates",
    categories: ["pilates"],
    muscles: ["core", "obliques"]
  },
  {
    id: 397,
    original: "Donkey kick pulses (left)",
    german: "Eseltritt-Pulsationen (links) Pilates",
    belongs_to_id: 398,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 398,
    original: "Donkey kick pulses (right)",
    german: "Donkey Kick Pulses (rechts) Pilates",
    belongs_to_id: 397,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 399,
    original: "Donkey kicks (left)",
    german: "Eseltritte (links) Pilates",
    belongs_to_id: 400,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 400,
    original: "Donkey kicks (right)",
    german: "Eseltritte (rechts) Pilates",
    belongs_to_id: 399,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 401,
    original: "Double leg stretch",
    german: "Doppeltes Beinstrecken Pilates",
    categories: ["pilates"],
    muscles: ["quads"]
  },
  {
    id: 402,
    original: "Extended leg push ups (left)",
    german: "Erweiterte Bein Push-Ups (links) Pilates",
    belongs_to_id: 403,
    categories: ["pilates"],
    muscles: ["quads"]
  },
  {
    id: 403,
    original: "Extended leg push ups (right)",
    german: "Erweiterte Bein Push-Ups (rechts) Pilates",
    belongs_to_id: 402,
    categories: ["pilates"],
    muscles: ["quads"]
  },
  {
    id: 404,
    original: "Fire hydrants (left)",
    german: "Feuerwehrhydranten (links) Pilates",
    belongs_to_id: 405,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 405,
    original: "Fire hydrants (right)",
    german: "Feuerwehrhydranten (rechts) Pilates",
    belongs_to_id: 404,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 406,
    original: "Fire hydrants",
    german: "Fire Hydrants Pilates",
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 407,
    original: "Glute kickback pulses (right)",
    german: "Glute Kickback Pulses (rechts) Pilates",
    belongs_to_id: 408,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 408,
    original: "Glute bcak pulses (left)",
    german: "Pilates Glute Kickback Pulses (links)",
    belongs_to_id: 407,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 409,
    original: "Glute kickbacks (left)",
    german: "Glute Kickbacks (links) Pilates",
    belongs_to_id: 410,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 410,
    original: "Glute kickbacks (right)",
    german: "Glute Kickbacks (rechts) Pilates",
    belongs_to_id: 409,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 411,
    original: "Glute kickbacks",
    german: "Glute Kickbacks Pilates",
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 412,
    original: "Glute rainbows (left)",
    german: "Glute Rainbows (links) Pilates",
    belongs_to_id: 413,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 413,
    original: "Glute rainbows (right)",
    german: "Glute Rainbows (rechts) Pilates",
    belongs_to_id: 412,
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 414,
    original: "Hamstring curls (left)",
    german: "Hamstring Curls (links) Pilates",
    belongs_to_id: 415,
    categories: ["pilates"],
    muscles: ["hamstrings"]
  },
  {
    id: 415,
    original: "Hamstring curls (right)",
    german: "Hamstring Curls (rechts) Pilates",
    belongs_to_id: 414,
    categories: ["pilates"],
    muscles: ["hamstrings"]
  },
  {
    id: 416,
    original: "Hamstring curls",
    german: "Hamstring Curls Pilates",
    categories: ["pilates"],
    muscles: ["hamstrings"]
  },
  {
    id: 417,
    original: "Heel touches",
    german: "Fersenberhrungen Pilates",
    categories: ["pilates"],
    muscles: ["hamstrings"]
  },
  {
    id: 418,
    original: "High plank",
    german: "High Plank Pilates",
    categories: ["pilates"],
    muscles: ["core"]
  },
  {
    id: 419,
    original: "Inch worms",
    german: "Inch Worms Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs"]
  },
  {
    id: 420,
    original: "Jack knives",
    german: "Jack Knives Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs"]
  },
  {
    id: 421,
    original: "Knee tuck to leg extensions (left)",
    german: "Knee Tuck zu Beinstreckungen (links) Pilates",
    belongs_to_id: 422,
    categories: ["pilates"],
    muscles: ["quads"]
  },
  {
    id: 422,
    original: "Knee tuck to leg extensions (right)",
    german: "Kniebeugen zu Beinstreckungen (rechts) Pilates",
    belongs_to_id: 421,
    categories: ["pilates"],
    muscles: ["quads"]
  },
  {
    id: 423,
    original: "Leg lifts",
    german: "Beinheben Pilates",
    categories: ["pilates"],
    muscles: ["quads"]
  },
  {
    id: 424,
    original: "Lunge with twist (left)",
    german: "Ausfallschritt mit Drehung (links) Pilates",
    belongs_to_id: 425,
    categories: ["pilates"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 425,
    original: "Lunge with twist (right)",
    german: "Ausfallschritt mit Drehung (rechts) Pilates",
    belongs_to_id: 424,
    categories: ["pilates"],
    muscles: ["quads", "glutes"]
  },
  {
    id: 426,
    original: "Marching bridges",
    german: "Marschierende Brücken Pilates",
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 427,
    original: "Mountain climbers",
    german: "Mountain Climbers Pilates",
    categories: ["pilates"],
    muscles: ["core", "glutes"]
  },
  {
    id: 428,
    original: "Over unders",
    german: "Over Unders Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 429,
    original: "Pilates 100s",
    german: "Pilates 100s",
    categories: ["pilates"],
    muscles: ["core", "glutes"]
  },
  {
    id: 430,
    original: "Pilates lunges (left)",
    german: "Pilates Ausfallschritte (links) Pilates",
    belongs_to_id: 431,
    categories: ["pilates"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 431,
    original: "Pilates lunges (right)",
    german: "Pilates Ausfallschritte (rechts) Pilates",
    belongs_to_id: 430,
    categories: ["pilates"],
    muscles: ["quads", "glutes", "calves"]
  },
  {
    id: 432,
    original: "Pilates marches",
    german: "Pilates Märsche",
    categories: ["pilates"],
    muscles: ["core", "glutes"]
  },
  {
    id: 433,
    original: "Plank lateral toe taps",
    german: "Plank seitliche Zehentippen Pilates",
    categories: ["pilates"],
    muscles: ["core", "obliques"]
  },
  {
    id: 434,
    original: "Plank leg lifts",
    german: "Plank Beinheben Pilates",
    categories: ["pilates"],
    muscles: ["core", "glutes"]
  },
  {
    id: 435,
    original: "Plank to pike",
    german: "Plank to Pike Pilates",
    categories: ["pilates"],
    muscles: ["core", "glutes"]
  },
  {
    id: 436,
    original: "Plie heel lifts",
    german: "Plie Fersenheben Pilates",
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 437,
    original: "Plie pulses",
    german: "Plie Pulses Pilates",
    categories: ["pilates"],
    muscles: ["glutes"]
  },
  {
    id: 438,
    original: "Plie side bends",
    german: "Plie-Seitbeugen Pilates",
    categories: ["pilates"],
    muscles: ["obliques"]
  },
  {
    id: 439,
    original: "Plie with diagonal arm extensions",
    german: "Plie mit diagonalen Armstreckungen Pilates",
    categories: ["pilates"],
    muscles: ["shoulders", "triceps"]
  },
  {
    id: 440,
    original: "Plie with lateral extensions",
    german: "Plie mit seitlichen Erweiterungen Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 441,
    original: "Plies",
    german: "Plies Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 442,
    original: "Pull down to openers",
    german: "Pull Down zu Openers Pilates",
    categories: ["pilates"],
    muscles: ["core", "shoulders"]
  },
  {
    id: 443,
    original: "Pull downs",
    german: "Pull Downs Pilates",
    categories: ["pilates"],
    muscles: ["core", "shoulders"]
  },
  {
    id: 444,
    original: "Reverse lunge to knee drive (left)",
    german: "Rückwärts-Ausfallschritt mit Knieheben (links) Pilates",
    belongs_to_id: 445,
    categories: ["pilates"],
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 445,
    original: "Reverse lunge to knee drives (right)",
    german: "Rückwärts-Ausfallschritt zu Knieantrieben (rechts) Pilates",
    belongs_to_id: 444,
    categories: ["pilates"],
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 446,
    original: "Reverse table tops",
    german: "Reverse Table Tops Pilates",
    categories: ["pilates"],
    muscles: ["core", "shoulders"]
  },
  {
    id: 447,
    original: "Roll ups",
    german: "Roll Ups Pilates",
    categories: ["pilates"],
    muscles: ["core", "shoulders"]
  },
  {
    id: 448,
    original: "Scissor kicks",
    german: "Schere Kicks Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 449,
    original: "Seated knee to elbows",
    german: "Sitzende Knie-zu-Ellenbogen Pilates",
    categories: ["pilates"],
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 450,
    original: "Side kneeling leg lifts (left)",
    german: "Seitliches Kniebeugen Beinheben (links) Pilates",
    belongs_to_id: 451,
    categories: ["pilates"],
    muscles: ["quads", "glutes", "inner thighs"]
  },
  {
    id: 451,
    original: "Side kneeling leg lifts (right)",
    german: "Seitliches Kniebeugen Beinheben (rechts) Pilates",
    belongs_to_id: 450,
    categories: ["pilates"],
    muscles: ["quads", "glutes", "inner thighs"]
  },
  {
    id: 452,
    original: "Side lying leg lifts (right)",
    german: "Seitliches Beinheben im Liegen (rechts) Pilates",
    belongs_to_id: 453,
    categories: ["pilates"],
    muscles: ["glutes", "inner thighs"]
  },
  {
    id: 453,
    original: "Side lying leg raises (left)",
    german: "Seitliches Beinheben im Liegen (links) Pilates",
    belongs_to_id: 452,
    categories: ["pilates"],
    muscles: ["glutes", "inner thighs"]
  },
  {
    id: 454,
    original: "Side plank dips (left)",
    german: "Seitenplanken-Dips (links) Pilates",
    belongs_to_id: 455,
    categories: ["pilates"],
    muscles: ["core", "obliques"]
  },
  {
    id: 455,
    original: "Side plank dips (right)",
    german: "Seitliche Plank Dips (rechts) Pilates",
    belongs_to_id: 454,
    categories: ["pilates"],
    muscles: ["core", "obliques"]
  },
  {
    id: 456,
    original: "Side plank rotations (left)",
    german: "Seitenplanken Rotationen (links) Pilates",
    belongs_to_id: 457,
    categories: ["pilates"],
    muscles: ["core", "obliques"]
  },
  {
    id: 457,
    original: "Side plank rotations (right)",
    german: "Seitenplanken Rotationen (rechts) Pilates",
    belongs_to_id: 456,
    categories: ["pilates"],
    muscles: ["core", "obliques"]
  },
  {
    id: 458,
    original: "Single leg bridges (left)",
    german: "Einbeinige Brücken (links) Pilates",
    belongs_to_id: 459,
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 459,
    original: "Single leg bridges (right)",
    german: "Einbeinige Brücken (rechts) Pilates",
    belongs_to_id: 458,
    categories: ["pilates"],
    muscles: ["glutes", "hamstrings"]
  },
  {
    id: 460,
    original: "Single leg reverse table tops (left)",
    german: "Einzelfuß umgekehrte Tischplatten (links) Pilates",
    belongs_to_id: 461,
    categories: ["pilates"],
    muscles: ["glutes", "inner thighs"]
  },
  {
    id: 461,
    original: "Single leg table tops (right)",
    german: "Einbeinige Tischplatten (rechts) Pilates",
    belongs_to_id: 460,
    categories: ["pilates"],
    muscles: ["glutes", "inner thighs"]
  },
  {
    id: 462,
    original: "Single leg stretches",
    german: "Einfaches Beinstrecken Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 463,
    original: "Single leg tricep dips (right)",
    german: "Einbeinige Trizeps-Dips (rechts) Pilates",
    belongs_to_id: 464,
    categories: ["pilates"],
    muscles: ["triceps"]
  },
  {
    id: 464,
    original: "Single leg tricep extensions (left)",
    german: "Einfache Trizeps-Extensions (links) Pilates",
    belongs_to_id: 463,
    categories: ["pilates"],
    muscles: ["triceps"]
  },
  {
    id: 465,
    original: "Standing glute kickbacks (left)",
    german: "Stehende Glute Kickbacks (links) Pilates",
    belongs_to_id: 466,
    categories: ["pilates"],
    muscles: ["glutes", "inner thighs"]
  },
  {
    id: 466,
    original: "Standing glute kickbacks (right)",
    german: "Stehende Glute Kickbacks (rechts) Pilates",
    belongs_to_id: 465,
    categories: ["pilates"],
    muscles: ["glutes", "inner thighs"]
  },
  {
    id: 467,
    original: "Standing knee to elbows",
    german: "Stehendes Knie zu Ellbogen Pilates",
    categories: ["pilates"],
    muscles: ["quads", "glutes", "biceps"]
  },
  {
    id: 468,
    original: "Superman to pull downs",
    german: "Superman zu Pull Downs Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes", "biceps"]
  },
  {
    id: 469,
    original: "Supermans",
    german: "Supermans Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes", "biceps"]
  },
  {
    id: 470,
    original: "Swimmers",
    german: "Swimmers Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes"]
  },
  {
    id: 471,
    original: "Three legged dog (left)",
    german: "Dreibeiniger Hund (links) Pilates",
    belongs_to_id: 472,
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes", "hamstrings"]
  },
  {
    id: 472,
    original: "Three legged dog (right)",
    german: "Dreibeiniger Hund (rechts) Pilates",
    belongs_to_id: 471,
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes", "hamstrings"]
  },
  {
    id: 473,
    original: "Three legged dogs",
    german: "Dreibeinige Hunde Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs", "glutes", "hamstrings"]
  },
  {
    id: 474,
    original: "Toe taps",
    german: "Toe Taps Pilates",
    categories: ["pilates"],
    muscles: ["inner thighs"]
  },
  {
    id: 475,
    original: "Tricep dips",
    german: "Trizeps Dips Pilates",
    categories: ["pilates"],
    muscles: ["triceps"]
  },
  {
    id: 476,
    original: "Tricep presses (left)",
    german: "Trizepsdrücken (links) Pilates",
    belongs_to_id: 477,
    categories: ["pilates"],
    muscles: ["shoulders"]
  },
  {
    id: 477,
    original: "Tricep presses (right)",
    german: "Trizepsdrücken (rechts) Pilates",
    belongs_to_id: 476,
    categories: ["pilates"],
    muscles: ["shoulders"]
  },
];