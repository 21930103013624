<template>
  <div v-cloak>
    <div class="row">
      <div class="submenu col-xl-2 d-none d-xl-block">
        <div class="width-wrap sticky-top">
          <div class="row top-spacer-25">
            <div class="col-lg-12">
              <a href="/#/training/dashboard">
                <div class="menu-entry">Dashboard</div></a
              >
            </div>
            <div class="col-lg-12">
              <a href="/#/training/kalender">
                <div class="menu-entry">Kalender</div></a
              >
            </div>

            <div class="col-lg-12">
              <a href="/#/training/liste">
                <div class="menu-entry">Trainingspläne</div>
              </a>
            </div>
            <div class="col-lg-12">
              <a href="/#/training/wissen">
                <div class="menu-entry">Wissen</div>
              </a>
            </div>
            <div class="col-lg-12">
              <a href="/#/training/kompakt">
                <div class="menu-entry active_submenu">Kompaktübersicht</div></a
              >
            </div>

            <div class="col-lg-12">
              <a href="/#/training/upload">
                <div class="menu-entry">Upload</div></a
              >
            </div>

            <div class="col-lg-12">
              <a href="/#/training/vorlagen">
                <div class="menu-entry">Workout-Vorlagen</div></a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-10">
        <div
          class="centered week light-shadow"
          style="padding-bottom: 35px; margin: 0"
        >
          <button
            id="scroll-bottom"
            style="width: 250px"
            @click="loadCalendarEntriesBottom"
          >
            Nächste Wochen laden
          </button>
        </div>
        <div
          class="spinner-border text-primary top-spacer-25"
          role="status"
          v-if="!loaded"
        ></div>
        <div
          class="row week top-spacer-25 light-shadow"
          style="padding-top: 15px; margin-left: 0; margin-right: 0"
          v-for="week in loadedWeeks.slice().reverse()"
          :key="week"
        >
          <div class="col-lg-1 col-md-1 col-12">
            <strong>
              {{ getDayOfWeek(week, 1) | formatDate }} bis
              {{ getDayOfWeek(week, 7) | formatDate }}</strong
            >
          </div>
          <div class="col-12 d-block d-md-none"><br /></div>

          <div class="col-lg-1 col-md-2 col-4">
            <strong>Zeit</strong><br />
            <!--
              <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).swim+getWeeklyIsValues(week).bike+getWeeklyIsValues(week).run+getWeeklyIsValues(week).strength,  
              getWeeklyShouldValues(week).swim+getWeeklyShouldValues(week).bike+getWeeklyShouldValues(week).run+getWeeklyShouldValues(week).strength)">-->
            {{
              formattedTimeSlash(
                getWeeklyIsValues(week).swim +
                  getWeeklyIsValues(week).bike +
                  getWeeklyIsValues(week).run +
                  getWeeklyIsValues(week).strength
              )
            }}
            <!--/{{
              formatWorkoutTimes(
                getWeeklyShouldValues(week).swim +
                  getWeeklyShouldValues(week).bike +
                  getWeeklyShouldValues(week).run +
                  getWeeklyShouldValues(week).strength
              )
                 }}-->
            <!--</span>-->
          </div>
          <div class="col-lg-1 col-md-2 col-4">
            <strong>Distanz</strong><br />

            {{
              Math.round(
                (getWeeklyDistanceValues(week).swim +
                  getWeeklyDistanceValues(week).bike +
                  getWeeklyDistanceValues(week).run +
                  getWeeklyDistanceValues(week).strength) *
                  10
              ) / 10
            }}km
          </div>

          <div class="col-lg-1 col-md-2 col-4">
            <strong>Load</strong><br />

            <span
              v-bind:style="
                'font-size:' +
                Math.max(12, Math.sqrt(getWeeklyIsValues(week).pss * 2) / 2) +
                'px'
              "
            >
              {{ getWeeklyIsValues(week).pss }}
            </span>
          </div>

          <div class="col-12 d-block d-md-none"><br /></div>

          <div class="col-lg-1 col-md-2 col-3">
            <strong>Schwimmen</strong><br />
            <!-- <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).swim, getWeeklyShouldValues(week).swim)">-->
            {{ formattedTimeSlash(getWeeklyIsValues(week).swim) }}<br />
            {{ getWeeklyDistanceValues(week).swim }}km
          </div>
          <div class="col-lg-1 col-md-2 col-3">
            <strong>Rad</strong><br />
            <!-- <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).bike, getWeeklyShouldValues(week).bike)">-->
            {{ formattedTimeSlash(getWeeklyIsValues(week).bike) }}<br />
            {{ getWeeklyDistanceValues(week).bike }}km
          </div>
          <div class="col-lg-1 col-md-2 col-3">
            <strong>Laufen</strong><br />
            <!--  <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).run, getWeeklyShouldValues(week).run)">-->
            {{ formattedTimeSlash(getWeeklyIsValues(week).run) }}<br />
            {{ getWeeklyDistanceValues(week).run }}km
          </div>

          <div class="col-lg-1 col-md-2 col-3">
            <strong>Athletik</strong><br />
            <!-- <span v-bind:style="'color:'+colorIsShould(getWeeklyIsValues(week).strength, getWeeklyShouldValues(week).strength)">-->
            {{ formattedTimeSlash(getWeeklyIsValues(week).strength) }}
          </div>

          <div class="col-12 d-block d-md-none"><br /></div>

          <div class="col-lg-2 col-md-1 col-12">
            <strong>Intensitätsverteilung</strong>
            <br />
            <div
              class="progress"
              style="margin-top: 6px"
              v-if="
                getWeeklyIntensityValues(week).time_hit +
                  getWeeklyIntensityValues(week).time_mit +
                  getWeeklyIntensityValues(week).time_lit >
                0
              "
            >
              <div
                class="progress-bar bg-success"
                role="progressbar"
                v-bind:style="
                  'width:' +
                  timePercentage(
                    getWeeklyIntensityValues(week).time_lit,
                    getWeeklyIntensityValues(week).time_hit +
                      getWeeklyIntensityValues(week).time_mit +
                      getWeeklyIntensityValues(week).time_lit
                  ) +
                  '%'
                "
                v-bind:aria-valuenow="
                  timePercentage(
                    getWeeklyIntensityValues(week).time_lit,
                    getWeeklyIntensityValues(week).time_hit +
                      getWeeklyIntensityValues(week).time_mit +
                      getWeeklyIntensityValues(week).time_lit
                  )
                "
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div
                class="progress-bar bg-info"
                role="progressbar"
                v-bind:style="
                  'background-color: rgb(232, 126, 4)!important;' +
                  'width:' +
                  timePercentage(
                    getWeeklyIntensityValues(week).time_mit,
                    getWeeklyIntensityValues(week).time_hit +
                      getWeeklyIntensityValues(week).time_mit +
                      getWeeklyIntensityValues(week).time_lit
                  ) +
                  '%'
                "
                v-bind:aria-valuenow="
                  timePercentage(
                    getWeeklyIntensityValues(week).time_mit,
                    getWeeklyIntensityValues(week).time_hit +
                      getWeeklyIntensityValues(week).time_mit +
                      getWeeklyIntensityValues(week).time_lit
                  )
                "
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div
                class="progress-bar bg-danger"
                role="progressbar"
                v-bind:style="
                  'width:' +
                  timePercentage(
                    getWeeklyIntensityValues(week).time_hit,
                    getWeeklyIntensityValues(week).time_hit +
                      getWeeklyIntensityValues(week).time_mit +
                      getWeeklyIntensityValues(week).time_lit
                  ) +
                  '%'
                "
                v-bind:aria-valuenow="
                  timePercentage(
                    getWeeklyIntensityValues(week).time_hit,
                    getWeeklyIntensityValues(week).time_hit +
                      getWeeklyIntensityValues(week).time_mit +
                      getWeeklyIntensityValues(week).time_lit
                  )
                "
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="col-lg-2">
            <button
              class="secondary"
              style="padding: 7px 15px; font-size: 14px; margin-top: 15px"
              @click="
                $router.push({
                  name: 'AuswertungPage',
                  query: {
                    from: getDayOfWeek(week, 1).startOf('day').unix(),
                    until: getDayOfWeek(week, 7).endOf('day').unix(),
                  },
                  params: { page: 'workouts' },
                })
              "
            >
              Einheiten ansehen
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div
              class="spinner-border text-primary top-spacer-25"
              role="status"
              v-if="!loaded"
              style="margin-bottom: 50px"
            ></div>
            <div
              class="centered week light-shadow"
              style="padding-bottom: 35px; margin: 0"
            >
              <button
                id="scroll-bottom"
                @click="loadCalendarEntriesTop"
                style="width: 250px"
              >
                Vorherige Wochen laden
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="top-spacer-50"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CompactCalendar",
  components: {},
  props: {
    msg: String,
  },
  data: function () {
    return {
      loaded: false,
      newWorkoutDate: undefined, // fuer WorkoutBuilder
      newWorkoutDatePicker: undefined, // Fuer Vorlagen-Picker
      selectedCalendarWorkout: undefined,
      workouts: [],
      weekdays: [
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag",
      ],
      weekLoadingBlocked: false,
      loadedWeeks: [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0],
      lastScroll: 0,
      thresholds: undefined,
      swimThreshold: 0,
      runThreshold: 0,
      ftp: 0,
      thresholdKey: 0,
      weeklyShould: [],
      // FIT UPLOAD
      fitFile: undefined,
      uploadFinished: false,
      uploadError: false,
      uploadFinishedLoader: true,
      showDropzone: false,
      totalFilesToUpload: 0,
      filesUploaded: 0,
      files: undefined,
      fileUploadErrors: 0,
      scrollLock: false,
    };
  },

  methods: {
    timePercentage(seconds, totalTime) {
      if (totalTime > 0) {
        return (100 / totalTime) * seconds;
      } else return 0;
    },
    formattedTimeSlash(seconds) {
      let days = 0;
      let ret = "";
      let tmpSeconds = seconds;
      while (tmpSeconds > 24 * 60 * 60) {
        days += 1;
        tmpSeconds -= 24 * 60 * 60;
      }
      if (days > 0) {
        ret += days + "/";
      }

      return ret + this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },

    formatWorkoutTimes(seconds) {
      return this.$moment().startOf("day").seconds(seconds).format("H:mm:ss");
    },
    loadCalendarEntriesBottom() {
      if (!this.weekLoadingBlocked) {
        this.weekLoadingBlocked = true;

        this.loadedWeeks.shift();
        this.loadedWeeks.shift();
        this.loadedWeeks.shift();
        this.loadedWeeks.shift();
        this.loadedWeeks.shift();

        this.loadedWeeks.push(
          this.loadedWeeks[this.loadedWeeks.length - 1] + 1
        );
        this.loadedWeeks.push(
          this.loadedWeeks[this.loadedWeeks.length - 1] + 1
        );
        this.loadedWeeks.push(
          this.loadedWeeks[this.loadedWeeks.length - 1] + 1
        );
        this.loadedWeeks.push(
          this.loadedWeeks[this.loadedWeeks.length - 1] + 1
        );
        this.loadedWeeks.push(
          this.loadedWeeks[this.loadedWeeks.length - 1] + 1
        );

        this.updateData();
      }
    },
    loadCalendarEntriesTop() {
      this.loaded = false;
      if (!this.weekLoadingBlocked) {
        this.weekLoadingBlocked = true;
        this.loadedWeeks.pop();
        this.loadedWeeks.pop();
        this.loadedWeeks.pop();
        this.loadedWeeks.pop();
        this.loadedWeeks.pop();

        this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);
        this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);
        this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);
        this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);
        this.loadedWeeks.unshift(this.loadedWeeks[0] - 1);

        this.updateData();
      }
    },

    getDayOfWeek: function (week, day) {
      return this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(day - 1, "d")
        .add(1, "h");
    },

    getWeeklyIntensityValues(week) {
      const startDate = this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(-1, "hour");
      const endDate = this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(7, "d");

      const workouts = this.workouts.filter(
        (w) =>
          this.$moment(w.date).isAfter(startDate) &&
          this.$moment(w.date).isBefore(endDate) &&
          w.durationIs > 0
      );
      let weeklyIntensity = { time_hit: 0, time_mit: 0, time_lit: 0 };
      workouts.forEach(function (w) {
        weeklyIntensity.time_hit +=
          w.time_hit * (w.durationIs || w.durationShould);
        weeklyIntensity.time_mit +=
          w.time_mit * (w.durationIs || w.durationShould);
        weeklyIntensity.time_lit +=
          w.time_lit * (w.durationIs || w.durationShould);
      });
      return weeklyIntensity;
    },

    getWeeklyIsValues(week) {
      const startDate = this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(-1, "hour");
      const endDate = this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(7, "d");

      const workouts = this.workouts.filter(
        (w) =>
          this.$moment(w.date).isAfter(startDate) &&
          this.$moment(w.date).isBefore(endDate)
      );
      let weeklyDuration = { swim: 0, run: 0, bike: 0, strength: 0, pss: 0 };
      workouts.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDuration.swim += w.durationIs || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDuration.bike += w.durationIs || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDuration.run += w.durationIs || 0;
        }
        if (w.sport == "Kraft") {
          weeklyDuration.strength += w.durationIs || 0;
        }
        weeklyDuration.pss += w.pss || 0;
      });

      return weeklyDuration;
    },

    getWeeklyShouldValues(week) {
      const startDate = this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(-1, "hour");
      const endDate = this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(7, "d");

      const workouts = this.workouts.filter(
        (w) =>
          this.$moment(w.date).isAfter(startDate) &&
          this.$moment(w.date).isBefore(endDate)
      );
      let weeklyDuration = { swim: 0, run: 0, bike: 0, strength: 0 };
      workouts.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDuration.swim += w.durationShould || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDuration.bike += w.durationShould || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDuration.run += w.durationShould || 0;
        }
        if (w.sport == "Kraft") {
          weeklyDuration.strength += w.durationShould || 0;
        }
      });
      return weeklyDuration;
    },

    getWeeklyDistanceValues(week) {
      const startDate = this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(-1, "hour");
      const endDate = this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(7, "d");

      const workouts = this.workouts.filter(
        (w) =>
          this.$moment(w.date).isAfter(startDate) &&
          this.$moment(w.date).isBefore(endDate)
      );
      let weeklyDistance = { swim: 0, run: 0, bike: 0, strength: 0 };
      workouts.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDistance.swim += w.distance || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDistance.bike += w.distance || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDistance.run += w.distance || 0;
        }
      });

      weeklyDistance.run = Math.round(weeklyDistance.run * 10) / 10;
      weeklyDistance.bike = Math.round(weeklyDistance.bike * 10) / 10;
      weeklyDistance.swim = Math.round(weeklyDistance.swim * 10) / 10;

      return weeklyDistance;
    },

    getWeeklyDistanceShouldValues(week) {
      const startDate = this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(-1, "hour");
      const endDate = this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(7, "d");

      const workouts = this.workouts.filter(
        (w) =>
          this.$moment(w.date).isAfter(startDate) &&
          this.$moment(w.date).isBefore(endDate)
      );
      let weeklyDistance = { swim: 0, run: 0, bike: 0, strength: 0 };
      workouts.forEach(function (w) {
        if (w.sport == "Schwimmen") {
          weeklyDistance.swim += w.distanceShould || 0;
        }
        if (w.sport == "Radfahren") {
          weeklyDistance.bike += w.distanceShould || 0;
        }
        if (w.sport == "Laufen") {
          weeklyDistance.run += w.distanceShould || 0;
        }
      });

      weeklyDistance.run = Math.round(weeklyDistance.run * 10) / 10;
      weeklyDistance.bike = Math.round(weeklyDistance.bike * 10) / 10;
      weeklyDistance.swim = Math.round(weeklyDistance.swim * 10) / 10;

      return weeklyDistance;
    },

    updateData: async function () {
      this.loaded = false;
      this.workouts = await (
        await axios.get(
          this.$host +
            "/workout/from/" +
            this.getDayOfWeek(this.loadedWeeks[0], 1) +
            "/until/" +
            this.getDayOfWeek(
              this.loadedWeeks[this.loadedWeeks.length - 1],
              7
            ).endOf("week")
        )
      ).data;
      this.loaded = true;
      const _self = this;
      setTimeout(function () {
        _self.weekLoadingBlocked = false;
      }, 400);
    },
  },

  mounted: async function () {
    this.loaded = false;
    this.workouts = await (
      await axios.get(
        this.$host +
          "/workout/from/" +
          this.getDayOfWeek(this.loadedWeeks[0], 1) +
          "/until/" +
          this.getDayOfWeek(
            this.loadedWeeks[this.loadedWeeks.length - 1],
            7
          ).endOf("week")
      )
    ).data;
    this.loaded = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.loading {
  background: green;
}

.week {
  margin-left: 15px;
  margin-right: 15px;

  background: #fff;
  margin-bottom: 30px;
  padding: 35px 0;
  padding-bottom: 15px;
  border-radius: 10px;
  color: #333;
}

.heading {
  font-weight: bold;
  font-size: 16px;
}

i {
  font-size: 16px;
  padding: 7px;
  color: #aaa;
}

.entry {
  background: #f7f7fa;
  padding: 5px;
  border-radius: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
  z-index: 66666;
  overflow: hidden;
}

.close-overlay i {
  cursor: pointer;
  font-size: 35px;
  float: right;
}

.overlay-box {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  background: #fff;
  padding: 0;
  overflow-y: auto !important;
  margin: auto;
  max-width: 750px;
  left: 50%;
  margin-left: -375px;
  overflow-x: hidden;
  top: 3%;
  bottom: 3%;

  z-index: 99999999;
  border-radius: 5px;
}

.overlay-box-library {
  position: fixed;
  left: 2%;
  right: 2%;
  top: 2%;
  bottom: 2%;
  margin: auto;

  padding: 0;
  overflow-x: hidden;
  z-index: 99999999;
  max-width: 1400px;
  border-radius: 5px;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 800px) {
  .overlay-box {
    position: fixed;

    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    margin-left: 0;
  }
  .overlay-box-library {
    position: fixed;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    background: #fff;
    padding: 0;
    overflow-x: hidden;
    z-index: 99999999;
  }
}
</style>
