<template>
  <div class="row">
    <div class="submenu col-xl-2 d-none d-xl-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/training/dashboard"> <div class="menu-entry">Dashboard</div></a>
          </div>
          <div class="col-lg-12">
            <a href="/#/training/dashboard">
              <div class="menu-entry active_submenu" style="padding-left: 35px">Bestleistungen</div></a
            >
          </div>
          <div class="col-lg-12">
            <a href="/#/training/kalender"> <div class="menu-entry">Kalender</div></a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/liste">
              <div class="menu-entry">Trainingspläne</div>
            </a>
          </div>
          <div class="col-lg-12">
            <a href="/#/training/FragDenCoach">
              <div class="menu-entry">Wissen</div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-10 white_wrapper">
      <div class="row">
        <div class="col-lg-12">
          <h4>Sport wählen:</h4>
          <button class="closer" v-if="showSport == 'Laufen'">Laufen</button>

          <button @click="showSport = 'Laufen'" class="primary" v-if="showSport != 'Laufen'">Laufen</button>

          &nbsp;

          <button class="closer" v-if="showSport == 'Radfahren'">Radfahren</button>
          <button @click="showSport = 'Radfahren'" class="primary" v-if="showSport != 'Radfahren'">Radfahren</button>
        </div>
      </div>

      <hr class="top-spacer-50" />

      <div v-if="showSport == 'Radfahren'">
        <div>
          <br />
          <h3 style="text-align: center">Bestleistungen Radfahren</h3>
        </div>

        <div
          v-for="(session, index) in workoutAnalysis"
          :key="index + 'bp'"
          style="text-align: center"
          class="top-spacer-25"
        >
          <div class="row" v-if="session.newBestPowersYear.length > 0">
            <div class="col-lg-3">
              <br />
              <strong> {{ $moment(session.workout.date).format("DD.MM.YYYY") }} </strong><br />
              <h4>
                <strong>{{ session.workout.name }}</strong>
              </h4>
            </div>
            <div class="col-lg-7">
              <div class="row justify-content-center">
                <div
                  class="col-md-2 col-4"
                  v-for="bestPower in session.newBestPowersYear"
                  :key="bestPower + 'bestPower'"
                  style="float: left; text-align: center"
                >
                  <div style="padding: 15px">
                    <i class="fa fa-trophy" aria-hidden="true"></i><br />
                    {{ formattedTime(bestPower.split("_")[1]) }} <br />
                    {{ Math.round(session[bestPower]) }}w
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <a type="submit" v-bind:href="'/#/auswertung/workout/details/' + session.workout._id">
                <button class="secondary" style="max-width: 200px">Details</button>
              </a>
            </div>
          </div>
          <hr />
        </div>

        <div v-if="workoutAnalysis.length == 0">
          <h4 class="top-spacer-50">Es liegen keine Daten vor</h4>
        </div>
      </div>
      <div v-if="showSport == 'Laufen'">
        <div>
          <br />
          <h3 style="text-align: center">Bestleistungen Laufen</h3>
        </div>

        <div
          v-for="(session, index) in workoutAnalysisRunning"
          :key="index + 'bp'"
          style="text-align: center"
          class="top-spacer-25"
        >
          <div class="row" v-if="session.newBestRunningTimesYear.length > 0">
            <div class="col-lg-3">
              <br />
              <strong> {{ $moment(session.workout.date).format("DD.MM.YYYY") }} </strong><br />
              <h4>
                <strong>{{ session.workout.name }}</strong>
              </h4>
            </div>
            <div class="col-lg-7">
              <div class="row justify-content-center">
                <div
                  class="col-md-2 col-4"
                  v-for="bestTime in session.newBestRunningTimesYear"
                  :key="bestTime + 'bestTime'"
                  style="float: left"
                >
                  <div style="padding: 15px">
                    <i class="fa fa-trophy" aria-hidden="true"></i><br />
                    {{ bestTime.split("_")[4] }}m <br />
                    {{
                      $moment
                        .utc(session["best_running_distance_time_" + bestTime.split("_")[4]] * 1000)
                        .format("H:mm:ss")
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <a type="submit" v-bind:href="'/#/auswertung/workout/details/' + session.workout._id">
                <button class="secondary" style="max-width: 200px">Details</button>
              </a>
            </div>
          </div>
          <hr />
        </div>
        <div v-if="workoutAnalysisRunning.length == 0">
          <h4 class="top-spacer-50">Es liegen keine Daten vor</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Rekordleistungen",

  data: function () {
    return {
      showSport: "Laufen",
      loaded: false,
      workoutAnalysis: [],
      workoutAnalysisRunning: [],
    };
  },
  methods: {
    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },
  },
  created: function () {
    const _self = this;
    axios
      .get(this.$host + "/workout/bestpower")
      .then(function (res) {
        _self.workoutAnalysis = res.data;
      })
      .catch(function (err) {
        console.log("ERR", err);
      });

    axios
      .get(this.$host + "/workout/bestrunning")
      .then(function (res) {
        _self.workoutAnalysisRunning = res.data;
      })
      .catch(function (err) {
        console.log("ERR", err);
      });
  },
};
</script>
