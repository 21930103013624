var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-xl-10"},[_c('div',{staticClass:"centered week light-shadow",staticStyle:{"padding-bottom":"35px","margin":"0"}},[_c('button',{staticStyle:{"width":"250px"},attrs:{"id":"scroll-bottom"},on:{"click":_vm.loadCalendarEntriesBottom}},[_vm._v(" Nächste Wochen laden ")])]),(!_vm.loaded)?_c('div',{staticClass:"spinner-border text-primary top-spacer-25",attrs:{"role":"status"}}):_vm._e(),_vm._l((_vm.loadedWeeks.slice().reverse()),function(week){return _c('div',{key:week,staticClass:"row week top-spacer-25 light-shadow",staticStyle:{"padding-top":"15px","margin-left":"0","margin-right":"0"}},[_c('div',{staticClass:"col-lg-1 col-md-1 col-12"},[_c('strong',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.getDayOfWeek(week, 1)))+" bis "+_vm._s(_vm._f("formatDate")(_vm.getDayOfWeek(week, 7))))])]),_vm._m(1,true),_c('div',{staticClass:"col-lg-1 col-md-2 col-4"},[_c('strong',[_vm._v("Zeit")]),_c('br'),_vm._v(" "+_vm._s(_vm.formattedTimeSlash( _vm.getWeeklyIsValues(week).swim + _vm.getWeeklyIsValues(week).bike + _vm.getWeeklyIsValues(week).run + _vm.getWeeklyIsValues(week).strength ))+" ")]),_c('div',{staticClass:"col-lg-1 col-md-2 col-4"},[_c('strong',[_vm._v("Distanz")]),_c('br'),_vm._v(" "+_vm._s(Math.round( (_vm.getWeeklyDistanceValues(week).swim + _vm.getWeeklyDistanceValues(week).bike + _vm.getWeeklyDistanceValues(week).run + _vm.getWeeklyDistanceValues(week).strength) * 10 ) / 10)+"km ")]),_c('div',{staticClass:"col-lg-1 col-md-2 col-4"},[_c('strong',[_vm._v("Load")]),_c('br'),_c('span',{style:('font-size:' +
              Math.max(12, Math.sqrt(_vm.getWeeklyIsValues(week).pss * 2) / 2) +
              'px')},[_vm._v(" "+_vm._s(_vm.getWeeklyIsValues(week).pss)+" ")])]),_vm._m(2,true),_c('div',{staticClass:"col-lg-1 col-md-2 col-3"},[_c('strong',[_vm._v("Schwimmen")]),_c('br'),_vm._v(" "+_vm._s(_vm.formattedTimeSlash(_vm.getWeeklyIsValues(week).swim))),_c('br'),_vm._v(" "+_vm._s(_vm.getWeeklyDistanceValues(week).swim)+"km ")]),_c('div',{staticClass:"col-lg-1 col-md-2 col-3"},[_c('strong',[_vm._v("Rad")]),_c('br'),_vm._v(" "+_vm._s(_vm.formattedTimeSlash(_vm.getWeeklyIsValues(week).bike))),_c('br'),_vm._v(" "+_vm._s(_vm.getWeeklyDistanceValues(week).bike)+"km ")]),_c('div',{staticClass:"col-lg-1 col-md-2 col-3"},[_c('strong',[_vm._v("Laufen")]),_c('br'),_vm._v(" "+_vm._s(_vm.formattedTimeSlash(_vm.getWeeklyIsValues(week).run))),_c('br'),_vm._v(" "+_vm._s(_vm.getWeeklyDistanceValues(week).run)+"km ")]),_c('div',{staticClass:"col-lg-1 col-md-2 col-3"},[_c('strong',[_vm._v("Athletik")]),_c('br'),_vm._v(" "+_vm._s(_vm.formattedTimeSlash(_vm.getWeeklyIsValues(week).strength))+" ")]),_vm._m(3,true),_c('div',{staticClass:"col-lg-2 col-md-1 col-12"},[_c('strong',[_vm._v("Intensitätsverteilung")]),_c('br'),(
              _vm.getWeeklyIntensityValues(week).time_hit +
                _vm.getWeeklyIntensityValues(week).time_mit +
                _vm.getWeeklyIntensityValues(week).time_lit >
              0
            )?_c('div',{staticClass:"progress",staticStyle:{"margin-top":"6px"}},[_c('div',{staticClass:"progress-bar bg-success",style:('width:' +
                _vm.timePercentage(
                  _vm.getWeeklyIntensityValues(week).time_lit,
                  _vm.getWeeklyIntensityValues(week).time_hit +
                    _vm.getWeeklyIntensityValues(week).time_mit +
                    _vm.getWeeklyIntensityValues(week).time_lit
                ) +
                '%'),attrs:{"role":"progressbar","aria-valuenow":_vm.timePercentage(
                  _vm.getWeeklyIntensityValues(week).time_lit,
                  _vm.getWeeklyIntensityValues(week).time_hit +
                    _vm.getWeeklyIntensityValues(week).time_mit +
                    _vm.getWeeklyIntensityValues(week).time_lit
                ),"aria-valuemin":"0","aria-valuemax":"100"}}),_c('div',{staticClass:"progress-bar bg-info",style:('background-color: rgb(232, 126, 4)!important;' +
                'width:' +
                _vm.timePercentage(
                  _vm.getWeeklyIntensityValues(week).time_mit,
                  _vm.getWeeklyIntensityValues(week).time_hit +
                    _vm.getWeeklyIntensityValues(week).time_mit +
                    _vm.getWeeklyIntensityValues(week).time_lit
                ) +
                '%'),attrs:{"role":"progressbar","aria-valuenow":_vm.timePercentage(
                  _vm.getWeeklyIntensityValues(week).time_mit,
                  _vm.getWeeklyIntensityValues(week).time_hit +
                    _vm.getWeeklyIntensityValues(week).time_mit +
                    _vm.getWeeklyIntensityValues(week).time_lit
                ),"aria-valuemin":"0","aria-valuemax":"100"}}),_c('div',{staticClass:"progress-bar bg-danger",style:('width:' +
                _vm.timePercentage(
                  _vm.getWeeklyIntensityValues(week).time_hit,
                  _vm.getWeeklyIntensityValues(week).time_hit +
                    _vm.getWeeklyIntensityValues(week).time_mit +
                    _vm.getWeeklyIntensityValues(week).time_lit
                ) +
                '%'),attrs:{"role":"progressbar","aria-valuenow":_vm.timePercentage(
                  _vm.getWeeklyIntensityValues(week).time_hit,
                  _vm.getWeeklyIntensityValues(week).time_hit +
                    _vm.getWeeklyIntensityValues(week).time_mit +
                    _vm.getWeeklyIntensityValues(week).time_lit
                ),"aria-valuemin":"0","aria-valuemax":"100"}})]):_vm._e()]),_c('div',{staticClass:"col-lg-2"},[_c('button',{staticClass:"secondary",staticStyle:{"padding":"7px 15px","font-size":"14px","margin-top":"15px"},on:{"click":function($event){_vm.$router.push({
                name: 'AuswertungPage',
                query: {
                  from: _vm.getDayOfWeek(week, 1).startOf('day').unix(),
                  until: _vm.getDayOfWeek(week, 7).endOf('day').unix(),
                },
                params: { page: 'workouts' },
              })}}},[_vm._v(" Einheiten ansehen ")])])])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[(!_vm.loaded)?_c('div',{staticClass:"spinner-border text-primary top-spacer-25",staticStyle:{"margin-bottom":"50px"},attrs:{"role":"status"}}):_vm._e(),_c('div',{staticClass:"centered week light-shadow",staticStyle:{"padding-bottom":"35px","margin":"0"}},[_c('button',{staticStyle:{"width":"250px"},attrs:{"id":"scroll-bottom"},on:{"click":_vm.loadCalendarEntriesTop}},[_vm._v(" Vorherige Wochen laden ")])])])])],2)]),_c('div',{staticClass:"top-spacer-50"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submenu col-xl-2 d-none d-xl-block"},[_c('div',{staticClass:"width-wrap sticky-top"},[_c('div',{staticClass:"row top-spacer-25"},[_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/dashboard"}},[_c('div',{staticClass:"menu-entry"},[_vm._v("Dashboard")])])]),_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/kalender"}},[_c('div',{staticClass:"menu-entry"},[_vm._v("Kalender")])])]),_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/liste"}},[_c('div',{staticClass:"menu-entry"},[_vm._v("Trainingspläne")])])]),_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/wissen"}},[_c('div',{staticClass:"menu-entry"},[_vm._v("Wissen")])])]),_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/kompakt"}},[_c('div',{staticClass:"menu-entry active_submenu"},[_vm._v("Kompaktübersicht")])])]),_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/upload"}},[_c('div',{staticClass:"menu-entry"},[_vm._v("Upload")])])]),_c('div',{staticClass:"col-lg-12"},[_c('a',{attrs:{"href":"/#/training/vorlagen"}},[_c('div',{staticClass:"menu-entry"},[_vm._v("Workout-Vorlagen")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-block d-md-none"},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-block d-md-none"},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-block d-md-none"},[_c('br')])
}]

export { render, staticRenderFns }