<template>
  <div class="info">
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-6 white_wrapper" style="text-align: left">
        <h3 class="top-spacer-50">Account löschen</h3>

        Um deinen Account bei uns zu löschen, hast du zwei einfache Optionen:<br /><br />

        1) Besuche dein Profil und klicke auf den Button „Ich möchte meinen
        Account löschen“. Folge den Anweisungen, um den Löschvorgang
        abzuschließen. Bitte beachte, dass mit der Bestätigung zur Löschung alle
        deine Daten unwiederbringlich gelöscht werden.<br /><br />

        2) Alternativ kannst du eine E-Mail von der bei uns registrierten
        E-Mail-Adresse an kontakt@pushinglimits.club senden. Teile uns in der
        E-Mail deinen Wunsch mit, den Account zu löschen. Auch in diesem Fall
        werden alle deine Daten endgültig und nicht wiederherstellbar gelöscht,
        sobald wir den Prozess einleiten.<br /><br />

        <strong
          >Achtung: Dein Abo musst du dennoch selber über dein Smartphone
          kündigen, dies können wir technisch nicht automatisch für dich
          erledigen.</strong
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteAccount",
  props: {
    msg: String,
  },
};
</script>

<style>
.info {
  font-size: 16px;
}
</style>
