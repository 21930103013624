<template>
  <div style="overflow-x: hidden; margin: -15px; margin-top: 0">
    <div
      class="desktop-border-radius"
      :style="{
        height: '100%',
        width: '100%',
        background:
          'url(https://assets.pushinglimits.club/matschfuss.jpg) no-repeat right' +
          '/cover',
      }"
    >
      <div>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-7">
            <div class="white_wrapper light-shadow" style="margin: 25px">
              <img
                class="mainlogo"
                alt="PL logo"
                style="box-shadow: none; width: 180px"
                v-bind:src="
                  $client.getClient() == 'pushinglimits'
                    ? require('@/assets/images/PL-Club-Logo.png')
                    : require('@/assets/images/' + $client.getClient() + '.jpg')
                "
              />
              <!-- <span
                v-if="$client.getClient() != 'pushinglimits'"
                style="font-size: 30px; padding: 20px; margin: auto"
              >
                X</span
              >
              <img
                class="mainlogo"
                alt="PL logo"
                style="box-shadow: none; max-width: 130px"
                v-if="$client.getClient() !== 'pushinglimits'"
                v-bind:src="require('@/assets/images/PL-Club-Logo.png')"
              />-->
              <h2 class="top-spacer-25">{{ $t("Account anlegen") }}</h2>
              <!-- Scyence im pushinglimits.club -->

              <form @submit.prevent="register" class="top-spacer-25">
                <div v-if="attempted">
                  <div v-if="success">
                    <h5 style="color: green">
                      {{
                        $t(
                          "Dein Account wurde registriert! Wir haben dir eine Mail mit einem Link gesendet. Bitte öffne diesen, um deine Anmeldung zu bestätigen."
                        )
                      }}
                    </h5>
                    <br />
                    <h4>
                      {{
                        $t(
                          "Bitte prüfe deinen Spam-Ordner, falls die Mail nicht ankommt."
                        )
                      }}
                    </h4>
                    <br />
                  </div>
                  <div v-if="!success">
                    <h5 style="color: red">
                      {{
                        $t(
                          "Dein Account konnte nicht registriert werden! Bitte prüfe nochmal deine eMail und wähle ein Passwort mit mindestens 8 Zeichen."
                        )
                      }}<br /><br />
                      <span style="color: green">
                        {{
                          $t(
                            'Klicke auf "Zur Anmeldung" Wenn du schon einen Account registriert hast'
                          )
                        }}</span
                      ><br /><br />

                      <a href="/#/login">{{ $t("Zur Anmeldung") }}</a
                      ><br /><br />

                      <span v-if="msg"><br />{{ msg }}</span>
                    </h5>
                  </div>
                </div>
                <div>
                  <input
                    ref="email"
                    id="email"
                    type="email"
                    placeholder="deine@email.de"
                    v-model="email"
                    name="email"
                    required
                  />
                </div>

                <div class="top-spacer-10">
                  <input
                    ref="password"
                    id="password"
                    type="password"
                    placeholder="Passwort"
                    v-model="password"
                    name="pw"
                    required
                  />
                </div>

                <div class="top-spacer-10">
                  <button type="submit" style="max-width: 100%; width: 300px">
                    {{ $t("Kostenlos Registrieren") }}
                  </button>
                </div>
                <div
                  style="font-size: 12px; line-height: 1.3; color: #666"
                  class="top-spacer-15"
                >
                  <span
                    style="
                      max-width: 300px;
                      display: table;
                      margin: 0 auto;
                      text-align: justify;
                    "
                  >
                    Mit Klick auf "Kostenlos Registrieren" stimmst du unseren
                    <a
                      style="color: #222"
                      href="https://www.iubenda.com/nutzungsbedingungen/44469436"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Nutzungsbedingungen</a
                    >
                    zu. In unserer
                    <a
                      style="color: #000"
                      href="https://www.iubenda.com/privacy-policy/44469436"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Datenschutzerklärung</a
                    >
                    erfährst du, wie deine Daten erfasst, verwendet und geteilt
                    werden.</span
                  >
                </div>

                <p class="top-spacer-15" style="font-weight: bold">
                  Schon registriert?
                  <a href="/#/login">{{ $t("Zur Anmeldung") }}</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8" style="font-size: 16px; padding: 0 25px">
        <h2 class="top-spacer-50">
          MATSCHFUSS <span style="font-size: 1.1rem">X</span> PUSHING LIMITS
          CLUB
        </h2>

        <div class="row">
          <div class="col-lg-6 top-spacer-50">
            <img
              src="https://assets.pushinglimits.club/Matschfuss.jpg"
              style="width: 100%"
            />
          </div>
          <div class="col-lg-6 top-spacer-50" style="text-align: left">
            <h3>LASS ES KRACHEN!</h3>
            <div class="top-spacer-25">
              MATSCHFUSS ist eins der größten Gravel-Festivals Deutschlands. Die
              Schlammschlacht im Norden findet zwei Mal im Jahr statt und kann
              ganz schön fordernd sein. Immerhin stehen mindestens 100 km auf
              dem Plan und diese sind gespickt mit schwierigen Passagen und
              unerwarteten Hürden. Eine gute Fitness ist hier KEY! Daher gibt es
              jetzt in Zusammenarbeit mit Johann Ackermann und dem PUSHING
              LIMITS CLUB Trainingspläne für das perfekte Matschfuss Erlebnis.
            </div>

            <h3 class="top-spacer-50">
              {{ $t("30 Tage kostenlos testen!") }}
            </h3>
            <div class="top-spacer-25">
              {{
                $t(
                  "Du kannst dir völlig risikofrei einen Account anlegen: dir entstehen keine Kosten und du gehst auch kein Abo ein. Wenn du dich nach deiner Testphase für eine Mitgliedschaft entscheidest, kannst du ein kostenpflichtiges Abo abschließen."
                )
              }}
            </div>

            <div class="row">
              <div class="col-lg-12 top-spacer-50" style="margin-bottom: 75px">
                <button @click="$scrollToTop()" style="width: 330px">
                  {{ $t("Jetzt kostenlos registrieren und testen") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="$client.getClient() !== 'pushinglimits'"
          style="padding: 25px"
        >
          <img
            class="mainlogo"
            alt="PL logo"
            style="box-shadow: none; width: 115px"
            v-bind:src="
              require('@/assets/images/' + $client.getClient() + '.jpg')
            "
          />
          <span
            v-if="$client.getClient() != 'pushinglimits'"
            style="font-size: 30px; padding: 20px; margin: auto"
          >
            X</span
          >
          <img
            class="mainlogo"
            alt="PL logo"
            style="box-shadow: none; max-width: 130px"
            v-bind:src="require('@/assets/images/PL-Club-Logo.png')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";

export default {
  metaInfo: {
    title: "Pushing Limits Club - Account anlegen",
  },
  data() {
    return {
      email: "",
      password: "",
      success: false,
      attempted: false,
      platform: Capacitor.getPlatform(),
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    register: function () {
      const _self = this;
      _self.attempted = false;
      _self.success = false;
      let data = {
        email: this.email || this.$refs.email.value,
        password: this.password || this.$refs.password.value,
        client: this.$client.getClient(),
      };
      this.$store
        .dispatch("register_plattfuss", data)
        .then(function () {
          _self.attempted = true;
          _self.success = true;
          //_self.$router.push("/");
        })
        .catch(function () {
          _self.attempted = true;
          _self.success = false;
        });
    },
  },
};
</script>

<style scoped>
a {
  color: blue;
}
input {
  width: 300px !important;
}

.VueCarousel-dot {
  min-width: 0 !important;
  background-color: rgb(200, 200, 200) !important;
}

.VueCarousel-dot--active {
  background-color: rgb(0, 0, 0) !important;
}

.VueCarousel-navigation-button {
  min-width: 50px;
}
</style>
