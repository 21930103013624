var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"impressum"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"}),_c('div',{staticClass:"col-lg-6 white_wrapper"},[_c('h3',{staticClass:"top-spacer-50"},[_vm._v(_vm._s(_vm.$t("Abo kündigen")))]),_vm._v(" Du kannst dein Abo jederzeit auf deinem Smartphone über den normalen Weg im App Store bzw. Play Store kündigen. Wenn du die App benutzt, erscheint hier ein Button um zur Verwaltung deiner Abos zu kommen. Dort kannst du das Abo kündigen."),_c('br'),_c('br'),(
          _vm.$parent.appstoreState &&
          _vm.$parent.appstoreState.product1 &&
          _vm.$parent.appstoreState.product2 &&
          Object.keys(_vm.$parent.appstoreState.product1).length > 0 &&
          Object.keys(_vm.$parent.appstoreState.product2).length > 0
        )?_c('div',[_c('button',{on:{"click":function($event){return _vm.$parent.manageSubscription()}}},[_vm._v(" "+_vm._s(_vm.$t("Zur Abo-Verwaltung"))+" ")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }