<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";

export default {
  props: ["sessions"],

  data: function () {
    return {
      workouts: [],
      chartdata: {
        labels: [
          "0",
          "20",
          "40",
          "60",
          "80",
          "100",
          "120",
          "140",
          "160",
          "180",
          "200",
          "220",
          "240",
          "260",
          "280",
          "300",
          "320",
          "340",
          "360",
          "380",
          "400",
          "420",
          "440",
          "460",
          "480",
          "500+",
        ],
        datasets: [
          {
            borderRadius: 3,

            data: [],
            borderColor: "hsl(226, 65%, 35%)",
            backgroundColor: "hsl(226, 65%, 35%)",
            fill: true,
            tension: 0.4,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                var datasetLabel = moment().startOf("day").seconds(context.parsed.y).format("H:mm:ss");
                return datasetLabel;
              },
            },
          },
        },

        elements: {
          point: {
            radius: 0,
          },
        },
        animation: {
          duration: 0,
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },
        scales: {
          yAxes: {
            ticks: {
              maxTicksLimit: 5,
              callback: function (value) {
                return moment().startOf("day").seconds(value).format("H:mm");
              },
            },
          },
          xAxes: {
            gridLines: {
              display: false,
            },
            ticks: {
              display: true,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  mounted() {
    let ctx = this.$refs.graph.getContext("2d");

    this.computeChartData();

    const config = {
      type: "bar",
      data: this.chartdata,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.yAxes;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
      ],
    };

    new Chart(ctx, config);
  },

  methods: {
    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },
    computeChartData: function () {
      // `this` points to the vm instance
      const _self = this;
      let dataDist = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.sessions.forEach(function (session) {
        session.laps.forEach(function (lap) {
          lap.records.forEach(function (record) {
            // TODO: timer_time - last_timer_time statt +=1
            if (record.power < 20) {
              dataDist[0] += 1;
            } else if (record.power < 40) {
              dataDist[1] += 1;
            } else if (record.power < 60) {
              dataDist[2] += 1;
            } else if (record.power < 80) {
              dataDist[3] += 1;
            } else if (record.power < 100) {
              dataDist[4] += 1;
            } else if (record.power < 120) {
              dataDist[5] += 1;
            } else if (record.power < 140) {
              dataDist[6] += 1;
            } else if (record.power < 160) {
              dataDist[7] += 1;
            } else if (record.power < 180) {
              dataDist[8] += 1;
            } else if (record.power < 200) {
              dataDist[9] += 1;
            } else if (record.power < 220) {
              dataDist[10] += 1;
            } else if (record.power < 240) {
              dataDist[11] += 1;
            } else if (record.power < 260) {
              dataDist[12] += 1;
            } else if (record.power < 280) {
              dataDist[13] += 1;
            } else if (record.power < 300) {
              dataDist[14] += 1;
            } else if (record.power < 320) {
              dataDist[15] += 1;
            } else if (record.power < 340) {
              dataDist[16] += 1;
            } else if (record.power < 360) {
              dataDist[17] += 1;
            } else if (record.power < 380) {
              dataDist[18] += 1;
            } else if (record.power < 400) {
              dataDist[19] += 1;
            } else if (record.power < 420) {
              dataDist[20] += 1;
            } else if (record.power < 440) {
              dataDist[21] += 1;
            } else if (record.power < 460) {
              dataDist[22] += 1;
            } else if (record.power < 480) {
              dataDist[23] += 1;
            } else if (record.power < 500) {
              dataDist[24] += 1;
            } else {
              dataDist[25] += 1;
            }

            _self.chartdata.datasets[0].data = dataDist;
          });
        });
      });

      /*
       
*/
      return this.chartdata;
    },
  },
};
</script>
